import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import VariablePaymentsApi from '../../../Api/VariablePaymentsApi';
import { VariablePayment } from '../../../ApiTypes/VariablePayment';
import VariablePaymentModal from '../../VariablePayment/VariablePaymentModal';
import VariablePaymentsTable from './VariablePaymentsTable';

export default function TpdTab() {
  let { claimNumber } = useParams();
  const [payments, setPayments] = useState<VariablePayment[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedPayment, setSelectedPayment] =
    useState<VariablePayment | null>(null);

  useEffect(() => {
    getPayments();
  }, [claimNumber]);

  const getPayments = () => {
    if (claimNumber) {
      VariablePaymentsApi.getVariablePaymentsForClaim(claimNumber)
        .then((res) => {
          setPayments(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const editPayment = (p?: VariablePayment) => {
    if (p) {
      setSelectedPayment(p);
    } else {
      setSelectedPayment(null);
    }
    setShowModal(true);
  };

  const handleSavePayment = (values: VariablePayment) => {
    if (values.id > 0) {
      return VariablePaymentsApi.updateVariablePayment(values)
        .then((res) => {
          if (res.data.success) {
            setShowModal(false);
            getPayments();
            setSelectedPayment(null);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to update payment');
        });
    }
    return VariablePaymentsApi.createVariablePayment(values)
      .then((res) => {
        if (res.data.success) {
          setShowModal(false);
          getPayments();
          setSelectedPayment(null);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create payment');
      });
  };

  return (
    <div>
      <div className='d-flex justify-content-center align-items-center mb-3'>
        <Button
          type='button'
          size='sm'
          variant='primary'
          title='Add'
          onClick={() => {
            editPayment();
          }}
        >
          <FaPlusCircle /> Add New
        </Button>
      </div>
      <VariablePaymentsTable payments={payments} editPayment={editPayment} />
      <VariablePaymentModal
        show={showModal}
        setShow={setShowModal}
        payment={selectedPayment}
        handleSave={handleSavePayment}
      />
    </div>
  );
}
