import accounting from "accounting";
import { addDays, differenceInBusinessDays, differenceInDays, isBefore } from "date-fns";
import { RecurringPayment } from "../ApiTypes/RecurringPayment";
import { displayDateOnly } from "../Utils";

export interface RecurringPaymentWeeklyDetail {
  week: number;
  start: string;
  end: string;
  amount: string;
  payOn: string;
}

export const getRecurringPaymentWeeklyDetail = (rp: RecurringPayment) => {
  let start = new Date(rp.startDate)
  let end = rp.endDate ? new Date(rp.endDate) : new Date()

  let w = Math.round(differenceInDays(end, start) / 7)

  let data: RecurringPaymentWeeklyDetail[] = []

  for (let i = 1; i <= w; i++) {
    let weekStart = addDays(start, (i - 1) * 7)
    let weekEnd = isBefore(addDays(weekStart, 6), end) ? addDays(weekStart, 6) : addDays(weekStart, differenceInDays(end, weekStart))
    let payOn = addDays(weekEnd, -3);


    let businessDaysCount = differenceInBusinessDays(weekEnd, weekStart) + 1; // add 1 to include start day


    let weekAmount = (rp.amount / 5) * businessDaysCount

    const detail: RecurringPaymentWeeklyDetail = {
      week: i,
      start: displayDateOnly(weekStart)!,
      end: displayDateOnly(weekEnd)!,
      payOn: displayDateOnly(payOn)!,
      amount: accounting.formatMoney(weekAmount),
    }

    data.push(detail);

  }

  return data;

}