import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { Employer } from '../../ApiTypes/Employer';
import { Form as RFFForm, Field } from 'react-final-form';
import { Button, Form } from 'react-bootstrap';
import styles from './index.module.css';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import { State } from '../../ApiTypes/State';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { parseDatesForServer } from '../../Utils';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import { requiredField, zipLength } from '../../Utils/FieldValidation';
import EmployerApi from '../../Api/EmployerApi';
import { toast } from 'react-toastify';
import {
  updateCurrentEmployerInReducer,
  updateCurrentInsuranceCompanyInReducer,
} from '../../Actions/CurrentClaimActions';
import { EmployerPolicy } from '../../ApiTypes/EmployerPolicy';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';

interface UpdateAddEmployerFormType extends Employer, EmployerPolicy {}

export default function EmployerAddEdit({
  show,
  setShow,
  employer,
  setEmployerToEdit,
}: {
  show: boolean;
  employer: Employer | null;
  setShow: (value: boolean) => void;
  setEmployerToEdit: (employer: Employer | null) => void;
}) {
  const dispatch = useAppDispatch();
  const { states, insuranceCompanies } = useAppSelector(
    (state) => state.reference
  );
  const [showPolicy, setShowPolicy] = useState<boolean>(false);

  const onSubmit = (values: UpdateAddEmployerFormType) => {
    if (values.employerId) {
      return updateEmployer(values);
    } else {
      return createEmployer(values);
    }
  };

  const createEmployer = (values: UpdateAddEmployerFormType) => {
    return EmployerApi.createEmployer(values)
      .then((res) => {
        if (res.data.success) {
          getEmployerById(+res.data.affectedEntityIdentifier);
          createNewPolicy(values, +res.data.affectedEntityIdentifier);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create employer');
      });
  };
  const updateEmployer = (values: UpdateAddEmployerFormType) => {
    return EmployerApi.updateEmployer(values)
      .then((res) => {
        if (res.data.success) {
          getEmployerById(+res.data.affectedEntityIdentifier);
          createNewPolicy(values, +res.data.affectedEntityIdentifier);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create employer');
      });
  };

  const createNewPolicy = (
    values: UpdateAddEmployerFormType,
    newEmployerId: number
  ) => {
    if (
      values.accountNumber &&
      values.startDate &&
      values.endDate &&
      values.treatyYear
    ) {
      const newPolicy: EmployerPolicy = {
        policyId: 0,
        employerId: newEmployerId,
        treatyYear: values.treatyYear,
        startDate: values.startDate,
        endDate: values.endDate,
        policyNumber: values.policyNumber,
        premium: null,
        deductible: null,
        uwpolicyid: null,
        excessPolicyId: null,
        needsSecondaryExcess: null,
        secondaryExcessPolicyId: null,
        wcpanelId: null,
        wcpanelAddDate: null,
        employer: null,
        excessPolicy: null,
        secondaryExcessPolicy: null,
      };
      EmployerApi.createEmployerPolicy(newPolicy)
        .then((res) => {
          if (res.data.success) {
            toast.success(
              'Policy Created! Please use the edit policy screen to add class codes for this policy.'
            );
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to create new policy');
        });
    }
  };

  const getEmployerById = (employerId: number) => {
    EmployerApi.getEmployerById(employerId)
      .then((res) => {
        dispatch(updateCurrentEmployerInReducer(res.data));
        const insur = insuranceCompanies.find(
          (f) => f.accountnumber === res.data.accountNumber
        );
        if (insur) {
          dispatch(updateCurrentInsuranceCompanyInReducer(insur));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => setShow(false)}
      dialogClassName=''
      aria-labelledby='Employer-Add-Edit-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Employer-Add-Edit-Form-modal'
        >
          <FaEdit className='pe-1' /> Employer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={employer ? employer : {}}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='name'
                    type='text'
                    label='Name'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='taxid'
                    type='text'
                    label='Tax Id'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='clientEmployerId'
                    type='text'
                    label='Customer Id'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='addressName'
                    type='text'
                    label='Address Name'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='address'
                    type='text'
                    label='Address 1'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='address2'
                    type='text'
                    label='Address 2'
                    component={FieldBSRenderText}
                  />
                  <div
                    className={`d-flex ${styles.address3} ${styles.address3children}`}
                  >
                    <Field
                      name='city'
                      type='text'
                      label='City'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='state'
                      label='State'
                      options={states}
                      optionMethod={(options: State[]) =>
                        options.map((o) => (
                          <option key={o.stateAbbr} value={o.stateAbbr}>
                            {o.stateAbbr}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                    <Field
                      name='zip'
                      type='text'
                      label='Zip'
                      validate={zipLength}
                      component={FieldBSRenderText}
                      maxLength={5}
                    />
                  </div>
                  {showPolicy && (
                    <fieldset>
                      <h3>New Policy</h3>
                      <div
                        className={`d-flex ${styles.address3} ${styles.address3children}`}
                      >
                        <Field
                          name='treatyYear'
                          type='number'
                          label='Treaty Year'
                          validate={requiredField}
                          component={FieldBSRenderText}
                        />
                        <Field
                          name='startDate'
                          type='text'
                          label='Start Date'
                          validate={requiredField}
                          parse={parseDatesForServer}
                          component={FieldBSRenderDate}
                        />
                        <Field
                          name='endDate'
                          type='text'
                          label='End Date'
                          validate={requiredField}
                          parse={parseDatesForServer}
                          component={FieldBSRenderDate}
                        />
                      </div>
                      <Field
                        name='policyNumber'
                        type='text'
                        label='Policy Number'
                        validate={requiredField}
                        component={FieldBSRenderText}
                      />
                    </fieldset>
                  )}
                  <div className='d-flex justify-content-between mt-2'>
                    <Button
                      type='button'
                      size='sm'
                      variant='danger'
                      onClick={() => {
                        // handle delete
                        form.reset();
                        setEmployerToEdit(null);
                        setShow(false);
                        setShowPolicy(false);
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      type='button'
                      size='sm'
                      variant='secondary'
                      onClick={() => {
                        setShowPolicy((showPolicy) => !showPolicy);
                      }}
                    >
                      New Policy
                    </Button>
                    <Button type='submit' size='sm' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                    <Button
                      type='button'
                      size='sm'
                      variant='secondary'
                      onClick={() => {
                        form.reset();
                        setEmployerToEdit(null);
                        setShowPolicy(false);
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
