import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { displayDateOnly } from '../../../Utils';
import { Button } from 'react-bootstrap';
import { FaEdit, FaPlusCircle, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import styles from './index.module.css';
import AddEditSubrogationModal from './AddEditSubrogationModal';
import { ClaimSubrogation } from '../../../ApiTypes/ClaimSubrogation';
import SubrogationApi from '../../../Api/SubrogationApi';
import accounting from 'accounting';

export default function SubrogationTable({
  data,
  claimNumber,
  getClaimSubrogations,
}: {
  data: ClaimSubrogation[];
  claimNumber: string;
  getClaimSubrogations: () => void;
}) {
  const handleEdit = (cs: ClaimSubrogation) => {
    setSelectedSubrogation(cs);
    setShowAddEdit(true);
  };

  const handleDelete = (cs: ClaimSubrogation) => {
    return SubrogationApi.deleteClaimSubrogation(cs.id)
      .then((res) => {
        if (res.data.success) {
          getClaimSubrogations();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to delete Subrogation');
      });
  };

  const [selectedSubrogation, setSelectedSubrogation] =
    useState<ClaimSubrogation | null>(null);

  const [showAddEdit, setShowAddEdit] = useState<boolean>(false);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<ClaimSubrogation>[] = [
    {
      header: '',
      id: 'actions',
      enableColumnFilter: false,
      enableSorting: false,
      size: 100,
      cell: ({ row }) => {
        return (
          <div className={`${styles.actionButtons}`}>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='Edit'
              onClick={() => {
                handleEdit(row.original);
              }}
            >
              <FaEdit />
            </Button>
            <Button
              type='button'
              variant='danger'
              size='sm'
              title='Delete'
              onClick={() => {
                handleDelete(row.original);
              }}
              disabled={
                (row.original.tTransactions &&
                  row.original.tTransactions?.length > 0) ||
                false
              }
            >
              <FaTrash />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Case Name',
      accessorKey: 'caseName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Type',
      accessorFn: (d) =>
        d.subrogationType !== null ? `${d.subrogationType?.name}` : null,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) => d.row.original.subrogationType?.name ?? '',
    },
    {
      header: 'Status',
      accessorFn: (d) => (d.status !== null ? `${d.status?.name}` : null),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) => d.row.original.status?.name ?? '',
    },
    {
      header: 'Docket #',
      accessorKey: 'docketNumber',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Referral Date',
      accessorKey: 'referalDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Lien Amount',
      accessorFn: (d) => `${d.lienAmount}`,
      sortingFn: 'alphanumericCaseSensitive',
      filterFn: 'includesString',
      cell: (d) => accounting.formatMoney(d.row.original.lienAmount ?? 0),
    },
    {
      header: 'Amount Paid',
      sortingFn: 'alphanumericCaseSensitive',
      filterFn: 'includesString',
      cell: (d) =>
        accounting.formatMoney(
          d.row.original.tTransactions
            ? d.row.original.tTransactions?.reduce(
                (a, { amount }) => a + (amount || 0),
                0
              )
            : 0
        ),
    },
    {
      header: 'Amount Remaining',
      sortingFn: 'alphanumericCaseSensitive',
      filterFn: 'includesString',
      cell: (d) =>
        accounting.formatMoney(
          d.row.original.tTransactions
            ? (d.row.original.lienAmount ?? 0) +
                d.row.original.tTransactions?.reduce(
                  (a, { amount }) => a + (amount || 0),
                  0
                )
            : d.row.original.lienAmount ?? 0
        ),
    },
  ];
  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <div className='d-flex justify-content-center align-items-center mb-3'>
        <Button
          type='button'
          variant='primary'
          size='sm'
          className='button-icon-text'
          onClick={() => {
            setSelectedSubrogation(null);
            setShowAddEdit(true);
          }}
        >
          <FaPlusCircle /> Add New
        </Button>
      </div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        selectableRow={true}
        highlightRow={true}
      />
      <AddEditSubrogationModal
        show={showAddEdit}
        setShow={setShowAddEdit}
        claimSubrogation={selectedSubrogation ?? null}
        setSelectedSubrogation={setSelectedSubrogation}
        claimNumber={claimNumber}
        getClaimSubrogations={getClaimSubrogations}
      />
    </div>
  );
}
