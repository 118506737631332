import React from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import { ProviderSearchResult } from '../../ApiTypes/ProviderSearchResult';
import { VPaymentTab } from '../../ApiTypes/VPaymentTab';
import styles from './index.module.css';
import ProviderLookUpForm from './ProviderLookUpForm';

export default function ProviderLookupModal({
  show,
  setShow,
  selectProvider,
  selectedPayment,
  disabled,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  disabled: boolean;
  selectProvider: (provider: ProviderSearchResult) => void;
  selectedPayment: VPaymentTab | null;
}) {
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='ProviderLookupModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='ProviderLookupModal-modal'
        >
          <FaCheck className='pe-1' /> Confirm
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <ProviderLookUpForm
            selectProvider={selectProvider}
            selectedPayment={selectedPayment}
            disabled={false}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
