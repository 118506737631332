import { displayDateOnly } from '../../Utils';
import {
  AutomatedReportType,
  AutomatedReportingJob,
} from '../../ApiTypes/AutomatedReportingJob';

const getReportName = (reportType: number) => {
  switch (reportType) {
    case AutomatedReportType.LossRun:
      return 'Loss Run';
    case AutomatedReportType.PremiumAndLoss:
      return 'Premium and Loss';
    default:
      return 'Unknown';
  }
};

export const automatedJobsExportHandler = (jobs: AutomatedReportingJob[]) => {
  const columns = [
    'Job Id',
    'Report',
    'Account',
    'Employer',
    'To',
    'Policy Number',
    'Start Date',
    'End On',
    'Day',
    'Creator',
    'Last Ran On',
    'Reports Sent',
  ];

  const rows: any[][] = [];

  jobs.forEach((t) => {
    const tRow = [
      `${t.id}`,
      getReportName(t.reportType ?? 0),
      t.insuranceCompany?.accountnumber ?? '',
      t.employer?.name ?? '',
      t.sendTo ?? '',
      t.employer?.clientEmployerId ?? '',
      displayDateOnly(t.startDate ?? ''),
      displayDateOnly(t.stopDate ?? ''),
      `${t.dayOfMonthToSend}`,
      t.createdBy ?? '',
      displayDateOnly(t.lastRunDate ?? ''),
      `${t.automatedReportingJobLogs?.length ?? 0}`,
    ];

    rows.push(tRow);
  });

  return {
    columns,
    rows,
    fileName: '',
  };
};
