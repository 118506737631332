import React, { useEffect, useState } from 'react';
import {
  Container,
  Modal,
  Tab,
  Tabs,
  Button,
  Form,
  Spinner,
  Alert,
} from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { FaFileExcel } from 'react-icons/fa';
import { Claim } from '../../ApiTypes/Claim';
import { Claimant } from '../../ApiTypes/Claimant';
import { ClaimPaymentTotals } from '../../ApiTypes/ClaimPaymentTotals';
import { ClaimReserveBuckets } from '../../ApiTypes/ClaimReserveBuckets';
import {
  ClaimReservesWorksheet,
  ReserveWorksheetStatus,
} from '../../ApiTypes/ClaimReservesWorksheet';
import { Employer } from '../../ApiTypes/Employer';
import { ReserveWorksheetFormType } from '../../Types/ReservesWorksheetFormType';
import ExpenseTab from './ExpenseTab';
import IndemnityTab from './IndemnityTab';

import styles from './index.module.css';
import LegalTab from './LegalTab';
import MedicalTab from './MedicalTab';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';
import { cleanMoney, makeMoney } from '../../Utils/InputFormatters';
import { format } from 'date-fns';
import { useAppSelector } from '../../Reducers/Store';
import { Adjuster } from '../../ApiTypes/Adjuster';
import BSSelect from '../Common/BSSelect';
import ReservesApi from '../../Api/ReservesApi';
import { toast } from 'react-toastify';
import { ApproveReserveWorksheetRequest } from '../../ApiTypes/ApproveReserveWorksheetRequest';

import { reservesWorksheetCalculator } from './ReserveWorksheetFormDecorator';
import { PtdResponse } from '../../ApiTypes/PtdResponse';
import { FormApi } from 'final-form';

export default function ReserveWorksheetModal({
  show,
  setShow,
  claim,
  claimant,
  employer,
  selectedWorksheet,
  setSelectedWorksheet,
  getClaimReservesWorksheets,
  medicalBucket,
  indemnityBucket,
  legalBucket,
  expenseBucket,
  claimPaymentTotals,
  ptdResponse,
  getReserveBucketsForClaim,
  getClaimPaymentTotals,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  claim: Claim | null;
  claimant: Claimant | null;
  employer: Employer | null;
  selectedWorksheet: ClaimReservesWorksheet | null;
  medicalBucket: ClaimReserveBuckets | null;
  indemnityBucket: ClaimReserveBuckets | null;
  legalBucket: ClaimReserveBuckets | null;
  expenseBucket: ClaimReserveBuckets | null;
  claimPaymentTotals: ClaimPaymentTotals | null;
  ptdResponse: PtdResponse | null;
  setSelectedWorksheet: (sheet: ClaimReservesWorksheet | null) => void;
  getClaimReservesWorksheets: () => void;
  getReserveBucketsForClaim: () => void;
  getClaimPaymentTotals: () => void;
}) {
  const [showMessage, setShowMessage] = useState<JSX.Element | null>(null);
  const [showAdjusterSelect, setShowAdjusterSelect] =
    useState<JSX.Element | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [initialVals, setInitialVals] = useState<
    Partial<ReserveWorksheetFormType>
  >({});

  const { userModel } = useAppSelector((state) => state.user);
  const { adjusters } = useAppSelector((state) => state.reference);

  let formInstance: FormApi<
    ReserveWorksheetFormType,
    Partial<ReserveWorksheetFormType>
  > | null = null;

  useEffect(() => {
    if (selectedWorksheet) {
      handleInitialEditValues();
    } else {
      handleInitialValues();
    }
  }, [
    claim,
    selectedWorksheet,
    ptdResponse,
    medicalBucket,
    legalBucket,
    indemnityBucket,
    expenseBucket,
    userModel,
    formInstance,
  ]);

  const handleInitialValues = () => {
    let DisabilityPTD = ptdResponse?.disabilityPTD ?? 0;
    let MedicalPTD = ptdResponse?.medicalPTD ?? 0;
    let LegalPTD = legalBucket?.paid ?? 0;
    let ExpensePTD = expenseBucket?.paid ?? 0;

    let NewIndemnity = DisabilityPTD;
    let NewMedical = MedicalPTD;
    let NewLegal = LegalPTD;
    let NewExpense = ExpensePTD;

    let PreviousIndemnity = indemnityBucket?.totalIncurred ?? 0;
    let PreviousMedical = medicalBucket?.totalIncurred ?? 0;
    let PreviousLegal = legalBucket?.totalIncurred ?? 0;
    let PreviousExpense = expenseBucket?.totalIncurred ?? 0;

    let CurrentIndemnityReserves = indemnityBucket?.reserves ?? 0;
    let CurrentMedicalReserves = medicalBucket?.reserves ?? 0;
    let CurrentLegalReserves = legalBucket?.reserves ?? 0;
    let CurrentExpenseReserves = expenseBucket?.reserves ?? 0;

    const values: ReserveWorksheetFormType = {
      adjusterName: `${userModel?.adjuster?.firstName ?? ''} ${
        userModel?.adjuster?.lastName ?? ''
      }`,
      totalFuture: '',
      percentageLossTotal: '',
      permanentImpairmentTotal: '',
      tpdTotal: '',
      permanentTotalCR: '',
      indemnityDifference: 0,
      medicalDifference: 0,
      legalDifference: 0,
      expenseDifference: 0,
      newIndemnityIncurred: NewIndemnity,
      newLegalIncurred: NewLegal,
      newExpenseIncurred: NewExpense,
      newMedicalIncurred: NewMedical,
      disabilityPTD: DisabilityPTD,
      medicalPTD: MedicalPTD,
      legalPTD: LegalPTD,
      expensePTD: ExpensePTD,
      indemnityRounded: Math.ceil(DisabilityPTD / 100) * 100,
      medicalRounded: Math.ceil(MedicalPTD / 100) * 100,
      legalRounded: Math.ceil(LegalPTD / 100) * 100,
      expenseRounded: Math.ceil(ExpensePTD / 100) * 100,
      txtPreviousIndemnity: PreviousIndemnity,
      txtPreviousMedical: PreviousMedical,
      txtPreviousLegal: PreviousLegal,
      txtPreviousExpense: PreviousExpense,

      txtCurrentIndemnityReserves: CurrentIndemnityReserves,
      txtCurrentMedicalReserves: CurrentMedicalReserves,
      txtCurrentLegalReserves: CurrentLegalReserves,
      txtCurrentExpenseReserves: CurrentExpenseReserves,

      id: 0,
      claimNo: claim?.claimNo ?? '',
      futureTempTotalExpected: null,
      futureTempTotalExpectedWeeks: null,
      permanantImpairment: null,
      percentageLoss: null,
      percentageLossWeeks: null,
      percentageLossCrtotal: null,
      tpdExposure: null,
      tpdExposureWeeks: null,
      permanentTotal: null,
      permanentTotalWeeks: null,
      deathBenefits: null,
      claimantAttorneyFees: null,
      futureMedicalPhysician: null,
      futureMedicalHospital: null,
      futureMedicalMileage: null,
      futureMedicalMedicalMgmt: null,
      futureMedicalPharmacy: null,
      futureMedicalPhysTherapy: null,
      newlegalReserves: null,
      newExpenseReserves: null,
      remarks: null,
      status: null,
      supervisorId: null,
      approvedBy: null,
      dateSubmitted: null,
      dateApproved: null,
      submittedBy: null,
      medicalRemarks: null,
      legalRemarks: null,
      expenseRemarks: null,

      indemnityRoundedOverride: null,
      medicalRoundedOverride: null,
      legalRoundedOverride: null,
      expenseRoundedOverride: null,

      oldIndemnity: null,
      oldMedical: null,
      oldLegal: null,
      oldExpense: null,
      totalIncurredReserves: null,
      totalReserveDifference: null,
      previouseIndemnityIncurred: null,
      previouseMedicalIncurred: null,
      previouseLegalIncurred: null,
      previouseExpenseIncurred: null,
    };

    setInitialVals(values);
  };

  const handleInitialEditValues = () => {
    let DisabilityPTD = ptdResponse?.disabilityPTD ?? 0;
    let MedicalPTD = ptdResponse?.medicalPTD ?? 0;
    let LegalPTD = legalBucket?.paid ?? 0;
    let ExpensePTD = expenseBucket?.paid ?? 0;

    let NewIndemnity = DisabilityPTD;
    let NewMedical = MedicalPTD;
    let NewLegal = LegalPTD;
    let NewExpense = ExpensePTD;

    let PreviousIndemnity = indemnityBucket?.totalIncurred ?? 0;
    let PreviousMedical = medicalBucket?.totalIncurred ?? 0;
    let PreviousLegal = legalBucket?.totalIncurred ?? 0;
    let PreviousExpense = expenseBucket?.totalIncurred ?? 0;

    let CurrentIndemnityReserves = indemnityBucket?.reserves ?? 0;
    let CurrentMedicalReserves = medicalBucket?.reserves ?? 0;
    let CurrentLegalReserves = legalBucket?.reserves ?? 0;
    let CurrentExpenseReserves = expenseBucket?.reserves ?? 0;

    const values: ReserveWorksheetFormType = {
      adjusterName: `${userModel?.adjuster?.firstName ?? ''} ${
        userModel?.adjuster?.lastName ?? ''
      }`,
      totalFuture: '',
      percentageLossTotal: '',
      permanentImpairmentTotal: '',
      tpdTotal: '',
      permanentTotalCR: '',
      indemnityDifference: 0,
      medicalDifference: 0,
      legalDifference: 0,
      expenseDifference: 0,
      newIndemnityIncurred: 0,
      newLegalIncurred: 0,
      newExpenseIncurred: 0,
      newMedicalIncurred: 0,
      disabilityPTD: DisabilityPTD,
      medicalPTD: MedicalPTD,
      legalPTD: LegalPTD,
      expensePTD: ExpensePTD,
      indemnityRounded: 0,
      medicalRounded: 0,
      legalRounded: 0,
      expenseRounded: 0,
      txtPreviousIndemnity: PreviousIndemnity,
      txtPreviousMedical: PreviousMedical,
      txtPreviousLegal: PreviousLegal,
      txtPreviousExpense: PreviousExpense,
      id: selectedWorksheet!.id,
      claimNo: null,
      futureTempTotalExpected: null,
      futureTempTotalExpectedWeeks: null,
      permanantImpairment: null,
      percentageLoss: null,
      percentageLossWeeks: null,
      percentageLossCrtotal: null,
      tpdExposure: null,
      tpdExposureWeeks: null,
      permanentTotal: null,
      permanentTotalWeeks: null,
      deathBenefits: null,
      claimantAttorneyFees: null,
      futureMedicalPhysician: null,
      futureMedicalHospital: null,
      futureMedicalMileage: null,
      futureMedicalMedicalMgmt: null,
      futureMedicalPharmacy: null,
      futureMedicalPhysTherapy: null,
      newlegalReserves: null,
      newExpenseReserves: null,
      remarks: null,
      status: null,
      supervisorId: null,
      approvedBy: null,
      dateSubmitted: null,
      dateApproved: null,
      submittedBy: null,
      medicalRemarks: null,
      legalRemarks: null,
      expenseRemarks: null,
      indemnityRoundedOverride: null,
      medicalRoundedOverride: null,
      legalRoundedOverride: null,
      expenseRoundedOverride: null,
      oldIndemnity: null,
      oldMedical: null,
      oldLegal: null,
      oldExpense: null,
      totalIncurredReserves: null,
      totalReserveDifference: null,
      previouseIndemnityIncurred: null,
      previouseMedicalIncurred: null,
      previouseLegalIncurred: null,
      previouseExpenseIncurred: null,
      txtCurrentIndemnityReserves: CurrentIndemnityReserves,
      txtCurrentMedicalReserves: CurrentMedicalReserves,
      txtCurrentLegalReserves: CurrentLegalReserves,
      txtCurrentExpenseReserves: CurrentExpenseReserves,
      ...selectedWorksheet,
    };
    setInitialVals(values);
  };

  const onSuccess = () => {
    getClaimReservesWorksheets();
    getReserveBucketsForClaim();
    getClaimPaymentTotals();
  };

  const onSubmit = (values: ReserveWorksheetFormType) => {
    setSubmitting(true);
    const ws = createWS(values);
    ws.status = ReserveWorksheetStatus.SubmittedWaitingForApproval;
    return submitWorksheet(ws as ClaimReservesWorksheet, values);
  };

  const save = async (values: ReserveWorksheetFormType) => {
    setSaving(true);
    const ws = createWS(values);
    ws.status = ReserveWorksheetStatus.InProgress;
    let message = '';
    let message2 = '';
    if (
      (values.totalReserveDifference ?? 0) >
      (userModel?.adjuster?.maximumReserveApproval ?? 0)
    ) {
      message = 'Worksheet will NOT be submitted,';
      message2 = 'would you like to submit it for approval now?';
    } else {
      message =
        'It looks like you are within limits to approve this worksheet. ';
      message2 = ' Would you like to Submit this now?';
    }
    let savedResolve: ((value: boolean | PromiseLike<boolean>) => void) | null =
      null;
    const valueReturned = new Promise<boolean>((resolve, reject) => {
      savedResolve = resolve;
    });
    const alert = (
      <Alert
        variant='info'
        className={`mt-3 ${styles.width800px}`}
        dismissible
        onClose={() => {
          savedResolve && savedResolve(false);
          setShowMessage(null);
        }}
      >
        <p>{message}</p>
        <p>{message2}</p>

        <div className='d-flex justify-content-around'>
          <Button
            type='button'
            onClick={() => {
              savedResolve && savedResolve(true);
              setShowMessage(null);
            }}
            variant='primary'
          >
            Yes
          </Button>
        </div>
      </Alert>
    );

    setShowMessage(alert);

    const res = await valueReturned;

    if (!res) {
      return saveInProgressWs(ws as ClaimReservesWorksheet, true);
    }
    return submitWorksheet(ws as ClaimReservesWorksheet, values);
  };

  const submitWorksheet = async (
    ws: ClaimReservesWorksheet,
    values: ReserveWorksheetFormType
  ) => {
    const saveResponse = await saveInProgressWs(ws, false);
    if (saveResponse === 0) {
      return;
    }

    let supervisorId = 0;
    if (
      (values.totalReserveDifference ?? 0) >
      (userModel?.adjuster?.maximumReserveApproval ?? 0)
    ) {
      const adjustersWhoCanApprove = adjusters?.filter(
        (a) =>
          (a?.maximumReserveApproval ?? 0) >=
          (values.totalReserveDifference ?? 0)
      );
      if (adjustersWhoCanApprove.length > 1) {
        let savedResolve:
          | ((value: boolean | PromiseLike<boolean>) => void)
          | null = null;
        const valueReturned = new Promise<boolean>((resolve, reject) => {
          savedResolve = resolve;
        });
        const alert = (
          <Alert
            variant='info'
            className={`mt-3 ${styles.width800px}`}
            dismissible
            onClose={() => {
              savedResolve && savedResolve(false);
              setShowAdjusterSelect(null);
            }}
          >
            <p>Choose a supervisor</p>
            <BSSelect
              name='supervisorId'
              label='Supervisor'
              onChange={(value: number) => {
                supervisorId = +value;
              }}
              options={adjusters.filter(
                (a) =>
                  (a?.maximumReserveApproval ?? 0) >=
                  (values.totalReserveDifference ?? 0)
              )}
              optionMethod={(options: Adjuster[]) =>
                options.map((o) => (
                  <option key={o.adjusterId} value={o.adjusterId}>
                    {`${o.firstName} ${o.lastName}`}
                  </option>
                ))
              }
            />

            <div className='d-flex justify-content-around'>
              <Button
                type='button'
                onClick={() => {
                  savedResolve && savedResolve(true);
                  setShowAdjusterSelect(null);
                }}
                variant='primary'
              >
                Confirm
              </Button>
            </div>
          </Alert>
        );

        setShowAdjusterSelect(alert);

        const res = await valueReturned;
        if (!res) {
          setSaving(false);
          setSubmitting(false);
          return;
        }
      } else if (adjustersWhoCanApprove.length === 1) {
        supervisorId = adjustersWhoCanApprove[1].adjusterId;
      } else {
        supervisorId = 1;
      }
    }

    const request: ApproveReserveWorksheetRequest = {
      id: saveResponse,
      expenseDifference: values.totalReserveDifference ?? 0,
      supervisorId,
    };

    return ReservesApi.approveClaimReservesWorksheet(
      request,
      claim?.claimNo ?? ''
    )
      .then((res) => {
        setSaving(false);
        setSubmitting(false);
        if (res.data.success) {
          setShowMessage(null);
          setShowAdjusterSelect(null);
          setSelectedWorksheet(null);
          setShow(false);
          onSuccess();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
        setSubmitting(false);
        toast.error('Failed to approve worksheet');
      });
  };

  const saveInProgressWs = (
    ws: ClaimReservesWorksheet,
    closeAfterSuccess: boolean
  ) => {
    if (!ws.id) {
      // save
      return ReservesApi.saveClaimReservesWorksheet(ws)
        .then((res) => {
          if (res.data.success) {
            setShowMessage(null);
            if (closeAfterSuccess) {
              setShowAdjusterSelect(null);
              setSelectedWorksheet(null);
              setShow(false);
              onSuccess();
              setSaving(false);
              setSubmitting(false);
            }
            return +res.data.affectedEntityIdentifier;
          } else {
            setSaving(false);
            setSubmitting(false);
            toast.error(res.data.message);
            return 0;
          }
        })
        .catch((err) => {
          toast.error('Failed to save');
          setSaving(false);
          setSubmitting(false);
          console.log(err);
          return 0;
        });
    }
    // update
    return ReservesApi.updateSavedClaimReservesWorksheet(ws)
      .then((res) => {
        if (res.data.success) {
          setShowMessage(null);
          if (closeAfterSuccess) {
            setShowAdjusterSelect(null);
            setSelectedWorksheet(null);
            setShow(false);
            onSuccess();
            setSaving(false);
            setSubmitting(false);
          }
          return +res.data.affectedEntityIdentifier;
        } else {
          toast.error(res.data.message);
          setSaving(false);
          setSubmitting(false);
          return 0;
        }
      })
      .catch((err) => {
        toast.error('Failed to update');
        setSaving(false);
        setSubmitting(false);
        console.log(err);
        return 0;
      });
  };

  const createWS = (values: ReserveWorksheetFormType) => {
    const ws: Partial<ClaimReservesWorksheet> = {
      futureTempTotalExpected: values.futureTempTotalExpected,
      futureTempTotalExpectedWeeks: values.futureTempTotalExpectedWeeks,
      permanantImpairment: values.permanantImpairment,
      percentageLoss: values.percentageLoss,
      percentageLossWeeks: values.percentageLossWeeks,
      percentageLossCrtotal: values.percentageLossCrtotal,
      tpdExposure: values.tpdExposure,
      tpdExposureWeeks: values.tpdExposureWeeks,
      permanentTotal: values.permanentTotal,
      permanentTotalWeeks: values.permanentTotalWeeks,
      deathBenefits: values.deathBenefits,
      claimantAttorneyFees: values.claimantAttorneyFees,
      indemnityRoundedOverride: values.indemnityRounded,
      futureMedicalPhysician: values.futureMedicalPhysician,
      futureMedicalHospital: values.futureMedicalHospital,
      futureMedicalMileage: values.futureMedicalMileage,
      futureMedicalMedicalMgmt: values.futureMedicalMedicalMgmt,
      futureMedicalPharmacy: values.futureMedicalPharmacy,
      futureMedicalPhysTherapy: values.futureMedicalPhysTherapy,
      medicalRoundedOverride: values.medicalRounded,
      newlegalReserves: values.newlegalReserves,
      legalRoundedOverride: values.legalRounded,
      newExpenseReserves: values.newExpenseReserves,
      expenseRoundedOverride: values.expenseRounded,
      previouseExpenseIncurred: values.txtPreviousExpense,
      previouseIndemnityIncurred: values.txtPreviousIndemnity,
      previouseLegalIncurred: values.txtPreviousLegal,
      previouseMedicalIncurred: values.txtPreviousMedical,
      oldExpense: values.txtCurrentExpenseReserves,
      oldIndemnity: values.txtCurrentIndemnityReserves,
      oldLegal: values.txtCurrentLegalReserves,
      oldMedical: values.txtCurrentMedicalReserves,
      remarks: values.remarks,
      medicalRemarks: values.medicalRemarks,
      legalRemarks: values.legalRemarks,
      expenseRemarks: values.expenseRemarks,
      totalIncurredReserves: values.totalIncurredReserves,
      totalReserveDifference: values.totalReserveDifference,
      claimNo: claim?.claimNo ?? null,
      id: values.id ?? 0,
      approvedBy: values.approvedBy ?? null,
      submittedBy: userModel?.adjuster?.adjusterId ?? null,
      supervisorId: values.supervisorId ?? null,
      dateSubmitted: values.dateSubmitted ?? format(new Date(), 'yyyy-MM-dd'),
      dateApproved: values.dateApproved ?? null,
    };
    return ws;
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
        setSubmitting(false);
        setSaving(false);
        setShowMessage(null);
        setShowAdjusterSelect(null);
        setSelectedWorksheet(null);
        onSuccess();
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='Reserve-worksheet-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Reserve-worksheet-modal'>
          <FaFileExcel className='pe-1' /> Reserve Worksheet
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <div className={styles.headerGrid}>
            <p>
              Claimant: {claimant?.firstName ?? ''} {claimant?.lastName ?? ''}
            </p>
            <p>Employer: {employer?.name ?? ''}</p>
            <p>Claim #: {claim?.claimNo ?? ''}</p>
          </div>
          <RFFForm
            onSubmit={save}
            initialValues={initialVals}
            decorators={[reservesWorksheetCalculator]}
            render={({ handleSubmit, form, values }) => {
              formInstance = form;
              return (
                <Form onSubmit={handleSubmit}>
                  <fieldset>
                    <Tabs
                      defaultActiveKey='indemnity'
                      id='worksheet-tabs'
                      className='mb-3'
                    >
                      <Tab eventKey='indemnity' title='Indemnity'>
                        {claim?.severity === 'MO' ? (
                          <p>Can not add Indemnity on "MO" claims</p>
                        ) : (
                          <IndemnityTab form={form} />
                        )}
                      </Tab>
                      <Tab eventKey='medical' title='Medical'>
                        <MedicalTab form={form} />
                      </Tab>
                      <Tab eventKey='legal' title='Legal'>
                        <LegalTab form={form} />
                      </Tab>
                      <Tab eventKey='expense' title='Expense'>
                        <ExpenseTab form={form} />
                      </Tab>
                    </Tabs>

                    <hr />
                    <div className={`${styles.headerGrid} my-2`}>
                      <Field
                        name='adjusterName'
                        label='Adjuster'
                        type='text'
                        readOnly
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='totalIncurredReserves'
                        label='Total Incurred Reserves'
                        type='text'
                        format={makeMoney}
                        parse={cleanMoney}
                        component={FieldBSRenderMoney}
                        readOnly
                      />
                      <Field
                        name='totalReserveDifference'
                        label='Reserve Difference'
                        type='text'
                        format={makeMoney}
                        parse={cleanMoney}
                        className={
                          (values?.totalReserveDifference ?? 0) >
                          (userModel?.adjuster?.maximumReserveApproval ?? 0)
                            ? 'text-danger'
                            : ''
                        }
                        component={FieldBSRenderMoney}
                        readOnly
                      />
                    </div>
                    {showMessage !== null && showMessage}
                    {showAdjusterSelect !== null && showAdjusterSelect}
                    <div className={`${styles.buttonDiv}`}>
                      <Button type='submit' variant='primary'>
                        {saving ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          'Save'
                        )}
                      </Button>
                      <Button
                        type='button'
                        variant='outline-primary'
                        onClick={() => onSubmit(values)}
                      >
                        {submitting ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          'Submit'
                        )}
                      </Button>
                      <Button
                        type='button'
                        onClick={() => {
                          form.reset();
                          setSubmitting(false);
                          setSaving(false);
                          setShowMessage(null);
                          setShowAdjusterSelect(null);
                          setSelectedWorksheet(null);
                          setShow(false);
                          onSuccess();
                        }}
                        variant='secondary'
                      >
                        Cancel
                      </Button>
                    </div>
                  </fieldset>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
