import React from 'react';
import { Alert, Button, Container, Modal } from 'react-bootstrap';
import { FaUpload } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ExportApi from '../../Api/ExportApi';
import { downloadCsv } from '../../Utils';

export default function MidlandsExport({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const exportMidman = async () => {
    setShow(false);
    try {
      const res = await toast.promise(ExportApi.exportMidlands(), {
        pending: `Exporting`,
        success: 'Success',
        error: `Failed to export data`,
      });
      downloadCsv(res.data.fileName, res.data.file);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='MidlandsExport-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='MidlandsExport-modal'>
          <FaUpload className='pe-1' /> MIDMAN Export
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Alert variant='info' className='text-center'>
            Files will be uploaded
          </Alert>
          <div className='d-flex justify-content-center align-items-center'>
            <Button
              type='button'
              variant='primary'
              size='sm'
              onClick={() => {
                exportMidman();
              }}
            >
              Upload
            </Button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
