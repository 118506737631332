import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import {
  ApproveWc1Request,
  ApproveWc1Response,
} from '../ApiTypes/ApproveWc1Request';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { Wc1 } from '../ApiTypes/Wc1';
import { FileDownload } from '../Types/FileDownLoad';

class Wc1Api {
  static getWc1ForClaim(claimNumber: string) {
    return axios.get<Wc1>(
      `${API_URL}/api/claims/${claimNumber}/wc1`,
      getAuthHeader()
    );
  }
  static updateWc1(wc1: Wc1) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/wc1/${wc1.wc1id}`,
      wc1,
      getAuthHeader()
    );
  }
  static viewWc1(
    maskSsn: boolean,
    showAdjuster: boolean,
    claimNumber: string,
    useClaimData: boolean
  ) {
    let url = '';
    url = `${API_URL}/api/claims/${claimNumber}/wc1s/view?maskSsn=${maskSsn}&showAdjuster=${showAdjuster}&useClaimData=${useClaimData}`;

    return axios.get<FileDownload>(url, getAuthHeader());
  }
  static attachWc1(
    maskSsn: boolean,
    showAdjuster: boolean,
    claimNumber: string,
    useClaimData: boolean
  ) {
    let url = '';
    url = `${API_URL}/api/claims/${claimNumber}/wc1s/attach?maskSsn=${maskSsn}&showAdjuster=${showAdjuster}&useClaimData=${useClaimData}`;

    return axios.post<ResponseApiModel>(url, {}, getAuthHeader());
  }
  static getWc1s(insurCoId: number, wc1Status: number) {
    return axios.get<Wc1[]>(
      `${API_URL}/api/wc1?insurCoId=${insurCoId}&wc1Status=${wc1Status}`,
      getAuthHeader()
    );
  }
  static rejectWc1(wc1Id: number) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/wc1/${wc1Id}/reject`,
      {},
      getAuthHeader()
    );
  }

  static approveWc1(request: ApproveWc1Request) {
    return axios.post<ApproveWc1Response>(
      `${API_URL}/api/wc1/approve`,
      request,
      getAuthHeader()
    );
  }
}

export default Wc1Api;
