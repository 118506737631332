import React from 'react'
import { Table as BSTable } from "react-bootstrap"
import { ScanDocsLogSimple } from '../../../ApiTypes/ScanDocSimple'
import { displayDateOnly } from '../../../Utils'





const ScanDocsLogSimpleTable = (data: ScanDocsLogSimple[]): JSX.Element => {
  const childColumnData = [
    {
      header: "Date Logged",
      accessorKey: "dateLogged",
      cell: (d: string) => displayDateOnly(d),
    },
    {
      header: "Description",
      accessorKey: "description",
    },
    {
      header: "User Name",
      accessorKey: "userName",
    },
    {
      header: "Old Status",
      accessorKey: "oldStatus",
    },
    {
      header: "New Status",
      accessorKey: "newStatus",
    },
  ]

  return (
    <BSTable
      size="sm"
      striped
      bordered
      hover
      style={{
        width: "auto",
        marginLeft: "2rem"
      }}
    >
      <thead >
        <tr>
          {
            childColumnData.map(h => <th key={h.header}>{h.header}</th>)
          }
        </tr>
      </thead>
      <tbody>
        {
          data.map((d, i) => (
            <tr key={i}>
              {
                childColumnData.map(h => {
                  if (!h.cell) {
                    return (
                      <td
                        style={{
                          whiteSpace: "nowrap"
                        }}
                        key={`${h.header}i`}>
                        {d[h.accessorKey as keyof typeof d]}
                      </td>
                    )
                  } else {
                    return (
                      <td
                        style={{
                          whiteSpace: "nowrap"
                        }}
                        key={`${h.header}i`}>
                        {h.cell(d[h.accessorKey as keyof typeof d]!)}
                      </td>
                    )
                  }
                })
              }
            </tr>
          ))
        }
      </tbody>
    </BSTable>
  )
}

export default ScanDocsLogSimpleTable;
