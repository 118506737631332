import React from 'react';
import ReactDOM from 'react-dom/client';
import { store } from './Reducers/Store';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import LoginScreen from './Components/Auth/LoginScreen';
import ErrorPage from './Components/ErrorPage/ErrorPage';
import 'react-toastify/dist/ReactToastify.css';
import RequireAuth from './Components/Auth/RequireAuth';
import EmptyScreen from './Components/EmptyScreen/EmptyScreen';
import { ToastContainer } from 'react-toastify';
import NewClaimRequestsPage from './Components/Pages/NewClaimRequests/NewClaimRequestsPage';
import { SiteFunctionsEnum } from './ApiTypes/SiteFunctions';
import { isAfter, toDate } from 'date-fns';
import { setUserFromToken, fetchCurrentUser } from './Actions';
import { DecodedToken } from './Types/DecodedToken';
import decode from 'jwt-decode';
import SendGemcLossRuns from './Components/SendGemcLossRuns/SendGemcLossRuns';
import EdiHistory from './Components/EdiHistory/EdiHistory';
import NewEdiTransactionPage from './Components/NewEdiSubmission/NewEdiTransactionPage';
import NcmDashboard from './Components/NcmDashboard/NcmDashboard';
import ClaimStatisticsPage from './Components/ClaimStatistics/ClaimStatisticsPage';
import ClaimsSummaryPage from './Components/ClaimsSummary/ClaimsSummary';
import ApproveReserves from './Components/ApproveReserves/ApproveReserves';
import CompIqStats from './Components/CompIqStats/CompIqStats';
import ExcessDetail from './Components/ExcessDetail/ExcessDetail';
import Medicare from './Components/Medicare/Medicare';
import MedicareClaimResultPage from './Components/MedicareClaimResult/MedicareClaimResultPage';
import Productivity from './Components/Productivity/Productivity';
import MyDocuments from './Components/MyDocuments/MyDocuments';
import ScanningCentral from './Components/ScanningCentral/ScanningCentral';
import StraightPay from './Components/StraightPay/StraightPay';
import CompCompleteProcessing from './Components/CompCompleteProcessing/CompCompleteProcessing';
import CompCompleteExceptions from './Components/CompCompleteExceptions/CompCompleteExceptions';
import EorProcessing from './Components/EorProcessing/EorProcessing';
import NcmDocuments from './Components/NcmDocuments/NcmDocuments';
import ManagerDashboard from './Components/ManagerDashboard/ManagerDashboard';
import MyRecentActivities from './Components/MyRecentActivities/MyRecentActivities';
import TurnAroundAvgs from './Components/TurnAroundAvgs/TurnAroundAvgs';
import DocumentNotesRequest from './Components/DocumentNotesRequest/DocumentNotesRequest';
import SharedFilesPage from './Components/SecureShare/SharedFilesPage';
import ApprovePayments from './Components/ApprovePayments/ApprovePayments';
import SecondaryApproval from './Components/SecondaryApproval/SecondaryApproval';
import CheckBatches from './Components/CheckBatches/CheckBatches';
import ViewCheckBatches from './Components/ViewCheckBatches/ViewCheckBatches';
import Checks from './Components/Checks/Checks';
import ImportExceptions from './Components/ImportExceptions/ImportExceptions';
import CompIqEomFees from './Components/CompIqEomFees/CompIqEomFees';
import ProcessRecurringPayments from './Components/ProcessRecurringPayments/ProcessRecurringPayments';
import PaymentEntry from './Components/PaymentEntry/PaymentEntry';
import ManualCheck from './Components/ManualCheck/ManualCheck';
import PaymentsEnteredToday from './Components/ReviewPaymentsEnteredToday/PaymentsEnteredToday';
import ReprintChecks from './Components/ReprintChecks/ReprintChecks';
import DeductiblePayments from './Components/DeductiblePayments/DeductiblePayments';
import ReconcileChecks from './Components/ReconcileChecks/ReconcileChecks';
import ImportCheckNumbers from './Components/ImportCheckNumbers/ImportCheckNumbers';
import ApproveSettlements from './Components/ApproveSettlements/ApproveSettlements';
import ProviderMaintenance from './Components/ProviderMaintenance/ProviderMaintenance';
import AddEditProvider from './Components/AddEditProvider/AddEditProvider';
import LawFirms from './Components/LawFirms/LawFirms';
import Attorneys from './Components/Attorneys/Attorneys';
import ProviderCleanup from './Components/ProviderCleanup/ProviderCleanup';
import WCPanels from './Components/WCPanels/WCPanels';
import WCPanelAddEdit from './Components/WcPanelAddEdit/WCPanelAddEdit';
import PanelApproval from './Components/PanelApproval/PanelApproval';
import PanelExceptions from './Components/PanelExceptions/PanelExceptions';
import Reminders from './Components/Reminders/Reminders';
import InsuranceCompanyMaintenance from './Components/InsuranceCompanyMaintenance/InsuranceCompanyMaintenance';
import EmployerMaintenance from './Components/EmployerMaintenance/EmployerMaintenance';
import DepartmentMaintenance from './Components/DeparmentsMaintenance/DepartmentMaintenance';
import PoliciesMaintenance from './Components/PoliciesMaintenance/PoliciesMaintenance';
import AddEditPolicy from './Components/PoliciesMaintenance/AddEditPolicy';
import ExcessCarriersMaintenance from './Components/ExcessCarriersMaintenance/ExcessCarriersMaintenance';
import ExcessPolicies from './Components/ExcessPolicies/ExcessPolicies';
import UserMaintenance from './Components/UserMaintenance/UserMaintenance';
import AddEditUser from './Components/AddEditUser/AddEditUser';
import EditUserAccess from './Components/EditUserAccess/EditUserAccess';
import VerifyUserPermissions from './Components/VerifyUserPermissions/VerifyUserPermissions';
import SiteFunctionGroupSetup from './Components/SiteFunctionGroupSetup/SiteFunctionGroupSetup';
import SyncUWPolicies from './Components/SyncUWPolicies/SyncUWPolicies';
import PremiumHistory from './Components/PremiumHistory/PremiumHistory';
import PanelList from './Components/Panels/PanelList';
import Panel from './Components/Panels/Panel';
import DoctorLocations from './Components/Panels/DoctorLocations';
import LocationEdit from './Components/Panels/LocationEdit';
import AddDoctorsOffice from './Components/Panels/AddDoctorsOffice';
import PbmList from './Components/Pbms/PbmList';
import AddEditPbm from './Components/Pbms/AddEditPbm';
import LetterMaintenance from './Components/LetterMaintenance/LetterMaintenance';
import NoteTypesMaintenance from './Components/NoteTypes/NoteTypes';
import ManageEmails from './Components/ManageEmails/ManageEmails';
import EdiLog from './Components/EdiLog/EdiLog';
import UserLogs from './Components/UserLogs/UserLogs';
import InsuranceCompanyLogs from './Components/InsuranceCompanyLogs/InsuranceCompanyLogs';
import AutomatedReporting from './Components/AutomatedReporting/AutomatedReporting';
import AdjusterDiaryReport from './Components/AdjusterDiaryReport/AdjusterDiaryReport';
import DocViewPage from './Components/DocViewPage/DocViewPage';
import BaseClaim from './Components/BaseClaim/BaseClaim';
import { ClaimTypes, ClaimTypesURL } from './ApiTypes/ClaimTypeConfiguration';
import EditClaimTypeAccess from './Components/EditClaimTypeAccess/EditClaimTypeAccess';

const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginScreen />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/',
    element: (
      <RequireAuth>
        <EmptyScreen />
      </RequireAuth>
    ),
  },
  {
    path: `/${ClaimTypesURL[ClaimTypes.WorkersComp]}/*`,
    element: (
      <RequireAuth claimTypeAccess={ClaimTypes.WorkersComp}>
        <BaseClaim claimTypeId={ClaimTypes.WorkersComp} />
      </RequireAuth>
    ),
  },
  {
    path: `/${ClaimTypesURL[ClaimTypes.WorkersComp]}/:claimNumber/*`,
    element: (
      <RequireAuth claimTypeAccess={ClaimTypes.WorkersComp}>
        <BaseClaim claimTypeId={ClaimTypes.WorkersComp} />
      </RequireAuth>
    ),
  },
  {
    path: '/scandocs/:id',
    element: (
      <RequireAuth>
        <DocViewPage />
      </RequireAuth>
    ),
  },
  {
    path: '/pendingscandocs/:id',
    element: (
      <RequireAuth>
        <DocViewPage />
      </RequireAuth>
    ),
  },
  {
    path: '/new-claim-requests',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.NewClaimRequest}>
        <NewClaimRequestsPage />
      </RequireAuth>
    ),
  },
  {
    path: `/${ClaimTypesURL[ClaimTypes.GeneralLiability]}/*`,
    element: (
      <RequireAuth claimTypeAccess={ClaimTypes.GeneralLiability}>
        <BaseClaim claimTypeId={ClaimTypes.GeneralLiability} />
      </RequireAuth>
    ),
  },
  {
    path: `/${ClaimTypesURL[ClaimTypes.GeneralLiability]}/:claimNumber/*`,
    element: (
      <RequireAuth claimTypeAccess={ClaimTypes.GeneralLiability}>
        <BaseClaim claimTypeId={ClaimTypes.GeneralLiability} />
      </RequireAuth>
    ),
  },
  {
    path: `/${ClaimTypesURL[ClaimTypes.AutomobileLiability]}/*`,
    element: (
      <RequireAuth claimTypeAccess={ClaimTypes.AutomobileLiability}>
        <BaseClaim claimTypeId={ClaimTypes.AutomobileLiability} />
      </RequireAuth>
    ),
  },
  {
    path: `/${ClaimTypesURL[ClaimTypes.AutomobileLiability]}/:claimNumber/*`,
    element: (
      <RequireAuth claimTypeAccess={ClaimTypes.AutomobileLiability}>
        <BaseClaim claimTypeId={ClaimTypes.AutomobileLiability} />
      </RequireAuth>
    ),
  },
  {
    path: '/send-gemc-loss-runs',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.SendGEMCLossRunsReports}>
        <SendGemcLossRuns />
      </RequireAuth>
    ),
  },
  {
    path: '/edi-history',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.EDIReports}>
        <EdiHistory />
      </RequireAuth>
    ),
  },
  {
    path: '/add-edi/:claimNumber',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.EDIReports}>
        <NewEdiTransactionPage />
      </RequireAuth>
    ),
  },
  {
    path: '/ncm-dashboard',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.NCMReports}>
        <NcmDashboard />
      </RequireAuth>
    ),
  },
  {
    path: '/adjuster-statistics',
    element: (
      <RequireAuth>
        <ClaimStatisticsPage />
      </RequireAuth>
    ),
  },
  {
    path: '/adjuster-statistics/:userId',
    element: (
      <RequireAuth>
        <ClaimStatisticsPage />
      </RequireAuth>
    ),
  },
  {
    path: '/adjuster-summary',
    element: (
      <RequireAuth>
        <ClaimsSummaryPage />
      </RequireAuth>
    ),
  },
  {
    path: '/approve-reserves',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ApproveReserves}>
        <ApproveReserves />
      </RequireAuth>
    ),
  },
  {
    path: '/compiqstats',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.CompCompleteReport}>
        <CompIqStats />
      </RequireAuth>
    ),
  },
  {
    path: '/excess-detail',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ExcessDetailReport}>
        <ExcessDetail />
      </RequireAuth>
    ),
  },
  {
    path: '/medicare-report',
    element: (
      <RequireAuth>
        <Medicare />
      </RequireAuth>
    ),
  },
  {
    path: '/medicare-results',
    element: (
      <RequireAuth>
        <MedicareClaimResultPage />
      </RequireAuth>
    ),
  },
  {
    path: '/productivity',
    element: (
      <RequireAuth>
        <Productivity />
      </RequireAuth>
    ),
  },
  {
    path: '/my-documents',
    element: (
      <RequireAuth>
        <MyDocuments />
      </RequireAuth>
    ),
  },
  {
    path: '/scanning-central',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ScaningCentral}>
        <ScanningCentral />
      </RequireAuth>
    ),
  },
  {
    path: '/straight-pay',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.StraightPay}>
        <StraightPay />
      </RequireAuth>
    ),
  },
  {
    path: '/comp-complete-processing',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.CompIQ}>
        <CompCompleteProcessing />
      </RequireAuth>
    ),
  },
  {
    path: '/comp-complete-exceptions',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.CompIQ}>
        <CompCompleteExceptions />
      </RequireAuth>
    ),
  },
  {
    path: '/eor-processing',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.EORProcessing}>
        <EorProcessing />
      </RequireAuth>
    ),
  },
  {
    path: '/ncm-documents',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.NCMDocuments}>
        <NcmDocuments />
      </RequireAuth>
    ),
  },
  {
    path: '/manager-dashboard',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ManagerDashboard}>
        <ManagerDashboard />
      </RequireAuth>
    ),
  },
  {
    path: '/recent-activity',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ScannedDocuments}>
        <MyRecentActivities />
      </RequireAuth>
    ),
  },
  {
    path: '/turn-around',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.TurnaroundAverages}>
        <TurnAroundAvgs />
      </RequireAuth>
    ),
  },
  {
    path: '/notes-requests',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.DocNotesRequests}>
        <DocumentNotesRequest />
      </RequireAuth>
    ),
  },
  {
    path: '/secure-shares',
    element: (
      <RequireAuth>
        <SharedFilesPage />
      </RequireAuth>
    ),
  },
  {
    path: '/payments-approve',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ApprovePayments}>
        <ApprovePayments />
      </RequireAuth>
    ),
  },
  {
    path: '/secondary-approval',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.SecondaryApproval}>
        <SecondaryApproval />
      </RequireAuth>
    ),
  },
  {
    path: '/check-batches',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.CreatePaymentBatch}>
        <CheckBatches />
      </RequireAuth>
    ),
  },
  {
    path: '/view-payment-batches',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ViewPaymentBatches}>
        <ViewCheckBatches />
      </RequireAuth>
    ),
  },
  {
    path: '/view-checks',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ViewChecksNew}>
        <Checks />
      </RequireAuth>
    ),
  },
  {
    path: '/import-exceptions',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ViewImportExceptions}>
        <ImportExceptions />
      </RequireAuth>
    ),
  },
  {
    path: '/compiq-eomfees',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.CompIQEOMFees}>
        <CompIqEomFees />
      </RequireAuth>
    ),
  },
  {
    path: '/process-recurring',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ProcessRecurringPayments}>
        <ProcessRecurringPayments />
      </RequireAuth>
    ),
  },
  {
    path: '/enter-payment',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.EnterPayments}>
        <PaymentEntry />
      </RequireAuth>
    ),
  },
  {
    path: '/enter-reimbursement',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.EnterPayments}>
        <PaymentEntry txType='REIMBURSEMENT' />
      </RequireAuth>
    ),
  },
  {
    path: '/adjustment-entry',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.AdjustmentEntry}>
        <ManualCheck />
      </RequireAuth>
    ),
  },
  {
    path: '/todays-payments',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ReviewPaymentsEnteredToday}>
        <PaymentsEnteredToday />
      </RequireAuth>
    ),
  },
  {
    path: '/reprint-checks',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.RePrintChecks}>
        <ReprintChecks />
      </RequireAuth>
    ),
  },
  {
    path: '/deductible-payments',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.EnterDeductiblePayments}>
        <DeductiblePayments />
      </RequireAuth>
    ),
  },
  {
    path: '/reconcile-checks',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ReconcileChecks}>
        <ReconcileChecks />
      </RequireAuth>
    ),
  },
  {
    path: '/check-number-import',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ImportCheckNumbers}>
        <ImportCheckNumbers />
      </RequireAuth>
    ),
  },
  {
    path: '/approve-settlements',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ApproveSettlements}>
        <ApproveSettlements />
      </RequireAuth>
    ),
  },
  {
    path: '/provider-maintenance',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ProviderMaintenance}>
        <ProviderMaintenance />
      </RequireAuth>
    ),
  },
  {
    path: '/provider-maintenance/:providerId',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ProviderMaintenance}>
        <AddEditProvider />
      </RequireAuth>
    ),
  },
  {
    path: '/lawfirms',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.LawFirms}>
        <LawFirms />
      </RequireAuth>
    ),
  },
  {
    path: '/lawfirms/:firmId/attorneys',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.LawFirms}>
        <Attorneys />
      </RequireAuth>
    ),
  },
  {
    path: '/provider-cleanup',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ProviderCleanup}>
        <ProviderCleanup />
      </RequireAuth>
    ),
  },
  {
    path: '/wcpanels',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ViewPanels}>
        <WCPanels />
      </RequireAuth>
    ),
  },
  {
    path: '/wcpanels/:panelId',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ViewPanels}>
        <WCPanelAddEdit />
      </RequireAuth>
    ),
  },
  {
    path: '/wcpanel-approvals',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ViewPanels}>
        <PanelApproval />
      </RequireAuth>
    ),
  },
  {
    path: '/wcpanel-exceptions',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ViewPanels}>
        <PanelExceptions />
      </RequireAuth>
    ),
  },
  {
    path: '/reminders',
    element: (
      <RequireAuth>
        <Reminders />
      </RequireAuth>
    ),
  },
  {
    path: '/insurance-companies/*',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.TableMaintenance}>
        <InsuranceCompanyMaintenance />
      </RequireAuth>
    ),
  },
  {
    path: '/employers/*',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.TableMaintenance}>
        <EmployerMaintenance />
      </RequireAuth>
    ),
  },
  {
    path: '/departments',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.Departments}>
        <DepartmentMaintenance />
      </RequireAuth>
    ),
  },
  {
    path: '/policies',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.Policies}>
        <PoliciesMaintenance />
      </RequireAuth>
    ),
  },
  {
    path: '/policies/:policyId',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.Policies}>
        <AddEditPolicy />
      </RequireAuth>
    ),
  },
  {
    path: '/excess-carriers',
    element: (
      <RequireAuth>
        <ExcessCarriersMaintenance />
      </RequireAuth>
    ),
  },
  {
    path: '/excess-carriers/:excessCarrierId/excess-policies',
    element: (
      <RequireAuth>
        <ExcessPolicies />
      </RequireAuth>
    ),
  },
  {
    path: '/users',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.Users}>
        <UserMaintenance />
      </RequireAuth>
    ),
  },
  {
    path: '/users/:userId/*',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.Users}>
        <AddEditUser />
      </RequireAuth>
    ),
  },
  {
    path: '/useraccess/:userId',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.Users}>
        <EditUserAccess />
      </RequireAuth>
    ),
  },
  {
    path: '/claimtypeaccess/:userId',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.Users}>
        <EditClaimTypeAccess />
      </RequireAuth>
    ),
  },
  {
    path: '/user-permissions',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.Users}>
        <VerifyUserPermissions />
      </RequireAuth>
    ),
  },
  {
    path: '/sitefunction-setup',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.SiteFunctionGroupSetup}>
        <SiteFunctionGroupSetup />
      </RequireAuth>
    ),
  },
  {
    path: '/uwsync',
    element: (
      <RequireAuth>
        <SyncUWPolicies />
      </RequireAuth>
    ),
  },
  {
    path: '/premium-history',
    element: (
      <RequireAuth>
        <PremiumHistory />
      </RequireAuth>
    ),
  },
  {
    path: '/panels',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ViewPanels}>
        <PanelList />
      </RequireAuth>
    ),
  },
  {
    path: '/panels/:panelId',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ViewPanels}>
        <Panel />
      </RequireAuth>
    ),
  },
  {
    path: '/panels/:panelId/add-locations',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ViewPanels}>
        <DoctorLocations />
      </RequireAuth>
    ),
  },

  {
    path: '/doctorlocations/:id',
    element: (
      <RequireAuth>
        <LocationEdit />
      </RequireAuth>
    ),
  },
  {
    path: '/doctorsoffices/new',
    element: (
      <RequireAuth>
        <AddDoctorsOffice />
      </RequireAuth>
    ),
  },
  {
    path: '/pbms',
    element: (
      <RequireAuth>
        <PbmList />
      </RequireAuth>
    ),
  },
  {
    path: '/pbms/:pbmId',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.TableMaintenance}>
        <AddEditPbm />
      </RequireAuth>
    ),
  },
  {
    path: '/letters',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.TableMaintenance}>
        <LetterMaintenance />
      </RequireAuth>
    ),
  },
  {
    path: '/notetypes',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.TableMaintenance}>
        <NoteTypesMaintenance />
      </RequireAuth>
    ),
  },
  {
    path: '/emails',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.TableMaintenance}>
        <ManageEmails />
      </RequireAuth>
    ),
  },
  {
    path: '/edilogs',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.EDILog}>
        <EdiLog />
      </RequireAuth>
    ),
  },
  {
    path: '/userlogs',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.TableMaintenance}>
        <UserLogs />
      </RequireAuth>
    ),
  },
  {
    path: '/insurance-company-logs',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.TableMaintenance}>
        <InsuranceCompanyLogs />
      </RequireAuth>
    ),
  },
  {
    path: '/automated-reporting',
    element: (
      <RequireAuth siteFunction={SiteFunctionsEnum.ScheduleLossRunsExternal}>
        <AutomatedReporting />
      </RequireAuth>
    ),
  },
  {
    path: '/adjuster-diary-report',
    element: (
      <RequireAuth>
        <AdjusterDiaryReport />
      </RequireAuth>
    ),
  },

  {
    path: '*',
    element: <ErrorPage />,
  },
]);

const token = localStorage.getItem('gasToken');
if (token) {
  const decoded: DecodedToken = decode(token);
  const now = new Date();
  store.dispatch(
    setUserFromToken({
      userName:
        decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
      exp: decoded.exp,
    })
  );
  const isValid = isAfter(toDate(decoded.exp * 1000), now);
  if (isValid) {
    store.dispatch(fetchCurrentUser());
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <RouterProvider router={router} />
    <ToastContainer
      position='bottom-center'
      autoClose={2000}
      limit={3}
      closeOnClick
      theme='dark'
    />
    {/* </React.StrictMode> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
