import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleError } from ".";
import EmployerApi from "../Api/EmployerApi";
import { EmployerSearchRequest } from "../ApiTypes/EmployerSearchRequest";

export const fetchEmployerById = createAsyncThunk(
  "FETCH_EMPLOYER_BY_ID",
  async (id: number, thunkApi) => {
    try {
      const response = await EmployerApi.getEmployerById(id);
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const fetchEmployerDepartments = createAsyncThunk(
  "FETCH_EMPLOYER_DEPARTMENTS",
  async (id: number, thunkApi) => {
    try {
      const response = await EmployerApi.getEmployerDepartments(id);
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const fetchEmployerPolicies = createAsyncThunk(
  "FETCH_EMPLOYER_POLICIES",
  async (id: number, thunkApi) => {
    try {
      const response = await EmployerApi.getEmployerPolicies(id);
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const fetchEmployerLocationsByPolicyId = createAsyncThunk(
  "FETCH_EMPLOYER_LOCATIONS_BY_POLICY_ID",
  async ({ employerId, policyId }: { employerId: number, policyId: number }, thunkApi) => {
    try {
      const response = await EmployerApi.getEmployerLocationsByPolicyId(employerId, policyId);
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchEmployerList = createAsyncThunk(
  "FETCH_EMPLOYER_LIST",
  async (search: EmployerSearchRequest, thunkApi) => {
    try {
      const response = await EmployerApi.getEmployerList(search);
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);