import React, { useState, useEffect } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { Form as RFFForm, Field } from 'react-final-form';
import { FaMoneyCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ChecksApi from '../../Api/ChecksApi';
import { CheckDocuments } from '../../ApiTypes/CheckDocuments';

import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { ReprintCheckRequest } from '../../ApiTypes/ReprintCheckRequest';
import { useAppSelector } from '../../Reducers/Store';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import DocViewModal from '../DocViewModal/DocViewModal';
import { useDocViewModal } from '../DocViewModal/useDocViewModal';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';

export default function ReprintChecks() {
  const { insuranceCompanies } = useAppSelector((state) => state.reference);
  const [eorSubmitting, setEorSubmitting] = useState<boolean>(false);
  const [reprintSubmitting, setReprintSubmitting] = useState<boolean>(false);
  const [data, setData] = useState<CheckDocuments | null>(null);

  const {
    showDocViewModal,
    fileDownload,
    setShowDocViewModal,
    setFileDownload,
  } = useDocViewModal();

  useEffect(() => {
    return () => {
      setData(null);
    };
  }, []);

  const onSubmit = (values: ReprintCheckRequest) => {
    ChecksApi.reprintChecks(values)
      .then((res) => {
        if (!res.data.checks && !res.data.eors) {
          toast.info('Nothing Returned');
        } else {
          setData(res.data);
        }
        stopSubmitting();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data ?? 'Failed to process the request');
        stopSubmitting();
      });
  };

  const stopSubmitting = () => {
    setEorSubmitting(false);
    setReprintSubmitting(false);
  };

  const eorOnly = (values: ReprintCheckRequest) => {
    values.eorOnly = true;
    setEorSubmitting(true);
    onSubmit(values);
  };

  const reprint = (values: ReprintCheckRequest) => {
    values.eorOnly = false;
    setReprintSubmitting(true);
    onSubmit(values);
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaMoneyCheck className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Re-Print Batch</h1>
          </div>
        </div>
      </div>
      <>
        {data !== null && (
          <Alert variant='info' dismissible onClose={() => setData(null)}>
            <div className='d-flex justify-content-center align-items-center gap1Rem'>
              {data.eors !== null && (
                <Button
                  variant='outline-primary'
                  size='sm'
                  onClick={() => {
                    setFileDownload({
                      file: data.eors ?? '',
                      fileName: 'eors.pdf',
                    });
                    setShowDocViewModal(true);
                  }}
                >
                  View EORs
                </Button>
              )}
              {data.checks && (
                <Button
                  variant='outline-primary'
                  size='sm'
                  onClick={() => {
                    setFileDownload({
                      file: data.checks ?? '',
                      fileName: 'checks.rpt',
                    });
                    setShowDocViewModal(true);
                  }}
                >
                  View Checks
                </Button>
              )}
            </div>
          </Alert>
        )}
      </>
      <RFFForm
        onSubmit={(values: ReprintCheckRequest) => {}}
        initialValues={{
          amount: 0,
        }}
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className={styles.grid3}></div>
              <div className={styles.grid3}>
                <Field
                  name='checkNumber'
                  type='text'
                  label='Check Number'
                  validate={requiredField}
                  component={FieldBSRenderText}
                />
                <Field
                  name='accountNumber'
                  label='Account'
                  options={insuranceCompanies}
                  optionMethod={(options: InsuranceCompany[]) =>
                    options.map((o) => (
                      <option
                        key={o.accountnumber}
                        value={o.accountnumber ?? ''}
                      >
                        {`${o.accountnumber} - ${o.shortname}`}
                      </option>
                    ))
                  }
                  validate={requiredField}
                  component={FieldBSRenderSelect}
                />
                <p className={styles.or}>-OR-</p>
              </div>
              <div className={styles.grid3}>
                <div />
                <Field
                  name='batchNumber'
                  type='text'
                  label='Batch Number'
                  component={FieldBSRenderText}
                />

                <Field
                  name='approvalCode'
                  type='text'
                  label='Approval Code'
                  component={FieldBSRenderText}
                />
              </div>
              <div className='d-flex justify-content-center align-items-center gap1Rem py-3'>
                <Button
                  type='button'
                  size='sm'
                  variant='primary'
                  onClick={() => {
                    eorOnly(values);
                  }}
                >
                  {eorSubmitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'EOR Only'
                  )}
                </Button>
                <Button
                  type='button'
                  size='sm'
                  variant='primary'
                  onClick={() => {
                    reprint(values);
                  }}
                >
                  {reprintSubmitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Re-Print'
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      />
      <DocViewModal
        show={showDocViewModal}
        setShow={setShowDocViewModal}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
    </PageScaffold>
  );
}
