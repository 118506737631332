import React, { useState, useEffect } from 'react';
import {
  Accordion,
  Alert,
  Button,
  Container,
  Form,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { FaEdit } from 'react-icons/fa';
import styles from './index.module.css';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import {
  displayDateOnly,
  parseDatesForServer,
  setAddressTextString,
} from '../../Utils';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import ProviderLookUpForm from '../ProviderLookup/ProviderLookUpForm';
import { ClaimReserveBuckets } from '../../ApiTypes/ClaimReserveBuckets';
import { useAppSelector } from '../../Reducers/Store';
import { Address } from '../../ApiTypes/Address';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import accounting from 'accounting';
import ProviderApi from '../../Api/ProviderApi';
import { ProviderSearchResult } from '../../ApiTypes/ProviderSearchResult';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { PayCodeItem } from '../../ApiTypes/PayCodeItem';
import {
  centsValidation,
  composeValidators,
  requiredField,
} from '../../Utils/FieldValidation';
import { FormApi } from 'final-form';
import { toast } from 'react-toastify';
import { DuplicateTransactionRequest } from '../../ApiTypes/DuplicateTransactionRequest';
import PaymentsApi from '../../Api/PaymentsApi';
import { useParams } from 'react-router-dom';
import ReservesApi from '../../Api/ReservesApi';
import { ClaimPaymentTotals } from '../../ApiTypes/ClaimPaymentTotals';
import { format } from 'date-fns';
import { cleanMoney, formatNumbers } from '../../Utils/InputFormatters';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';
import { SaveGemcexcessTransaction } from '../../ApiTypes/SaveGemcexcessTransaction';
import RecoveryApi from '../../Api/RecoveryApi';

export default function EditCreateRecovery({
  show,
  setShow,
  selectedPayment,
  getTransactionsForClaim,
  getReservesForClaim,
}: {
  show: boolean;
  selectedPayment: SaveGemcexcessTransaction | null;
  setShow: (show: boolean) => void;
  getTransactionsForClaim: () => void;
  getReservesForClaim: () => void;
}) {
  let { claimNumber } = useParams();

  const [fBucketText, setFBucketText] = useState<string>('');
  const [reservesCushionText, setReservesCushionText] = useState<string>('');
  const [claimReserveBuckets, setClaimReserveBuckets] = useState<
    ClaimReserveBuckets[]
  >([]);
  const [claimPaymentTotals, setClaimPaymentTotals] =
    useState<ClaimPaymentTotals | null>(null);

  const [claimantAddress, setClaimantAddress] = useState<string>('');
  const [providerAddress, setProviderAddress] = useState<string>('');
  const [checkPayableTo, setCheckPayableTo] = useState<string>('');

  const [activeKey, setActiveKey] = useState<AccordionEventKey>('');
  const [selectedPaymentProvider, setSelectedPaymentProvider] =
    useState<ProviderSearchResult | null>(null);
  const [selectedSearchProvider, setSelectedSearchProvider] =
    useState<ProviderSearchResult | null>(null);
  const [selectedPayCode, setSelectedPayCode] = useState<PayCodeItem | null>(
    null
  );
  const [messageText, setMessageText] = useState<string>('');
  const [payCodeWarningMessage, setPayCodeWarningMessage] =
    useState<string>('');

  const [allowPayCodeChange, setAllowPayCodeChange] = useState<boolean>(false);
  const [disableAllButPayCode, setDisableAllButPayCode] =
    useState<boolean>(false);
  const [allowPayeeEnabled, setAllowPayeeEnabled] = useState<boolean>(true);
  const [allowCheckEnabled, setAllowCheckEnabled] = useState<boolean>(true);
  const [allowSaveNewEnabled, setAllowSaveNewEnabled] = useState<boolean>(true);
  const [allowSaveEnabled, setAllowSaveEnabled] = useState<boolean>(true);
  const [saveButtonText, setSaveButtonText] =
    useState<string>('Save and Close');
  const [serviceFromEnabled, setServiceFromEnabled] = useState<boolean>(true);
  const [serviceToEnabled, setServiceToEnabled] = useState<boolean>(true);
  const [allowDeleteEnabled, setAllowDeleteEnabled] = useState<boolean>(true);
  const [documentControlNumberEnabled, setDocumentControlNumberEnabled] =
    useState<boolean>(true);

  const [showCheckDate, setShowCheckDate] = useState<boolean>(true);
  const [showCheckNumber, setShowCheckNumber] = useState<boolean>(true);
  const [reserveAmt, setReserveAmt] = useState<number>(0);

  const [
    showNonAdminAmountGreaterThanReserves,
    setShowNonAdminAmountGreaterThanReserves,
  ] = useState<JSX.Element | null>(null);
  const [
    showAdminAmountGreaterThanReserves,
    setShowAdminAmountGreaterThanReserves,
  ] = useState<JSX.Element | null>(null);
  const [showDuplicateWarning, setShowDuplicateWarning] =
    useState<JSX.Element | null>(null);
  const [showRefundGreaterWarning, setShowRefundGreaterWarning] =
    useState<JSX.Element | null>(null);
  const [showRecoveryIsMoreThanBucket, setShowRecoveryIsMoreThanBucket] =
    useState<JSX.Element | null>(null);
  const [showIndemnityNotLostTimeWarning, setShowIndemnityNotLostTimeWarning] =
    useState<JSX.Element | null>(null);

  const [submittingNew, setSubmittingNew] = useState<boolean>(false);
  const [submittingSaveCancel, setSubmittingSaveCancel] =
    useState<boolean>(false);

  let formInstance: FormApi<
    SaveGemcexcessTransaction,
    Partial<SaveGemcexcessTransaction>
  >;

  const { claim, claimant } = useAppSelector(
    (state) => state.currentClaimReducer
  );

  const { excessPayCodes: payCodes } = useAppSelector(
    (state) => state.reference
  );

  const { userName, userModel } = useAppSelector((state) => state.user);

  useEffect(() => {
    init();
  }, [selectedPayment, claim, claimant]);

  useEffect(() => {
    getFBucketText();
    checkTxType();
  }, [claimReserveBuckets, selectedPayment]);

  const onSubmit = (values: SaveGemcexcessTransaction) => {
    // console.log("ONSUBMIT", values)
  };

  const getReserveBucketsForClaim = () => {
    if (claimNumber) {
      return ReservesApi.getClaimReserveBucketsForClaim(claimNumber)
        .then((res) => {
          setClaimReserveBuckets(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setClaimReserveBuckets([]);
      return Promise.resolve();
    }
  };

  const getClaimPaymentTotals = () => {
    if (claimNumber) {
      ReservesApi.getClaimPaymentTotals(claimNumber)
        .then((res) => {
          setClaimPaymentTotals(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      setClaimPaymentTotals(null);
    }
  };

  const init = async () => {
    getReservesCushionText();
    getClaimantAddress();
    await getReserveBucketsForClaim();
    getClaimPaymentTotals();
    if (selectedPayment) {
      initExisting(selectedPayment);
    } else {
      getFBucketText();
      initNew();
    }
  };

  const disableAll = () => {
    setAllowPayeeEnabled(false);
    setAllowCheckEnabled(false);
    setAllowSaveNewEnabled(false);
    setAllowSaveEnabled(false);
    setServiceFromEnabled(false);
    setServiceToEnabled(false);
    setAllowDeleteEnabled(false);
  };
  const enableAll = () => {
    setAllowPayeeEnabled(true);
    setAllowCheckEnabled(true);
    setAllowSaveNewEnabled(true);
    setAllowSaveEnabled(true);
    setServiceFromEnabled(true);
    setServiceToEnabled(true);
    setAllowDeleteEnabled(true);
  };

  const initExisting = (t: SaveGemcexcessTransaction | null) => {
    if (t) {
      getFBucketText();
      if (t?.payee?.toUpperCase() === 'P') {
        getProvider();
      }
      setDocumentControlNumberEnabled(false);

      if (t?.payee?.toUpperCase() === 'C') {
        setCheckPayableTo(`${claimant?.firstName} ${claimant?.lastName}`);
      }

      if (t?.status?.toUpperCase() === 'P' || userName === 'CEDWARDS') {
        //   if status = "P" OR user isAdmin
        setDisableAllButPayCode(true);
        disableAll();
        setMessageText('*** Read Only - Check has been printed ***');
        setAllowPayCodeChange(true);
        return load(true);
      }
      load(false);
    } else {
      load(false);
    }
  };

  const initNew = () => {
    load(false);
  };

  const load = (allowPayCodeChange: boolean) => {
    if (allowPayCodeChange) {
      setAllowSaveEnabled(true);
      setSaveButtonText('Save PayCode');
      setServiceFromEnabled(true);
      setServiceToEnabled(true);
    }

    if (selectedPayment) {
      //  NOT A NEW TRANSACTION
      if (selectedPayment?.payee?.toUpperCase() === 'P') {
        setAllowPayeeEnabled(true);
      }
      if (
        selectedPayment?.status?.toUpperCase() === 'N' ||
        selectedPayment?.status?.toUpperCase() === 'R'
      ) {
        setAllowDeleteEnabled(true);
      }
    }
    checkTxType();
  };

  const cleanUp = () => {
    setSelectedPaymentProvider(null);
    setSelectedSearchProvider(null);
    setActiveKey('');
    setReservesCushionText('');
    setFBucketText('');
    setMessageText('');
    setAllowPayCodeChange(false);
    setDisableAllButPayCode(false);
    setClaimantAddress('');
    setProviderAddress('');
    setAllowCheckEnabled(false);
    setAllowSaveEnabled(false);
    setAllowSaveNewEnabled(false);
    setSaveButtonText('Save and Close');
    setAllowDeleteEnabled(false);
    setAllowPayeeEnabled(false);
    setPayCodeWarningMessage('');
    setShowNonAdminAmountGreaterThanReserves(null);
    setShowAdminAmountGreaterThanReserves(null);
    setShowDuplicateWarning(null);
    setShowRefundGreaterWarning(null);
    setShowIndemnityNotLostTimeWarning(null);
    setCheckPayableTo('');
    enableAll();
    setDocumentControlNumberEnabled(true);
    setClaimReserveBuckets([]);
    setSubmittingNew(false);
    setSubmittingSaveCancel(false);
  };

  const checkTxType = (payCode?: string) => {
    let code: PayCodeItem | undefined;
    if (payCode) {
      code = payCodes.find((p) => p.payCode === payCode);
      setSelectedPayCode(code ?? null);
      getFBucketText(code);
    } else if (selectedPayment) {
      code = payCodes.find((p) => p.payCode === selectedPayment.payCode);
      setSelectedPayCode(code ?? null);
      getFBucketText(code);
    }

    if (code?.txType === 'RECOVERY' || code?.txType === 'REIMBURSEMENT') {
      setShowCheckDate(true);
      setShowCheckNumber(true);
    } else {
      setShowCheckDate(false);
      setShowCheckNumber(false);
      setAllowCheckEnabled(true);
    }
    if (userName === 'CEDWARDS') {
      setAllowCheckEnabled(true);
    }
  };

  const handlePayCodeChange = (payCode?: string) => {
    checkTxType(payCode);

    setAllowSaveEnabled(true);
    setAllowSaveNewEnabled(true);
    setPayCodeWarningMessage('');
  };

  const getProvider = () => {
    if (selectedPayment && selectedPayment.providerId) {
      ProviderApi.getProviderById(selectedPayment?.providerId)
        .then((res) => {
          const p = res.data.find(
            (p) => p.providerId === selectedPayment.providerId
          );
          if (!p) {
            toast.error('Failed to get Provider');
          }
          setSelectedPaymentProvider(p ?? null);
          getProviderAddress(p);
          if (selectedPayment.payee?.toUpperCase() === 'P') {
            setCheckPayableTo(p?.companyName ?? '');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getFBucketText = (payCode?: PayCodeItem) => {
    let text = 'FBucket';
    let reserveBucket: ClaimReserveBuckets | undefined;
    if (payCode) {
      reserveBucket = claimReserveBuckets.find(
        (b) => b.fBucket === payCode?.fBucket
      );
    } else {
      reserveBucket = claimReserveBuckets.find(
        (b) => b.fBucket === selectedPayment?.fbucket
      );
    }
    if (reserveBucket) {
      text = `${reserveBucket.description} - Reserves: ${accounting.formatMoney(
        reserveBucket.reserves
      )}`;
      setReserveAmt(reserveBucket.reserves);
    }
    setFBucketText(text);
  };

  const getReservesCushionText = () => {
    let cushion = 0;
    if (claim && claim.status?.toUpperCase() === 'C') {
      cushion = 1000;
    }
    setReservesCushionText(`Reserves Cushion: ${cushion}`);
  };

  const getClaimantAddress = () => {
    let address: Address | null = null;
    if (claimant && claimant.usePaymentAddress) {
      address = claimant?.paymentAddress ?? null;
    } else {
      address = claimant?.mainAddress ?? null;
    }
    setAddressTextString(address, setClaimantAddress);
  };

  const getProviderAddress = (provider?: ProviderSearchResult) => {
    let address: Address | null = null;
    if (provider) {
      const { address1, address2, city, state, zip } = provider;
      address = {
        address1,
        address2,
        city,
        state,
        zip,
        addressId: 0,
        addressName: '',
        ownerId: 0,
        ownerTable: '',
      };
    } else if (selectedSearchProvider) {
      const { address1, address2, city, state, zip } = selectedSearchProvider;
      address = {
        address1,
        address2,
        city,
        state,
        zip,
        addressId: 0,
        addressName: '',
        ownerId: 0,
        ownerTable: '',
      };
    } else if (selectedPaymentProvider) {
      const { address1, address2, city, state, zip } = selectedPaymentProvider;
      address = {
        address1,
        address2,
        city,
        state,
        zip,
        addressId: 0,
        addressName: '',
        ownerId: 0,
        ownerTable: '',
      };
    }
    setAddressTextString(address, setProviderAddress);
  };

  const handleSelectedProvider = (provider: ProviderSearchResult) => {
    setSelectedSearchProvider(provider);
    getProviderAddress(provider);
    setCheckPayableTo(provider.companyName);
    setActiveKey('');
  };

  const handlePayeeChange = (value: string) => {
    if (value === 'C') {
      getClaimantAddress();
      setCheckPayableTo(`${claimant?.firstName} ${claimant?.lastName}`);
    }
    if (value === 'P') {
      getProviderAddress();
      let name: string = '';
      if (selectedSearchProvider) {
        name = selectedSearchProvider.companyName;
      } else if (selectedPaymentProvider) {
        name = selectedPaymentProvider.companyName;
      }
      setCheckPayableTo(name);
    }
  };

  const stopAllSubmitting = () => {
    setSubmittingNew(false);
    setSubmittingSaveCancel(false);
  };

  const saveNew = async (values: SaveGemcexcessTransaction) => {
    console.log({ values });
    console.log({ selectedPayCode });
    console.log({ selectedPayment });

    values.claimNo = claimNumber ?? '';
    if (
      selectedPayCode?.txType === 'RECOVERY' ||
      selectedPayCode?.txType === 'REIMBURSEMENT'
    ) {
      values.status = 'R';
    } else {
      values.status = 'N';
    }

    if (!values.payee) {
      toast.error('Please choose a provider.');
      stopAllSubmitting();
      return;
    } else if (!values.amount) {
      toast.error('Please enter an amount.');
      stopAllSubmitting();
      return;
    } else if (!values.serviceFrom && selectedPayCode?.txType === 'PAYMENT') {
      toast.error('Please enter a service from and to date.');
      stopAllSubmitting();
      return;
    } else if (!values.serviceTo && selectedPayCode?.txType === 'PAYMENT') {
      toast.error('Please enter a service from and to date.');
      stopAllSubmitting();
      return;
    } else if (selectedPayCode?.txType !== 'PAYMENT' && !values.checkNumber) {
      toast.error('Please enter a check number.');
      stopAllSubmitting();
      return;
    } else if (selectedPayCode?.txType !== 'PAYMENT' && !values.checkDate) {
      toast.error('Please enter a check date.');
      stopAllSubmitting();
      return;
    } else {
      if (!submittingSaveCancel) {
        setSubmittingNew(true);
      }
      if (selectedPayCode?.txType === 'PAYMENT') {
        let taxId: string = '';
        if (values.payee === 'Provider') {
          taxId = selectedSearchProvider
            ? selectedSearchProvider.taxId
            : selectedPaymentProvider?.taxId ?? '';
        } else {
          taxId = claimant?.ssn ?? '';
        }

        let request: DuplicateTransactionRequest = {
          amount: values.amount ?? 0,
          claimNumber: claimNumber!,
          fromDate: values.serviceFrom!,
          taxId: taxId,
          toDate: values.serviceTo!,
        };

        const dupResponse = await PaymentsApi.checkForDuplicatePayments(
          request
        ).catch((err) => {
          console.log(err);
        });
        if (dupResponse && dupResponse.data.duplicateExists) {
          let savedResolve:
            | ((value: boolean | PromiseLike<boolean>) => void)
            | null = null;
          const valueReturned = new Promise<boolean>((resolve, reject) => {
            savedResolve = resolve;
          });
          const { enteredBy, txEnterDate } = dupResponse.data;

          const alert = (
            <Alert
              variant='danger'
              className='mt-3'
              dismissible
              onClose={() => {
                savedResolve && savedResolve(false);
                setShowDuplicateWarning(null);
              }}
            >
              <p>{`Possible duplicate with transaction`}</p>
              <p>{`entered on ${displayDateOnly(
                txEnterDate ?? ''
              )} by ${enteredBy}`}</p>
              <p>Would you like to Proceed?</p>
              <div className='d-flex justify-content-around'>
                <Button
                  type='button'
                  onClick={() => {
                    savedResolve && savedResolve(true);
                    setShowDuplicateWarning(null);
                  }}
                  variant='danger'
                >
                  Yes
                </Button>
              </div>
            </Alert>
          );
          setShowDuplicateWarning(alert);

          const res = await valueReturned;
          if (!res) {
            stopAllSubmitting();
            return;
          }
        }
      }

      let reserveCushion = 0;
      if (claim) {
        //per 3/31/2015 meeting, reserve cushion is only on closed claims and should always be $1000
        if (claim.status?.toUpperCase() === 'C') {
          setReserveAmt(0);
          reserveCushion = 1000;
        } else {
          reserveCushion = 0;
        }
      } else {
        reserveCushion = 0;
      }

      if (
        values.amount > reserveAmt + reserveCushion &&
        selectedPayCode?.txType === 'PAYMENT'
      ) {
        // CHANGE TO GREATER THAN
        let savedResolve:
          | ((value: boolean | PromiseLike<boolean>) => void)
          | null = null;
        const valueReturned = new Promise<boolean>((resolve, reject) => {
          savedResolve = resolve;
        });

        const alert = (
          <Alert
            variant='danger'
            className='mt-3'
            dismissible
            onClose={() => {
              savedResolve && savedResolve(false);
              setShowAdminAmountGreaterThanReserves(null);
            }}
          >
            <p>{`The amount of the transaction ${accounting.formatMoney(
              values.amount ?? 0
            )} is greater than the reserve amount ${accounting.formatMoney(
              reserveAmt
            )}`}</p>
            <p>Please increase reserves before entering additional payments.</p>
            <p>Would you like to Proceed?</p>
            <div className='d-flex justify-content-around'>
              <Button
                type='button'
                onClick={() => {
                  savedResolve && savedResolve(true);
                  setShowAdminAmountGreaterThanReserves(null);
                }}
                variant='danger'
              >
                Yes
              </Button>
            </div>
          </Alert>
        );

        setShowAdminAmountGreaterThanReserves(alert);

        const res = await valueReturned;
        if (!res) {
          stopAllSubmitting();
          return;
        }
      }

      let totalPaid = 0;

      if (claimPaymentTotals && values.amount < 0) {
        if (totalPaid + values.amount < 0) {
          const { medical, indemnity, expense, legal } = claimPaymentTotals;

          let savedResolve:
            | ((value: boolean | PromiseLike<boolean>) => void)
            | null = null;
          const valueReturned = new Promise<boolean>((resolve, reject) => {
            savedResolve = resolve;
          });
          const alert = (
            <Alert
              variant='danger'
              className='mt-3'
              dismissible
              onClose={() => {
                savedResolve && savedResolve(false);
                setShowRefundGreaterWarning(null);
              }}
            >
              <p>
                Amount of refund/reimbursement is greater than the amount of
                payments.
              </p>
              <p>{`Total Paid: ${accounting.formatMoney(totalPaid)}`}</p>
              <p>{`Medical Paid: ${accounting.formatMoney(medical)}`}</p>
              <p>{`Indemnity Paid: ${accounting.formatMoney(indemnity)}`}</p>
              <p>{`Expense Paid: ${accounting.formatMoney(expense)}`}</p>
              <p>{`Legal Paid: ${accounting.formatMoney(legal)}`}</p>
              <p>Would you like to proceed?</p>
              <div className='d-flex justify-content-around'>
                <Button
                  type='button'
                  onClick={() => {
                    savedResolve && savedResolve(true);
                    setShowRefundGreaterWarning(null);
                  }}
                  variant='danger'
                >
                  Yes
                </Button>
              </div>
            </Alert>
          );

          setShowRefundGreaterWarning(alert);

          const res = await valueReturned;
          if (!res) {
            stopAllSubmitting();
            return;
          }
        } else {
          let amountOk: boolean = true;
          switch (selectedPayCode?.fBucket) {
            case '1':
              if (claimPaymentTotals.medical + values.amount < 0) {
                amountOk = false;
              }
              break;
            case '2':
              if (claimPaymentTotals.indemnity + values.amount < 0) {
                amountOk = false;
              }
              break;
            case '3':
              if (claimPaymentTotals.expense + values.amount < 0) {
                amountOk = false;
              }
              break;
            case '4':
              if (claimPaymentTotals.legal + values.amount < 0) {
                amountOk = false;
              }
              break;
          }

          if (!amountOk) {
            let savedResolve:
              | ((value: boolean | PromiseLike<boolean>) => void)
              | null = null;
            const valueReturned = new Promise<boolean>((resolve, reject) => {
              savedResolve = resolve;
            });
            const alert = (
              <Alert
                variant='danger'
                className='mt-3'
                dismissible
                onClose={() => {
                  savedResolve && savedResolve(false);
                  setShowRecoveryIsMoreThanBucket(null);
                }}
              >
                <p>
                  The amount of the recovery is more than the amount of payments
                  for the financial bucket,
                </p>
                <p>
                  however the total amount may be able to be split between other
                  buckets.
                </p>
                <p>{`Medical Paid: ${accounting.formatMoney(
                  claimPaymentTotals.medical
                )}`}</p>
                <p>{`Indemnity Paid: ${accounting.formatMoney(
                  claimPaymentTotals.indemnity
                )}`}</p>
                <p>{`Expense Paid: ${accounting.formatMoney(
                  claimPaymentTotals.expense
                )}`}</p>
                <p>{`Legal Paid: ${accounting.formatMoney(
                  claimPaymentTotals.legal
                )}`}</p>
                <p>Would you like to proceed?</p>
                <div className='d-flex justify-content-around'>
                  <Button
                    type='button'
                    onClick={() => {
                      savedResolve && savedResolve(true);
                      setShowRecoveryIsMoreThanBucket(null);
                    }}
                    variant='danger'
                  >
                    Yes
                  </Button>
                </div>
              </Alert>
            );
            setShowRecoveryIsMoreThanBucket(alert);

            const res = await valueReturned;
            if (!res) {
              stopAllSubmitting();
              return;
            }
          }
        }
      }

      if (values.payee.toUpperCase() === 'C') {
        values.providerId = claimant?.claimantId ?? null;
      } else {
        values.providerId = selectedSearchProvider
          ? selectedSearchProvider.providerId
          : selectedPaymentProvider?.providerId!;
      }

      values.payCode = selectedPayCode?.payCode ?? null;
      values.fbucket = selectedPayCode?.fBucket ?? null;

      if (values.checkNumber) {
        if (new Date(values.checkDate ?? '') < new Date()) {
          values.checkDate = parseDatesForServer(
            format(new Date(), 'yyyy-MM-dd')
          );
        }
      }
      if (!values.serviceTo) {
        values.serviceTo = parseDatesForServer(
          format(new Date(), 'yyyy-MM-dd')
        );
      }
      if (!values.serviceFrom) {
        values.serviceFrom = parseDatesForServer(
          format(new Date(), 'yyyy-MM-dd')
        );
      }
      return saveTransaction(values);
    }
  };

  const saveTransaction = (transaction: SaveGemcexcessTransaction) => {
    const copy = { ...transaction };
    if (copy.txid && copy.txid > 0) {
      return RecoveryApi.updateTransaction(copy)
        .then((res) => {
          if (res.data.success) {
            formInstance.reset();
            setShow(false);
            cleanUp();
            getTransactionsForClaim();
            getReservesForClaim();
          } else {
            toast.error(res.data.message);
          }
          stopAllSubmitting();
        })
        .catch((err) => {
          console.log(err);
          stopAllSubmitting();
          toast.error('Failed to update transaction');
        });
    }
    copy.claimNo = claimNumber ?? '';
    copy.documentControlNumber = 'SystemEntry - 3';
    copy.txdate = format(new Date(), 'yyyy-MM-dd') ?? null;
    copy.txenterDate = format(new Date(), 'yyyy-MM-dd') ?? null;
    copy.alteredBy = userModel?.user?.userId ?? '';
    copy.enteredBy = userModel?.user?.userId ?? '';

    return RecoveryApi.createTransaction(copy)
      .then((res) => {
        if (res.data.success) {
          formInstance.reset();
          setShow(false);
          cleanUp();
          getTransactionsForClaim();
          getReservesForClaim();
        } else {
          toast.error(res.data.message);
        }
        stopAllSubmitting();
      })
      .catch((err) => {
        console.log(err);
        stopAllSubmitting();
        toast.error('Failed to create transaction');
      });
  };

  const saveAndClose = (values: SaveGemcexcessTransaction) => {
    setSubmittingSaveCancel(true);
    if (allowPayCodeChange) {
      console.log('SAVING PAYCODE');
      return savePayCode(values);
    } else {
      console.log('SAVING NEW');
      return saveNew(values);
    }
  };

  const savePayCode = (values: SaveGemcexcessTransaction) => {
    // values.payCode = selectedPayCode?.payCode ?? null;
    // if (!values.serviceTo) {
    //   values.serviceTo = parseDatesForServer(format(new Date(), "yyyy-MM-dd"));
    // }
    // if (!values.serviceFrom) {
    //   values.serviceFrom = parseDatesForServer(format(new Date(), "yyyy-MM-dd"));
    // }

    // values.payCode = selectedPayCode?.payCode ?? null;
    // values.fbucket = selectedPayCode?.fBucket ?? null;
    stopAllSubmitting();

    // return saveTransaction(values)
  };

  const allowCheckClick = () => {
    if (!showCheckDate) {
      setShowCheckDate(true);
      setShowCheckNumber(true);
    } else {
      setShowCheckDate(false);
      setShowCheckNumber(false);
    }
  };

  const deletePayment = (values: SaveGemcexcessTransaction) => {
    toast.error('This feature is not working.  Please Contact Ben.');
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
        cleanUp();
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='Edit-Create-Payment-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Edit-Create-Payment-Form-modal'
        >
          <FaEdit className='pe-1' /> Edit Payment
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid className='w-100 d-flex'>
          <div className='w-50 pe-2'>
            <RFFForm
              onSubmit={onSubmit}
              initialValues={selectedPayment ?? {}}
              validate={(values) => {
                const errors: {
                  [Property in keyof SaveGemcexcessTransaction]?: string;
                } = {};

                if (!values?.payee) {
                  errors.payee = 'Required';
                }
                return errors;
              }}
              render={({ handleSubmit, form, values, submitting }) => {
                const payeeFieldState = form.getFieldState(
                  'payee' as keyof SaveGemcexcessTransaction
                )!;
                formInstance = form;
                return (
                  <Form onSubmit={handleSubmit}>
                    <div className='d-flex justify-content-between w-100'>
                      <div className='w-50 pe-2'>
                        <fieldset
                          className={`${styles.height80px} py-0`}
                          disabled={disableAllButPayCode}
                        >
                          <div
                            style={
                              payeeFieldState?.submitFailed &&
                              payeeFieldState?.invalid
                                ? {
                                    border: '1px solid red',
                                    borderRadius: '.25rem',
                                  }
                                : {}
                            }
                          >
                            <legend className='col-form-label col-sm-2 py-0'>
                              Payee
                            </legend>
                            <Field
                              name='payee'
                              type='radio'
                              label='Claimant'
                              value='C'
                              checked={values?.payee?.toUpperCase() === 'C'}
                              onCheckChange={handlePayeeChange}
                              component={FieldBSRenderCheckbox}
                            />
                            <Field
                              name='payee'
                              type='radio'
                              label='Provider'
                              value='P'
                              checked={values?.payee?.toUpperCase() === 'P'}
                              onCheckChange={handlePayeeChange}
                              component={FieldBSRenderCheckbox}
                            />
                          </div>
                        </fieldset>
                        {showCheckDate ? (
                          <Field
                            name='checkDate'
                            label='Check Date'
                            parse={parseDatesForServer}
                            component={FieldBSRenderDate}
                            disabled={disableAllButPayCode}
                          />
                        ) : (
                          <div
                            className={`${styles.height80px} d-flex align-items-center`}
                          >
                            <p>Check Date - assigned after printing</p>
                          </div>
                        )}
                        <div
                          className={`${styles.height80px} d-flex align-items-center`}
                        >
                          <p>{fBucketText}</p>
                        </div>
                        <div
                          className={`${styles.height80px} d-flex align-items-center`}
                        >
                          <p>{reservesCushionText}</p>
                        </div>
                        <div className={styles.height160px}>
                          <div className={`position-relative mb-3`}>
                            <label
                              htmlFor='mailingAddress'
                              className='form-label fs-6  m-0'
                            >
                              Mailing Address
                            </label>
                            <div>
                              <textarea
                                name='mailingAddress'
                                id='mailingAddress'
                                rows={5}
                                value={
                                  values?.payee?.toUpperCase() === 'C'
                                    ? claimantAddress
                                    : values?.payee?.toUpperCase() === 'P'
                                    ? providerAddress
                                    : ''
                                }
                                className='form-control form-control-sm'
                                placeholder='Mailing Address'
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        <Field
                          name='memo'
                          type='text'
                          label='Memo'
                          component={FieldBSRenderText}
                          disabled={disableAllButPayCode}
                        />
                      </div>
                      <div className='w-50 ps-2'>
                        {showCheckNumber ? (
                          <Field
                            name='checkNumber'
                            type='text'
                            label='Check Number'
                            component={FieldBSRenderText}
                            disabled={disableAllButPayCode}
                          />
                        ) : (
                          <div
                            className={`${styles.height80px} d-flex align-items-center`}
                          >
                            <p>Check # - assigned after printing</p>
                          </div>
                        )}
                        <Field
                          name='amount'
                          label='Amount'
                          type='text'
                          format={formatNumbers}
                          parse={cleanMoney}
                          validate={composeValidators(
                            centsValidation,
                            requiredField
                          )}
                          component={FieldBSRenderMoney}
                          disabled={disableAllButPayCode}
                        />
                        <div
                          className={`position-relative ${styles.vFieldHeight}`}
                        >
                          <label
                            htmlFor='checkPayableTo'
                            className='form-label fs-6  m-0'
                          >
                            Payee Info
                          </label>
                          <input
                            type='text'
                            name='checkPayableTo'
                            id='checkPayableTo'
                            defaultValue={checkPayableTo}
                            className='form-control form-control-sm'
                            placeholder='Payee Info'
                            onFocus={() => {
                              if (values?.payee?.toUpperCase() === 'P') {
                                setActiveKey('0');
                              }
                            }}
                            disabled={!allowPayeeEnabled}
                          />
                        </div>
                        <Field
                          name='payCode'
                          label='Pay Code'
                          options={payCodes}
                          onChange={handlePayCodeChange}
                          optionMethod={(options: PayCodeItem[]) =>
                            options.map((o) => (
                              <option key={o.payCode} value={o.payCode}>
                                {o.description}
                              </option>
                            ))
                          }
                          component={FieldBSRenderSelect}
                        />
                        {payCodeWarningMessage && (
                          <div>
                            <p className='text-danger'>
                              {payCodeWarningMessage}
                            </p>
                          </div>
                        )}
                        <Field
                          name='serviceFrom'
                          label='Service From'
                          parse={parseDatesForServer}
                          component={FieldBSRenderDate}
                          disabled={!serviceFromEnabled}
                          validate={requiredField}
                        />
                        <Field
                          name='serviceTo'
                          label='Service To'
                          parse={parseDatesForServer}
                          component={FieldBSRenderDate}
                          disabled={!serviceToEnabled}
                          validate={requiredField}
                        />
                        <Field
                          name='documentControlNumber'
                          type='text'
                          label='Document Control Number'
                          component={FieldBSRenderText}
                          disabled={!documentControlNumberEnabled}
                        />
                      </div>
                    </div>
                    {messageText && (
                      <Alert variant='danger'>
                        <p className='text-danger text-center'>{messageText}</p>
                      </Alert>
                    )}
                    <div className={`${styles.buttonDiv}`}>
                      <Button
                        type='button'
                        size='sm'
                        variant='danger'
                        onClick={() => deletePayment(values)}
                        disabled={!allowDeleteEnabled}
                      >
                        Delete
                      </Button>
                      <Button
                        type='button'
                        onClick={allowCheckClick}
                        size='sm'
                        variant='primary'
                        disabled={!allowCheckEnabled}
                      >
                        Allow Check
                      </Button>
                      <Button
                        type='button'
                        size='sm'
                        variant='primary'
                        onClick={() => saveNew(values)}
                        disabled={!allowSaveNewEnabled}
                      >
                        {submittingNew ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          'Save and New'
                        )}
                      </Button>
                      <Button
                        type='button'
                        onClick={() => saveAndClose(values)}
                        size='sm'
                        variant='primary'
                        disabled={!allowSaveEnabled}
                      >
                        {submittingSaveCancel ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          `${saveButtonText}`
                        )}
                      </Button>
                      <Button
                        type='button'
                        size='sm'
                        variant='secondary'
                        onClick={() => {
                          form.reset();
                          setShow(false);
                          cleanUp();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                );
              }}
            />
          </div>
          <div className='w-50 ps-2'>
            <Accordion
              onSelect={(eventKey: AccordionEventKey) => setActiveKey(eventKey)}
              activeKey={activeKey}
              className='mt-3'
            >
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Provider Look Up</Accordion.Header>
                <Accordion.Body>
                  <ProviderLookUpForm
                    selectProvider={handleSelectedProvider}
                    selectedPayment={null}
                    disabled={false}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {showNonAdminAmountGreaterThanReserves !== null &&
              showAdminAmountGreaterThanReserves}
            {showAdminAmountGreaterThanReserves !== null &&
              showAdminAmountGreaterThanReserves}
            {showDuplicateWarning !== null && showDuplicateWarning}
            {showRefundGreaterWarning !== null && showRefundGreaterWarning}
            {showRecoveryIsMoreThanBucket !== null &&
              showRecoveryIsMoreThanBucket}
            {showIndemnityNotLostTimeWarning !== null &&
              showIndemnityNotLostTimeWarning}
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
