import React from 'react';
import { Decorator } from 'final-form';
import createDecorator from 'final-form-calculate';
import { ProgressReportFormType } from './ProgressReportFormType';
import accounting from 'accounting';

const calcTotal = (values: ProgressReportFormType) => {
  let total = 0;

  total =
    (values.totalWeeklyBenefits ? +values.totalWeeklyBenefits : 0) +
    (values.physicianBenefits ? +values.physicianBenefits : 0) +
    (values.hospitalBenefits ? +values.hospitalBenefits : 0) +
    (values.pharmacyBenefits ? +values.pharmacyBenefits : 0) +
    (values.physicalTherapy ? +values.physicalTherapy : 0) +
    (values.chiropractic ? +values.chiropractic : 0) +
    (values.otherMedical ? +values.otherMedical : 0) +
    (values.rehab ? +values.rehab : 0) +
    (values.latePaymentPenalties ? +values.latePaymentPenalties : 0) +
    (values.assessedFees ? +values.assessedFees : 0) +
    (values.burial ? +values.burial : 0);

  return total;
};

const calcTotalFromB = (values: ProgressReportFormType) => {
  let total = 0;

  total =
    (values.ttdPayments ? +values.ttdPayments : 0) +
    (values.splcPayments ? +values.splcPayments : 0) +
    (values.tpdPayments ? +values.tpdPayments : 0) +
    (values.ppdPayments ? +values.ppdPayments : 0) +
    (values.deathPayments ? +values.deathPayments : 0) +
    (values.settlementPayments ? +values.settlementPayments : 0) +
    (values.advancesPayments ? +values.advancesPayments : 0);

  return total;
};

export const progressReportFormDecorator = createDecorator(
  {
    field: [
      'totalWeeklyBenefits',
      'physicianBenefits',
      'hospitalBenefits',
      'pharmacyBenefits',
      'physicalTherapy',
      'chiropractic',
      'otherMedical',
      'rehab',
      'latePaymentPenalties',
      'assessedFees',
      'burial',
    ],
    updates: (value, field, allValues, previousValue) => {
      var total = calcTotal(allValues as ProgressReportFormType);
      return {
        totalLabel: accounting.formatMoney(total),
      };
    },
  },
  {
    field: [
      'ttdPayments',
      'splcPayments',
      'tpdPayments',
      'ppdPayments',
      'deathPayments',
      'settlementPayments',
      'advancesPayments',
    ],
    updates: (value, field, allValues, previousValue) => {
      var total = calcTotalFromB(allValues as ProgressReportFormType);
      return {
        totalWeeklyBenefits: total,
      };
    },
  }
) as Decorator<ProgressReportFormType, object>;
