export interface ClaimCustomOption {
  id: number;
  type: number | null;
  name: string | null;
  value: number | null;
  claimTypeId: number | null;
}

export enum OptionTypeEnum {
  Unknown = 0,
  ClaimCauses = 1,
  SubrogationTypes = 2,
  SubrogationStatuses = 3,
  SubrogationResponsibleFors = 4,
  SubrogationResults = 5,
  ClaimAccidentLossTypes = 6,
  PolicyForm = 7,
  SeverityCode = 8,
  LitigationAppealingParty = 9,
  LitigationJurisdiction = 10,
  LitigationAppealResult = 11,
  LitigationTrialResult = 12,
  LitigationMSJResult = 13,
  PropertyVehicleType = 14,
  PropertyOwnerType = 15,
  PropertySalvageType = 16,
  PropertyDamageType = 17,
  PropertyBuyerType = 18,
  ClaimResultCodes = 19,
  CheckStatusExplanations = 20,
}
