import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { Reminder } from '../ApiTypes/Reminder';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class ReminderApi {
  static getReminders(userId: string, asOfDate: string) {
    return axios.get<Reminder[]>(
      `${API_URL}/api/reminders?asOfDate=${asOfDate}&userId=${userId}`,
      getAuthHeader()
    );
  }
  static markComplete(noteId: number) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/reminders/complete/${noteId}`,
      {},
      getAuthHeader()
    );
  }
}

export default ReminderApi;
