import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { GemcLossRunEmail } from '../ApiTypes/GemcLossRunEmail';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { GemcLossRunEmailRequest } from '../ApiTypes/GemcLossRunEmailRequest';

class GemcLossRunsApi {
  static getEmails() {
    return axios.get<GemcLossRunEmail[]>(
      `${API_URL}/api/gemclossruns/emails`,
      getAuthHeader()
    );
  }
  static getRequest() {
    return axios.get<GemcLossRunEmailRequest>(
      `${API_URL}/api/gemclossruns`,
      getAuthHeader()
    );
  }
  static updateRequest(request: GemcLossRunEmailRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/gemclossruns`,
      request,
      getAuthHeader()
    );
  }
  static TestEmail(
    request: GemcLossRunEmail,
    startDate: string,
    endDate: string
  ) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/gemclossruns/test?start=${startDate}&end=${endDate}`,
      request,
      getAuthHeader()
    );
  }
  static updateEmail(email: GemcLossRunEmail) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/gemclossruns/emails/${email.keyEmailId}`,
      email,
      getAuthHeader()
    );
  }
}

export default GemcLossRunsApi;
