import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { Judge } from '../ApiTypes/Judge';

class JudgeApi {
  static updateJudge(request: Judge) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/judge`,
      request,
      getAuthHeader()
    );
  }
}

export default JudgeApi;
