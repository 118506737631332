import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { ClaimProperty } from '../ApiTypes/ClaimProperty';
import { PropertyPhoto } from '../ApiTypes/PropertyPhoto';

class ClaimPropertyApi {
  static getClaimPropertiesForClaim(claimNumber: string) {
    return axios.get<ClaimProperty[]>(
      `${API_URL}/api/claims/${claimNumber}/claimproperty`,
      getAuthHeader()
    );
  }
  static getClaimPropertyById(claimNumber: string, claimPropertyId: number) {
    return axios.get<ClaimProperty>(
      `${API_URL}/api/claims/${claimNumber}/claimproperty/${claimPropertyId}`,
      getAuthHeader()
    );
  }
  static createClaimProperty(property: ClaimProperty, claimNumber: string) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/claimproperty`,
      property,
      getAuthHeader()
    );
  }
  static updateClaimProperty(property: ClaimProperty, claimNumber: string) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/claimproperty/${property.id}`,
      property,
      getAuthHeader()
    );
  }
  static deleteClaimProperty(claimPropertyId: number, claimNumber: string) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/claimproperty/${claimPropertyId}`,
      getAuthHeader()
    );
  }
}

export default ClaimPropertyApi;
