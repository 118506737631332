import { format } from 'date-fns';
import { FormApi } from 'final-form';
import React, { useState, useEffect } from 'react';
import {
  Modal,
  Container,
  Form,
  Button,
  Spinner,
  Alert,
} from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaCheckSquare, FaEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';
import EdiTransactionApi from '../../Api/EdiTransactionApi';
import { EdiProgressReport } from '../../ApiTypes/EdiProgressReport';
import { parseDatesForServer } from '../../Utils';
import { formatNumbers, cleanMoney } from '../../Utils/InputFormatters';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import styles from './index.module.css';
import { progressReportFormDecorator } from './ProgressReportFormDecorator';
import { ProgressReportFormType } from './ProgressReportFormType';
import { useAppDispatch } from '../../Reducers/Store';

export default function ProgressReport({
  wc4Id,
  claimNumber,
  claimantFirstName,
  claimantLastName,
  isFn,
  show,
  setShow,
  setWc4Id,
}: {
  wc4Id?: number;
  claimNumber: string;
  claimantFirstName: string;
  claimantLastName: string;
  isFn?: boolean;
  setWc4Id?: (id: number) => void;
  show: boolean;
  setShow: (s: boolean) => void;
}) {
  const dispatch = useAppDispatch();
  const [progressReport, setProgressReport] =
    useState<EdiProgressReport | null>(null);

  const [showCreateConfirm, setShowCreateConfirm] = useState<boolean>(false);
  const [showSaveConfirm, setShowSaveConfirm] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showSaveDocument, setShowSaveDocument] = useState<boolean>(false);
  const [wc4IdToSave, setWc4IdToSave] = useState<number>(0);

  useEffect(() => {
    getProgressReport();
  }, [wc4Id]);

  let formInstance: FormApi<
    ProgressReportFormType,
    Partial<ProgressReportFormType>
  >;

  const getProgressReport = () => {
    if (wc4Id && wc4Id > 0) {
      EdiTransactionApi.getEdiProgressReport(claimNumber, wc4Id)
        .then((res) => {
          setProgressReport(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getProgressReportData();
    }
  };

  const getProgressReportData = () => {
    EdiTransactionApi.getEdiProgressReportData(claimNumber)
      .then((res) => {
        let status = isFn ? 'F' : 'S';
        let copy = { ...res.data, status };
        setProgressReport(copy);
      })
      .catch((err) => console.log(err));
  };

  const onSubmit = (values: ProgressReportFormType) => {
    const state = formInstance.getState();
    const dirtyFields = Object.keys(state.dirtyFields);
    const notModified =
      dirtyFields.length < 1 ||
      (dirtyFields.length === 1 && dirtyFields[0] === 'totalLabel');

    if (progressReport !== null && progressReport.wc4id > 0) {
      if (notModified) {
        // no changes - just close modal
        setShowCreateConfirm(false);
        setShowSaveConfirm(false);
        setIsSubmitting(false);
        setShow(false);
      } else {
        // confirm save?
        setIsSubmitting(true);
        setShowSaveConfirm(true);
      }
    } else {
      // confirm create new progress report
      setIsSubmitting(true);
      setShowCreateConfirm(true);
    }
  };

  const saveProgressReport = (values: ProgressReportFormType) => {
    const copy = { ...values };
    let send: EdiProgressReport = {
      wc4id: copy.wc4id ?? 0,
      claimno: copy.claimno,
      status: copy.status,
      submitdate: format(new Date(), 'yyyy-MM-dd'),
      ttdRate: copy.ttdRate ?? null,
      ttdWeeks: copy.ttdWeeks ?? null,
      ttdDays: copy.ttdDays ?? null,
      ttdPayments: copy.ttdPayments ?? null,
      tpdRate: copy.tpdRate ?? null,
      tpdWeeks: copy.tpdWeeks ?? null,
      tpdDays: copy.tpdDays ?? null,
      tpdPayments: copy.tpdPayments ?? null,
      ppdRate: copy.ppdRate ?? null,
      ppdWeeks: copy.ppdWeeks ?? null,
      ppdDays: copy.ppdDays ?? null,
      ppdPayments: copy.ppdPayments ?? null,
      deathRate: copy.deathRate ?? null,
      deathWeeks: copy.deathWeeks ?? null,
      deathDays: copy.deathDays ?? null,
      deathPayments: copy.deathPayments ?? null,
      settlementPayments: copy.settlementPayments ?? null,
      advancesPayments: copy.advancesPayments ?? null,
      totalWeeklyBenefits: copy.totalWeeklyBenefits ?? null,
      physicianBenefits: copy.physicianBenefits ?? null,
      hospitalBenefits: copy.hospitalBenefits ?? null,
      pharmacyBenefits: copy.pharmacyBenefits ?? null,
      physicalTherapy: copy.physicalTherapy ?? null,
      chiropractic: copy.chiropractic ?? null,
      otherMedical: copy.otherMedical ?? null,
      rehab: copy.rehab ?? null,
      latePaymentPenalties: copy.latePaymentPenalties ?? null,
      assessedFees: copy.assessedFees ?? null,
      burial: copy.burial ?? null,
      remarks: copy.remarks ?? null,
      recoveryCodes: copy.recoveryCodes ?? null,
      advancesRate: copy.advancesRate ?? null,
      advancesWeeks: copy.advancesWeeks ?? null,
      advancesDays: copy.advancesDays ?? null,
      settlementRate: copy.settlementRate ?? null,
      settlementWeeks: copy.settlementWeeks ?? null,
      settlementDays: copy.settlementDays ?? null,
      dateOfFinalWeeklyPayment: copy.dateOfFinalWeeklyPayment ?? null,
      ttdStart: copy.ttdStart ?? null,
      ttdEnd: copy.ttdEnd ?? null,
      splcRate: copy.splcRate ?? null,
      splcWeeks: copy.splcWeeks ?? null,
      splcDays: copy.splcDays ?? null,
      splcPayments: copy.splcPayments ?? null,
      splcStart: copy.splcStart ?? null,
      splcEnd: copy.splcEnd ?? null,
      tpdStart: copy.tpdStart ?? null,
      tpdEnd: copy.tpdEnd ?? null,
      ppdStart: copy.ppdStart ?? null,
      ppdEnd: copy.ppdEnd ?? null,
      deathStart: copy.deathStart ?? null,
      deathEnd: copy.deathEnd ?? null,
      settleStart: copy.settleStart ?? null,
      settleEnd: copy.settleEnd ?? null,
      advanceStart: copy.advanceStart ?? null,
      advanceEnd: copy.advanceEnd ?? null,
    };
    if (send.wc4id > 0) {
      return EdiTransactionApi.updateEdiProgressReport(values.claimno, send)
        .then((res) => {
          if (res.data.success) {
            setWc4IdToSave(+res.data.affectedEntityIdentifier);
            setWc4Id && setWc4Id(+res.data.affectedEntityIdentifier);
            setShowSaveDocument(true);
            setShowCreateConfirm(false);
            setShowSaveConfirm(false);
            setIsSubmitting(false);
          } else {
            setIsSubmitting(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsSubmitting(false);
          toast.error('Failed to update the progress report');
        });
    } else {
      return EdiTransactionApi.createEdiProgressReport(values.claimno, send)
        .then((res) => {
          if (res.data.success) {
            setWc4IdToSave(+res.data.affectedEntityIdentifier);
            setWc4Id && setWc4Id(+res.data.affectedEntityIdentifier);
            setShowSaveDocument(true);
            setShowCreateConfirm(false);
            setShowSaveConfirm(false);
            setIsSubmitting(false);
          } else {
            setIsSubmitting(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsSubmitting(false);
          toast.error('Failed to create the progress report');
        });
    }
  };

  const saveToDocuments = () => {
    if (wc4IdToSave > 0) {
      return EdiTransactionApi.saveEdiProgressReportToDocuments(
        claimNumber!,
        wc4IdToSave
      )
        .then((res) => {
          if (res.data.success) {
            setWc4IdToSave(0);
            setShowCreateConfirm(false);
            setShowSaveConfirm(false);
            setShow(false);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to save report to documents tab');
        });
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShowCreateConfirm(false);
        setShowSaveConfirm(false);
        setIsSubmitting(false);
        setShowSaveDocument(false);
        setWc4IdToSave(0);
        setShow(false);
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='Progress-Report-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Progress-Report-modal'>
          <FaEdit className='pe-1' />{' '}
          {`Claimant Name ${claimantFirstName} ${claimantLastName} (${claimNumber})`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            decorators={[progressReportFormDecorator]}
            validate={(values) => {
              const errors: {
                [Property in keyof ProgressReportFormType]?: string;
              } = {};

              if (!values?.status) {
                errors.status = 'Required';
              }
              return errors;
            }}
            initialValues={
              progressReport ?? {
                wc4id: 0,
                claimno: claimNumber,
                status: isFn ? 'F' : 'S',
              }
            }
            render={({ handleSubmit, form, values, submitting }) => {
              formInstance = form;
              return (
                <Form onSubmit={handleSubmit}>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center gap1Rem'>
                      <Field
                        name='status'
                        type='radio'
                        label='Initial'
                        value='I'
                        checked={values?.status === 'I'}
                        component={FieldBSRenderCheckbox}
                      />
                      <Field
                        name='status'
                        type='radio'
                        label='Supplement'
                        value='S'
                        checked={values?.status === 'S'}
                        component={FieldBSRenderCheckbox}
                      />
                      <Field
                        name='status'
                        type='radio'
                        label='Final'
                        value='F'
                        checked={values?.status === 'F'}
                        component={FieldBSRenderCheckbox}
                      />
                      <Field
                        name='status'
                        type='radio'
                        label='Reopened'
                        value='R'
                        checked={values?.status === 'R'}
                        component={FieldBSRenderCheckbox}
                      />
                    </div>

                    <Field
                      name='dateOfFinalWeeklyPayment'
                      type='text'
                      label='Final Weekly Payment'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                  </div>
                  <fieldset>
                    <legend>B. Payment Type</legend>
                    <div className={`${styles.grid7}`}>
                      {/* first line */}
                      <span>(a) Temporary Total</span>
                      <Field
                        name='ttdRate'
                        label='Rate'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='ttdWeeks'
                        label='Weeks'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='ttdDays'
                        label='Days'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='ttdPayments'
                        label='Total Payments'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='ttdStart'
                        type='text'
                        label='Start Date'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                      <Field
                        name='ttdEnd'
                        type='text'
                        label='End Date'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                      {/* second line */}
                      <span>(i) Employer Paid (SPLC)</span>
                      <Field
                        name='splcRate'
                        label='Rate'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='splcWeeks'
                        label='Weeks'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='splcDays'
                        label='Days'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='splcPayments'
                        label='Total Payments'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='splcStart'
                        type='text'
                        label='Start Date'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                      <Field
                        name='splcEnd'
                        type='text'
                        label='End Date'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                      {/* third line */}
                      <span>(b) Temporary Partial</span>
                      <Field
                        name='tpdRate'
                        label='Rate'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='tpdWeeks'
                        label='Weeks'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='tpdDays'
                        label='Days'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='tpdPayments'
                        label='Total Payments'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='tpdStart'
                        type='text'
                        label='Start Date'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                      <Field
                        name='tpdEnd'
                        type='text'
                        label='End Date'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                      {/* fourth line */}
                      <span>(c) Permanent Partial</span>
                      <Field
                        name='ppdRate'
                        label='Rate'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='ppdWeeks'
                        label='Weeks'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='ppdDays'
                        label='Days'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='ppdPayments'
                        label='Total Payments'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='ppdStart'
                        type='text'
                        label='Start Date'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                      <Field
                        name='ppdEnd'
                        type='text'
                        label='End Date'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                      {/* fifth line */}
                      <span>(d) Death</span>
                      <Field
                        name='deathRate'
                        label='Rate'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='deathWeeks'
                        label='Weeks'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='deathDays'
                        label='Days'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='deathPayments'
                        label='Total Payments'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='deathStart'
                        type='text'
                        label='Start Date'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                      <Field
                        name='deathEnd'
                        type='text'
                        label='End Date'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                      {/* sixth line */}
                      <span>(e) Stipulation/Settlement</span>
                      <Field
                        name='settlementRate'
                        label='Rate'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='settlementWeeks'
                        label='Weeks'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='settlementDays'
                        label='Days'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='settlementPayments'
                        label='Total Payments'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='settleStart'
                        type='text'
                        label='Start Date'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                      <Field
                        name='settleEnd'
                        type='text'
                        label='End Date'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                      {/* seventh line */}
                      <span>(f) Advances</span>
                      <Field
                        name='advancesRate'
                        label='Rate'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='advancesWeeks'
                        label='Weeks'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='advancesDays'
                        label='Days'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='advancesPayments'
                        label='Total Payments'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='advanceStart'
                        type='text'
                        label='Start Date'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                      <Field
                        name='advanceEnd'
                        type='text'
                        label='End Date'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                    </div>
                  </fieldset>
                  <div className={`${styles.grid2}`}>
                    <fieldset>
                      <legend>C. Payments</legend>
                      <div className={`${styles.grid2} align-items-center`}>
                        <span>1. Total Weekly Benefits</span>
                        <Field
                          name='totalWeeklyBenefits'
                          label=' '
                          type='text'
                          format={formatNumbers}
                          parse={cleanMoney}
                          component={FieldBSRenderMoney}
                        />
                        <span>2. Physician Benefits</span>
                        <Field
                          name='physicianBenefits'
                          label=' '
                          type='text'
                          format={formatNumbers}
                          parse={cleanMoney}
                          component={FieldBSRenderMoney}
                        />
                        <span>3. Hospital Benefits</span>
                        <Field
                          name='hospitalBenefits'
                          label=' '
                          type='text'
                          format={formatNumbers}
                          parse={cleanMoney}
                          component={FieldBSRenderMoney}
                        />
                        <span>4. Pharmacy Benefits</span>
                        <Field
                          name='pharmacyBenefits'
                          label=' '
                          type='text'
                          format={formatNumbers}
                          parse={cleanMoney}
                          component={FieldBSRenderMoney}
                        />
                        <span>5. Physical Therapy</span>
                        <Field
                          name='physicalTherapy'
                          label=' '
                          type='text'
                          format={formatNumbers}
                          parse={cleanMoney}
                          component={FieldBSRenderMoney}
                        />
                        <span>6. Chiropractic</span>
                        <Field
                          name='chiropractic'
                          label=' '
                          type='text'
                          format={formatNumbers}
                          parse={cleanMoney}
                          component={FieldBSRenderMoney}
                        />
                        <span>7. Other (Medical)</span>
                        <Field
                          name='otherMedical'
                          label=' '
                          type='text'
                          format={formatNumbers}
                          parse={cleanMoney}
                          component={FieldBSRenderMoney}
                        />
                        <span>8. Rehabilitation / Vocational</span>
                        <Field
                          name='rehab'
                          label=' '
                          type='text'
                          format={formatNumbers}
                          parse={cleanMoney}
                          component={FieldBSRenderMoney}
                        />
                        <span>9. Late Payments</span>
                        <Field
                          name='latePaymentPenalties'
                          label=' '
                          type='text'
                          format={formatNumbers}
                          parse={cleanMoney}
                          component={FieldBSRenderMoney}
                        />
                        <span>10. Assessed Attorney's Fees</span>
                        <Field
                          name='assessedFees'
                          label=' '
                          type='text'
                          format={formatNumbers}
                          parse={cleanMoney}
                          component={FieldBSRenderMoney}
                        />
                        <span>11. Burial</span>
                        <Field
                          name='burial'
                          label=' '
                          type='text'
                          format={formatNumbers}
                          parse={cleanMoney}
                          component={FieldBSRenderMoney}
                        />
                        <span className='text-center'>Total</span>
                        <Field
                          name='totalLabel'
                          label=' '
                          type='text'
                          readOnly
                          component={FieldBSRenderText}
                        />
                      </div>
                    </fieldset>
                    <div className='d-flex flex-column justify-content-start'>
                      <fieldset>
                        <legend>D. Recovery Code</legend>
                        <div className='d-flex align-items-center gap1Rem'>
                          <Field
                            name='recoveryCodes'
                            type='radio'
                            label='For Subrogation'
                            value='S'
                            checked={values?.recoveryCodes === 'S'}
                            component={FieldBSRenderCheckbox}
                          />
                          <Field
                            name='recoveryCodes'
                            type='radio'
                            label='For Overpayment'
                            value='P'
                            checked={values?.recoveryCodes === 'P'}
                            component={FieldBSRenderCheckbox}
                          />
                          <Field
                            name='recoveryCodes'
                            type='radio'
                            label='For SITF'
                            value='F'
                            checked={values?.recoveryCodes === 'F'}
                            component={FieldBSRenderCheckbox}
                          />
                          <Field
                            name='recoveryCodes'
                            type='radio'
                            label='Other'
                            value='O'
                            checked={values?.recoveryCodes === 'O'}
                            component={FieldBSRenderCheckbox}
                          />
                        </div>
                        <Field
                          name='remarks'
                          label='Remarks'
                          rows={4}
                          component={FieldBSRenderTextArea}
                        />
                      </fieldset>
                      <fieldset>
                        <legend>E. Certification</legend>
                        <div className='button-icon-text justify-content-start'>
                          <FaCheckSquare className='text-primary' /> I certify
                          that the total payments are as correct as the
                          information available indicates.
                        </div>
                      </fieldset>
                      <div className='h-100 d-flex flex-column justify-content-end'>
                        <div>
                          {showSaveConfirm && (
                            <Alert
                              variant='info'
                              className='mt-3 text-center'
                              dismissible
                              onClose={() => {
                                setShowSaveConfirm(false);
                                setIsSubmitting(false);
                                setWc4IdToSave(0);
                                setShow(false);
                              }}
                            >
                              <p>You are about to SAVE the Progress Report,</p>
                              <p>are you sure you want to do this?</p>
                              <div className='d-flex justify-content-center gap1Rem'>
                                <Button
                                  type='button'
                                  onClick={() => {
                                    setShowSaveConfirm(false);
                                    setIsSubmitting(false);
                                    setWc4IdToSave(0);
                                    setShow(false);
                                  }}
                                  variant='secondary'
                                >
                                  Cancel
                                </Button>
                                <Button
                                  type='button'
                                  onClick={() => {
                                    saveProgressReport(values);
                                    setShowSaveConfirm(false);
                                  }}
                                  variant='primary'
                                >
                                  Save
                                </Button>
                              </div>
                            </Alert>
                          )}
                          {showCreateConfirm && (
                            <Alert
                              variant='info'
                              className='mt-3 text-center'
                              dismissible
                              onClose={() => {
                                setShowSaveConfirm(false);
                                setIsSubmitting(false);
                                setWc4IdToSave(0);
                                setShow(false);
                              }}
                            >
                              <p>
                                You are about to CREATE a new Progress Report,
                              </p>
                              <p>are you sure you want to do this?</p>
                              <div className='d-flex justify-content-center gap1Rem'>
                                <Button
                                  type='button'
                                  onClick={() => {
                                    setIsSubmitting(false);
                                    setShowCreateConfirm(false);
                                    setWc4IdToSave(0);
                                    setShow(false);
                                  }}
                                  variant='secondary'
                                >
                                  Cancel
                                </Button>
                                <Button
                                  type='button'
                                  onClick={() => {
                                    saveProgressReport(values);
                                    setShowCreateConfirm(false);
                                  }}
                                  variant='primary'
                                >
                                  Create
                                </Button>
                              </div>
                            </Alert>
                          )}
                          {showSaveDocument && (
                            <Alert
                              variant='info'
                              className='mt-3 text-center'
                              dismissible
                              onClose={() => {
                                setShowSaveDocument(false);
                                setWc4IdToSave(0);
                                setShow(false);
                              }}
                            >
                              <p>
                                "Would you like to save this as a Document in
                                the Documents Tab?
                              </p>
                              <div className='d-flex justify-content-center gap1Rem'>
                                <Button
                                  type='button'
                                  onClick={() => {
                                    setShowSaveDocument(false);
                                    setWc4IdToSave(0);
                                    setShow(false);
                                  }}
                                  variant='secondary'
                                >
                                  Cancel
                                </Button>
                                <Button
                                  type='button'
                                  onClick={() => {
                                    saveToDocuments();
                                    setShowCreateConfirm(false);
                                  }}
                                  variant='primary'
                                >
                                  Save to Documents Tab
                                </Button>
                              </div>
                            </Alert>
                          )}
                        </div>
                        <div className='d-flex justify-content-end gap1Rem'>
                          {/* <Button
                            type='button'
                            size='lg'
                            variant='outline-primary'
                            disabled={isSubmitting}
                            onClick={() => {
                              // TODO Reports.rptEDIProgressReport(Save(), user.EmailAddress))
                            }}
                          >
                            Preview
                          </Button> */}
                          <Button
                            type='button'
                            variant='secondary'
                            disabled={isSubmitting}
                            onClick={() => {
                              form.reset();
                              setShowCreateConfirm(false);
                              setShowSaveConfirm(false);
                              setIsSubmitting(false);
                              setShowSaveDocument(false);
                              setWc4IdToSave(0);
                              setShow(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            type='submit'
                            variant='primary'
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <Spinner
                                as='span'
                                animation='grow'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                              />
                            ) : (
                              'Save'
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
