import React from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaEdit } from 'react-icons/fa';
import { Department } from '../../ApiTypes/Department';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import styles from './index.module.css';

export default function AddEditDepartment({
  show,
  setShow,
  employerId,
  department,
  handleSubmit,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  employerId: number;
  department: Department | null;
  handleSubmit: (dept: Department) => Promise<void>;
}) {
  const onSubmit = (values: Department) => {
    return handleSubmit(values);
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='AddEditDepartment-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='AddEditDepartment-modal'>
          <FaEdit className='pe-1' /> Department
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={department ? department : { employerId }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='departmentName'
                    label='Department Name'
                    type='text'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='supervisor'
                    label='Supervisor'
                    type='text'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='clientDeptId'
                    label='Client Department Name'
                    type='text'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <div className='d-flex justify-content-center align-items-center gap1Rem my-3'>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type='submit' size='sm' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
