import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { Adjuster } from '../ApiTypes/Adjuster';
import { AttorneyType } from '../ApiTypes/AttorneyType';
import { BodyPartItem } from '../ApiTypes/BodyPart';
import { CaseManagementResolutionType } from '../ApiTypes/CaseManagementResolutionType';
import { ClaimNoteType } from '../ApiTypes/ClaimNoteType';
import { ClaimStatus } from '../ApiTypes/ClaimStatus';
import { ClassCodeItem } from '../ApiTypes/ClassCode';
import { County } from '../ApiTypes/County';
import { DispositionType } from '../ApiTypes/DispositionType';
import { DocPriority } from '../ApiTypes/DocPriority';
import { DocStatus } from '../ApiTypes/DocStatus';
import { DocType } from '../ApiTypes/DocType';
import { EdiBenefitTypeCode } from '../ApiTypes/EdiBenefitTypeCode';
import { EdiCode } from '../ApiTypes/EdiCode';
import { EdiMaintTypeCode } from '../ApiTypes/EdiMaintTypeCode';
import { EdiPartOfBodyCode } from '../ApiTypes/EdiPartOfBodyCode';
import { EntityUser } from '../ApiTypes/EntityUser';
import { InjuryCode } from '../ApiTypes/InjuryCode';
import { InsuranceCompany } from '../ApiTypes/InsuranceCompany';
import { LawFirm } from '../ApiTypes/LawFirm';
import { MedicareItem } from '../ApiTypes/MedicareItem';
import { PayCodeItem } from '../ApiTypes/PayCodeItem';
import { Pbm } from '../ApiTypes/Pbm';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { SettlementType } from '../ApiTypes/SettlementType';
import { Shift } from '../ApiTypes/Shift';
import { State } from '../ApiTypes/State';
import { WorkStatusBodyPart } from '../ApiTypes/WorkStatusBodyPart';
import { WorkStatusType } from '../ApiTypes/WorkStatusType';
import { Judge } from '../ApiTypes/Judge';
import { AppealRuling } from '../ApiTypes/AppealRuling';
import { AwardRuling } from '../ApiTypes/AwardRuling';
import { ClaimType } from '../ApiTypes/ClaimType';
import { Icdcode } from '../ApiTypes/Icdcode';
import { PlanInsuranceType } from '../ApiTypes/PlanInsuranceType';
import { InsuranceType } from '../ApiTypes/InsuranceType';
import { StopPaymentOption } from '../ApiTypes/StopPaymentOption';

class ReferenceApi {
  static getAdjusters() {
    return axios.get<Adjuster[]>(`${API_URL}/api/adjusters`, getAuthHeader());
  }
  static getAdjustersActive() {
    return axios.get<Adjuster[]>(
      `${API_URL}/api/adjusters/active`,
      getAuthHeader()
    );
  }
  static getBodyParts() {
    return axios.get<BodyPartItem[]>(
      `${API_URL}/api/bodyparts`,
      getAuthHeader()
    );
  }
  static getCounties() {
    return axios.get<County[]>(`${API_URL}/api/counties`, getAuthHeader());
  }
  static getCauses() {
    return axios.get<InjuryCode[]>(`${API_URL}/api/causes`, getAuthHeader());
  }
  static getStopPaymentOptions() {
    return axios.get<StopPaymentOption[]>(
      `${API_URL}/api/stoppaymentoptions`,
      getAuthHeader()
    );
  }
  static getResults() {
    return axios.get<InjuryCode[]>(`${API_URL}/api/results`, getAuthHeader());
  }
  static getJobClasses() {
    return axios.get<ClassCodeItem[]>(
      `${API_URL}/api/jobclasses`,
      getAuthHeader()
    );
  }
  static getStates() {
    return axios.get<State[]>(`${API_URL}/api/states`, getAuthHeader());
  }
  static getClaimStatues() {
    return axios.get<ClaimStatus[]>(
      `${API_URL}/api/claimstatuses`,
      getAuthHeader()
    );
  }
  static getMedicares() {
    return axios.get<MedicareItem[]>(
      `${API_URL}/api/medicares`,
      getAuthHeader()
    );
  }
  static getShifts() {
    return axios.get<Shift[]>(`${API_URL}/api/shifts`, getAuthHeader());
  }
  static getSupervisors() {
    return axios.get<EntityUser[]>(
      `${API_URL}/api/supervisors`,
      getAuthHeader()
    );
  }
  static getNurseCaseManagers() {
    return axios.get<EntityUser[]>(
      `${API_URL}/api/nursecasemanagers`,
      getAuthHeader()
    );
  }
  static getClaimsAssistants() {
    return axios.get<EntityUser[]>(
      `${API_URL}/api/claimsassistants`,
      getAuthHeader()
    );
  }
  static getInsuranceCompanies(activeOnly = true) {
    return axios.get<InsuranceCompany[]>(
      `${API_URL}/api/insurancecompanies?activeOnly=${activeOnly}`,
      getAuthHeader()
    );
  }
  static getClaimNoteTypes() {
    return axios.get<ClaimNoteType[]>(
      `${API_URL}/api/claimnotetypes`,
      getAuthHeader()
    );
  }
  static getPayCodes(isExcess: boolean) {
    return axios.get<PayCodeItem[]>(
      `${API_URL}/api/paycodes?isExcess=${isExcess}`,
      getAuthHeader()
    );
  }
  static getCaseManagementResolutionTypes() {
    return axios.get<CaseManagementResolutionType[]>(
      `${API_URL}/api/casemanagementresolutiontypes`,
      getAuthHeader()
    );
  }
  static createCaseManagementResolutionType(
    type: CaseManagementResolutionType
  ) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/casemanagementresolutiontypes`,
      type,
      getAuthHeader()
    );
  }
  static getAttorneyTypes() {
    return axios.get<AttorneyType[]>(
      `${API_URL}/api/attorneytypes`,
      getAuthHeader()
    );
  }
  static getLawFirms() {
    return axios.get<LawFirm[]>(`${API_URL}/api/lawfirms`, getAuthHeader());
  }
  static getSettlementTypes() {
    return axios.get<SettlementType[]>(
      `${API_URL}/api/settlementtypes`,
      getAuthHeader()
    );
  }
  static getDispositionTypes() {
    return axios.get<DispositionType[]>(
      `${API_URL}/api/dispositiontypes`,
      getAuthHeader()
    );
  }
  static getJudges() {
    return axios.get<Judge[]>(`${API_URL}/api/judges`, getAuthHeader());
  }
  static getAppealRulings() {
    return axios.get<AppealRuling[]>(
      `${API_URL}/api/appealrulings`,
      getAuthHeader()
    );
  }
  static getAwardRulings() {
    return axios.get<AwardRuling[]>(
      `${API_URL}/api/awardrulings`,
      getAuthHeader()
    );
  }
  static getDocTypes() {
    return axios.get<DocType[]>(`${API_URL}/api/doctypes`, getAuthHeader());
  }
  static getDocTypesFullScanner() {
    return axios.get<DocType[]>(
      `${API_URL}/api/doctypes/fullscannerlist`,
      getAuthHeader()
    );
  }
  static getDocStatuses() {
    return axios.get<DocStatus[]>(
      `${API_URL}/api/docstatuses`,
      getAuthHeader()
    );
  }
  static getEdiCodes() {
    return axios.get<EdiCode[]>(`${API_URL}/api/edicodes`, getAuthHeader());
  }
  static getEdiBenefitTypeCodes() {
    return axios.get<EdiBenefitTypeCode[]>(
      `${API_URL}/api/edibenefittypecodes`,
      getAuthHeader()
    );
  }
  static getEdiMaintTypeCodes() {
    return axios.get<EdiMaintTypeCode[]>(
      `${API_URL}/api/edimainttypecodes`,
      getAuthHeader()
    );
  }
  static getEdiPartOfBodyCodes() {
    return axios.get<EdiPartOfBodyCode[]>(
      `${API_URL}/api/edipartofbodycodes`,
      getAuthHeader()
    );
  }
  static getWorkStatusTypes() {
    return axios.get<WorkStatusType[]>(
      `${API_URL}/api/workstatustypes`,
      getAuthHeader()
    );
  }
  static getWorkStatusBodyParts() {
    return axios.get<WorkStatusBodyPart[]>(
      `${API_URL}/api/workstatusbodyparts`,
      getAuthHeader()
    );
  }
  static getInsuranceCompaniesForUser() {
    return axios.get<InsuranceCompany[]>(
      `${API_URL}/api/insurancecompanies/user`,
      getAuthHeader()
    );
  }
  static getPbms() {
    return axios.get<Pbm[]>(`${API_URL}/api/pbms`, getAuthHeader());
  }
  static getDocPriorities() {
    return axios.get<DocPriority[]>(
      `${API_URL}/api/docpriorities`,
      getAuthHeader()
    );
  }
  static getICDCodes() {
    return axios.get<Icdcode[]>(`${API_URL}/api/icdcodes`, getAuthHeader());
  }
  static getPlanInsuranceTypes() {
    return axios.get<PlanInsuranceType[]>(
      `${API_URL}/api/planinsurancetypes`,
      getAuthHeader()
    );
  }
  static getInsuranceTypes() {
    return axios.get<InsuranceType[]>(
      `${API_URL}/api/insurancetypes`,
      getAuthHeader()
    );
  }
  static getClaimTypes() {
    return axios.get<ClaimType[]>(`${API_URL}/api/claimtypes`, getAuthHeader());
  }
}

export default ReferenceApi;
