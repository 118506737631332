import React, { useState, useEffect } from 'react';
import { FaColumns } from 'react-icons/fa';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { Tab, Tabs } from 'react-bootstrap';

import { useParams } from 'react-router-dom';
import WcPanelApi from '../../Api/WcPanelApi';
import { WcPanel } from '../../ApiTypes/WcPanel';
import { toast } from 'react-toastify';
import PanelDetails from './PanelDetails';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { EmployerPolicyPanel } from '../../ApiTypes/EmployerPolicyPanel';
import { WcPanelCoverage } from '../../ApiTypes/WcPanelCoverage';
import { useDocViewModal } from '../DocViewModal/useDocViewModal';
import DocViewModal from '../DocViewModal/DocViewModal';
import DocumentsApi from '../../Api/DocumentsApi';
import EmployersAssigned from './EmployersAssigned';

export default function WCPanelAddEdit() {
  const { panelId } = useParams();

  const [panel, setPanel] = useState<WcPanel | null>(null);
  const [linkedInsuranceCompanies, setLinkedInsuranceCompanies] = useState<
    InsuranceCompany[]
  >([]);
  const [employerPolicyPanels, setEmployerPolicyPanels] = useState<
    EmployerPolicyPanel[]
  >([]);

  const [panelCoverages, setPanelCoverages] = useState<WcPanelCoverage[]>([]);
  const {
    showDocViewModal,
    fileDownload,
    setShowDocViewModal,
    setFileDownload,
  } = useDocViewModal();

  useEffect(() => {
    fetchAll();
  }, [panelId]);

  const fetchAll = () => {
    if (panelId && panelId !== 'new') {
      getPanelById();
      getLinkedInsuranceCompanies();
      getEmployerPolicyPanels();
      getPanelCoverages();
    }
  };

  const getPanelById = () => {
    if (panelId && panelId !== 'new') {
      WcPanelApi.getWcPanelById(+panelId)
        .then((res) => {
          setPanel(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get Panel');
        });
    }
  };

  const getLinkedInsuranceCompanies = () => {
    if (panelId && panelId !== 'new') {
      WcPanelApi.getLinkedInsuranceCompanies(+panelId)
        .then((res) => {
          setLinkedInsuranceCompanies(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get linked insurance companies for panel');
        });
    }
  };

  const getEmployerPolicyPanels = () => {
    if (panelId && panelId !== 'new') {
      WcPanelApi.getEmployerPolicyPanelsForPanel(+panelId)
        .then((res) => {
          setEmployerPolicyPanels(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get employer policy panels for panel');
        });
    }
  };

  const getPanelCoverages = () => {
    if (panelId && panelId !== 'new') {
      WcPanelApi.getPanelCoverages(+panelId)
        .then((res) => {
          setPanelCoverages(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get coverages for panel');
        });
    }
  };

  const handleViewPanel = () => {
    if (panel && panel.docId) {
      return DocumentsApi.getScanDocFile(panel?.docId)
        .then((res) => {
          if (res.data.file.length > 1) {
            setFileDownload(res.data);
            setShowDocViewModal(true);
          } else {
            toast.error('Failed to get document');
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get document');
        });
    }
  };

  const handleViewEmployerDoc = (docId: number) => {
    return DocumentsApi.getScanDocFile(docId)
      .then((res) => {
        if (res.data.file.length > 1) {
          setFileDownload(res.data);
          setShowDocViewModal(true);
        } else {
          toast.error('Failed to get document');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get document');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaColumns className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Work Comp Panel Add/Edit</h1>
          </div>
        </div>
      </div>
      <div className='w-100 h-100'>
        <Tabs defaultActiveKey='panel' id='wcpaneladd' className='mb-3'>
          <Tab eventKey='panel' title='Panel Details'>
            <PanelDetails
              panel={panel}
              panelId={panelId}
              linkedInsuranceCompanies={linkedInsuranceCompanies}
              employerPolicyPanels={employerPolicyPanels}
              panelCoverages={panelCoverages}
              handleViewPanel={handleViewPanel}
              getLinkedInsuranceCompanies={getLinkedInsuranceCompanies}
              getPanelCoverages={getPanelCoverages}
              fetchAll={fetchAll}
            />
          </Tab>
          {panelId && panelId !== 'new' && (
            <Tab eventKey='employers' title='Employers Assigned'>
              <EmployersAssigned
                panel={panel}
                employerPolicyPanels={employerPolicyPanels}
                handleViewEmployerDoc={handleViewEmployerDoc}
                fetchAll={fetchAll}
              />
            </Tab>
          )}
        </Tabs>
      </div>
      <DocViewModal
        show={showDocViewModal}
        setShow={setShowDocViewModal}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
    </PageScaffold>
  );
}
