import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaUserCog } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { SiteFunctionsEnum } from '../../../ApiTypes/SiteFunctions';
import { useAppSelector } from '../../../Reducers/Store';
import ApprovalCodeModal from '../../ApprovalCodeModal/ApprovalCodeModal';
import ExportDocsModal from '../../ExportDocs/ExportDocsModal';
import { ExportDocumentsRequest } from '../../../ApiTypes/ExportDocumentsRequest';
import DocumentsApi from '../../../Api/DocumentsApi';
import ClassCodesModal from '../../ClassCodes/ClassCodesModal';
import ReassignAccount from '../../ReasignAccount/ReassignAccount';
import { toast } from 'react-toastify';
import EdiTransactionApi from '../../../Api/EdiTransactionApi';

export default function SetupDropdown() {
  const { userModel } = useAppSelector((state) => state.user);
  const [showApprovalCode, setShowApprovalCode] = useState<boolean>(false);
  const [showExportDocs, setShowExportDocs] = useState<boolean>(false);
  const [showClassCodes, setShowClassCodes] = useState<boolean>(false);
  const [showReassign, setShowReassign] = useState<boolean>(false);

  const exportDocs = (values: { claimNumber: string; insurcoid: number }) => {
    const request: ExportDocumentsRequest = {
      claimNumber: values.claimNumber ?? '',
      insuranceCompanyId: values.insurcoid ?? 0,
    };

    DocumentsApi.exportDocuments(request);
    // .then((res) => {
    //   if (res.data.success) {
    //     toast.success('Check your email');
    //   } else {
    //     toast.error('Failed to get documents');
    //   }
    // })
    // .catch((err) => {
    //   console.log(err);
    //   toast.error('Failed to get documents');
    // });
    setShowExportDocs(false);
  };
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant='secondary'
        id='setup'
        className='button-icon-text'
      >
        <FaUserCog />
        Setup
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.TableMaintenance && f.enabled
        ) && (
          <Dropdown drop='end'>
            <Dropdown.Toggle
              variant='light'
              id='claim-reports'
              className='w-100 text-start bg-transparent'
            >
              Table Maintenance
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Link to='/insurance-companies'>
                <Dropdown.Item as='button'>Insurance Companies</Dropdown.Item>
              </Link>
              <Link to='/employers'>
                <Dropdown.Item as='button'>Employers</Dropdown.Item>
              </Link>
              {userModel?.userSiteFunctions?.some(
                (f) =>
                  f.siteFunctionId === SiteFunctionsEnum.Departments &&
                  f.enabled
              ) && (
                <Link to='/departments'>
                  <Dropdown.Item as='button'>Departments</Dropdown.Item>
                </Link>
              )}
              {userModel?.userSiteFunctions?.some(
                (f) =>
                  f.siteFunctionId === SiteFunctionsEnum.Policies && f.enabled
              ) && (
                <Link to='/policies'>
                  <Dropdown.Item as='button'>Policies</Dropdown.Item>
                </Link>
              )}
              <Link to='/excess-carriers'>
                <Dropdown.Item as='button'>Excess Carriers</Dropdown.Item>
              </Link>
              {userModel?.userSiteFunctions?.some(
                (f) => f.siteFunctionId === SiteFunctionsEnum.Users && f.enabled
              ) && (
                <Link to='/users'>
                  <Dropdown.Item as='button'>Users</Dropdown.Item>
                </Link>
              )}
              {userModel?.userSiteFunctions?.some(
                (f) =>
                  f.siteFunctionId ===
                    SiteFunctionsEnum.SiteFunctionGroupSetup && f.enabled
              ) && (
                <Link to='/sitefunction-setup'>
                  <Dropdown.Item as='button'>
                    Site Function Group Setup
                  </Dropdown.Item>
                </Link>
              )}
              {userModel?.userSiteFunctions?.some(
                (f) => f.siteFunctionId === SiteFunctionsEnum.Users && f.enabled
              ) && (
                <Link to='/user-permissions'>
                  <Dropdown.Item as='button'>
                    Verify User Permissions
                  </Dropdown.Item>
                </Link>
              )}
              <Link to='/uwsync'>
                <Dropdown.Item as='button'>Sync UW Policies</Dropdown.Item>
              </Link>
              <Dropdown.Item
                as='button'
                onClick={() => setShowApprovalCode(true)}
              >
                Approval Code
              </Dropdown.Item>
              <Link to='/premium-history'>
                <Dropdown.Item as='button'>
                  Policy Premium Histories
                </Dropdown.Item>
              </Link>
              <Dropdown.Item
                as='button'
                onClick={() => setShowExportDocs(true)}
              >
                Export Documents
              </Dropdown.Item>
              <Link to='/panels'>
                <Dropdown.Item as='button'>Panels</Dropdown.Item>
              </Link>
              <Link to='/pbms'>
                <Dropdown.Item as='button'>PBMs</Dropdown.Item>
              </Link>
              <Dropdown.Item
                as='button'
                onClick={() => {
                  setShowClassCodes(true);
                }}
              >
                Class Codes
              </Dropdown.Item>
              <Link to='/letters'>
                <Dropdown.Item as='button'>Letters</Dropdown.Item>
              </Link>
              <Link to='/notetypes'>
                <Dropdown.Item as='button'>Note Types</Dropdown.Item>
              </Link>
              <Dropdown.Item as='button' onClick={() => setShowReassign(true)}>
                ReAssign Account
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {/* <Link to='/claims'>
          <Dropdown.Item as='button'>Create New Report</Dropdown.Item>
        </Link> */}
        <Link to='/emails'>
          <Dropdown.Item as='button'>Manage Emails</Dropdown.Item>
        </Link>
        <Link to='/edilogs'>
          <Dropdown.Item as='button'>EDI Log</Dropdown.Item>
        </Link>
        {userModel?.userSiteFunctions?.some(
          (f) => f.siteFunctionId === SiteFunctionsEnum.WCPanels && f.enabled
        ) && (
          <Link to='/wcpanels'>
            <Dropdown.Item as='button'>WC Panels</Dropdown.Item>
          </Link>
        )}
        <Dropdown.Item
          as='button'
          onClick={async () => {
            try {
              const res = await toast.promise(
                EdiTransactionApi.removeSBWCDuplicates(),
                {
                  pending: `Removing Duplicates...`,
                  success: 'Success',
                  error: `Failed to remove duplicates`,
                }
              );
              if (res.data.success) {
              } else {
                toast.error(res.data.message);
              }
            } catch (error) {
              console.log(error);
            }
          }}
        >
          Remove SBWC Duplicates
        </Dropdown.Item>
        <Dropdown drop='end'>
          <Dropdown.Toggle
            variant='light'
            id='claim-reports'
            className='w-100 text-start bg-transparent'
          >
            Logs
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Link to='/userlogs'>
              <Dropdown.Item as='button'>User Logs</Dropdown.Item>
            </Link>
            <Link to='/insurance-company-logs'>
              <Dropdown.Item as='button'>Ins. Co. Logs</Dropdown.Item>
            </Link>
          </Dropdown.Menu>
        </Dropdown>
      </Dropdown.Menu>
      {showApprovalCode && (
        <ApprovalCodeModal
          show={showApprovalCode}
          setShow={setShowApprovalCode}
        />
      )}
      <ExportDocsModal
        show={showExportDocs}
        setShow={setShowExportDocs}
        claimNumber=''
        confirm={exportDocs}
      />
      {showClassCodes && (
        <ClassCodesModal
          show={showClassCodes}
          setShow={setShowClassCodes}
          policyId={0}
          isExcess={false}
          refreshData={() => {}}
        />
      )}
      {showReassign && (
        <ReassignAccount show={showReassign} setShow={setShowReassign} />
      )}
    </Dropdown>
  );
}
