import { Button, Container, Modal, Spinner, Form } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { Form as RFForm, Field } from 'react-final-form';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderFilePicker from '../Common/FieldBSRenderFilePicker';

import styles from './index.module.css';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { Employer } from '../../ApiTypes/Employer';
import { InsCoEmployerEmailFile } from '../../ApiTypes/InsCoEmployerEmailFile';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';

export default function AddEditClaimEmailDocs({
  show,
  setShow,
  inscoId,
  employerId,
  insCoEmployerEmailFile,
  handleAddUpdate,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  inscoId: number | null;
  employerId: number | null;
  insCoEmployerEmailFile: InsCoEmployerEmailFile | null;
  handleAddUpdate: (values: InsCoEmployerEmailFile) => Promise<void>;
}) {
  const onSubmit = (values: InsCoEmployerEmailFile) => {
    return handleAddUpdate(values);
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      // dialogClassName={styles.largeModal}
      aria-labelledby='AddEditInsCoEmployerEmailDocs-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='AddEditInsCoEmployerEmailDocs-modal'
        >
          <FaEdit className='pe-1' />{' '}
          {inscoId !== null
            ? 'Insurance Company Email Files'
            : 'Employer Email Files'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={
              insCoEmployerEmailFile
                ? insCoEmployerEmailFile
                : {
                    insCoId: inscoId,
                    employerId: employerId,
                    dateAdded: Date.now.toString(),
                    useInEmail: true,
                  }
            }
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  {/* <Field
                    name='fileURL'
                    type='file'
                    validate={requiredField}
                    component={FieldBSRenderFilePicker}
                  /> */}
                  {!insCoEmployerEmailFile && (
                    <Field
                      name='doc'
                      type='file'
                      validate={requiredField}
                      component={FieldBSRenderFilePicker}
                    />
                  )}
                  <Field
                    name='friendlyFileName'
                    type='text'
                    label='File Name'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='useInEmail'
                    label='Use In Email'
                    type='checkbox'
                    checked={values?.useInEmail}
                    component={FieldBSRenderCheckbox}
                  />
                  <div className={`${styles.buttonDiv} mt-3`}>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        // form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
