import React, { useState, useEffect, useCallback } from 'react';
import { Field, Form as RFFForm } from 'react-final-form';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { Modal, Container, Form, Button, Spinner } from 'react-bootstrap';
import { FaEdit, FaPlusCircle } from 'react-icons/fa';
import PhysicianApi from '../../Api/PhysicianApi';
import { ClaimAuthorizedPhysician } from '../../ApiTypes/ClaimAuthorizedPhysician';
import { Physician } from '../../ApiTypes/Physician';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { requiredField, zipLength } from '../../Utils/FieldValidation';
import { DoctorLocation } from '../../ApiTypes/DoctorLocation';
import DoctorOfficeApi from '../../Api/DoctorOfficeApi';
import { DoctorsOffice } from '../../ApiTypes/DoctorsOffice';
import { PhysicianTypes } from '../../ApiTypes/PhysicianTypes';

import styles from './index.module.css';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import ViewAddPhysicianForm from '../ViewAddPhysician/ViewAddPhysicianForm';
import { toast } from 'react-toastify';
import { FormApi } from 'final-form';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import { useAppSelector } from '../../Reducers/Store';
import { State } from '../../ApiTypes/State';
import DoctorLocationApi from '../../Api/DoctorLocationApi';
import { format } from 'date-fns';
import { ClaimAuthorizedPhysicianBodyPart } from '../../ApiTypes/ClaimAuthorizedPhysicianBodyPart';

export default function AddUpdateClaimAuthorizedPhysician({
  show,
  setShow,
  claimNumber,
  claimPhysician,
  setClaimPhysician,
  allPhysicians,
  getAllPhysicians,
  getClaimAuthorizedPhysicians,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  claimNumber: string;
  claimPhysician: ClaimAuthorizedPhysician | null;
  setClaimPhysician: (p: ClaimAuthorizedPhysician | null) => void;
  allPhysicians: Physician[];
  getAllPhysicians: () => Promise<void>;
  getClaimAuthorizedPhysicians: () => void;
}) {
  const [locations, setLocations] = useState<DoctorLocation[]>([]);
  const [office, setOffice] = useState<DoctorsOffice | null>(null);
  const [showNewPhysician, setShowNewPhysician] = useState<boolean>(false);
  const [showNewLocation, setShowNewLocation] = useState<boolean>(false);
  const [physicianId, setPhysicianId] = useState<number>(0);
  const [selectedPhysician, setSelectedPhysician] = useState<Physician | null>(
    null
  );

  const { workStatusBodyParts } = useAppSelector((state) => state.reference);
  const { states } = useAppSelector((state) => state.reference);

  const containerWidth = 752;
  let formInstance: FormApi<
    ClaimAuthorizedPhysician,
    Partial<ClaimAuthorizedPhysician>
  >;

  const getLocations = useCallback(
    (id: number, claimPhys?: ClaimAuthorizedPhysician | null) => {
      if (claimPhys && claimPhys !== null) {
        DoctorLocationApi.getDoctorLocationsByDoctorId(
          claimPhys?.physician?.doctorId!
        )
          .then((res) => {
            setLocations(res.data);
          })
          .catch((err) => console.log(err));
        getOffice(claimPhys.physician?.doctorId!);
        setSelectedPhysician(claimPhys.physician);
      } else if (+id > 0) {
        PhysicianApi.getPhysicianById(id)
          .then((res) => {
            if (res.data !== null && (res.data?.doctorId ?? 0) > 0) {
              setSelectedPhysician(res.data);
              DoctorLocationApi.getDoctorLocationsByDoctorId(
                res.data?.doctorId!
              )
                .then((res) => {
                  setLocations(res.data);
                })
                .catch((err) => console.log(err));
              getOffice(res.data?.doctorId!);
            }
          })
          .catch((err) => console.log(err));
      } else {
        setLocations([]);
      }
    },
    []
  );

  useEffect(() => {
    getLocations(0, claimPhysician);
  }, [claimPhysician, getLocations]);

  const onSubmit = (values: ClaimAuthorizedPhysician) => {
    const cap: ClaimAuthorizedPhysician = {
      id: values.id ?? 0,
      claimNo: values.claimNo ?? claimNumber,
      physicianId: values.physicianId,
      dateAdded: values.dateAdded ?? format(new Date(), 'yyyy-MM-dd'),
      bodyPartId: null,
      physicianTypeId: values.physicianTypeId,
      authorized: values.authorized,
      doctorLocationId: values.doctorLocationId,
      doctorLocation: null,
      physician: null,
      address: '',
      claimAuthorizedPhysicianBodyParts:
        values.claimAuthorizedPhysicianBodyParts,
    };

    if (cap.id > 0) {
      return updateClaimAuthorizedPhysician(cap);
    } else {
      return addClaimAuthorizedPhysician(cap);
    }
  };

  const addClaimAuthorizedPhysician = (cap: ClaimAuthorizedPhysician) => {
    return PhysicianApi.addClaimAuthorizedPhysician(cap)
      .then((res) => {
        if (res.data.success) {
          getClaimAuthorizedPhysicians();
          setShow(false);
          setClaimPhysician(null);
          setShowNewLocation(false);
          setShowNewPhysician(false);
          setSelectedPhysician(null);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to add claim authorized physician');
      });
  };
  const updateClaimAuthorizedPhysician = (cap: ClaimAuthorizedPhysician) => {
    return PhysicianApi.updateClaimAuthorizedPhysician(cap)
      .then((res) => {
        if (res.data.success) {
          getClaimAuthorizedPhysicians();
          setShow(false);
          setClaimPhysician(null);
          setShowNewLocation(false);
          setShowNewPhysician(false);
          setSelectedPhysician(null);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update claim authorized physician');
      });
  };

  const getOffice = (id: number) => {
    if (id > 0) {
      DoctorOfficeApi.getDoctorsOfficeById(id)
        .then((res) => {
          setOffice(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      setOffice(null);
    }
  };

  const createAddressText = (loc: DoctorLocation) => {
    let text = office?.officeName ?? '';
    text += ` - ${loc.address}`;
    if (loc.address2) {
      text += `, ${loc.address2 ?? ''}`;
    }
    if (loc.city) {
      text += `, ${loc.city ?? ''}, ${loc.state ?? ''} ${loc.zip ?? ''}`;
    }
    return text;
  };

  ///////
  const submitAddView = (values: Physician) => {
    if (values.id > 0) {
      return updatePhysician(values);
    } else {
      return createPhysician(values);
    }
  };

  const createPhysician = (values: Physician) => {
    return PhysicianApi.createPhysician(values)
      .then((res) => {
        if (res.data.success) {
          setPhysicianId(0);
          getAllPhysicians().then((r) => {
            console.log(r);
            formInstance.change(
              'physicianId',
              +res.data.affectedEntityIdentifier
            );
            getLocations(+res.data.affectedEntityIdentifier);
          });
          setShowNewPhysician(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create new physician');
      });
  };
  const updatePhysician = (values: Physician) => {
    return PhysicianApi.updatePhysician(values)
      .then((res) => {
        if (res.data.success) {
          setPhysicianId(0);
          getAllPhysicians().then((r) => {
            formInstance.change(
              'physicianId',
              +res.data.affectedEntityIdentifier
            );
          });
          setShowNewPhysician(false);
          getAllPhysicians();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update new physician');
      });
  };

  const locationSubmit = (values: DoctorLocation) => {
    if (values.id > 0) {
      return updateDoctorLocation(values);
    } else {
      return createDoctorLocation(values);
    }
  };

  const createDoctorLocation = (values: DoctorLocation) => {
    return DoctorLocationApi.createDoctorLocation(values)
      .then((res) => {
        if (res.data.success) {
          getLocations(selectedPhysician?.id ?? 0);
          formInstance.change(
            'doctorLocationId',
            +res.data.affectedEntityIdentifier
          );
          setShowNewLocation(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create doctor location');
      });
  };
  const updateDoctorLocation = (values: DoctorLocation) => {
    return DoctorLocationApi.updateDoctorLocation(values)
      .then((res) => {
        if (res.data.success) {
          getLocations(selectedPhysician?.id ?? 0);
          formInstance.change(
            'doctorLocationId',
            +res.data.affectedEntityIdentifier
          );
          setShowNewLocation(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update doctor location');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setClaimPhysician(null);
        setShow(false);
        setShowNewLocation(false);
        setShowNewPhysician(false);
        setSelectedPhysician(null);
      }}
      dialogClassName=''
      aria-labelledby='Add-Update-ClaimAuthorizedPhysician-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Add-Update-ClaimAuthorizedPhysician-Form-modal'
        >
          {!showNewPhysician && !showNewLocation && (
            <>
              {claimPhysician ? (
                <>
                  <FaEdit className='pe-1' />
                  Edit Claim Physician
                </>
              ) : (
                <>
                  <FaPlusCircle className='pe-1' />
                  Add Claim Physician
                </>
              )}
            </>
          )}
          {showNewPhysician && (
            <>
              <FaPlusCircle /> Add Physician
            </>
          )}
          {showNewLocation && (
            <>
              <FaPlusCircle /> Add Location
            </>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <div
            style={{
              width: `${containerWidth}px`,
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                width: '2256px',
                height: '100%',
                display: 'grid',
                gridTemplateColumns: '752px 752px 752px',
                position: 'relative',
                left: `${
                  !showNewPhysician && !showNewLocation
                    ? 0
                    : showNewPhysician
                    ? -containerWidth
                    : -(containerWidth * 2)
                }px`,
              }}
            >
              <RFFForm
                onSubmit={onSubmit}
                initialValues={
                  claimPhysician ?? {
                    claimNo: claimNumber,
                  }
                }
                render={({ handleSubmit, form, values, submitting }) => {
                  formInstance = form;
                  return (
                    <Form onSubmit={handleSubmit}>
                      <div className={`${styles.officeGrid}`}>
                        {/* <Field
                          name='physicianId'
                          label='Physician'
                          options={allPhysicians}
                          optionMethod={(options: Physician[]) =>
                            options.map((o) => (
                              <option key={o.id} value={o.id}>
                                {o.displayName}
                              </option>
                            ))
                          }
                          onChange={(id: number) => {
                            getLocations(id);
                          }}
                          validate={requiredField}
                          component={FieldBSRenderSelect}
                        /> */}
                        <Field name='physicianId' validate={requiredField}>
                          {({ input, meta: { touched, error } }) => (
                            <div
                              className={`position-relative ${styles.vFieldHeight}`}
                            >
                              <label
                                htmlFor={input.name}
                                className='form-label fs-6  m-0'
                              >
                                Physician
                              </label>
                              <Select
                                name={input.name}
                                placeholder='Physician'
                                isClearable
                                defaultValue={
                                  claimPhysician?.physician
                                    ? {
                                        id: claimPhysician.physician.id,
                                        displayName:
                                          claimPhysician.physician.displayName,
                                      }
                                    : null
                                }
                                onChange={(e) => {
                                  if (e) {
                                    input.onChange(e.id);
                                    getLocations(e.id);
                                  } else {
                                    input.onChange(null);
                                    getLocations(0);
                                  }
                                }}
                                styles={{
                                  control: (baseStyles: any, state: any) => ({
                                    ...baseStyles,
                                    borderColor: touched && error && 'red',
                                  }),
                                }}
                                options={allPhysicians.map((x) => ({
                                  id: x.id,
                                  displayName: x.displayName,
                                }))}
                                getOptionLabel={(d) => d.displayName ?? 'N/A'}
                                getOptionValue={(d) => `${d.id}`}
                              />

                              {touched && error && (
                                <span
                                  className={`${styles.fieldError} text-danger`}
                                >
                                  {error}
                                </span>
                              )}
                            </div>
                          )}
                        </Field>
                        <Button
                          type='button'
                          variant='outline-primary'
                          size='sm'
                          onClick={() => {
                            setShowNewPhysician(true);
                          }}
                          className='button-icon-text'
                        >
                          <FaPlusCircle /> New
                        </Button>
                      </div>
                      <div className={`${styles.officeGrid}`}>
                        <Field
                          name='doctorLocationId'
                          label='Address'
                          options={locations}
                          optionMethod={(options: DoctorLocation[]) =>
                            options.map((o) => (
                              <option key={o.id} value={o.id}>
                                {createAddressText(o)}
                              </option>
                            ))
                          }
                          component={FieldBSRenderSelect}
                        />
                        <Button
                          type='button'
                          variant='outline-primary'
                          size='sm'
                          onClick={() => {
                            setShowNewLocation(true);
                          }}
                          className='button-icon-text'
                        >
                          <FaPlusCircle /> New
                        </Button>
                      </div>
                      <Field
                        name='physicianTypeId'
                        label='Type'
                        options={Object.values(PhysicianTypes).filter((value) =>
                          isNaN(Number(PhysicianTypes[value as number]))
                        )}
                        optionMethod={(options: number[]) =>
                          options.map((o) => (
                            <option key={o} value={o}>
                              {PhysicianTypes[o]}
                            </option>
                          ))
                        }
                        validate={requiredField}
                        component={FieldBSRenderSelect}
                      />
                      <Field
                        name='claimAuthorizedPhysicianBodyParts'
                        validate={requiredField}
                      >
                        {({ input, meta: { touched, error } }) => (
                          <div
                            className={`position-relative ${styles.vFieldHeight}`}
                          >
                            <label
                              htmlFor={input.name}
                              className='form-label fs-6  m-0'
                            >
                              Body Part(s)
                            </label>
                            <Select
                              name={input.name}
                              placeholder='Body Parts'
                              isClearable
                              defaultValue={
                                claimPhysician?.claimAuthorizedPhysicianBodyParts ??
                                []
                              }
                              isMulti
                              onChange={(e) => {
                                if (e) {
                                  input.onChange(e);
                                } else {
                                  input.onChange(null);
                                }
                              }}
                              styles={{
                                control: (baseStyles: any, state: any) => ({
                                  ...baseStyles,
                                  borderColor: touched && error && 'red',
                                }),
                              }}
                              options={workStatusBodyParts.map((x) => ({
                                id: 0,
                                claimAuthorizedPhysicianId:
                                  claimPhysician?.id ?? 0,
                                bodyPartId: 2,
                                bodyPartName: x.bodyPart,
                                bodyPart: null,
                                claimAuthorizedPhysician: null,
                              }))}
                              getOptionLabel={(d) => d.bodyPartName ?? 'N/A'}
                              getOptionValue={(d) => d.bodyPartName ?? 'N/A'}
                            />

                            {touched && error && (
                              <span
                                className={`${styles.fieldError} text-danger`}
                              >
                                {error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field
                        name='authorized'
                        label='Authorized'
                        type='checkbox'
                        checked={values.authorized == true}
                        component={FieldBSRenderCheckbox}
                      />
                      <div className={`${styles.buttonDiv} mt-3`}>
                        <Button type='submit' variant='primary' size='sm'>
                          {submitting ? (
                            <Spinner
                              as='span'
                              animation='grow'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                            />
                          ) : (
                            'Save'
                          )}
                        </Button>
                        <Button
                          type='button'
                          variant='secondary'
                          size='sm'
                          onClick={() => {
                            form.reset();
                            setShow(false);
                            setClaimPhysician(null);
                            setShowNewLocation(false);
                            setShowNewPhysician(false);
                            setSelectedPhysician(null);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              />
              <ViewAddPhysicianForm
                show={showNewPhysician}
                setShow={setShowNewPhysician}
                physicianId={physicianId}
                setPhysicianId={setPhysicianId}
                submitAddView={submitAddView}
              />
              <div>
                <RFFForm
                  onSubmit={locationSubmit}
                  initialValues={{
                    id: 0,
                    doctorId: selectedPhysician?.doctorId ?? 0,
                  }}
                  render={({ handleSubmit, form, values, submitting }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <Field
                          name='address'
                          label='Address'
                          type='text'
                          validate={requiredField}
                          component={FieldBSRenderText}
                        />
                        <Field
                          name='address2'
                          label='Address 2'
                          type='text'
                          component={FieldBSRenderText}
                        />
                        <div className={`${styles.grid3}`}>
                          <Field
                            name='city'
                            type='text'
                            label='City'
                            validate={requiredField}
                            component={FieldBSRenderText}
                          />
                          <Field
                            name='state'
                            label='State'
                            options={states}
                            optionMethod={(options: State[]) =>
                              options.map((o) => (
                                <option key={o.stateAbbr} value={o.stateAbbr}>
                                  {o.stateAbbr}
                                </option>
                              ))
                            }
                            validate={requiredField}
                            component={FieldBSRenderSelect}
                          />
                          <Field
                            name='zip'
                            type='text'
                            label='Zip'
                            validate={zipLength}
                            component={FieldBSRenderText}
                            maxLength={5}
                          />
                        </div>
                        <div className={`${styles.buttonDiv} mt-3`}>
                          <Button type='submit' variant='primary' size='sm'>
                            {submitting ? (
                              <Spinner
                                as='span'
                                animation='grow'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                              />
                            ) : (
                              'Save'
                            )}
                          </Button>
                          <Button
                            type='button'
                            variant='secondary'
                            size='sm'
                            onClick={() => {
                              form.reset();
                              setShowNewLocation(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
