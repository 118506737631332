import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { FaColumns, FaPlusCircle } from 'react-icons/fa';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import PanelApi from '../../Api/PanelApi';
import { toast } from 'react-toastify';
import { Panel } from '../../ApiTypes/Panel';
import { DoctorsOffice } from '../../ApiTypes/DoctorsOffice';
import { DoctorLocation } from '../../ApiTypes/DoctorLocation';
import PanelTable from './PanelTable';
import { Button } from 'react-bootstrap';

export default function PanelView() {
  const { panelId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [panel, setPanel] = useState<Panel | null>(null);
  const [doctorOffices, setDoctorOffices] = useState<DoctorsOffice[]>([]);

  useEffect(() => {
    onLocationChange();
  }, [location]);

  useEffect(() => {
    getById();
    getDoctorOfficesForPanel();
  }, [panelId]);

  const onLocationChange = () => {
    if (location.search) {
      const { insurCoId } = queryString.parse(location.search);
      if (insurCoId && +insurCoId > 0) {
      } else {
      }
    } else {
    }
  };

  const getById = () => {
    if (panelId) {
      PanelApi.getPanelById(+panelId)
        .then((res) => {
          setPanel(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get panel');
        });
    } else {
      setPanel(null);
    }
  };
  const getDoctorOfficesForPanel = () => {
    if (panelId) {
      PanelApi.getDoctorOfficesForPanel(+panelId)
        .then((res) => {
          setDoctorOffices(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get doctor offices');
        });
    } else {
      setDoctorOffices([]);
    }
  };

  const handleRemove = (l: DoctorsOffice) => {
    if (panelId) {
      PanelApi.removeOfficeFromPanel(+panelId, l.id)
        .then((res) => {
          if (res.data.success) {
            getDoctorOfficesForPanel();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to remove');
        });
    }
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaColumns className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Panel{panel ? `: ${panel.panelName}` : ''}</h1>
          </div>
          <div className='ms-3'></div>
        </div>
      </div>
      <div className='d-flex justify-content-center py-3'>
        <Link to={`/panels/${panelId}/add-locations`}>
          <Button
            type='button'
            variant='primary'
            size='sm'
            className='button-icon-text'
          >
            <FaPlusCircle /> Location
          </Button>
        </Link>
      </div>
      <PanelTable data={doctorOffices} handleRemove={handleRemove} />
    </PageScaffold>
  );
}
