import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ExcessCarrier } from '../ApiTypes/ExcessCarrier';
import { ExcessCarrierPolicy } from '../ApiTypes/ExcessCarrierPolicy';
import { ExcessDetailObj } from '../ApiTypes/ExcessDetailObj';
import { FileDownload } from '../Types/FileDownLoad';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class ExcessCarrierApi {
  static getExcessReport(insurCoId: number, treatyYear: number) {
    return axios.get<ExcessDetailObj[]>(
      `${API_URL}/api/excesscarriers/details?insurcoid=${insurCoId}&treatyYear=${treatyYear}`,
      getAuthHeader()
    );
  }
  static getExport(insurCoId: number, treatyYear: number) {
    return axios.get<FileDownload>(
      `${API_URL}/api/excesscarriers/details/export?insurcoid=${insurCoId}&treatyYear=${treatyYear}`,
      getAuthHeader()
    );
  }
  static list() {
    return axios.get<ExcessCarrier[]>(
      `${API_URL}/api/excesscarriers/list`,
      getAuthHeader()
    );
  }
  static getForCarrier(id: number) {
    return axios.get<ExcessCarrierPolicy[]>(
      `${API_URL}/api/excesscarriers/${id}/policies`,
      getAuthHeader()
    );
  }
  static getById(id: number) {
    return axios.get<ExcessCarrier>(
      `${API_URL}/api/excesscarriers/${id}`,
      getAuthHeader()
    );
  }
  static create(excessCarrier: ExcessCarrier) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/excesscarriers`,
      excessCarrier,
      getAuthHeader()
    );
  }
  static update(excessCarrier: ExcessCarrier) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/excesscarriers/${excessCarrier.excessCarrierId}`,
      excessCarrier,
      getAuthHeader()
    );
  }
}

export default ExcessCarrierApi;
