import React from 'react';
import { Button, Container, Form, Modal } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaCheck } from 'react-icons/fa';
import { ChangeClaimNumberRequest } from '../../ApiTypes/ChangeClaimNumberRequest';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import styles from './index.module.css';

export default function ChangeClaimNumberModal({
  show,
  setShow,
  confirm,
  claimNumber,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  confirm: (values: ChangeClaimNumberRequest) => Promise<void>;
  claimNumber: string;
}) {
  const onSubmit = (values: ChangeClaimNumberRequest) => {
    return confirm(values);
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='Confirm-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Confirm-modal'>
          <FaCheck className='pe-1' /> Confirm
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              oldClaimNumber: claimNumber,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='oldClaimNumber'
                    label='Previous Claim Number'
                    type='text'
                    readOnly
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='newClaimNumber'
                    label='New Claim Number'
                    type='text'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <div className={styles.confirmButtonsDiv}>
                    <Button type='submit' size='lg' variant='primary'>
                      Submit
                    </Button>
                    <Button
                      type='button'
                      size='lg'
                      variant='secondary'
                      onClick={() => {
                        form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
