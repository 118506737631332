import React from 'react';
import { Form as RFForm, Field } from 'react-final-form';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { FaCheck, FaEdit, FaEnvelope } from 'react-icons/fa';
import styles from './index.module.css';
import { ScanDoc } from '../../ApiTypes/ScanDoc';
import { ScanDocSimple } from '../../ApiTypes/ScanDocSimple';
import { parseDatesForServer } from '../../Utils';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import { useAppSelector } from '../../Reducers/Store';
import { DocType } from '../../ApiTypes/DocType';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { DocPriority } from '../../ApiTypes/DocPriority';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import DocumentsApi from '../../Api/DocumentsApi';
import { UpdateScanDocRequest } from '../../ApiTypes/UpdateScanDocRequest';
import { toast } from 'react-toastify';
import { DocumentType } from '../../ApiTypes/DocumentType';
import { useNavigate } from 'react-router-dom';

import { Claim } from '../../ApiTypes/Claim';
import { format } from 'date-fns';

export default function EditDocumentProperties({
  show,
  setShow,
  scanDoc,
  scanDocSimple,
  refresh,
  claim,
  handleStraightPay,
  handleBillReview,
  handleApprove,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  scanDoc: ScanDoc | null;
  scanDocSimple: ScanDocSimple | null;
  refresh: () => void;
  claim?: Claim;
  handleStraightPay: (doc: ScanDocSimple) => void;
  handleBillReview: (doc: ScanDocSimple) => void;
  handleApprove: (doc: ScanDocSimple) => void;
}) {
  const navigate = useNavigate();
  const { docStatuses, docTypes, docPriorities } = useAppSelector(
    (state) => state.reference
  );
  const onSubmit = (values: ScanDoc) => {
    var request: UpdateScanDocRequest = {
      docId: values.docId,
      serviceFrom: values.serviceFrom,
      serviceTo: values.serviceTo,
      docTypeId: values.doctypeid,
      priorityId: values.priorityId,
      notes: values.note ?? '',
    };
    return DocumentsApi.updateScanDocProperties(request, values.claimno ?? '')
      .then((res) => {
        if (res.data.success) {
          toast.success('Updated');
          // refresh();
          if (+request.docTypeId! === DocumentType.WorkStatus) {
            navigate(`/claims/${values.claimno}/work-status`);
          }
          return true;
        } else {
          toast.error(res.data.message);
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update scan doc');
        return false;
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        // refresh();
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='EditDocumentProperties-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='EditDocumentProperties-modal'
        >
          <FaEdit className='pe-1' /> Edit Document Properties
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={
              scanDoc
                ? {
                    docId: scanDoc.docId,
                    serviceFrom: scanDoc.serviceFrom,
                    serviceTo: scanDoc.serviceTo,
                    doctypeid: scanDoc.doctypeid,
                    priorityId: scanDoc.priorityId,
                    note: scanDoc.note,
                    claimno: scanDoc.claimno,
                  }
                : scanDocSimple
                ? {
                    docId: scanDocSimple.docid,
                    serviceFrom: scanDocSimple.serviceFrom,
                    serviceTo: scanDocSimple.serviceTo,
                    doctypeid: scanDocSimple.doctypeid,
                    note: scanDocSimple.note,
                    claimno: scanDocSimple.claimno,
                    priorityId: docPriorities.find(
                      (x) => x.name === scanDocSimple.priority
                    )?.keyDocPriorityId,
                  }
                : {}
            }
            render={({ handleSubmit, form, values, submitting, pristine }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className='d-flex gap1Rem'>
                    <Field
                      name='serviceFrom'
                      label='Service From'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                      onBlur={(value: string) => {
                        if (value) {
                          form.change('serviceTo', values.serviceFrom);
                        }
                      }}
                    />
                    <Field
                      name='serviceTo'
                      label='Service To'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                  </div>
                  <div className='d-flex gap1Rem'>
                    <div style={{ minWidth: '205px' }}>
                      <Field
                        name='doctypeid'
                        label='Type'
                        options={docTypes}
                        optionMethod={(options: DocType[]) =>
                          options.map((o) => (
                            <option key={o.docTypeId} value={o.docTypeId}>
                              {o.description}
                            </option>
                          ))
                        }
                        validate={requiredField}
                        component={FieldBSRenderSelect}
                      />
                    </div>
                    <div style={{ minWidth: '205px' }}>
                      <Field
                        name='priorityId'
                        label='Priority'
                        options={docPriorities}
                        optionMethod={(options: DocPriority[]) =>
                          options.map((o) => (
                            <option
                              key={o.keyDocPriorityId}
                              value={o.keyDocPriorityId}
                            >
                              {o.name}
                            </option>
                          ))
                        }
                        validate={requiredField}
                        component={FieldBSRenderSelect}
                      />
                    </div>
                  </div>
                  <Field
                    name='note'
                    label='Description'
                    type='text'
                    component={FieldBSRenderText}
                  />
                  <div className={styles.buttonDiv}>
                    <Button
                      type='button'
                      size='sm'
                      variant='primary'
                      onClick={() => {
                        form.submit()?.then((res) => {
                          if (res) {
                            refresh();
                          }
                        });
                      }}
                    >
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                    <Button
                      type='button'
                      size='sm'
                      variant='secondary'
                      onClick={() => {
                        form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  {scanDocSimple && (
                    <div className='d-flex justify-content-around p-3'>
                      <Button
                        type='button'
                        variant='primary'
                        size='sm'
                        title='Send to straight pay'
                        onClick={() => {
                          if (claim?.status === 'I') {
                            toast.info(
                              'You cannot request/create payments on Incident Only Claims.  Please change the status to allow this.'
                            );
                            return;
                          }
                          form.submit()?.then((res) => {
                            if (res) {
                              handleStraightPay(scanDocSimple);
                            }
                          });
                        }}
                      >
                        Straight Pay
                      </Button>
                      <Button
                        type='button'
                        variant='primary'
                        size='sm'
                        title='Send to bill review'
                        onClick={() => {
                          if (claim?.status === 'I' || claim?.status === 'IO') {
                            toast.info(
                              'You cannot request/create payments on Incident Only Claims.  Please change the status to allow this.'
                            );
                            return;
                          }
                          form.submit()?.then((res) => {
                            if (res) {
                              handleBillReview(scanDocSimple);
                            }
                          });
                        }}
                      >
                        Bill Review
                      </Button>
                      <Button
                        type='button'
                        variant='primary'
                        size='sm'
                        title='Approve'
                        onClick={() => {
                          form.submit()?.then((res) => {
                            if (res) {
                              handleApprove(scanDocSimple);
                            }
                          });
                        }}
                      >
                        Approve
                      </Button>
                    </div>
                  )}
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
