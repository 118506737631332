import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DocumentType } from '../../../ApiTypes/DocumentType';
import { ScanDocSimple } from '../../../ApiTypes/ScanDocSimple';
import DocumentsTable from './DocumentsTable';
import TableSpinner from './TableSpinner';

export default function RecoveryDocuments({
  scanDocs,
  setScanDocs,
  loading,
  getScanDocsForClaim,
  claimType,
}: {
  scanDocs: ScanDocSimple[];
  setScanDocs: (
    docs: ScanDocSimple[] | ((docs: ScanDocSimple[]) => ScanDocSimple[])
  ) => void;
  loading: boolean;
  getScanDocsForClaim: (type: number) => void;
  claimType: number;
}) {
  let { claimNumber } = useParams();

  useEffect(() => {
    getScanDocsForClaim(DocumentType.Recovery);
  }, [claimNumber]);

  return (
    <div className='w-100 h-100'>
      {loading ? (
        <TableSpinner />
      ) : (
        <DocumentsTable
          docs={scanDocs}
          setDocs={setScanDocs}
          type={DocumentType.Recovery}
          getScanDocsForClaim={getScanDocsForClaim}
          claimType={claimType}
        />
      )}
    </div>
  );
}
