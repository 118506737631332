import React, { useState, useEffect, useMemo } from 'react';
import { displayDateOnly } from '../../Utils';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  RowSelectionState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { ClaimSearchResult } from '../../ApiTypes/ClaimSearchResult';
import IndeterminateCheckbox from '../Common/IndeterminateCheckbox';
import styles from './index.module.css';
import { VPaymentTab } from '../../ApiTypes/VPaymentTab';
import accounting from 'accounting';

export default function CheckBatchesTable({
  data,
  rowSelection,
  setRowSelection,
  setSelectedTransactions,
}: {
  data: VPaymentTab[];
  rowSelection: RowSelectionState;
  setRowSelection: (obj: object) => void;
  setSelectedTransactions: (transactions: VPaymentTab[]) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  useEffect(() => {
    handleNewData();
  }, [data]);

  const handleNewData = () => {
    setRowSelection({});
  };

  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  const handleRowSelectionChange = () => {
    setSelectedTransactions(
      table.getSelectedRowModel().flatRows.map((r) => r.original)
    );
  };

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<VPaymentTab>[] = [
    {
      header: ({ table }) => (
        <div className='px-3 d-flex gap1Rem'>
          {Object.keys(rowSelection).length}
          <IndeterminateCheckbox
            className='form-check-input'
            {...{
              id: 'totalPaymentsSelection',
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      id: 'options',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 85,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center'>
            <IndeterminateCheckbox
              className='form-check-input'
              {...{
                id: row.id,
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        );
      },
    },
    {
      header: 'Enter Date',
      accessorKey: 'txDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.txDate ?? ''),
    },
    {
      header: 'Claim No',
      accessorFn: (d) => d.claimNo,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
    },
    {
      header: 'Check Payable To',
      accessorKey: 'checkPayableTo',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Payee',
      accessorKey: 'payee',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Amount',
      accessorFn: (d) => `${d.checkAmount}`,
      sortingFn: 'text',
      filterFn: 'includesString',
      cell: ({ row }) => accounting.formatMoney(row.original.checkAmount ?? 0),
    },
    {
      header: 'Pay Code',
      accessorKey: 'payCode',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Bucket',
      accessorKey: 'fBucket',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Approved By',
      accessorKey: 'pmtApprovedBy',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Source',
      accessorKey: 'source',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, [rowSelection]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
      />
    </div>
  );
}
