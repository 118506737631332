import React, { useState } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaCheck } from 'react-icons/fa';
import ClaimApi from '../../Api/ClaimApi';
import {
  ClaimSearchRequest,
  SearchClaimStatus,
  SearchSeverity,
} from '../../ApiTypes/ClaimSearchRequest';
import { ClaimSearchResult } from '../../ApiTypes/ClaimSearchResult';
import { removeNonDigits, formatSSN } from '../../Utils/InputFormatters';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import ClaimSearchTable from '../TopToolbar/ClaimSearch/ClaimSearchTable';
import styles from './index.module.css';

export default function ClaimSearch({
  show,
  setShow,
  handleSelection,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  handleSelection: (result: ClaimSearchResult) => void;
}) {
  const [results, setResults] = useState<ClaimSearchResult[]>([]);

  const claimSearch = (request: ClaimSearchRequest) => {
    return ClaimApi.searchClaims(request)
      .then((res) => {
        setResults(res.data);
      })
      .catch((err) => {
        setResults([]);
        console.log(err);
      });
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='ClaimSearch-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='ClaimSearch-modal'>
          <FaCheck className='pe-1' /> Confirm
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={claimSearch}
            initialValues={{
              severity: SearchSeverity.Any,
              claimStatus: SearchClaimStatus.ShowAll,
            }}
            render={({ handleSubmit, form, values, pristine, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center gap-3'>
                      <h4>Attach Document to Claim</h4>
                    </div>
                    <div className='d-flex justify-content-end align-items-center gap1Rem'>
                      <Button
                        type='button'
                        variant='outline-secondary'
                        size='sm'
                        onClick={() => {
                          form.reset();
                        }}
                      >
                        Reset
                      </Button>
                      <Button type='submit' variant='primary' size='sm'>
                        {submitting ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          'Search'
                        )}
                      </Button>
                    </div>
                  </div>
                  <div className='d-flex justify-content-around gap1Rem'>
                    <Field
                      name='firstName'
                      type='text'
                      label='First Name'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='lastName'
                      type='text'
                      label='Last Name'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='claimNumber'
                      type='text'
                      label='Claim #'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='ssn'
                      type='text'
                      label='SSN'
                      parse={removeNonDigits}
                      format={formatSSN}
                      component={FieldBSRenderText}
                    />
                  </div>
                </Form>
              );
            }}
          />
          <ClaimSearchTable
            data={results}
            getSelectedClaim={handleSelection}
            setShow={setShow}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
