import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ExcessCarrierPolicyClassCode } from '../ApiTypes/ExcessCarrierPolicyClassCode';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class ExcessCarrierPolicyClassCodeApi {
  static getForPolicy(policyId: number) {
    return axios.get<ExcessCarrierPolicyClassCode[]>(
      `${API_URL}/api/excesscarrierpolicies/${policyId}/policyclasscodes`,
      getAuthHeader()
    );
  }
  static create(policyClassCode: ExcessCarrierPolicyClassCode) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/excesscarrierpolicies/${policyClassCode.excessCarrierPolicyId}/policyclasscodes`,
      policyClassCode,
      getAuthHeader()
    );
  }
  static update(policyClassCode: ExcessCarrierPolicyClassCode) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/excesscarrierpolicyclasscodes/${policyClassCode.id}`,
      policyClassCode,
      getAuthHeader()
    );
  }
  static delete(id: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/excesscarrierpolicyclasscodes/${id}`,
      getAuthHeader()
    );
  }
}

export default ExcessCarrierPolicyClassCodeApi;
