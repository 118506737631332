import { FormApi } from 'final-form';
import React, { useState, useEffect } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaMoneyCheckAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import DeductibleApi from '../../Api/DeductibleApi';
import { DeductibleInvoiceObj } from '../../ApiTypes/DeductibleInvoiceObj';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { useAppSelector } from '../../Reducers/Store';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import PageScaffold from '../PageScaffold/PageScaffold';
import DeductiblePaymentsTable from './DeductiblePaymentsTable';
import styles from './index.module.css';

export default function DeductiblePayments() {
  const { insuranceCompanies } = useAppSelector((state) => state.reference);
  const [invoices, setInvoices] = useState<DeductibleInvoiceObj[]>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [serviceTo, setServiceTo] = useState<string>('');

  let formInstance: FormApi<InvoiceFormType, Partial<InvoiceFormType>>;

  type InvoiceFormType = {
    accountNumber: string;
    policyNumber: string;
    serviceTo: string;
  };

  const getInvoices = (values: InvoiceFormType) => {
    setRowSelection({});
    setServiceTo(values.serviceTo);
    return DeductibleApi.getDeductibles(
      values.accountNumber,
      values.serviceTo,
      values.policyNumber
    )
      .then((res) => {
        setRowSelection({});
        setInvoices(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get invoices');
      });
  };

  const reSubmit = () => {
    formInstance.submit();
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaMoneyCheckAlt className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Deductible Invoice</h1>
          </div>
        </div>
      </div>
      <RFForm
        onSubmit={getInvoices}
        initialValues={{}}
        render={({ handleSubmit, form, values, submitting }) => {
          formInstance = form;
          return (
            <Form onSubmit={handleSubmit}>
              <div className={styles.grid4}>
                <Field
                  name='accountNumber'
                  label='Account'
                  options={insuranceCompanies}
                  optionMethod={(options: InsuranceCompany[]) =>
                    options.map((o) => (
                      <option
                        key={o.accountnumber}
                        value={o.accountnumber ?? ''}
                      >
                        {`${o.accountnumber} - ${o.shortname}`}
                      </option>
                    ))
                  }
                  validate={requiredField}
                  component={FieldBSRenderSelect}
                />
                <Field
                  name='serviceTo'
                  label='End of Quarter'
                  validate={requiredField}
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />
                <Field
                  name='policyNumber'
                  type='text'
                  label='Policy Number'
                  component={FieldBSRenderText}
                />
                <div>
                  <Button type='submit' variant='primary' size='sm'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Refresh'
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      />
      <DeductiblePaymentsTable
        data={invoices}
        serviceTo={serviceTo}
        setData={setInvoices}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        reSubmit={reSubmit}
      />
    </PageScaffold>
  );
}
