import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { FaArrowCircleUp } from 'react-icons/fa';
import DocumentsApi from '../../Api/DocumentsApi';
import { VwDocTurnaroundGrouped } from '../../ApiTypes/VwDocTurnaroundGrouped';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import TurnAroundChart from './TurnAroundChart';
import TurnAroundTable from './TurnAroundTable';

export default function TurnAroundAvgs() {
  const [data, setData] = useState<VwDocTurnaroundGrouped[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  useEffect(() => {
    getTurnAround();
  }, []);

  const getTurnAround = () => {
    setFetching(true);
    DocumentsApi.getTurnAround()
      .then((res) => {
        setData(res.data.sort((a, b) => (b.avgHours ?? 0) - (a.avgHours ?? 0)));
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);
        console.log(err);
      });
  };
  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaArrowCircleUp className='fs-1 text-primary mt-1' />
          <div className='ms-3 button-icon-text'>
            <h1>Document Turnaround</h1>
            {fetching && (
              <Spinner
                as='span'
                animation='grow'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            )}
          </div>
        </div>
      </div>
      <TurnAroundChart data={data} />
      <TurnAroundTable data={data} />
    </PageScaffold>
  );
}
