import { format, addDays } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaClock } from 'react-icons/fa';
import { toast } from 'react-toastify';
import DocumentsApi from '../../Api/DocumentsApi';
import { ScanDocsLog } from '../../ApiTypes/ScanDocsLog';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import MyRecentActivityTable from './MyRecentActvityTable';

export default function MyRecentActivities() {
  const [logs, setLogs] = useState<ScanDocsLog[]>([]);
  useEffect(() => {
    onSubmit({
      fromDate: format(addDays(new Date(), -7), 'yyyy-MM-dd'),
      toDate: format(new Date(), 'yyyy-MM-dd'),
    });
  }, []);

  const onSubmit = (values: { fromDate: string; toDate: string }) => {
    return DocumentsApi.getScanDocLogsByDates(values.fromDate, values.toDate)
      .then((res) => {
        setLogs(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get logs');
      });
  };
  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaClock className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Recent Document Activity</h1>
          </div>
        </div>
      </div>
      <RFForm
        onSubmit={onSubmit}
        initialValues={{
          fromDate: format(addDays(new Date(), -7), 'yyyy-MM-dd'),
          toDate: format(new Date(), 'yyyy-MM-dd'),
        }}
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <div className='d-flex justify-content-center'>
              <Form onSubmit={handleSubmit}>
                <div className='d-flex  align-items-center gap1Rem'>
                  <Field
                    name='fromDate'
                    type='text'
                    label='Date From'
                    validate={requiredField}
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                  />
                  <Field
                    name='toDate'
                    type='text'
                    label='Date To'
                    validate={requiredField}
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                  />

                  <Button type='submit' variant='primary' size='sm'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Search'
                    )}
                  </Button>
                </div>
              </Form>
            </div>
          );
        }}
      />
      <MyRecentActivityTable data={logs} />
    </PageScaffold>
  );
}
