import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ClaimLitigation } from '../ApiTypes/ClaimLitigation';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class LitigationApi {
  static getClaimLitigations(claimNumber: string) {
    return axios.get<ClaimLitigation[]>(
      `${API_URL}/api/claims/${claimNumber}/litigations`,
      getAuthHeader()
    );
  }
  static getClaimLitigationById(id: number) {
    return axios.get<ClaimLitigation>(
      `${API_URL}/api/litigations/${id}`,
      getAuthHeader()
    );
  }
  static updateClaimLitigation(request: ClaimLitigation) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/litigations`,
      request,
      getAuthHeader()
    );
  }
  static deleteClaimLitigation(id: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/litigations/${id}`,
      getAuthHeader()
    );
  }
}

export default LitigationApi;
