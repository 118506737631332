import React from 'react';
import { Field } from 'react-final-form';
import { AddEditUserFormType } from './AddEditUserFormType';
import styles from './index.module.css';
import { SiteFunction } from '../../ApiTypes/SiteFunction';
import { SiteFunctionGroups } from '../../ApiTypes/SiteFunctions';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import BSSelect from '../Common/BSSelect';
import { SiteFunctionDefaultGroup } from '../../ApiTypes/SiteFunctionDefaultGroup';
import { FormApi } from 'final-form';

export default function FunctionsForm({
  form,
  values,
  siteFunctions,
  siteFunctionDefaultGroups,
  handleDefaultAccessChange,
}: {
  form: FormApi<AddEditUserFormType, Partial<AddEditUserFormType>>;
  values: AddEditUserFormType;
  siteFunctions: SiteFunction[];
  siteFunctionDefaultGroups: SiteFunctionDefaultGroup[];
  handleDefaultAccessChange: (id: number) => void;
}) {
  return (
    <div>
      <div className='d-flex justify-content-center'>
        <BSSelect
          name='siteFunctionDefaultGroup'
          label='Default Access'
          options={siteFunctionDefaultGroups}
          onChange={handleDefaultAccessChange}
          optionMethod={(options: SiteFunctionDefaultGroup[]) =>
            options.map((o) => (
              <option key={o.id} value={o.id}>
                {o.name}
              </option>
            ))
          }
        />
      </div>
      <fieldset className='mb-3'>
        <legend className='fw-bold fs-3'>Claim Functions</legend>
        <div className={`${styles.grid5NoHeight}`}>
          {siteFunctions
            .filter((x) => x.groupId === SiteFunctionGroups.Claims)
            .map((x) => {
              return (
                <Field
                  key={`${x.siteFunctionId}`}
                  name='siteFunctionIds'
                  type='checkbox'
                  label={`${x.functionName}`}
                  value={`${x.siteFunctionId}`}
                  checked={values.siteFunctionIds.includes(
                    `${x.siteFunctionId}`
                  )}
                  component={FieldBSRenderCheckbox}
                />
              );
            })}
        </div>
      </fieldset>
      <fieldset className='my-3'>
        <legend className='fw-bold fs-3'>Report Functions</legend>
        <div className={`${styles.grid5NoHeight}`}>
          {siteFunctions
            .filter((x) => x.groupId === SiteFunctionGroups.Reports)
            .map((x) => {
              return (
                <Field
                  key={`${x.siteFunctionId}`}
                  name='siteFunctionIds'
                  type='checkbox'
                  label={`${x.functionName}`}
                  value={`${x.siteFunctionId}`}
                  checked={values.siteFunctionIds.includes(
                    `${x.siteFunctionId}`
                  )}
                  component={FieldBSRenderCheckbox}
                />
              );
            })}
        </div>
      </fieldset>
      <fieldset className='my-3'>
        <legend className='fw-bold fs-3'>Document Functions</legend>
        <div className={`${styles.grid5NoHeight}`}>
          {siteFunctions
            .filter((x) => x.groupId === SiteFunctionGroups.Documents)
            .map((x) => {
              return (
                <Field
                  key={`${x.siteFunctionId}`}
                  name='siteFunctionIds'
                  type='checkbox'
                  label={`${x.functionName}`}
                  value={`${x.siteFunctionId}`}
                  checked={values.siteFunctionIds.includes(
                    `${x.siteFunctionId}`
                  )}
                  component={FieldBSRenderCheckbox}
                />
              );
            })}
        </div>
      </fieldset>
      <fieldset className='my-3'>
        <legend className='fw-bold fs-3'>Payment Functions</legend>
        <div className={`${styles.grid5NoHeight}`}>
          {siteFunctions
            .filter((x) => x.groupId === SiteFunctionGroups.Payments)
            .map((x) => {
              return (
                <Field
                  key={`${x.siteFunctionId}`}
                  name='siteFunctionIds'
                  type='checkbox'
                  label={`${x.functionName}`}
                  value={`${x.siteFunctionId}`}
                  checked={values.siteFunctionIds.includes(
                    `${x.siteFunctionId}`
                  )}
                  component={FieldBSRenderCheckbox}
                />
              );
            })}
        </div>
      </fieldset>
      <fieldset className='my-3'>
        <legend className='fw-bold fs-3'>Provider Functions</legend>
        <div className={`${styles.grid5NoHeight}`}>
          {siteFunctions
            .filter((x) => x.groupId === SiteFunctionGroups.Providers)
            .map((x) => {
              return (
                <Field
                  key={`${x.siteFunctionId}`}
                  name='siteFunctionIds'
                  type='checkbox'
                  label={`${x.functionName}`}
                  value={`${x.siteFunctionId}`}
                  checked={values.siteFunctionIds.includes(
                    `${x.siteFunctionId}`
                  )}
                  component={FieldBSRenderCheckbox}
                />
              );
            })}
        </div>
      </fieldset>
      <fieldset className='my-3'>
        <legend className='fw-bold fs-3'>WCPanel Functions</legend>
        <div className={`${styles.grid5NoHeight}`}>
          {siteFunctions
            .filter((x) => x.groupId === SiteFunctionGroups.WCPanels)
            .map((x) => {
              return (
                <Field
                  key={`${x.siteFunctionId}`}
                  name='siteFunctionIds'
                  type='checkbox'
                  label={`${x.functionName}`}
                  value={`${x.siteFunctionId}`}
                  checked={values.siteFunctionIds.includes(
                    `${x.siteFunctionId}`
                  )}
                  component={FieldBSRenderCheckbox}
                />
              );
            })}
        </div>
      </fieldset>
      <fieldset className='my-3'>
        <legend className='fw-bold fs-3'>Setup Functions</legend>
        <div className={`${styles.grid5NoHeight}`}>
          {siteFunctions
            .filter((x) => x.groupId === SiteFunctionGroups.Setup)
            .map((x) => {
              return (
                <Field
                  key={`${x.siteFunctionId}`}
                  name='siteFunctionIds'
                  type='checkbox'
                  label={`${x.functionName}`}
                  value={`${x.siteFunctionId}`}
                  checked={values.siteFunctionIds.includes(
                    `${x.siteFunctionId}`
                  )}
                  component={FieldBSRenderCheckbox}
                />
              );
            })}
        </div>
      </fieldset>
    </div>
  );
}
