import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { VwUser } from '../../ApiTypes/VwUser';
import { FaEdit } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { displayDateOnly } from '../../Utils';
import { formatPhoneNumbers } from '../../Utils/InputFormatters';
import { Link } from 'react-router-dom';

export default function UserTable({ data }: { data: VwUser[] }) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<VwUser>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      size: 75,
      cell: ({ row }) => {
        return (
          <Link to={`/users/${row.original.userId}`}>
            <Button type='button' variant='primary' size='sm'>
              <FaEdit />
            </Button>
          </Link>
        );
      },
    },
    {
      header: 'User Id',
      accessorKey: 'userId',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Name',
      accessorKey: 'userName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Ad Account',
      accessorKey: 'adaccount',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Security Level',
      accessorFn: (d) => `${d.securityLevel}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 75,
    },
    {
      header: 'Active',
      accessorFn: (d) => (d.active !== false ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 75,
    },
    {
      header: 'Initials',
      accessorKey: 'initials',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 75,
    },
    {
      header: 'Default Ins Co Id',
      accessorFn: (d) => `${d.defaultInsurCoId}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'View Only',
      accessorFn: (d) => (d.viewOnly !== false ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 75,
    },
    {
      header: 'Spell Check',
      accessorFn: (d) => (d.spellCheckAsYouGo !== false ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 75,
    },
    {
      header: 'Email Address',
      accessorKey: 'emailAddress',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Enable Documents',
      accessorFn: (d) => (d.enableDocuments !== false ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Auto Show Docs',
      accessorFn: (d) => (d.autoShowDocs !== false ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Phone Number',
      accessorFn: (d) => formatPhoneNumbers(d.phoneNumber ?? ''),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Is Claims Assistant',
      accessorFn: (d) => (d.isClaimsAssistant === true ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Is NCM',
      accessorFn: (d) => (d.isNcm === true ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 75,
    },
    {
      header: 'Is Admin',
      accessorFn: (d) => (d.isAdmin === true ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 75,
    },
    {
      header: 'Is Supervisor',
      accessorFn: (d) => (d.isSupervisor === true ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Company Currently Looking At',
      accessorFn: (d) => `${d.currentCompanyLookingAt ?? 'N/A'}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
