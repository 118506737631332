import React from 'react';
import { Modal, Container, Button } from 'react-bootstrap';
import { FaQuestionCircle } from 'react-icons/fa';

export default function ShouldResend1Modal({
  show,
  setShow,
  handleResendResponse,
}: {
  show: boolean;
  setShow: (s: boolean) => void;
  handleResendResponse: (shouldResend: boolean) => void;
}) {
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => setShow(false)}
      dialogClassName=''
      aria-labelledby='Should-resend-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Should-resend-modal'>
          <FaQuestionCircle className='pe-1' /> Re-Send
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p>
            Are you sure you want to re-send this? It looks like it has already
            been accepted. Please confirm.
          </p>
          <div className='d-flex justify-content-around align-items-center'>
            <Button
              type='button'
              variant='outline-primary'
              size='lg'
              onClick={() => handleResendResponse(true)}
            >
              Re-Send
            </Button>
            <Button
              type='button'
              variant='outline-primary'
              size='lg'
              onClick={() => handleResendResponse(false)}
            >
              Cancel
            </Button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
