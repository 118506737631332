import React, { useState } from 'react';
import { FaMoneyCheck } from 'react-icons/fa';
import { Field, Form as RFFForm } from 'react-final-form';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { Transaction } from '../../ApiTypes/Transaction';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Claim } from '../../ApiTypes/Claim';
import { Claimant } from '../../ApiTypes/Claimant';
import { ClaimPaymentTotals } from '../../ApiTypes/ClaimPaymentTotals';
import { ClaimReserveBuckets } from '../../ApiTypes/ClaimReserveBuckets';
import { useAppSelector } from '../../Reducers/Store';
import ClaimSearch from '../PaymentEntry/ClaimSearch';
import { ClaimSearchResult } from '../../ApiTypes/ClaimSearchResult';
import ClaimApi from '../../Api/ClaimApi';
import { toast, ToastContentProps } from 'react-toastify';
import ClaimantApi from '../../Api/ClaimantApi';
import ReservesApi from '../../Api/ReservesApi';
import { PayCodeItem } from '../../ApiTypes/PayCodeItem';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import { parseDatesForServer } from '../../Utils';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import { formatNumbers, cleanMoney } from '../../Utils/InputFormatters';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';
import { format, isBefore } from 'date-fns';
import PaymentsApi from '../../Api/PaymentsApi';
import { FormApi } from 'final-form';

export default function ManualCheck() {
  const { payCodes } = useAppSelector((state) => state.reference);
  const { userModel } = useAppSelector((state) => state.user);
  let formInstance: FormApi<Transaction, Partial<Transaction>>;

  const [claimReserveBuckets, setClaimReserveBuckets] = useState<
    ClaimReserveBuckets[]
  >([]);
  const [claimPaymentTotals, setClaimPaymentTotals] =
    useState<ClaimPaymentTotals | null>(null);

  const [claimant, setClaimant] = useState<Claimant | null>(null);
  const [claim, setClaim] = useState<Claim | null>(null);
  const [showClaimSearch, setShowClaimSearch] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const getClaimant = (claimantId: number) => {
    ClaimantApi.getClaimantById(claimantId)
      .then((res) => {
        setClaimant(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get claimant');
      });
  };
  const getReserves = (claimNumber: string) => {
    ReservesApi.getClaimReserveBucketsForClaim(claimNumber)
      .then((res) => {
        setClaimReserveBuckets(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to reserve buckets for claim');
      });
  };
  const getClaimPaymentTotals = (claimNumber: string) => {
    ReservesApi.getClaimPaymentTotals(claimNumber)
      .then((res) => {
        setClaimPaymentTotals(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to claim payment totals');
      });
  };

  const handleSelectedClaim = (claim: ClaimSearchResult) => {
    ClaimApi.getClaimByClaimNumber(claim.claimNo)
      .then((res) => {
        setClaim(res.data);
        getClaimant(res.data.claimantId ?? 0);
        getReserves(res.data.claimNo);
        getClaimPaymentTotals(res.data.claimNo);
        setShowClaimSearch(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get claim');
      });
  };

  const Msg = ({ closeToast, toastProps }: Partial<ToastContentProps>) => (
    <div>
      {(toastProps?.data as { text: string }).text}
      <div className='d-flex justify-content-around align-items-center my-3'>
        <Button
          type='button'
          variant='outline-light'
          size='sm'
          onClick={() => {
            closeToast && closeToast();
            continueWithTransaction(
              (toastProps?.data as { values: Transaction }).values
            );
          }}
        >
          Continue
        </Button>
        <Button
          variant='outline-light'
          size='sm'
          type='button'
          onClick={() => {
            setIsSubmitting(false);
            closeToast && closeToast();
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );

  const onSubmit = (values: Transaction) => {
    if (!claimant || !claim) {
      toast.error('Please, choose a claim.');
      return;
    }
    if (!values.serviceFrom || !values.serviceTo) {
      toast.error('Please enter a service from and to date. ');
      return;
    }
    if (isBefore(new Date(values.serviceTo), new Date(values.serviceFrom))) {
      toast.error('Service From Date must be less than Service To date.');
      return;
    }
    if (
      isBefore(new Date(values.serviceFrom), new Date(claim.injuryDate ?? ''))
    ) {
      toast.error('Service From date must be greater or equal to injury date.');
      return;
    }
    if (!values.checkNumber) {
      toast.error('Please, enter a check number');
      return;
    }
    if (!values.checkDate) {
      toast.error('Please, enter a check date');
      return;
    }

    setIsSubmitting(true);

    let totalPaid =
      (claimPaymentTotals?.medical ?? 0) +
      (claimPaymentTotals?.legal ?? 0) +
      (claimPaymentTotals?.indemnity ?? 0) +
      (claimPaymentTotals?.expense ?? 0);

    if (totalPaid + +values.amount! < 0) {
      toast(<Msg />, {
        autoClose: false,
        closeOnClick: false,
        hideProgressBar: true,
        draggable: false,
        data: {
          values,
          text: `This Adjustment will put the claim in Negative Reserves. Do you wish to continue?`,
        },
      });
    } else {
      return continueWithTransaction(values);
    }
  };

  const continueWithTransaction = (values: Transaction) => {
    const now = format(new Date(), 'yyyy-MM-dd');
    const payCode = payCodes.find((p) => p.payCode === values.payCode);
    const status =
      payCode?.txType === 'PAYMENT'
        ? 'N'
        : payCode?.txType === 'RECOVERY' || payCode?.txType === 'REIMBURSEMENT'
        ? 'R'
        : 'N';
    let transaction: Transaction = {
      txId: 0,
      claimNo: claim!.claimNo,
      txDate: now,
      txEnterDate: now,
      payee: 'C',
      providerId: claimant!.claimantId,
      serviceFrom: values.serviceFrom,
      serviceTo: values.serviceTo,
      payCode: values.payCode,
      checkId: null,
      amount: values.amount ?? 0,
      checkNumber: values.checkNumber,
      checkDate: values.checkDate,
      memo: values.memo,
      fbucket: payCode?.fBucket ?? '',
      paymentNumber: null,
      status: status,
      bankId: null,
      pmtApproved: true,
      pmtApprovedBy: null,
      pmtBatchId: null,
      source: 'Manual CK Entry',
      importId: null,
      documentControlNumber: null,
      enteredBy: userModel?.user?.userId ?? '',
      alteredBy: userModel?.user?.userId ?? '',
      docId: null,
      approvalDate: null,
      managerApproved: null,
      managerApprovedBy: null,
      managerApprovedDate: null,
      requireSecondaryApproval: null,
      claimSubrogationId: values.claimSubrogationId,
    };
    return PaymentsApi.SavePayment(transaction)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          setIsSubmitting(false);
          formInstance.restart();
          setClaim(null);
          setClaimant(null);
          setClaimReserveBuckets([]);
          setClaimPaymentTotals(null);
        } else {
          toast.error(res.data.message ?? 'Failed to save transaction');
          setIsSubmitting(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
        toast.error('Failed to save transaction');
      });
  };
  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaMoneyCheck className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Add Manual Check</h1>
          </div>
        </div>
      </div>
      <RFFForm
        onSubmit={onSubmit}
        initialValues={{
          amount: 0,
        }}
        render={({ handleSubmit, form, values, submitting }) => {
          formInstance = form;
          return (
            <Form onSubmit={handleSubmit}>
              <div className={styles.grid3}>
                <div className={`position-relative ${styles.vFieldHeight}`}>
                  <label htmlFor='claimNo' className='form-label fs-6  m-0'>
                    Claim Number
                  </label>
                  <input
                    type='text'
                    name='claimNo'
                    value={claim ? claim.claimNo : ''}
                    className='form-control form-control-sm'
                    placeholder='Claim Number'
                    onClick={() => {
                      setShowClaimSearch(true);
                    }}
                    readOnly
                  />
                </div>

                <div className={`position-relative ${styles.vFieldHeight}`}>
                  <label
                    htmlFor='claimantName'
                    className='form-label fs-6  m-0'
                  >
                    Claimant
                  </label>
                  <input
                    type='text'
                    name='claimantName'
                    value={
                      claimant
                        ? `${claimant?.firstName ?? ''} ${
                            claimant?.lastName ?? ''
                          }`
                        : ''
                    }
                    className='form-control form-control-sm'
                    placeholder='Claimant'
                    readOnly
                  />
                </div>
                <Field
                  name='payCode'
                  label='Pay Code'
                  options={payCodes}
                  optionMethod={(options: PayCodeItem[]) =>
                    options.map((o) => (
                      <option key={o.payCode} value={o.payCode}>
                        {o.description}
                      </option>
                    ))
                  }
                  validate={requiredField}
                  component={FieldBSRenderSelect}
                />
              </div>
              <div className={styles.grid3}>
                <Field
                  name='checkNumber'
                  type='text'
                  label='Check Number'
                  validate={requiredField}
                  component={FieldBSRenderText}
                />
                <Field
                  name='checkDate'
                  label='Check Date'
                  validate={requiredField}
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />
                <Field
                  name='amount'
                  label='Amount'
                  type='text'
                  format={formatNumbers}
                  parse={cleanMoney}
                  validate={requiredField}
                  component={FieldBSRenderMoney}
                />
              </div>
              <div className={styles.grid3}>
                <Field
                  name='serviceFrom'
                  label='Service From'
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />
                <Field
                  name='serviceTo'
                  label='Service To'
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />
                <Field
                  name='memo'
                  type='text'
                  label='Memo'
                  component={FieldBSRenderText}
                />
              </div>
              <div className='d-flex justify-content-center align-items-center gap1Rem py-3'>
                <Button
                  type='submit'
                  size='sm'
                  variant='primary'
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Submit'
                  )}
                </Button>
                <Button
                  type='button'
                  variant='secondary'
                  size='sm'
                  onClick={() => {
                    setClaim(null);
                    setClaimant(null);
                    setClaimPaymentTotals(null);
                    setClaimReserveBuckets([]);
                    form.restart();
                  }}
                >
                  Reset
                </Button>
              </div>
            </Form>
          );
        }}
      />
      <ClaimSearch
        show={showClaimSearch}
        setShow={setShowClaimSearch}
        handleSelection={handleSelectedClaim}
      />
    </PageScaffold>
  );
}
