import accounting, { formatNumber } from 'accounting';
import { format } from 'date-fns';
import { FormApi } from 'final-form';
import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Modal,
  Form,
  Alert,
  Spinner,
} from 'react-bootstrap';
import { Form as RFForm } from 'react-final-form';
import { FaChevronLeft, FaChevronRight, FaPlusCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ClaimApi from '../../Api/ClaimApi';
import EdiTransactionApi from '../../Api/EdiTransactionApi';
import RecurringPaymentsApi from '../../Api/RecurringPaymentsApi';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { FullClaim } from '../../ApiTypes/FullClaim';
import { RecurringPayment } from '../../ApiTypes/RecurringPayment';
import { useAppSelector } from '../../Reducers/Store';
import { displayDateOnly } from '../../Utils';
import ProgressReport from '../EdiForms/ProgressReport';
import PageScaffold from '../PageScaffold/PageScaffold';
import BenefitPage from './BenefitPage';
import CompletePage from './CompletePage';
import DenialPage from './DenialPage';
import styles from './index.module.css';
import SuspensionPage from './SuspensionPage';
import WelcomePage from './WelcomePage';

enum PageNumber {
  Welcome = 1,
  Benefit = 2,
  Denial = 3,
  Suspension = 4,
  Complete = 5,
}

export default function NewEdiTransactionPage() {
  const { claimNumber } = useParams();
  const { ediCodes, FROIEdiMaintTypeCodes, SROIEdiMaintTypeCodes } =
    useAppSelector((state) => state.reference);
  const [page, setPage] = useState<number>(1);
  const [wc4Id, setWc4Id] = useState<number>(0);
  const [isFn, setIsFn] = useState<boolean>(false);
  const [summary, setSummary] = useState<string>('');
  const [showProgressReport, setShowProgressReport] = useState<boolean>(false);
  const [recurringPayments, setRecurringPayments] = useState<
    RecurringPayment[]
  >([]);
  const [fullClaim, setFullClaim] = useState<FullClaim | null>(null);
  const [disabledFields, setDisabledFields] = useState<string[]>([]);
  const [requiredFiles, setRequiredFiles] = useState<
    { [key: string]: boolean }[]
  >([]);

  let formInstance: FormApi<EdiTransaction, Partial<EdiTransaction>>;

  useEffect(() => {
    getClaim();
    getRecurringPayments();
  }, [claimNumber]);

  const getClaim = () => {
    if (claimNumber) {
      ClaimApi.getFullClaimByClaimNumber(claimNumber)
        .then((res) => {
          setFullClaim(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get claim');
        });
    }
  };

  const getRecurringPayments = () => {
    if (claimNumber) {
      RecurringPaymentsApi.getRecurringPaymentsByClaimNumber(claimNumber)
        .then((res) => {
          res.data.sort((a, b) => {
            const aDate = new Date(a.startDate);
            const bDate = new Date(b.startDate);
            return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
          });
          setRecurringPayments(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onSubmit = (values: EdiTransaction) => {
    if (page === PageNumber.Complete) {
      return handleFormSubmit(values);
    }
    return nextPage(values);
  };

  const handleFormSubmit = (values: EdiTransaction) => {
    const transaction: EdiTransaction = {
      id: 0,
      claimno: claimNumber!,
      form: values.form,
      maintTypeCode: values.maintTypeCode,
      submitDate: format(new Date(), 'yyyy-MM-dd'),
      submitStatus: 0,
      receiveDate: null,
      receiveStatus: null,
      disabilitydate: values.disabilitydate,
      benefitTypeCode: values.benefitTypeCode ?? '00',
      avgWeeklyWage: values.avgWeeklyWage,
      weeklyBenefitAmt: values.weeklyBenefitAmt,
      benefitStartDate: values.benefitStartDate,
      benefitThroughDate: values.benefitThroughDate,
      fullWagesPaidOnDoi: values.fullWagesPaidOnDoi ? 'Y' : 'N',
      empPaidSalaryInLieuOfCompensation: 'N',
      initialReturnToWorkDate: values.initialReturnToWorkDate,
      benefitSuspendDate: values.benefitSuspendDate,
      fullDenialReasonCode: values.fullDenialReasonCode,
      fullDenialEffectiveDate: values.fullDenialEffectiveDate,
      partialDenialReasonCode: values.partialDenialReasonCode,
      denialNarrative: values.denialNarrative,
      ackId: null,
      dn0111: null,
      transSetId:
        values.maintTypeCode === 'FN' || values.maintTypeCode === 'AN'
          ? 'A49'
          : null,
      wc4id: wc4Id > 0 ? wc4Id : null,
      fileType: null,
      benefitCheckDate: values.benefitCheckDate,
      wagePeriodCode: null,
      daysWorkedPerWeek: null,
      lastDayWorked: null,
      dateClaimWentLostTime: null,
      hireDate: null,
      initialTreatmentCode: null,
      dateOfDeath: null,
      dateEmployerHadKnowledge: null,
      dateClaimantAttyNotified: null,
      numberOfPermantImpairments: null,
      dateOfMaxMedImprovement: null,
      numberOfDependents: null,
      numberOfDeathDependentRelationships: null,
      permanentImpairmentMinPaymentIndicator: null,
      anticipatedWageLossIndicator: null,
      physicalRestrictionsIndicator: null,
      releaseSsnindicator: null,
      rtwsameEmployerIndicator: null,
      releaseMedsIndicator: null,
      paymentReasonCode: null,
      paymentAmount: null,
      ppdimpairmentBodyPartCode: null,
      ppdimpairmentWeeks: null,
      penaltyPercentage: null,
      penaltyAmount: null,
      permImpairmentPercentage: null,
      dependentPayeeRelationshipCode: null,
      employeePaidSalaryPriorToAquisitionCode: null,
      workWeekTypeCode: null,
      workDaysScheduledCode: null,
      employeeSecurityId: null,
      ppdimpairmentDays: null,
      lumpSumPaymentReasonCode: null,
      suspensionCheck0: null,
      suspensionCheck1: null,
      suspensionCheck2: null,
      suspensionCheck3: null,
      suspensionCheck4: null,
      suspensionCheck5: null,
      suspensionCheck6: null,
      suspensionCheck7: null,
      suspensionCheck8: null,
      suspensionCheck9: null,
      suspensionCheck10: null,
      suspensionCheck11: null,
      suspensionReducedPay: null,
      suspensionOther: null,
      dateSalaryPaid: null,
      wc3bcheck1: null,
      wc3bcheck2: null,
      wc3bcheck3: null,
      controvertCopySent: null,
      currentBenefitEndDate: null,
      changeEdiTransactionId: null,
      priorEdiTransactionId: null,
      severity: null,
      amountPaid: null,
      amountOwed: null,
      overUnderPayment: null,
      weeksPaid: null,
      weeksOwed: null,
      ediStatus: null,
      ediTransactionLogs: null,
      eventDate: '',
      notes: '',
      logStatusString: '',
      logCreator: '',
      logDate: null,
      submitStatusString: '',
      benefitTypeName: '',
      claimantFullName: null,
      claim: null,
      claimant: null,
    };

    return createTransaction(transaction);
  };

  const createTransaction = (t: EdiTransaction) => {
    return EdiTransactionApi.createEdiTransaction(t.claimno, t)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success!');
          formInstance.restart();
          setPage(PageNumber.Welcome);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create transaction');
      });
  };

  const handleMaintTypeChange = (type: string, form: string) => {
    setIsFn(type === 'FN');
    if (type === '04') {
      setDisabledFields(['benefitTypeCode', 'partialDenialReasonCode']);
    } else if (type === 'PD') {
      setDisabledFields(['fullDenialReasonCode']);
    } else if (type[0] === 'S') {
      setDisabledFields(['benefitTypeCode']);
    } else if (type === 'FN' || type === 'AN') {
      setDisabledFields(['benefitTypeCode']);
    } else {
      setDisabledFields([]);
    }
    if (form && type) {
      getEdiFiles(form, type);
    }
  };

  const getEdiFiles = (formType: string, maintCode: string) => {
    EdiTransactionApi.getEdiFiles(formType, maintCode, claimNumber!)
      .then((res) => {
        Array.isArray(res.data)
          ? setRequiredFiles(res.data)
          : setRequiredFiles([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSettingWc4Id = (id: number) => {
    setWc4Id(id);
  };

  const handleProgressClose = (show: boolean) => {
    setShowProgressReport(false);
    if (wc4Id) {
      setPage(PageNumber.Complete);
    }
  };

  const nextPage = (values: EdiTransaction) => {
    const { maintTypeCode, form } = values;
    if (page === 1) {
      if (maintTypeCode === '04') {
        setPage(PageNumber.Denial);
      } else if (maintTypeCode === 'PD') {
        setPage(PageNumber.Denial);
      } else if (maintTypeCode[0] === 'S') {
        setPage(PageNumber.Suspension);
      } else if (maintTypeCode === '00' && form === 'FROI') {
        setPage(PageNumber.Complete);
        getSummary(values);
      } else if (maintTypeCode === 'FN' || maintTypeCode === 'AN') {
        setShowProgressReport(true);
      } else {
        setPage(PageNumber.Benefit);
      }
    } else {
      setPage(PageNumber.Complete);
      getSummary(values);
    }
  };

  const previousPage = () => {
    if (page !== PageNumber.Welcome) {
      setPage(PageNumber.Welcome);
    }
    formInstance.restart();
  };

  const handleFullDenial = (values: EdiTransaction) => {
    const denial = ediCodes.find((d) => d.code === values.fullDenialReasonCode);

    const text = `
      The Full Denial Reason is ${denial?.description}
      The Denial Narrative is ${values.denialNarrative}
      `;
    return text;
  };
  const handlePartialDenial = (values: EdiTransaction) => {
    const denial = ediCodes.find(
      (d) => d.code === values.partialDenialReasonCode
    );

    const text = `
      The Partial Denial Reason is ${denial?.description}
      The Denial Narrative is ${values.denialNarrative}
      `;
    return text;
  };

  const handleSroi = (values: EdiTransaction) => {
    let text = `
      The Weekly Benefit is ${
        values.weeklyBenefitAmt
      } and is payable from ${displayDateOnly(
      values.benefitStartDate ?? ''
    )} to ${displayDateOnly(values.benefitThroughDate ?? '')}

      The Average Weekly Wage is ${accounting.formatMoney(
        values.avgWeeklyWage ?? 0
      )}

      Full Wages Paid on DOI? ${values.fullWagesPaidOnDoi ? true : false}
      `;

    return text;
  };

  const getSummary = (values: EdiTransaction) => {
    let text = `
    Georgia Administrative Services

    New ${values.form} EDI Submission

    Requested on ${displayDateOnly(new Date())}

    The Disability Date is ${displayDateOnly(values.disabilitydate ?? '')}

    ------------------------------------------------------------------

    You are submitting a ${
      values.form
    } request for claim number ${claimNumber} (${
      fullClaim?.claimant?.firstName
    } ${fullClaim?.claimant?.lastName})
    `;

    const benefitCode = ediCodes.find((f) => f.code === values.benefitTypeCode);
    const maintCode =
      values.form === 'FROI'
        ? FROIEdiMaintTypeCodes.find((f) => f.code === values.maintTypeCode)
        : SROIEdiMaintTypeCodes.find((f) => f.code === values.maintTypeCode);

    text += `
    The type of request is ${maintCode?.shortdesc} ${
      benefitCode ? benefitCode.description : ''
    }
    `;

    if (values.fullDenialReasonCode) {
      text += handleFullDenial(values);
    }
    if (values.partialDenialReasonCode) {
      text += handlePartialDenial(values);
    }
    if (values.form === 'SROI' && values.weeklyBenefitAmt) {
      text += handleSroi(values);
    }

    if (values.maintTypeCode === 'FN' || values.maintTypeCode === 'AN') {
      text += `
      
      A detailed WC-4 form has been created (Id: ${wc4Id})
      `;
    }
    if (values.benefitSuspendDate) {
      text += `
    The Suspend date is ${displayDateOnly(values.benefitSuspendDate ?? '')}
      `;
    }
    if (values.initialReturnToWorkDate) {
      text += `
    The return to work date is ${displayDateOnly(
      values.initialReturnToWorkDate ?? ''
    )} ${values.physicalRestrictionsIndicator ? 'with' : 'without'} restrictions
      `;
    }

    setSummary(text);
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaPlusCircle className='fs-1 text-primary mt-1' />
          <div className='ms-3 pb-3'>
            <h1>New EDI Submission</h1>
          </div>
        </div>
      </div>
      <Container>
        <Alert variant='info' className='mt-3 text-center'>
          {page === PageNumber.Welcome && (
            <p>
              This screen will walk you through the requirements for each EDI
              submission type
            </p>
          )}
          {page === PageNumber.Benefit && (
            <p>Please enter benefit information</p>
          )}
          {page === PageNumber.Suspension && (
            <p>Please provide more information on the suspension of benefits</p>
          )}
          {page === PageNumber.Denial && (
            <p>
              Please enter more information regarding the denial of this claim
            </p>
          )}
          {page === PageNumber.Complete && <p>Review and Complete</p>}
        </Alert>
        <RFForm
          onSubmit={onSubmit}
          validate={(values) => {
            if (
              values.form === 'SROI' &&
              !disabledFields.includes('benefitTypeCode') &&
              !values.benefitTypeCode
            ) {
              return {
                benefitTypeCode: 'Required',
              };
            }
            return {};
          }}
          initialValues={{
            claimno: fullClaim?.claim?.ediClaimNo,
            avgWeeklyWage: fullClaim?.claim?.avgWeeklyWage,
            disabilitydate: fullClaim?.claim?.disabilityDate
              ? fullClaim?.claim?.disabilityDate
              : recurringPayments.length > 0
              ? recurringPayments[recurringPayments.length - 1].startDate
              : undefined,
            fullDenialEffectiveDate: format(new Date(), 'yyyy-MM-dd'),
          }}
          render={({ handleSubmit, form, values, submitting }) => {
            formInstance = form;
            return (
              <Form onSubmit={handleSubmit}>
                {page === PageNumber.Welcome && (
                  <WelcomePage
                    values={values}
                    disabledFields={disabledFields}
                    requiredFiles={requiredFiles}
                    getEdiFiles={getEdiFiles}
                    handleMaintTypeChange={handleMaintTypeChange}
                  />
                )}
                {page === PageNumber.Benefit && (
                  <BenefitPage
                    values={values}
                    disabledFields={disabledFields}
                  />
                )}
                {page === PageNumber.Denial && (
                  <DenialPage values={values} disabledFields={disabledFields} />
                )}
                {page === PageNumber.Suspension && (
                  <SuspensionPage
                    values={values}
                    disabledFields={disabledFields}
                  />
                )}
                {page === PageNumber.Complete && (
                  <CompletePage
                    values={values}
                    summary={summary}
                    disabledFields={disabledFields}
                  />
                )}
                <div className='d-flex justify-content-around align-items-center my-3'>
                  <Button
                    type='button'
                    size='sm'
                    variant='secondary'
                    className='button-icon-text'
                    onClick={() => {
                      previousPage();
                    }}
                  >
                    <FaChevronLeft /> Previous
                  </Button>
                  <Button
                    type='submit'
                    size='sm'
                    variant='primary'
                    className='button-icon-text'
                  >
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : page === PageNumber.Complete ? (
                      'Submit'
                    ) : (
                      <>
                        Save and Continue <FaChevronRight />
                      </>
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        />
      </Container>
      <ProgressReport
        wc4Id={wc4Id}
        claimNumber={claimNumber ?? ''}
        claimantFirstName={fullClaim?.claimant?.firstName ?? ''}
        claimantLastName={fullClaim?.claimant?.lastName ?? ''}
        show={showProgressReport}
        setShow={handleProgressClose}
        setWc4Id={handleSettingWc4Id}
        isFn={isFn}
      />
    </PageScaffold>
  );
}
