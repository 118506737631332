import { format } from 'date-fns';
import React, { useState } from 'react';
import { Modal, Container } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';
import EdiTransactionApi from '../../Api/EdiTransactionApi';
import { EDIStatus } from '../../ApiTypes/EdiStatus';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { useAppSelector } from '../../Reducers/Store';
import GetFormForNewTransaction from './GetFormForNewTransaction';
import NewEdiForm from './NewEdiForm';
import { NewEdiFormValues } from './NewEdiFormValues';

export default function NewEdiModal({
  show,
  handleCloseModal,
  ipAlreadySent,
  epAlreadySent,
  getTransactions,
}: {
  show: boolean;
  handleCloseModal: () => void;
  ipAlreadySent: boolean;
  epAlreadySent: boolean;
  getTransactions: () => void;
}) {
  const [newEdiFormSubmitted, setNewEdiFormSubmitted] =
    useState<boolean>(false);
  const [selectedMaintTypeCode, setSelectedMaintTypeCode] =
    useState<string>('');
  const [selectedBenefitTypeCode, setSelectedBenefitTypeCode] =
    useState<string>('');
  const [selectedFormType, setSelectedFormType] = useState<string>('');
  const [severity, setSeverity] = useState<string>('');

  const { claim } = useAppSelector((state) => state.currentClaimReducer);

  const onSubmit = (values: EdiTransaction) => {
    const copy = { ...values };
    const now = format(new Date(), 'yyyy-MM-dd');

    copy.ediTransactionLogs = null;

    copy.claimno = claim?.claimNo ?? '';
    copy.submitStatus = EDIStatus.New;
    copy.submitDate = now;
    copy.severity = claim?.severity ?? '';
    if (copy.benefitSuspendDate) {
      copy.suspensionCheck0 = true;
    } else {
      copy.suspensionCheck0 = false;
    }

    return createTransaction(copy);
  };

  const createTransaction = (t: EdiTransaction) => {
    return EdiTransactionApi.createEdiTransaction(t.claimno, t)
      .then((res) => {
        if (res.data.success) {
          getTransactions();
          handleCloseModal();
          setNewEdiFormSubmitted(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create transaction');
      });
  };

  const closeModal = () => {
    setNewEdiFormSubmitted(false);
    setSelectedMaintTypeCode('');
    setSelectedBenefitTypeCode('');
    setSelectedFormType('');
    setSeverity('');
    handleCloseModal();
  };

  const checkIfBenefitRequired = (values: NewEdiFormValues) => {
    if (
      values.formType === 'SROI' &&
      values.maintTypeCode !== 'AN' &&
      values.maintTypeCode !== 'FN' &&
      claim?.severity !== 'MO' &&
      claim?.severity !== 'AM'
    ) {
      if (!values.benefitTypeCode) {
        toast.error('Please Select a Benefit Type Code');
        return false;
      }
    }
    return true;
  };

  const onNewFormSubmit = (values: NewEdiFormValues) => {
    if (values.formType === 'SROI') {
      const res = checkIfBenefitRequired(values);
      if (!res) {
        return;
      }
    }

    if (
      values.formType === 'SROI' &&
      (values.maintTypeCode === 'IP' ||
        values.maintTypeCode === 'CB' ||
        values.maintTypeCode === 'RB' ||
        values.maintTypeCode === 'CO' ||
        values.maintTypeCode === 'CA' ||
        values.maintTypeCode === 'RE' ||
        values.maintTypeCode === 'EP' ||
        values.maintTypeCode === 'ER' ||
        values.maintTypeCode === 'AP')
    ) {
      if (!claim?.disabilityDate) {
        toast.error(
          'Please ensure a valid Initial Disability Date is entered in the Indemnity Tab before proceeding.'
        );
        return;
      }
    }

    if (
      values.formType === 'SROI' &&
      values.maintTypeCode === 'CB' &&
      ipAlreadySent === false &&
      epAlreadySent === false
    ) {
      toast.error(
        'You cannot send a CB unless you have previously sent an Initial Payment or Employer Payment.'
      );
      return;
    }

    if (
      values.formType === 'SROI' &&
      ipAlreadySent === false &&
      epAlreadySent === false &&
      (values.maintTypeCode === 'RB' ||
        values.maintTypeCode === 'CO' ||
        values.maintTypeCode === 'CA')
    ) {
      toast.error(
        `You cannot send a ${values.maintTypeCode} unless you have previously sent an Initial Payment or Employer Payment.`
      );
      return;
    }

    if (
      values.formType === 'SROI' &&
      values.maintTypeCode === 'ER' &&
      epAlreadySent === false
    ) {
      toast.error(
        'You cannot send a ER unless you have previously sent an EP, please select EP and try again.'
      );
      return;
    }

    setSelectedMaintTypeCode(values.maintTypeCode);
    setSelectedBenefitTypeCode(values.benefitTypeCode ?? '');
    setSelectedFormType(values.formType);
    setSeverity(claim?.severity ?? '');

    setNewEdiFormSubmitted(true);
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        closeModal();
      }}
      dialogClassName=''
      aria-labelledby='EDI-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='EDI-Form-modal'>
          <FaEdit className='pe-1' /> EDI
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {newEdiFormSubmitted ? (
            <GetFormForNewTransaction
              onFormSubmit={onSubmit}
              closeModal={closeModal}
              ipAlreadySent={ipAlreadySent}
              epAlreadySent={epAlreadySent}
              formType={selectedFormType}
              maintTypeCode={selectedMaintTypeCode}
              benefitTypeCode={selectedBenefitTypeCode}
              severity={severity}
            />
          ) : (
            <NewEdiForm
              closeModal={closeModal}
              ipAlreadySent={ipAlreadySent}
              epAlreadySent={epAlreadySent}
              onFormSubmit={onNewFormSubmit}
            />
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}
