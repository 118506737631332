import { addDays, format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { Spinner, Form, Button } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaChartArea, FaEye } from 'react-icons/fa';
import { toast } from 'react-toastify';
import CompCompleteApi from '../../Api/CompCompleteApi';
import { CompIqStatObj } from '../../ApiTypes/CompIqStatObj';
import { DailyCounts } from '../../ApiTypes/DailyCounts';
import { downloadExcel, parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import PageScaffold from '../PageScaffold/PageScaffold';
import CompIqChart from './CompIqChart';
import CompIqStatsTable from './CompIqStatsTable';
import styles from './index.module.css';
import {
  DevExpressReportRequest,
  DxAvailableReports,
} from '../../ApiTypes/DevExpressReportRequest';
import { requestDx } from '../DxReportRequestModal/useDxReportRequestModal';
import { getDxReport } from '../DocViewModal/useDocViewModal';
import { useAppDispatch } from '../../Reducers/Store';
import {
  setDocViewFileDownload,
  setShowDocView,
} from '../../Actions/DocViewActions';
import { FileDownload } from '../../Types/FileDownLoad';

export default function CompIqStats() {
  const dispatch = useAppDispatch();
  const [fetching, setFetching] = useState<boolean>(false);
  const [stats, setStats] = useState<CompIqStatObj[]>([]);
  const [chartData, setChartData] = useState<DailyCounts[]>([]);

  let formValues: { fromDate: string; toDate: string };

  const onSubmit = (values: { fromDate: string; toDate: string }) => {
    const endDate = format(addDays(new Date(values.toDate), 1), 'yyyy-MM-dd');
    getChartData(values.fromDate, endDate);
    return getStats(values.fromDate, endDate);
  };

  const setShowDx = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownload = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  const getStats = (fromDate: string, toDate: string) => {
    return CompCompleteApi.getStats(fromDate, toDate)
      .then((res) => {
        setStats(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get stats');
      });
  };
  const getChartData = (fromDate: string, toDate: string) => {
    return CompCompleteApi.getChartData(fromDate, toDate)
      .then((res) => {
        setChartData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getExport = () => {
    const endDate = format(
      addDays(new Date(formValues.toDate), 1),
      'yyyy-MM-dd'
    );
    return CompCompleteApi.getExport(formValues.fromDate, endDate)
      .then((res) => {
        downloadExcel(res.data.fileName, res.data.file);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to export');
      });
  };
  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaChartArea className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>
              Comp IQ Stats
              {fetching && (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                  className='mb-2'
                />
              )}
            </h1>
          </div>
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-between'>
        <RFForm
          onSubmit={onSubmit}
          initialValues={{
            fromDate: format(addDays(new Date(), -7), 'yyyy-MM-dd'),
            toDate: format(new Date(), 'yyyy-MM-dd'),
          }}
          render={({ handleSubmit, form, values, submitting }) => {
            formValues = values;
            return (
              <Form onSubmit={handleSubmit}>
                <div className='d-flex  align-items-center gap1Rem'>
                  <Field
                    name='fromDate'
                    type='text'
                    label='Date From'
                    validate={requiredField}
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                  />
                  <Field
                    name='toDate'
                    type='text'
                    label='To Date'
                    validate={requiredField}
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                  />

                  <Button type='submit' variant='primary' size='sm'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Search'
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        />
        <div
          style={{
            display: 'flex',
            gap: '1rem',
          }}
        >
          <Button
            type='button'
            variant='outline-primary'
            size='sm'
            onClick={getExport}
          >
            Export
          </Button>
          <Button
            type='button'
            variant='primary'
            size='sm'
            className='button-icon-text'
            onClick={() => {
              const request: DevExpressReportRequest = {
                ...requestDx,
                report: DxAvailableReports.rptCompIQStats,
                fromDate: formValues.fromDate,
                toDate: formValues.toDate,
                insurCoId: 1,
              };
              getDxReport(request, setFileDownload, setShowDx);
            }}
          >
            <FaEye /> Report
          </Button>
        </div>
      </div>
      <CompIqChart data={chartData} />
      <CompIqStatsTable data={stats} />
    </PageScaffold>
  );
}
