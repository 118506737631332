import { AxiosResponse } from 'axios'
import React, { useState, useEffect } from 'react'
import { Accordion, Button, Container, Form, Modal, Spinner } from 'react-bootstrap'
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext'
import { Field, Form as RFFForm } from "react-final-form"
import { FaCheckSquare } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { fetchCaseManagementResolutionTypes } from '../../../Actions/ReferenceActions'
import ReferenceApi from '../../../Api/ReferenceApi'
import { CaseManagement } from '../../../ApiTypes/CaseManagement'
import { CaseManagementResolutionType } from '../../../ApiTypes/CaseManagementResolutionType'
import { ResponseApiModel } from '../../../ApiTypes/ResponseApiModel'
import { useAppDispatch, useAppSelector } from '../../../Reducers/Store'
import { requiredField } from '../../../Utils/FieldValidation'
import FieldBSRenderSelect from '../../Common/FieldBSRenderSelect'
import FieldBSRenderText from '../../Common/FieldBSRenderText'
import FieldBSRenderTextArea from '../../Common/FieldRenderTextArea'

import styles from "./index.module.css"

export default function NcmResolutionModal({
  show,
  setShow,
  ncm,
  handleResolution,
}: {
  ncm: CaseManagement,
  show: boolean,
  setShow: (show: boolean) => void,
  handleResolution: (ncm: CaseManagement) => Promise<void | AxiosResponse<ResponseApiModel, any>>
}) {
  const dispatch = useAppDispatch();
  const [activeKey, setActiveKey] = useState<AccordionEventKey>('')
  const [createdResolutionId, setCreatedResolutionId] = useState<number | undefined>(undefined)
  const {
    caseManagementResolutionTypes
  } = useAppSelector(state => state.reference)

  const runCleanUp = () => {
    setCreatedResolutionId(undefined)
  }

  const onSubmit = async (values: any) => {
    const updated = { ...ncm, ...values }
    const res = await handleResolution(updated)
    if (res && res.data.success) {
      runCleanUp();
      setShow(false)
    }
  }

  const submitNewResolution = (values: CaseManagementResolutionType) => {
    return createResolutionType(values)
  }

  const createResolutionType = (values: CaseManagementResolutionType) => {
    return ReferenceApi.createCaseManagementResolutionType(values)
      .then(res => {
        if (res.data.success) {
          dispatch(fetchCaseManagementResolutionTypes())
          setCreatedResolutionId(+res.data.affectedEntityIdentifier)
          setActiveKey('')
        } else {
          toast.error(res.data.message)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <Modal
      centered
      show={show}
      size="lg"
      onHide={() => {
        runCleanUp()
        setShow(false)
      }}
      aria-labelledby="NCM-Resolution-Form-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="button-icon-text" id="NCM-Resolution-Form-modal">
          <FaCheckSquare className="pe-1" /> NCM Completion Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p>Are you sure you want to Stop NCM on this Claim?</p>
          <p className='text-center'>Yes - Select a Resolution and Confirm</p>
          <p className='text-center'>No - Cancel</p>
          <Accordion
            onSelect={(eventKey: AccordionEventKey) => setActiveKey(eventKey)}
            activeKey={activeKey}
            className='my-3'>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Add New Resolution</Accordion.Header>
              <Accordion.Body>
                <RFFForm
                  onSubmit={submitNewResolution}
                  initialValues={{ id: 0 }}
                  render={({ handleSubmit, form, values, submitting }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <Field
                          name="name"
                          label="Name"
                          type="text"
                          component={FieldBSRenderText}
                          validate={requiredField}
                        />
                        <div className={styles.buttons}>
                          <Button
                            type="submit"
                            size="sm"
                            variant='primary'
                          >
                            {submitting ?
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              : "Submit"
                            }
                          </Button>
                          <Button
                            type="button"
                            size="sm"
                            variant='secondary'
                            onClick={() => {
                              form.restart();
                              setActiveKey('')
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Form>
                    )
                  }}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={{ resolutionId: createdResolutionId }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name="resolutionId"
                    label="Resolution"
                    options={caseManagementResolutionTypes}
                    optionMethod={(options: CaseManagementResolutionType[]) =>
                      options.map((o) => (
                        <option key={o.id} value={o.id}>
                          {o.name}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                    validate={requiredField}
                  />


                  <Field
                    name="resolutionNotes"
                    label="Notes"
                    rows={3}
                    component={FieldBSRenderTextArea}
                  />
                  <div className={styles.buttons}>
                    <Button
                      type="submit"
                      size="sm"
                      variant='primary'
                    >
                      {submitting ?
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        : "Submit"
                      }
                    </Button>
                    <Button
                      type="button"
                      size="sm"
                      variant='secondary'
                      onClick={() => {
                        runCleanUp();
                        form.reset();
                        setShow(false)
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              )
            }}

          />
        </Container>
      </Modal.Body>
    </Modal>
  )
}
