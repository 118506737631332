import React from 'react';
import styles from './index.module.css';

const redBorder = {
  borderColor: 'red',
};

type FinalRenderFieldProps = {
  input: any;
  label?: string;
  underInputNote?: string;
  required?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  className?: string;
  onChange: (value: any) => void;
  onBlur: (value: any) => void;
  onFocus: () => void;
  onClick: () => void;
  meta: { touched: boolean; error: string; warning: string };
};

export default function FieldBSRenderText({
  input,
  label,
  required,
  disabled,
  readOnly,
  maxLength,
  className,
  placeholder,
  underInputNote,
  autoFocus,
  onChange,
  onBlur,
  onFocus,
  onClick,
  meta: { touched, error, warning },
}: FinalRenderFieldProps) {
  return (
    <div className={`position-relative ${styles.vFieldHeight}`}>
      <label htmlFor={input.name} className='form-label fs-6  m-0'>
        {label}
      </label>
      <input
        autoFocus={autoFocus}
        {...input}
        id={input.name}
        className='form-control form-control-sm'
        placeholder={placeholder || label}
        onChange={(e) => {
          input.onChange(e);
          onChange && onChange(e.target.value);
        }}
        onBlur={(e) => {
          input.onBlur(e);
          onBlur && onBlur(e.target.value);
        }}
        onFocus={(e) => {
          input.onFocus(e);
          onFocus && onFocus();
        }}
        onClick={(e) => {
          onClick && onClick();
        }}
        style={touched && error ? redBorder : {}}
        disabled={disabled}
        readOnly={readOnly}
        maxLength={maxLength}
      />
      {touched &&
        ((error && (
          <span className={`${styles.fieldError} text-danger`}>{error}</span>
        )) ||
          (warning && (
            <span className={`${styles.fieldWarning} text-warning`}>
              {warning}
            </span>
          )))}
    </div>
  );
}
