import React, { useState, useEffect } from 'react';
import { Container, Modal } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import styles from './index.module.css';
import { toast } from 'react-toastify';
import { Employer } from '../../ApiTypes/Employer';
import EmployerApi from '../../Api/EmployerApi';
import BSSelect from '../Common/BSSelect';
import { UserInsuranceCompanyAccess } from '../../ApiTypes/UserInsuranceCompanyAccess';
import UserEmployerAccessTable from './UserEmployerAccessTable';
import { UserEmployerAccess } from '../../ApiTypes/UserEmployerAccess';
import UserInsuranceCompanyAccessApi from '../../Api/UserInsuranceCompanyAccessApi';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';

export default function UserAccessEmployerModal({
  show,
  setShow,
  userAccessId,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  userAccessId: number;
}) {
  const [access, setAccess] = useState<UserInsuranceCompanyAccess | null>(null);
  const [employers, setEmployers] = useState<Employer[]>([]);
  const [selectedEmployerAccess, setSelectedEmployerAccess] =
    useState<UserEmployerAccess | null>(null);

  const [showDelete, setShowDelete] = useState<boolean>(false);

  useEffect(() => {
    getAccess();
  }, [userAccessId]);

  const getAccess = () => {
    UserInsuranceCompanyAccessApi.getById(userAccessId)
      .then((res) => {
        setAccess(res.data);
        getEmployers(res.data.insurCo?.accountnumber!);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get access');
      });
  };

  const getEmployers = (accountNumber: string) => {
    EmployerApi.getEmployerList({
      accountNumber,
    })
      .then((res) =>
        setEmployers(
          res.data.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
        )
      )
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get employers');
      });
  };

  const handleDelete = (ea: UserEmployerAccess) => {
    setSelectedEmployerAccess(ea);
    setShowDelete(true);
  };

  const deleteEmployerAccess = () => {
    if (selectedEmployerAccess) {
      UserInsuranceCompanyAccessApi.deleteEmployerAccess(
        selectedEmployerAccess.id
      )
        .then((res) => {
          if (res.data.success) {
            getAccess();
          } else {
            toast.error(res.data.message);
          }
          setShowDelete(false);
        })
        .catch((err) => {
          console.log(err);
          setShowDelete(false);
          toast.error('Failed to delete');
        });
    }
    return Promise.resolve();
  };

  const handleCreate = (employerId: number) => {
    if (employerId) {
      UserInsuranceCompanyAccessApi.createEmployerAccess(
        userAccessId,
        employerId
      )
        .then((res) => {
          if (res.data.success) {
            getAccess();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to add access');
        });
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='UserAccessEmployerModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='UserAccessEmployerModal-modal'
        >
          <FaCheck className='pe-1' /> User Access Employer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p>
            {access?.insurCo?.insurcoid ?? ''} -{' '}
            {access?.insurCo?.fullname ?? ''}
          </p>
          <BSSelect
            name='employerId'
            label='Employer'
            options={employers}
            onChange={handleCreate}
            optionMethod={(options: Employer[]) =>
              options.map((o) => (
                <option key={o.employerId} value={o.employerId ?? ''}>
                  {o.name}
                </option>
              ))
            }
          />
          <UserEmployerAccessTable
            data={access?.userEmployerAccesses ?? []}
            handleDelete={handleDelete}
          />
          <DeleteConfirmationModal
            show={showDelete}
            setShow={setShowDelete}
            setNull={setSelectedEmployerAccess}
            handleDelete={deleteEmployerAccess}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
