import React from "react"
import { Field } from "react-final-form";

const ErrorField = ({ name }: {
  name: string
}) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? (
        <div className="final-error text-danger" style={{ marginBottom: "1rem" }}>
          {error}
        </div>
      ) : null
    }
  />
);

export default ErrorField;