import React from 'react'
import { Button, Container, Modal } from 'react-bootstrap'
import { FaCheckSquare } from 'react-icons/fa'

export default function DeleteConfirmationModal({
  show,
  setShow,
  setNull,
  handleDelete,
}: {
  show: boolean,
  setNull: (value: null) => void,
  setShow: (show: boolean) => void,
  handleDelete: () => Promise<void>
}) {
  return (
    <Modal
      centered
      show={show}
      size="sm"
      onHide={() => {
        setShow(false)
        setNull(null)
      }}
      aria-labelledby="Delete-Confirm-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="button-icon-text" id="Delete-Confirm-modal">
          <FaCheckSquare className="pe-1" /> Confirm Delete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p>Are you sure you want to Delete this record?</p>
          <div className='d-flex justify-content-around mt-3'>
            <Button
              type="button"
              variant='danger'
              size='sm'
              onClick={() => {
                handleDelete()
              }}
            >
              Delete
            </Button>
            <Button
              type="button"
              variant='secondary'
              size='sm'
              onClick={() => {
                setNull(null)
                setShow(false)
              }}
            >
              Cancel
            </Button>

          </div>
        </Container>
      </Modal.Body>
    </Modal>
  )
}
