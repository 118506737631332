import React, { useState, useEffect, useMemo } from 'react';
import { displayDateAndTime, displayDateOnly } from '../../Utils';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  RowSelectionState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import IndeterminateCheckbox from '../Common/IndeterminateCheckbox';
import accounting from 'accounting';
import { Button } from 'react-bootstrap';
import { FaCopy, FaEye } from 'react-icons/fa';
import PaymentsApi from '../../Api/PaymentsApi';
import { toast } from 'react-toastify';
import { CheckDetail } from '../../ApiTypes/CheckDetail';
import { CheckSaveObj } from '../../ApiTypes/CheckSaveObj';
import CheckDetailModal from '../CheckDetail/CheckDetailModal';
import { useAppSelector } from '../../Reducers/Store';
import PayableWarningModal from './PayableWarningModal';

export default function VCheckTable({
  data,
  rowSelection,
  setRowSelection,
  setSelectedChecks,
  handleDuplicate,
}: {
  data: CheckSaveObj[];
  rowSelection: RowSelectionState;
  setRowSelection: (obj: object) => void;
  setSelectedChecks: (transactions: CheckSaveObj[]) => void;
  handleDuplicate: (check: CheckSaveObj) => Promise<void>;
}) {
  const { userModel } = useAppSelector((state) => state.user);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [showCheckDetail, setShowCheckDetail] = useState<boolean>(false);
  const [checkDetails, setCheckDetails] = useState<CheckDetail[]>([]);

  const [checkToDuplicate, setCheckToDuplicate] = useState<CheckSaveObj | null>(
    null
  );
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const handleViewCheckDetail = (check: CheckSaveObj) => {
    if (check.manual === true) {
      toast.info(
        'Cannot show the check details for a check we did not print. This check was likely Imported.'
      );
      return;
    }
    getDetails(check.checkid);
  };
  const closeDetail = (show: boolean) => {
    setShowCheckDetail(false);
    setCheckDetails([]);
  };

  useEffect(() => {
    handleNewData();
  }, [data]);

  const handleNewData = () => {
    setRowSelection({});
  };

  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  const handleRowSelectionChange = () => {
    setSelectedChecks(
      table.getSelectedRowModel().flatRows.map((r) => r.original)
    );
  };

  const getDetails = (checkId: string) => {
    PaymentsApi.getCheckDetails(checkId)
      .then((res) => {
        setCheckDetails(res.data);
        setShowCheckDetail(true);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get check details');
      });
  };

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<CheckSaveObj>[] = [
    {
      header: ({ table }) => (
        <div className='px-3 d-flex gap1Rem'>
          {Object.keys(rowSelection).length}
          <IndeterminateCheckbox
            className='form-check-input'
            {...{
              id: 'totalSelected',
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      id: 'options',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 125,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center gap1Rem'>
            <IndeterminateCheckbox
              className='form-check-input'
              {...{
                id: row.id,
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='View Check Detail'
              onClick={() => {
                handleViewCheckDetail(row.original);
              }}
            >
              <FaEye />
            </Button>
            {userModel?.user?.isAdmin && (
              <Button
                type='button'
                variant='primary'
                size='sm'
                title='Duplicate Payables'
                onClick={() => {
                  setCheckToDuplicate(row.original);
                  setShowWarning(true);
                }}
              >
                <FaCopy />
              </Button>
            )}
          </div>
        );
      },
    },
    {
      header: 'Check Number',
      accessorKey: 'checknumber',
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
    },
    {
      header: 'Check Date',
      accessorKey: 'checkdate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateAndTime(d.row.original.checkdate ?? ''),
    },
    {
      header: 'Amount',
      accessorFn: (d) => `${d.amount}`,
      sortingFn: 'text',
      filterFn: 'includesString',
      cell: ({ row }) => accounting.formatMoney(row.original.amount ?? 0),
    },
    {
      header: 'Payable To',
      accessorKey: 'payee',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Payee Type',
      accessorKey: 'payeetype',
      sortingFn: 'text',
      filterFn: 'includesString',
      maxSize: 85,
    },
    {
      header: 'Status',
      accessorKey: 'status',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 75,
    },
    {
      header: 'Imported',
      accessorFn: (d) => (d.manual === true ? 'YES' : 'NO'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 75,
    },
  ];

  const columns = useMemo(() => columnData, [rowSelection, userModel]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
      />
      <CheckDetailModal
        setShow={closeDetail}
        show={showCheckDetail}
        checkDetails={checkDetails}
      />
      <PayableWarningModal
        show={showWarning}
        setShow={setShowWarning}
        check={checkToDuplicate}
        handleDuplicate={handleDuplicate}
        setNull={setCheckToDuplicate}
      />
    </div>
  );
}
