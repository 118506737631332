import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { FaEye, FaPlusCircle, FaTrash } from 'react-icons/fa';
import { ProviderFile } from '../../ApiTypes/ProviderFile';
import { displayDateOnly } from '../../Utils';

export default function ProviderFileTable({
  data,
  handleDelete,
  handleView,
  handleAdd,
}: {
  data: ProviderFile[];
  handleDelete: (file: ProviderFile) => void;
  handleView: (file: ProviderFile) => void;
  handleAdd: () => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<ProviderFile>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center align-items-center gap1Rem'>
            <Button
              type='button'
              variant='primary'
              size='sm'
              onClick={() => {
                handleView(row.original);
              }}
            >
              <FaEye />
            </Button>
            <Button
              type='button'
              variant='danger'
              size='sm'
              onClick={() => {
                handleDelete(row.original);
              }}
            >
              <FaTrash />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Name',
      accessorKey: 'name',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Type',
      accessorKey: 'type',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date',
      accessorKey: 'dateCreated',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dateCreated ?? ''),
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <div>
      <div className='d-flex justify-content-around align-items-center py-3'>
        <p className='mb-0 fw-bold'>Files</p>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={handleAdd}
        >
          <FaPlusCircle /> File
        </Button>
      </div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
      />
    </div>
  );
}
