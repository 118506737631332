export enum SiteFunctionsEnum {
  SearchClaims = 1,
  EnterNewClaim = 2,
  DeleteClaim = 4,
  NewClaimRequest = 6,
  ClaimReports = 7,
  FinancialReports = 8,
  EDIReports = 9,
  NCMReports = 10,
  MECCExportData = 11,
  USSUExportData = 12,
  SafetyNationalExport = 13,
  YKKExport = 14,
  DocumentReports = 15,
  ApproveReserves = 16,
  CompCompleteReport = 17,
  ExcessDetailReport = 18,
  ScanningDocuments = 19,
  StraightPay = 20,
  CompIQ = 21,
  EORProcessing = 22,
  NCMDocuments = 23,
  ManagerDashboard = 24,
  TurnaroundAverages = 25,
  DocNotesRequests = 26,
  ApprovePayments = 68,
  SecondaryApproval = 69,
  CreatePaymentBatch = 70,
  ViewPaymentBatches = 71,
  ViewChecks = 72,
  ViewChecksNew = 73,
  ImportPayments = 74,
  ProcessRecurringPayments = 75,
  EnterPayments = 76,
  AdjustmentEntry = 77,
  ReviewPaymentsEnteredToday = 78,
  RePrintChecks = 79,
  EnterDeductiblePayments = 80,
  ReconcileChecks = 81,
  ImportCheckNumbers = 82,
  ApproveSettlements = 83,
  ProviderMaintenance = 84,
  LawFirms = 85,
  ProviderCleanup = 86,
  ViewPanels = 87,
  TableMaintenance = 89,
  CreateNewReport = 90,
  ManageEmails = 91,
  EDILog = 92,
  WCPanels = 93,
  RemoveSWBCDuplicates = 94,
  ScheduleLossRunsExternal = 95,
  ImportFromCompIQ = 96,
  ViewImportExceptions = 97,
  CompIQEOMFees = 98,
  OpenClaimListingByYear = 99,
  OpenClaimListingByEmployer = 100,
  OpenClaimListingByAdjuster = 101,
  LossRunByProgram = 102,
  LossRunByProgramNew = 103,
  LossRunByProgramWithDetailForOpenClaims = 104,
  LossRunDetail = 105,
  LossRunDetailAllEmployersAllClaims = 106,
  LossRunReport = 107,
  LossRunReportByMemberExternal = 108,
  DiaryListing = 109,
  CheckRegisterLongFormat = 110,
  CheckRegisterCompactFormat = 111,
  SettlementReport = 112,
  SendGEMCLossRunsReports = 113,
  CheckRegisterAllAccounts = 114,
  CheckRegisterBasic = 115,
  MIDMANExportData = 116,
  Users = 117,
  Departments = 118,
  UserCategories = 119,
  Policies = 120,
  Notes = 121,
  Payments = 122,
  EnterPaymentsClaims = 123,
  EditPayments = 124,
  Reserves = 125,
  UpdateReserves = 126,
  IndemnityPayments = 127,
  Ncm = 128,
  Legal = 129,
  Recoveries = 130,
  Letters = 131,
  ScannedDocuments = 132,
  SwbcReporting = 133,
  FroiInfo = 134,
  ClaimReview = 135,
  Physicians = 136,
  WorkStatus = 137,
  ScaningCentral = 138,
  SiteFunctionGroupSetup = 139,
  DiaryTab = 140,
  WitnessesTab = 141,
  ClaimPropertyTab = 142,
  ClaimCMSInfoTab = 143,
  ClaimSubrogationTab = 144,
  LitigationTab = 145,
}

export enum SiteFunctionGroups {
  Claims = 2,
  Reports = 3,
  Documents = 4,
  Payments = 5,
  Providers = 6,
  WCPanels = 7,
  Setup = 8,
}

export enum SiteFunctionDefaultGroupEnum {
  Admin = 1,
  Adjuster = 2,
  Vendor = 3,
}
