import React, { useState, useEffect } from 'react';
import { Field, Form as RFForm } from 'react-final-form';
import { FaSync } from 'react-icons/fa';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { toast } from 'react-toastify';
import { Button, Form, Spinner } from 'react-bootstrap';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { VPolicy } from '../../ApiTypes/VPolicy';
import PolicyApi from '../../Api/PolicyApi';
import VPolicyTable from './VPolicyTable';
import { FormApi } from 'final-form';

export default function SyncUWPolicies() {
  const [years, setYears] = useState<number[]>([]);
  const [policiesNotInClaims, setPoliciesNotInClaims] = useState<VPolicy[]>([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedPolicies, setSelectedPolicies] = useState<VPolicy[]>([]);
  const [syncing, setSyncing] = useState<boolean>(false);

  let formInstance: FormApi<
    { treatyYear: number },
    Partial<{ treatyYear: number }>
  >;

  useEffect(() => {
    loadYears();
  }, []);

  const loadYears = () => {
    var ys: number[] = [];
    var thisYear = new Date().getFullYear();
    for (let i = 0; i <= 10; i++) {
      ys.push(thisYear - i);
    }
    setYears(ys);
  };

  const onSubmit = (values: { treatyYear: number }) => {
    return PolicyApi.getPoliciesNotInClaims(values.treatyYear)
      .then((res) => {
        setPoliciesNotInClaims(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get policies');
      });
  };

  const handleSync = () => {
    if (selectedPolicies.length === 0) {
      toast.error('No policies selected.');
      return;
    }
    setSyncing(true);
    PolicyApi.syncUwPolicies({ vPolicies: selectedPolicies })
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          formInstance.submit();
        } else {
          toast.error(res.data.message);
        }
        setSyncing(false);
      })
      .catch((err) => {
        console.log(err);
        setSyncing(false);
        toast.error('Failed dto sync policies');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaSync className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Sync Policies from Underwriting</h1>
            <p>
              The following policies are found in underwriting, but not in
              claims.
            </p>
          </div>
          <div className='ms-3'></div>
        </div>
      </div>
      <div className='d-flex justify-content-around align-items-center'>
        <RFForm
          onSubmit={onSubmit}
          initialValues={{}}
          render={({ handleSubmit, form, values, submitting }) => {
            formInstance = form;
            return (
              <Form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-center align-items-center gap1Rem'>
                  <Field
                    name='treatyYear'
                    label='Treaty Year'
                    options={years}
                    optionMethod={(options: number[]) =>
                      options.map((o) => (
                        <option key={o} value={o}>
                          {o}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  />
                  <Button type='submit' size='sm' variant='primary'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Refresh'
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        />
        <Button
          type='button'
          className='button-icon-text'
          variant='primary'
          size='sm'
          disabled={selectedPolicies.length === 0}
          onClick={handleSync}
        >
          {syncing ? (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            <>
              <FaSync /> Sync
            </>
          )}
        </Button>
      </div>
      <VPolicyTable
        data={policiesNotInClaims}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        setSelectedPolicies={setSelectedPolicies}
      />
    </PageScaffold>
  );
}
