import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { Panel } from '../ApiTypes/Panel';
import { DoctorsOffice } from '../ApiTypes/DoctorsOffice';

class PanelApi {
  static getPanelList(insurCoId: number) {
    return axios.get<Panel[]>(
      `${API_URL}/api/panels?insCoId=${insurCoId}`,
      getAuthHeader()
    );
  }
  static getPanelById(panelId: number) {
    return axios.get<Panel>(
      `${API_URL}/api/panels/${panelId}`,
      getAuthHeader()
    );
  }
  static createPanel(panel: Panel) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/panels`,
      panel,
      getAuthHeader()
    );
  }
  static getDoctorOfficesForPanel(panelId: number) {
    return axios.get<DoctorsOffice[]>(
      `${API_URL}/api/panels/${panelId}/doctorsoffices`,
      getAuthHeader()
    );
  }
  static removeOfficeFromPanel(panelId: number, doctorId: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/panels/${panelId}/doctorsoffices/${doctorId}`,
      getAuthHeader()
    );
  }
  static addOfficeToPanel(panelId: number, doctorId: number) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/panels/${panelId}/doctorsoffices/${doctorId}`,
      {},
      getAuthHeader()
    );
  }
}

export default PanelApi;
