import React from 'react';
import { Field } from 'react-final-form';
import { parseDatesForServer } from '../../Utils';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderText from '../Common/FieldBSRenderText';

import styles from './index.module.css';

export default function Wc1Employment({ values }: { values: any }) {
  return (
    <div className='d-flex justify-content-center'>
      <div className='w-50 pe-2'>
        <Field
          name='dateHired'
          label='Hire Date'
          parse={parseDatesForServer}
          component={FieldBSRenderDate}
        />
        <Field
          name='jobClassificationCode'
          label='Job Title'
          type='text'
          component={FieldBSRenderText}
        />
        <Field
          name='employerNaicscode'
          type='text'
          label='NAICS Code (Department)'
          component={FieldBSRenderText}
        />
        <div className='d-flex justify-content-between align-items-center'>
          <div className={styles.width35}>
            <Field
              name='daysWorkedPerWeek'
              label='Number of Days Worked per Week'
              type='number'
              component={FieldBSRenderText}
            />
          </div>
          <div
            className={`d-flex justify-content-center  ${styles.flexGap1rem}`}
          >
            <Field
              name='probationary'
              type='checkbox'
              label='Probationary'
              // parse={(value: string, name: string) => value === 'true'}
              checked={values?.probationary === true}
              component={FieldBSRenderCheckbox}
            />
          </div>
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <div className={styles.width35}>
            <Field
              name='wage'
              label='Wage Rate at Time of Injury'
              type='text'
              component={FieldBSRenderText}
            />
          </div>
          <div
            className={`d-flex justify-content-center ${styles.flexGap1rem}`}
          >
            <Field
              name='wageType'
              type='radio'
              label='per Hour'
              value={0}
              checked={+values?.wageType === 0}
              component={FieldBSRenderCheckbox}
            />
            <Field
              name='wageType'
              type='radio'
              label='per Day'
              value={1}
              checked={+values.wageType === 1}
              component={FieldBSRenderCheckbox}
            />
            <Field
              name='wageType'
              type='radio'
              label='per Week'
              value={2}
              checked={+values?.wageType === 2}
              component={FieldBSRenderCheckbox}
            />
            <Field
              name='wageType'
              type='radio'
              label='per Month'
              value={3}
              checked={+values.wageType === 3}
              component={FieldBSRenderCheckbox}
            />
          </div>
        </div>
        <Field
          name='normalScheduledDaysOff'
          label='Normally Scheduled Days Off'
          type='text'
          component={FieldBSRenderText}
        />
        <Field
          name='rtwdate'
          label='Returned to Work Date'
          parse={parseDatesForServer}
          component={FieldBSRenderDate}
        />
        <Field
          name='returnWage'
          label='Returned at What Wage (per Week)'
          type='text'
          component={FieldBSRenderText}
        />
      </div>
    </div>
  );
}
