import React from 'react';
import {
  downloadAny,
  downloadCsv,
  downloadExcel,
  downloadIif,
  downloadMp3,
  downloadMsg,
  downloadPgp,
  downloadRpt,
  downloadTxt,
  downloadWav,
  downloadZip,
} from '../../Utils';
import styles from './index.module.css';
import { Button } from 'react-bootstrap';
import { FaFileDownload } from 'react-icons/fa';

export default function DownloadComponent({
  base64Data,
  fileName,
}: {
  base64Data: string;
  fileName: string;
}) {
  const download = () => {
    if (fileName && fileName.toLowerCase().endsWith('.wav')) {
      downloadWav(fileName, base64Data);
    } else if (fileName && fileName.toLowerCase().endsWith('.csv')) {
      downloadCsv(fileName, base64Data);
    } else if (fileName && fileName.toLowerCase().endsWith('.txt')) {
      downloadTxt(fileName, base64Data);
    } else if (fileName && fileName.toLowerCase().endsWith('.zip')) {
      downloadZip(fileName, base64Data);
    } else if (fileName && fileName.toLowerCase().endsWith('.pgp')) {
      downloadPgp(fileName, base64Data);
    } else if (fileName && fileName.toLowerCase().endsWith('.iif')) {
      downloadIif(fileName, base64Data);
    } else if (fileName && fileName.toLowerCase().endsWith('.msg')) {
      downloadMsg(fileName, base64Data);
    } else if (fileName && fileName.toLowerCase().endsWith('.mp3')) {
      downloadMp3(fileName, base64Data);
    } else if (fileName && fileName.toLowerCase().endsWith('.rpt')) {
      downloadRpt(fileName, base64Data);
    } else if (
      fileName &&
      (fileName.toLowerCase().endsWith('.xls') ||
        fileName.toLowerCase().endsWith('.xlsx'))
    ) {
      downloadExcel(fileName, base64Data);
    } else {
      downloadAny(fileName, base64Data);
    }
  };

  if (base64Data) {
    return (
      <div>
        <div className='d-flex justify-content-center align-items-center mb-3'>
          <Button
            type='button'
            onClick={download}
            variant='outline-primary'
            className='button-icon-text'
            style={{
              width: 'auto',
            }}
            size='sm'
            title='Download'
          >
            <FaFileDownload /> Download
          </Button>
        </div>
      </div>
    );
  }
  return <p className={styles.noResults}>Document Not Available</p>;
}
