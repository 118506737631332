import React from 'react';
import { Column, Table } from '@tanstack/react-table';
import { Form } from 'react-bootstrap';

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 300,
  type,
  id,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  type: string;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Form.Control
      {...props}
      type='search'
      id={id}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      size='sm'
    />
  );
}

export function Filter<T extends { [key: string]: any }>({
  column,
  table,
}: {
  column: Column<any, unknown>;
  table: Table<T>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys())
            .filter((v) => v !== '' && v !== null)
            .sort(),
    [column.getFacetedUniqueValues()]
  );
  // console.log(column.id, sortedUniqueValues, column.getFacetedUniqueValues().keys())
  return typeof firstValue === 'number' ? (
    <div>
      <div>
        <DebouncedInput
          type='number'
          id={column.id}
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value) =>
            column.setFilterValue((old: [number, number]) => [value, value])
          }
          placeholder={`(${column.getFacetedMinMaxValues()?.[0]} - ${
            column.getFacetedMinMaxValues()?.[1]
          })`}
          className='border shadow-sm'
        />
      </div>
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => {
          return <option value={value} key={value} />;
        })}
      </datalist>
      <DebouncedInput
        type='text'
        id={column.id}
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search (${sortedUniqueValues.length})`} //column.getFacetedUniqueValues().size
        className='border shadow-sm'
        list={column.id + 'list'}
      />
    </>
  );
}
