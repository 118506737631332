import React, { useRef, useEffect } from 'react';
import styles from './index.module.css';

export default function TiffThumbnail({
  canvas,
  pageNumber,
  currentPageNumber,
  onThumbnailClick,
}: {
  canvas: HTMLImageElement;
  pageNumber: number;
  currentPageNumber: number;
  onThumbnailClick: (pageNumber: number) => void;
}) {
  const canvasRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (canvasRef.current as HTMLDivElement).innerHTML = '';
    (canvasRef.current as HTMLDivElement).appendChild(canvas);
  }, [canvas]);

  return (
    <div
      className={
        pageNumber === currentPageNumber
          ? `${styles.pdfThumbnail_selected}`
          : `${styles.pdfThumbnail}`
      }
      id='thumb'
      key={pageNumber}
      onClick={() => onThumbnailClick(pageNumber)}
      ref={canvasRef}
    ></div>
  );
}
