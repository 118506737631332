import React, { useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { FaBan, FaEdit, FaTrash } from 'react-icons/fa';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import styles from './index.module.css';
import { EmployerPolicy } from '../../ApiTypes/EmployerPolicy';
import { displayDateOnly } from '../../Utils';
import accounting from 'accounting';
import { Link } from 'react-router-dom';

export default function PolicyTable({
  policies,
  handleDelete,
  handleRemove,
  location,
}: {
  handleDelete: (policy: EmployerPolicy) => void;
  handleRemove: (policy: EmployerPolicy) => void;
  policies: EmployerPolicy[];
  location: object;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => policies, [policies]);

  const columnData: ColumnDef<EmployerPolicy>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      size: 145,
      cell: (d) => {
        return (
          <div className='d-flex justify-content-center gap1Rem'>
            <Link to={`/policies/${d.row.original.policyId}`}>
              <Button type='button' size='sm' variant='primary' title='Edit'>
                <FaEdit />
              </Button>
            </Link>
            <Button
              type='button'
              size='sm'
              variant='danger'
              onClick={() => {
                handleRemove(d.row.original);
              }}
              title='Remove Excess Policy'
            >
              <FaBan />
            </Button>
            <Button
              type='button'
              size='sm'
              variant='danger'
              onClick={() => {
                handleDelete(d.row.original);
              }}
              title='Delete'
            >
              <FaTrash />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Name',
      accessorKey: 'employer.name',
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
    },
    {
      header: 'Treaty Year',
      accessorFn: (d) => `${d.treatyYear}`,
      sortingFn: 'text',
      filterFn: 'includesString',
      size: 100,
    },
    {
      header: 'Policy Number',
      accessorKey: 'policyNumber',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Start Date',
      accessorKey: 'startDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      size: 100,
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'End Date',
      accessorKey: 'endDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      size: 100,
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Premium',
      accessorFn: (d) => `${d.premium}`,
      sortingFn: 'text',
      filterFn: 'includesString',
      cell: ({ row }) => accounting.formatMoney(row.original.premium ?? 0),
    },
    {
      header: 'Deductible',
      accessorFn: (d) => `${d.deductible}`,
      sortingFn: 'text',
      filterFn: 'includesString',
      cell: ({ row }) => accounting.formatMoney(row.original.deductible ?? 0),
    },
    {
      header: 'Employer Id',
      accessorFn: (d) => `${d.employerId}`,
      sortingFn: 'text',
      filterFn: 'includesString',
      size: 100,
    },
    {
      header: 'Policy Id',
      accessorFn: (d) => `${d.policyId}`,
      sortingFn: 'text',
      filterFn: 'includesString',
      size: 100,
    },
    {
      header: 'Excess Policy Id',
      accessorFn: (d) => `${d.excessPolicy?.carrierPolicyNo ?? 'None'}`,
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Excess SIR',
      accessorFn: (d) => `${d.excessPolicy?.defaultSir}`,
      sortingFn: 'text',
      filterFn: 'includesString',
      cell: ({ row }) =>
        accounting.formatMoney(row.original.excessPolicy?.defaultSir ?? 0),
    },
  ];

  const columns = useMemo(() => columnData, [location]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
