import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { ServerSetting } from '../ApiTypes/ServerSetting';
import { SettingRequest } from '../ApiTypes/SettingRequest';

class SettingApi {
  static getSettingByName(request: SettingRequest) {
    return axios.post<ServerSetting>(
      `${API_URL}/api/settings/get`,
      request,
      getAuthHeader()
    );
  }
  static updateSetting(request: ServerSetting) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/settings/${request.settingId}`,
      request,
      getAuthHeader()
    );
  }
}

export default SettingApi;
