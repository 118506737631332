import React from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaCheck } from 'react-icons/fa';
import { CcExceptionObj } from '../../ApiTypes/CcExceptionObj';
import { UpdateControlNumberRequest } from '../../ApiTypes/UpdateControlNumberRequest';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderText from '../Common/FieldBSRenderText';

export default function UpdateControlNumber({
  show,
  setShow,
  exception,
  submit,
}: {
  show: boolean;
  exception: CcExceptionObj | null;
  setShow: (show: boolean) => void;
  submit: (exc: UpdateControlNumberRequest) => void;
}) {
  const onSubmit = (values: CcExceptionObj) => {
    const request: UpdateControlNumberRequest = {
      docId: exception?.docId!,
      newCompIqControlNumber: values.ccDocumentNum,
      notes: '',
    };
    return submit(request);
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='UpdateClaimNumber-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='UpdateClaimNumber-modal'>
          <FaCheck className='pe-1' /> Update Control Number
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              ourDocumentNum: exception?.ourDocumentNum,
              ccDocumentNum: exception?.ccDocumentNum,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='ourDocumentNum'
                    label='Current Number'
                    type='text'
                    readOnly
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='ccDocumentNum'
                    label='New Number'
                    type='text'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <div className='d-flex justify-content-center'>
                    <Button type='submit' variant='outline-primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
