import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaEnvelopeOpenText, FaPlusCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { AutomatedReportingJob } from '../../ApiTypes/AutomatedReportingJob';
import AutomatedReportingApi from '../../Api/AutomatedReportingApi';
import AutomatedJobsTable from './AutomatedJobsTable';
import EditJob from './EditJob';
import ConfirmSendAllNow from './ConfirmSendAllNow';
import ExportApi from '../../Api/ExportApi';
import { downloadExcel } from '../../Utils';
import { automatedJobsExportHandler } from '../PaymentReport/AutomatedJobsExportHandler';
import { useAppSelector } from '../../Reducers/Store';

export default function AutomatedReporting() {
  const { userModel } = useAppSelector((state) => state.user);
  const [jobs, setJobs] = useState<AutomatedReportingJob[]>([]);
  const [selectedJob, setSelectedJob] = useState<AutomatedReportingJob | null>(
    null
  );
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  useEffect(() => {
    getAllJobs();
  }, []);

  const getAllJobs = () => {
    AutomatedReportingApi.getAllJobs()
      .then((res) => {
        setJobs(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get jobs');
      });
  };

  const updateJob = (job: AutomatedReportingJob) => {
    return AutomatedReportingApi.updateJob(job)
      .then((res) => {
        if (res.data.success) {
          setShowEdit(false);
          getAllJobs();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update job');
      });
  };

  const handleDeleteJob = (job: AutomatedReportingJob) => {
    job.deleted = true;
    return updateJob(job);
  };

  const handleEdit = (job: AutomatedReportingJob) => {
    setSelectedJob(job);
    setShowEdit(true);
  };
  const handleTest = (job: AutomatedReportingJob) => {
    setSelectedJob(job);
    AutomatedReportingApi.sendJob(
      job.id,
      true,
      userModel?.user?.emailAddress ?? ''
    )
      .then((res) => {
        if (res.data.success) {
          toast.success(
            `Report sent to ${userModel?.user?.emailAddress ?? ''}`
          );
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Test Failed');
      });
  };
  const handleConfirm = () => {
    setShowConfirm(false);
    AutomatedReportingApi.sendAllJobs();
    toast.success('Loss Runs Processed');
  };

  const createJob = (job: AutomatedReportingJob) => {
    return AutomatedReportingApi.createJob(job)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          setShowEdit(false);
          getAllJobs();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create job');
      });
  };

  const handelAddUpdate = (job: AutomatedReportingJob) => {
    if (job.id > 0) {
      return updateJob(job);
    }
    return createJob(job);
  };

  const exportToExcel = () => {
    const exportData = automatedJobsExportHandler(jobs);
    exportData.fileName = `Scheduled Loss Runs.xls`;

    return ExportApi.exportToExcel(exportData)
      .then((res) => {
        downloadExcel(res.data.fileName, res.data.file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaEnvelopeOpenText className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Reporting Email Jobs</h1>
            <p>View all automated reporting jobs</p>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center gap1Rem py-3'>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          className='button-icon-text'
          onClick={() => {
            setSelectedJob(null);
            setShowEdit(true);
          }}
        >
          <FaPlusCircle /> New Job
        </Button>
        {userModel?.user?.userId === 'danie_000' && (
          <Button
            type='button'
            variant='outline-primary'
            size='sm'
            className='button-icon-text'
            onClick={() => {
              setShowConfirm(true);
            }}
          >
            Send All Now
          </Button>
        )}
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          className='button-icon-text'
          onClick={() => {
            exportToExcel();
          }}
        >
          Export
        </Button>
      </div>
      <AutomatedJobsTable
        data={jobs}
        handleDelete={handleDeleteJob}
        handleEdit={handleEdit}
        handleTest={handleTest}
      />
      <EditJob
        show={showEdit}
        setShow={setShowEdit}
        job={selectedJob}
        handleSubmit={handelAddUpdate}
      />
      <ConfirmSendAllNow
        show={showConfirm}
        setShow={setShowConfirm}
        handleConfirm={handleConfirm}
      />
    </PageScaffold>
  );
}
