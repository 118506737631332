import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ClassCodeApi from '../../Api/ClassCodeApi';
import { ClassCodeItem } from '../../ApiTypes/ClassCode';
import ClassCodeTable from './ClassCodeTable';
import { Button } from 'react-bootstrap';
import PolicyClassCodeApi from '../../Api/PolicyClassCodeApi';
import ExcessCarrierPolicyClassCodeApi from '../../Api/ExcessCarrierPolicyClassCodeApi';

export default function ClassCodes({
  policyId,
  isExcess,
  refreshData,
  setShow,
}: {
  policyId?: number;
  isExcess?: boolean;
  refreshData: () => void;
  setShow?: (show: boolean) => void;
}) {
  const [classCodes, setClassCodes] = useState<ClassCodeItem[]>([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [selectedCodes, setSelectedCodes] = useState<ClassCodeItem[]>([]);

  useEffect(() => {
    getClassCodes();
    getClassCodeList();
  }, [policyId, isExcess]);

  const updateClassCode = (classCode: ClassCodeItem) => {
    ClassCodeApi.update(classCode)
      .then((res) => {
        if (res.data.success) {
          toast.success('Class Code updated');
        } else {
          toast.error(res.data.message);
          getClassCodes();
        }
      })
      .catch((err) => {
        console.log(err);
        getClassCodes();
        toast.error('Failed to update Class Code');
      });
  };

  const getClassCodes = () => {
    if (policyId && policyId > 0) {
      ClassCodeApi.getForPolicy(policyId ?? 0, policyId ? true : false)
        .then((res) => {
          setClassCodes(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get class codes');
        });
    }
  };

  const getClassCodeList = () => {
    if (!policyId) {
      ClassCodeApi.getClassCodes()
        .then((res) => {
          setClassCodes(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get class codes');
        });
    }
  };

  const addToPolicy = () => {
    Promise.all(
      selectedCodes.map((c) =>
        PolicyClassCodeApi.create({
          policyId: +policyId!,
          classCodeId: c.classCodeId,
          sirAmount: 0,
          keyCodeId: 0,
          classCode: null,
        })
      )
    )
      .then((res) => {
        res.forEach((r) => {
          if (!r.data.success) {
            toast.error(r.data.message);
          }
        });
        refreshData();
        setShow && setShow(false);
      })
      .catch((err) => {
        console.log(err);
        refreshData();
        toast.error('Failed to attach class codes to policy');
      });
  };

  const addToExcessPolicy = () => {
    Promise.all(
      selectedCodes.map((c) =>
        ExcessCarrierPolicyClassCodeApi.create({
          id: 0,
          excessCarrierPolicyId: +policyId!,
          classCodeId: c.classCodeId,
          defaultSir: c.sirAmount,
          payroll: 0,
          classCode: c.classCode,
          ClassCodeItem: null,
        })
      )
    )
      .then((res) => {
        res.forEach((r) => {
          if (!r.data.success) {
            toast.error(r.data.message);
          }
        });
        refreshData();
        setShow && setShow(false);
      })
      .catch((err) => {
        console.log(err);
        refreshData();
        toast.error('Failed to attach class codes to policy');
      });
  };

  return (
    <div>
      <div className='d-flex justify-content-center align-items-center py-3'>
        {policyId && policyId > 0 ? (
          <Button
            type='button'
            variant='primary'
            size='sm'
            disabled={selectedCodes.length === 0}
            onClick={() => {
              if (isExcess) {
                addToExcessPolicy();
              } else {
                addToPolicy();
              }
            }}
          >
            Add to Policy
          </Button>
        ) : (
          <div />
        )}
      </div>
      <ClassCodeTable
        codes={classCodes}
        setData={setClassCodes}
        updateClassCode={updateClassCode}
        setSelectedCodes={setSelectedCodes}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        hasPolicyId={!!(policyId && policyId > 0)}
      />
    </div>
  );
}
