import React, { useState, useEffect } from 'react';
import { useAppSelector } from '../../../Reducers/Store';
import { ClaimSearchRequest } from '../../../ApiTypes/ClaimSearchRequest';
import ClaimApi from '../../../Api/ClaimApi';
import { ClaimSearchResult } from '../../../ApiTypes/ClaimSearchResult';
import ClaimSearchModal from '../../TopToolbar/ClaimSearch/ClaimSearchModal';

export default function MoveClaimNoteToNewClaim({
  show,
  setShow,
  handleMoveNoteToNewClaim,
}: {
  show: boolean;
  setShow: (value: boolean) => void;
  handleMoveNoteToNewClaim: (result: ClaimSearchResult) => void;
}) {
  const [searchResults, setSearchResults] = useState<ClaimSearchResult[]>([]);

  useEffect(() => {
    clearResults();
  }, [show]);

  const { adjusters, supervisors, insuranceCompanies, claimTypes } =
    useAppSelector((state) => state.reference);
  const { userModel } = useAppSelector((state) => state.user);

  const search = (values: ClaimSearchRequest) => {
    return ClaimApi.searchClaims(values)
      .then((res) => {
        setSearchResults(res.data);
      })
      .catch((err) => {
        setSearchResults([]);
        console.log(err);
      });
  };
  const clearResults = () => {
    if (!show) {
      setSearchResults([]);
    }
  };

  return (
    <ClaimSearchModal
      show={show}
      setShow={setShow}
      search={search}
      searchResults={searchResults}
      setSearchResults={setSearchResults}
      adjusters={adjusters}
      insuranceCompanies={insuranceCompanies}
      supervisors={supervisors}
      handleSelectedClaim={handleMoveNoteToNewClaim}
      user={userModel}
      claimTypes={claimTypes}
    />
  );
}
