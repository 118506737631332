import { format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { FaHistory } from 'react-icons/fa';
import ExportApi from '../../Api/ExportApi';
import PaymentsApi from '../../Api/PaymentsApi';
import {
  ClaimFinancialHistoryDetailObj,
  ClaimFinancialHistoryObj,
} from '../../ApiTypes/HistoryObjects';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import { downloadExcel } from '../../Utils';
import ClaimFinancialHistoryChart from './ClaimFinancialHistoryChart';
import { claimFinancialHistoryExportHandler } from './ClaimFinancialHistoryExcelHelper';
import ClaimFinancialHistoryTable from './ClaimFinancialHistoryTable';
import styles from './index.module.css';
import { getDxReport } from '../DocViewModal/useDocViewModal';
import { requestDx } from '../DxReportRequestModal/useDxReportRequestModal';
import {
  DevExpressReportRequest,
  DxAvailableReports,
} from '../../ApiTypes/DevExpressReportRequest';
import {
  setDocViewFileDownload,
  setShowDocView,
} from '../../Actions/DocViewActions';
import { FileDownload } from '../../Types/FileDownLoad';

export default function ClaimFinancialHistoryModal({
  show,
  setShow,
  claimNumber,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  claimNumber: string;
}) {
  const dispatch = useAppDispatch();
  const { claim } = useAppSelector((state) => state.currentClaimReducer);
  const [result, setResult] = useState<ClaimFinancialHistoryObj | null>(null);
  const [tableData, setTableData] = useState<ClaimFinancialHistoryDetailObj[]>(
    []
  );

  useEffect(() => {
    getHistory();
  }, [claim]);

  const setShowDx = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownload = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  const getHistory = () => {
    if (claim) {
      const doi = format(new Date(claim?.injuryDate!), 'yyyy-MM-dd');
      const end =
        claim?.status?.toUpperCase() === 'C'
          ? new Date(claim.statusEffectiveDate ?? '')
          : new Date();
      const endDate = format(end, 'yyyy-MM-dd');
      PaymentsApi.getFinancialHistory(claim?.claimNo ?? '', doi, endDate)
        .then((res) => {
          setResult(res.data);
          setTableData(res.data.details);
        })
        .catch((err) => console.log(err));
    }
  };

  const exportExcel = () => {
    if (tableData.length > 0) {
      const exportData = claimFinancialHistoryExportHandler(tableData);
      exportData.fileName = `${claimNumber}-ClaimFinancialHistory-Report.xls`;

      return ExportApi.exportToExcel(exportData)
        .then((res) => {
          downloadExcel(res.data.fileName, res.data.file);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='CLaim-Financial-History-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='CLaim-Financial-History-modal'
        >
          <FaHistory className='pe-1' /> Claim Financial History
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <div className='d-flex justify-content-center align-items-center gap1Rem mb-3'>
            <Button
              type='button'
              variant='outline-primary'
              size='sm'
              onClick={() => {
                getHistory();
              }}
            >
              Refresh
            </Button>
            <Button
              type='button'
              variant='outline-primary'
              size='sm'
              onClick={() => {
                exportExcel();
              }}
            >
              Export
            </Button>
            <Button
              type='button'
              variant='outline-primary'
              size='sm'
              onClick={() => {
                const request: DevExpressReportRequest = {
                  ...requestDx,
                  claimNumber: claimNumber,
                  report: DxAvailableReports.rptReserveHistory,
                  insurCoId: claim?.insurcoid ?? 1,
                };
                getDxReport(request, setFileDownload, setShowDx);
              }}
            >
              Export PDF
            </Button>
          </div>
          <ClaimFinancialHistoryChart data={tableData} />
          <ClaimFinancialHistoryTable data={tableData} />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
