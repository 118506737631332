import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaEdit } from 'react-icons/fa';
import { Adjuster } from '../../ApiTypes/Adjuster';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { State } from '../../ApiTypes/State';
import { useAppSelector } from '../../Reducers/Store';
import { centsValidation, zipLength } from '../../Utils/FieldValidation';
import { formatNumbers, cleanMoney } from '../../Utils/InputFormatters';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import styles from './index.module.css';
import NewClaimEmailDocsTable from '../NewClaimEmailDocs/NewClaimEmailDocsTable';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function AddEditInsuranceCompany({
  show,
  setShow,
  ins,
  handleAddUpdate,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  ins: InsuranceCompany | null;
  handleAddUpdate: (values: InsuranceCompany) => Promise<void>;
}) {
  const { states, adjusters } = useAppSelector((state) => state.reference);
  const [quillValue, setQuillValue] = useState('');

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ['right', 'center', 'justify'] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      [{ color: ['red', '#785412'] }],
      [{ background: ['red', '#785412'] }],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'image',
    'background',
    'align',
    'size',
    'font',
  ];
  useEffect(() => {
    if (quillValue === '' && ins?.emailBodyOnClaimCreate) {
      setQuillValue(ins.emailBodyOnClaimCreate);
    }
  }, [ins]);

  const onSubmit = (values: InsuranceCompany) => {
    values.emailBodyOnClaimCreate = quillValue;
    return handleAddUpdate(values);
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
        setQuillValue('');
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='AddEditInsuranceCompany-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='AddEditInsuranceCompany-modal'
        >
          <FaEdit className='pe-1' /> Insurance Company
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={
              ins
                ? ins
                : {
                    active: true,
                  }
            }
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className='d-flex align-items-center gap1Rem'>
                    <Field
                      name='accountnumber'
                      type='text'
                      label='Account Number'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='active'
                      type='checkbox'
                      label='Active'
                      checked={values?.active === true}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='enableEdi'
                      type='checkbox'
                      label='Enable EDI'
                      checked={values?.enableEdi === true}
                      component={FieldBSRenderCheckbox}
                    />
                  </div>
                  <div className={styles.flex2}>
                    <Field
                      name='shortname'
                      type='text'
                      label='Short Name'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='fullname'
                      type='text'
                      label='Full Name'
                      component={FieldBSRenderText}
                    />
                  </div>
                  <div>
                    <Field
                      name='address'
                      type='text'
                      label='Address'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='address2'
                      type='text'
                      label='Address 2'
                      component={FieldBSRenderText}
                    />
                  </div>
                  <div className={styles.flex3}>
                    <Field
                      name='city'
                      type='text'
                      label='City'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='state'
                      label='State'
                      options={states}
                      optionMethod={(options: State[]) =>
                        options.map((o) => (
                          <option key={o.stateAbbr} value={o.stateAbbr}>
                            {o.stateAbbr}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                    <Field
                      name='zip'
                      type='text'
                      label='Zip'
                      validate={zipLength}
                      component={FieldBSRenderText}
                      maxLength={5}
                    />
                  </div>
                  <div className={styles.flex3}>
                    <Field
                      name='bankid'
                      type='text'
                      label='Bank Id'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='compIqclientId'
                      type='text'
                      label='Comp IQ Client Id'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='inscotaxid'
                      type='text'
                      label='Ins. Co. Tax Id'
                      component={FieldBSRenderText}
                    />
                  </div>
                  <div className='d-flex align-items-center gap1Rem'>
                    <Field
                      name='ncmfee'
                      type='text'
                      label='NCM Fee'
                      format={formatNumbers}
                      parse={cleanMoney}
                      validate={centsValidation}
                      component={FieldBSRenderMoney}
                    />
                    <Field
                      name='groupFund'
                      type='checkbox'
                      label='Group Fund'
                      checked={values?.groupFund === true}
                      component={FieldBSRenderCheckbox}
                    />
                  </div>
                  <div className={styles.flex2}>
                    <Field
                      name='wc1approvalEmails'
                      type='text'
                      label='Approval Emails'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='notifyOnNewClaims'
                      type='text'
                      label='Notify on New Claims'
                      component={FieldBSRenderText}
                    />
                  </div>
                  <div className={styles.flex3}>
                    <Field
                      name='defaultNatureOfBusiness'
                      type='text'
                      label='Default Nature of Business'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='adjusterId'
                      label='Adjuster'
                      options={adjusters.filter((a) => a.active)}
                      optionMethod={(options: Adjuster[]) =>
                        options.map((o) => (
                          <option key={o.adjusterId} value={o.adjusterId}>
                            {o.lastName}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                    <Field
                      name='adjusterIdMo'
                      label='MO Adjuster'
                      options={adjusters.filter((a) => a.active)}
                      optionMethod={(options: Adjuster[]) =>
                        options.map((o) => (
                          <option key={o.adjusterId} value={o.adjusterId}>
                            {o.lastName}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                  </div>
                  <div className={styles.flex3}>
                    <Field
                      name='sbwcid'
                      type='text'
                      label='SBWCID'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='medicareRreid'
                      type='text'
                      label='Medicare RREID'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='warningAmount'
                      type='text'
                      label='Warning Amount'
                      format={formatNumbers}
                      parse={cleanMoney}
                      validate={centsValidation}
                      component={FieldBSRenderMoney}
                    />
                  </div>
                  <div className={styles.flex3}>
                    <Field
                      name='secondaryApprovalAmount'
                      type='text'
                      label='Secondary Approval Amount'
                      format={formatNumbers}
                      parse={cleanMoney}
                      validate={centsValidation}
                      component={FieldBSRenderMoney}
                    />
                    <Field
                      name='reconcileChecks'
                      type='checkbox'
                      label='Reconcile Checks'
                      checked={values?.reconcileChecks === true}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='checkPrintDay'
                      label='Day of Week to Print Checks'
                      options={[
                        'Sunday',
                        'Monday',
                        'Tuesday',
                        'Wednesday',
                        'Thursday',
                        'Friday',
                        'Saturday',
                      ]}
                      component={FieldBSRenderSelect}
                    />
                  </div>
                  <Field
                    name='notes'
                    label='Notes'
                    rows={3}
                    component={FieldBSRenderTextArea}
                  />
                  <hr />
                  <h5>Claim Creation Email</h5>

                  <ReactQuill
                    theme='snow'
                    value={quillValue}
                    onChange={setQuillValue}
                    modules={modules}
                    formats={formats}
                  />

                  {ins?.insurcoid && (
                    <NewClaimEmailDocsTable
                      inscoId={ins?.insurcoid}
                      employerId={0}
                    />
                  )}

                  <div className='d-flex justify-content-around align-items-center py-3'>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type='submit' size='sm' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
