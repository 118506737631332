import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaExclamationTriangle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import BillImportApi from '../../Api/BillImportApi';
import ClaimApi from '../../Api/ClaimApi';
import { ClaimSearchRequest } from '../../ApiTypes/ClaimSearchRequest';
import { ClaimSearchResult } from '../../ApiTypes/ClaimSearchResult';
import { ImportException } from '../../ApiTypes/ImportException';
import { UpdateBillImportExceptionRequest } from '../../ApiTypes/UpdateBillImportExceptionRequest';
import PageScaffold from '../PageScaffold/PageScaffold';
import ClaimResultsModal from './ClaimResultsModal';
import ImportExceptionTable from './ImportExceptionTable';
import styles from './index.module.css';

export default function ImportExceptions() {
  const [exceptions, setExceptions] = useState<ImportException[]>([]);
  const [updatedExceptions, setUpdatedExceptions] = useState<ImportException[]>(
    []
  );
  const [searchResults, setSearchResults] = useState<ClaimSearchResult[]>([]);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [selectedException, setSelectedException] =
    useState<ImportException | null>(null);

  useEffect(() => {
    getImportExceptions();
  }, []);

  const getImportExceptions = () => {
    setUpdatedExceptions([]);
    BillImportApi.getImportExceptions()
      .then((res) => {
        if (res.data.length === 0) {
          toast.info('No Import Exceptions Found!');
        } else {
          setExceptions(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get import exceptions');
      });
  };

  const searchFirstAndLast = (exception: ImportException) => {
    setSelectedException(exception);
    const request = {
      firstName: exception.patientFirstName,
      lastName: exception.patientLastName,
    };
    search(request as ClaimSearchRequest);
  };
  const searchLast = (exception: ImportException) => {
    setSelectedException(exception);
    const request = {
      lastName: exception.patientLastName,
    };
    search(request as ClaimSearchRequest);
  };

  const search = (request: ClaimSearchRequest) => {
    return ClaimApi.searchClaims(request)
      .then((res) => {
        setSearchResults(res.data);
        setShowResults(true);
      })
      .catch((err) => {
        setSearchResults([]);
        setSelectedException(null);
        console.log(err);
      });
  };

  const handleSelectedClaim = (claim: ClaimSearchResult) => {
    if (selectedException) {
      const request: UpdateBillImportExceptionRequest = {
        claimNumber: claim.claimNo,
        documentControlNumber: selectedException.documentControlNumber,
        payCode: selectedException.payCode,
      };
      setShowResults(false);
      updateException(request);
    }
  };

  const updateException = (request: UpdateBillImportExceptionRequest) => {
    BillImportApi.updateException(request)
      .then((res) => {
        if (res.data.success) {
          handleUpdateSuccess();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update exception');
        setSelectedException(null);
        setSearchResults([]);
      });
  };

  const processExceptions = () => {
    BillImportApi.processException()
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          getImportExceptions();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdateSuccess = () => {
    if (selectedException) {
      setUpdatedExceptions([...updatedExceptions, selectedException]);
      const exceptionsStill = exceptions.filter(
        (e) =>
          e.documentControlNumber !== selectedException.documentControlNumber &&
          e.payCode !== selectedException.payCode &&
          e.amount !== selectedException.amount
      );
      setExceptions(exceptionsStill);
      setSelectedException(null);
      setSearchResults([]);
    }
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaExclamationTriangle className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Import Exceptions</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center gap1Rem mb-3'>
        <span className='fw-bold'>
          Exceptions Updated: {updatedExceptions.length}
        </span>
        <Button
          type='button'
          variant='primary'
          size='sm'
          disabled={updatedExceptions.length === 0}
          onClick={processExceptions}
        >
          Process Again
        </Button>
      </div>
      <ImportExceptionTable
        data={exceptions}
        searchLast={searchLast}
        searchFirstAndLast={searchFirstAndLast}
      />
      <ClaimResultsModal
        show={showResults}
        setShow={setShowResults}
        searchResults={searchResults}
        handleSelectedClaim={handleSelectedClaim}
      />
    </PageScaffold>
  );
}
