import { createReducer } from '@reduxjs/toolkit';

import {
  clearState,
  setUserFromToken,
  fetchCurrentUser,
} from "../Actions/index"
import { UserModel } from '../ApiTypes/UserModel';

type UserReducerType = {
  userName: string,
  exp: number,
  authenticated: boolean,
  userModel: UserModel | null,
  fetchingUser: boolean,
}

const initialState: UserReducerType = {
  userName: "",
  exp: 0,
  authenticated: false,
  userModel: null,
  fetchingUser: false,
}

const UserReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUserFromToken, (state, action) => {
      return { ...state, userName: action.payload.userName, exp: action.payload.exp, authenticated: true };
    })
    .addCase(fetchCurrentUser.fulfilled, (state, action) => {
      return { ...state, userModel: action.payload, fetchingUser: false }
    })
    .addCase(fetchCurrentUser.pending, (state, action) => {
      return { ...state, fetchingUser: true }
    })
    .addCase(fetchCurrentUser.rejected, (state, action) => {
      return { ...state, fetchingUser: false }
    })
    .addCase(clearState, (state, action) => {
      return initialState;
    })
})

export default UserReducer;