import React, { useState } from 'react';
import styles from './index.module.css';
import { FaEye } from 'react-icons/fa';

const redBorder = {
  borderColor: 'red',
};

type FinalRenderFieldProps = {
  input: any;
  label?: string;
  underInputNote?: string;
  required?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  onChange: (value: any) => void;
  onBlur: (value: any) => void;
  onFocus: () => void;
  onClick: () => void;
  meta: { touched: boolean; error: string; warning: string };
};

export default function PasswordInput({
  input,
  label,
  required,
  disabled,
  readOnly,
  className,
  placeholder,
  underInputNote,
  onChange,
  onBlur,
  onFocus,
  onClick,
  meta: { touched, error, warning },
}: FinalRenderFieldProps) {
  const [passwordFieldAttribute, setPasswordFieldAttribute] = useState(true);
  const togglePasswordField = () => {
    setPasswordFieldAttribute(!passwordFieldAttribute);
  };
  return (
    <div className={`position-relative ${styles.vFieldHeight}`}>
      <label htmlFor={input.name} className='form-label fs-6  m-0'>
        {label}
      </label>
      <div className='input-group mb-3'>
        <input
          {...input}
          type={passwordFieldAttribute ? 'password' : 'text'}
          id={input.name}
          className='form-control form-control-sm'
          placeholder={placeholder || label}
          onChange={(e) => {
            input.onChange(e);
            onChange && onChange(e.target.value);
          }}
          onBlur={(e) => {
            input.onBlur(e);
            onBlur && onBlur(e.target.value);
          }}
          onFocus={(e) => {
            input.onFocus(e);
            onFocus && onFocus();
          }}
          onClick={(e) => {
            onClick && onClick();
          }}
          style={touched && error ? redBorder : {}}
          disabled={disabled}
          readOnly={readOnly}
        />
        <button
          className='btn btn-outline-secondary'
          type='button'
          id='button-addon2'
          onClick={togglePasswordField}
          title='View Password'
        >
          <FaEye />
        </button>
      </div>
      {touched &&
        ((error && (
          <span className={`${styles.fieldError} text-danger`}>{error}</span>
        )) ||
          (warning && (
            <span className={`${styles.fieldWarning} text-warning`}>
              {warning}
            </span>
          )))}
    </div>
  );
}
