import React, { useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { FaEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { displayDateOnly } from '../../Utils';
import IndeterminateCheckbox from '../Common/IndeterminateCheckbox';
import { Button, Spinner } from 'react-bootstrap';
import accounting from 'accounting';
import { toast } from 'react-toastify';
import PaymentsApi from '../../Api/PaymentsApi';
import { SecondaryApprovalModel } from '../../ApiTypes/SecondaryApprovalModel';

export default function SecondaryApprovalTable({
  data,
  fetching,
  getTransactions,
}: {
  getTransactions: () => void;
  fetching: boolean;
  data: SecondaryApprovalModel[];
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const [rowSelection, setRowSelection] = React.useState({});
  const [selectedPayments, setSelectedPayments] = useState<
    SecondaryApprovalModel[]
  >([]);

  useEffect(() => {
    setRowSelection({});
    setColumnFilters([]);
  }, [data]);
  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  const handleRowSelectionChange = () => {
    setSelectedPayments(
      table.getSelectedRowModel().flatRows.map((r) => r.original)
    );
  };

  const tableData = useMemo(() => data, [data]);

  const approveTransactions = () => {
    if (selectedPayments.length === 0) {
      toast.info(
        'Please select the transactions that you would like to approve'
      );
      return;
    }

    const promises = selectedPayments.map((s) => {
      return approve(s.transaction.txId);
    });
    Promise.allSettled(promises)
      .then((res) => {
        res.forEach((r) => {
          if (r.status === 'rejected') {
            toast.error(r.reason);
          } else {
            if (!r.value.data.success) {
              toast.error(r.value.data.message);
            }
          }
          getTransactions();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const approve = (txId: number) => {
    return PaymentsApi.approveSecondaryApprovals(txId);
  };

  const columnData: ColumnDef<SecondaryApprovalModel>[] = [
    {
      header: ({ table }) => (
        <div className='px-3 d-flex gap1Rem'>
          {Object.keys(rowSelection).length}
          <IndeterminateCheckbox
            className='form-check-input'
            {...{
              id: 'totalRowsSelected',
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      id: 'options',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => {
        return (
          <div className='d-flex gap1Rem align-items-center'>
            <IndeterminateCheckbox
              className='form-check-input'
              {...{
                id: row.id,
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />

            <Link to={`/claims/${row.original.transaction.claimNo}/reserves`}>
              <Button
                type='button'
                variant='outline-primary'
                size='sm'
                className='button-icon-text'
              >
                <FaEye /> Claim
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      header: 'Claim Number',
      accessorKey: 'transaction.claimNo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Status',
      accessorFn: (d) => d.transaction.status,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 100,
    },
    {
      header: 'Adjuster',
      accessorFn: (d) => d.transaction.enteredBy,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'SVC From',
      accessorKey: 'transaction.serviceFrom',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        displayDateOnly(d.row.original.transaction.serviceFrom ?? ''),
    },
    {
      header: 'SVC To',
      accessorKey: 'transaction.serviceTo',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.transaction.serviceTo ?? ''),
    },
    {
      header: 'Payee',
      accessorKey: 'transaction.payee',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Memo',
      accessorKey: 'transaction.memo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Tax Id',
      accessorKey: 'transaction.taxId',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Amount',
      accessorFn: (d) => `${d.transaction.amount}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: ({ row }) =>
        accounting.formatMoney(row.original.transaction.amount ?? 0),
    },
    {
      header: 'Pay Code',
      accessorKey: 'transaction.payCode',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Financial Bucket',
      accessorKey: 'transaction.fBucket',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Approved',
      accessorFn: (d) => (d.transaction.pmtApproved === true ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Approved By',
      accessorKey: 'transaction.pmtApprovedBy',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Manager Approved',
      accessorFn: (d) =>
        d.transaction.managerApproved === true ? 'Yes' : 'No',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, [rowSelection]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <>
      <div className='d-flex justify-content-center align-items-center gap1Rem mt-3 pb-3'>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={() => {
            setRowSelection({});
          }}
        >
          Uncheck All
        </Button>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={() => {
            table.getRowModel().rows.forEach((r) => {
              if (r.original.transaction.payee?.toUpperCase() === 'P') {
                r.toggleSelected(true);
              }
            });
          }}
        >
          Check All Provider
        </Button>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={() => {
            table.getRowModel().rows.forEach((r) => {
              if (r.original.transaction.payee?.toUpperCase() === 'C') {
                r.toggleSelected(true);
              }
            });
          }}
        >
          Check All Claimant
        </Button>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={() => {
            approveTransactions();
          }}
        >
          Save
        </Button>
        <Button
          type='button'
          variant='primary'
          size='sm'
          onClick={getTransactions}
        >
          {fetching ? (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            'Refresh'
          )}
        </Button>
      </div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
      />
    </>
  );
}
