import React, { useState, useEffect } from 'react';
import { FaFolderOpen, FaPlusCircle } from 'react-icons/fa';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { ExcessCarrier } from '../../ApiTypes/ExcessCarrier';
import ExcessCarrierApi from '../../Api/ExcessCarrierApi';
import { toast } from 'react-toastify';
import ExcessCarrierTable from './ExcessCarrierTable';
import { Button } from 'react-bootstrap';
import AddEditExcessCarrier from '../AddEcitExcessCarier/AddEditExcessCarrier';

export default function ExcessCarriersMaintenance() {
  const [excessCarriers, setExcessCarriers] = useState<ExcessCarrier[]>([]);
  const [selectedExcessCarrier, setSelectedExcessCarrier] =
    useState<ExcessCarrier | null>(null);
  const [showAddEdit, setShowAddEdit] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    ExcessCarrierApi.list()
      .then((res) => {
        setExcessCarriers(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get carriers');
      });
  };

  const handleEdit = (excessCarrier: ExcessCarrier) => {
    setSelectedExcessCarrier(excessCarrier);
    setShowAddEdit(true);
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaFolderOpen className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Excess Carriers</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center py-3'>
        <Button
          type='button'
          className='button-icon-text'
          variant='primary'
          size='sm'
          onClick={() => {
            setSelectedExcessCarrier(null);
            setShowAddEdit(true);
          }}
        >
          <FaPlusCircle /> Carrier
        </Button>
      </div>
      <ExcessCarrierTable data={excessCarriers} handleEdit={handleEdit} />
      <AddEditExcessCarrier
        show={showAddEdit}
        setShow={setShowAddEdit}
        fetchData={fetchData}
        excessCarrier={selectedExcessCarrier}
      />
    </PageScaffold>
  );
}
