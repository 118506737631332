import React from 'react';
import { Button, Container, Modal, Form, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ChecksApi from '../../Api/ChecksApi';
import { downloadExcel, downloadTxt } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderFilePicker from '../Common/FieldBSRenderFilePicker';
import styles from './index.module.css';

interface QbToPpFormType {
  file: { file: File; name: string };
  action: string;
}

export default function ConvertQbToPpModal({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const onSubmit = (values: QbToPpFormType) => {
    const fd = new FormData();
    if (values.file) {
      const { file } = values.file;
      let fileToUpload = file;
      fileToUpload = new File([file], `${file.name}`, { type: file.type });
      fd.append('file', fileToUpload);
    }
    fd.append('action', values.action);

    return ChecksApi.convertQbFile(fd)
      .then((res) => {
        if (res.data.fileName !== 'Failed') {
          if (res.data.fileName.endsWith('txt')) {
            downloadTxt(res.data.fileName, res.data.file);
          } else if (res.data.fileName.endsWith('csv')) {
            downloadExcel(res.data.fileName, res.data.file);
          }
        } else {
          toast.error('Failed to convert file');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to convert file');
      });
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='ConvertQbToPpModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='ConvertQbToPpModal-modal'>
          <FaCheck className='pe-1' /> Convert Quickbooks to Positive Pay
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              action: 'regions',
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className='d-flex align-items-center gap1Rem mb-3'>
                    <Field
                      name='action'
                      label='Regions'
                      type='radio'
                      value='regions'
                      checked={values?.action === 'regions'}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='action'
                      label='Mountain Valley'
                      type='radio'
                      value='MountainValley'
                      checked={values?.action === 'MountainValley'}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='action'
                      label='Sun Trust (Truist)'
                      type='radio'
                      value='SunTrust'
                      checked={values?.action === 'SunTrust'}
                      component={FieldBSRenderCheckbox}
                    />
                  </div>
                  <p>
                    Checks will be exported to account{' '}
                    {values.action === 'regions' ? '6490170274' : '0001830'}{' '}
                  </p>
                  <Field
                    name='file'
                    type='file'
                    accept='.csv'
                    validate={requiredField}
                    component={FieldBSRenderFilePicker}
                  />
                  <Button type='submit' variant='primary' size='sm'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Positive Pay Files'
                    )}
                  </Button>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
