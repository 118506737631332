import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { UserLog } from '../ApiTypes/UserLog';
import { SearchUserLogsRequest } from '../ApiTypes/SearchUserLogsRequest';

class UserLogApi {
  static getAll() {
    return axios.get<UserLog[]>(`${API_URL}/api/userlogs`, getAuthHeader());
  }
  static searchUserLogs(request: SearchUserLogsRequest) {
    return axios.post<UserLog[]>(
      `${API_URL}/api/userlogs/search`,
      request,
      getAuthHeader()
    );
  }
  static getById(id: number) {
    return axios.get<UserLog>(`${API_URL}/api/userlogs/${id}`, getAuthHeader());
  }
}

export default UserLogApi;
