export let API_URL: string;

if (process.env.NODE_ENV === 'production') {
  if (process.env.REACT_APP_DEPLOY_ENV === 'prod') {
    API_URL = 'https://gasclaimsapi.georgia-admin.com';
  }
  else if (process.env.REACT_APP_DEPLOY_ENV === 'gl') {
    API_URL = 'https://gasglsystemapi.georgia-admin.com';
  } 
  else {
    API_URL = 'https://gasdevclaimsapi.georgia-admin.com';
  }
} 
else {
  API_URL = 'https://localhost:7090';
}

export const getAuthHeader = () => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem('gasToken')}` },
  };
};
