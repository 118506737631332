import React, { useState, useEffect } from 'react';
import { FaColumns } from 'react-icons/fa';
import { useAppSelector } from '../../Reducers/Store';
import { Form as RFForm, Field } from 'react-final-form';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { Button, Form, Spinner } from 'react-bootstrap';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import WcPanelApi from '../../Api/WcPanelApi';
import { toast } from 'react-toastify';
import { WCPanelLocation } from '../../ApiTypes/WcPanelLocation';
import PanelExceptionTable from './PanelExceptionTable';

export default function PanelExceptions() {
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );

  const [years, setYears] = useState<number[]>([]);
  const [exceptions, setExceptions] = useState<WCPanelLocation[]>([]);

  useEffect(() => {
    loadYears();
  }, []);

  const loadYears = () => {
    var ys: number[] = [];
    var thisYear = new Date().getFullYear();
    for (let i = 0; i <= 15; i++) {
      ys.push(thisYear - i);
    }
    setYears(ys);
  };

  const onSearch = (values: { insurCoId: number; treatyYear: number }) => {
    return WcPanelApi.getPanelExceptions(
      values.insurCoId ?? 0,
      values.treatyYear
    )
      .then((res) => {
        setExceptions(res.data);
      })
      .catch((err) => {
        console.log(err);
        setExceptions([]);
        toast.error('Failed to get exceptions');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaColumns className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>WC Exceptions</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-around align-items-center'>
        <RFForm
          onSubmit={onSearch}
          initialValues={{
            treatyYear: new Date().getFullYear(),
          }}
          render={({ handleSubmit, form, values, submitting }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-center align-items-center gap1Rem'>
                  <Field
                    name='insurCoId'
                    label='Account'
                    options={insuranceCompaniesForUser}
                    optionMethod={(options: InsuranceCompany[]) =>
                      options.map((o) => (
                        <option key={o.insurcoid} value={o.insurcoid}>
                          {`${o.accountnumber} - ${o.shortname}`}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='treatyYear'
                    label='Treaty Year'
                    options={years}
                    optionMethod={(options: number[]) =>
                      options.map((o) => (
                        <option key={o} value={o}>
                          {o}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />

                  <Button type='submit' variant='primary' size='sm'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Search'
                    )}
                  </Button>
                  <Button
                    type='button'
                    variant='secondary'
                    size='sm'
                    onClick={() => {
                      form.reset();
                    }}
                  >
                    Clear
                  </Button>
                </div>
              </Form>
            );
          }}
        />
      </div>
      <PanelExceptionTable data={exceptions} />
    </PageScaffold>
  );
}
