import React, { useState, useEffect } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaNotesMedical } from 'react-icons/fa';
import { toast } from 'react-toastify';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { ClaimNoteType } from '../../ApiTypes/ClaimNoteType';
import ClaimNoteTypesApi from '../../Api/ClaimNoteTypeApi';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import ClaimNoteTypeTable from './ClaimNoteTypeTable';
import { FormApi } from 'final-form';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';

export default function NoteTypesMaintenance() {
  const [types, setTypes] = useState<ClaimNoteType[]>([]);

  let formInstance: FormApi<ClaimNoteType, Partial<ClaimNoteType>>;

  useEffect(() => {
    getClaimNoteTypes();
  }, []);

  const getClaimNoteTypes = () => {
    ClaimNoteTypesApi.getClaimNoteTypes()
      .then((res) => {
        setTypes(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to note types');
      });
  };

  const handleDelete = (t: ClaimNoteType) => {
    t.deleted = true;
    updateClaimNoteType(t);
  };

  const updateClaimNoteType = (t: ClaimNoteType) => {
    ClaimNoteTypesApi.updateClaimNoteType(t)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          getClaimNoteTypes();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update');
      });
  };
  const createClaimNoteType = (t: ClaimNoteType) => {
    ClaimNoteTypesApi.createClaimNoteType(t)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          getClaimNoteTypes();
          formInstance.reset();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update');
      });
  };

  const onSubmit = (values: ClaimNoteType) => {
    const t: ClaimNoteType = {
      id: 0,
      name: values.name,
      deleted: false,
      claimTypeId: null,
      isDiary: values.isDiary
    };
    return createClaimNoteType(t);
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaNotesMedical className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Manage Note Types</h1>
          </div>
        </div>
      </div>
      <RFForm
        onSubmit={onSubmit}
        initialValues={{}}
        render={({ handleSubmit, form, values, submitting }) => {
          formInstance = form;
          return (
            <Form onSubmit={handleSubmit}>
              <div className='d-flex justify-content-center align-items-center gap1Rem'>
                <Field
                  name='name'
                  type='text'
                  label='Name'
                  component={FieldBSRenderText}
                />
                <Field 
                  name='isDiary'
                  type='check'
                  label='Is Diary'
                  component={FieldBSRenderCheckbox}
                />
                <Button type='submit' variant='primary' size='sm'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Create'
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      />
      <ClaimNoteTypeTable data={types} handleDelete={handleDelete} />
    </PageScaffold>
  );
}
