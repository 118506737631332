import React, { useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  RowSelectionState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { ScanDocsPending } from '../../ApiTypes/ScanDocsPending';
import { displayDateOnly } from '../../Utils';
import IndeterminateCheckbox from '../Common/IndeterminateCheckbox';
import { Button } from 'react-bootstrap';
import { FaEnvelope, FaTrash } from 'react-icons/fa';

export default function PendingDocTable({
  data,
  setSelectedDoc,
  rowSelection,
  setRowSelection,
  setShowEmail,
  setShowDeleteConfirm,
}: {
  data: ScanDocsPending[];
  setSelectedDoc: (d: ScanDocsPending | null) => void;
  rowSelection: RowSelectionState;
  setRowSelection: (obj: object) => void;
  setShowEmail: (show: boolean) => void;
  setShowDeleteConfirm: (show: boolean) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  const handleRowSelectionChange = () => {
    setSelectedDoc(
      table.getSelectedRowModel().flatRows.map((r) => r.original)[0]
    );
  };

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<ScanDocsPending>[] = [
    {
      header: '',
      id: 'options',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 50,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center gap1Rem align-items-center'>
            <IndeterminateCheckbox
              className='form-check-input'
              {...{
                id: row.id,
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
            {row.getIsSelected() && (
              <Button
                type='button'
                variant='outline-primary'
                size='sm'
                style={{ width: 'auto' }}
                onClick={() => setShowEmail(true)}
              >
                <FaEnvelope />
              </Button>
            )}
            {row.getIsSelected() && (
              <Button
                type='button'
                variant='outline-danger'
                size='sm'
                style={{ width: 'auto' }}
                onClick={() => setShowDeleteConfirm(true)}
              >
                <FaTrash />
              </Button>
            )}
          </div>
        );
      },
    },
    {
      header: 'Doc Name',
      accessorKey: 'filename',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date',
      accessorKey: 'date',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.date ?? ''),
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
