import React, { useState, useEffect } from 'react';
import { Field, Form as RFFForm } from 'react-final-form';
import { Form, Button, Spinner } from 'react-bootstrap';
import { FaPlusCircle } from 'react-icons/fa';
import PhysicianApi from '../../Api/PhysicianApi';
import { Physician } from '../../ApiTypes/Physician';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { DoctorsOffice } from '../../ApiTypes/DoctorsOffice';
import DoctorOfficeApi from '../../Api/DoctorOfficeApi';
import { DoctorLocation } from '../../ApiTypes/DoctorLocation';

import styles from './index.module.css';
import { requiredField } from '../../Utils/FieldValidation';
import AddUpdateDoctorOffice from '../AddUpdateDoctorOffice/AddUpdateDoctorOffice';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import { toast } from 'react-toastify';
import { FormApi } from 'final-form';
import DoctorLocationApi from '../../Api/DoctorLocationApi';

export default function ViewAddPhysicianForm({
  show,
  setShow,
  physicianId,
  setPhysicianId,
  submitAddView,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  physicianId?: number;
  setPhysicianId: (id: number) => void;
  submitAddView: (values: Physician) => Promise<void>;
}) {
  const [physician, setPhysician] = useState<Physician | null>(null);
  const [doctorOffices, setDoctorOffices] = useState<DoctorsOffice[]>([]);
  const [doctorLocations, setDoctorLocations] = useState<DoctorLocation[]>([]);
  const [showAddEditDoctorOffice, setShowAddEditDoctorOffice] =
    useState<boolean>(false);
  const [showNewOffice, setShowNewOffice] = useState<boolean>(false);

  const containerWidth = 752;

  let formInstance: FormApi<Physician, Partial<Physician>>;

  useEffect(() => {
    getPhysicianById();
    if (doctorOffices.length < 1) {
      getDoctorOffices();
    }
  }, [physicianId]);

  const getPhysicianById = () => {
    if (physicianId && physicianId > 0) {
      PhysicianApi.getPhysicianById(physicianId)
        .then((res) => {
          setPhysician(res.data);
          getDoctorLocationsForPhysician(res.data.doctorId ?? 0);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setPhysician(null);
      setDoctorLocations([]);
    }
  };

  const getDoctorOffices = () => {
    return DoctorOfficeApi.getAll()
      .then((res) => {
        setDoctorOffices(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDoctorLocationsForPhysician = (id: number) => {
    DoctorLocationApi.getDoctorLocationsByDoctorId(id)
      .then((res) => {
        setDoctorLocations(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = (values: Physician) => {
    return submitAddView(values);
  };

  const submitOffice = (values: DoctorsOffice) => {
    if (values.id > 0) {
      return updateOffice(values);
    } else {
      return createOffice(values);
    }
  };

  const createOffice = (values: DoctorsOffice) => {
    return DoctorOfficeApi.createDoctorsOffice(values)
      .then((res) => {
        if (res.data.success) {
          setShowNewOffice(false);
          getDoctorOffices().then((r) => {
            formInstance.change('doctorId', +res.data.affectedEntityIdentifier);
            getDoctorLocationsForPhysician(+res.data.affectedEntityIdentifier);
          });
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create doctors office');
      });
  };
  const updateOffice = (values: DoctorsOffice) => {
    return DoctorOfficeApi.updateDoctorsOffice(values)
      .then((res) => {
        if (res.data.success) {
          setShowNewOffice(false);
          getDoctorOffices().then((r) => {
            formInstance.change('doctorId', +res.data.affectedEntityIdentifier);
            getDoctorLocationsForPhysician(+res.data.affectedEntityIdentifier);
          });
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update doctors office');
      });
  };

  return (
    <div
      style={{
        width: `${containerWidth}px`,
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          width: '1504px',
          height: '100%',
          display: 'grid',
          gridTemplateColumns: '752px 752px',
          position: 'relative',
          left: `${showNewOffice ? -containerWidth : 0}px`,
        }}
      >
        <div>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={physician ?? { id: 0 }}
            render={({ handleSubmit, form, values, submitting }) => {
              formInstance = form;
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='firstName'
                    label='First Name'
                    type='text'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='lastName'
                    label='Last Name'
                    type='text'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='title'
                    label='Title'
                    type='text'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='phoneNumber'
                    label='Phone'
                    type='text'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='faxNumber'
                    label='Fax'
                    type='text'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='emailAddress'
                    label='Email'
                    type='text'
                    component={FieldBSRenderText}
                  />
                  <div className={`${styles.officeGrid}`}>
                    <Field
                      name='doctorId'
                      label='Office'
                      options={doctorOffices}
                      onChange={(value: string) => {
                        if (value) {
                          getDoctorLocationsForPhysician(+value);
                        }
                      }}
                      optionMethod={(options: DoctorsOffice[]) =>
                        options.map((o) => (
                          <option key={o.id} value={o.id}>
                            {o.officeName}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                    <Button
                      type='button'
                      variant='outline-primary'
                      size='sm'
                      onClick={() => {
                        setShowNewOffice(true);
                      }}
                      className='button-icon-text'
                    >
                      <FaPlusCircle /> New
                    </Button>
                  </div>
                  {doctorLocations.length > 0 && (
                    <div>
                      <p className='mb-0'>{doctorLocations[0].address}</p>
                      {doctorLocations[0].address2 && (
                        <p className='mb-0'>{doctorLocations[0].address2}</p>
                      )}
                      <p className='mb-0'>
                        {doctorLocations[0].city}, {doctorLocations[0].state}{' '}
                        {doctorLocations[0].zip}
                      </p>
                    </div>
                  )}
                  <div className={`${styles.buttonDiv} my-5`}>
                    <Button type='submit' size='lg' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                    <Button
                      type='button'
                      size='lg'
                      variant='secondary'
                      onClick={() => {
                        setPhysicianId(0);
                        form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </div>
        <div>
          <RFFForm
            onSubmit={submitOffice}
            initialValues={{
              id: 0,
              doctorLocations: null,
              doctorNames: null,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <h4 className='button-icon-text'>
                    {' '}
                    <FaPlusCircle /> Add Facility
                  </h4>
                  <Field
                    name='officeName'
                    label='Facility Name'
                    type='text'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='comments'
                    label='Notes'
                    rows={3}
                    component={FieldBSRenderTextArea}
                  />
                  <div className={`${styles.buttonDiv} my-5`}>
                    <Button type='submit' size='lg' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                    <Button
                      type='button'
                      size='lg'
                      variant='secondary'
                      onClick={() => {
                        form.reset();
                        setShowNewOffice(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </div>
      </div>
      <AddUpdateDoctorOffice
        show={showAddEditDoctorOffice}
        setShow={setShowAddEditDoctorOffice}
        id={0}
      />
    </div>
  );
}
