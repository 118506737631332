import React from 'react';

export default function ErrorMessage({ messages }: { messages: string[] }) {
  if (!messages || messages.length === 0) {
    return <p>Failed to get report</p>;
  }
  return (
    <div>
      {messages.map((t, i) => (
        <p key={i}>{t}</p>
      ))}
    </div>
  );
}
