import accounting from "accounting";
import { ClaimFinancialHistoryDetailObj } from "../../ApiTypes/HistoryObjects";
import { displayDateOnly } from "../../Utils";

export const claimFinancialHistoryExportHandler = (
  data: ClaimFinancialHistoryDetailObj[],
    ) => {
  const columns = [
    'As Of Date',
    'Med Reserves',
    'Ind Reserves',
    'Exp Reserves',
    'Leg Reserves',
    'Total Reserves',
    'Med Paid',
    'Ind Paid',
    'Exp Paid',
    'Leg Paid',
    'Total Paid',
    'Med Incurred',
    'Ind Incurred',
    'Exp Incurred',
    'Leg Incurred',
    'Total Incurred',
  ]

  const rows: any[][] = [];

   data.forEach(t => {
    const tRow =  [
      displayDateOnly(t.asOfDate ?? ""),
      accounting.formatMoney(t.medReserves ?? 0),
      accounting.formatMoney(t.indReserves ?? 0),
      accounting.formatMoney(t.expReserves ?? 0),
      accounting.formatMoney(t.legReserves ?? 0),
      accounting.formatMoney(t.totalReserves ?? 0),

      accounting.formatMoney(t.medPaid ?? 0),
      accounting.formatMoney(t.indPaid ?? 0),
      accounting.formatMoney(t.expPaid ?? 0),
      accounting.formatMoney(t.legPaid ?? 0),
      accounting.formatMoney(t.totalPaid ?? 0),

      accounting.formatMoney(t.medIncurred ?? 0),
      accounting.formatMoney(t.indIncurred ?? 0),
      accounting.formatMoney(t.expIncurred ?? 0),
      accounting.formatMoney(t.legIncurred ?? 0),
      accounting.formatMoney(t.totalIncurred ?? 0),
      
    ]

    rows.push(tRow)
  })

  return {
    columns,
    rows,
    fileName: "",
  }
}


