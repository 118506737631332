import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { ClaimReserveBuckets } from '../../../ApiTypes/ClaimReserveBuckets';
import { ClaimPaymentTotals } from '../../../ApiTypes/ClaimPaymentTotals';
import accounting from 'accounting';
import TableSpinner from '../Documents/TableSpinner';

export default function ReservesTable({
  claimReserveBuckets,
  claimPaymentTotals,
  getReserveBucketsForClaim,
  getClaimPaymentTotals,
  medicalStatuteOfLimitations400Week,
  loading,
}: {
  claimReserveBuckets: ClaimReserveBuckets[];
  claimPaymentTotals: ClaimPaymentTotals | null;
  getReserveBucketsForClaim: () => void;
  getClaimPaymentTotals: () => void;
  medicalStatuteOfLimitations400Week: boolean;
  loading: boolean;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => claimReserveBuckets, [claimReserveBuckets]);

  const elem = document.querySelector('#reserve_bucket_table tbody');

  if (elem && medicalStatuteOfLimitations400Week) {
    const tr = elem.children[0];
    if (tr && tr.children) {
      for (let i = 0; i < tr.children.length; i++) {
        const element = tr.children[i];
        element.classList.add('text-bg-danger');
      }
    }
  }

  if (elem && !medicalStatuteOfLimitations400Week) {
    const tr = elem.children[0];

    if (tr && tr.children) {
      for (let i = 0; i < tr.children.length; i++) {
        const element = tr.children[i];
        element.classList.remove('text-bg-danger');
      }
    }
  }

  const columnData: ColumnDef<ClaimReserveBuckets>[] = [
    {
      header: 'Classification',
      accessorFn: (d) => d.description,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
      footer: 'Totals',
    },
    {
      header: 'Paid',
      accessorFn: (d) => `${d.paid}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
      cell: (d) => accounting.formatMoney(d.getValue() as number),
      footer: (p) => {
        const paid = tableData.reduce((acc, curr) => {
          return curr.description === 'Recovery'
            ? (acc -= curr.paid)
            : (acc += curr.paid);
        }, 0);
        return accounting.formatMoney(paid);
      },
    },
    {
      header: 'Reserves',
      accessorFn: (d) => `${d.reserves}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
      cell: (d) => accounting.formatMoney(d.getValue() as number),
      footer: (p) => {
        const reserves = tableData.reduce((acc, curr) => {
          return (acc += curr.reserves);
        }, 0);
        return accounting.formatMoney(reserves);
      },
    },
    {
      header: 'Total Incurred',
      accessorFn: (d) => `${d.totalIncurred}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
      cell: (d) => accounting.formatMoney(d.getValue() as number),
      footer: (p) => {
        const totalIncurred = tableData.reduce((acc, curr) => {
          return (acc += curr.totalIncurred);
        }, 0);
        return accounting.formatMoney(totalIncurred);
      },
    },
  ];

  const columns = useMemo(() => columnData, [columnData, tableData]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div id='reserve_bucket_table'>
      {loading ? (
        <TableSpinner />
      ) : (
        <PaginatedTable
          table={table}
          columnResizeMode='onChange'
          showFilters={true}
          showPagination={false}
          showFooter={true}
        />
      )}
    </div>
  );
}
