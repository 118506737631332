import React, { useState, useEffect } from 'react';
import { Container, Modal, Spinner } from 'react-bootstrap';
import { FaDollarSign } from 'react-icons/fa';
import { Form as RFFForm } from 'react-final-form';
import { Button, Form } from 'react-bootstrap';
import { ClaimReserveBuckets } from '../../../ApiTypes/ClaimReserveBuckets';
import { Claim } from '../../../ApiTypes/Claim';
import styles from './index.module.css';

export default function ChangeReserveBucketModalCustom({
  show,
  setShow,
  reserveBuckets,
  updateReserves,
  claim,
  userName,
}: {
  show: boolean;
  reserveBuckets: ClaimReserveBuckets[] | null;
  claim: Claim | null;
  userName: string;
  setShow: () => void;
  updateReserves: (
    data: ClaimReserveBuckets[],
    reason: string
  ) => Promise<void>;
}) {
  const [inputFields, setInputFields] = useState<ClaimReserveBuckets[]>([]);
  const [inputReason, setInputReason] = useState<string>('');

  useEffect(() => {
    setInitialValues();
  }, [reserveBuckets]);

  const setInitialValues = () => {
    if ((reserveBuckets?.length ?? 0) > 0) {
      setInputFields(reserveBuckets!);
      setInputReason('');
    }
  };

  const onSubmitNew = () => {
    return updateReserves(inputFields, inputReason);
  };

  const handleFormChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let data = [...inputFields];
    data[index].updateAmount = Number(event.target.value);
    data[index].totalIncurred =
      data[index].paid + data[index].reserves + Number(event.target.value);

    setInputFields(data);
  };
  const handleReasonChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setInputReason(event.target.value);
  };

  return (
    <Modal
      centered
      show={show}
      // size='lg'
      onHide={() => {
        setShow();
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='Change-Reserves-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Change-Reserves-modal'>
          <FaDollarSign className='pe-1' /> Change Reserves
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={onSubmitNew}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  {inputFields!.map((input, index) => {
                    return (
                      <div
                        key={index}
                        className='d-flex justify-content-center w-100'
                      >
                        <div className='p-2 mx-auto'>
                          <label className='form-label'>Classification</label>
                          <input
                            name='description'
                            value={input.description}
                            className='form-control form-control-sm'
                            readOnly
                            disabled
                          />
                        </div>
                        <div className='p-2 mx-auto'>
                          <label className='form-label'>Paid</label>
                          <div className={styles.inputicon}>
                            <input
                              name='paid'
                              value={input.paid}
                              className='form-control form-control-sm'
                              readOnly
                              disabled
                            />
                            <i>$</i>
                          </div>
                        </div>
                        <div className='p-2 mx-auto'>
                          <label className='form-label'>Reserves</label>
                          <div className={styles.inputicon}>
                            <input
                              name='reserves'
                              value={input.reserves}
                              className='form-control form-control-sm'
                              readOnly
                              disabled
                            />
                            <i>$</i>
                          </div>
                        </div>
                        <div className='p-2 mx-auto'>
                          <label className='form-label'>
                            Change in Reserve
                          </label>
                          <div className={styles.inputicon}>
                            <input
                              name='updateAmount'
                              placeholder='0.00'
                              type='number'
                              onWheel={(e) =>
                                (e.target as HTMLInputElement).blur()
                              }
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                              className='form-control form-control-sm'
                            />
                            <i>$</i>
                          </div>
                        </div>
                        <div className='p-2 mx-auto'>
                          <label className='form-label'>Total Incurred</label>
                          <div className={styles.inputicon}>
                            <input
                              name='totalIncurred'
                              value={input.totalIncurred}
                              className='form-control form-control-sm'
                              readOnly
                              disabled
                            />
                            <i>$</i>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className='p-2 mx-auto'>
                    <label className='form-label'>Reserve Update Reason</label>
                    <textarea
                      name='reason'
                      placeholder='Reserve Update Reason'
                      value={inputReason}
                      onChange={(event) => handleReasonChange(event)}
                      className='form-control form-control-sm'
                      rows={3}
                      required
                      onInvalid={(event) =>
                        event.currentTarget.setCustomValidity(
                          'Please enter a Reason'
                        )
                      }
                      onInput={(event) =>
                        event.currentTarget.setCustomValidity('')
                      }
                    />
                  </div>
                  <div className='d-flex justify-content-center'>
                    <Button type='submit' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Update'
                      )}
                    </Button>
                    <Button
                      type='button'
                      onClick={() => {
                        form.reset();
                        setShow();
                      }}
                      className='ms-2'
                      variant='secondary'
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
