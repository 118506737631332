import React, { useState, useEffect } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';
import AttorneysTab from './AttorneysTab';
import HearingAddEdit from './HearingAddEdit';
import HearingsTab from './HearingsTab';
import SettlementAddEdit from './SettlementAddEdit';
import SettlementTab from './SettlementTab';

export default function LegalTab({ claimType }: { claimType: number }) {
  let location = useLocation();
  let { claimNumber } = useParams();
  const [key, setKey] = useState<string>('attorneys');

  useEffect(() => {
    locationToEventKey();
  }, [location]);

  const locationToEventKey = () => {
    const split = location.pathname.split('/');
    if (split.length === 4 && split[3] === 'legal') {
      setKey('attorneys');
    } else {
      setKey(split[4]);
    }
  };
  return (
    <div className='w-100 h-100'>
      <Tab.Container
        defaultActiveKey='attorneys'
        activeKey={key}
        id='legalTabs'
        onSelect={(k) => setKey(k ?? '')}
      >
        <Nav variant='tabs' className='mb-3'>
          <Nav.Item>
            <NavLink to='attorneys'>
              <Nav.Link as='button' eventKey='attorneys'>
                Attorneys
              </Nav.Link>
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to='settlements'>
              <Nav.Link as='button' eventKey='settlements'>
                Settlement Authorities
              </Nav.Link>
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to='hearings'>
              <Nav.Link as='button' eventKey='hearings'>
                Hearings
              </Nav.Link>
            </NavLink>
          </Nav.Item>
        </Nav>
        <Routes>
          <Route path='attorneys' element={<AttorneysTab />} />
          <Route path='settlements' element={<SettlementTab />} />
          {/* <Route
            path='settlements/:settlementId'
            element={<SettlementAddEdit />}
          /> */}
          <Route path='hearings' element={<HearingsTab />} />
          {/* <Route path='hearings/:hearingId' element={<HearingAddEdit />} /> */}
          {/* <Route
            path='hearings/:hearingId/reset'
            element={<HearingAddEdit />}
          /> */}
        </Routes>
      </Tab.Container>
    </div>
  );
}
