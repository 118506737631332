import React, { useState, useEffect } from 'react';
import { FaFolderOpen, FaPlusCircle, FaUsers } from 'react-icons/fa';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import UserApi from '../../Api/UserApi';
import { VwUser } from '../../ApiTypes/VwUser';
import UserTable from './UserTable';
import { Link } from 'react-router-dom';

export default function UserMaintenance() {
  const [users, setUsers] = useState<VwUser[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    UserApi.getUsersForMaintenance(false)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get users');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaUsers className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Users</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center py-3'>
        <Link to='/users/new'>
          <Button
            type='button'
            className='button-icon-text'
            variant='primary'
            size='sm'
          >
            <FaPlusCircle /> User
          </Button>
        </Link>
      </div>
      <UserTable data={users} />
    </PageScaffold>
  );
}
