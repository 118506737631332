import React, { useState, useEffect } from 'react';
import { Form as RFForm } from 'react-final-form';
import { FaUser } from 'react-icons/fa';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { toast } from 'react-toastify';
import { Button, Container, Form, Nav, Spinner, Tab } from 'react-bootstrap';
import UserApi from '../../Api/UserApi';
import {
  Link,
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { EntityUser } from '../../ApiTypes/EntityUser';
import { Adjuster } from '../../ApiTypes/Adjuster';
import AdjustersApi from '../../Api/AdjustersApi';
import { SiteFunction } from '../../ApiTypes/SiteFunction';
import { UserSiteFunction } from '../../ApiTypes/UserSiteFunction';
import SiteFunctionApi from '../../Api/SiteFunctionApi';
import UserSiteFunctionApi from '../../Api/UserSiteFunctionApi';
import { SiteFunctionDefaultGroup } from '../../ApiTypes/SiteFunctionDefaultGroup';
import SiteFunctionDefaultGroupApi from '../../Api/SiteFunctionDefaultGroupApi';
import { useAppSelector } from '../../Reducers/Store';
import Navigation from '../CompIqEomFees/Navigation';
import { FormApi } from 'final-form';
import UserForm from './UserForm';
import { AddEditUserFormType } from './AddEditUserFormType';
import FunctionsForm from './FunctionsForm';
import UserAccessForm from './UserAccessForm';
import { AddUpdateUserRequest } from '../../ApiTypes/AddUpdateUserRequest';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import InsuranceCompanyApi from '../../Api/InsuranceCompanyApi';
import ErrorBoundary from '../Common/ErrorBoundary';

export default function AddEditUser() {
  const { userId } = useParams();
  const navigate = useNavigate();

  const { insuranceCompanies } = useAppSelector((state) => state.reference);

  const [insuranceCompaniesForUser, setInsuranceCompaniesForUser] = useState<
    InsuranceCompany[]
  >([]);
  const [user, setUser] = useState<EntityUser | null>(null);
  const [adjuster, setAdjuster] = useState<Adjuster | null>(null);
  const [isAdjuster, setIsAdjuster] = useState<boolean>(false);
  const [userSiteFunctions, setUserSiteFunctions] = useState<
    UserSiteFunction[]
  >([]);

  const [siteFunctions, setSiteFunctions] = useState<SiteFunction[]>([]);
  const [siteFunctionDefaultGroups, setSiteFunctionDefaultGroups] = useState<
    SiteFunctionDefaultGroup[]
  >([]);

  const [formValues, setFormValues] = useState<AddEditUserFormType>();

  let formInstance: FormApi<AddEditUserFormType, Partial<AddEditUserFormType>>;

  const [key, setKey] = useState<string>('functions');

  useEffect(() => {
    getSiteFunctions();
    getSiteFunctionDefaultGroups();
    fetchUser();
    getInsuranceCompaniesForUser();
    getAdjuster();
    getUserSiteFunctions();
  }, [userId]);

  useEffect(() => {
    handleSetInitialValues();
  }, [
    user,
    isAdjuster,
    adjuster,
    userSiteFunctions,
    insuranceCompaniesForUser,
  ]);

  const fetchAll = () => {
    getSiteFunctions();
    getSiteFunctionDefaultGroups();
    fetchUser();
    getInsuranceCompaniesForUser();
    getAdjuster();
    getUserSiteFunctions();
  };

  let location = useLocation();
  useEffect(() => {
    locationToEventKey();
  }, [location]);

  const locationToEventKey = () => {
    const split = location.pathname.split('/');
    if (split.length === 3 || split[3] === 'functions') {
      setKey('functions');
    } else {
      setKey(split[3]);
    }
  };

  const handleSetInitialValues = () => {
    const values = user
      ? {
          ...user,
          isAdjuster: isAdjuster,
          password: '',
          maximumReserveApproval: adjuster?.maximumReserveApproval ?? null,
          siteFunctionIds: userSiteFunctions
            .filter((x) => x.enabled)
            .map(
              // may have to filter for enabled
              (x) => `${x.siteFunctionId}`
            ),
          insurCoIds: insuranceCompaniesForUser?.map((x) => `${x.insurcoid}`),
        }
      : {
          siteFunctionIds: [],
          insurCoIds: [],
          password: '',
          maximumReserveApproval: null,
          isAdjuster: false,
        };

    setFormValues(values as AddEditUserFormType);
  };

  const fetchUser = () => {
    if (userId && userId !== 'new') {
      UserApi.getById(userId)
        .then((res) => {
          setUser(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get user');
        });
    }
  };

  const getInsuranceCompaniesForUser = () => {
    if (userId && userId !== 'new') {
      InsuranceCompanyApi.getForAnotherUser(userId)
        .then((res) => {
          setInsuranceCompaniesForUser(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get insurance companies for user');
        });
    }
  };

  const getAdjuster = () => {
    if (userId && userId !== 'new') {
      AdjustersApi.getAdjusterByUserId(userId)
        .then((res) => {
          if (res.data) {
            setAdjuster(res.data);
            setIsAdjuster(res.data.active === true);
          } else {
            setAdjuster(null);
            setIsAdjuster(false);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get adjuster');
        });
    }
  };

  const getSiteFunctions = () => {
    SiteFunctionApi.list()
      .then((res) => {
        setSiteFunctions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSiteFunctionDefaultGroups = () => {
    SiteFunctionDefaultGroupApi.list()
      .then((res) => {
        setSiteFunctionDefaultGroups(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserSiteFunctions = () => {
    if (userId && userId !== 'new') {
      UserSiteFunctionApi.getForUser(userId)
        .then((res) => {
          setUserSiteFunctions(res.data);
        })
        .catch((err) => {
          console.log(err);
          // toast.error('Failed to get adjuster');
        });
    }
  };

  const handleDefaultAccessChange = (id: number) => {
    const grp = siteFunctionDefaultGroups.find((x) => x.id === +id);
    if (grp) {
      const defaultFunctionIds = grp.siteFunctionDefaultGroupFunctions.map(
        (x) => `${x.siteFunctionId}`
      );
      formInstance.change('siteFunctionIds', defaultFunctionIds);
    } else {
    }
  };

  const selectAll = () => {
    const all = insuranceCompanies
      .filter((x) => x.active)
      .map((x) => `${x.insurcoid}`);

    formInstance.change('insurCoIds', all);
  };

  const onSubmit = (values: AddEditUserFormType) => {
    const copy = { ...values };

    var regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{1,30}$/gm;

    if (copy.password) {
      if (!regex.test(copy.password)) {
        toast.error(
          'Password must include 1 uppercase character, 1 number, and 1 special character ( !@#$%^&* ).'
        );
        return;
      }
    }

    const password = copy.password;
    const adjusterChecked = copy.isAdjuster == true;
    const maximumReserveApproval = copy.maximumReserveApproval ?? 0;

    const siteFunctionIds = copy.siteFunctionIds.map((x) => +x);
    const insurCoIds = copy.insurCoIds.map((x) => +x);

    const formUser: EntityUser = {
      userId: copy.userId ?? copy.adAccount,
      userName: copy.userName,
      adAccount: copy.adAccount,
      active: copy.active === true, //
      initials: copy.initials,
      spellCheckAsYouGo: null,
      emailAddress: copy.emailAddress,
      autoShowDocs: null,
      fontSize: null,
      phoneNumber: copy.phoneNumber,
      fax: copy.fax,
      isClaimsAssistant: copy.isClaimsAssistant === true,
      isNcm: copy.isNcm === true,
      isAdmin: copy.isAdmin === true,
      isSupervisor: copy.isSupervisor === true,
      currentCompanyLookingAt: null,
      textCode: null,
      textCodeExpiration: null,
      lastLoggedIn: null,
      textCodeConfirmed: null,
      textCodeConfirmedDate: null,
      dashboardPreference: null,
      workPhone: copy.workPhone,
    };

    const toSend: AddUpdateUserRequest = {
      user: formUser,
      isNew: userId === 'new',
      siteFunctionIds,
      insurCoIds,
      password: password,
      adjusterChecked: adjusterChecked,
      maximumReserveApproval: maximumReserveApproval,
    };

    return UserApi.addUpdateUserWithSiteFunctionIdsAndAccess(toSend)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          if (userId === 'new') {
            navigate(`/users/${res.data.affectedEntityIdentifier}`);
          } else {
            fetchAll();
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to process user');
      });
  };

  return (
    <PageScaffold>
      <Container fluid>
        <div className={`${styles.pageTop} bg-light mb-3`}>
          <div className='ps-3 d-flex'>
            <FaUser className='fs-1 text-primary mt-1' />
            <div className='ms-3'>
              <h1>{user ? user.userName : 'New User'}</h1>
            </div>
          </div>
        </div>
        <Button
          className='mb-5'
          variant='secondary'
          type='button'
          onClick={() => {
            // dispatch(clearSelectedClaim());
            navigate('/users');
            // form.restart();
          }}
        >
          Back
        </Button>
        <RFForm
          onSubmit={onSubmit}
          initialValues={formValues}
          validate={(values) => {
            var regex =
              /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{1,30}$/gm;

            const errors: {
              [Property in keyof AddEditUserFormType]?: string;
            } = {};

            if (values.password && !regex.test(values.password)) {
              errors.password =
                'Password must include 1 uppercase character, 1 number, and 1 special character ( !@#$%^&* ).';
            }
            return errors;
          }}
          render={({ handleSubmit, form, values, submitting }) => {
            formInstance = form;
            return (
              <Form onSubmit={handleSubmit}>
                {/* {page === 1 && ( */}
                <Container fluid>
                  <UserForm
                    form={form}
                    values={values}
                    isNew={userId === 'new'}
                  />
                  <div className={styles.saveCancelBtnGrp}>
                    {userId !== 'new' && (
                      <div className='d-flex justify-content-center align-items-center mb-2'>
                        {/* <div> */}
                        <Link to={`/useraccess/${userId}`} className='px-2'>
                          <Button
                            type='button'
                            variant='outline-primary'
                            size='sm'
                          >
                            Edit User Access
                          </Button>
                        </Link>
                        <Link
                          to={`/claimtypeaccess/${userId}`}
                          className='px-2'
                        >
                          <Button
                            type='button'
                            variant='outline-primary'
                            size='sm'
                          >
                            Edit Claim Type Access
                          </Button>
                        </Link>
                      </div>
                    )}
                    <div className='d-flex justify-content-center align-items-center '>
                      <Button type='submit' variant='primary'>
                        {submitting ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          'Save'
                        )}
                      </Button>
                    </div>
                  </div>
                  <div className={`${styles.tabWidth} p-1`}>
                    <Tab.Container
                      defaultActiveKey='functions'
                      activeKey={key}
                      id='user-tabs'
                      onSelect={(k) => setKey(k ?? '')}
                    >
                      {/* <Tab> */}
                      <Nav variant='tabs' className='mb-3'>
                        <Nav.Item>
                          <NavLink to='functions'>
                            <Nav.Link as='button' eventKey='functions'>
                              Functions
                            </Nav.Link>
                          </NavLink>
                        </Nav.Item>
                        <Nav.Item>
                          <NavLink to='accessForm'>
                            <Nav.Link as='button' eventKey='accessForm'>
                              Access Form
                            </Nav.Link>
                          </NavLink>
                        </Nav.Item>
                      </Nav>
                      <Routes>
                        <Route
                          path=''
                          element={
                            <Tab.Content>
                              <Tab.Pane eventKey='functions'>
                                <ErrorBoundary>
                                  <FunctionsForm
                                    form={form}
                                    values={values}
                                    siteFunctions={siteFunctions}
                                    siteFunctionDefaultGroups={
                                      siteFunctionDefaultGroups
                                    }
                                    handleDefaultAccessChange={
                                      handleDefaultAccessChange
                                    }
                                  />
                                </ErrorBoundary>
                              </Tab.Pane>
                            </Tab.Content>
                          }
                        />
                        <Route
                          path='functions'
                          element={
                            <Tab.Content>
                              <Tab.Pane eventKey='functions'>
                                <ErrorBoundary>
                                  <FunctionsForm
                                    form={form}
                                    values={values}
                                    siteFunctions={siteFunctions}
                                    siteFunctionDefaultGroups={
                                      siteFunctionDefaultGroups
                                    }
                                    handleDefaultAccessChange={
                                      handleDefaultAccessChange
                                    }
                                  />
                                </ErrorBoundary>
                              </Tab.Pane>
                            </Tab.Content>
                          }
                        />
                        <Route
                          path='accessForm'
                          element={
                            // <RequireAuth siteFunction={SiteFunctionsEnum.Notes}>
                            <Tab.Content>
                              <Tab.Pane eventKey='accessForm'>
                                <ErrorBoundary>
                                  <UserAccessForm
                                    form={form}
                                    values={values}
                                    isNew={userId === 'new'}
                                    selectAll={selectAll}
                                    activeInsuranceCompanies={[
                                      ...insuranceCompanies
                                        .filter((x) => x.active)
                                        .sort((a, b) =>
                                          (a.fullname ?? '').localeCompare(
                                            b.fullname ?? ''
                                          )
                                        ),
                                    ]}
                                  />
                                </ErrorBoundary>
                              </Tab.Pane>
                            </Tab.Content>
                            // </RequireAuth>
                          }
                        />
                      </Routes>
                      {/* </Tab> */}
                    </Tab.Container>
                  </div>
                </Container>
              </Form>
            );
          }}
        />
      </Container>
    </PageScaffold>
  );
}
