import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import WorkStatusApi from '../../../Api/WorkStatusApi';
import { WorkStatusGridObj } from '../../../ApiTypes/WorkStatusGridObject';
import WorkStatusTable from './WorkStatusTable';

export default function WorkStatusTab({ claimType }: { claimType: number }) {
  const { claimNumber } = useParams();
  const [statusObjs, setStatusObjs] = useState<WorkStatusGridObj[]>([]);

  useEffect(() => {
    getWorkStatusesForGrid();
  }, [claimNumber]);

  const getWorkStatusesForGrid = () => {
    if (claimNumber) {
      WorkStatusApi.getWorkStatusesForGrid(claimNumber)
        .then((res) => {
          setStatusObjs(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setStatusObjs([]);
    }
  };

  return (
    <div>
      <WorkStatusTable
        claimNumber={claimNumber ?? ''}
        statusObjs={statusObjs}
        getWorkStatusesForGrid={getWorkStatusesForGrid}
        claimType={claimType}
      />
    </div>
  );
}
