import { useState, useEffect } from 'react';
import { Form as RFForm, Field } from 'react-final-form';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import FieldBSRenderCheckbox from '../../Common/FieldBSRenderCheckbox';
import { useAppSelector } from '../../../Reducers/Store';
import { FormApi } from 'final-form';
import { toast } from 'react-toastify';

import styles from './index.module.css';
import { ClaimProperty } from '../../../ApiTypes/ClaimProperty';
import {
  centsValidation,
  composeValidators,
  requiredField,
  zipLength,
} from '../../../Utils/FieldValidation';
import { PropertyType, PropertyTypeEnum } from '../../../ApiTypes/PropertyType';
import ClaimPropertyApi from '../../../Api/ClaimPropertyApi';
import { useLocation, useParams } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import FieldBSRenderMoney from '../../Common/FieldBSRenderMoney';
import {
  cleanMoney,
  formatNumbers,
  formatSSN,
  removeNonDigits,
} from '../../../Utils/InputFormatters';
import { PropertyPhoto } from '../../../ApiTypes/PropertyPhoto';
import PropertyPhotoTable from './PropertyPhotosTable';
import PropertyPhotoApi from '../../../Api/PropertyPhotoApi';
import DeleteConfirmationModal from '../Legal/DeleteConfirmationModal';
import FieldBSRenderTextArea from '../../Common/FieldRenderTextArea';
import AddPhotoModal from './AddPhotoModal';
import { AddFileFormType } from '../../AddFile/AddFileFormType';
import DocumentsApi from '../../../Api/DocumentsApi';
import FieldBSRenderSelect from '../../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../../Common/FieldBSRenderText';
import {
  ClaimCustomOption,
  OptionTypeEnum,
} from '../../../ApiTypes/ClaimCustomOption';
import { State } from '../../../ApiTypes/State';
import FieldBSRenderSelectHorizontal from '../../Common/FieldBSRenderSelectHorizontal';
import { County } from '../../../ApiTypes/County';
import FieldBSRenderDate from '../../Common/FieldBSRenderDate';
import FieldBSRenderYesNoSelect from '../../Common/FieldBSRenderYesNoSelect';
import { parseDatesForServer } from '../../../Utils';

export default function ClaimPropertyAddEdit({
  claimType,
  selectedPropertyClaimId,
  show,
  setShow,
  getClaimProperties,
}: {
  claimType: number;
  selectedPropertyClaimId: string | '';
  show: boolean;
  setShow: () => void;
  getClaimProperties: () => void;
}) {
  let { claimNumber } = useParams();
  let claimPropertyId = selectedPropertyClaimId;
  let location = useLocation();

  const startYear = 1900;
  const endYear = new Date().getFullYear() + 1;
  const years = Array.from(
    new Array(endYear - startYear),
    (val, index) => endYear - index
  );

  const [claimProperty, setClaimProperty] = useState<ClaimProperty | null>(
    null
  );
  const [ownerCountiesForState, setOwnerCountiesForState] = useState<County[]>(
    []
  );
  const [buyerCountiesForState, setBuyerCountiesForState] = useState<County[]>(
    []
  );

  const [propertyPhotos, setPropertyPhotos] = useState<PropertyPhoto[]>([]);
  const [selectedPhoto, setSelectedPhoto] = useState<PropertyPhoto | null>(
    null
  );
  const [propertyPhotosToDelete, setPropertyPhotosToDelete] =
    useState<PropertyPhoto | null>(null);
  const { claim, claimant, claimTypeConfiguration } = useAppSelector(
    (state) => state.currentClaimReducer
  );
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const [showAddPhotoModal, setAddPhotoModal] = useState<boolean>(false);

  const { propertyTypes, claimCustomOptions, states, counties } =
    useAppSelector((state) => state.reference);
  const { userModel } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (claimProperty) {
      getOwnerCounties(claimProperty.ownerState ?? '');
      getBuyerCounties(claimProperty.buyerState ?? '');
    }
  }, [claimProperty]);

  const getOwnerCounties = (state: string) => {
    if (state) {
      const countiesNeeded = counties.filter(
        (c) =>
          c.stateName === states.find((s) => s.stateAbbr === state)?.stateName
      );
      setOwnerCountiesForState(countiesNeeded);
    } else {
      setOwnerCountiesForState([]);
    }
  };
  const getBuyerCounties = (state: string) => {
    if (state) {
      const countiesNeeded = counties.filter(
        (c) =>
          c.stateName === states.find((s) => s.stateAbbr === state)?.stateName
      );
      setBuyerCountiesForState(countiesNeeded);
    } else {
      setBuyerCountiesForState([]);
    }
  };

  let formInstance: FormApi<ClaimProperty, Partial<ClaimProperty>>;

  const getPropertyPhotosForClaimProperty = () => {
    if (claimNumber && claimPropertyId && claimPropertyId !== '') {
      PropertyPhotoApi.getPropertyPhotosForClaim(claimPropertyId)
        .then((res) => {
          setPropertyPhotos(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setPropertyPhotos([]);
    }
  };

  const handlePropertyChange = (id: number) => {
    var ownerD = document.getElementById('ownerDetail');
    var insuranceD = document.getElementById('insuranceDetail');
    var damageD = document.getElementById('damageDetail');
    var salvageD = document.getElementById('salvageDetail');
    var buyerD = document.getElementById('buyerDetail');
    var vehicleD = document.getElementById('vehicleDetail');
    var propertyD = document.getElementById('propertyDetail');

    if (id && propertyTypes) {
      var prop = propertyTypes.find((x) => x.id.toString() === id.toString());

      if (
        prop?.id === PropertyTypeEnum.Business ||
        prop?.id === PropertyTypeEnum.ResidentialHome
      ) {
        SetShowHide(ownerD, true);
        SetShowHide(insuranceD, true);
        SetShowHide(damageD, true);
        SetShowHide(salvageD, true);
        SetShowHide(buyerD, true);
        SetShowHide(vehicleD, false);
        SetShowHide(propertyD, true);
      } else if (prop?.id === PropertyTypeEnum.PassengerCar) {
        SetShowHide(ownerD, true);
        SetShowHide(insuranceD, true);
        SetShowHide(damageD, true);
        SetShowHide(salvageD, true);
        SetShowHide(buyerD, true);
        SetShowHide(vehicleD, true);
        SetShowHide(propertyD, false);
      } else {
        SetShowHide(ownerD, true);
        SetShowHide(insuranceD, false);
        SetShowHide(damageD, true);
        SetShowHide(salvageD, true);
        SetShowHide(buyerD, true);
        SetShowHide(vehicleD, false);
        SetShowHide(propertyD, false);
      }
    } else {
      toast.success('Property Type changed with no id');
      SetShowHide(ownerD, false);
      SetShowHide(insuranceD, false);
      SetShowHide(damageD, false);
      SetShowHide(salvageD, false);
      SetShowHide(buyerD, false);
      SetShowHide(vehicleD, false);
      SetShowHide(propertyD, false);
    }
  };

  const handleBuyerTypeChange = (id: number) => {
    var business = document.getElementById('buyerTypeB');
    var individual = document.getElementById('buyerTypeI');

    if (id && claimCustomOptions) {
      var prop = claimCustomOptions.find(
        (x) => x.id.toString() === id.toString()
      );

      if (id) {
        if (prop?.name === 'Business') {
          SetShowHide(business, true);
          SetShowHide(individual, false);
        } else if (prop?.name === 'Individual') {
          SetShowHide(individual, true);
          SetShowHide(business, false);
        } else {
          SetShowHide(business, false);
          SetShowHide(individual, false);
        }
      }
    } else {
      SetShowHide(business, false);
      SetShowHide(individual, false);
    }
  };

  const SetShowHide = (ele: HTMLElement | null, value: boolean) => {
    if (ele) {
      if (value) {
        ele.className = '';
        ele.classList.add('d-flex');
      } else {
        ele.className = '';
        ele.classList.add(styles.hide);
      }
    }
  };
  useEffect(() => {
    getClaimPropertyById();
    getPropertyPhotosForClaimProperty();
  }, [claimNumber, claimPropertyId, location]);

  const getClaimPropertyById = () => {
    if (claimNumber && claimPropertyId && claimPropertyId !== 'new') {
      ClaimPropertyApi.getClaimPropertyById(claimNumber, +claimPropertyId)
        .then((res) => {
          setClaimProperty(res.data);
          if (res.data.propertyTypeId) {
            handlePropertyChange(res.data.propertyTypeId);
          }
          if (res.data.buyerTypeId) {
            handleBuyerTypeChange(res.data.buyerTypeId);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setClaimProperty(null);
    }
  };

  const onSubmit = (values: ClaimProperty) => {
    const copy = { ...values };
    copy.claimNo = claimNumber ?? '';
    copy.createdBy = userModel?.user?.userId ?? '';
    if (copy.id) {
      return updateClaimProperty(copy);
    }
    return createClaimProperty(copy);
  };

  const createClaimProperty = (v: ClaimProperty) => {
    v.id = 0;
    return ClaimPropertyApi.createClaimProperty(v, claimNumber!)
      .then((res) => {
        if (res.data.success) {
          getClaimProperties();
          setShow();
          toast.success('Success');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create Claim Property');
      });
  };

  const updateClaimProperty = (v: ClaimProperty) => {
    return ClaimPropertyApi.updateClaimProperty(v, claimNumber!)
      .then((res) => {
        if (res.data.success) {
          getClaimPropertyById();
          getPropertyPhotosForClaimProperty();
          setShow();
          toast.success('Success');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update Claim Property');
      });
  };

  const handlePropertyPhotoToDelete = (cp: PropertyPhoto) => {
    setPropertyPhotosToDelete(cp);
    setShowDelete(true);
  };

  const handleDelete = () => {
    if (propertyPhotosToDelete && claimNumber) {
      return PropertyPhotoApi.deletePropertyPhoto(
        propertyPhotosToDelete.id.toString(),
        claimNumber
      )
        .then((res) => {
          if (res.data.success) {
            getPropertyPhotosForClaimProperty();
            setPropertyPhotosToDelete(null);
            setShowDelete(false);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to delete Property Photo');
        });
    }
    return Promise.resolve();
  };

  const handleAddDocument = (values: AddFileFormType) => {
    if (!selectedPhoto) {
      const { file } = values.doc;
      const {
        docTypeId,
        claimNumber,
        newName,
        action,
        serviceFrom,
        serviceTo,
      } = values;
      const fd = new FormData();
      let fileToUpload = file;
      fileToUpload = new File([file], `${file.name}`, { type: file.type });
      fd.append('docTypeId', `${docTypeId}`);
      fd.append('claimNumber', claimNumber);
      fd.append('file', fileToUpload);
      fd.append('newName', file.name);
      fd.append('action', action);
      fd.append('serviceFrom', serviceFrom ?? '');
      fd.append('serviceTo', serviceTo ?? '');
      return DocumentsApi.uploadDocument(claimNumber, fd)
        .then((res) => {
          if (res.data.success) {
            setAddPhotoModal(false);
            return updatePropertyPhoto(
              values,
              res.data.affectedEntityIdentifier,
              newName
            );
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to upload document');
        });
    }
    return updatePropertyPhoto(
      values,
      selectedPhoto.docId!.toString(),
      values.newName
    );
  };

  const updatePropertyPhoto = (
    file: AddFileFormType,
    docid: string,
    notes: string
  ) => {
    const newPhoto: PropertyPhoto = {
      id: selectedPhoto?.id ?? 0,
      claimPropertyId: Number(claimPropertyId),
      docId: Number(docid),
      notes: notes,
      claimProperty: null,
      doc: null,
    };

    return PropertyPhotoApi.updatePropertyPhoto(newPhoto)
      .then((res) => {
        if (res.data.success) {
          getPropertyPhotosForClaimProperty();
          setAddPhotoModal(false);
          toast.success('Success');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update Property Photo');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='xl'
      onHide={() => {
        setShow();
      }}
      dialogClassName=''
      aria-labelledby='Edit-Create-ClaimProperty-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Edit-Create-ClaimProperty-Form-modal'
        >
          <FaEdit className='pe-1' />
          {claimProperty ? 'Edit' : 'Create'} Claim Property
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className='w-100 h-100'>
            <div className={`${styles.addEditTopDetails}`}>
              <p>Claim No: {claim?.claimNo}</p>
              <p>
                Claimant: {claimant?.firstName} {claimant?.lastName}
              </p>
            </div>

            <RFForm
              onSubmit={onSubmit}
              initialValues={
                claimProperty
                  ? {
                      ...claimProperty,
                      logChanges: true,
                    }
                  : {
                      propertyTypeId: 0,
                      logChanges: true,
                    }
              }
              render={({ handleSubmit, form, values, submitting }) => {
                formInstance = form;
                return (
                  <Form onSubmit={handleSubmit}>
                    <div className={`${styles.form}`}>
                      <div className={`${styles.column}`}>
                        <div className='w-100 d-flex'>
                          <div className='w-75 pe-2'>
                            <Field
                              name='propertyTypeId'
                              label='Property Type'
                              options={[...propertyTypes].sort((a, b) =>
                                (a.name ?? '').localeCompare(b.name ?? '')
                              )}
                              optionMethod={(options: PropertyType[]) =>
                                options.map((o) => (
                                  <option key={o.id} value={o.id}>
                                    {o.name}
                                  </option>
                                ))
                              }
                              validate={requiredField}
                              component={FieldBSRenderSelectHorizontal}
                              onChange={handlePropertyChange}
                            />
                          </div>
                          <div className='w-25 ps-2'>
                            <div
                              className='mb-2'
                              style={{ height: '55px' }}
                            ></div>
                          </div>
                        </div>
                        <div className='w-100'>
                          <fieldset
                            className={`${styles.hide}`}
                            id='ownerDetail'
                          >
                            <legend className=''>Owner</legend>
                            <div className='w-100'>
                              <div className='w-100 d-flex'>
                                <div className='w-50 pe-2'>
                                  <Field
                                    name='ownerTypeId'
                                    label='Owner Type'
                                    options={claimCustomOptions
                                      .filter(
                                        (x) =>
                                          x.type ===
                                          OptionTypeEnum.PropertyOwnerType
                                      )
                                      .sort((a, b) =>
                                        (a.name ?? '').localeCompare(
                                          b.name ?? ''
                                        )
                                      )}
                                    optionMethod={(
                                      options: ClaimCustomOption[]
                                    ) =>
                                      options.map((o) => (
                                        <option
                                          key={o.id}
                                          value={o.id?.toString()}
                                        >
                                          {o.name}
                                        </option>
                                      ))
                                    }
                                    component={FieldBSRenderSelect}
                                    // validate={requiredField}
                                  />
                                </div>
                              </div>
                              <div className='w-100 d-flex'>
                                <div className={`${styles.column33} pe-2`}>
                                  <Field
                                    name='ownerName'
                                    label='First Name'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                                <div className={`${styles.column33} pe-2`}>
                                  <Field
                                    name='ownerMiddleName'
                                    label='Middle Name'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                                <div className={`${styles.column33} pe-2`}>
                                  <Field
                                    name='ownerLastName'
                                    label='Last Name'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                              </div>
                              <div className='w-100 d-flex'>
                                <div className={`${styles.column33} pe-2`}>
                                  <Field
                                    name='ownerSsn'
                                    type='text'
                                    label='SSN'
                                    parse={removeNonDigits}
                                    format={formatSSN}
                                    component={FieldBSRenderText}
                                  />
                                </div>
                                <div className={`${styles.column33} pe-2`}>
                                  <Field
                                    name='ownerDob'
                                    type='text'
                                    label='DOB'
                                    parse={parseDatesForServer}
                                    component={FieldBSRenderDate}
                                  />
                                </div>
                                <div
                                  className={`${styles.column33} d-flex justify-content-center gap-2 my-auto pe-2`}
                                >
                                  <Field
                                    name='ownerSex'
                                    type='radio'
                                    label='Male'
                                    value='M'
                                    checked={values?.ownerSex === 'M'}
                                    component={FieldBSRenderCheckbox}
                                    // Required={requiredField}
                                  />
                                  <Field
                                    name='ownerSex'
                                    type='radio'
                                    label='Female'
                                    value='F'
                                    checked={values.ownerSex === 'F'}
                                    component={FieldBSRenderCheckbox}
                                    // Required={requiredField}
                                  />
                                </div>
                              </div>

                              <div className='w-100 d-flex'>
                                <div className='w-50 pe-2'>
                                  <Field
                                    name='ownerAddress1'
                                    label='Address 1'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                                <div className='w-50 pe-2'>
                                  <Field
                                    name='ownerAddress2'
                                    label='Address 2'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                              </div>
                              <div className='w-100 d-flex'>
                                <div className={`${styles.column33} pe-2`}>
                                  <Field
                                    name='ownerCity'
                                    label='City'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                                <div className={`${styles.column66} d-flex`}>
                                  <div className={`${styles.column33} pe-2`}>
                                    <Field
                                      name='ownerState'
                                      label='State'
                                      options={[...states].sort((a, b) =>
                                        (a.stateAbbr ?? '').localeCompare(
                                          b.stateAbbr ?? ''
                                        )
                                      )}
                                      optionMethod={(options: State[]) =>
                                        options.map((o) => (
                                          <option
                                            key={o.stateAbbr}
                                            value={o.stateAbbr}
                                          >
                                            {o.stateAbbr}
                                          </option>
                                        ))
                                      }
                                      onChange={getOwnerCounties}
                                      component={FieldBSRenderSelect}
                                    />
                                  </div>
                                  <div className={`${styles.column33} pe-2`}>
                                    <Field
                                      name='ownerZip'
                                      label='Zip'
                                      validate={zipLength}
                                      component={FieldBSRenderText}
                                      maxLength={5}
                                    />
                                  </div>
                                  <div className={`${styles.column66} pe-2`}>
                                    <Field
                                      name='ownerCounty'
                                      label='County'
                                      options={[...ownerCountiesForState].sort(
                                        (a, b) =>
                                          (a.countyName ?? '').localeCompare(
                                            b.countyName ?? ''
                                          )
                                      )}
                                      optionMethod={(options: County[]) =>
                                        options.map((o) => (
                                          <option
                                            key={o.id}
                                            value={o.countyName}
                                          >
                                            {o.countyName}
                                          </option>
                                        ))
                                      }
                                      component={FieldBSRenderSelect}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='w-100 d-flex'>
                                <div className='w-50 pe-2'></div>
                              </div>

                              <div className='d-flex'>
                                <div className='w-50 pe-2'>
                                  <Field
                                    name='ownerPhone'
                                    label='Phone'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                                <div className='w-50 pe-2'>
                                  <Field
                                    name='ownerEmail'
                                    label='Email'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <fieldset
                            className={`${styles.hide}`}
                            id='insuranceDetail'
                          >
                            <legend className=''>Insurance</legend>
                            <div className='w-100 d-flex'>
                              <div className='w-50 pe-2'>
                                <Field
                                  name='insuranceName'
                                  label='Insurance Name'
                                  component={FieldBSRenderText}
                                />
                                <Field
                                  name='insuranceEmail'
                                  label='Insurance Email'
                                  component={FieldBSRenderText}
                                />
                                <Field
                                  name='insurancePolicyId'
                                  label='Policy #'
                                  component={FieldBSRenderText}
                                />
                              </div>
                              <div className='w-50 pe-2'>
                                <Field
                                  name='insurancePhone'
                                  label='Insurance Phone'
                                  component={FieldBSRenderText}
                                />
                                <Field
                                  name='insuranceContactName'
                                  label='Insurance Contact Name'
                                  component={FieldBSRenderText}
                                />
                              </div>
                            </div>
                          </fieldset>
                          <fieldset
                            className={`${styles.hide}`}
                            id='damageDetail'
                          >
                            <legend className=''>Damage</legend>
                            <div className='w-100'>
                              <div>
                                <Field
                                  name='damageTypeId'
                                  label='Damage Type'
                                  options={claimCustomOptions
                                    .filter(
                                      (x) =>
                                        x.type ===
                                        OptionTypeEnum.PropertyDamageType
                                    )
                                    .sort((a, b) =>
                                      (a.name ?? '').localeCompare(b.name ?? '')
                                    )}
                                  optionMethod={(
                                    options: ClaimCustomOption[]
                                  ) =>
                                    options.map((o) => (
                                      <option
                                        key={o.id}
                                        value={o.id?.toString()}
                                      >
                                        {o.name}
                                      </option>
                                    ))
                                  }
                                  component={FieldBSRenderSelect}
                                  // validate={requiredField}
                                />
                              </div>
                              <div className='w-100 pe-2'>
                                <Field
                                  name='damageDescription'
                                  label='Description'
                                  rows={3}
                                  component={FieldBSRenderTextArea}
                                />
                              </div>
                              <div className='d-flex'>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='estimatedValue'
                                    label='Estimated Value'
                                    type='text'
                                    format={formatNumbers}
                                    parse={cleanMoney}
                                    validate={composeValidators(
                                      centsValidation
                                    )}
                                    component={FieldBSRenderMoney}
                                  />
                                  <Field
                                    name='estimatedDamageAmount'
                                    label='Estimated Damage Amount'
                                    type='text'
                                    format={formatNumbers}
                                    parse={cleanMoney}
                                    validate={composeValidators(
                                      centsValidation
                                    )}
                                    component={FieldBSRenderMoney}
                                  />
                                </div>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='actualValue'
                                    label='Actual Value'
                                    type='text'
                                    format={formatNumbers}
                                    parse={cleanMoney}
                                    validate={composeValidators(
                                      centsValidation
                                    )}
                                    component={FieldBSRenderMoney}
                                  />
                                  <Field
                                    name='actualDamageAmount'
                                    label='Actual Damage Amount'
                                    type='text'
                                    format={formatNumbers}
                                    parse={cleanMoney}
                                    validate={composeValidators(
                                      centsValidation
                                    )}
                                    component={FieldBSRenderMoney}
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <fieldset
                            className={`${styles.hide}`}
                            id='salvageDetail'
                          >
                            <legend className=''>Salvage</legend>
                            <div className='w-100 mb-2 pe-2'>
                              <div className='d-flex'>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='salvageTypeId'
                                    label='Salvage Type'
                                    options={claimCustomOptions
                                      .filter(
                                        (x) =>
                                          x.type ===
                                          OptionTypeEnum.PropertySalvageType
                                      )
                                      .sort((a, b) =>
                                        (a.name ?? '').localeCompare(
                                          b.name ?? ''
                                        )
                                      )}
                                    optionMethod={(
                                      options: ClaimCustomOption[]
                                    ) =>
                                      options.map((o) => (
                                        <option
                                          key={o.id}
                                          value={o.id?.toString()}
                                        >
                                          {o.name}
                                        </option>
                                      ))
                                    }
                                    component={FieldBSRenderSelect}
                                    // validate={requiredField}
                                  />
                                </div>
                              </div>
                              <div className='d-flex'>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='salvageTitleRecievedDate'
                                    label='Title Recieved Date'
                                    parse={parseDatesForServer}
                                    component={FieldBSRenderDate}
                                  />
                                </div>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='salvageSaleDate'
                                    label='Sale Date'
                                    parse={parseDatesForServer}
                                    component={FieldBSRenderDate}
                                  />
                                </div>
                              </div>
                              <div className='d-flex'>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='salvageForwardedToYard'
                                    label='Forwarded To Salvage Yard'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='salvageEngineMissing'
                                    label='Engine Missing'
                                    checked={
                                      values.salvageEngineMissing === true
                                    }
                                    component={FieldBSRenderCheckbox}
                                    type='checkbox'
                                  />
                                </div>
                              </div>
                              <div className='d-flex'>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='salvageProceedsDate'
                                    label='Proceeds Date'
                                    parse={parseDatesForServer}
                                    component={FieldBSRenderDate}
                                  />
                                </div>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='salvageProceedsAmount'
                                    label='Proceeds Amount'
                                    type='text'
                                    format={formatNumbers}
                                    parse={cleanMoney}
                                    validate={centsValidation}
                                    component={FieldBSRenderMoney}
                                  />
                                </div>
                              </div>
                              <div className='d-flex'>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='salvageTakeSalvage'
                                    label='Take Salvage'
                                    checked={values.salvageTakeSalvage === true}
                                    component={FieldBSRenderCheckbox}
                                    type='checkbox'
                                  />
                                </div>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='salvageAppraisalAmount'
                                    label='Appraisal Value'
                                    type='text'
                                    format={formatNumbers}
                                    parse={cleanMoney}
                                    validate={centsValidation}
                                    component={FieldBSRenderMoney}
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <fieldset
                            className={`${styles.hide}`}
                            id='buyerDetail'
                          >
                            <legend className=''>Buyer</legend>
                            <div className='w-100'>
                              <div className='w-100 d-flex'>
                                <div className='w-50 pe-2'>
                                  <Field
                                    name='buyerTypeId'
                                    label='Buyer Type'
                                    options={claimCustomOptions
                                      .filter(
                                        (x) =>
                                          x.type ===
                                          OptionTypeEnum.PropertyBuyerType
                                      )
                                      .sort((a, b) =>
                                        (a.name ?? '').localeCompare(
                                          b.name ?? ''
                                        )
                                      )}
                                    optionMethod={(
                                      options: ClaimCustomOption[]
                                    ) =>
                                      options.map((o) => (
                                        <option
                                          key={o.id}
                                          value={o.id?.toString()}
                                        >
                                          {o.name}
                                        </option>
                                      ))
                                    }
                                    component={FieldBSRenderSelect}
                                    onChange={handleBuyerTypeChange}
                                    // validate={requiredField}
                                  />
                                </div>
                              </div>
                              <div className={`${styles.hide}`} id='buyerTypeB'>
                                <div className='w-100'>
                                  <Field
                                    name='buyerBusinessName'
                                    label='Business Name'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                              </div>
                              <div className={`${styles.hide}`} id='buyerTypeI'>
                                <div className='w-100 d-flex'>
                                  <div className={`${styles.column33} pe-2`}>
                                    <Field
                                      name='buyerFirstName'
                                      label='First Name'
                                      component={FieldBSRenderText}
                                    />
                                  </div>
                                  <div className={`${styles.column33} pe-2`}>
                                    <Field
                                      name='buyerMiddleName'
                                      label='Middle Name'
                                      component={FieldBSRenderText}
                                    />
                                  </div>
                                  <div className={`${styles.column33} pe-2`}>
                                    <Field
                                      name='buyerLastName'
                                      label='Last Name'
                                      component={FieldBSRenderText}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='w-100 d-flex'>
                                <div className='w-50 pe-2'>
                                  <Field
                                    name='buyerAddress1'
                                    label='Address 1'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                                <div className='w-50 pe-2'>
                                  <Field
                                    name='buyerAddress2'
                                    label='Address 2'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                              </div>
                              <div className='w-100 d-flex'>
                                <div className={`${styles.column33} pe-2`}>
                                  <Field
                                    name='buyerCity'
                                    label='City'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                                <div className={`${styles.column66} d-flex`}>
                                  <div className={`${styles.column33} pe-2`}>
                                    <Field
                                      name='buyerState'
                                      label='State'
                                      options={[...states].sort((a, b) =>
                                        (a.stateAbbr ?? '').localeCompare(
                                          b.stateAbbr ?? ''
                                        )
                                      )}
                                      optionMethod={(options: State[]) =>
                                        options.map((o) => (
                                          <option
                                            key={o.stateAbbr}
                                            value={o.stateAbbr}
                                          >
                                            {o.stateAbbr}
                                          </option>
                                        ))
                                      }
                                      onChange={getBuyerCounties}
                                      component={FieldBSRenderSelect}
                                    />
                                  </div>
                                  <div className={`${styles.column33} pe-2`}>
                                    <Field
                                      name='buyerZip'
                                      label='Zip'
                                      validate={zipLength}
                                      component={FieldBSRenderText}
                                      maxLength={5}
                                    />
                                  </div>
                                  <div className={`${styles.column66} pe-2`}>
                                    <Field
                                      name='buyerCounty'
                                      label='County'
                                      options={[...buyerCountiesForState].sort(
                                        (a, b) =>
                                          (a.countyName ?? '').localeCompare(
                                            b.countyName ?? ''
                                          )
                                      )}
                                      optionMethod={(options: County[]) =>
                                        options.map((o) => (
                                          <option
                                            key={o.id}
                                            value={o.countyName}
                                          >
                                            {o.countyName}
                                          </option>
                                        ))
                                      }
                                      component={FieldBSRenderSelect}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='w-100 d-flex'>
                                <div className='w-50 pe-2'></div>
                              </div>

                              <div className='d-flex'>
                                <div className='w-50 pe-2'>
                                  <Field
                                    name='buyerPhone'
                                    label='Phone'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                                <div className='w-50 pe-2'>
                                  <Field
                                    name='buyerEmail'
                                    label='Email'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                        {/* end left side */}
                      </div>
                      {/* right side */}
                      <div className={`${styles.column}`}>
                        <div className='w-100 d-flex'>
                          <div className='w-50 pe-2'>
                            <div
                              className='mb-2'
                              style={{ height: '55px' }}
                            ></div>
                          </div>
                          <div className='w-50 ps-2'>
                            <div
                              className='mb-2'
                              style={{ height: '55px' }}
                            ></div>
                          </div>
                        </div>
                        <div className='w-100'>
                          <fieldset
                            className={`${styles.hide}`}
                            id='vehicleDetail'
                          >
                            <legend className=''>Vehicle</legend>
                            <div className='w-100 mb-2 pe-2'>
                              <div className='d-flex'>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='vehicleTypeId'
                                    label='Vehicle Type'
                                    options={claimCustomOptions
                                      .filter(
                                        (x) =>
                                          x.type ===
                                          OptionTypeEnum.PropertyVehicleType
                                      )
                                      .sort((a, b) =>
                                        (a.name ?? '').localeCompare(
                                          b.name ?? ''
                                        )
                                      )}
                                    optionMethod={(
                                      options: ClaimCustomOption[]
                                    ) =>
                                      options.map((o) => (
                                        <option
                                          key={o.id}
                                          value={o.id?.toString()}
                                        >
                                          {o.name}
                                        </option>
                                      ))
                                    }
                                    component={FieldBSRenderSelect}
                                    // validate={requiredField}
                                  />
                                </div>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='vehicleYear'
                                    label='Year'
                                    options={years}
                                    optionMethod={(options: number[]) =>
                                      options.map((o) => (
                                        <option key={o} value={o}>
                                          {o}
                                        </option>
                                      ))
                                    }
                                    component={FieldBSRenderSelect}
                                  />
                                </div>
                              </div>
                              <div className='d-flex'>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='vehicleMake'
                                    label='Make'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='vehicleModel'
                                    label='Model'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                              </div>
                              <div className='d-flex'>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='vehicleColor'
                                    label='Color'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='vehicleVin'
                                    label='VIN'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                              </div>
                              <div className='d-flex'>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='vehicleTagNumber'
                                    label='License Plate Number'
                                    component={FieldBSRenderText}
                                    maxLength={7}
                                  />
                                </div>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='vehicleTagState'
                                    label='Registered State'
                                    options={[...states].sort((a, b) =>
                                      (a.stateAbbr ?? '').localeCompare(
                                        b.stateAbbr ?? ''
                                      )
                                    )}
                                    optionMethod={(options: State[]) =>
                                      options.map((o) => (
                                        <option
                                          key={o.stateAbbr}
                                          value={o.stateAbbr}
                                        >
                                          {o.stateAbbr}
                                        </option>
                                      ))
                                    }
                                    component={FieldBSRenderSelect}
                                  />
                                </div>
                              </div>
                              <div className='d-flex'>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='vehicleMileage'
                                    label='Mileage'
                                    type='number'
                                    // formatOnBlur={removeNonDigits}
                                    component={FieldBSRenderText}
                                  />
                                </div>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='vehicleFleetNumber'
                                    label='Fleet Number'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                              </div>

                              <div className='d-flex'>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='ownerIsDriver'
                                    label='Owner Is Driver?'
                                    checked={values.ownerIsDriver === true}
                                    component={FieldBSRenderCheckbox}
                                    type='checkbox'
                                  />
                                </div>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='vehicleDui'
                                    label='DUI?'
                                    checked={values.vehicleDui === true}
                                    component={FieldBSRenderCheckbox}
                                    type='checkbox'
                                  />
                                </div>
                              </div>

                              <div className='d-flex'>
                                <div className='w-100 mb-2 pe-2'>
                                  <Field
                                    name='vehicleCurrentLocation'
                                    label='Current Vehicle Location'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                              </div>
                              <div className='d-flex'>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='wasTowed'
                                    label='Was Towed?'
                                    checked={values.wasTowed === true}
                                    component={FieldBSRenderCheckbox}
                                    type='checkbox'
                                  />
                                </div>
                                <div className='w-50 mb-2 pe-2'></div>
                              </div>
                              <div className='d-flex'>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='towAmount'
                                    label='Tow Amount'
                                    type='text'
                                    format={formatNumbers}
                                    parse={cleanMoney}
                                    validate={centsValidation}
                                    component={FieldBSRenderMoney}
                                  />
                                </div>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='vehicleTowLocation'
                                    label='Tow Location'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <fieldset
                            className={`${styles.hide}`}
                            id='propertyDetail'
                          >
                            <legend className=''>Property Location</legend>
                            <div className='w-100'>
                              <div className='w-100'>
                                <Field
                                  name='address'
                                  label='Address'
                                  component={FieldBSRenderText}
                                />
                              </div>
                              <div className='d-flex'>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='city'
                                    label='City'
                                    component={FieldBSRenderText}
                                  />
                                  <Field
                                    name='zip'
                                    label='Zip'
                                    validate={zipLength}
                                    component={FieldBSRenderText}
                                    maxLength={5}
                                  />
                                </div>
                                <div className='w-50 mb-2 pe-2'>
                                  <Field
                                    name='state'
                                    label='State'
                                    component={FieldBSRenderText}
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                    {claimProperty != null && (
                      <div className='w-100 mt-5'>
                        <h4>Property Photos</h4>
                        <div className={`${styles.actionButtons} mb-2`}>
                          <Button
                            type='button'
                            variant='primary'
                            size='sm'
                            onClick={() => {
                              setSelectedPhoto(null);
                              setAddPhotoModal(true);
                            }}
                          >
                            New Property Photo
                          </Button>
                        </div>
                        <PropertyPhotoTable
                          propertyPhotos={propertyPhotos.sort((a, b) =>
                            (b.doc?.date ?? '').localeCompare(a.doc?.date ?? '')
                          )}
                          handlePropertyPhotoToDelete={
                            handlePropertyPhotoToDelete
                          }
                          setShowPropertyPhoto={setAddPhotoModal}
                          setSelectedPhoto={setSelectedPhoto}
                        />
                        <AddPhotoModal
                          show={showAddPhotoModal}
                          setShow={setAddPhotoModal}
                          claimNumber={claimNumber!}
                          handleAddDocument={handleAddDocument}
                          selectedPhoto={selectedPhoto}
                          claimType={claimType}
                        />
                        <DeleteConfirmationModal
                          show={showDelete}
                          setShow={setShowDelete}
                          setNull={setPropertyPhotosToDelete}
                          handleDelete={handleDelete}
                        />
                      </div>
                    )}

                    <div className={`${styles.actionButtons} pt-3`}>
                      <Field
                        name='logChanges'
                        type='checkbox'
                        label='Add Change Log Note on Save'
                        checked={values.logChanges === true}
                        component={FieldBSRenderCheckbox}
                      />
                      <Button type='submit' variant='primary' size='sm'>
                        {submitting ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          'Save'
                        )}
                      </Button>
                      <Button
                        type='button'
                        size='sm'
                        variant='secondary'
                        onClick={() => {
                          // runCleanUp();
                          form.reset();
                          setShow();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                );
              }}
            />
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
