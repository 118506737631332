import React from 'react';

type FinalRenderSelectProps = {
  label?: string;
  options: any[];
  optionMethod?: (options: any[]) => any[];
  name?: string;
  disabled?: boolean;
  defaultValue?: any;
  onChange?: (value: any) => void;
  value: any;
};

export default function BSControlledSelect({
  label,
  options,
  optionMethod,
  name,
  disabled,
  onChange,
  defaultValue,
  value,
}: FinalRenderSelectProps) {
  return (
    <div className='mb-3'>
      <label htmlFor={name} className='form-label fs-6  m-0'>
        {label}
      </label>
      <select
        id={name}
        disabled={disabled}
        defaultValue={defaultValue}
        value={value}
        onChange={(e) => {
          onChange && onChange(e.target.value);
        }}
        className='form-select form-select-sm'
      >
        <option value=''>--Select--</option>
        {optionMethod && optionMethod(options)}
        {!optionMethod &&
          options.map((val) => (
            <option value={val} key={val}>
              {val}
            </option>
          ))}
      </select>
    </div>
  );
}
