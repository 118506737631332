import { ClaimType } from './ClaimType';
import { ClaimTab } from './ClaimTab';

export interface ClaimTypeTab {
  id: number;
  claimTypeId: number;
  claimTabId: number;
  visible: boolean;

  //References
  claimType: ClaimType | null;
  claimTab: ClaimTab | null;
}

export enum ClaimTabsEnum {
  Info = 1,
  Notes = 2,
  Diary = 3,
  Payments = 4,
  Reserves = 5,
  Indemnity = 6,
  NCM = 7,
  Legal = 8,
  Recoveries = 9,
  Letters = 10,
  Documents = 11,
  SBWC = 12,
  FROI = 13,
  Review = 14,
  Physicians = 15,
  WorkStatus = 16,
  Witnesses = 17,
  ClaimProperty = 18,
  ClaimCMSInfo = 19,
  ClaimSubrogation = 20,
  Litigation = 21,
  ClaimantForm = 22,
}
