import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { FaPlusCircle, FaPrescriptionBottle } from 'react-icons/fa';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { toast } from 'react-toastify';
import { Button, Spinner } from 'react-bootstrap';
import BSControlledSelect from '../Common/BSControlledSelect';
import { useAppSelector } from '../../Reducers/Store';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import PanelApi from '../../Api/PanelApi';
import { Panel } from '../../ApiTypes/Panel';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Pbm } from '../../ApiTypes/Pbm';
import PbmApi from '../../Api/PbmApi';
import PbmTable from './PbmTable';

export default function PbmList() {
  const [pbms, setPbms] = useState<Pbm[]>([]);

  useEffect(() => {
    getPbms();
  }, []);

  const getPbms = () => {
    PbmApi.getActivePbms()
      .then((res) => {
        setPbms(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get PBMs ');
      });
  };

  // const handleAddPanel = () => {
  //   if (selectedInsurCoId) {
  //     setShowAddPanel(true);
  //   }
  // };

  // const createPanel = (values: Panel) => {
  //   return PanelApi.createPanel(values)
  //     .then((res) => {
  //       if (res.data.success) {
  //         navigate(`/panels/${res.data.affectedEntityIdentifier}`);
  //       } else {
  //         toast.error(res.data.message);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error('Failed to create panel');
  //     });
  // };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaPrescriptionBottle className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>PBM List</h1>
          </div>
          <div className='ms-3'></div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center gap1Rem pb-3'>
        <Link to={`/pbms/new`}>
          <Button
            type='button'
            variant='outline-primary'
            size='sm'
            className='button-icon-text'
          >
            <FaPlusCircle /> PBM
          </Button>
        </Link>
      </div>
      <PbmTable data={pbms} />
    </PageScaffold>
  );
}
