import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaChartBar } from 'react-icons/fa';
import { toast } from 'react-toastify';
import DocumentsApi from '../../Api/DocumentsApi';
import { DocumentStatus } from '../../ApiTypes/DocumentStatus';
import { ScanDocGridObject } from '../../ApiTypes/ScanDocGridObject';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import ManagerBarChart from './ManagerBarChart';
import ManagerDashboardTable from './ManagerDashboardTable';

export default function ManagerDashboard() {
  const [docs, setDocs] = useState<ScanDocGridObject[]>([]);
  const [filteredDocs, setFilteredDocs] = useState<ScanDocGridObject[]>([]);
  const [chartObjects, setChartObjects] = useState<chartObject[]>([]);

  type chartObject = {
    adjuster: string;
    docCount: number;
  };

  useEffect(() => {
    getDocs();
  }, []);

  const getDocs = () => {
    DocumentsApi.getScanDocsByStatus(DocumentStatus.New)
      .then((res) => {
        setDocs(res.data);
        setFilteredDocs(res.data);
        createChartObjects(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get docs');
      });
  };
  const getAdjusterFromChart = (adjusterName: string) => {
    const f = docs.filter((d) => d.adjusterName === adjusterName);
    setFilteredDocs(f);
  };

  const createChartObjects = (list: ScanDocGridObject[]) => {
    const objects = list.reduce((acc, curr) => {
      if (curr.adjusterName in acc) {
        acc[curr.adjusterName] = acc[curr.adjusterName] + 1;
      } else {
        acc[curr.adjusterName] = 1;
      }
      return acc;
    }, {} as { [key: string]: number });

    const bars: chartObject[] = [];
    for (let key in objects) {
      bars.push({ adjuster: key, docCount: objects[key] });
    }
    setChartObjects(bars);
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaChartBar className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Managers Documents Dashboard</h1>
            <p>View adjusters with new documents to review</p>
          </div>
        </div>
        <div className='align-self-center d-flex justify-content-around'>
          <Button
            type='button'
            variant='outline-primary'
            size='sm'
            onClick={() => {
              setFilteredDocs(docs);
            }}
          >
            Show All Adjuster Documents
          </Button>
        </div>
      </div>
      <ManagerBarChart
        data={chartObjects}
        getAdjusterFromChart={getAdjusterFromChart}
      />
      <ManagerDashboardTable data={filteredDocs} />
    </PageScaffold>
  );
}
