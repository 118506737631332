import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ClaimTypeTab } from '../ApiTypes/ClaimTypeTab';
import { ClaimTypeConfiguration } from '../ApiTypes/ClaimTypeConfiguration';
import { ClaimSubType } from '../ApiTypes/ClaimSubType';

class ClaimTypeConfigurationApi {
  static getClaimTypeTabs(claimType: number) {
    return axios.get<ClaimTypeTab[]>(
      `${API_URL}/api/claimtypetabs/${claimType}`,
      getAuthHeader()
    );
  }

  static getClaimTypeConfigurations(claimType: number) {
    return axios.get<ClaimTypeConfiguration[]>(
      `${API_URL}/api/claimtypeconfigurations/${claimType}`,
      getAuthHeader()
    );
  }

  static getClaimSubTypes(claimType: number) {
    return axios.get<ClaimSubType[]>(
      `${API_URL}/api/claimsubtypes/${claimType}`,
      getAuthHeader()
    );
  }
}
export default ClaimTypeConfigurationApi;
