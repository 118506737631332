import React, { useState, useEffect, useCallback } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { FaUserCheck } from 'react-icons/fa';
import WorkStatusApi from '../../Api/WorkStatusApi';
import { Physician } from '../../ApiTypes/Physician';
import { WorkStatus } from '../../ApiTypes/WorkStatus';
import { WorkStatusBodyPart } from '../../ApiTypes/WorkStatusBodyPart';
import { WorkStatusType } from '../../ApiTypes/WorkStatusType';
import { useAppSelector } from '../../Reducers/Store';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import { WorkStatusFormType } from './WorkStatusFormType';

import styles from './index.module.css';
import { toast } from 'react-toastify';
import { WorkStatusRequest } from '../../ApiTypes/WorkStatusRequest';
import { useNavigate, useParams } from 'react-router-dom';
import { ClaimTypesURL } from '../../ApiTypes/ClaimTypeConfiguration';

export default function WorkStatusModal({
  show,
  setShow,
  workStatusId,
  nullWs,
  authorizedPhysicians,
  claimNumber,
  getWorkStatusesForGrid,
  claimType,
}: {
  show: boolean;
  setShow: (s: boolean) => void;
  workStatusId: number | null;
  nullWs: (ws: null) => void;
  authorizedPhysicians: Physician[];
  claimNumber: string;
  getWorkStatusesForGrid: () => void;
  claimType: number;
}) {
  const { workStatusTypes, workStatusBodyParts } = useAppSelector(
    (state) => state.reference
  );

  const { docid } = useParams();
  const navigate = useNavigate();

  const [ws, setWs] = useState<WorkStatus | null>(null);
  const [wsBodyParts, setWsBodyParts] = useState<WorkStatusBodyPart[]>([]);
  const getWorkStatus = useCallback(() => {
    if (workStatusId && workStatusId > 0) {
      WorkStatusApi.getWorkStatusById(workStatusId)
        .then((res) => {
          setWs(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setWs(null);
    }
  }, [workStatusId]);

  const getBodyPartsForWorkStatus = useCallback(() => {
    if (workStatusId && workStatusId > 0) {
      WorkStatusApi.getBodyPartsForWorkStatus(workStatusId)
        .then((res) => {
          setWsBodyParts(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setWsBodyParts([]);
    }
  }, [workStatusId]);

  useEffect(() => {
    getWorkStatus();
    getBodyPartsForWorkStatus();
  }, [getWorkStatus, getBodyPartsForWorkStatus]);

  const onSubmit = (values: WorkStatusFormType) => {
    if (values.bodyParts.length < 1) {
      toast.error('Must select at least one body part');
    }
    if (
      values.setDiary === true &&
      (values.nextOfficeVisit === null || values.nextOfficeVisit === '')
    ) {
      toast.error('Next visit date is required if set diary is checked');
    }
    const copy = { ...values };
    const toSend: WorkStatus = {
      ...copy,
      dischargedFromCare:
        copy.dischargedFromCare === 'true'
          ? true
          : copy.dischargedFromCare === 'false'
          ? false
          : null,
      dischargedFromCareUnknown:
        copy.dischargedFromCare !== 'true' &&
        copy.dischargedFromCare !== 'false',
    };

    var workStatusBodyPartsToSend: WorkStatusBodyPart[] = [];

    copy.bodyParts.forEach((b) => {
      var p = workStatusBodyParts.find(
        (s) => s.bodyPart.toUpperCase().trim() === b.toUpperCase().trim()
      );
      if (p) {
        workStatusBodyPartsToSend.push(p);
      }
    });

    const request: WorkStatusRequest = {
      status: toSend,
      bodyParts: workStatusBodyPartsToSend,
    };

    if ((request?.status?.id ?? 0) > 0) {
      return updateStatus(request);
    } else {
      return createStatus(request);
    }
  };

  const updateStatus = (request: WorkStatusRequest) => {
    return WorkStatusApi.updateWorkStatus(request)
      .then((res) => {
        if (res.data.success) {
          setShow(false);
          if (docid) {
            navigate(`/${ClaimTypesURL[claimType]}/${claimNumber}/work-status`);
          }
          nullWs(null);
          getWorkStatusesForGrid();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update work status');
      });
  };
  const createStatus = (request: WorkStatusRequest) => {
    request.status.id = 0;
    request.status.bodyPart = null;
    request.status.bodyPartValue = null;
    return WorkStatusApi.createWorkStatus(request)
      .then((res) => {
        if (res.data.success) {
          if (docid) {
            navigate(`/${ClaimTypesURL[claimType]}/${claimNumber}/work-status`);
          }
          setShow(false);
          nullWs(null);
          getWorkStatusesForGrid();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create work status');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
        nullWs(null);
      }}
      aria-labelledby='Work-Status-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Work-Status-Form-modal'>
          <FaUserCheck className='pe-1' /> Work Status
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={onSubmit}
            validate={(values) => {
              const errors: {
                [Property in keyof WorkStatusFormType]?: string;
              } = {};
              if (values.statusType === 2 && !values.statusDescription) {
                errors.statusDescription = 'Required';
              }
              if (
                values.setDiary === true &&
                (values.nextOfficeVisit === null ||
                  values.nextOfficeVisit === '')
              ) {
                errors.nextOfficeVisit = 'Required';
              }
              return errors;
            }}
            initialValues={
              ws
                ? {
                    ...ws,
                    bodyParts: wsBodyParts.map((x) => x.bodyPart),
                    dischargedFromCare:
                      ws.dischargedFromCare === true
                        ? 'true'
                        : ws.dischargedFromCare === false
                        ? 'false'
                        : 'null',
                  }
                : {
                    bodyParts: [],
                    dischargedFromCare: 'null',
                    claimNo: claimNumber,
                    nextOfficeVisit: null,
                  }
            }
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className={`${styles.gridSurround}`}>
                    <div>
                      <Field
                        name='statusType'
                        label='Work Status Type'
                        options={workStatusTypes}
                        optionMethod={(options: WorkStatusType[]) =>
                          options.map((o) => (
                            <option key={o.id} value={o.id}>
                              {o.title}
                            </option>
                          ))
                        }
                        component={FieldBSRenderSelect}
                        validate={requiredField}
                      />
                      <Field
                        name='statusDescription'
                        label='Description'
                        rows={3}
                        component={FieldBSRenderTextArea}
                      />
                      <div className='mb-3'>
                        <Field
                          name='wc104needed'
                          label='WC104 is Needed'
                          type='checkbox'
                          check={values.wc104needed === true}
                          component={FieldBSRenderCheckbox}
                        />
                      </div>
                      <Field
                        name='assignedPhysician'
                        label='Assigned Physician'
                        options={authorizedPhysicians}
                        optionMethod={(options: Physician[]) =>
                          options.map((o) => (
                            <option key={o.id} value={o.id}>
                              {o.displayName}
                            </option>
                          ))
                        }
                        component={FieldBSRenderSelect}
                        validate={requiredField}
                      />
                      <Field
                        name='date'
                        label='Date of Work Status'
                        validate={requiredField}
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                      <div className='mb-3'>
                        <Field
                          name='lightDutyAvailable'
                          label='Light Duty Available'
                          type='checkbox'
                          check={values.lightDutyAvailable === true}
                          component={FieldBSRenderCheckbox}
                        />
                      </div>
                      <div className={`${styles.nextVisit}`}>
                        <Field
                          name='nextOfficeVisit'
                          label='Next Office Visit'
                          parse={parseDatesForServer}
                          component={FieldBSRenderDate}
                        />
                        <Field
                          name='setDiary'
                          label='Set Diary'
                          type='checkbox'
                          check={values.setDiary === true}
                          component={FieldBSRenderCheckbox}
                        />
                      </div>
                      <Field
                        name='benefitsReducedFrom'
                        label='Inc Benefits Reduced From'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='benefitsReducedTo'
                        label='Inc Benefits Reduced To'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <fieldset>
                        <legend>Discharged From Care</legend>

                        <div className={`${styles.nextVisit}`}>
                          <Field
                            name='dischargedFromCare'
                            label='Yes'
                            type='radio'
                            value='true'
                            checked={values.dischargedFromCare === 'true'}
                            component={FieldBSRenderCheckbox}
                          />
                          <Field
                            name='dischargedFromCare'
                            label='No'
                            type='radio'
                            value='false'
                            checked={values.dischargedFromCare === 'false'}
                            component={FieldBSRenderCheckbox}
                          />
                          <Field
                            name='dischargedFromCare'
                            label='Unknown'
                            type='radio'
                            value='null'
                            checked={values.dischargedFromCare === 'null'}
                            component={FieldBSRenderCheckbox}
                          />
                        </div>
                      </fieldset>
                    </div>
                    <div>
                      <fieldset className={`${styles.fieldSet}`}>
                        <legend>Body Parts</legend>
                        {workStatusBodyParts.map((b) => {
                          return (
                            <Field
                              key={b.id}
                              name='bodyParts'
                              label={b.bodyPart}
                              type='checkbox'
                              value={b.bodyPart}
                              checked={values?.bodyParts?.includes(b.bodyPart)}
                              component={FieldBSRenderCheckbox}
                            />
                          );
                        })}
                      </fieldset>
                    </div>
                  </div>
                  <div className={`${styles.buttonDiv} mt-3`}>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        form.reset();
                        setShow(false);
                        if (docid) {
                          navigate(
                            `/${ClaimTypesURL[claimType]}/${claimNumber}/work-status`
                          );
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
