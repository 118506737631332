import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Colors,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DailyCounts } from '../../ApiTypes/DailyCounts';
import styles from './index.module.css';
import { displayDateOnly } from '../../Utils';
import { ChartObj } from '../../ApiTypes/ChartObj';

export default function LineChart({
  data,
  movedData,
  allHistory,
  showAllHistory,
}: {
  data: DailyCounts[];
  movedData: DailyCounts[];
  allHistory: ChartObj[];
  showAllHistory: boolean;
}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ChartDataLabels,
    Colors
  );

  const getOptions = (): any => ({
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      datalabels: {
        color: '#8E999F',
        anchor: 'end',
        align: 'start',
        font: {
          weight: 'bold',
        },
      },
      tooltip: {
        callbacks: {},
      },
      legend: {
        position: 'top',
        align: 'center',
      },
      title: {
        text: 'Daily Log',
        align: 'center',
        font: {
          size: 24,
        },
        display: true,
      },
      colors: {
        enabled: true,
      },
    },

    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        ticks: {
          beginAtZero: false,
        },
      },
    },
  });
  function onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }

  const getData = (data: DailyCounts[], movedData: DailyCounts[]): any => {
    const allDates = [...data, ...movedData]
      .map((x) => x.argumentName)
      .filter(onlyUnique);

    const filledData = allDates.map((x) => {
      var item = data.find((d) => d.argumentName == x);
      if (item) {
        return item.value;
      }
      return 0;
    });
    const filledMovedData = allDates.map((x) => {
      var item = movedData.find((d) => d.argumentName == x);
      if (item) {
        return item.value;
      }
      return 0;
    });

    return {
      labels: allDates.map((x) => displayDateOnly(x)).filter(onlyUnique),
      datasets: [
        {
          label: 'Documents Assigned',
          data: filledData,
          fill: false,
          type: 'line',
          backgroundColor: '#3f95ff',
          borderColor: '#3f95ff',
        },
        {
          label: 'Documents Handled',
          data: filledMovedData,
          fill: false,
          type: 'line',
          backgroundColor: '#e4581b',
          borderColor: '#e4581b',
        },
      ],
    };
  };
  const getAllHistoryData = (data: ChartObj[]): any => {
    const seriesNames = allHistory.map((x) => x.seriesName).filter(onlyUnique);
    const allDates = data.map((x) => x.name).filter(onlyUnique);

    const getLineData = (seriesName: string) => {
      return allDates.map((x) => {
        var item = data.find(
          (d) => d.name === x && d.seriesName === seriesName
        );
        if (item) {
          return item.value;
        }
        return 0;
      });
    };

    return {
      labels: data.map((x) => displayDateOnly(x.name)).filter(onlyUnique),
      datasets: seriesNames.map((s) => {
        return {
          label: s,
          data: getLineData(s),
          fill: false,
          type: 'line',
        };
      }),
    };
  };

  return (
    <div className='mb-3'>
      <div className={`${styles.chartArea}`}>
        {!data && 'No Available Data'}
        {data && !showAllHistory && (
          <Line data={getData(data, movedData)} options={getOptions()} />
        )}
        {data && showAllHistory && (
          <Line data={getAllHistoryData(allHistory)} options={getOptions()} />
        )}
      </div>
    </div>
  );
}
