import { addYears, format } from 'date-fns';
import { FormApi } from 'final-form';
import React, { useState, useEffect } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaMoneyCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import PaymentsApi from '../../Api/PaymentsApi';
import { CheckSaveObj } from '../../ApiTypes/CheckSaveObj';
import { CheckSearchRequest } from '../../ApiTypes/CheckSearchRequest';
import { DuplicateCheckRequest } from '../../ApiTypes/DuplicateCheckRequest';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { VoidCheckRequest } from '../../ApiTypes/VoidCheckRequest';
import { useAppSelector } from '../../Reducers/Store';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import PageScaffold from '../PageScaffold/PageScaffold';

import styles from './index.module.css';
import VCheckExplanation from './VCheckExplanation';
import VCheckTable from './VCheckTable';

export default function Checks() {
  const { insuranceCompanies } = useAppSelector((state) => state.reference);
  const [checks, setChecks] = useState<CheckSaveObj[]>([]);
  const [selectedChecks, setSelectedChecks] = useState<CheckSaveObj[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [showExplanation, setShowExplanation] = useState<boolean>(false);
  const [performAction, setPerformAction] = useState<string>('');

  let formInstance: FormApi<CheckSearchRequest, Partial<CheckSearchRequest>>;

  useEffect(() => {
    updateChecksWithNullInsurCo();
  }, []);

  const updateChecksWithNullInsurCo = () => {
    PaymentsApi.updateChecksWithNullInsurCo()
      .then((res) => {
        if (res.data.success) {
          toast.info(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update checks with null insurcoid');
      });
  };

  const onSubmit = (values: CheckSearchRequest) => {
    if (
      !values.fromDate &&
      !values.toDate &&
      !values.checkNumber &&
      !values.payee
    ) {
      toast.info(
        'Please select at least one search criteria along with account'
      );
      return;
    }

    return PaymentsApi.searchChecks(values)
      .then((res) => {
        setChecks(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const voidChecks = () => {
    if (selectedChecks.length === 0) {
      toast.info('Please select the checks you wish to take action on');
      return;
    }
    if (selectedChecks.some((x) => x.status.toUpperCase() === 'V')) {
      toast.info('Cannot void a payment that has already been Voided.');
      return;
    }
    setPerformAction('void');
    setShowExplanation(true);
  };
  const stopPayment = () => {
    if (selectedChecks.length === 0) {
      toast.info('Please select the checks you wish to take action on');
      return;
    }
    if (selectedChecks.some((x) => x.status.toUpperCase() === 'V')) {
      toast.info('Cannot void a payment that has already been Voided.');
      return;
    }
    setPerformAction('stop');
    setShowExplanation(true);
  };

  const handleExplanation = (explanation: string, action: string) => {
    const request: VoidCheckRequest = {
      checkIds: selectedChecks.map((x) => x.checkid),
      explanation: explanation ?? '',
      isVoid: true,
    };
    switch (action) {
      case 'void':
        handleVoidOrStopChecks(request);
        break;
      case 'stop':
        request.isVoid = false;
        handleVoidOrStopChecks(request);
        break;
      default:
        break;
    }
  };

  const handleVoidOrStopChecks = (request: VoidCheckRequest) => {
    PaymentsApi.voidOrStopChecks(request)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          formInstance.submit();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to change check status');
      });
  };

  const handleCloseExplanation = (show: boolean) => {
    setShowExplanation(false);
    setPerformAction('');
  };

  const handleDuplicate = (check: CheckSaveObj) => {
    const insurCoId = formInstance.getState().values.insurCoId;
    const company = insuranceCompanies.find((x) => x.insurcoid === +insurCoId);

    const request: DuplicateCheckRequest = {
      accountNumber: company?.accountnumber ?? '',
      checkNumber: check.checknumber,
    };
    return PaymentsApi.duplicateCheck(request)
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          formInstance.submit();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to Duplicate checks');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaMoneyCheck className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Checks</h1>
          </div>
        </div>
      </div>
      <div>
        <RFForm
          onSubmit={onSubmit}
          initialValues={{
            toDate: format(new Date(), 'yyyy-MM-dd'),
            fromDate: format(addYears(new Date(), -1), 'yyyy-MM-dd'),
          }}
          render={({ handleSubmit, form, values, submitting }) => {
            formInstance = form;
            return (
              <Form onSubmit={handleSubmit}>
                <div className='d-flex align-items-center justify-content-center gap1Rem'>
                  <Field
                    name='insurCoId'
                    label='Account'
                    options={[
                      ...insuranceCompanies
                        .map((x) => x)
                        .sort((a, b) => +a.accountnumber! - +b.accountnumber!),
                    ]}
                    optionMethod={(options: InsuranceCompany[]) =>
                      options.map((o) => (
                        <option key={o.insurcoid} value={o.insurcoid}>
                          {`${o.accountnumber} - ${o.shortname}`}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='fromDate'
                    type='text'
                    label='Date From'
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                    startOfDay={true}
                  />
                  <Field
                    name='toDate'
                    type='text'
                    label='To Date'
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                    endOfDay={true}
                  />
                  <Field
                    name='payee'
                    type='text'
                    label='Payee'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='checkNumber'
                    type='text'
                    label='Check #'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='includeManual'
                    label='Include Manual Checks'
                    type='checkbox'
                    component={FieldBSRenderCheckbox}
                  />
                </div>
                <div className='d-flex align-items-center justify-content-end gap1Rem mb-3 me-5'>
                  <Button type='submit' variant='primary' size='sm'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Search'
                    )}
                  </Button>
                  <Button
                    type='button'
                    variant='outline-primary'
                    size='sm'
                    onClick={() => {
                      form.restart();
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </Form>
            );
          }}
        />
      </div>
      <div className='d-flex justify-content-center align-items-center gap1Rem mb-3'>
        <Button
          type='button'
          variant='outline-danger'
          size='sm'
          onClick={() => {
            voidChecks();
          }}
        >
          Void
        </Button>
        <Button
          type='button'
          variant='outline-danger'
          size='sm'
          onClick={() => {
            stopPayment();
          }}
        >
          Stop Payment
        </Button>
      </div>
      <VCheckTable
        data={checks}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        setSelectedChecks={setSelectedChecks}
        handleDuplicate={handleDuplicate}
      />
      <VCheckExplanation
        show={showExplanation}
        setShow={handleCloseExplanation}
        numberOfChecks={selectedChecks.length}
        action={performAction}
        submitFunction={handleExplanation}
      />
    </PageScaffold>
  );
}
