import accounting from 'accounting';
import { format } from 'date-fns';
import queryString from 'query-string';
import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Modal } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaEdit } from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmployerApi from '../../Api/EmployerApi';
import EmployerPolicyApi from '../../Api/EmployerPolicyApi';
import ExcessCarrierApi from '../../Api/ExcessCarrierApi';
import { EmployerPolicy } from '../../ApiTypes/EmployerPolicy';
import { ExcessCarrier } from '../../ApiTypes/ExcessCarrier';
import { ExcessCarrierPolicy } from '../../ApiTypes/ExcessCarrierPolicy';
import { parseDatesForServer } from '../../Utils';
import { centsValidation } from '../../Utils/FieldValidation';
import { formatNumbers, cleanMoney } from '../../Utils/InputFormatters';
import BlankModal from '../Common/BlankModal';
import BSControlledSelect from '../Common/BSControlledSelect';
import BSRenderText from '../Common/BSRenderText';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import PageScaffold from '../PageScaffold/PageScaffold';
import PolicyClassCodesModal from '../PolicyClassCodes/PolicyClassCodesModal';
import AddEditExcessPolicy from './AddEditExcessPolicy';
import styles from './index.module.css';

type addEditExcessType = {
  excessCarrierId: number;
  excessPolicyId: number;
  secondary: boolean;
};

interface EmployerPolicyFormType extends EmployerPolicy {
  excessCarrierId: number | null;
  secondaryExcessCarrierId: number | null;
}

export default function AddEditPolicy() {
  let location = useLocation();
  const { policyId } = useParams();
  let navigate = useNavigate();
  const [excessCarriers, setExcessCarriers] = useState<ExcessCarrier[]>([]);
  const [excessCarrierPolicies, setExcessCarrierPolicies] = useState<
    ExcessCarrierPolicy[]
  >([]);
  const [secondaryExcessCarrierPolicies, setSecondaryExcessCarrierPolicies] =
    useState<ExcessCarrierPolicy[]>([]);

  const [policy, setPolicy] = useState<EmployerPolicy | null>(null);

  const [excessCarrierPolicy, setExcessCarrierPolicy] =
    useState<ExcessCarrierPolicy | null>(null);

  const [secondaryExcessCarrierPolicy, setSecondaryExcessCarrierPolicy] =
    useState<ExcessCarrierPolicy | null>(null);

  const [showExcessPolicy, setShowExcessPolicy] = useState<boolean>(false);
  const [excessModalData, setExcessModalData] = useState<addEditExcessType>({
    excessCarrierId: 0,
    excessPolicyId: 0,
    secondary: false,
  });

  const [showRolloverWarning, setShowRolloverWarning] =
    useState<boolean>(false);

  const [showClassCodes, setShowClassCodes] = useState<boolean>(false);

  let search = queryString.parse(location.search);

  useEffect(() => {
    getExcessCarriers();
  }, []);

  useEffect(() => {
    getPolicy();
  }, [policyId]);

  let now = new Date();

  const getPolicy = () => {
    if (policyId && policyId !== 'new') {
      EmployerPolicyApi.getById(+policyId)
        .then((res) => {
          setPolicy(res.data);
          if (res.data) {
            if (res.data.excessPolicy?.excessCarrierId) {
              setExcessCarrierPolicy(res.data.excessPolicy);
              getExcessCarrierPolicies(res.data.excessPolicy?.excessCarrierId);
            } else {
              setExcessCarrierPolicy(null);
            }
            if (res.data.secondaryExcessPolicy?.excessCarrierId) {
              setSecondaryExcessCarrierPolicy(res.data.secondaryExcessPolicy);
              getSecondaryExcessCarrierPolicies(
                res.data.secondaryExcessPolicy.excessCarrierId
              );
            } else {
              setSecondaryExcessCarrierPolicy(null);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get Policy');
        });
    } else {
      setPolicy(null);
    }
  };

  const getExcessCarriers = () => {
    ExcessCarrierApi.list()
      .then((res) => {
        setExcessCarriers(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get Excess Carriers');
      });
  };

  const getExcessCarrierPolicies = (id: number) => {
    if (id) {
      ExcessCarrierApi.getForCarrier(id)
        .then((res) => {
          setExcessCarrierPolicies(res.data);
        })
        .catch((err) => {
          console.log(err);
          setExcessCarrierPolicies([]);
          toast.error('Failed to get excess carrier policies');
        });
    } else {
      setExcessCarrierPolicies([]);
    }
  };
  const getSecondaryExcessCarrierPolicies = (id: number) => {
    if (id) {
      ExcessCarrierApi.getForCarrier(id)
        .then((res) => {
          setSecondaryExcessCarrierPolicies(res.data);
        })
        .catch((err) => {
          console.log(err);
          setSecondaryExcessCarrierPolicies([]);
          toast.error('Failed to get secondary excess carrier policies');
        });
    } else {
      setSecondaryExcessCarrierPolicies([]);
    }
  };

  const handleExcessPolicyChange = (id: number) => {
    if (id && excessCarrierPolicies.length > 0) {
      const newExcessPolicy = excessCarrierPolicies.find(
        (x) => x.excessCarrierPolicyId == id
      );
      setExcessCarrierPolicy(newExcessPolicy ?? null);
    }
  };

  const handleSecondaryExcessPolicyChange = (id: number) => {
    if (id && secondaryExcessCarrierPolicies.length > 0) {
      const newExcessPolicy = secondaryExcessCarrierPolicies.find(
        (x) => x.excessCarrierPolicyId == id
      );
      setSecondaryExcessCarrierPolicy(newExcessPolicy ?? null);
    }
  };

  const onSubmit = (values: EmployerPolicyFormType) => {
    if (values?.policyId) {
      return updatePolicy(values);
    }
    return addPolicy(values);
  };

  const updatePolicy = (values: EmployerPolicy) => {
    const updated = {
      ...values,
      excessPolicyId: excessCarrierPolicy?.excessCarrierPolicyId ?? null,
      secondaryExcessPolicyId: values.needsSecondaryExcess
        ? secondaryExcessCarrierPolicy?.excessCarrierPolicyId ?? null
        : null,
    };
    return EmployerPolicyApi.updatePolicy(updated as EmployerPolicy)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          if (policyId === 'new') {
            navigate(`/policies/${values.policyId}`);
          } else {
            getPolicy();
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update policy');
      });
  };

  const addPolicy = (values: EmployerPolicy) => {
    const updated = {
      ...values,
      excessPolicyId: excessCarrierPolicy?.excessCarrierPolicyId ?? null,
      secondaryExcessPolicyId: values?.needsSecondaryExcess
        ? secondaryExcessCarrierPolicy?.excessCarrierId ?? null
        : null,
    } as EmployerPolicy;
    return EmployerApi.createEmployerPolicy(updated)
      .then((res) => {
        if (res.data.success) {
          values.policyId = +res.data.affectedEntityIdentifier;
          return updatePolicy(values);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create new policy');
      });
  };

  const handleDelete = () => {
    if (policyId && policyId !== 'new') {
      EmployerPolicyApi.deletePolicy(+policyId)
        .then((res) => {
          if (res.data.success) {
            toast.success('Deleted');
            navigate(-1);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to delete');
        });
    }
  };

  const handleRollover = () => {
    setShowRolloverWarning(false);
    if (policyId && policyId !== 'new') {
      EmployerPolicyApi.rolloverPolicy(+policyId)
        .then((res) => {
          if (res.data.success) {
            toast.success('Rolled!');
            getPolicy();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to roll over');
        });
    }
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaEdit className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Employer Policy</h1>
          </div>
        </div>
      </div>
      <RFForm
        onSubmit={onSubmit}
        initialValues={
          policy
            ? {
                ...policy,
                excessCarrierId: policy?.excessPolicy?.excessCarrierId,
                secondaryExcessCarrierId:
                  policy?.secondaryExcessPolicy?.excessCarrierId,
              }
            : {
                treatyYear: now.getFullYear(),
                employerId: search?.employerId,
                startDate: format(
                  new Date(now.getFullYear(), 0, 1),
                  'yyyy-MM-dd'
                ),
                endDate: format(
                  new Date(now.getFullYear() + 1, 0, 1),
                  'yyyy-MM-dd'
                ),
              }
        }
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className={`${styles.flex3}`}>
                <div>
                  <p className='fw-bold text-center'>Policy Info</p>
                  <Field
                    name='policyNumber'
                    type='text'
                    label='Policy Number'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='employerId'
                    type='text'
                    label='Employer Id'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='treatyYear'
                    type='text'
                    label='Treaty Year'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='startDate'
                    label='Start Date'
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                  />
                  <Field
                    name='endDate'
                    label='End Date'
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                  />
                  <Field
                    name='premium'
                    label='Premium'
                    type='text'
                    format={formatNumbers}
                    parse={cleanMoney}
                    validate={centsValidation}
                    component={FieldBSRenderMoney}
                  />
                  <Field
                    name='deductible'
                    label='Deductible'
                    type='text'
                    format={formatNumbers}
                    parse={cleanMoney}
                    validate={centsValidation}
                    component={FieldBSRenderMoney}
                  />
                </div>
                <div>
                  <div className='d-flex justify-content-around align-items-center'>
                    <p className='fw-bold'>Excess Policy Info</p>
                    {values.excessPolicyId && (
                      <Button
                        type='button'
                        variant='outline-primary'
                        size='sm'
                        onClick={() => {
                          setExcessModalData({
                            excessCarrierId:
                              excessCarrierPolicy?.excessCarrierId ?? 0,
                            excessPolicyId:
                              excessCarrierPolicy?.excessCarrierPolicyId ?? 0,
                            secondary: false,
                          });

                          setShowExcessPolicy(true);
                        }}
                      >
                        <FaEdit />
                      </Button>
                    )}
                  </div>
                  <Field
                    name='excessCarrierId'
                    label='Excess Carrier'
                    options={excessCarriers}
                    onChange={getExcessCarrierPolicies}
                    optionMethod={(options: ExcessCarrier[]) =>
                      options.map((o) => (
                        <option
                          key={o.excessCarrierId}
                          value={o.excessCarrierId}
                        >
                          {o.name}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='excessPolicyId'
                    label='Policy Number'
                    options={excessCarrierPolicies}
                    onChange={handleExcessPolicyChange}
                    optionMethod={(options: ExcessCarrierPolicy[]) =>
                      options.map((o) => (
                        <option
                          key={o.excessCarrierPolicyId}
                          value={o.excessCarrierPolicyId}
                        >
                          {o.carrierPolicyNo}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  />
                  <BSRenderText
                    name='excessPolicyDefaultSir'
                    type='text'
                    label='SIR'
                    readOnly
                    value={accounting.formatMoney(
                      excessCarrierPolicy?.defaultSir ?? 0
                    )}
                  />
                  <BSRenderText
                    name='excessPolicyCorridorDeductaible'
                    type='text'
                    label='Corridor Amount'
                    readOnly
                    value={accounting.formatMoney(
                      excessCarrierPolicy?.corridorDeductaible ?? 0
                    )}
                  />
                  <BSRenderText
                    name='excessPolicyTreatyYear'
                    type='text'
                    label='Treaty Year'
                    readOnly
                    value={excessCarrierPolicy?.treatyYear?.toString() ?? ''}
                  />
                  <BSRenderText
                    name='excessPolicyStartDate'
                    type='text'
                    label='Start Date'
                    readOnly
                    value={
                      excessCarrierPolicy?.startDate
                        ? format(
                            new Date(excessCarrierPolicy?.startDate ?? 0),
                            'MM/dd/yyyy'
                          )
                        : ''
                    }
                  />
                  <BSRenderText
                    name='excessPolicyEndDate'
                    type='text'
                    label='End Date'
                    readOnly
                    value={
                      excessCarrierPolicy?.endDate
                        ? format(
                            new Date(excessCarrierPolicy?.endDate ?? 0),
                            'MM/dd/yyyy'
                          )
                        : ''
                    }
                  />
                </div>
                <div>
                  <div className='d-flex justify-content-around align-items-center'>
                    <p className='fw-bold'>Secondary Info</p>
                    {values?.secondaryExcessPolicyId && (
                      <Button
                        type='button'
                        variant='outline-primary'
                        size='sm'
                        onClick={() => {
                          setExcessModalData({
                            excessCarrierId:
                              secondaryExcessCarrierPolicy?.excessCarrierId ??
                              0,
                            excessPolicyId:
                              secondaryExcessCarrierPolicy?.excessCarrierPolicyId ??
                              0,
                            secondary: true,
                          });

                          setShowExcessPolicy(true);
                        }}
                      >
                        <FaEdit />
                      </Button>
                    )}
                  </div>
                  <Field
                    name='secondaryExcessCarrierId'
                    label='Excess Carrier'
                    options={excessCarriers}
                    disabled={values?.needsSecondaryExcess !== true}
                    onChange={getSecondaryExcessCarrierPolicies}
                    optionMethod={(options: ExcessCarrier[]) =>
                      options.map((o) => (
                        <option
                          key={o.excessCarrierId}
                          value={o.excessCarrierId}
                        >
                          {o.name}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='secondaryExcessPolicyId'
                    label='Policy Number'
                    options={secondaryExcessCarrierPolicies}
                    disabled={values?.needsSecondaryExcess !== true}
                    onChange={handleSecondaryExcessPolicyChange}
                    optionMethod={(options: ExcessCarrierPolicy[]) =>
                      options.map((o) => (
                        <option
                          key={o.excessCarrierPolicyId}
                          value={o.excessCarrierPolicyId}
                        >
                          {o.carrierPolicyNo}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  />
                  <BSRenderText
                    name='secondaryExcessPolicyDefaultSir'
                    type='text'
                    label='SIR'
                    readOnly
                    value={accounting.formatMoney(
                      secondaryExcessCarrierPolicy?.defaultSir ?? 0
                    )}
                  />
                  <BSRenderText
                    name='secondaryExcessPolicyCorridorDeductaible'
                    type='text'
                    label='Corridor Amount'
                    readOnly
                    value={accounting.formatMoney(
                      secondaryExcessCarrierPolicy?.corridorDeductaible ?? 0
                    )}
                  />
                  <BSRenderText
                    name='secondaryExcessPolicyTreatyYear'
                    type='text'
                    label='Treaty Year'
                    readOnly
                    value={
                      secondaryExcessCarrierPolicy?.treatyYear?.toString() ?? ''
                    }
                  />
                  <BSRenderText
                    name='secondaryExcessPolicyStartDate'
                    type='text'
                    label='Start Date'
                    readOnly
                    value={
                      secondaryExcessCarrierPolicy?.startDate
                        ? format(
                            new Date(
                              secondaryExcessCarrierPolicy?.startDate ?? 0
                            ),
                            'MM/dd/yyyy'
                          )
                        : ''
                    }
                  />
                  <BSRenderText
                    name='secondaryExcessPolicyEndDate'
                    type='text'
                    label='End Date'
                    readOnly
                    value={
                      secondaryExcessCarrierPolicy?.endDate
                        ? format(
                            new Date(
                              secondaryExcessCarrierPolicy?.endDate ?? 0
                            ),
                            'MM/dd/yyyy'
                          )
                        : ''
                    }
                  />
                </div>
              </div>
              <div className='d-flex justify-content-center align-items-center my-3'>
                <Field
                  name='needsSecondaryExcess'
                  type='checkbox'
                  label='Need Secondary Excess Policy'
                  checked={values?.needsSecondaryExcess === true}
                  component={FieldBSRenderCheckbox}
                />
              </div>
              <div className='d-flex justify-content-around align-items-center py-3'>
                <Button
                  type='button'
                  variant='danger'
                  size='sm'
                  disabled={!policyId || policyId === 'new'}
                  onClick={handleDelete}
                >
                  Delete Policy
                </Button>
                <Button
                  type='button'
                  variant='outline-primary'
                  size='sm'
                  disabled={!policyId || policyId === 'new'}
                  onClick={() => setShowClassCodes(true)}
                >
                  Policy Class Codes
                </Button>
                <Button
                  type='button'
                  variant='outline-primary'
                  size='sm'
                  disabled={!policyId || policyId === 'new'}
                  onClick={() => setShowRolloverWarning(true)}
                >
                  Roll Over Policy
                </Button>
                <Button type='submit' variant='primary' size='sm'>
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      />
      <>
        {showExcessPolicy && (
          <AddEditExcessPolicy
            show={showExcessPolicy}
            setShow={setShowExcessPolicy}
            excessCarrierId={excessModalData.excessCarrierId}
            excessPolicyId={excessModalData.excessPolicyId}
            refreshData={getPolicy}
            setNull={() => {}}
          />
        )}
      </>
      <BlankModal
        show={showRolloverWarning}
        setShow={setShowRolloverWarning}
        showCancel={false}
        message={
          <div>
            <p className='fs-2'>
              Are you sure you want to Roll over this policy?
            </p>
            <div className='d-flex justify-content-center py-3'>
              <Button
                type='button'
                size='sm'
                variant='outline-danger'
                onClick={handleRollover}
              >
                Confirm
              </Button>
            </div>
          </div>
        }
      />
      <>
        {showClassCodes && (
          <PolicyClassCodesModal
            show={showClassCodes}
            setShow={setShowClassCodes}
            policyId={+(policyId ?? 0)}
            isExcess={false}
          />
        )}
      </>
    </PageScaffold>
  );
}
