import accounting from 'accounting';
import { FormApi } from 'final-form';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { Field } from 'react-final-form';
import { VBillFee } from '../../ApiTypes/VBillFee';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import { FeeWizardFormType } from './FeeWizardFormType';
import styles from './index.module.css';

export default function Page4({
  formInstance,
  values,
  fees,
}: {
  formInstance: FormApi<FeeWizardFormType, Partial<FeeWizardFormType>>;
  values: FeeWizardFormType;
  fees: VBillFee[];
}) {
  function onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }

  const total = fees.reduce((acc, curr) => {
    return (acc += curr.brfees ?? 0);
  }, 0);
  const totalCredits = fees.reduce((acc, curr) => {
    if ((curr.brfees ?? 0) < 0) {
      acc += curr.brfees!;
    }
    return acc;
  }, 0);
  const uniqueClaimNumbers = fees.map((x) => x.claimNumber).filter(onlyUnique);

  return (
    <div>
      <div>
        <Alert variant='info'>
          <Alert.Heading>Finish Billing Process</Alert.Heading>
        </Alert>
      </div>
      <h4 className='text-center'>Summary</h4>
      <div className={`${styles.grid2}`}>
        <p>Total Amount:</p>
        <p>{accounting.formatMoney(total)}</p>
        <p>Claim Count:</p>
        <p>{uniqueClaimNumbers.length}</p>
        <p>Transaction Count:</p>
        <p>{fees.length}</p>
        <p>Credit Amount:</p>
        <p>{accounting.formatMoney(totalCredits)}</p>
      </div>
      <div className='d-flex justify-content-center'>
        <div style={{ width: '300px' }}>
          <Field
            name='notes'
            type='text'
            label='Notes'
            component={FieldBSRenderText}
          />
        </div>
      </div>
      <div className='d-flex justify-content-center'>
        <p>
          Amount to be Billed: {accounting.formatMoney(total - totalCredits)}
        </p>
      </div>
      <hr />
      <p className='text-center fw-bold'>Press submit to finish</p>
    </div>
  );
}
