import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class ApprovalCodeApi {
  static getCode() {
    return axios.get<ResponseApiModel>(
      `${API_URL}/api/approvalcodes`,
      getAuthHeader()
    );
  }
  static updateCode() {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/approvalcodes`,
      {},
      getAuthHeader()
    );
  }
}

export default ApprovalCodeApi;
