import React, { useEffect, useState } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaCheck, FaEdit } from 'react-icons/fa';
import { Adjuster } from '../../ApiTypes/Adjuster';
import { Employer } from '../../ApiTypes/Employer';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { State } from '../../ApiTypes/State';
import { useAppSelector } from '../../Reducers/Store';
import { requiredField, zipLength } from '../../Utils/FieldValidation';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import styles from './index.module.css';
import NewClaimEmailDocsTable from '../NewClaimEmailDocs/NewClaimEmailDocsTable';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function AddEditEmployer({
  show,
  setShow,
  accountNumber,
  employer,
  handleEmployerSubmit,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  accountNumber: string;
  employer: Employer | null;
  handleEmployerSubmit: (values: Employer) => Promise<void>;
}) {
  const { adjusters, states, insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );
  const [quillValue, setQuillValue] = useState('');

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ['right', 'center', 'justify'] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      [{ color: ['red', '#785412'] }],
      [{ background: ['red', '#785412'] }],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'image',
    'background',
    'align',
    'size',
    'font',
  ];

  useEffect(() => {
    if (quillValue === '' && employer?.emailBodyOnClaimCreate) {
      setQuillValue(employer.emailBodyOnClaimCreate);
    }
  }, [employer]);

  const onSubmit = (values: Employer) => {
    values.emailBodyOnClaimCreate = quillValue;
    return handleEmployerSubmit(values);
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
        setQuillValue('');
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='AddEditEmployer-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='AddEditEmployer-modal'>
          <FaEdit className='pe-1' /> Employer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={
              employer ? employer : { accountNumber: accountNumber }
            }
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className={styles.flex3}>
                    <Field
                      name='accountNumber'
                      label='Account'
                      options={insuranceCompaniesForUser}
                      optionMethod={(options: InsuranceCompany[]) =>
                        options.map((o) => (
                          <option
                            key={o.accountnumber}
                            value={o.accountnumber!}
                          >
                            {`${o.accountnumber} - ${o.shortname}`}
                          </option>
                        ))
                      }
                      validate={requiredField}
                      component={FieldBSRenderSelect}
                    />
                    <Field
                      name='name'
                      type='text'
                      label='Name'
                      validate={requiredField}
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='taxid'
                      type='text'
                      label='Tax Id'
                      component={FieldBSRenderText}
                    />
                  </div>
                  <div>
                    <Field
                      name='address'
                      type='text'
                      label='Address'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='address2'
                      type='text'
                      label='Address 2'
                      component={FieldBSRenderText}
                    />
                  </div>
                  <div className={styles.flex3}>
                    <Field
                      name='city'
                      type='text'
                      label='City'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='state'
                      label='State'
                      options={states}
                      optionMethod={(options: State[]) =>
                        options.map((o) => (
                          <option key={o.stateAbbr} value={o.stateAbbr}>
                            {o.stateAbbr}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                    <Field
                      name='zip'
                      type='text'
                      label='Zip'
                      validate={zipLength}
                      component={FieldBSRenderText}
                      maxLength={5}
                    />
                  </div>
                  <div className={styles.flex3}>
                    <Field
                      name='clientEmployerId'
                      type='text'
                      label='Client Employer Id'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='uwCustid'
                      type='text'
                      label='Uw Customer Id'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='defaultNatureOfBusiness'
                      type='text'
                      label='Default Nature of Business'
                      component={FieldBSRenderText}
                    />
                  </div>
                  <div className={styles.flex3}>
                    <Field
                      name='sbwcid'
                      type='text'
                      label='SBWC Id'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='wc1approvalEmails'
                      type='text'
                      label='Approval Emails'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='emailToNotify'
                      type='text'
                      label='Email To Notify'
                      component={FieldBSRenderText}
                    />
                  </div>
                  <div className={`${styles.flex3} pb-3`}>
                    <Field
                      name='sendEmailOnDeny'
                      type='checkbox'
                      label='Send Email on Deny'
                      checked={values?.sendEmailOnDeny === true}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='sendEmailOnCreate'
                      type='checkbox'
                      label='Send Email on Create'
                      checked={values?.sendEmailOnCreate === true}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='sendEmailOnOpen'
                      type='checkbox'
                      label='Send Email on Open'
                      checked={values?.sendEmailOnOpen === true}
                      component={FieldBSRenderCheckbox}
                    />
                  </div>
                  <div className={styles.flex2}>
                    <Field
                      name='adjusterIdLt'
                      label='Adjuster'
                      options={adjusters.filter((a) => a.active)}
                      optionMethod={(options: Adjuster[]) =>
                        options.map((o) => (
                          <option key={o.adjusterId} value={o.adjusterId}>
                            {o.lastName}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                    <Field
                      name='adjusterIdMo'
                      label='MO Adjuster'
                      options={adjusters.filter((a) => a.active)}
                      optionMethod={(options: Adjuster[]) =>
                        options.map((o) => (
                          <option key={o.adjusterId} value={o.adjusterId}>
                            {o.lastName}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                  </div>
                  <Field
                    name='notes'
                    label='Notes'
                    rows={3}
                    component={FieldBSRenderTextArea}
                  />
                  <hr />
                  <h5>Claim Creation Email</h5>

                  <ReactQuill
                    theme='snow'
                    value={quillValue}
                    onChange={setQuillValue}
                    modules={modules}
                    formats={formats}
                  />

                  {employer?.employerId && (
                    <NewClaimEmailDocsTable
                      inscoId={0}
                      employerId={employer?.employerId}
                    />
                  )}
                  <div className='d-flex justify-content-around align-items-center py-3'>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type='submit' size='sm' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
