import React from 'react';
import { Field } from 'react-final-form';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';

import styles from './index.module.css';

export default function WC1Treatment({ values }: { values: any }) {
  return (
    <div className='d-flex justify-content-center'>
      <div className='w-50 pe-2'>
        <Field
          name='treatingPhysician'
          label='Treating Physician - Name and Address (Optional)'
          rows={4}
          component={FieldBSRenderTextArea}
        />
        <p className='text-center'>Initial Treatment Given (Optional)</p>
        <div className='mb-3'>
          <div
            className={`d-flex justify-content-start  ${styles.flexGap1rem}`}
          >
            <Field
              name='initialTreatmentGiven'
              type='radio'
              label='No Initial Treatment'
              value={0}
              checked={values?.initialTreatmentGiven?.toString() === '0'}
              component={FieldBSRenderCheckbox}
            />
            <Field
              name='initialTreatmentGiven'
              type='radio'
              label='Minor By Employer'
              value={1}
              checked={values?.initialTreatmentGiven?.toString() === '1'}
              component={FieldBSRenderCheckbox}
            />
            <Field
              name='initialTreatmentGiven'
              type='radio'
              label='Minor: Clinical/Hospital'
              value={2}
              checked={values?.initialTreatmentGiven?.toString() === '2'}
              component={FieldBSRenderCheckbox}
            />
          </div>
          <div
            className={`d-flex justify-content-start  ${styles.flexGap1rem}`}
          >
            <Field
              name='initialTreatmentGiven'
              type='radio'
              label='Emergency Room'
              value={3}
              checked={values?.initialTreatmentGiven?.toString() === '3'}
              component={FieldBSRenderCheckbox}
            />
            <Field
              name='initialTreatmentGiven'
              type='radio'
              label='Hospitalized > 24 hrs'
              value={4}
              checked={values?.initialTreatmentGiven?.toString() === '4'}
              component={FieldBSRenderCheckbox}
            />
          </div>
        </div>
        <Field
          name='hospitalTreatingFacility'
          label='Hospital/Treating Facility - Name and Address (Optional)'
          rows={4}
          component={FieldBSRenderTextArea}
        />
      </div>
    </div>
  );
}
