import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { Attorney } from '../ApiTypes/Attorney';
import { AttorneyToClaimant } from '../ApiTypes/AttorneyToClaimant';
import { DispositionType } from '../ApiTypes/DispositionType';
import { EdiTransaction } from '../ApiTypes/EdiTransaction';
import { Hearing } from '../ApiTypes/Hearing';
import { LawFirm } from '../ApiTypes/LawFirm';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { Settlement } from '../ApiTypes/Settlement';
import { SettlementPaymentDetail } from '../ApiTypes/SettlementPaymentDetail';
import { UpdateSettlementStatusRequest } from '../ApiTypes/UpdateSettlementStatusRequest';
import { EmailDocumentsRequest } from '../ApiTypes/EmailDocumentsRequest';

class LegalApi {
  static getAttorneysForClaim(claimNumber: string) {
    return axios.get<AttorneyToClaimant[]>(
      `${API_URL}/api/claims/${claimNumber}/attorneys`,
      getAuthHeader()
    );
  }
  static deleteAttorneyToClaimantRecord(
    attorneyToClaimantId: number,
    claimNumber: string
  ) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/attorneys/${attorneyToClaimantId}`,
      getAuthHeader()
    );
  }
  static assignAttorneyToClaimant(attorneyToClaimant: AttorneyToClaimant) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${attorneyToClaimant.claimNo}/attorneys`,
      attorneyToClaimant,
      getAuthHeader()
    );
  }
  static updateAttorneyToClaimant(attorneyToClaimant: AttorneyToClaimant) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${attorneyToClaimant.claimNo}/attorneys/${attorneyToClaimant.attorneyToClaimantId}`,
      attorneyToClaimant,
      getAuthHeader()
    );
  }
  static createAttorney(attorney: Attorney) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/attorneys`,
      attorney,
      getAuthHeader()
    );
  }
  static updateAttorney(attorney: Attorney) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/attorneys/${attorney.attorneyId}`,
      attorney,
      getAuthHeader()
    );
  }
  static getLawFirmById(id: number) {
    return axios.get<LawFirm>(`${API_URL}/api/lawfirms/${id}`, getAuthHeader());
  }
  static createLawFirm(lawFirm: LawFirm) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/lawfirms`,
      lawFirm,
      getAuthHeader()
    );
  }
  static updateLawFirm(lawFirm: LawFirm) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/lawfirms/${lawFirm.firmId}`,
      lawFirm,
      getAuthHeader()
    );
  }
  static getAttorneysForFirm(firmId: number) {
    return axios.get<Attorney[]>(
      `${API_URL}/api/lawfirms/${firmId}/attorneys`,
      getAuthHeader()
    );
  }
  static getSettlementsForClaim(claimNumber: string) {
    return axios.get<Settlement[]>(
      `${API_URL}/api/claims/${claimNumber}/settlements`,
      getAuthHeader()
    );
  }
  static getSettlementById(claimNumber: string, settlementId: number) {
    return axios.get<Settlement>(
      `${API_URL}/api/claims/${claimNumber}/settlements/${settlementId}`,
      getAuthHeader()
    );
  }
  static createSettlement(settlement: Settlement, claimNumber: string) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/settlements`,
      settlement,
      getAuthHeader()
    );
  }
  static updateSettlement(settlement: Settlement, claimNumber: string) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/settlements/${settlement.settlementId}`,
      settlement,
      getAuthHeader()
    );
  }
  static deleteSettlement(settlementId: number, claimNumber: string) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/settlements/${settlementId}`,
      getAuthHeader()
    );
  }
  static createSettlementPaymentDetail(
    settlementPaymentDetail: SettlementPaymentDetail,
    claimNumber: string
  ) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/settlements/${settlementPaymentDetail.settlementId}/settlementpaymentdetail`,
      settlementPaymentDetail,
      getAuthHeader()
    );
  }
  static updateSettlementPaymentDetail(
    settlementPaymentDetail: SettlementPaymentDetail,
    claimNumber: string
  ) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/settlements/${settlementPaymentDetail.settlementId}/settlementpaymentdetail/${settlementPaymentDetail.id}`,
      settlementPaymentDetail,
      getAuthHeader()
    );
  }
  static getHearingsForClaim(claimNumber: string) {
    return axios.get<Hearing[]>(
      `${API_URL}/api/claims/${claimNumber}/hearings`,
      getAuthHeader()
    );
  }
  static getHearingById(claimNumber: string, hearingId: number) {
    return axios.get<Hearing>(
      `${API_URL}/api/claims/${claimNumber}/hearings/${hearingId}`,
      getAuthHeader()
    );
  }
  static createHearing(hearing: Hearing, claimNumber: string) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/hearings`,
      hearing,
      getAuthHeader()
    );
  }
  static updateHearing(hearing: Hearing, claimNumber: string) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/hearings/${hearing.hearingId}`,
      hearing,
      getAuthHeader()
    );
  }
  static deleteHearing(hearingId: number, claimNumber: string) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/hearings/${hearingId}`,
      getAuthHeader()
    );
  }
  static createDispositionType(type: DispositionType) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/dispositiontypes`,
      type,
      getAuthHeader()
    );
  }
  static getSettlementsToApprove(insurCoId: number, status: number) {
    return axios.get<Settlement[]>(
      `${API_URL}/api/settlements?insurCoId=${insurCoId}&status=${status}`,
      getAuthHeader()
    );
  }
  static approveSettlement(settlementId: number) {
    return axios.post<EmailDocumentsRequest>(
      `${API_URL}/api/settlements/${settlementId}/approve`,
      {},
      getAuthHeader()
    );
  }
  static updateSettlementStatus(request: UpdateSettlementStatusRequest) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/settlements/${request.settlementId}/status`,
      request,
      getAuthHeader()
    );
  }
}

export default LegalApi;
