import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ClaimNote } from '../ApiTypes/ClaimNote';
import { ClaimNoteMigrationRequest } from '../ApiTypes/ClaimNoteMigrationRequest';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class ClaimNotesApi {
  static getClaimNotesByClaimNumber(claimNo: string) {
    return axios.get<ClaimNote[]>(
      `${API_URL}/api/claims/${claimNo}/claimnotes`,
      getAuthHeader()
    );
  }
  static getDiaryNotesByClaimNumber(claimNo: string, includeComplete: boolean) {
    return axios.get<ClaimNote[]>(
      `${API_URL}/api/claims/${claimNo}/diaries?includeComplete=${includeComplete}`,
      getAuthHeader()
    );
  }
  static createClaimNote(note: ClaimNote) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claimnotes`,
      note,
      getAuthHeader()
    );
  }
  static updateClaimNote(note: ClaimNote) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claimnotes/${note.noteId}`,
      note,
      getAuthHeader()
    );
  }
  static deleteClaimNote(id: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/claimnotes/${id}`,
      getAuthHeader()
    );
  }
  static moveNoteToNewClaim(request: ClaimNoteMigrationRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claimnotes/moveclaim`,
      request,
      getAuthHeader()
    );
  }
}
export default ClaimNotesApi;
