import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import styles from './index.module.css';
import { ClaimSearchResult } from '../../ApiTypes/ClaimSearchResult';
import ClaimSearchTable from '../TopToolbar/ClaimSearch/ClaimSearchTable';
import { exportHandler } from './ClaimResultsExportHandler';
import ExportApi from '../../Api/ExportApi';
import { downloadExcel } from '../../Utils';

export default function ClaimResultsModal({
  show,
  setShow,
  searchResults,
  handleSelectedClaim,
}: {
  show: boolean;
  searchResults: ClaimSearchResult[];
  handleSelectedClaim: (result: ClaimSearchResult) => void;
  setShow: (show: boolean) => void;
}) {
  const exportExcel = () => {
    if (searchResults.length > 0) {
      const exportData = exportHandler(searchResults);
      exportData.fileName = `Transactions.xls`;

      return ExportApi.exportToExcel(exportData)
        .then((res) => {
          downloadExcel(res.data.fileName, res.data.file);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => setShow(false)}
      dialogClassName={styles.largeModal}
      aria-labelledby='Claim-Results-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Claim-Results-modal'>
          <FaSearch className='pe-1' /> Claim Search Results
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-center align-items-center mb-3'>
          <Button
            type='button'
            variant='outline-primary'
            size='sm'
            onClick={() => {
              exportExcel();
            }}
          >
            Export to Excel
          </Button>
        </div>
        <div>
          <ClaimSearchTable
            data={searchResults}
            getSelectedClaim={handleSelectedClaim}
            setShow={setShow}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
