import React, { useState, useEffect } from 'react';
import { Container, Modal, Form, Button } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaFilter } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import EmployerApi from '../../Api/EmployerApi';
import { Employer } from '../../ApiTypes/Employer';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import {
  AvailableReport,
  FileFormat,
  ReportRequest,
} from '../../ApiTypes/ReportRequest';
import { useAppSelector } from '../../Reducers/Store';
import { FileDownload } from '../../Types/FileDownLoad';
import { parseDatesForServer } from '../../Utils';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import { getCrystalReport } from '../DocViewModal/useDocViewModal';

import styles from './index.module.css';

export default function ReportRequestModal({
  show,
  setShow,
  reportEnumValue,
  setShowDocViewModal,
  setFileDownload,
}: {
  reportEnumValue: number;
  show: boolean;
  setShow: (show: boolean) => void;
  setShowDocViewModal: (s: boolean) => void;
  setFileDownload: (f: FileDownload | null) => void;
}) {
  const { claimNumber } = useParams();
  const { insuranceCompanies } = useAppSelector((state) => state.reference);
  const { userName } = useAppSelector((state) => state.user);

  const [employers, setEmployers] = useState<Employer[]>([]);
  const [fieldsToShow, setFieldsToShow] = useState<string[]>([]);

  useEffect(() => {
    getFieldsToShow();
  }, [reportEnumValue]);

  const getFieldsToShow = () => {
    switch (reportEnumValue) {
      case AvailableReport.ClaimListingByEmployer:
      case AvailableReport.ClaimListingByYear:
      case AvailableReport.ClaimListingByAdjuster:
      case AvailableReport.LossRunByProgramWithDetailForOpen:
      case AvailableReport.LossRunSummaryOld:
      case AvailableReport.ClaimStatusReportAllClaimsCurrent:
        setFieldsToShow(['accountNumber']);
        break;

      case AvailableReport.PaymentsByPayCodeAsOf:
      case AvailableReport.ReservesHistoryAsOf:
        setFieldsToShow(['claimNumber', 'fromDate']);
        break;

      case AvailableReport.DiaryReport:
        setFieldsToShow(['fromDate', 'toDate', 'accountNumber', 'userId']);
        break;

      case AvailableReport.CheckRegisterAllAccounts:
        setFieldsToShow(['fromDate', 'toDate']);
        break;

      case AvailableReport.SettlementReport:
      case AvailableReport.CheckRegisterNew:
      case AvailableReport.CheckRegisterIncludeSplc:
      case AvailableReport.CheckRegisterExcludeSplc:
      case AvailableReport.CheckRegisterSplcOnly:
      case AvailableReport.CheckRegisterIndOnly:
        setFieldsToShow(['accountNumber', 'fromDate', 'toDate']);
        break;

      case AvailableReport.GemcLossRun:
      case AvailableReport.LossRunDetailIncludeClosed:
        setFieldsToShow(['employerId', 'fromDate', 'toDate']);
        break;

      case AvailableReport.LossRunDetailAllEmployersAllClaimsByPolicyDate:
      case AvailableReport.LossRunDetailAllEmployersAllClaims:
      case AvailableReport.CheckRegister:
        setFieldsToShow(['insurCoId', 'fromDate', 'toDate']);
        break;
      case AvailableReport.LossRunReportByMemberInternalOld:
      case AvailableReport.LossRunReportByMemberExternalOld:
        setFieldsToShow([
          'accountNumber',
          'employerId',
          'treatyYear',
          'treatyYearTo',
        ]);
        break;
      case AvailableReport.LossRunByProgramAndDeptWithDetailForOpen:
        setFieldsToShow(['accountNumber', 'treatyYear', 'treatyYearTo']);
        break;
      default:
        setFieldsToShow([]);
        break;
    }
  };

  const onSubmit = (values: ReportRequest) => {
    const request: ReportRequest = {
      accountNumber: values.accountNumber ?? '',
      claimNumber: values.claimNumber ?? '',
      checkNumber: '',
      employerId: values.employerId ?? null,
      exportFormat: FileFormat.PortableDocFormat,
      fromDate: values.fromDate ?? null,
      insurCoId: values.insurCoId ?? null,
      report: reportEnumValue,
      severity: values.severity ?? '',
      status: values.status ?? '',
      toDate: values.toDate ?? null,
      userId: userName ?? '',
      claimsByAccountNumberAndTreatyYearCount: 0,
      claimsByEmployerAndTreatyYearCount: 0,
      treatyYear: values.treatyYear ?? null,
      treatyYearTo: values.treatyYearTo ?? null,
      genericCheckReportName: null,
      paymentBatchIds: null,
      fromName: '',
      openOnly: false,
      title: '',
    };

    getCrystalReport(request, setFileDownload, setShowDocViewModal);
    setShow(false);
    setEmployers([]);
  };

  const getEmployers = (accountNumber: string) => {
    EmployerApi.getEmployerList({ accountNumber })
      .then((res) => {
        setEmployers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
        setEmployers([]);
      }}
      aria-labelledby='Report-Request-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Report-Request-modal'>
          <FaFilter className='pe-1' /> Report Options
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              claimNumber,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  {(fieldsToShow.includes('accountNumber') ||
                    fieldsToShow.includes('employerId')) && (
                    <Field
                      name='accountNumber'
                      label='Account'
                      options={[
                        ...insuranceCompanies
                          .map((x) => x)
                          .sort(
                            (a, b) => +a.accountnumber! - +b.accountnumber!
                          ),
                      ]}
                      optionMethod={(options: InsuranceCompany[]) =>
                        options.map((o) => (
                          <option
                            key={o.accountnumber!}
                            value={o.accountnumber!}
                          >
                            {`${o.accountnumber} - ${o.shortname}`}
                          </option>
                        ))
                      }
                      onChange={getEmployers}
                      component={FieldBSRenderSelect}
                    />
                  )}
                  {fieldsToShow.includes('insurCoId') && (
                    <Field
                      name='insurCoId'
                      label='Insurance Company'
                      options={[
                        ...insuranceCompanies
                          .map((x) => x)
                          .sort(
                            (a, b) => +a.accountnumber! - +b.accountnumber!
                          ),
                      ]}
                      optionMethod={(options: InsuranceCompany[]) =>
                        options.map((o) => (
                          <option key={o.insurcoid!} value={o.insurcoid!}>
                            {`${o.accountnumber} - ${o.shortname}`}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                  )}
                  {fieldsToShow.includes('employerId') && (
                    <Field
                      name='employerId'
                      label='Employer'
                      options={employers}
                      optionMethod={(options: Employer[]) =>
                        options.map((o) => (
                          <option key={o.employerId} value={o.employerId}>
                            {o.name}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                  )}
                  {fieldsToShow.includes('claimNumber') && (
                    <Field
                      name='claimNumber'
                      type='text'
                      label='Claim #'
                      component={FieldBSRenderText}
                    />
                  )}
                  {fieldsToShow.includes('fromDate') && (
                    <Field
                      name='fromDate'
                      type='text'
                      label='From Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                  )}
                  {fieldsToShow.includes('toDate') && (
                    <Field
                      name='toDate'
                      type='text'
                      label='To Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                  )}
                  {fieldsToShow.includes('treatyYear') && (
                    <Field
                      name='treatyYear'
                      type='text'
                      label='Treaty Year'
                      component={FieldBSRenderText}
                    />
                  )}
                  {fieldsToShow.includes('treatyYearTo') && (
                    <Field
                      name='treatyYearTo'
                      type='text'
                      label='Treaty Year To'
                      component={FieldBSRenderText}
                    />
                  )}
                  <div className='d-flex justify-content-around'>
                    {fieldsToShow.includes('severity') && (
                      <fieldset>
                        <legend>Severity</legend>
                        <Field
                          name='severity'
                          type='radio'
                          label='Any'
                          value='Any'
                          checked={values.severity === 'Any'}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='severity'
                          type='radio'
                          label='LT'
                          value='LT'
                          checked={values.severity === 'LT'}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='severity'
                          type='radio'
                          label='MO'
                          value='MO'
                          checked={values.severity === 'MO'}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='severity'
                          type='radio'
                          label='AM'
                          value='AM'
                          checked={values.severity === 'AM'}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='severity'
                          type='radio'
                          label='MO/AM'
                          value='MO_AM'
                          checked={values.severity === 'MO_AM'}
                          component={FieldBSRenderCheckbox}
                        />
                      </fieldset>
                    )}
                    {fieldsToShow.includes('status') && (
                      <fieldset>
                        <legend>Status</legend>
                        <Field
                          name='status'
                          type='radio'
                          label='Any'
                          value='Any'
                          checked={values.status === 'Any'}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='status'
                          type='radio'
                          label='Open/Re-Open'
                          value='Open'
                          checked={values.status === 'Open'}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='status'
                          type='radio'
                          label='Closed'
                          value='Closed'
                          checked={values.status === 'Closed'}
                          component={FieldBSRenderCheckbox}
                        />
                      </fieldset>
                    )}
                  </div>
                  <div className='d-flex justify-content-center align-items-center gap1Rem my-3'>
                    <Button type='submit' variant='primary' size='sm'>
                      Submit
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        setShow(false);
                        setEmployers([]);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
