import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaFolderOpen } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { fetchEmployerList } from '../../Actions/EmployerActions';
import EmployerApi from '../../Api/EmployerApi';
import { Employer } from '../../ApiTypes/Employer';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import BSSelect from '../Common/BSSelect';
import PageScaffold from '../PageScaffold/PageScaffold';
import AddEditEmployer from './AddEditEmployer';
import EmployerTable from './EmployerTable';
import styles from './index.module.css';

export default function EmployerMaintenance() {
  const [selectedEmployer, setSelectedEmployer] = useState<Employer | null>(
    null
  );
  const [showEdit, setShowEdit] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { employers } = useAppSelector((state) => state.employer);
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );

  const [accountNumberValue, setAccountNumberValue] = useState<string>('');

  const [fetching, setFetching] = useState(false);

  const handleAccountChange = (accountNumber: string) => {
    setAccountNumberValue(accountNumber);
    if (accountNumber) {
      setFetching(true);
      dispatch(fetchEmployerList({ accountNumber }))
        .then((res) => setFetching(false))
        .catch((err) => setFetching(false));
    }
  };

  const handleEdit = (employer: Employer | null) => {
    setSelectedEmployer(employer);
    setShowEdit(true);
  };

  const handleEmployerSubmit = (values: Employer) => {
    if (values.employerId) {
      return update(values);
    }
    return create(values);
  };

  const create = (values: Employer) => {
    return EmployerApi.createEmployer(values)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          handleAccountChange(accountNumberValue);
          setShowEdit(false);
          setSelectedEmployer(null);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create employer');
      });
  };

  const update = (values: Employer) => {
    return EmployerApi.updateEmployer(values)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          handleAccountChange(accountNumberValue);
          setSelectedEmployer(null);
          setShowEdit(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update employer');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaFolderOpen className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Employer List</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-around align-items-center'>
        <div>
          <BSSelect
            name='accountNumber'
            label='Account'
            onChange={handleAccountChange}
            defaultValue={accountNumberValue}
            options={[...insuranceCompaniesForUser].sort((a, b) =>
              a.shortname!.localeCompare(b.shortname!)
            )}
            optionMethod={(options: InsuranceCompany[]) =>
              options.map((o) => (
                <option key={o.accountnumber!} value={o.accountnumber!}>
                  {`${o.accountnumber} - ${o.shortname}`}
                </option>
              ))
            }
          />
        </div>
        <div className={`${styles.width33} ms-2`}>
          {fetching && (
            <Spinner
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          )}
        </div>
        <div>
          <Button
            type='button'
            size='sm'
            onClick={() => {
              if (!accountNumberValue) {
                toast.info('Select an account for the new employer');
                return;
              }
              setShowEdit(true);
            }}
          >
            Add New
          </Button>
        </div>
      </div>
      <EmployerTable employers={employers} handleEdit={handleEdit} />
      <AddEditEmployer
        show={showEdit}
        setShow={setShowEdit}
        employer={selectedEmployer}
        accountNumber={accountNumberValue}
        handleEmployerSubmit={handleEmployerSubmit}
      />
    </PageScaffold>
  );
}
