import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import { FaPlusCircle, FaUser } from 'react-icons/fa';
import PageScaffold from '../PageScaffold/PageScaffold';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EntityUser } from '../../ApiTypes/EntityUser';
import UserApi from '../../Api/UserApi';
import { Button } from 'react-bootstrap';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import UserClaimTypeAccessApi from '../../Api/UserClaimTypeAccessApi';
import { UserClaimTypeAccess } from '../../ApiTypes/UserClaimTypeAccess';
import ClaimTypeAccessTable from './ClaimTypeAccessTable';
import AddEditClaimTypeAccessModal from './AddEditClaimTypeAccessModal';
// import UserAccessEmployerModal from './UserAccessEmployerModal';

export default function EditClaimTypeAccess() {
  const { userId } = useParams();

  const [userAccess, setUserAccess] = useState<UserClaimTypeAccess[]>([]);
  const [user, setUser] = useState<EntityUser | null>(null);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [selectedAccess, setSelectedAccess] =
    useState<UserClaimTypeAccess | null>(null);

  const [showAddEdit, setShowAddEdit] = useState<boolean>(false);

  useEffect(() => {
    // getUserAccess();
    getUser();
  }, [userId]);

  const getUserAccess = () => {
    UserClaimTypeAccessApi.getUserClaimTypeAccessess(userId!)
      .then((res) => setUserAccess(res.data))
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get user claim type access');
      });
  };

  const getUser = () => {
    UserApi.getById(userId!)
      .then((res) => {
        setUser(res.data);
        getUserAccess();
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get user');
      });
  };

  const handleDelete = (access: UserClaimTypeAccess) => {
    setSelectedAccess(access);
    setShowDelete(true);
  };

  const deleteAccess = () => {
    if (selectedAccess) {
      return UserClaimTypeAccessApi.delete(selectedAccess.id)
        .then((res) => {
          if (res.data.success) {
            getUserAccess();
          } else {
            toast.error(res.data.message);
          }
          setShowDelete(false);
        })
        .catch((err) => {
          console.log(err);
          setShowDelete(false);
          toast.error('Failed to delete claim type access');
        });
    }

    return Promise.resolve();
  };

  const handleEdit = (a: UserClaimTypeAccess) => {
    setSelectedAccess(a);
    setShowAddEdit(true);
  };
  const handleNew = () => {
    setSelectedAccess(null);
    setShowAddEdit(true);
  };

  const updateAccess = (claimTypeAccess: UserClaimTypeAccess) => {
    if (!claimTypeAccess.id) {
      return UserClaimTypeAccessApi.create(claimTypeAccess)
        .then((res) => {
          if (res.data.success) {
            setShowAddEdit(false);
            getUserAccess();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to update claim type access');
        });
    } else {
      return UserClaimTypeAccessApi.update(claimTypeAccess)
        .then((res) => {
          if (res.data.success) {
            setShowAddEdit(false);
            setSelectedAccess(null);
            getUserAccess();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to update claim type access');
        });
    }
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaUser className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Claim Type Access</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center py-3'>
        <Button
          type='button'
          variant='primary'
          size='sm'
          className='button-icon-text'
          onClick={handleNew}
        >
          <FaPlusCircle /> Claim Type
        </Button>
      </div>
      <ClaimTypeAccessTable
        data={userAccess}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
      />
      <DeleteConfirmationModal
        show={showDelete}
        setShow={setShowDelete}
        setNull={setSelectedAccess}
        handleDelete={deleteAccess}
      />
      <AddEditClaimTypeAccessModal
        show={showAddEdit}
        setShow={setShowAddEdit}
        id={selectedAccess?.id ?? null}
        userId={userId!}
        userName={user?.userName ?? ''}
        handleSubmit={updateAccess}
      />
    </PageScaffold>
  );
}
