import React, { useState, useEffect } from 'react';
import { Button, Container, Modal, Form } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaFilePdf } from 'react-icons/fa';
import ReferenceApi from '../../Api/ReferenceApi';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { useAppSelector } from '../../Reducers/Store';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import styles from './index.module.css';

export default function ExportDocsModal({
  show,
  setShow,
  confirm,
  claimNumber,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  confirm: (values: { claimNumber: string; insurcoid: number }) => void;
  claimNumber: string;
}) {
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );

  const onSubmit = (values: { claimNumber: string; insurcoid: number }) => {
    return confirm(values);
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='Confirm-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Confirm-modal'>
          <FaFilePdf className='pe-1' /> Document Export
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p className='fw-bold text-center'>
            Export all documents for an account or claim. A zip file will be
            emailed to you upon completion.
          </p>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              claimNumber: claimNumber,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='claimNumber'
                    label='Claim Number'
                    type='text'
                    component={FieldBSRenderText}
                  />
                  <p>-- OR --</p>
                  <Field
                    name='insurcoid'
                    label='Account'
                    options={insuranceCompaniesForUser}
                    optionMethod={(options: InsuranceCompany[]) =>
                      options.map((o) => (
                        <option key={o.insurcoid!} value={o.insurcoid!}>
                          {`${o.accountnumber} - ${o.shortname}`}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  />

                  <div className={styles.confirmButtonsDiv}>
                    <Button type='submit' size='lg' variant='primary'>
                      Confirm
                    </Button>
                    <Button
                      type='button'
                      size='lg'
                      variant='secondary'
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
