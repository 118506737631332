import React from 'react';

import { Container, Modal } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import { CheckDetail } from '../../ApiTypes/CheckDetail';
import CheckDetailTable from './CheckDetailTable';
import styles from './index.module.css';

export default function CheckDetailModal({
  show,
  setShow,
  checkDetails,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  checkDetails: CheckDetail[];
}) {
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='CheckDetailModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='CheckDetailModal-modal'>
          <FaCheck className='pe-1' /> Check Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <CheckDetailTable data={checkDetails} />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
