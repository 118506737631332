import { createAction } from '@reduxjs/toolkit';
import { FileDownload } from '../Types/FileDownLoad';

const SET_SHOW = 'SET_SHOW';
const SET_FILE_DOWNLOAD = 'SET_FILE_DOWNLOAD';

export const setShowDocView = createAction<boolean>(SET_SHOW);
export const setDocViewFileDownload = createAction<FileDownload | null>(
  SET_FILE_DOWNLOAD
);
