import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { UserClaimTypeAccess } from '../ApiTypes/UserClaimTypeAccess';

class UserClaimTypeAccessApi {
  static getUserClaimTypeAccessess(userId: string) {
    return axios.get<UserClaimTypeAccess[]>(
      `${API_URL}/api/userclaimtypeaccesses/${userId}`,
      getAuthHeader()
    );
  }
  static getUserClaimTypeAccessById(userClaimTypeAccessId: number) {
    return axios.get<UserClaimTypeAccess>(
      `${API_URL}/api/userclaimtypeaccesses/${userClaimTypeAccessId}`,
      getAuthHeader()
    );
  }
  static create(data: UserClaimTypeAccess) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/userclaimtypeaccesses`,
      data,
      getAuthHeader()
    );
  }
  static update(data: UserClaimTypeAccess) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/userclaimtypeaccesses/${data.id}`,
      data,
      getAuthHeader()
    );
  }
  static delete(userClaimTypeAccessId: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/userclaimtypeaccesses/${userClaimTypeAccessId}`,
      getAuthHeader()
    );
  }
}

export default UserClaimTypeAccessApi;
