import React, { useState, useEffect } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import DocumentsApi from '../../../Api/DocumentsApi';
import { ScanDocSimple } from '../../../ApiTypes/ScanDocSimple';
import AllDocuments from './AllDocuments';
import BillDocuments from './BillDocuments';
import BoardDocuments from './BoardDocuments';
import MedicalDocuments from './MedicalDocuments';
import RecoveryDocuments from './RecoveryDocuments';
import ReportsDocuments from './ReportsDocuments';
import ReserveWSDocuments from './ReserveWSDocuments';
import { useAppSelector } from '../../../Reducers/Store';
import { ClaimTabsEnum } from '../../../ApiTypes/ClaimTypeTab';

export default function DocumentsTab({ claimType }: { claimType: number }) {
  let location = useLocation();
  let { claimNumber } = useParams();
  let navigate = useNavigate();
  const [key, setKey] = useState<string>('all');
  const [scanDocs, setScanDocs] = useState<ScanDocSimple[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { claimTypeConfiguration } = useAppSelector(
    (state) => state.currentClaimReducer
  );

  useEffect(() => {
    locationToEventKey();
  }, [location]);

  const locationToEventKey = () => {
    setScanDocs([]);
    const split = location.pathname.split('/');
    if (split.length === 4 && split[3] === 'scanned-documents') {
      navigate('all');
    } else {
      setKey(split[4]);
    }
  };

  const getScanDocsForClaim = (type: number) => {
    if (claimNumber) {
      setLoading(true);
      DocumentsApi.getScanDocsForClaim(claimNumber, type)
        .then((res) => {
          setScanDocs(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className='w-100 h-100'>
      <Tab.Container
        defaultActiveKey='all'
        activeKey={key}
        id='documentTabs'
        onSelect={(k) => setKey(k ?? '')}
      >
        <Nav variant='tabs' className='mb-3'>
          <Nav.Item>
            <NavLink to='all'>
              <Nav.Link as='button' eventKey='all'>
                All Documents
              </Nav.Link>
            </NavLink>
          </Nav.Item>
          {claimTypeConfiguration?.find(
            (f) =>
              f.claimTabId === ClaimTabsEnum.Documents &&
              f.fieldName === 'board-forms'
          )?.visible && (
            <Nav.Item>
              <NavLink to='board-forms'>
                <Nav.Link as='button' eventKey='board-forms'>
                  Board Forms
                </Nav.Link>
              </NavLink>
            </Nav.Item>
          )}
          {claimTypeConfiguration?.find(
            (f) =>
              f.claimTabId === ClaimTabsEnum.Documents &&
              f.fieldName === 'bills'
          )?.visible && (
            <Nav.Item>
              <NavLink to='bills'>
                <Nav.Link as='button' eventKey='bills'>
                  Bills
                </Nav.Link>
              </NavLink>
            </Nav.Item>
          )}
          {claimTypeConfiguration?.find(
            (f) =>
              f.claimTabId === ClaimTabsEnum.Documents &&
              f.fieldName === 'medical'
          )?.visible && (
            <Nav.Item>
              <NavLink to='medical'>
                <Nav.Link as='button' eventKey='medical'>
                  Medical
                </Nav.Link>
              </NavLink>
            </Nav.Item>
          )}
          {claimTypeConfiguration?.find(
            (f) =>
              f.claimTabId === ClaimTabsEnum.Documents &&
              f.fieldName === 'recovery'
          )?.visible && (
            <Nav.Item>
              <NavLink to='recovery'>
                <Nav.Link as='button' eventKey='recovery'>
                  Recovery
                </Nav.Link>
              </NavLink>
            </Nav.Item>
          )}
          {claimTypeConfiguration?.find(
            (f) =>
              f.claimTabId === ClaimTabsEnum.Documents &&
              f.fieldName === 'reports'
          )?.visible && (
            <Nav.Item>
              <NavLink to='reports'>
                <Nav.Link as='button' eventKey='reports'>
                  Reports
                </Nav.Link>
              </NavLink>
            </Nav.Item>
          )}
          {claimTypeConfiguration?.find(
            (f) =>
              f.claimTabId === ClaimTabsEnum.Documents &&
              f.fieldName === 'reserve-ws'
          )?.visible && (
            <Nav.Item>
              <NavLink to='reserve-ws'>
                <Nav.Link as='button' eventKey='reserve-ws'>
                  Reserve WS
                </Nav.Link>
              </NavLink>
            </Nav.Item>
          )}
        </Nav>
        <Routes>
          <Route
            path='all'
            element={
              <AllDocuments
                getScanDocsForClaim={getScanDocsForClaim}
                scanDocs={scanDocs}
                setScanDocs={setScanDocs}
                loading={loading}
                claimType={claimType}
              />
            }
          />
          <Route
            path='board-forms'
            element={
              <BoardDocuments
                getScanDocsForClaim={getScanDocsForClaim}
                scanDocs={scanDocs}
                setScanDocs={setScanDocs}
                loading={loading}
                claimType={claimType}
              />
            }
          />
          <Route
            path='bills'
            element={
              <BillDocuments
                getScanDocsForClaim={getScanDocsForClaim}
                scanDocs={scanDocs}
                setScanDocs={setScanDocs}
                loading={loading}
                claimType={claimType}
              />
            }
          />
          <Route
            path='medical'
            element={
              <MedicalDocuments
                getScanDocsForClaim={getScanDocsForClaim}
                scanDocs={scanDocs}
                setScanDocs={setScanDocs}
                loading={loading}
                claimType={claimType}
              />
            }
          />
          <Route
            path='recovery'
            element={
              <RecoveryDocuments
                getScanDocsForClaim={getScanDocsForClaim}
                scanDocs={scanDocs}
                setScanDocs={setScanDocs}
                loading={loading}
                claimType={claimType}
              />
            }
          />
          <Route
            path='reports'
            element={
              <ReportsDocuments
                getScanDocsForClaim={getScanDocsForClaim}
                scanDocs={scanDocs}
                setScanDocs={setScanDocs}
                loading={loading}
                claimType={claimType}
              />
            }
          />
          <Route
            path='reserve-ws'
            element={
              <ReserveWSDocuments
                getScanDocsForClaim={getScanDocsForClaim}
                scanDocs={scanDocs}
                setScanDocs={setScanDocs}
                loading={loading}
                claimType={claimType}
              />
            }
          />
        </Routes>
      </Tab.Container>
    </div>
  );
}
