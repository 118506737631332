import React, { useState, useEffect } from 'react';
import { FaChartPie } from 'react-icons/fa';
import { toast } from 'react-toastify';
import NcmApi from '../../Api/NcmApi';
import { NCMDashboardChartObj } from '../../ApiTypes/NCMDashboardChartObj';
import { NcmDashboardObj } from '../../ApiTypes/NcmDashboardObj';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import PageScaffold from '../PageScaffold/PageScaffold';
import ReassignNcm from '../ReassignNcm/ReassignNcm';
import styles from './index.module.css';
import NcmDashboardChart from './NcmDashboardChart';
import NcmDashboardTable from './NcmDashboardTable';
import { addDays, format } from 'date-fns';
import {
  setShowDocView,
  setDocViewFileDownload,
} from '../../Actions/DocViewActions';
import { FileDownload } from '../../Types/FileDownLoad';
import {
  DevExpressReportRequest,
  DxAvailableReports,
} from '../../ApiTypes/DevExpressReportRequest';
import { getDxReport } from '../DocViewModal/useDocViewModal';
import { requestDx } from '../DxReportRequestModal/useDxReportRequestModal';

export default function NcmDashboard() {
  const dispatch = useAppDispatch();
  const { nurseCaseManagers } = useAppSelector((state) => state.reference);
  const [showReassign, setShowReassign] = useState<boolean>(false);
  const [selected, setSelected] = useState<NcmDashboardObj | null>(null);
  const [ncm, setNcm] = useState<string>('');
  const [days, setDays] = useState<number>(30);
  const [dashboardObjects, setDashboardObjects] = useState<NcmDashboardObj[]>(
    []
  );
  const [chartObjects, setChartObjects] = useState<NCMDashboardChartObj[]>([]);

  useEffect(() => {
    getData();
    getChartData();
  }, [ncm, days]);

  const getData = () => {
    let manager = 'All';
    if (ncm) {
      manager = ncm;
    }
    NcmApi.getDashboard(manager)
      .then((res) => {
        setDashboardObjects(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getChartData = () => {
    let manager = 'All';
    if (ncm) {
      manager = ncm;
    }
    NcmApi.getChartObjs(manager, days)
      .then((res) => {
        setChartObjects(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setShowDx = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownload = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  const viewReport = (obj: NcmDashboardObj) => {
    var now = new Date();
    var asOf = format(now, 'yyyy-MM-dd');

    const request: DevExpressReportRequest = {
      ...requestDx,
      report: DxAvailableReports.NCMReportByManager,
      asOfDate: asOf,
      userId: obj.assignedTo,
    };
    getDxReport(request, setFileDownload, setShowDx);
  };

  const handleReassign = (cm: NcmDashboardObj) => {
    setSelected(cm);
    setShowReassign(true);
  };

  const reassignNcm = (values: {
    userId: string;
    claimNumber: string;
    current: string;
  }) => {
    return NcmApi.reassign(selected?.caseManagementId ?? 0, values.userId)
      .then((res) => {
        getData();
        setSelected(null);
        setShowReassign(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed To Reassign');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaChartPie className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Nurse Case Manager Dashboard</h1>
            <p>View claims assigned to a Nurse Case Manager</p>
          </div>
        </div>
        <div className='d-flex gap1Rem align-items-center'>
          <div className='mb-3'>
            <label htmlFor='ncm' className='form-label fs-6  m-0'>
              Nurse Case Manager
            </label>
            <select
              id='ncm'
              onChange={(e) => {
                setNcm(e.target.value);
              }}
              className='form-select form-select-sm'
            >
              <option value=''>All</option>
              {nurseCaseManagers.map((o) => (
                <option key={o.userId} value={o.userId}>
                  {o.userName}
                </option>
              ))}
            </select>
          </div>
          <div className={`position-relative mb-3`}>
            <label htmlFor='days' className='form-label fs-6  m-0'>
              Days Back
            </label>
            <input
              name='days'
              id='days'
              type='number'
              step='30'
              className='form-control form-control-sm'
              placeholder='Days Back'
              onChange={(e) => {
                setDays(+e.target.value);
              }}
              value={days}
            />
          </div>
        </div>
      </div>
      <NcmDashboardChart data={chartObjects} days={days} />
      <NcmDashboardTable
        data={dashboardObjects}
        viewReport={viewReport}
        handleReassign={handleReassign}
      />
      <ReassignNcm
        show={showReassign}
        setShow={setShowReassign}
        ncm={selected}
        submitForm={reassignNcm}
      />
    </PageScaffold>
  );
}
