import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { EmployerPolicy } from '../ApiTypes/EmployerPolicy';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class EmployerPolicyApi {
  static list(accountNumber: string, treatyYear: number) {
    return axios.get<EmployerPolicy[]>(
      `${API_URL}/api/employerpolicies/list?accountNumber=${accountNumber}&treatyYear=${treatyYear}`,
      getAuthHeader()
    );
  }
  static getById(policyId: number) {
    return axios.get<EmployerPolicy>(
      `${API_URL}/api/employerpolicies/${policyId}`,
      getAuthHeader()
    );
  }
  static updatePolicy(policy: EmployerPolicy) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/employerpolicies/${policy.policyId}`,
      policy,
      getAuthHeader()
    );
  }
  static removeAllExcess(accountNumber: string, treatyYear: number) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/employerpolicies/removeallexcess?accountNumber=${accountNumber}&treatyYear=${treatyYear}`,
      {},
      getAuthHeader()
    );
  }
  static deletePolicy(policyId: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/employerpolicies/${policyId}`,
      getAuthHeader()
    );
  }
  static rolloverPolicy(policyId: number) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/employerpolicies/${policyId}/rollover`,
      {},
      getAuthHeader()
    );
  }
  static rolloverPolicies(accountNumber: string, treatyYear: number) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/employerpolicies/rollover?accountNumber=${accountNumber}&treatyYear=${treatyYear}`,
      {},
      getAuthHeader()
    );
  }
}

export default EmployerPolicyApi;
