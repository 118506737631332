import React, { useState } from 'react';
import { Modal, Container } from 'react-bootstrap';
import { FaEnvelope } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LetterApi from '../../../Api/LetterApi';
import { EmailLetterRequest } from '../../../ApiTypes/EmailLetterRequest';
import { Letter } from '../../../ApiTypes/Letter';
import { UserEmailList } from '../../../ApiTypes/UserEmailList';
import { useAppSelector } from '../../../Reducers/Store';
import EmailLetterPageOne from './EmailLetterPageOne';
import EmailLetterPageThree from './EmailLetterPageThree';
import EmailLetterPageTwo from './EmailLetterPageTwo';

import styles from './index.module.css';

export default function EmailLetterModal({
  show,
  setShow,
  keyLetterId,
  letter,
  userEmailList,
  handleUpdateEmailAddress,
  getLetters,
  getHistory,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  keyLetterId: number;
  letter: Letter | null;
  userEmailList: UserEmailList[];
  handleUpdateEmailAddress: (values: {
    emailAddress: string;
  }) => Promise<boolean>;
  getLetters: () => void;
  getHistory: () => void;
}) {
  const { claimNumber } = useParams();
  const [page, setPage] = useState<number>(1);
  const [selectedHeader, setSelectedHeader] = useState<number>(0);

  const { userModel } = useAppSelector((state) => state.user);

  const handleEmailSubmit = (values: { emailAddress: string }) => {
    return handleUpdateEmailAddress(values).then((res) => {
      if (res) {
        setPage(2);
      }
      return res;
    });
  };

  const handleSelectedHeader = (value: number) => {
    if (value) {
      setSelectedHeader(value);
      setPage(3);
    }
  };

  const handleFormSubmit = (values: EmailLetterRequest) => {
    const copy = { ...values };
    copy.letterLogoOption = selectedHeader;
    copy.letterId = keyLetterId;
    copy.from = userModel?.user?.emailAddress ?? '';

    return LetterApi.emailLetter(copy)
      .then((res) => {
        if (res.data.success) {
          setShow(false);
          setPage(1);
          setSelectedHeader(0);
          getLetters();
          getHistory();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to email letter');
      });
  };

  const checkForEmail = () => {
    if (!userModel?.user?.emailAddress) {
      setPage(1);
    } else {
      setPage(2);
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setPage(1);
        setSelectedHeader(0);
        setShow(false);
      }}
      onEntered={() => checkForEmail()}
      aria-labelledby='Email-letter-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Email-letter-modal'>
          <FaEnvelope className='pe-1' /> Email Letter
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {page === 1 && (
            <EmailLetterPageOne
              userModel={userModel}
              handleEmailSubmit={handleEmailSubmit}
            />
          )}
          {page === 2 && (
            <EmailLetterPageTwo handleSelection={handleSelectedHeader} />
          )}

          {page === 3 && (
            <EmailLetterPageThree
              userModel={userModel}
              letter={letter}
              userEmailList={userEmailList}
              handleFormSubmit={handleFormSubmit}
              setShow={setShow}
              claimNumber={claimNumber ?? ''}
            />
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}
