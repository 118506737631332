import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { useAppDispatch } from '../../../Reducers/Store';
import { requiredField } from '../../../Utils/FieldValidation';
import FieldBSRenderText from '../../Common/FieldBSRenderText';

import styles from './index.module.css';
import { toast } from 'react-toastify';
import { Judge } from '../../../ApiTypes/Judge';
import JudgeApi from '../../../Api/JudgeApi';
import { fetchJudges } from '../../../Actions/ReferenceActions';

export default function AddJudge({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (s: boolean) => void;
}) {
  const dispatch = useAppDispatch();
  // const { judges } = useAppSelector((state) => state.reference);

  //   useEffect(() => {
  //     if (claimLitigation && claimLitigation.state) {
  //       getCounties(claimLitigation.state);
  //     }
  //   }, [claimLitigation]);

  const onSubmit = (values: Judge) => {
    return updateClaimLitigation(values);
  };

  const updateClaimLitigation = (request: Judge) => {
    return JudgeApi.updateJudge(request)
      .then((res) => {
        if (res.data.success) {
          toast.success('Judge Saved!');
          dispatch(fetchJudges());
          setShow(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update Judge');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='sm'
      onHide={() => {
        setShow(false);
      }}
      aria-labelledby='Add-Judge-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Add-Judge-Form-modal'>
          Add New Judge
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={onSubmit}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <div className='w-100'>
                      <div className='w-100'>
                        <Field
                          name='firstName'
                          label='First Name'
                          type='text'
                          component={FieldBSRenderText}
                          validate={requiredField}
                        />
                      </div>
                      <div className='w-100'>
                        <Field
                          name='lastName'
                          label='Last Name'
                          type='text'
                          component={FieldBSRenderText}
                          validate={requiredField}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.actionButtons}`}>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
