import React from 'react';
import { Field } from 'react-final-form';
import styles from './index.module.css';
import {
  cleanMoney,
  formatNumbers,
  makeMoney,
} from '../../Utils/InputFormatters';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';
import { centsValidation } from '../../Utils/FieldValidation';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import { FormApi } from 'final-form';
import { ReserveWorksheetFormType } from '../../Types/ReservesWorksheetFormType';

export default function MedicalTab({
  form,
}: {
  form: FormApi<ReserveWorksheetFormType, Partial<ReserveWorksheetFormType>>;
}) {
  return (
    <div>
      <div className={`${styles.grid4Columns}`}>
        <Field
          name='medicalPTD'
          label='Medical Paid To Date'
          type='text'
          disabled
          format={formatNumbers}
          parse={cleanMoney}
          validate={centsValidation}
          component={FieldBSRenderMoney}
        />
      </div>
      <p>Future Medical Expected</p>
      <div className={`${styles.grid4Columns}`}>
        <div />
        <Field
          name='futureMedicalPhysician'
          label='1. Physician'
          type='text'
          format={formatNumbers}
          parse={cleanMoney}
          validate={centsValidation}
          component={FieldBSRenderMoney}
        />
      </div>
      <div className={`${styles.grid4Columns}`}>
        <div />
        <Field
          name='futureMedicalHospital'
          label='2. Hospital'
          type='text'
          format={formatNumbers}
          parse={cleanMoney}
          validate={centsValidation}
          component={FieldBSRenderMoney}
        />
      </div>
      <div className={`${styles.grid4Columns}`}>
        <div />
        <Field
          name='futureMedicalMileage'
          label='3. Mileage'
          type='text'
          format={formatNumbers}
          parse={cleanMoney}
          validate={centsValidation}
          component={FieldBSRenderMoney}
        />
      </div>
      <div className={`${styles.grid4Columns}`}>
        <div />
        <Field
          name='futureMedicalMedicalMgmt'
          label='4. Medical Mgmt'
          type='text'
          format={formatNumbers}
          parse={cleanMoney}
          validate={centsValidation}
          component={FieldBSRenderMoney}
        />
      </div>
      <div className={`${styles.grid4Columns}`}>
        <div />
        <Field
          name='futureMedicalPharmacy'
          label='5. Pharmacy'
          type='text'
          format={formatNumbers}
          parse={cleanMoney}
          validate={centsValidation}
          component={FieldBSRenderMoney}
        />
      </div>
      <div className={`${styles.grid4Columns}`}>
        <div />
        <Field
          name='futureMedicalPhysTherapy'
          label='6. Physical Therapy'
          type='text'
          format={formatNumbers}
          parse={cleanMoney}
          validate={centsValidation}
          component={FieldBSRenderMoney}
        />
      </div>
      <div className={`${styles.grid4Columns}`}>
        <div />
        <div />
        <div />
        <Field
          name='newMedicalIncurred'
          label='Total New Medical Incurred'
          type='text'
          format={makeMoney}
          parse={cleanMoney}
          disabled
          component={FieldBSRenderMoney}
        />
      </div>
      <div className={`${styles.grid4Columns}`}>
        <div />
        <div />
        <div />
        <Field
          name='medicalRounded'
          label='Rounded'
          type='text'
          disabled
          format={formatNumbers}
          parse={cleanMoney}
          component={FieldBSRenderMoney}
        />
      </div>
      <div className={`${styles.grid4Columns}`}>
        <div />
        <Field
          name='txtCurrentMedicalReserves'
          label='Current Medical Reserves'
          type='text'
          disabled
          format={makeMoney}
          parse={cleanMoney}
          component={FieldBSRenderMoney}
        />
        <Field
          name='txtPreviousMedical'
          label='Previous Total Incurred'
          type='text'
          disabled
          format={makeMoney}
          parse={cleanMoney}
          component={FieldBSRenderMoney}
        />
        <Field
          name='medicalDifference'
          label='Difference'
          type='text'
          disabled
          format={makeMoney}
          parse={cleanMoney}
          component={FieldBSRenderMoney}
        />
      </div>
      <div className={`${styles.medicalRemarksGrid}`}>
        <div />
        <Field
          name='medicalRemarks'
          label='Medical Remarks'
          rows={3}
          component={FieldBSRenderTextArea}
        />
      </div>
    </div>
  );
}
