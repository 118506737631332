import React, { useState, useEffect } from 'react';
import { FaTable } from 'react-icons/fa';
import { Form as RFForm, Field } from 'react-final-form';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { Button, Form, Spinner } from 'react-bootstrap';
import { downloadExcel, parseDatesForServer } from '../../Utils';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import { requiredField } from '../../Utils/FieldValidation';
import { addDays, format } from 'date-fns';
import ClaimStatisticApi from '../../Api/ClaimStatisticApi';
import { ClaimStatisticsObj } from '../../ApiTypes/ClaimStatisticObj';
import ClaimSummaryTable from './ClaimSummaryTable';
import ExportApi from '../../Api/ExportApi';
import { exportHandler } from './ClaimSummaryExport';
import { VwUser } from '../../ApiTypes/VwUser';
import { useAppSelector } from '../../Reducers/Store';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import UserApi from '../../Api/UserApi';
import { toast } from 'react-toastify';
import { FormApi } from 'final-form';

export default function ClaimsSummaryPage() {
  const [data, setData] = useState<ClaimStatisticsObj[]>([]);
  const [users, setUsers] = useState<VwUser[]>([]);
  const [showUsers, setShowUsers] = useState(true);

  const { userModel } = useAppSelector((state) => state.user);

  let formInstance: FormApi<
    {
      fromDate: string;
      toDate: string;
      userId: string;
    },
    Partial<{
      fromDate: string;
      toDate: string;
      userId: string;
    }>
  >;

  useEffect(() => {
    handleUser();
  }, [userModel]);

  const onSubmit = (values: {
    fromDate: string;
    toDate: string;
    userId: string;
  }) => {
    return getClaimStatisticsList(
      values.fromDate,
      values.toDate,
      values.userId
    );
  };

  const getClaimStatisticsList = (
    fromDate: string,
    toDate: string,
    userId: string
  ) => {
    const uId = userId ?? '';
    return ClaimStatisticApi.getClaimStatisticList(fromDate, toDate, uId)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUser = () => {
    if (userModel) {
      if (!userModel.user?.isAdmin) {
        if (userModel.user?.isSupervisor) {
          getUsersForSupervisor();
          setShowUsers(true);
        } else {
          setShowUsers(false);
        }
      } else {
        // admin
        setShowUsers(true);
        getUsersForAdmin();
      }
    }
  };

  const getUsersForSupervisor = () => {
    UserApi.getVwUsersForSupervisor(userModel?.user?.userId ?? '')
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get users');
      });
  };

  const getUsersForAdmin = () => {
    UserApi.getUsersForMaintenance(true)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get users');
      });
  };

  const exportDoc = () => {
    var toSend = exportHandler(data);
    toSend.fileName = `Statistics.xls`;
    return ExportApi.exportToExcel(toSend)
      .then((res) => {
        downloadExcel(res.data.fileName, res.data.file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const exportRawData = () => {
    const values = formInstance.getState().values;
    const uId = values.userId ?? '';
    ClaimStatisticApi.exportRawData(values.fromDate, values.toDate, uId)
      .then((res) => {
        if (res.data.file) {
          downloadExcel(res.data.fileName, res.data.file);
        } else {
          toast.error('Failed to get raw data');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get raw data');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaTable className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Claims Summary Report</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <RFForm
          onSubmit={onSubmit}
          initialValues={{
            fromDate: format(addDays(new Date(), -7), 'yyyy-MM-dd'),
            toDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
          }}
          render={({ handleSubmit, form, values, submitting }) => {
            formInstance = form;
            return (
              <Form onSubmit={handleSubmit}>
                <div className='d-flex  align-items-center gap1Rem'>
                  {showUsers && (
                    <Field
                      name='userId'
                      label='User'
                      options={users}
                      optionMethod={(options: VwUser[]) =>
                        options.map((o) => (
                          <option key={o.userId} value={o.userId}>
                            {o.userName}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                  )}
                  <Field
                    name='fromDate'
                    type='text'
                    label='Date From'
                    validate={requiredField}
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                  />
                  <Field
                    name='toDate'
                    type='text'
                    label='To Date'
                    validate={requiredField}
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                  />

                  <Button type='submit' variant='primary' size='sm'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Search'
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        />
        <div className='d-flex justify-content-center gap1Rem'>
          <Button
            type='button'
            variant='outline-primary'
            size='sm'
            onClick={() => {
              exportDoc();
            }}
          >
            Export
          </Button>
          <Button
            type='button'
            variant='outline-primary'
            size='sm'
            onClick={() => {
              exportRawData();
            }}
          >
            Export Raw Data
          </Button>
        </div>
      </div>
      <ClaimSummaryTable data={data} />
    </PageScaffold>
  );
}
