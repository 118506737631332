import React from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import styles from './index.module.css';

export default function ConfirmSendAllNow({
  show,
  setShow,
  handleConfirm,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  handleConfirm: () => void;
}) {
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='ConfirmSendAllNow-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='ConfirmSendAllNow-modal'>
          <FaCheck className='pe-1' /> Confirm
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p>Are you sure you want to send all jobs now?</p>
          <div className='d-flex justify-content-center align-items-center gap1Rem py-3'>
            <Button
              type='button'
              variant='secondary'
              size='sm'
              onClick={() => setShow(false)}
            >
              Cancel
            </Button>
            <Button
              type='button'
              variant='primary'
              size='sm'
              onClick={() => handleConfirm()}
            >
              Confirm
            </Button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
