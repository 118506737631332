import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { InsCoLog } from '../ApiTypes/InsCoLog';
import { SearchInsuranceCompanyLogsRequest } from '../ApiTypes/SearchInsuranceCompanyLogsRequest';

class InsuranceCompanyLogApi {
  static getAll() {
    return axios.get<InsCoLog[]>(
      `${API_URL}/api/insurancecompanylogs`,
      getAuthHeader()
    );
  }
  static searchLogs(request: SearchInsuranceCompanyLogsRequest) {
    return axios.post<InsCoLog[]>(
      `${API_URL}/api/insurancecompanylogs/search`,
      request,
      getAuthHeader()
    );
  }
}

export default InsuranceCompanyLogApi;
