import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ProductivityObj } from '../ApiTypes/ProductivityObj';
import { Adjuster } from '../ApiTypes/Adjuster';
import { VwDiaryReport } from '../ApiTypes/VwDiaryReport';

class AdjustersApi {
  static adjustersProductivity(fromDate: string, toDate: string) {
    return axios.get<ProductivityObj[]>(
      `${API_URL}/api/adjusters/productivity?fromDate=${fromDate}&toDate=${toDate}`,
      getAuthHeader()
    );
  }
  static getAdjusterByUserId(userId: string) {
    return axios.get<Adjuster>(
      `${API_URL}/api/adjusters/users/${userId}`,
      getAuthHeader()
    );
  }
  static getAdjusterDiaryReport(adjusterId: number) {
    return axios.get<VwDiaryReport[]>(
      `${API_URL}/api/adjusters/${adjusterId}/diaryreport`,
      getAuthHeader()
    );
  }
}

export default AdjustersApi;
