import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import {
  FaEye,
  FaFileExcel,
  FaFilePdf,
  FaFileWord,
  FaPlayCircle,
  FaVoicemail,
} from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { displayDateOnly } from '../../Utils';
import accounting from 'accounting';
import { ScanDocSimple } from '../../ApiTypes/ScanDocSimple';
import DocumentsApi from '../../Api/DocumentsApi';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../Reducers/Store';
import {
  setShowDocView,
  setDocViewFileDownload,
} from '../../Actions/DocViewActions';
import { FileDownload } from '../../Types/FileDownLoad';

export default function TransactionDocTable({
  scanDocs,
}: {
  scanDocs: ScanDocSimple[];
}) {
  let { claimNumber } = useParams();
  const dispatch = useAppDispatch();
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => scanDocs, [scanDocs]);

  const getIcon = (type: string) => {
    switch (type) {
      case 'pdf':
        return <FaFilePdf />;
      case 'word':
        return <FaFileWord />;
      case 'excel':
        return <FaFileExcel />;
      case 'play':
        return <FaPlayCircle />;
      case 'voice':
        return <FaVoicemail />;
      default:
        return <FaFilePdf />;
    }
  };

  const setShow = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownload = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  const handleViewDoc = (doc: ScanDocSimple) => {
    DocumentsApi.getScanDocFile(doc.docid)
      .then((res) => {
        setFileDownload(res.data);
        setShow(true);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get document to view');
      });
  };

  const columnData: ColumnDef<ScanDocSimple>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 170,
      cell: (d) => {
        return (
          <div className='d-flex justify-content-around'>
            <Link
              to={`/scandocs/${d.row.original.docid}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button
                type='button'
                size='sm'
                variant='primary'
                title='View'
                onClick={() => {
                  // handleViewDoc(d.row.original);
                }}
              >
                <FaEye />
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      header: 'Date Added',
      accessorKey: 'date',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'SVC From',
      accessorKey: 'serviceFrom',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'SVC To',
      accessorKey: 'serviceTo',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'Icon',
      accessorKey: 'docIcon',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) => getIcon(d.row.original.docIcon),
      maxSize: 75,
    },
    {
      header: 'Description',
      accessorKey: 'note',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Doc Type',
      accessorKey: 'docType',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 100,
    },
    {
      header: 'Priority',
      accessorKey: 'priority',
      sortingFn: 'alphanumericCaseSensitive',
      // filterFn: "equals",
      // enableColumnFilter: false,
      maxSize: 100,
      // cell: d => accounting.formatMoney(d.row.original.txAmount ?? 0)
    },
    {
      header: 'Status',
      accessorKey: 'status',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 75,
    },
    {
      header: 'CC Start',
      accessorKey: 'ccStart',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'CC End',
      accessorKey: 'ccEnd',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'Bill Total',
      accessorFn: (d) => `${d.billedAmount}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 120,
      cell: (d) => accounting.formatMoney(d.row.original.billedAmount ?? 0),
    },
    {
      header: 'Allowance',
      accessorFn: (d) => `${d.recAllowance}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 120,
      cell: (d) => accounting.formatMoney(d.row.original.recAllowance ?? 0),
    },
    {
      header: 'Savings',
      accessorFn: (d) => `${d.savings}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 120,
      cell: (d) => accounting.formatMoney(d.row.original.savings ?? 0),
    },
    {
      header: 'Fee Amount',
      accessorFn: (d) => `${d.feeAmount}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 120,
      cell: (d) => accounting.formatMoney(d.row.original.feeAmount ?? 0),
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
