import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import accounting from 'accounting';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ClaimFinancialHistoryDetailObj } from '../../ApiTypes/HistoryObjects';
import { displayDateOnly } from '../../Utils';

import styles from './index.module.css';

export default function ClaimFinancialHistoryChart({
  data,
}: {
  data: ClaimFinancialHistoryDetailObj[];
}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ChartDataLabels
  );

  const sortAsc = (data: ClaimFinancialHistoryDetailObj[]) => {
    return data.sort((a, b) => {
      const aName = new Date(a.asOfDate);
      const bName = new Date(b.asOfDate);
      return aName < bName ? -1 : aName > bName ? 1 : 0;
    });
  };

  const getData = (propsData: ClaimFinancialHistoryDetailObj[]): any => ({
    labels: data.map((x) => displayDateOnly(x.asOfDate)),
    datasets: [
      {
        label: 'Reserves',
        data: data.map((x) => x.totalReserves),
        fill: false,
        type: 'line',
        backgroundColor: '#3f95ff',
        borderColor: '#3f95ff',
      },
      {
        label: 'Paid',
        data: data.map((x) => x.totalPaid),
        fill: false,
        type: 'line',
        backgroundColor: '#e4581b',
        borderColor: '#e4581b',
      },
      {
        label: 'Incurred',
        data: data.map((x) => x.totalIncurred),
        fill: false,
        type: 'line',
        borderColor: '#8E999F',
        backgroundColor: '#8E999F',
      },
    ],
  });
  const getOptions = (): any => ({
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      datalabels: {
        color: '#8E999F',
        anchor: 'end',
        align: 'start',
        formatter: (value: number, context: any) =>
          context.datasetIndex === 0 ? accounting.formatMoney(+value) : '',
        font: {
          weight: 'bold',
        },
      },
      tooltip: {
        callbacks: {
          label: (context: any) => accounting.formatMoney(context.parsed.y),
        },
      },
      legend: {
        position: 'right',
        align: 'start',
      },
    },

    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        ticks: {
          beginAtZero: false,
          callback: (value: number) => accounting.formatMoney(value),
        },
      },
    },
  });
  return (
    <div>
      <div className={`${styles.chartArea}`}>
        {data ? (
          <Line data={getData(sortAsc(data))} options={getOptions()} />
        ) : (
          'No Available Data'
        )}
      </div>
    </div>
  );
}
