import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import accounting from 'accounting';
import { displayDateOnly } from '../../Utils';
import { VRecurringPayment } from '../../ApiTypes/VRecurringPayment';

export default function RecurringPaymentsTable({
  recurringPayments,
}: {
  recurringPayments: VRecurringPayment[];
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => recurringPayments, [recurringPayments]);

  const columnData: ColumnDef<VRecurringPayment>[] = [
    {
      header: 'Claim Number',
      accessorKey: 'claimno',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Payee',
      accessorKey: 'payee',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Pay To',
      accessorKey: 'payto',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Amount',
      accessorFn: (d) => `${d.amount}`,
      sortingFn: 'alphanumericCaseSensitive',
      filterFn: 'includesString',
      cell: (d) => accounting.formatMoney(d.row.original.amount ?? 0),
    },
    {
      header: 'Next Svc From',
      accessorKey: 'nextservicefromdate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Next Svc To',
      accessorKey: 'nextservicetodate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Next Print Date',
      accessorKey: 'nextprintdate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Next Paid Date',
      accessorKey: 'lastpaiddate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Comments',
      accessorKey: 'comments',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
      />
    </div>
  );
}
