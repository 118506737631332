import React, { useState, useEffect } from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import MedicareApi from '../../Api/MedicareApi';
import { MedicareClaimResult } from '../../ApiTypes/MedicareClaimResult';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import MedicareClaimResultDetailPage from './MedicareClaimResultDetailPage';
import ResultTable from './ResultTable';

export default function MedicareClaimResultPage() {
  const [results, setResults] = useState<MedicareClaimResult[]>([]);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [selectedResultId, setSelectedResultId] = useState<number | null>(null);

  useEffect(() => {
    getResults();
  }, []);

  const getResults = () => {
    MedicareApi.getResults()
      .then((res) => {
        setResults(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get Results');
      });
  };

  const handleViewDetails = (r: MedicareClaimResult) => {
    setSelectedResultId(r.id);
    setShowDetails(true);
  };
  const closeDetails = (show: boolean) => {
    setShowDetails(false);
    setSelectedResultId(null);
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaCalendarAlt className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Medicare Claim Results</h1>
          </div>
        </div>
      </div>
      <ResultTable data={results} handleViewDetails={handleViewDetails} />
      <MedicareClaimResultDetailPage
        show={showDetails}
        setShow={closeDetails}
        id={selectedResultId}
      />
    </PageScaffold>
  );
}
