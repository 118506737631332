import { format } from 'date-fns';
import React from 'react';
import { Modal, Container } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import EdiTransactionApi from '../../Api/EdiTransactionApi';
import { EDIStatus } from '../../ApiTypes/EdiStatus';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { useAppSelector } from '../../Reducers/Store';
import GetFormForEditTransaction from './GetFormForEditTransaction';

export default function EditEdiModal({
  show,
  handleCloseModal,
  transaction,
  getTransactions,
}: {
  show: boolean;
  handleCloseModal: () => void;
  transaction: EdiTransaction | null;
  getTransactions: () => void;
}) {
  const { claimNumber } = useParams();
  const { claim } = useAppSelector((state) => state.currentClaimReducer);
  const onSubmit = (values: EdiTransaction) => {
    console.log(values);
    const copy = { ...values };
    const now = format(new Date(), 'yyyy-MM-dd');

    copy.ediTransactionLogs = null;

    if (transaction) {
      if (copy.benefitSuspendDate) {
        copy.suspensionCheck0 = true;
      } else {
        copy.suspensionCheck0 = false;
      }
      return updateTransaction(copy);
    }
    copy.claimno = claimNumber ?? '';
    copy.submitStatus = EDIStatus.New;
    copy.submitDate = now;
    copy.severity = claim?.severity ?? '';
    if (copy.benefitSuspendDate) {
      copy.suspensionCheck0 = true;
    } else {
      copy.suspensionCheck0 = false;
    }

    return createTransaction(copy);
  };

  const createTransaction = (t: EdiTransaction) => {
    return EdiTransactionApi.createEdiTransaction(t.claimno, t)
      .then((res) => {
        if (res.data.success) {
          getTransactions();
          handleCloseModal();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create transaction');
      });
  };
  const updateTransaction = (t: EdiTransaction) => {
    return EdiTransactionApi.updateEdiTransaction(t.claimno, t)
      .then((res) => {
        if (res.data.success) {
          getTransactions();
          handleCloseModal();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update transaction');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        handleCloseModal();
      }}
      dialogClassName=''
      aria-labelledby='EDI-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='EDI-Form-modal'>
          <FaEdit className='pe-1' /> EDI
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <GetFormForEditTransaction
            transaction={transaction}
            onFormSubmit={onSubmit}
            closeModal={handleCloseModal}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
