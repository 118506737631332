import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { CheckDetail } from '../../ApiTypes/CheckDetail';
import { displayDateAndTime, displayDateOnly } from '../../Utils';
import accounting from 'accounting';

export default function CheckDetailTable({ data }: { data: CheckDetail[] }) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<CheckDetail>[] = [
    {
      header: 'Check Id',
      accessorKey: 'checkid',
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
    },
    {
      header: 'Check Number',
      accessorKey: 'checknumber',
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
    },
    {
      header: 'Amount',
      accessorFn: (d) => `${d.amount}`,
      sortingFn: 'text',
      filterFn: 'includesString',
      cell: ({ row }) => accounting.formatMoney(row.original.amount ?? 0),
    },
    {
      header: 'Tx Date',
      accessorKey: 'txdate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateAndTime(d.row.original.txdate ?? ''),
    },
    {
      header: 'Claim Number',
      accessorKey: 'claimno',
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
    },
    {
      header: 'Claimant',
      accessorKey: 'claimantname',
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
    },
    {
      header: 'Employer',
      accessorKey: 'employername',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Pay Code',
      accessorKey: 'paycode',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Service From',
      accessorKey: 'servicefrom',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.servicefrom ?? ''),
    },
    {
      header: 'Service To',
      accessorKey: 'serviceto',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.serviceto ?? ''),
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
