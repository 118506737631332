import React from 'react';
import { Field, Form as RFForm } from 'react-final-form';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import styles from './index.module.css';
import { InsCoPremiumHistory } from '../../ApiTypes/InsCoPremiumHistory';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import { centsValidation } from '../../Utils/FieldValidation';
import { formatNumbers, cleanMoney } from '../../Utils/InputFormatters';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';

export default function EditPremiumHistoryModal({
  show,
  setShow,
  history,
  addUpdate,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  history: InsCoPremiumHistory | null;
  addUpdate: (h: InsCoPremiumHistory) => Promise<void>;
}) {
  const onSubmit = (values: InsCoPremiumHistory) => {
    return addUpdate(values);
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='EditPremiumHistoryModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='EditPremiumHistoryModal-modal'
        >
          <FaCheck className='pe-1' /> History
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={history ?? {}}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='treatyYear'
                    type='text'
                    label='Treaty Year'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='premium'
                    label='Premium'
                    type='text'
                    format={formatNumbers}
                    parse={cleanMoney}
                    validate={centsValidation}
                    component={FieldBSRenderMoney}
                  />
                  <Field
                    name='policyCount'
                    type='text'
                    label='Policy Count'
                    component={FieldBSRenderText}
                  />
                  <div className='d-flex justify-content-center align-items-center gap1Rem'>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type='submit' size='sm' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
