import { format } from 'date-fns';
import { FormApi } from 'final-form';
import React, { useState, useEffect } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaRecycle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import RecurringPaymentsApi from '../../Api/RecurringPaymentsApi';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { VRecurringPayment } from '../../ApiTypes/VRecurringPayment';
import { useAppSelector } from '../../Reducers/Store';
import { displayDateOnly, parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import RecurringPaymentsTable from './RecurringPaymentTable';

export default function ProcessRecurringPayments() {
  const { insuranceCompanies } = useAppSelector((state) => state.reference);
  const [payments, setPayments] = useState<VRecurringPayment[]>([]);

  let formInstance: FormApi<
    { asOf: string; accountNumber: string },
    Partial<{ asOf: string; accountNumber: string }>
  >;

  const onSubmit = (values: { asOf: string; accountNumber: string }) => {
    setPayments([]);
    return RecurringPaymentsApi.getRecurringPayments(
      values.accountNumber,
      values.asOf
    )
      .then((res) => {
        if (res.data.length === 0) {
          notifyNoResults(values);
          return;
        } else {
          setPayments(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get payments');
      });
  };

  const notifyNoResults = (values: { asOf: string; accountNumber: string }) => {
    toast.info(
      `No payments are due to be processed for ${
        values.accountNumber
      } as of ${displayDateOnly(values.asOf)}`
    );
  };

  const generate = () => {
    const { asOf, accountNumber } = formInstance.getState().values;
    RecurringPaymentsApi.processRecurringPayments(accountNumber, asOf)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          formInstance.submit();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to process');
      });
  };
  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaRecycle className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Process Recurring Payments</h1>
          </div>
        </div>
      </div>
      <RFForm
        onSubmit={onSubmit}
        initialValues={{
          asOf: format(new Date(), 'yyyy-MM-dd'),
        }}
        render={({ handleSubmit, form, values, submitting }) => {
          formInstance = form;
          return (
            <Form onSubmit={handleSubmit}>
              <div className='d-flex align-items-center justify-content-center gap1Rem'>
                <Field
                  name='accountNumber'
                  label='Account'
                  options={insuranceCompanies}
                  optionMethod={(options: InsuranceCompany[]) =>
                    options.map((o) => (
                      <option
                        key={o.accountnumber}
                        value={o.accountnumber ?? ''}
                      >
                        {`${o.accountnumber} - ${o.shortname}`}
                      </option>
                    ))
                  }
                  validate={requiredField}
                  component={FieldBSRenderSelect}
                />
                <Field
                  name='asOf'
                  type='text'
                  label='As Of'
                  validate={requiredField}
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />

                <Button type='submit' variant='primary' size='sm'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Search'
                  )}
                </Button>
                <Button
                  type='button'
                  variant='outline-primary'
                  size='sm'
                  onClick={() => {
                    form.restart();
                  }}
                >
                  Reset
                </Button>
              </div>
            </Form>
          );
        }}
      />
      <>
        {payments.length > 0 && (
          <Alert variant='info'>
            <div className='d-flex justify-content-center align-items-center gap1Rem'>
              <p className='fw-bold mb-0'>
                The following recurring payments will be generated.
              </p>
              <Button variant='primary' size='sm' onClick={generate}>
                Generate
              </Button>
            </div>
          </Alert>
        )}
        <RecurringPaymentsTable recurringPayments={payments} />
      </>
    </PageScaffold>
  );
}
