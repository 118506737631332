import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ClaimCustomOption } from '../ApiTypes/ClaimCustomOption';

class ClaimCustomOptionApi {
  static getClaimCustomOptions(optionType: number) {
    return axios.get<ClaimCustomOption[]>(
      `${API_URL}/api/claimcustomoptions/${optionType}`,
      getAuthHeader()
    );
  }
  static getAllClaimCustomOptions() {
    return axios.get<ClaimCustomOption[]>(
      `${API_URL}/api/claimcustomoptions`,
      getAuthHeader()
    );
  }
}

export default ClaimCustomOptionApi;
