import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { Department } from '../ApiTypes/Department';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class DepartmentApi {
  static create(request: Department) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/departments`,
      request,
      getAuthHeader()
    );
  }
  static update(request: Department) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/departments/${request.departmentId}`,
      request,
      getAuthHeader()
    );
  }
  static delete(id: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/departments/${id}`,
      getAuthHeader()
    );
  }
}

export default DepartmentApi;
