import { useState, useEffect } from 'react';

export const useBlankModal = () => {
  const [showBlankModal, setShowBlankModal] = useState<boolean>(false);
  const [blankModalMessage, setBlankModalMessage] = useState<JSX.Element>(
    <div />
  );

  useEffect(() => {
    handleClose();
  }, [showBlankModal]);

  const handleClose = () => {
    if (!showBlankModal) {
      setBlankModalMessage(<div />);
    }
  };

  return {
    showBlankModal,
    blankModalMessage,
    setShowBlankModal,
    setBlankModalMessage,
  };
};
