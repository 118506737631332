import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { DeductibleInvoiceObj } from '../ApiTypes/DeductibleInvoiceObj';
import { ProcessDeductiblesDueRequest } from '../ApiTypes/ProcessDeductiblesDueRequest';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class DeductibleApi {
  static getDeductibles(
    accountNumber: string,
    serviceTo: string,
    policyNumber?: string
  ) {
    return axios.get<DeductibleInvoiceObj[]>(
      `${API_URL}/api/deductibles/forinvoice?accountnumber=${accountNumber}&policyNumber=${
        policyNumber ?? ''
      }&serviceTo=${serviceTo}`,
      getAuthHeader()
    );
  }
  static processDeductibles(request: ProcessDeductiblesDueRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/deductibles/forinvoice`,
      request,
      getAuthHeader()
    );
  }
}

export default DeductibleApi;
