import React, { useState, useEffect } from 'react';
import { Modal, Container, Button } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import EdiTransactionApi from '../../Api/EdiTransactionApi';
import ExportApi from '../../Api/ExportApi';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { downloadExcel } from '../../Utils';
import { ediExportHelper } from './EdiDetailExportHelper';
import EdiDetailTable from './EdiDetailTable';
import styles from './index.module.css';

export default function EdiDetailModal({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (s: boolean) => void;
}) {
  let { claimNumber } = useParams();

  const [transactions, setTransactions] = useState<EdiTransaction[]>([]);

  useEffect(() => {
    getTransactions();
  }, [claimNumber]);

  const getTransactions = () => {
    if (claimNumber) {
      EdiTransactionApi.getEdiTransactionsForClaim(claimNumber)
        .then((res) => {
          setTransactions(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleExport = () => {
    const data = ediExportHelper(transactions);
    data.fileName = `${claimNumber}-EDILog.xls`;

    return ExportApi.exportToExcel(data)
      .then((res) => {
        downloadExcel(res.data.fileName, res.data.file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='Claim-EDI-Detail-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Claim-EDI-Detail-modal'>
          <FaEye className='pe-1' /> Claim EDI Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid></Container>
        <div className='d-flex align-items-center gap1Rem mb-3'>
          <h4>Details for {claimNumber}</h4>
          <Button
            type='button'
            variant='primary'
            size='sm'
            onClick={() => handleExport()}
          >
            Export
          </Button>
        </div>
        <EdiDetailTable transactions={transactions} />
      </Modal.Body>
    </Modal>
  );
}
