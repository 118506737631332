import React, { useState, useEffect } from 'react';
import { Field, Form as RFForm } from 'react-final-form';
import { FaUser } from 'react-icons/fa';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { toast } from 'react-toastify';
import { Button, Form, Spinner } from 'react-bootstrap';
import UserApi from '../../Api/UserApi';
import { SiteFunction } from '../../ApiTypes/SiteFunction';
import { UserSiteFunction } from '../../ApiTypes/UserSiteFunction';
import SiteFunctionApi from '../../Api/SiteFunctionApi';
import UserSiteFunctionApi from '../../Api/UserSiteFunctionApi';
import { VwUser } from '../../ApiTypes/VwUser';
import { SiteFunctionGroups } from '../../ApiTypes/SiteFunctions';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { UpdateUserSiteFunctionsRequest } from '../../ApiTypes/UpdateUserSiteFunctionsRequest';

export default function VerifyUserPermissions() {
  const [userId, setUserId] = useState<string>('');
  const [userSiteFunctions, setUserSiteFunctions] = useState<
    UserSiteFunction[]
  >([]);

  const [siteFunctions, setSiteFunctions] = useState<SiteFunction[]>([]);

  const [users, setUsers] = useState<VwUser[]>([]);
  const [formValues, setFormValues] = useState<{
    userId: string;
    siteFunctionIds: string[];
  }>({
    userId: '',
    siteFunctionIds: [],
  });

  useEffect(() => {
    getUsers();
    getSiteFunctions();
  }, []);

  useEffect(() => {
    handleSetInitialValues();
  }, [userSiteFunctions]);
  useEffect(() => {
    getUserSiteFunctions();
  }, [userId]);

  const handleSetInitialValues = () => {
    const values = {
      userId,
      siteFunctionIds: userSiteFunctions
        .filter((x) => x.enabled)
        .map((x) => `${x.siteFunctionId}`),
    };
    setFormValues(values);
  };

  const getUsers = () => {
    UserApi.getUsersForMaintenance(true)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get users');
      });
  };

  const getSiteFunctions = () => {
    SiteFunctionApi.list()
      .then((res) => {
        setSiteFunctions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserSiteFunctions = () => {
    if (userId) {
      UserSiteFunctionApi.getForUser(userId)
        .then((res) => {
          setUserSiteFunctions(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get user site functions');
        });
    } else {
      setUserSiteFunctions([]);
    }
  };

  const onSubmit = (values: { userId: string; siteFunctionIds: string[] }) => {
    const request: UpdateUserSiteFunctionsRequest = {
      userId: values.userId,
      siteFunctionIds: values.siteFunctionIds.map((x) => +x),
    };
    return UserSiteFunctionApi.update(request)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaUser className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>User Permissions</h1>
          </div>
          <div className='ms-3'></div>
        </div>
      </div>
      <RFForm
        onSubmit={onSubmit}
        initialValues={formValues}
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div>
                <div className='d-flex justify-content-center'>
                  <Field
                    name='userId'
                    label='User'
                    options={users}
                    onChange={setUserId}
                    optionMethod={(options: VwUser[]) =>
                      options.map((o) => (
                        <option key={o.userId} value={o.userId}>
                          {o.userName}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  />
                </div>
                <fieldset className='mb-3'>
                  <legend className='fw-bold fs-3'>Claim Functions</legend>
                  <div className={`${styles.grid5NoHeight}`}>
                    {siteFunctions
                      .filter((x) => x.groupId === SiteFunctionGroups.Claims)
                      .map((x) => {
                        return (
                          <Field
                            key={`${x.siteFunctionId}`}
                            name='siteFunctionIds'
                            type='checkbox'
                            label={`${x.functionName}`}
                            value={`${x.siteFunctionId}`}
                            checked={values.siteFunctionIds.includes(
                              `${x.siteFunctionId}`
                            )}
                            component={FieldBSRenderCheckbox}
                          />
                        );
                      })}
                  </div>
                </fieldset>
                <fieldset className='my-3'>
                  <legend className='fw-bold fs-3'>Report Functions</legend>
                  <div className={`${styles.grid5NoHeight}`}>
                    {siteFunctions
                      .filter((x) => x.groupId === SiteFunctionGroups.Reports)
                      .map((x) => {
                        return (
                          <Field
                            key={`${x.siteFunctionId}`}
                            name='siteFunctionIds'
                            type='checkbox'
                            label={`${x.functionName}`}
                            value={`${x.siteFunctionId}`}
                            checked={values.siteFunctionIds.includes(
                              `${x.siteFunctionId}`
                            )}
                            component={FieldBSRenderCheckbox}
                          />
                        );
                      })}
                  </div>
                </fieldset>
                <fieldset className='my-3'>
                  <legend className='fw-bold fs-3'>Document Functions</legend>
                  <div className={`${styles.grid5NoHeight}`}>
                    {siteFunctions
                      .filter((x) => x.groupId === SiteFunctionGroups.Documents)
                      .map((x) => {
                        return (
                          <Field
                            key={`${x.siteFunctionId}`}
                            name='siteFunctionIds'
                            type='checkbox'
                            label={`${x.functionName}`}
                            value={`${x.siteFunctionId}`}
                            checked={values.siteFunctionIds.includes(
                              `${x.siteFunctionId}`
                            )}
                            component={FieldBSRenderCheckbox}
                          />
                        );
                      })}
                  </div>
                </fieldset>
                <fieldset className='my-3'>
                  <legend className='fw-bold fs-3'>Payment Functions</legend>
                  <div className={`${styles.grid5NoHeight}`}>
                    {siteFunctions
                      .filter((x) => x.groupId === SiteFunctionGroups.Payments)
                      .map((x) => {
                        return (
                          <Field
                            key={`${x.siteFunctionId}`}
                            name='siteFunctionIds'
                            type='checkbox'
                            label={`${x.functionName}`}
                            value={`${x.siteFunctionId}`}
                            checked={values.siteFunctionIds.includes(
                              `${x.siteFunctionId}`
                            )}
                            component={FieldBSRenderCheckbox}
                          />
                        );
                      })}
                  </div>
                </fieldset>
                <fieldset className='my-3'>
                  <legend className='fw-bold fs-3'>Provider Functions</legend>
                  <div className={`${styles.grid5NoHeight}`}>
                    {siteFunctions
                      .filter((x) => x.groupId === SiteFunctionGroups.Providers)
                      .map((x) => {
                        return (
                          <Field
                            key={`${x.siteFunctionId}`}
                            name='siteFunctionIds'
                            type='checkbox'
                            label={`${x.functionName}`}
                            value={`${x.siteFunctionId}`}
                            checked={values.siteFunctionIds.includes(
                              `${x.siteFunctionId}`
                            )}
                            component={FieldBSRenderCheckbox}
                          />
                        );
                      })}
                  </div>
                </fieldset>
                <fieldset className='my-3'>
                  <legend className='fw-bold fs-3'>WCPanel Functions</legend>
                  <div className={`${styles.grid5NoHeight}`}>
                    {siteFunctions
                      .filter((x) => x.groupId === SiteFunctionGroups.WCPanels)
                      .map((x) => {
                        return (
                          <Field
                            key={`${x.siteFunctionId}`}
                            name='siteFunctionIds'
                            type='checkbox'
                            label={`${x.functionName}`}
                            value={`${x.siteFunctionId}`}
                            checked={values.siteFunctionIds.includes(
                              `${x.siteFunctionId}`
                            )}
                            component={FieldBSRenderCheckbox}
                          />
                        );
                      })}
                  </div>
                </fieldset>
                <fieldset className='my-3'>
                  <legend className='fw-bold fs-3'>Setup Functions</legend>
                  <div className={`${styles.grid5NoHeight}`}>
                    {siteFunctions
                      .filter((x) => x.groupId === SiteFunctionGroups.Setup)
                      .map((x) => {
                        return (
                          <Field
                            key={`${x.siteFunctionId}`}
                            name='siteFunctionIds'
                            type='checkbox'
                            label={`${x.functionName}`}
                            value={`${x.siteFunctionId}`}
                            checked={values.siteFunctionIds.includes(
                              `${x.siteFunctionId}`
                            )}
                            component={FieldBSRenderCheckbox}
                          />
                        );
                      })}
                  </div>
                </fieldset>
              </div>
              <div className='d-flex justify-content-center align-items-center py-3'>
                <Button type='submit' size='sm' variant='primary'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    </PageScaffold>
  );
}
