import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaHammer } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CompCompleteApi from '../../Api/CompCompleteApi';
import DocumentsApi from '../../Api/DocumentsApi';
import { CcExceptionObj } from '../../ApiTypes/CcExceptionObj';
import { UpdateCcClaimNumberRequest } from '../../ApiTypes/UpdateCcClaimNumberRequest';
import { UpdateControlNumberRequest } from '../../ApiTypes/UpdateControlNumberRequest';
import DocViewModal from '../DocViewModal/DocViewModal';
import { useDocViewModal } from '../DocViewModal/useDocViewModal';
import PageScaffold from '../PageScaffold/PageScaffold';
import CompCompleteExceptionTable from './CompCompleteExceptionTable';
import styles from './index.module.css';
import UpdateClaimNumber from './UpdateClaimNumber';
import UpdateControlNumber from './UpdateControlNumber';

export default function CompCompleteExceptions() {
  const [fetching, setFetching] = useState(false);
  const [exceptions, setExceptions] = useState<CcExceptionObj[]>([]);
  const [selectedDoc, setSelectedDoc] = useState<CcExceptionObj | null>(null);
  const [rowSelection, setRowSelection] = React.useState({});
  const [showUpdateClaimNumber, setShowUpdateClaimNumber] =
    useState<boolean>(false);
  const [showUpdateControlNumber, setShowUpdateControlNumber] =
    useState<boolean>(false);

  const {
    showDocViewModal,
    fileDownload,
    setShowDocViewModal,
    setFileDownload,
  } = useDocViewModal();

  useEffect(() => {
    getExceptions();
  }, []);

  const getExceptions = () => {
    setFetching(true);
    CompCompleteApi.getCompCompleteExceptions()
      .then((res) => {
        setExceptions(res.data);
        setFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setFetching(false);
        toast.error('Failed to get exceptions');
      });
  };

  const handleViewDoc = () => {
    if (selectedDoc) {
      getScanDocFile(selectedDoc.docId);
    }
  };

  const getScanDocFile = (docId: number) => {
    return DocumentsApi.getScanDocFile(docId)
      .then((res) => {
        if (res.data.file.length > 1) {
          setFileDownload(res.data);
          setShowDocViewModal(true);
        } else {
          toast.error('Failed to get document');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get document');
      });
  };

  const handleUpdateClaimNumber = (request: UpdateCcClaimNumberRequest) => {
    return CompCompleteApi.updateClaimNumber(request)
      .then((res) => {
        if (res.data.success) {
          getExceptions();
          setShowUpdateClaimNumber(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update claim number');
      });
  };
  const handleUpdateControlNumber = (request: UpdateControlNumberRequest) => {
    return CompCompleteApi.updateControlNumber(request)
      .then((res) => {
        if (res.data.success) {
          getExceptions();
          setShowUpdateControlNumber(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update control number');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaHammer className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>CompComplete Import Exceptions</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center my-3'>
        <Button
          type='button'
          variant='primary'
          size='sm'
          onClick={getExceptions}
        >
          {fetching ? (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            'Refresh'
          )}
        </Button>
      </div>
      <CompCompleteExceptionTable
        data={exceptions}
        getDocs={getExceptions}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        setSelectedDoc={setSelectedDoc}
      />
      <div className='d-flex justify-content-between align-items-center gap1Rem mt-3 pb-3'>
        <Link
          to={`/scandocs/${selectedDoc?.docId}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          <Button
            type='button'
            variant='outline-primary'
            size='sm'
            onClick={() => {
              // handleViewDoc();
            }}
          >
            View Doc
          </Button>
        </Link>
        <Link
          to={
            selectedDoc?.ourClaimNum
              ? `/claims/${selectedDoc?.ourClaimNum}`
              : ''
          }
        >
          <Button type='button' variant='outline-primary' size='sm'>
            View Our Claim
          </Button>
        </Link>
        <Link
          to={
            selectedDoc?.ccClaimNum ? `/claims/${selectedDoc?.ccClaimNum}` : ''
          }
        >
          <Button type='button' variant='outline-primary' size='sm'>
            View CC Claim
          </Button>
        </Link>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={() => {
            if (selectedDoc) {
              setShowUpdateClaimNumber(true);
            }
          }}
        >
          Release Bill With Claims Info (Fixed in CC)
        </Button>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={() => {
            if (selectedDoc) {
              setShowUpdateControlNumber(true);
            }
          }}
        >
          Update Our Control Number
        </Button>
      </div>
      <UpdateClaimNumber
        show={showUpdateClaimNumber}
        setShow={setShowUpdateClaimNumber}
        exception={selectedDoc}
        submit={handleUpdateClaimNumber}
      />
      <UpdateControlNumber
        show={showUpdateControlNumber}
        setShow={setShowUpdateControlNumber}
        exception={selectedDoc}
        submit={handleUpdateControlNumber}
      />
      <DocViewModal
        show={showDocViewModal}
        setShow={setShowDocViewModal}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
    </PageScaffold>
  );
}
