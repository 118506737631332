import { RowSelectionState } from '@tanstack/react-table';
import React from 'react';
import { ClaimSearchRequest } from '../../ApiTypes/ClaimSearchRequest';
import { ClaimSearchResult } from '../../ApiTypes/ClaimSearchResult';
import ClaimSearch from './ClaimSearch';
import ClaimTable from './ClaimTable';
import styles from './index.module.css';

export default function ClaimSide({
  search,
  searchResults,
  selectedSearchResult,
  setSelectedSearchResult,
  rowSelection,
  setRowSelection,
}: {
  searchResults: ClaimSearchResult[];
  search: (values: ClaimSearchRequest) => Promise<void>;
  selectedSearchResult: ClaimSearchResult | null;
  setSelectedSearchResult: (r: ClaimSearchResult | null) => void;
  rowSelection: RowSelectionState;
  setRowSelection: (obj: object) => void;
}) {
  return (
    <div>
      <ClaimSearch search={search} />
      <ClaimTable
        data={searchResults}
        getSelectedClaim={setSelectedSearchResult}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
      <div className='mt-3'>
        <p className='fw-bold text-center'>Selected Claim</p>
        <div className={`${styles.outerGrid}`}>
          <div className={`${styles.selectedClaimGrid}`}>
            <span>Name</span>
            <span>
              {selectedSearchResult?.firstName} {selectedSearchResult?.lastName}
            </span>
            <span>Claim No</span>
            <span>{selectedSearchResult?.claimNo}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
