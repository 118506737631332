import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { WcPanel } from '../../ApiTypes/WcPanel';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { displayDateOnly } from '../../Utils';
import { Link } from 'react-router-dom';

export default function WCPanelTable({
  data,
  handleDelete,
}: {
  data: WcPanel[];
  handleDelete: (panel: WcPanel) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<WcPanel>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center align-items-center gap1Rem'>
            <Link to={`/wcpanels/${row.original.id}`}>
              <Button type='button' variant='primary' size='sm' title='Edit'>
                <FaEdit />
              </Button>
            </Link>
            <Button
              type='button'
              variant='danger'
              size='sm'
              title='Delete'
              onClick={() => {
                handleDelete(row.original);
              }}
            >
              <FaTrash />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Name',
      accessorKey: 'name',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Year',
      accessorFn: (d) => `${d.year}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Doc Id',
      accessorFn: (d) => `${d.docId}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Created By',
      accessorKey: 'createdBy',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date Modified',
      accessorKey: 'dateModified',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dateModified ?? ''),
    },
    {
      header: 'Notes',
      accessorKey: 'notes',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
