import React from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import styles from './index.module.css';
import ClassCodes from './ClassCodes';

export default function ClassCodesModal({
  show,
  setShow,
  policyId,
  isExcess,
  refreshData,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  policyId?: number;
  isExcess?: boolean;
  refreshData: () => void;
}) {
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='ClassCodesModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='ClassCodesModal-modal'>
          <FaCheck className='pe-1' /> Class Codes
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <ClassCodes
            policyId={policyId}
            isExcess={isExcess}
            refreshData={refreshData}
            setShow={setShow}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
