import React from 'react';
import { Field } from 'react-final-form';
import { EdiCode } from '../../ApiTypes/EdiCode';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { useAppSelector } from '../../Reducers/Store';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';

export default function DenialPage({
  values,
  disabledFields,
}: {
  values: EdiTransaction;
  disabledFields: string[];
}) {
  const { ediCodes } = useAppSelector((state) => state.reference);
  return (
    <div>
      <Field
        name='fullDenialEffectiveDate'
        label='Full Denial Effective Date'
        parse={parseDatesForServer}
        component={FieldBSRenderDate}
        validate={requiredField}
        disabled={disabledFields.includes('fullDenialEffectiveDate')}
      />
      <Field
        name='fullDenialReasonCode'
        label='Full Denial Reason'
        options={ediCodes.filter((c) => c.dn === '0198')}
        optionMethod={(options: EdiCode[]) =>
          options.map((o) => (
            <option key={o.code} value={o.code!}>
              {o.description}
            </option>
          ))
        }
        validate={
          !disabledFields.includes('fullDenialReasonCode')
            ? requiredField
            : undefined
        }
        disabled={disabledFields.includes('fullDenialReasonCode')}
        component={FieldBSRenderSelect}
      />
      <Field
        name='partialDenialReasonCode'
        label='Partial Denial Reason'
        options={ediCodes.filter((c) => c.dn === '0294')}
        optionMethod={(options: EdiCode[]) =>
          options.map((o) => (
            <option key={o.code} value={o.code!}>
              {o.description}
            </option>
          ))
        }
        validate={
          !disabledFields.includes('partialDenialReasonCode')
            ? requiredField
            : undefined
        }
        disabled={disabledFields.includes('partialDenialReasonCode')}
        component={FieldBSRenderSelect}
      />
      <Field
        name='denialNarrative'
        label='Denial Narrative'
        rows={3}
        validate={requiredField}
        component={FieldBSRenderTextArea}
      />
    </div>
  );
}
