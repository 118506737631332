import React, { useState } from 'react';
import { FaUserClock } from 'react-icons/fa';
import { Spinner, Form, Button } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { addDays, addMonths, format } from 'date-fns';
import { downloadExcel, parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import AdjustersApi from '../../Api/AdjustersApi';
import { ProductivityObj } from '../../ApiTypes/ProductivityObj';
import ProductivityTable from './ProductivityTable';
import ExportApi from '../../Api/ExportApi';
import { exportHandler } from './ProductivityExport';

export default function Productivity() {
  const [data, setData] = useState<ProductivityObj[]>([]);
  const onSubmit = (values: { fromDate: string; toDate: string }) => {
    return getProductivity(values.fromDate, values.toDate);
  };
  const getExport = () => {
    if (data.length > 0) {
      var toSend = exportHandler(data);
      toSend.fileName = 'ProductivityReport.xls';
      return ExportApi.exportToExcel(toSend)
        .then((res) => {
          downloadExcel(res.data.fileName, res.data.file);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getProductivity = (fromDate: string, toDate: string) => {
    return AdjustersApi.adjustersProductivity(fromDate, toDate)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaUserClock className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Productivity</h1>
          </div>
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-between'>
        <RFForm
          onSubmit={onSubmit}
          initialValues={{
            fromDate: format(addMonths(new Date(), -1), 'yyyy-MM-dd'),
            toDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
          }}
          render={({ handleSubmit, form, values, submitting }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div className='d-flex  align-items-center gap1Rem'>
                  <Field
                    name='fromDate'
                    type='text'
                    label='Date From'
                    validate={requiredField}
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                  />
                  <Field
                    name='toDate'
                    type='text'
                    label='To Date'
                    validate={requiredField}
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                  />

                  <Button type='submit' variant='primary' size='sm'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Search'
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        />
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={getExport}
        >
          Export
        </Button>
      </div>
      <ProductivityTable data={data} />
    </PageScaffold>
  );
}
