import { format } from 'date-fns';
import { FormApi } from 'final-form';
import React, { useState, useEffect } from 'react';
import {
  Accordion,
  Button,
  Container,
  Form,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import { Field, Form as RFFForm } from 'react-final-form';
import { FaChevronCircleLeft, FaEdit } from 'react-icons/fa';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  fetchAppealRulings,
  fetchAwardRulings,
  fetchDispositionTypes,
  fetchJudges,
} from '../../../Actions/ReferenceActions';
import LegalApi from '../../../Api/LegalApi';
import { DispositionType } from '../../../ApiTypes/DispositionType';
import { Hearing } from '../../../ApiTypes/Hearing';
import { useAppDispatch, useAppSelector } from '../../../Reducers/Store';
import { parseDatesForServer } from '../../../Utils';
import { requiredField } from '../../../Utils/FieldValidation';
import FieldBSRenderDate from '../../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../../Common/FieldRenderTextArea';

import styles from './index.module.css';
import { Options } from 'devextreme-react/autocomplete';
import { Judge } from '../../../ApiTypes/Judge';
import { AppealRuling } from '../../../ApiTypes/AppealRuling';
import { AwardRuling } from '../../../ApiTypes/AwardRuling';
import FieldBSRenderCheckbox from '../../Common/FieldBSRenderCheckbox';

export default function HearingAddEdit({
  selectedHearingId,
  show,
  setShow,
  getHearings,
  hearingIsReset,
}: {
  selectedHearingId: string | '';
  show: boolean;
  setShow: () => void;
  getHearings: () => void;
  hearingIsReset: boolean;
}) {
  let { claimNumber } = useParams();
  let hearingId = selectedHearingId;
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useAppDispatch();

  const [hearing, setHearing] = useState<Hearing | null>(null);
  const [isReset, setIsReset] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<AccordionEventKey>('');

  let formInstance: FormApi<Hearing, Partial<Hearing>>;

  const { claim, claimant } = useAppSelector(
    (state) => state.currentClaimReducer
  );

  const { dispositionTypes, judges, appealRulings, awardRulings } =
    useAppSelector((state) => state.reference);

  const { userModel } = useAppSelector((state) => state.user);

  const getReferences = async () => {
    if (
      !dispositionTypes.length ||
      !judges.length ||
      !appealRulings.length ||
      !awardRulings.length
    ) {
      await Promise.all([
        dispatch(fetchDispositionTypes()),
        dispatch(fetchJudges()),
        dispatch(fetchAppealRulings()),
        dispatch(fetchAwardRulings()),
      ]);
    }
  };

  useEffect(() => {
    getReferences();
  }, []);

  useEffect(() => {
    getHearingById();
    // checkIsReset();
  }, [claimNumber, hearingId, location]);

  const checkIsReset = () => {
    if (hearingIsReset) {
      formInstance.change('hearingDate', format(new Date(), 'yyyy-MM-dd'));
      setIsReset(true);
    } else {
      setIsReset(false);
    }
  };

  const getHearingById = () => {
    if (claimNumber && hearingId && hearingId !== '') {
      LegalApi.getHearingById(claimNumber, +hearingId)
        .then((res) => {
          setHearing(res.data);
          setTimeout(checkIsReset, 300);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setHearing(null);
    }
  };

  const onSubmit = (values: Hearing) => {
    const copy = { ...values };
    copy.claimNo = claimNumber ?? '';
    copy.creator = userModel?.user?.userId ?? '';
    if (copy.hearingId && !isReset) {
      return updateHearing(copy);
    }
    return createHearing(copy);
  };

  const createHearing = (v: Hearing) => {
    v.hearingId = 0;
    return LegalApi.createHearing(v, claimNumber!)
      .then((res) => {
        if (res.data.success) {
          getHearings();
          setShow();
          toast.success('Success');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create hearing');
      });
  };

  const updateHearing = (v: Hearing) => {
    return LegalApi.updateHearing(v, claimNumber!)
      .then((res) => {
        if (res.data.success) {
          getHearings();
          setShow();
          toast.success('Success');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create hearing');
      });
  };

  const createDispositionType = (values: any) => {
    const type: DispositionType = {
      name: values.name,
      description: values.name,
      dispositionTypeId: 0,
    };

    return LegalApi.createDispositionType(type)
      .then((res) => {
        if (res.data.success) {
          dispatch(fetchDispositionTypes());
          setActiveKey('');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create type');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        // runCleanUp();
        setShow();
      }}
      dialogClassName=''
      aria-labelledby='Edit-Create-Hearing-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Edit-Create-Hearing-Form-modal'
        >
          <FaEdit className='pe-1' />
          {hearing ? 'Edit' : 'Create'} Hearing
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className='w-100 h-100'>
            <div className={`${styles.addEditSettlementTopDetails}`}>
              <p>Claim No: {claim?.claimNo}</p>
              <p>
                Claimant: {claimant?.firstName} {claimant?.lastName}
              </p>
            </div>

            <RFFForm
              onSubmit={onSubmit}
              initialValues={
                hearing ?? {
                  hearingId: 0,
                }
              }
              render={({ handleSubmit, form, values, submitting }) => {
                formInstance = form;
                return (
                  <Form onSubmit={handleSubmit}>
                    <div className='d-flex'>
                      <div className={`${styles.column33} pe-3`}>
                        <Field
                          name='hearingDate'
                          label='Hearing Date'
                          parse={parseDatesForServer}
                          component={FieldBSRenderDate}
                          validate={requiredField}
                        />
                      </div>
                      <div className={`${styles.column33} pe-3`}>
                        <Field
                          name='judgeId'
                          label='Judge'
                          options={judges}
                          optionMethod={(options: Judge[]) =>
                            options.map((o) => (
                              <option key={o.id} value={o.id}>
                                {(o.firstName ?? '') + ' ' + (o.lastName ?? '')}
                              </option>
                            ))
                          }
                          component={FieldBSRenderSelect}
                        />
                      </div>
                    </div>
                    <div>
                      <Field
                        name='notes'
                        label='Reason for Request'
                        rows={3}
                        component={FieldBSRenderTextArea}
                      />
                    </div>
                    <div className='d-flex'>
                      <div className={`${styles.column33} pe-3`}>
                        <Field
                          name='dispositionTypeId'
                          label='Disposition'
                          options={dispositionTypes}
                          optionMethod={(options: DispositionType[]) =>
                            options.map((o) => (
                              <option
                                key={o.dispositionTypeId}
                                value={o.dispositionTypeId}
                              >
                                {o.name}
                              </option>
                            ))
                          }
                          component={FieldBSRenderSelect}
                        />
                      </div>
                      <div className={`${styles.column33} pe-3`}>
                        <Field
                          name='numberOfSettings'
                          label='# of Settings'
                          options={[
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                            16, 17, 18, 19, 20,
                          ]}
                          component={FieldBSRenderSelect}
                        />
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className={`${styles.column33} pe-3`}>
                        <Field
                          name='appealedDate'
                          label='Appealed Date'
                          parse={parseDatesForServer}
                          component={FieldBSRenderDate}
                        />
                      </div>
                      <div className={`${styles.column33} pe-3`}>
                        <Field
                          name='appealedRulingId'
                          label='Appeal Ruling'
                          options={appealRulings}
                          optionMethod={(options: AppealRuling[]) =>
                            options.map((o) => (
                              <option key={o.id} value={o.id}>
                                {o.name}
                              </option>
                            ))
                          }
                          component={FieldBSRenderSelect}
                        />
                      </div>
                      <div className={`${styles.column33} pe-3 my-auto`}>
                        <Field
                          name='appealed'
                          type='checkbox'
                          label='Appealed?'
                          checked={!!values?.appealed}
                          component={FieldBSRenderCheckbox}
                        />
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className={`${styles.column33} pe-3`}>
                        <Field
                          name='dateOfReward'
                          label='Award Date'
                          parse={parseDatesForServer}
                          component={FieldBSRenderDate}
                        />
                      </div>
                      <div className={`${styles.column33} pe-3`}>
                        <Field
                          name='awardRulingId'
                          label='Award Ruling'
                          options={awardRulings}
                          optionMethod={(options: AwardRuling[]) =>
                            options.map((o) => (
                              <option key={o.id} value={o.id}>
                                {o.name}
                              </option>
                            ))
                          }
                          component={FieldBSRenderSelect}
                        />
                      </div>
                    </div>
                    <div className={`${styles.maxWidth700}`}>
                      <Button
                        type='submit'
                        variant='primary'
                        size='sm'
                        className='m-auto d-block'
                      >
                        {submitting ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          'Save'
                        )}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            />
            <div className={`${styles.formRow}`}>
              {userModel?.user?.userId === 'cedwards' && (
                <Accordion
                  onSelect={(eventKey: AccordionEventKey) =>
                    setActiveKey(eventKey)
                  }
                  activeKey={activeKey}
                  className='mb-3'
                >
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header>New Disposition Type</Accordion.Header>
                    <Accordion.Body>
                      <RFFForm
                        initialValues={{}}
                        onSubmit={createDispositionType}
                        render={({ handleSubmit, submitting }) => {
                          return (
                            <Form onSubmit={handleSubmit}>
                              <Field
                                name='name'
                                label='Name'
                                component={FieldBSRenderText}
                              />
                              <div className={`${styles.maxWidth700}`}>
                                <Button
                                  type='submit'
                                  variant='primary'
                                  size='sm'
                                  className='m-auto d-block'
                                >
                                  {submitting ? (
                                    <Spinner
                                      as='span'
                                      animation='grow'
                                      size='sm'
                                      role='status'
                                      aria-hidden='true'
                                    />
                                  ) : (
                                    'Save'
                                  )}
                                </Button>
                              </div>
                            </Form>
                          );
                        }}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
            </div>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
