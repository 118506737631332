import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FaFolderOpen } from 'react-icons/fa';
import { toast } from 'react-toastify';
import DocumentsApi from '../../Api/DocumentsApi';
import UserEmailListApi from '../../Api/UserEmailListApi';
import { SecureShare } from '../../ApiTypes/SecureShare';
import { SecureShareDocumentRequest } from '../../ApiTypes/SecureShareDocumentRequest';
import { UserEmailList } from '../../ApiTypes/UserEmailList';
import { useAppSelector } from '../../Reducers/Store';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import Loading from '../Common/Loading';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import SecureShareModal from './SecureShareModal';
import SharedFilesTable from './SharedFilesTable';

export default function SharedFilesPage() {
  const [secureShares, setSecureShares] = useState<SecureShare[]>([]);
  const [shareToDelete, setShareToDelete] = useState<SecureShare | null>(null);
  const [shareToEdit, setShareToEdit] = useState<SecureShare | null>(null);
  const [userEmailList, setUserEmailList] = useState<UserEmailList[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);

  const { userModel } = useAppSelector((state) => state.user);

  useEffect(() => {
    getSS();
  }, []);

  useEffect(() => {
    getUserEmailList();
  }, [userModel]);

  const getSS = () => {
    getSecureSharesForUser();
  };

  const getSecureSharesForUser = () => {
    setFetching(true);
    DocumentsApi.getSecureShareForUser()
      .then((res) => {
        setSecureShares(res.data);
        setFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setFetching(false);
      });
  };

  const getUserEmailList = () => {
    if (userModel?.user?.userId) {
      UserEmailListApi.getEmailList(userModel?.user?.userId)
        .then((res) => {
          setUserEmailList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleDelete = () => {
    if (shareToDelete) {
      return DocumentsApi.deleteSecureShare(shareToDelete.id)
        .then((res) => {
          if (res.data.success) {
            getSS();
            setShareToDelete(null);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to delete secure share');
        });
    }
    return Promise.resolve();
  };

  const handleSecureShare = (values: SecureShareDocumentRequest) => {
    return DocumentsApi.updateSecureShare(values)
      .then((res) => {
        if (res.data.success) {
          setShareToEdit(null);
          getSS();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to share documents');
      });
  };

  const setEditShare = (s: SecureShare) => {
    setShareToEdit(s);
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaFolderOpen className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Shared Files</h1>
            <p>Files shared with 3rd parties.</p>
          </div>
        </div>
      </div>
      <>{fetching && <Loading />}</>
      <SharedFilesTable
        sharedFiles={secureShares}
        setEditShare={setEditShare}
        setShareToDelete={setShareToDelete}
      />
      <SecureShareModal
        show={shareToEdit != null}
        setShow={(s: boolean) => setShareToEdit(null)}
        claimNumber={shareToEdit?.claimNo ?? ''}
        docs={[]}
        handleSecureShare={handleSecureShare}
        userEmailList={userEmailList}
        share={shareToEdit}
      />
      <DeleteConfirmationModal
        show={shareToDelete !== null}
        setShow={() => {}}
        setNull={setShareToDelete}
        handleDelete={handleDelete}
      />
    </PageScaffold>
  );
}
