import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { UserSiteFunction } from '../ApiTypes/UserSiteFunction';
import { UpdateUserSiteFunctionsRequest } from '../ApiTypes/UpdateUserSiteFunctionsRequest';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class UserSiteFunctionApi {
  static getForUser(userId: string) {
    return axios.get<UserSiteFunction[]>(
      `${API_URL}/api/usersitefunctions/${userId}`,
      getAuthHeader()
    );
  }
  static update(request: UpdateUserSiteFunctionsRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/usersitefunctions`,
      request,
      getAuthHeader()
    );
  }
}

export default UserSiteFunctionApi;
