import React, { useState, useEffect } from 'react';
import { Form as RFForm, Field } from 'react-final-form';
import {
  Accordion,
  Button,
  Container,
  Form,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { FaCheck, FaEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ExcessCarrierApi from '../../Api/ExcessCarrierApi';
import ExcessCarrierPolicyApi from '../../Api/ExcessCarrierPolicyApi';
import { ExcessCarrier } from '../../ApiTypes/ExcessCarrier';
import { ExcessCarrierPolicy } from '../../ApiTypes/ExcessCarrierPolicy';
import { useAppSelector } from '../../Reducers/Store';
import styles from './index.module.css';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import { parseDatesForServer } from '../../Utils';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import { centsValidation, requiredField } from '../../Utils/FieldValidation';
import { formatNumbers, cleanMoney } from '../../Utils/InputFormatters';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import { ExcessCarrierPolicyRequest } from '../../ApiTypes/ExcessCarrierPolicyRequest';
import PolicyClassCodes from '../PolicyClassCodes/PolicyClassCodes';

interface ExcessCarrierPolicyFormType extends ExcessCarrierPolicy {
  year: number;
}

export default function AddEditExcessPolicy({
  show,
  setShow,
  excessPolicyId,
  excessCarrierId,
  refreshData,
  setNull,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  excessPolicyId: number;
  excessCarrierId: number;
  refreshData: () => void;
  setNull: () => void;
}) {
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );

  const [excessPolicy, setExcessPolicy] = useState<ExcessCarrierPolicy | null>(
    null
  );
  const [excessCarrier, setExcessCarrier] = useState<ExcessCarrier | null>(
    null
  );

  const [years, setYears] = useState<number[]>([]);

  const [activeKey, setActiveKey] = useState<AccordionEventKey>('');

  useEffect(() => {
    getExcessPolicy();
  }, [excessPolicyId]);

  useEffect(() => {
    getExcessCarrier(excessCarrierId);
  }, [excessCarrierId]);

  useEffect(() => {
    loadYears();
  }, []);

  const loadYears = () => {
    var ys: number[] = [];
    var thisYear = new Date().getFullYear() + 1;
    for (let i = 0; i <= 25; i++) {
      ys.push(thisYear - i);
    }
    setYears(ys);
  };

  const getExcessPolicy = () => {
    if (excessPolicyId) {
      ExcessCarrierPolicyApi.getById(excessPolicyId)
        .then((res) => {
          setExcessPolicy(res.data);
          if (res.data.excessCarrierId && !excessCarrierId) {
            getExcessCarrier(res.data.excessCarrierId);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get policy');
        });
    }
  };

  const getExcessCarrier = (excessCarrierId: number) => {
    if (excessCarrierId) {
      ExcessCarrierApi.getById(excessCarrierId)
        .then((res) => {
          setExcessCarrier(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get carrier');
        });
    }
  };

  const handleActiveKey = (eventKey: AccordionEventKey) => {
    if (!excessPolicy) {
      setActiveKey('');
      toast.warn('Save policy before adding class codes');
    } else {
      setActiveKey(eventKey);
    }
  };

  const getExcessCarrierPolicyById = (excessPolicyId: number) => {
    ExcessCarrierPolicyApi.getById(excessPolicyId)
      .then((res) => {
        setExcessPolicy(res.data);
        if (res.data.excessCarrierId && !excessCarrierId) {
          getExcessCarrier(res.data.excessCarrierId);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get policy');
      });
  };

  const onSubmit = (values: ExcessCarrierPolicyFormType) => {
    const toSend: ExcessCarrierPolicyRequest = {
      excessCarrierPolicy: values,
      isUpdate: excessPolicyId !== 0,
      year: values.year,
    };

    ExcessCarrierPolicyApi.createUpdate(toSend)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          refreshData();
          setNull();
          if (toSend.isUpdate) {
            setShow(false);
          } else {
            getExcessCarrierPolicyById(+res.data.affectedEntityIdentifier);
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to process request');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='AddEditExcessPolicy-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='AddEditExcessPolicy-modal'
        >
          <FaEdit className='pe-1' /> Excess Policy
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              carrierName: excessCarrier?.name,
              ...excessPolicy,
              excessCarrierId: excessCarrierId,
              year: excessPolicy?.treatyYear,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='carrierName'
                    type='text'
                    label='Excess Carrier'
                    readOnly
                    component={FieldBSRenderText}
                  />
                  <div className={styles.flex2}>
                    <Field
                      name='carrierPolicyNo'
                      type='text'
                      label='Policy Number'
                      validate={requiredField}
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='treatyYear'
                      type='text'
                      label='Treaty Year'
                      component={FieldBSRenderText}
                    />
                  </div>
                  <div className={styles.flex2}>
                    <Field
                      name='startDate'
                      label='Start Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                    <Field
                      name='endDate'
                      label='End Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                  </div>
                  <div className={styles.flex2}>
                    <Field
                      name='premium'
                      label='Premium'
                      type='text'
                      format={formatNumbers}
                      parse={cleanMoney}
                      validate={centsValidation}
                      component={FieldBSRenderMoney}
                    />
                    <Field
                      name='defaultSir'
                      label='Default SIR'
                      type='text'
                      format={formatNumbers}
                      parse={cleanMoney}
                      validate={centsValidation}
                      component={FieldBSRenderMoney}
                    />
                  </div>
                  <div className={styles.flex2}>
                    <div className={styles.flex2}>
                      <Field
                        name='audited'
                        label='Audited?'
                        type='checkbox'
                        checked={values.audited === true}
                        component={FieldBSRenderCheckbox}
                      />
                      <Field
                        name='isUssu'
                        label='USSU?'
                        type='checkbox'
                        checked={values.isUssu === true}
                        component={FieldBSRenderCheckbox}
                      />
                    </div>
                    <Field
                      name='corridorDeductaible'
                      label='Corridor Deductible'
                      type='text'
                      format={formatNumbers}
                      parse={cleanMoney}
                      validate={centsValidation}
                      component={FieldBSRenderMoney}
                    />
                  </div>
                  <p className='fw-bold'>
                    Select an account to assign policy to
                  </p>
                  <div className={styles.grid2}>
                    <Field
                      name='year'
                      label='Treaty Year'
                      options={years}
                      component={FieldBSRenderSelect}
                    />
                    <Field
                      name='accountnumber'
                      label='Account'
                      options={[...insuranceCompaniesForUser].sort((a, b) =>
                        a.shortname!.localeCompare(b.shortname!)
                      )}
                      optionMethod={(options: InsuranceCompany[]) =>
                        options.map((o) => (
                          <option
                            key={o.accountnumber!}
                            value={o.accountnumber!}
                          >
                            {`${o.accountnumber} - ${o.shortname}`}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                  </div>
                  <div className='d-flex justify-content-around align-items-center py-3'>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type='submit' size='sm' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save Policy'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
          <Accordion
            onSelect={(eventKey: AccordionEventKey) =>
              handleActiveKey(eventKey)
            }
            activeKey={activeKey}
            className='mb-3'
          >
            <Accordion.Item eventKey='0'>
              <Accordion.Header>Class Codes</Accordion.Header>
              <Accordion.Body>
                <PolicyClassCodes
                  policyId={excessPolicy?.excessCarrierPolicyId ?? 0}
                  isExcess={true}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
