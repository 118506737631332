import React from 'react';
import saveAs from 'file-saver';
import { Button } from 'react-bootstrap';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { useParams } from 'react-router-dom';

export default function CompletePage({
  values,
  disabledFields,
  summary,
}: {
  values: EdiTransaction;
  disabledFields: string[];
  summary: string;
}) {
  const { claimNumber } = useParams();
  const downloadFile = () => {
    const fileName = `${claimNumber}_EDITransmission`;
    const blob = new Blob([summary], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, fileName);
  };
  return (
    <div className='d-flex justify-content-stretch gap1Rem'>
      <div className={`position-relative mb-3 w-100`}>
        <label htmlFor='summary' className='form-label fs-6  m-0'>
          Summary
        </label>
        <div>
          <textarea
            name='summary'
            rows={10}
            className='form-control form-control-sm'
            value={summary}
            readOnly
          />
        </div>
      </div>
      <div className='d-flex flex-column p-3 justify-content-center gap1Rem'>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={() => {
            downloadFile();
          }}
        >
          Download
        </Button>
      </div>
    </div>
  );
}
