import React from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { FaQuestion } from 'react-icons/fa';
import styles from './index.module.css';

export default function DiaryCreateQuestionModal({
  show,
  setShow,
  createDiary,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  createDiary: () => void;
}) {
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='DiaryCreateQuestionModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='DiaryCreateQuestionModal-modal'
        >
          <FaQuestion className='pe-1' /> Create Another Diary
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <div className={styles.claimantFormButtonDiv}>
            <Button
              type='button'
              variant='primary'
              onClick={() => {
                createDiary();
              }}
            >
              Create
            </Button>
            <Button
              type='button'
              variant='secondary'
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
