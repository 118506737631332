import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { ClaimProperty } from '../../../ApiTypes/ClaimProperty';
import styles from './index.module.css';

export default function ClaimPropertyTable({
  claimProperties,
  handleClaimPropertyToDelete,
  show,
  setShow,
  selectedPropertyClaimId,
  setSelectedPropertyClaimId,
}: {
  handleClaimPropertyToDelete: (w: ClaimProperty) => void;
  claimProperties: ClaimProperty[];
  show: boolean;
  setShow: (show: boolean) => void;
  selectedPropertyClaimId: string | '';
  setSelectedPropertyClaimId: (claimPropertyId: string | '') => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => claimProperties, [claimProperties]);

  const columnData: ColumnDef<ClaimProperty>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      size: 50,
      cell: (d) => {
        return (
          <div className={`${styles.actionButtons}`}>
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='View/Edit'
              onClick={() => {
                setSelectedPropertyClaimId(d.row.original.id.toString());
                setShow(true);
              }}
            >
              <FaEdit />
            </Button>
            {/* </Link> */}
            <Button
              type='button'
              size='sm'
              variant='danger'
              title='Delete'
              onClick={() => {
                handleClaimPropertyToDelete(d.row.original);
              }}
            >
              <FaTrash />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Property Type',
      accessorKey: 'propertyType.name',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Name',
      accessorFn: (d) => `${d.ownerName ?? ''} ${d.ownerLastName ?? ''}`,

      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) =>
        (d.row.original.ownerName ?? '') +
        ' ' +
        (d.row.original.ownerLastName ?? ''),
    },
    {
      header: 'Phone',
      accessorKey: 'ownerPhone',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Email',
      accessorKey: 'ownerEmail',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        selectableRow={true}
        highlightRow={true}
      />
    </div>
  );
}
