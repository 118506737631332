import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaMoneyBillWave } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { SiteFunctionsEnum } from '../../../ApiTypes/SiteFunctions';
import { useAppSelector } from '../../../Reducers/Store';

export default function PaymentsDropdown() {
  const { userModel } = useAppSelector((state) => state.user);
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant='secondary'
        id='payments'
        className='button-icon-text'
      >
        <FaMoneyBillWave />
        Payments
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.ApprovePayments && f.enabled
        ) && (
          <Link to='/payments-approve'>
            <Dropdown.Item as='button'>Approve Payments</Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.SecondaryApproval &&
            f.enabled
        ) && (
          <Link to='/secondary-approval'>
            <Dropdown.Item as='button'>Secondary Approval</Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.CreatePaymentBatch &&
            f.enabled
        ) && (
          <Link to='/check-batches'>
            <Dropdown.Item as='button'>Create Payment Batch</Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.ViewPaymentBatches &&
            f.enabled
        ) && (
          <Link to='/view-payment-batches'>
            <Dropdown.Item as='button'>View Payment Batches</Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.ViewChecksNew && f.enabled
        ) && (
          <Link to='/view-checks'>
            <Dropdown.Item as='button'>View Checks</Dropdown.Item>
          </Link>
        )}
        {/* {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.ViewChecksNew && f.enabled
        ) && (
          <Link to='/claims'>
            <Dropdown.Item as='button'>View Checks New</Dropdown.Item>
          </Link>
        )} */}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.ImportPayments && f.enabled
        ) && (
          <>
            <Dropdown.Divider />
            <Dropdown.Header>Import Payments</Dropdown.Header>
            {/* {userModel?.userSiteFunctions?.some(
              (f) =>
                f.siteFunctionId === SiteFunctionsEnum.ImportFromCompIQ &&
                f.enabled
            ) && (
              <Link to='/claims'>
                <Dropdown.Item as='button'>Import From CompIQ</Dropdown.Item>
              </Link>
            )} */}
            {userModel?.userSiteFunctions?.some(
              (f) =>
                f.siteFunctionId === SiteFunctionsEnum.ViewImportExceptions &&
                f.enabled
            ) && (
              <Link to='/import-exceptions'>
                <Dropdown.Item as='button'>
                  View Import Exceptions
                </Dropdown.Item>
              </Link>
            )}
            {/* this is no longer used per Daniel {userModel?.userSiteFunctions?.some(
              (f) =>
                f.siteFunctionId === SiteFunctionsEnum.CompIQEOMFees &&
                f.enabled
            ) && (
              <Link to='/compiq-eomfees'>
                <Dropdown.Item as='button'>CompIQ EOM Fees</Dropdown.Item>
              </Link>
            )} */}
            <Dropdown.Divider />
          </>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.ProcessRecurringPayments &&
            f.enabled
        ) && (
          <Link to='/process-recurring'>
            <Dropdown.Item as='button'>
              Process Recurring Payments
            </Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.EnterPayments && f.enabled
        ) && (
          <>
            <Link to='/enter-payment'>
              <Dropdown.Item as='button'>Enter Payments</Dropdown.Item>
            </Link>
            <Link to='/enter-reimbursement'>
              <Dropdown.Item as='button'>Enter Reimbursement</Dropdown.Item>
            </Link>
          </>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.AdjustmentEntry && f.enabled
        ) && (
          <Link to='/adjustment-entry'>
            <Dropdown.Item as='button'>Adjustment Entry</Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.ReviewPaymentsEnteredToday &&
            f.enabled
        ) && (
          <Link to='/todays-payments'>
            <Dropdown.Item as='button'>
              Review Payments Entered Today
            </Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.RePrintChecks && f.enabled
        ) && (
          <Link to='/reprint-checks'>
            <Dropdown.Item as='button'>Re-Print Checks/EOR's</Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.EnterDeductiblePayments &&
            f.enabled
        ) && (
          <Link to='/deductible-payments'>
            <Dropdown.Item as='button'>Enter Deductible Payments</Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.ReconcileChecks && f.enabled
        ) && (
          <Link to='/reconcile-checks'>
            <Dropdown.Item as='button'>Reconcile Checks</Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.ImportCheckNumbers &&
            f.enabled
        ) && (
          <Link to='/check-number-import'>
            <Dropdown.Item as='button'>Import Check Numbers</Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.ApproveSettlements &&
            f.enabled
        ) && (
          <Link to='/approve-settlements'>
            <Dropdown.Item as='button'>Approve Settlements</Dropdown.Item>
          </Link>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
