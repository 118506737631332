import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaFileWord } from 'react-icons/fa';
import ClaimantApi from '../../Api/ClaimantApi';
import DocumentsApi from '../../Api/DocumentsApi';
import { DocType } from '../../ApiTypes/DocType';
import { ScanDoc } from '../../ApiTypes/ScanDoc';
import { useAppSelector } from '../../Reducers/Store';
import BSSelect from '../Common/BSSelect';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import MyDocumentTable from './MyDocumentTable';

export default function MyDocuments() {
  const [fetching, setFetching] = useState<boolean>(false);
  const [allDocs, setAllDocs] = useState<ScanDoc[]>([]);
  const [filtered, setFiltered] = useState<ScanDoc[]>([]);
  const [docTypeId, setDocTypeId] = useState<string>('');

  const { docTypes } = useAppSelector((state) => state.reference);

  useEffect(() => {
    getAll();
  }, []);
  useEffect(() => {
    filterData(docTypeId);
  }, [docTypeId, allDocs]);

  const getAll = async () => {
    try {
      setFetching(true);
      const res = await DocumentsApi.getFullNewListByUser();
      setAllDocs(res.data.sort((a, b) => a.date!.localeCompare(b.date!)));
      filterData(docTypeId);
      setFetching(false);
    } catch (err) {
      console.log(err);
      setFetching(false);
    }
  };

  const filterData = (id: string) => {
    if (id) {
      const f = allDocs.filter((f) => f.doctypeid === +id);
      setFiltered(f);
    } else {
      setFiltered(allDocs);
    }
  };
  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaFileWord className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Document Queue</h1>
          </div>
        </div>
      </div>
      <div className='d-flex align-items-center gap1Rem'>
        <BSSelect
          name='dcoTypeId'
          label='Type of Document'
          onChange={setDocTypeId}
          options={docTypes}
          optionMethod={(options: DocType[]) =>
            options.map((o) => (
              <option key={o.docTypeId} value={o.docTypeId}>
                {o.description}
              </option>
            ))
          }
        />
        <Button type='button' variant='primary' size='sm' onClick={getAll}>
          {fetching ? (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            'Refresh'
          )}
        </Button>
      </div>
      <MyDocumentTable data={filtered} getDocs={getAll} />
    </PageScaffold>
  );
}
