import React from 'react';
import { Field, Form as RFForm } from 'react-final-form';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import styles from './index.module.css';
import FieldBSRenderText from '../../Common/FieldBSRenderText';
import { centsValidation, requiredField } from '../../../Utils/FieldValidation';
import { formatNumbers, cleanMoney } from '../../../Utils/InputFormatters';
import FieldBSRenderMoney from '../../Common/FieldBSRenderMoney';
import {
  ClaimCmsinfoIcdcode,
  ICDStatusEnum,
} from '../../../ApiTypes/ClaimCmsinfoIcdcode';
import FieldBSRenderSelectHorizontal from '../../Common/FieldBSRenderSelectHorizontal';
import { Icdcode } from '../../../ApiTypes/Icdcode';
import { useAppSelector } from '../../../Reducers/Store';

export default function AddEditICDDiagnosisCodeModal({
  show,
  setShow,
  data,
  addUpdate,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  data: ClaimCmsinfoIcdcode | null;
  addUpdate: (c: ClaimCmsinfoIcdcode, status: null) => void;
}) {
  const { icdCodes } = useAppSelector((state) => state.reference);
  const onSubmit = (values: ClaimCmsinfoIcdcode) => {
    if (!values.icdcode) {
      var code = icdCodes.find(
        (c) => c.id.toString() === values.icdcodeId.toString()
      )!;
      values.icdcode = code;
    }
    return addUpdate(values, null);
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='AddEditICDDiagnosisCodeModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='AddEditICDDiagnosisCodeModal-modal'
        >
          <FaCheck className='pe-1' /> ICD Code
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={
              data ?? {
                statusId: 1,
              }
            }
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='icdcodeId'
                    label='Code'
                    options={icdCodes}
                    optionMethod={(options: Icdcode[]) =>
                      options.map((o) => (
                        <option key={o.id} value={o.id}>
                          {o.name} : {o.description}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelectHorizontal}
                  />
                  <div className='d-flex justify-content-center align-items-center gap1Rem'>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type='submit' size='sm' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
