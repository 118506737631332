import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { FaEdit, FaTrash, FaUserEdit } from 'react-icons/fa';
import { AttorneyToClaimant } from '../../../ApiTypes/AttorneyToClaimant';
import { displayDateOnly } from '../../../Utils';
import { Attorney } from '../../../ApiTypes/Attorney';
import accounting from 'accounting';

export default function AttorneysTable({
  attorneys,
  getAttorneys,
  setShowDelete,
  setShowAttorney,
  setRecordToDelete,
  setAttorneyToEdit,
  handleEditViewAttorneyToClaimant,
}: {
  attorneys: AttorneyToClaimant[];
  getAttorneys: () => void;
  setShowDelete: (show: boolean) => void;
  setShowAttorney: (show: boolean) => void;
  setRecordToDelete: (record: AttorneyToClaimant | null) => void;
  setAttorneyToEdit: (attorney: Attorney | null) => void;
  handleEditViewAttorneyToClaimant: (ac: AttorneyToClaimant) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => attorneys, [attorneys]);

  const columnData: ColumnDef<AttorneyToClaimant>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      size: 125,
      cell: (d) => {
        return (
          <div className='d-flex justify-content-around'>
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='View/Edit Attorney'
              onClick={() => {
                setAttorneyToEdit(d.row.original.attorney);
                setShowAttorney(true);
              }}
            >
              <FaUserEdit />
            </Button>
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='View/Edit Attachment'
              onClick={() => {
                handleEditViewAttorneyToClaimant(d.row.original);
              }}
            >
              <FaEdit />
            </Button>
            <Button
              type='button'
              size='sm'
              variant='danger'
              title='Delete'
              onClick={() => {
                setRecordToDelete(d.row.original);
                setShowDelete(true);
              }}
            >
              <FaTrash />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Type',
      accessorKey: 'type.attorneyTypeName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'First Name',
      accessorKey: 'attorney.firstName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Last Name',
      accessorKey: 'attorney.lastName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Phone',
      accessorKey: 'attorney.phone',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date Hired',
      accessorKey: 'dateHired',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) => displayDateOnly(d.row.original.dateHired ?? ''),
    },
    {
      header: 'Date Terminated',
      accessorKey: 'dateTerminated',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dateTerminated ?? ''),
    },
    {
      header: 'Firm',
      accessorKey: 'attorney.firm.firmName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Lein',
      accessorFn: (d) => (d.isLein === true ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 50,
    },
    {
      header: 'Lein Amount',
      accessorFn: (d) => `${d.lienAmount}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: ({ row }) => accounting.formatMoney(row.original.lienAmount ?? 0),
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        selectableRow={true}
        highlightRow={true}
      />
    </div>
  );
}
