import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleError } from '.';
import ClaimCustomOptionApi from '../Api/ClaimCustomOptionApi';

export const fetchAllClaimCustomOptions = createAsyncThunk(
  'FETCH_ALL_CLAIM_CUSTOM_OPTIONS',
  async (_, thunkApi) => {
    try {
      const response = await ClaimCustomOptionApi.getAllClaimCustomOptions();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
