import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { LoginRequest } from '../ApiTypes/LoginRequest';
import { UserModel } from '../ApiTypes/UserModel';

class AuthApi {
  static login(data: LoginRequest) {
    return axios.post<string>(`${API_URL}/api/auth/login`, data);
  }
  static getCurrentUser() {
    return axios.get<UserModel>(`${API_URL}/api/auth`, getAuthHeader());
  }
}

export default AuthApi;
