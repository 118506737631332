import { Button } from 'react-bootstrap';
import PaginatedTable from '../Common/PaginatedTable';
import { FaEdit, FaPlusCircle, FaTrash } from 'react-icons/fa';
import AddEditClaimEmailDocs from './AddEditClaimEmailDocs';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import { InsCoEmployerEmailFile } from '../../ApiTypes/InsCoEmployerEmailFile';
import { useEffect, useMemo, useState } from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import { useAppDispatch } from '../../Reducers/Store';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import InsCoEmployerEmailFilesApi from '../../Api/InsCoEmployerEmailFilesApi';
import { displayDateOnly } from '../../Utils';

export default function NewClaimEmailDocsTable({
  inscoId,
  employerId,
}: {
  inscoId: number | 0;
  employerId: number | 0;
}) {
  //   let location = useLocation();
  //   const dispatch = useAppDispatch();
  //   let navigate = useNavigate();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const [emailDocs, setEmailDocs] = useState<InsCoEmployerEmailFile[]>([]);
  const [selectedEmailDoc, setSelectedEmailDoc] =
    useState<InsCoEmployerEmailFile | null>(null);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [showAddEmailDoc, setShowAddEmailDoc] = useState<boolean>(false);

  useEffect(() => {
    getEmailFiles();
  }, [inscoId, employerId]);
  //   useEffect(() => {
  //     getEmailFiles();
  //   }, [emailDocs]);

  const getEmailFiles = () => {
    if (inscoId || employerId) {
      return InsCoEmployerEmailFilesApi.getEmailFiles(inscoId, employerId)
        .then((res) => {
          if (res.data) {
            setEmailDocs(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setEmailDocs([]);
    }
  };
  const tableData = useMemo(() => emailDocs, [emailDocs]);
  const columnData: ColumnDef<InsCoEmployerEmailFile>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      size: 50,
      cell: ({ row }) => {
        return (
          <div
            className={`d-flex justify-content-around ${
              row.original.id === selectedEmailDoc?.id ? 'orange' : ''
            }`}
          >
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='View/Edit'
              onClick={() => {
                handleViewEdit(row.original);
              }}
            >
              <FaEdit />
            </Button>
            <Button
              type='button'
              variant='danger'
              size='sm'
              title='Delete'
              onClick={() => {
                setSelectedEmailDoc(row.original);
                setShowDelete(true);
                document.body.click();
              }}
            >
              <FaTrash />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'File Name',
      accessorKey: 'friendlyFileName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date Added',
      accessorKey: 'dateAdded',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Using in Email',
      accessorFn: (d) => (d.useInEmail === true ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, [selectedEmailDoc]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  const handleViewEdit = (emailFile: InsCoEmployerEmailFile | null) => {
    setSelectedEmailDoc(emailFile);
    setShowAddEmailDoc(true);
  };

  const handleDelete = () => {
    if (selectedEmailDoc) {
      return InsCoEmployerEmailFilesApi.delete(selectedEmailDoc)
        .then((res) => {
          if (res.data.success) {
            getEmailFiles();
            setSelectedEmailDoc(null);
            setShowDelete(false);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to delete document');
        });
    }
    return Promise.resolve();
  };

  const handleAddUpdateEmailDoc = (values: InsCoEmployerEmailFile) => {
    const {
      id,
      insCoId,
      employerId,
      fileURL,
      dateAdded,
      useInEmail,
      friendlyFileName,
    } = values;

    if (values.doc !== undefined) {
      const { file } = values.doc;
      const fd = new FormData();
      let fileToUpload = file;
      fileToUpload = new File([file], `${file.name}`, { type: file.type });
      fd.append('file', fileToUpload);
      // fd.append('docTypeId', `${docTypeId}`);

      fd.append('emailFileId', id?.toString() ?? '0');

      fd.append('insCoId', insCoId?.toString() ?? '0');
      fd.append('employerId', employerId?.toString() ?? '0');

      // fd.append('newName', newName ?? '');
      // fd.append('action', action);
      // fd.append('serviceFrom', serviceFrom ?? '');
      fd.append('dateAdded', dateAdded ?? '');
      fd.append('useInEmail', useInEmail === true ? 'true' : 'false');
      fd.append('fileName', friendlyFileName ?? '');
      return InsCoEmployerEmailFilesApi.uploadDocument(fd)
        .then((res) => {
          if (res.data.success) {
            setShowAddEmailDoc(false);
            getEmailFiles();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to upload document');
        });
    } else {
      return InsCoEmployerEmailFilesApi.update(values)
        .then((res) => {
          if (res.data.success) {
            setShowAddEmailDoc(false);
            getEmailFiles();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to update document');
        });
    }
  };

  return (
    <div>
      <div>
        <Button
          variant='primary'
          size='sm'
          onClick={() => {
            setSelectedEmailDoc(null);
            setShowAddEmailDoc(true);
          }}
          className='mb-2 mt-2'
        >
          <div className='button-icon-text'>
            <FaPlusCircle /> Add Doc
          </div>
        </Button>
      </div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
      />
      <AddEditClaimEmailDocs
        show={showAddEmailDoc}
        setShow={setShowAddEmailDoc}
        inscoId={inscoId}
        employerId={employerId}
        insCoEmployerEmailFile={selectedEmailDoc}
        handleAddUpdate={handleAddUpdateEmailDoc}
      />
      <DeleteConfirmationModal
        show={showDelete}
        setShow={setShowDelete}
        setNull={setSelectedEmailDoc}
        handleDelete={handleDelete}
      />
    </div>
  );
}
