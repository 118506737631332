import React, { useEffect, useState } from 'react';
import {
  FaBan,
  FaCheckSquare,
  FaEdit,
  FaEye,
  FaFolderOpen,
  FaPlusCircle,
} from 'react-icons/fa';
import { useAppSelector } from '../../../Reducers/Store';
import { Field, Form as RFFForm } from 'react-final-form';
import PageScaffold from '../../PageScaffold/PageScaffold';

import styles from './index.module.css';
import { Button, Form, Spinner } from 'react-bootstrap';
import FieldBSRenderSelect from '../../Common/FieldBSRenderSelect';
import { InsuranceCompany } from '../../../ApiTypes/InsuranceCompany';
import Wc1Api from '../../../Api/Wc1Api';
import { toast } from 'react-toastify';
import { Wc1 } from '../../../ApiTypes/Wc1';
import { FormApi } from 'final-form';
import Wc1Table from './Wc1Table';
import { useNavigate } from 'react-router-dom';
import EditWc1 from '../../EditWc1/EditWc1';
import ChooseAdjuster from '../../ChooseAdjuster.tsx/ChooseAdjuster';
import { format } from 'date-fns';
import EmployerApi from '../../../Api/EmployerApi';
import { EmployerPolicy } from '../../../ApiTypes/EmployerPolicy';
import { requiredField } from '../../../Utils/FieldValidation';

enum WC1Status {
  New = 1,
  Approved = 2,
  Denied = 3,
}

export default function NewClaimRequestsPage() {
  const navigate = useNavigate();
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );

  const [wc1s, setWc1s] = useState<Wc1[]>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedWc1, setSelectedWc1] = useState<Wc1 | null>(null);
  const [showWc1Edit, setShowWc1Edit] = useState<boolean>(false);
  const [showChooseAdjuster, setShowChooseAdjuster] = useState<boolean>(false);
  const [approving, setApproving] = useState(false);

  let formInstance: FormApi<
    { insurCoId: number; status: number },
    Partial<{ insurCoId: number; status: number }>
  >;

  useEffect(() => {
    initialSubmit();
  }, [insuranceCompaniesForUser]);

  const initialSubmit = () => {
    if (formInstance) {
      formInstance.submit();
    }
  };

  const onSubmit = (values: { insurCoId: number; status: number }) => {
    setRowSelection({});
    return Wc1Api.getWc1s(values.insurCoId ?? 0, values.status)
      .then((res) => {
        setWc1s(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get new claim requests');
      });
  };

  const handleArchive = () => {
    if (selectedWc1) {
      selectedWc1.status = WC1Status.Denied;
      Wc1Api.updateWc1(selectedWc1)
        .then((res) => {
          if (res.data.success) {
            setRowSelection({});
            toast.success('Success');
            formInstance.submit();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to archive wc1');
        });
    }
  };

  const handleReject = () => {
    if (selectedWc1) {
      Wc1Api.rejectWc1(selectedWc1.wc1id)
        .then((res) => {
          if (res.data.success) {
            toast.success('Success');
            setRowSelection({});
            formInstance.submit();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to reject wc1');
        });
    }
  };

  const handleApproveAndOpen = () => {
    if (selectedWc1) {
      if (!selectedWc1.employerId) {
        toast.info('Please edit this WC1 and pick and employer');
        return;
      }

      // choose adjuster
      setShowChooseAdjuster(true);
    }
  };

  const handleAdjusterId = (adjusterId: number) => {
    setShowChooseAdjuster(false);

    if (selectedWc1) {
      setApproving(true);
      const injuryDate =
        selectedWc1.dateOfInjury ?? format(new Date(), 'yyyy-MM-dd');
      let employerPolicy;
      EmployerApi.getEmployerPolicyByTreatyYear(
        selectedWc1.employerId ?? 0,
        injuryDate
      )
        .then((res) => {
          if (res.data.policyId) {
            employerPolicy = res.data;
            return handleApprove(selectedWc1, employerPolicy, adjusterId);
          } else {
            toast.error(
              'Inactive or Missing Policy for this Employer.  Please confirm the Employer is correct and if so, please confirm there is a policy covering Date of Injury.'
            );
            setApproving(false);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
          setApproving(false);
          toast.error('Failed to get employer policy');
        });
    }
  };

  const handleApprove = (
    wc1: Wc1,
    employerPolicy: EmployerPolicy,
    adjusterId: number
  ) => {
    return Wc1Api.approveWc1({
      wc1,
      adjusterId,
      policyId: employerPolicy.policyId,
    })
      .then((res) => {
        if (res.data.success) {
          if (res.data.message) {
            toast.warn(res.data.message);
          } else {
            toast.success('Success');
          }
          navigate(`/claims/${res.data.claimNumber}`);
        } else {
          toast.error(res.data.claimNumber ?? 'Failed to approve. ');
        }
        setApproving(false);
      })
      .catch((err) => {
        console.log(err);
        setApproving(false);
        toast.error('Failed to approve');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaFolderOpen className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>New Claim Requests</h1>
            <p>New claim requests from the online File Claim System</p>
          </div>
        </div>

        <div className={`align-self-start ${styles.formContainerPadding}`}>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={{
              // insurCoId: insuranceCompaniesForUser[0]?.insurcoid ?? undefined,
              status: 1,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              formInstance = form;
              return (
                <Form onSubmit={handleSubmit}>
                  <div
                    className={`d-flex w-100 justify-content-center align-items-center ${styles.gap1rem}`}
                  >
                    <Field
                      name='insurCoId'
                      label='Account'
                      options={insuranceCompaniesForUser}
                      optionMethod={(options: InsuranceCompany[]) =>
                        options.map((o) => (
                          <option key={o.insurcoid!} value={o.insurcoid!}>
                            {`${o.accountnumber} - ${o.shortname}`}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                    <Field
                      name='status'
                      label='Status'
                      options={[
                        { name: 'New', value: 1 },
                        { name: 'Approved', value: 2 },
                        { name: 'Denied', value: 3 },
                      ]}
                      optionMethod={(
                        options: { name: string; value: number }[]
                      ) =>
                        options.map((o) => (
                          <option key={o.value} value={o.value}>
                            {o.name}
                          </option>
                        ))
                      }
                      validate={requiredField}
                      component={FieldBSRenderSelect}
                    />
                    <Button variant='primary' type='submit' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Refresh'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </div>
        <div></div>
      </div>
      <div className='d-flex justify-content-center gap1Rem py-3'>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          title='Open Claim'
          disabled={!selectedWc1 || !selectedWc1.claimNo}
          onClick={() => {
            navigate(`/claims/${selectedWc1?.claimNo}`);
          }}
        >
          <FaEye />
        </Button>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          title='Archive WC1'
          disabled={!selectedWc1}
          onClick={handleArchive}
        >
          Archive
        </Button>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          title='Edit WC1'
          disabled={!selectedWc1}
          onClick={() => {
            setShowWc1Edit(true);
          }}
        >
          <FaEdit />
        </Button>
        {/* <Button
          type='button'
          variant='outline-primary'
          size='sm'
          title='Add New WC1'
          disabled={selectedWc1 != null}
          onClick={() => {
            //TODO - is this used
            // setShowWc1Edit(true);
            toast.info(
              'Is this used to create new wc1s? Add new code is commented out?'
            );
          }}
        >
          <FaPlusCircle />
        </Button> */}
        <Button
          type='button'
          variant='outline-success'
          size='sm'
          title='Approve & Open'
          disabled={!selectedWc1}
          onClick={handleApproveAndOpen}
        >
          {approving ? (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            <FaCheckSquare />
          )}
        </Button>
        <Button
          type='button'
          variant='outline-danger'
          size='sm'
          title='Reject'
          disabled={!selectedWc1}
          onClick={handleReject}
        >
          <FaBan />
        </Button>
      </div>
      <Wc1Table
        data={wc1s}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        setSelectedWc1={setSelectedWc1}
      />
      <>
        {showWc1Edit && (
          <EditWc1
            show={showWc1Edit}
            setShow={setShowWc1Edit}
            wc1={selectedWc1}
            getWc1={initialSubmit}
          />
        )}
      </>
      <ChooseAdjuster
        show={showChooseAdjuster}
        setShow={setShowChooseAdjuster}
        insurCoId={selectedWc1?.insCoId ?? 0}
        handleSubmit={handleAdjusterId}
      />
    </PageScaffold>
  );
}
