import { CellContext } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { ScanDocSimple } from '../../../ApiTypes/ScanDocSimple';
import { useAppSelector } from '../../../Reducers/Store';

export default function EditableDocPrioritiesCell({
  getValue,
  row,
  column,
  table,
}: CellContext<ScanDocSimple, unknown>) {
  const { docPriorities } = useAppSelector((state) => state.reference);
  let initialValue = getValue();

  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (row.getIsSelected() && !table.options.meta?.enableMultiple) {
    return (
      <select
        name='priorityId'
        value={value as string}
        onChange={(e) => {
          setValue(e.target.value);
          table.options.meta?.updateData(row.index, column.id, e.target.value);
        }}
      >
        {docPriorities.map((o) => (
          <option key={o.keyDocPriorityId} value={o.name ?? ''}>
            {o.name}
          </option>
        ))}
      </select>
    );
  } else {
    if (getValue()) {
      return (
        <div onClick={() => row.toggleSelected()}>
          {(getValue() as string) ?? ''}
        </div>
      );
    } else {
      return <div onClick={() => row.toggleSelected()}>-</div>;
    }
  }
}
