import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useAppSelector } from '../../../Reducers/Store';
import RecurringTab from './RecurringTab';
import TpdTab from './TpdTab';

export default function IndemnityPaymentsTab({
  claimType,
}: {
  claimType: number;
}) {
  const { userName } = useAppSelector((state) => state.user);
  return (
    <div className='w-100 h-100'>
      <Tabs defaultActiveKey='recurring' id='indemnityTabs' className='mb-3'>
        <Tab eventKey='recurring' title='Recurring'>
          <RecurringTab />
        </Tab>
        {userName === 'CEDWARDS' && (
          <Tab eventKey='tpd' title='TPD'>
            <TpdTab />
          </Tab>
        )}
      </Tabs>
    </div>
  );
}
