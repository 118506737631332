import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import EdiTransactionApi from '../../Api/EdiTransactionApi';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import FormFroi from './FormFroi';
import FormFroiDenial from './FormFroiDenial';
import FormSroi from './FormSroi';
import FormSroi04 from './FormSroi04';
import FormSroiFn from './FormSroiFN';
import FormSroiPd from './FormSroiPd';
import FormSroiPy from './FormSroiPy';
import FormSroiS1 from './FormSroiS1';
import FormSroiEp from './FormSroiEp';

export default function GetFormForEditTransaction({
  transaction,
  onFormSubmit,
  closeModal,
}: {
  transaction: EdiTransaction | null;
  onFormSubmit: (values: EdiTransaction) => Promise<void>;
  closeModal: () => void;
}) {
  const [selectedMaintTypeCode, setSelectedMaintTypeCode] =
    useState<string>('');

  const [selectedForm, setSelectedForm] = useState<string>('');

  useEffect(() => {
    setCodes();
  }, [transaction]);

  const setCodes = () => {
    if (transaction) {
      const { maintTypeCode, form } = transaction;

      if (maintTypeCode === '02' && transaction.changeEdiTransactionId) {
        EdiTransactionApi.getEdiTransactionById(
          transaction.claimno,
          transaction.changeEdiTransactionId
        )
          .then((res) => {
            setSelectedMaintTypeCode(res.data.maintTypeCode);
            setSelectedForm(res.data.form);
          })
          .catch((err) => {
            console.log(err);
            toast.error('Failed to get old transaction for change');
          });
      } else {
        setSelectedMaintTypeCode(maintTypeCode);
        setSelectedForm(form);
      }
    } else {
      setSelectedMaintTypeCode('');
      setSelectedForm('');
    }
  };

  if (selectedForm === 'FROI') {
    //original or cancellation
    if (selectedMaintTypeCode === '00' || selectedMaintTypeCode === '01') {
      return (
        <FormFroi
          transaction={transaction}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={selectedForm}
          maintTypeCode={selectedMaintTypeCode}
        />
      );
    }
    //denial
    if (selectedMaintTypeCode === '04') {
      return (
        <FormFroiDenial
          transaction={transaction}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={selectedForm}
          maintTypeCode={selectedMaintTypeCode}
        />
      );
    }
    //Acquired...unallocated
    if (selectedMaintTypeCode === 'AQ') {
      //launch the Original WC1 Screen...BUT Prob should be different.
      return (
        <FormFroi
          transaction={transaction}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={selectedForm}
          maintTypeCode={selectedMaintTypeCode}
        />
      );
    }
    //Acquired
    if (selectedMaintTypeCode === 'AU') {
      //launch the Original WC1 Screen...BUT Prob should be different.
      return (
        <FormFroi
          transaction={transaction}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={selectedForm}
          maintTypeCode={selectedMaintTypeCode}
        />
      );
    }

    // no match
    return (
      <FormFroi
        transaction={transaction}
        onFormSubmit={onFormSubmit}
        closeModal={closeModal}
        formType={selectedForm}
        maintTypeCode={selectedMaintTypeCode}
      />
    );
  } else if (selectedForm === 'SROI') {
    //original or cancellation
    if (selectedMaintTypeCode === 'IP') {
      return (
        <FormSroi
          transaction={transaction}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={selectedForm}
          maintTypeCode={selectedMaintTypeCode}
        />
      );
    }
    //01
    else if (selectedMaintTypeCode === '01') {
      return (
        <FormSroi
          transaction={transaction}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={selectedForm}
          maintTypeCode={selectedMaintTypeCode}
        />
      );
    }
    //04 Denial
    else if (selectedMaintTypeCode === '04') {
      return (
        <FormSroi04
          transaction={transaction}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={selectedForm}
          maintTypeCode={selectedMaintTypeCode}
        />
      );
    }
    //PD Partial Denial
    else if (selectedMaintTypeCode === 'PD') {
      return (
        <FormSroiPd
          transaction={transaction}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={selectedForm}
          maintTypeCode={selectedMaintTypeCode}
        />
      );
    }
    //Suspend
    else if (
      selectedMaintTypeCode === 'S1' ||
      selectedMaintTypeCode === 'S2' ||
      selectedMaintTypeCode === 'S3' ||
      selectedMaintTypeCode === 'S4' ||
      selectedMaintTypeCode === 'S5' ||
      selectedMaintTypeCode === 'S6' ||
      selectedMaintTypeCode === 'S7'
    ) {
      return (
        <FormSroiS1
          transaction={transaction}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={selectedForm}
          maintTypeCode={selectedMaintTypeCode}
        />
      );
    }
    //FN or AN
    else if (selectedMaintTypeCode === 'FN' || selectedMaintTypeCode === 'AN') {
      return (
        <FormSroiFn
          transaction={transaction}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={selectedForm}
          maintTypeCode={selectedMaintTypeCode}
        />
      );
    } else if (selectedMaintTypeCode === 'EP') {
      return (
        <FormSroiEp
          transaction={transaction}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={selectedForm}
          maintTypeCode={selectedMaintTypeCode}
        />
      );
    } else if (selectedMaintTypeCode === 'PY') {
      return (
        <FormSroiPy
          transaction={transaction}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={selectedForm}
          maintTypeCode={selectedMaintTypeCode}
        />
      );
    } else {
      return (
        <FormSroi
          transaction={transaction}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={selectedForm}
          maintTypeCode={selectedMaintTypeCode}
        />
      );
    }
  } else {
    return <div>Getting correct form</div>;
  }
}
