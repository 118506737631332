import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { PolicyClassCode } from '../ApiTypes/PolicyClassCode';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class PolicyClassCodeApi {
  static getForPolicy(policyId: number) {
    return axios.get<PolicyClassCode[]>(
      `${API_URL}/api/employerpolicies/${policyId}/policyclasscodes`,
      getAuthHeader()
    );
  }
  static update(policyClassCode: PolicyClassCode) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/policyclasscodes/${policyClassCode.keyCodeId}`,
      policyClassCode,
      getAuthHeader()
    );
  }
  static delete(keyCodeId: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/policyclasscodes/${keyCodeId}`,
      getAuthHeader()
    );
  }
  static create(policyClassCode: PolicyClassCode) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/employerpolicies/${policyClassCode.policyId}/classcodes`,
      policyClassCode,
      getAuthHeader()
    );
  }
}

export default PolicyClassCodeApi;
