import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { Hearing } from '../../../ApiTypes/Hearing';
import { Link } from 'react-router-dom';
import { FaEdit, FaRecycle, FaTrash } from 'react-icons/fa';
import { displayDateOnly } from '../../../Utils';

export default function HearingTable({
  hearings,
  handleHearingToDelete,
  show,
  setShow,
  selectedHearingId,
  setSelectedHearingId,
  hearingIsReset,
}: {
  handleHearingToDelete: (h: Hearing) => void;
  hearings: Hearing[];
  show: boolean;
  setShow: (show: boolean) => void;
  selectedHearingId: string | '';
  setSelectedHearingId: (hearingId: string | '') => void;
  hearingIsReset: (show: boolean) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => hearings, [hearings]);

  const columnData: ColumnDef<Hearing>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 75,
      size: 75,
      cell: (d) => {
        return (
          <div className='d-flex justify-content-around'>
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='View/Edit'
              onClick={() => {
                setSelectedHearingId(d.row.original.hearingId.toString());
                setShow(true);
              }}
            >
              <FaEdit />
            </Button>
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='Reset'
              onClick={() => {
                setSelectedHearingId(d.row.original.hearingId.toString());
                hearingIsReset(true);
                setShow(true);
              }}
            >
              <FaRecycle />
            </Button>
            <Button
              type='button'
              size='sm'
              variant='danger'
              title='Delete'
              onClick={() => {
                handleHearingToDelete(d.row.original);
              }}
            >
              <FaTrash />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Date',
      accessorKey: 'createDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.createDate ?? ''),
    },
    {
      header: 'Judge',
      accessorKey: 'judge.lastName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Reason for Request',
      accessorKey: 'notes',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Disposition',
      accessorKey: 'dispositionType.name',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: '# of Settings',
      accessorKey: 'numberOfSettings',
      sortingFn: 'alphanumeric',
      filterFn: 'inNumberRange',
    },
    {
      header: 'Hearing Date',
      accessorKey: 'hearingDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.hearingDate ?? ''),
    },
    {
      header: 'Award Ruling',
      accessorKey: 'awardRuling.name',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date of Award',
      accessorKey: 'dateOfReward',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dateOfReward ?? ''),
    },
    {
      header: 'Appealed',
      accessorKey: 'appealed',
      sortingFn: 'alphanumeric',
      filterFn: 'equals',
    },
    {
      header: 'Appealed Date',
      accessorKey: 'appealedDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.appealedDate ?? ''),
    },
    {
      header: 'Appeal Ruling',
      accessorKey: 'appealedRuling.name',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        selectableRow={true}
        highlightRow={true}
      />
    </div>
  );
}
