import { ProductivityObj } from '../../ApiTypes/ProductivityObj';

export const exportHandler = (data: ProductivityObj[]) => {
  const columns = [
    'Name',
    'Diary Added',
    'Diary Completed',
    'Diary Total Open',
    'Notes Added',
    'Payments Approved',
    'Docs Added',
    'Docs Moved from New',
    'Docs in New',
  ];

  const rows: any[][] = [];

  data.forEach((t) => {
    const tRow = [
      t.adjusterName,
      `${t.diaryAdded}`,
      `${t.diaryCompleted}`,
      `${t.diaryTotalOpen}`,
      `${t.noteCount}`,
      `${t.paymentsAdded}`,
      `${t.docAdded}`,
      `${t.docMoved}`,
      `${t.docTotalUntouched}`,
    ];

    rows.push(tRow);
  });
  console.log(rows);

  return {
    columns,
    rows,
    fileName: '',
  };
};
