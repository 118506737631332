import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SubrogationTable from './SubrogationTable';
import { ClaimSubrogation } from '../../../ApiTypes/ClaimSubrogation';
import SubrogationApi from '../../../Api/SubrogationApi';

export default function SubrogationTab() {
  const { claimNumber } = useParams();
  const [data, setData] = useState<ClaimSubrogation[]>([]);

  useEffect(() => {
    getClaimSubrogations();
  }, [claimNumber]);

  const getClaimSubrogations = () => {
    if (claimNumber) {
      SubrogationApi.getClaimSubrogations(claimNumber)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setData([]);
    }
  };

  return (
    <div>
      <SubrogationTable
        claimNumber={claimNumber ?? ''}
        data={data}
        getClaimSubrogations={getClaimSubrogations}
      />
    </div>
  );
}
