import React, { useState, useEffect, useRef } from 'react';
import { FaFileWord } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ClaimApi from '../../Api/ClaimApi';
import DocumentsApi from '../../Api/DocumentsApi';
import { ClaimSearchRequest } from '../../ApiTypes/ClaimSearchRequest';
import { ClaimSearchResult } from '../../ApiTypes/ClaimSearchResult';
import { ScanDocsPending } from '../../ApiTypes/ScanDocsPending';
import { useAppSelector } from '../../Reducers/Store';
import PageScaffold from '../PageScaffold/PageScaffold';
import ClaimSide from './ClaimSide';
import DocumentSide from './DocumentSide';
import styles from './index.module.css';
import { DocumentStatus } from '../../ApiTypes/DocumentStatus';
import { AddDocumentRequest } from '../../ApiTypes/AddDocumentRequest';
import { format } from 'date-fns';
import UserEmailListApi from '../../Api/UserEmailListApi';
import { UserEmailList } from '../../ApiTypes/UserEmailList';

export default function ScanningCentral() {
  const [searchResults, setSearchResults] = useState<ClaimSearchResult[]>([]);
  const [selectedSearchResult, setSelectedSearchResult] =
    useState<ClaimSearchResult | null>(null);
  const [docTypeId, setDocTypeId] = useState<string>('2');
  const [fetching, setFetching] = useState<boolean>(false);
  const [pendingDocs, setPendingDocs] = useState<ScanDocsPending[]>([]);
  const [selectedDoc, setSelectedDoc] = useState<ScanDocsPending | null>(null);
  // overrides
  const [statusOverride, setStatusOverride] = useState<number>(
    DocumentStatus.New
  );
  const [nameOverride, setNameOverride] = useState<string>('');
  const [typeOverride, setTypeOverride] = useState<string>('2');
  const [priorityOverride, setPriorityOverride] = useState<string>('1');

  const [rowClaimSelection, setRowClaimSelection] = React.useState({});
  const [rowDocSelection, setRowDocSelection] = React.useState({});

  const [assigning, setAssigning] = useState<boolean>(false);

  const [userEmailList, setUserEmailList] = useState<UserEmailList[]>([]);

  const { docTypes, docTypesFullScannerList, docPriorities } = useAppSelector(
    (state) => state.reference
  );

  const { userModel } = useAppSelector((state) => state.user);

  let docWindow = useRef<Window | null>(null);

  useEffect(() => {
    getPending();
  }, [docTypeId]);

  useEffect(() => {
    handleViewDoc();
  }, [selectedDoc]);

  useEffect(() => {
    getUserEmailList();
  }, [userModel]);

  const getUserEmailList = () => {
    if (userModel?.user?.userId) {
      UserEmailListApi.getEmailList(userModel?.user?.userId)
        .then((res) => {
          setUserEmailList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const search = (values: ClaimSearchRequest) => {
    return ClaimApi.searchClaims(values)
      .then((res) => {
        setSearchResults(res.data);
      })
      .catch((err) => {
        setSearchResults([]);
        console.log(err);
      });
  };

  const getPending = () => {
    setTypeOverride(docTypeId);
    setFetching(true);
    DocumentsApi.getPending(+docTypeId)
      .then((res) => {
        setPendingDocs(res.data);
        // if docs - select first one
        if (res.data.length > 0) {
          setRowDocSelection({ 0: true });
        }
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);
        console.log(err);
      });
  };

  const handleViewDoc = () => {
    if (selectedDoc) {
      if (docWindow.current && !docWindow.current.closed) {
        docWindow.current.location.href = `/pendingscandocs/${selectedDoc?.docId}`;
      } else {
        const wind = window.open(
          `/pendingscandocs/${selectedDoc?.docId}`,
          'Pending Documents'
        ) as Window;
        docWindow.current = wind;
      }
    }
  };

  const assignDocToClaim = () => {
    if (!selectedDoc) {
      toast.error('No Document Selected');
      return Promise.resolve();
    }
    if (!selectedSearchResult) {
      toast.error('No Claim Selected');
      return Promise.resolve();
    }
    setAssigning(true);
    const originalDocTypeId = docTypeId;
    const overrideDocTypeId = typeOverride;
    const docName = nameOverride ? nameOverride : selectedDoc.filename ?? '';
    const statusId = statusOverride;
    const priorityId = priorityOverride;

    const request: AddDocumentRequest = {
      claimNumber: selectedSearchResult.claimNo,
      completedBy: statusId === DocumentStatus.New ? 'System' : '',
      date: format(new Date(), 'yyyy-MM-dd'),
      documentPriority: +priorityId,
      documentStatus: statusId,
      docType: +originalDocTypeId,
      docTypeOverride: +overrideDocTypeId,
      end: null,
      friendlyName: docName,
      notes: `Document scanned into system and Assigned to claim ${selectedSearchResult.claimNo}.  The Document Status is ${DocumentStatus[statusId]}.`,
      originalFileName: selectedDoc.filename ?? '',
      start: null,
      deletePending: true,
    };

    return DocumentsApi.assignDocToClaim(request)
      .then((res) => {
        if (res.data.success) {
          // rather than refresh pending docs
          // just remove the one that was assigned
          const pd = pendingDocs.filter((x) => x.docId !== selectedDoc.docId);

          // clear
          setRowDocSelection({});

          // save new pending docs
          setPendingDocs(pd);

          // select first in list if it exists
          if (pd.length > 0) {
            setRowDocSelection({ 0: true });
          }

          toast.success('Success');
        } else {
          toast.error(res.data.message);
        }
        setAssigning(false);
      })
      .catch((err) => {
        console.log(err);
        setAssigning(false);
        toast.error('Failed to add doc to claim');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaFileWord className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Scanning Central</h1>
          </div>
        </div>
      </div>
      <div className={styles.grid2}>
        <ClaimSide
          search={search}
          searchResults={searchResults}
          selectedSearchResult={selectedSearchResult}
          setSelectedSearchResult={setSelectedSearchResult}
          rowSelection={rowClaimSelection}
          setRowSelection={setRowClaimSelection}
        />
        <DocumentSide
          setDocTypeId={setDocTypeId}
          docTypeId={docTypeId}
          docTypesFullScannerList={docTypesFullScannerList}
          docTypes={docTypes}
          docPriorities={docPriorities}
          getPending={getPending}
          pendingDocs={pendingDocs}
          fetching={fetching}
          setSelectedDoc={setSelectedDoc}
          selectedDoc={selectedDoc}
          statusOverride={statusOverride}
          setStatusOverride={setStatusOverride}
          setNameOverride={setNameOverride}
          setTypeOverride={setTypeOverride}
          setPriorityOverride={setPriorityOverride}
          priorityOverride={priorityOverride}
          typeOverride={typeOverride}
          nameOverride={nameOverride}
          handleViewDoc={handleViewDoc}
          rowSelection={rowDocSelection}
          setRowSelection={setRowDocSelection}
          assignDocToClaim={assignDocToClaim}
          assigning={assigning}
          userEmailList={userEmailList}
        />
      </div>
    </PageScaffold>
  );
}
