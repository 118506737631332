import React, { useState, useEffect, useMemo } from 'react';
import { displayDateOnly } from '../../Utils';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  RowSelectionState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { ClaimSearchResult } from '../../ApiTypes/ClaimSearchResult';
import IndeterminateCheckbox from '../Common/IndeterminateCheckbox';
import styles from './index.module.css';

export default function ClaimTable({
  data,
  getSelectedClaim,
  rowSelection,
  setRowSelection,
}: {
  data: ClaimSearchResult[];
  getSelectedClaim: (result: ClaimSearchResult) => void;
  rowSelection: RowSelectionState;
  setRowSelection: (obj: object) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  useEffect(() => {
    handleNewData();
  }, [data]);

  const handleNewData = () => {
    setRowSelection({});
  };

  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  const handleRowSelectionChange = () => {
    getSelectedClaim(
      table.getSelectedRowModel().flatRows.map((r) => r.original)[0]
    );
  };

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<ClaimSearchResult>[] = [
    {
      header: '',
      id: 'options',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 50,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center'>
            <IndeterminateCheckbox
              className='form-check-input'
              {...{
                id: row.id,
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        );
      },
    },
    {
      header: 'Claim No',
      accessorFn: (d) => d.claimNo,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
    },
    {
      header: 'Injury Date',
      accessorKey: 'injuryDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.injuryDate ?? ''),
    },
    {
      header: 'Last Name',
      accessorFn: (d) => d.lastName,
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'First Name',
      accessorFn: (d) => d.firstName,
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Status',
      accessorFn: (d) => d.status,
      sortingFn: 'text',
      filterFn: 'includesString',
      size: 50,
    },
    {
      header: 'Adjuster',
      accessorFn: (d) => {
        return d.adjusterName;
      },
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
      />
    </div>
  );
}
