import React, { useState, useEffect } from 'react';
import { Form as RFForm, Field } from 'react-final-form';
import styles from './index.module.css';
import { FaUserMd } from 'react-icons/fa';
import PageScaffold from '../PageScaffold/PageScaffold';
import { useLocation, useNavigate } from 'react-router-dom';
import { DoctorsOffice } from '../../ApiTypes/DoctorsOffice';
import DoctorOfficeApi from '../../Api/DoctorOfficeApi';
import { toast } from 'react-toastify';
import { Button, Form, Spinner } from 'react-bootstrap';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import queryString from 'query-string';

export default function AddDoctorsOffice() {
  const location = useLocation();
  const navigate = useNavigate();

  const { panelId } = queryString.parse(location.search);

  const handleAdd = (values: DoctorsOffice) => {
    DoctorOfficeApi.createDoctorsOffice(values)
      .then((res) => {
        if (res.data.success) {
          navigate(
            `/doctorlocations/new?panelId=${panelId}&doctorId=${res.data.affectedEntityIdentifier}`
          );
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create new doctors office');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaUserMd className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Add New Doctor</h1>
          </div>
          <div className='ms-3'></div>
        </div>
      </div>
      <RFForm
        onSubmit={handleAdd}
        initialValues={{
          id: 0,
          doctorLocations: null,
        }}
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Field
                name='officeName'
                type='text'
                label='Office Name'
                validate={requiredField}
                component={FieldBSRenderText}
              />
              <Field
                name='doctorNames'
                type='text'
                label='Doctors Names'
                component={FieldBSRenderText}
              />
              <Field
                name='comments'
                type='text'
                label='comments'
                component={FieldBSRenderText}
              />
              <div className='d-flex justify-content-center align-items-center gap1Rem py-3'>
                <Button type='submit' variant='primary' size='sm'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    </PageScaffold>
  );
}
