import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { FaBriefcase, FaClipboard } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Wc1Api from '../../Api/Wc1Api';
import { Wc1 } from '../../ApiTypes/Wc1';
import { FileDownload } from '../../Types/FileDownLoad';
import styles from './index.module.css';

export default function Wc1ReportOptions({
  show,
  setShow,
  attach,
  claimNumber,
  wc1,
  handleViewWc1,
}: {
  show: boolean;
  attach: boolean;
  claimNumber: string;
  wc1: Wc1 | null;
  handleViewWc1: (fileDownload: FileDownload) => void;
  setShow: (show: boolean) => void;
}) {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const handleOnlineData = () => {
    // OK -> Dialog result from legacy
    if (attach) {
      return attachWc1(false, true, claimNumber, false, wc1?.wc1id);
    }
    return viewWc1(false, true, claimNumber, false, wc1?.wc1id);
  };
  const handleOnlineDataMaskedSsn = () => {
    // YES
    if (attach) {
      return attachWc1(true, true, claimNumber, false, wc1?.wc1id);
    }
    return viewWc1(true, true, claimNumber, false, wc1?.wc1id);
  };
  const handleClaimData = () => {
    // NO
    if (attach) {
      return attachWc1(false, true, claimNumber, true, undefined);
    }
    return viewWc1(false, true, claimNumber, true, undefined);
  };
  const handleClaimDataMaskedSsn = () => {
    //RETRY
    if (attach) {
      return attachWc1(true, true, claimNumber, true, undefined);
    }
    return viewWc1(true, true, claimNumber, true, undefined);
  };

  const attachWc1 = (
    maskSsn: boolean,
    showAdjuster: boolean,
    claimNumber: string,
    useClaimData: boolean,
    wc1Id?: number
  ) => {
    setSubmitting(true);
    return Wc1Api.attachWc1(maskSsn, showAdjuster, claimNumber, useClaimData)
      .then((res) => {
        if (res.data.success) {
          toast.success('Wc1 attached');
          setShow(false);
        } else {
          toast.error(res.data.message);
        }
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
        toast.error('Failed to attach Wc1');
      });
  };

  const viewWc1 = async (
    maskSsn: boolean,
    showAdjuster: boolean,
    claimNumber: string,
    useClaimData: boolean,
    wc1Id?: number
  ) => {
    return Wc1Api.viewWc1(maskSsn, showAdjuster, claimNumber, useClaimData)
      .then((res) => {
        handleViewWc1(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get Wc1');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => setShow(false)}
      dialogClassName=''
      aria-labelledby='WC1-Report-Options-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='WC1-Report-Options-modal'>
          WC1 Report Options {submitting && <Spinner animation='grow' />}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.optionsGrid}>
          <div>
            <Button
              type='button'
              size='lg'
              variant='light'
              onClick={handleOnlineData}
              disabled={wc1 === null}
            >
              <div>
                <FaClipboard />
              </div>
              <div className={styles.reportOptionsButtonText}>
                <p>Report based on</p>
                <p>Online Data</p>
              </div>
            </Button>
          </div>
          <div>
            <Button
              type='button'
              size='lg'
              variant='light'
              onClick={handleOnlineDataMaskedSsn}
              disabled={wc1 === null}
            >
              <div>
                <FaBriefcase />
              </div>
              <div className={styles.reportOptionsButtonText}>
                <p>Report based on</p>
                <p>Claim Data</p>
              </div>
            </Button>
          </div>
          <div>
            <Button
              type='button'
              size='lg'
              variant='light'
              onClick={handleClaimData}
            >
              <div>
                <FaClipboard />
              </div>
              <div className={styles.reportOptionsButtonText}>
                <p>Report based on</p>
                <p>Online Data</p>
                <p>(Masked SSN)</p>
              </div>
            </Button>
          </div>
          <div>
            <Button
              type='button'
              size='lg'
              variant='light'
              onClick={handleClaimDataMaskedSsn}
            >
              <div>
                <FaBriefcase />
              </div>
              <div className={styles.reportOptionsButtonText}>
                <p>Report based on</p>
                <p>Claim Data</p>
                <p>(Masked SSN)</p>
              </div>
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
