import { displayDateOnly } from '../../Utils';
import { VwDiaryReport } from '../../ApiTypes/VwDiaryReport';

export const diaryExportHandler = (data: VwDiaryReport[]) => {
  const columns = [
    'Adjuster Name',
    'Claim',
    'Claimant',
    'DOB',
    'Injury Date',
    'Severity',
    'Status',
    'Employer',
    'Adjuster Diary Date',
    'Supervisor Diary Date',
  ];

  const rows: any[][] = [];

  data.forEach((t) => {
    const tRow = [
      t.adjusterName,
      t.claimNo,
      t.claimantName,
      displayDateOnly(t.dob ?? ''),
      displayDateOnly(t.injuryDate ?? ''),
      t.severity,
      t.status,
      t.employer,
      displayDateOnly(t.adjusterFollowUpDate ?? ''),
      displayDateOnly(t.supervisorFollowUpDate ?? ''),
    ];

    rows.push(tRow);
  });

  return {
    columns,
    rows,
    fileName: '',
  };
};
