import { ClaimStatisticsObj } from '../../ApiTypes/ClaimStatisticObj';

export const exportHandler = (data: ClaimStatisticsObj[]) => {
  const columns = [
    'User',
    'Claims Created',
    'Claims Created LT',
    'Claims Created MO',
    'Docs Added',
    'Docs Added LT',
    'Docs Added MO',
    'Docs Moved',
    'Docs Moved LT',
    'Docs Moved MO',
    'Percentage',
    'Current Doc Count',
    'Sent to Straight Pay',
    'Straight Pay Approved',
    'Sent to Comp IQ',
  ];

  const rows: any[][] = [];

  data.forEach((t) => {
    const tRow = [
      t.userName,
      `${t.claimsCreated}`,
      `${t.claimsCreatedLT}`,
      `${t.claimsCreatedMO}`,
      `${t.documentsAdded}`,
      `${t.documentsAddedLT}`,
      `${t.documentsAddedMO}`,
      `${t.documentsMoved}`,
      `${t.documentsMovedLT}`,
      `${t.documentsMovedMO}`,
      `${t.percentDifferent.toFixed(2)}`,
      `${t.currentUnhandledDocsCount}`,
      `${t.documentsSentToStraightPay}`,
      `${t.documentsApprovedForStraightPay}`,
      `${t.documentsSentToCompIQ}`,
    ];

    rows.push(tRow);
  });
  console.log(rows);

  return {
    columns,
    rows,
    fileName: '',
  };
};
