import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaEnvelope, FaFolderOpen } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { useAppSelector } from '../../Reducers/Store';
import BSSelect from '../Common/BSSelect';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { Letter } from '../../ApiTypes/Letter';
import LetterApi from '../../Api/LetterApi';
import AddLetterModal from '../Tabs/Letters/AddLetterModal';
import EditLetterModal from '../Tabs/Letters/EditLetterModal';

export default function LetterMaintenance() {
  const [showEdit, setShowEdit] = useState<boolean>(false);

  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );

  const [keyLetterId, setKeyLetterId] = useState<number>(0);
  const [letters, setLetters] = useState<Letter[]>([]);
  const [showAddLetter, setShowAddLetter] = useState<boolean>(false);
  const [showEditLetter, setShowEditLetter] = useState<boolean>(false);
  const [letterToEdit, setLetterToEdit] = useState<Letter | null>(null);

  const [insurCoId, setInsurCoId] = useState<number>(0);
  const [insuranceCompany, setInsuranceCompany] =
    useState<InsuranceCompany | null>(null);

  const [fetching, setFetching] = useState(false);

  const handleAccountChange = (id: number) => {
    setInsurCoId(id);
    var company = insuranceCompaniesForUser.find((x) => x.insurcoid == id);
    setInsuranceCompany(company ?? null);
    if (id) {
      setFetching(true);
      LetterApi.getLettersByInsCoId(id)
        .then((res) => {
          setLetters(res.data);
          setFetching(false);
        })
        .catch((err) => {
          console.log(err);
          setFetching(false);
        })
        .catch((err) => setFetching(false));
    } else {
      setLetters([]);
    }
  };

  const createNewLetter = (letter: Letter) => {
    return LetterApi.createNewLetter(letter)
      .then((res) => {
        if (res.data.success) {
          setKeyLetterId(+res.data.affectedEntityIdentifier);
          setShowAddLetter(false);
          handleAccountChange(insurCoId);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create new letter');
      });
  };

  const handleLetterChange = (letterId: number) => {
    setKeyLetterId(letterId);
    const letter = letters.find((l) => l.keyLetterId === +letterId);
    setLetterToEdit(letter ?? null);
    if (letter) {
      setShowEditLetter(true);
    }
  };

  const saveEditedLetter = (l: Letter) => {
    return LetterApi.updateLetter(l, 'null')
      .then((res) => {
        if (res.data.success) {
          handleAccountChange(insurCoId);
          setLetterToEdit(null);
          setShowEditLetter(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update letter');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaEnvelope className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Letter List</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-around align-items-center'>
        <div>
          <BSSelect
            name='insurcoid'
            label='Account'
            onChange={handleAccountChange}
            defaultValue={insurCoId}
            options={[...insuranceCompaniesForUser].sort((a, b) =>
              a.fullname!.localeCompare(b.fullname!)
            )}
            optionMethod={(options: InsuranceCompany[]) =>
              options.map((o) => (
                <option key={o.insurcoid!} value={o.insurcoid!}>
                  {`${o.accountnumber} - ${o.fullname}`}
                </option>
              ))
            }
          />
        </div>
        <div className={`${styles.width33} ms-2`}>
          {fetching && (
            <Spinner
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          )}
        </div>
        <div>
          <Button
            type='button'
            size='sm'
            onClick={() => {
              if (!insurCoId) {
                toast.info('Select an account for the new letter');
                return;
              }
              setLetterToEdit(null);
              setShowAddLetter(true);
            }}
          >
            Add New
          </Button>
        </div>
      </div>
      <div className='d-flex justify-content-center'>
        <BSSelect
          name='keyLetterId'
          label='Letter'
          onChange={handleLetterChange}
          options={letters}
          optionMethod={(options: Letter[]) =>
            options.map((o) => (
              <option key={o.keyLetterId!} value={o.keyLetterId!}>
                {o.letterName}
              </option>
            ))
          }
        />
      </div>
      <AddLetterModal
        show={showAddLetter}
        setShow={setShowAddLetter}
        insCoId={insurCoId}
        createNewLetter={createNewLetter}
      />
      <EditLetterModal
        show={showEditLetter}
        setShow={setShowEditLetter}
        insuranceCompanyName={insuranceCompany?.fullname ?? ''}
        letter={letterToEdit}
        saveLetter={saveEditedLetter}
      />
    </PageScaffold>
  );
}
