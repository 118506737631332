import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { MedicareClaimResult } from '../../ApiTypes/MedicareClaimResult';
import { FaEye } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { displayDateOnly } from '../../Utils';

export default function ResultTable({
  data,
  handleViewDetails,
}: {
  data: MedicareClaimResult[];
  handleViewDetails: (r: MedicareClaimResult) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<MedicareClaimResult>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      size: 50,
      cell: ({ row }) => {
        return (
          <Button
            type='button'
            variant='primary'
            size='sm'
            title='View Details'
            onClick={() => {
              handleViewDetails(row.original);
            }}
          >
            <FaEye />
          </Button>
        );
      },
    },
    {
      header: 'Date Processed',
      accessorKey: 'dateProcessed',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dateProcessed ?? ''),
    },
    {
      header: 'Record Indicator',
      accessorKey: 'recordIndicator',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'File Name',
      accessorKey: 'fileName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Total Summary',
      accessorKey: 'totalSummary',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Total Records',
      accessorFn: (d) => `${d.totalRecords}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Total System Errors',
      accessorFn: (d) => `${d.totalSystemErrors}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Total File Errors',
      accessorFn: (d) => `${d.totalFileErrors}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Total Duplicate Errors',
      accessorFn: (d) => `${d.totalDuplicateErrors}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Total Successful Records',
      accessorFn: (d) => `${d.totalSuccessfulRecords}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
