import React, { useState, useRef } from 'react';
import {
  create,
  createOptions,
  RichEdit,
  ViewType,
  RichEditUnit,
  DocumentFormat,
} from 'devexpress-richedit';
import { FaEdit } from 'react-icons/fa';
import { Letter } from '../../../ApiTypes/Letter';

import styles from './index.module.css';
import 'devextreme/dist/css/dx.light.css';
import 'devexpress-richedit/dist/dx.richedit.css';
import { LetterFieldOptions } from './LetterFieldOptions';
import FieldBSRenderSelectHorizontal from '../../Common/FieldBSRenderSelectHorizontal';
import { ClaimType } from '../../../ApiTypes/ClaimType';
import { useAppSelector } from '../../../Reducers/Store';
import { Field, Form as RFFForm } from 'react-final-form';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';

export default function EditLetterModal({
  insuranceCompanyName,
  letter,
  show,
  setShow,
  saveLetter,
  claimType,
}: {
  insuranceCompanyName: string;
  letter: Letter | null;
  show: boolean;
  setShow: (show: boolean) => void;
  saveLetter: (l: Letter) => Promise<void>;
  claimType: number;
}) {
  const copy = useRef({ ...letter });
  const [rich, setRich] = useState<RichEdit | null>(null);
  const [richEnvelope, setRichEnvelope] = useState<RichEdit | null>(null);
  const { claimTypes } = useAppSelector((state) => state.reference);

  const buildEditor = () => {
    const options = createOptions();
    options.bookmarks = {};
    options.confirmOnLosingChanges = {};
    options.fields = {};
    options.view = {};
    options.view.simpleViewSettings = {};

    options.bookmarks.visibility = true;
    options.bookmarks.color = '#ff0000';

    options.confirmOnLosingChanges.enabled = false;

    options.fields.updateFieldsBeforePrint = true;
    options.fields.updateFieldsOnPaste = true;

    options.unit = RichEditUnit.Inch;

    options.view.viewType = ViewType.Simple;

    options.readOnly = false;

    const fileTab = options.ribbon.getTab(0);
    const mergeTab = options.ribbon.getTab(5);
    options.ribbon.activeTabIndex = 0;

    options.ribbon.removeTab(fileTab!);
    options.ribbon.removeTab(mergeTab!);

    if (letter) {
      copy.current = { ...letter };
    }

    if (document.getElementById('richEdit')) {
      var richT = create(document.getElementById('richEdit')!, options);
      if (letter !== null) {
        const fileContent = letter.letterText
          ? btoa(letter?.letterText)
          : undefined;
        if (!fileContent) {
          richT.documentName = letter.letterName ?? '';
          richT.documentFormat = DocumentFormat.Rtf;
        } else {
          richT.openDocument(
            fileContent,
            letter?.letterName ?? undefined,
            DocumentFormat.Rtf
          );
        }
      }
      setRich(richT);
    }

    if (document.getElementById('richEditEnvelope')) {
      const eOptions = { ...options };
      eOptions.width = '700px';
      eOptions.height = '300px';
      var richEnvelopeT = create(
        document.getElementById('richEditEnvelope')!,
        eOptions
      );
      richEnvelopeT.updateRibbon((ribbon) => (ribbon.visible = false));
      if (letter !== null) {
        const fileContent = letter.envelopeText
          ? btoa(letter?.envelopeText)
          : undefined;
        if (!fileContent) {
          richEnvelopeT.documentName = letter.letterName ?? '';
          richEnvelopeT.documentFormat = DocumentFormat.Rtf;
        } else {
          richEnvelopeT.openDocument(
            fileContent,
            letter?.letterName ?? undefined,
            DocumentFormat.Rtf
          );
        }
      }
      setRichEnvelope(richEnvelopeT);
    }
  };

  const onSubmit = (values: Letter) => {
    if (copy.current) {
      copy.current = { ...copy.current, claimTypeId: values.claimTypeId };
    }
    if (rich) {
      rich.exportToBase64(handleBase64LetterText, DocumentFormat.Rtf);
    }
  };

  const handleBase64LetterText = (base64: string) => {
    const decoded = atob(base64);
    if (copy.current) {
      copy.current = { ...copy.current, letterText: decoded };
    }
    if (richEnvelope) {
      richEnvelope.exportToBase64(handleBase64EnvelopeText, DocumentFormat.Rtf);
    }
  };
  const handleBase64EnvelopeText = (base64: string) => {
    const decoded = atob(base64);
    if (copy.current) {
      copy.current = { ...copy.current, envelopeText: decoded };
      return saveLetter(copy.current as Letter);
    }
  };
  const handleOptionChange = (value: string) => {
    if (rich && value) {
      var subDocument = rich.selection.activeSubDocument;
      var position = rich.selection.active;
      subDocument.insertText(position, `[${value}]`);
    }
  };

  const handleOptionChangeEnvelope = (value: string) => {
    if (richEnvelope && value) {
      var subDocument = richEnvelope.selection.activeSubDocument;
      var position = richEnvelope.selection.active;
      subDocument.insertText(position, `[${value}]`);
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      aria-labelledby='Edit-Letter-Form-modal'
      dialogClassName={styles.largeModal}
      onEntered={() => buildEditor()}
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Edit-Letter-Form-modal'>
          <FaEdit className='pe-1' /> Edit Letter
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={{
              claimTypeId: letter?.claimTypeId,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className={`${styles.centerContent}`}>
                    <p>Ins Company: {insuranceCompanyName}</p>
                    <p>Letter Name: {letter?.letterName ?? ''}</p>
                    <div className='w-50 ps-2'>
                      <Field
                        name='claimTypeId'
                        label='Claim Type'
                        options={claimTypes.filter((c) => c.id === claimType)}
                        optionMethod={(options: ClaimType[]) =>
                          options.map((o) => (
                            <option key={o.name} value={o.id}>
                              {o.name}
                            </option>
                          ))
                        }
                        component={FieldBSRenderSelectHorizontal}
                      />
                    </div>
                    <div className={`${styles.topWindow}`}>
                      <div className={`${styles.documentWindow}`}>
                        <div id='richEdit' />
                      </div>
                      <div className={`${styles.buttonColumn}`}>
                        {LetterFieldOptions.map((o) => (
                          <Button
                            key={`1${o}`}
                            type='button'
                            variant='outline-primary'
                            size='sm'
                            onClick={() => handleOptionChange(o)}
                          >
                            {o}
                          </Button>
                        ))}
                      </div>
                    </div>
                    <hr />
                    <p className='mt-2 mb-0'>Envelope Text</p>
                    <div className={`${styles.topWindow}`}>
                      <div className={`${styles.envelopeWindow}`}>
                        <div id='richEditEnvelope' />
                      </div>
                      <div
                        className={`${styles.buttonColumn} ${styles.maxHeight300}`}
                      >
                        {LetterFieldOptions.map((o) => (
                          <Button
                            key={`2${o}`}
                            type='button'
                            variant='outline-primary'
                            size='sm'
                            onClick={() => handleOptionChangeEnvelope(o)}
                          >
                            {o}
                          </Button>
                        ))}
                      </div>
                    </div>
                    <div className={styles.buttonDiv}>
                      <Button type='submit' variant='primary' size='sm'>
                        {submitting ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          'Save'
                        )}
                      </Button>
                      <Button
                        type='button'
                        variant='secondary'
                        size='sm'
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
