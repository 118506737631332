import React from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import { CheckSaveObj } from '../../ApiTypes/CheckSaveObj';

export default function PayableWarningModal({
  show,
  setShow,
  setNull,
  handleDuplicate,
  check,
}: {
  show: boolean;
  check: CheckSaveObj | null;
  setShow: (show: boolean) => void;
  setNull: (value: null) => void;
  handleDuplicate: (check: CheckSaveObj) => Promise<void>;
}) {
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='PayableWarningModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='PayableWarningModal-modal'
        >
          <FaCheck className='pe-1' /> Confirm
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p className='text-center fs-2'>
            Are you sure you want to create a payment?
          </p>
          <div className='d-flex justify-content-around mt-3'>
            <Button
              type='button'
              variant='primary'
              size='sm'
              onClick={() => {
                if (check) {
                  handleDuplicate(check).then(() => setShow(false));
                }
              }}
            >
              Create
            </Button>
            <Button
              type='button'
              variant='secondary'
              size='sm'
              onClick={() => {
                setNull(null);
                setShow(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
