import { createReducer } from '@reduxjs/toolkit';
import { Employer } from '../ApiTypes/Employer';

import {
  clearState,
  clearSelectedClaim,
} from "../Actions/index"

import {
  fetchEmployerById,
  fetchEmployerDepartments,
  fetchEmployerPolicies,
  fetchEmployerLocationsByPolicyId,
  fetchEmployerList,
} from "../Actions/EmployerActions"
import { Department } from '../ApiTypes/Department';
import { EmployerPolicy } from '../ApiTypes/EmployerPolicy';
import { Location } from '../ApiTypes/Location';

type EmployerReducerType = {
  employer: Employer | null;
  departments: Department[];
  policies: EmployerPolicy[];
  locations: Location[];
  employers: Employer[];
}

const initialState: EmployerReducerType = {
  employer: null,
  departments: [],
  policies: [],
  locations: [],
  employers: [],
}

const EmployerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchEmployerById.fulfilled, (state, action) => {
      return { ...state, employer: action.payload };
    })
    .addCase(fetchEmployerDepartments.fulfilled, (state, action) => {
      return { ...state, departments: action.payload };
    })
    .addCase(fetchEmployerPolicies.fulfilled, (state, action) => {
      return { ...state, policies: action.payload };
    })
    .addCase(fetchEmployerLocationsByPolicyId.fulfilled, (state, action) => {
      return { ...state, locations: action.payload };
    })
    .addCase(fetchEmployerList.fulfilled, (state, action) => {
      return { ...state, employers: action.payload };
    })
    .addCase(clearSelectedClaim, (state, action) => {
      return initialState;
    })
    .addCase(clearState, (state, action) => {
      return initialState;
    })
})

export default EmployerReducer;