import { addDays, addMonths, format } from 'date-fns';
import { FormApi } from 'final-form';
import React from 'react';
import { Field } from 'react-final-form';
import FieldBSRenderMonthPicker from '../Common/FieldBSRenderMonthPicker';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import { FeeWizardFormType } from './FeeWizardFormType';

export default function Page1({
  formInstance,
  values,
}: {
  formInstance: FormApi<FeeWizardFormType, Partial<FeeWizardFormType>>;
  values: FeeWizardFormType;
}) {
  const handleMonthChange = (value: string | null) => {
    const parts = value?.split('/');
    if (parts?.length === 2) {
      const start = new Date(+parts[1], +parts[0] - 1, 1);
      const end = addDays(addMonths(start, 1), -1);
      formInstance.change('startDate', format(start, 'MM/dd/yyyy'));
      formInstance.change('endDate', format(end, 'MM/dd/yyyy'));
    }
  };
  return (
    <div>
      <p className='text-info text-center'>Please select a month</p>
      <div className='d-flex justify-content-around'>
        <Field
          name='month'
          type='text'
          label='Month'
          onChange={handleMonthChange}
          component={FieldBSRenderMonthPicker}
        />
      </div>
      <div className='d-flex justify-content-center gap1Rem'>
        <Field
          name='startDate'
          type='text'
          label='Start'
          readOnly
          component={FieldBSRenderText}
        />
        <Field
          name='endDate'
          type='text'
          label='End'
          readOnly
          component={FieldBSRenderText}
        />
      </div>
    </div>
  );
}
