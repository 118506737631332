import React, { useMemo, useState, useEffect } from 'react';
import { Modal, Container, Button } from 'react-bootstrap';
import { FaEdit, FaEye, FaStickyNote } from 'react-icons/fa';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { ScanDocNotesRequest } from '../../ApiTypes/ScanDocNotesRequest';
import { ScanDocSimple } from '../../ApiTypes/ScanDocSimple';
import DocumentsApi from '../../Api/DocumentsApi';

import styles from './index.module.css';
import { displayDateOnly } from '../../Utils';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import {
  setDocViewFileDownload,
  setShowDocView,
} from '../../Actions/DocViewActions';
import { FileDownload } from '../../Types/FileDownLoad';
import {
  DevExpressReportRequest,
  DxAvailableReports,
} from '../../ApiTypes/DevExpressReportRequest';
import { requestDx } from '../DxReportRequestModal/useDxReportRequestModal';
import { getDxReport } from '../DocViewModal/useDocViewModal';

export default function ViewScanDocNotesRequest({
  show,
  setShow,
  doc,
  setSelectedNoteRequest,
  handleEditNoteRequestClick,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  doc: ScanDocSimple | null;
  setSelectedNoteRequest: (r: ScanDocNotesRequest | null) => void;
  handleEditNoteRequestClick: (r: ScanDocNotesRequest | null) => void;
}) {
  const dispatch = useAppDispatch();
  const { claim } = useAppSelector((state) => state.currentClaimReducer);
  const [requests, setRequests] = useState<ScanDocNotesRequest[]>([]);

  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => requests, [requests]);

  useEffect(() => {
    getRequests();
  }, [doc]);

  const getRequests = () => {
    if (doc) {
      DocumentsApi.getNotesRequestsForDoc(doc.docid)
        .then((res) => {
          setRequests(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const setShowDx = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownload = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  const columnData: ColumnDef<ScanDocNotesRequest>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 150,
      cell: (d) => {
        return (
          <div className={`${styles.actionColumn}`}>
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='View'
              onClick={() => {
                setSelectedNoteRequest(d.row.original);
                const request: DevExpressReportRequest = {
                  ...requestDx,
                  report: DxAvailableReports.rptDoctorNotesRequest,
                  keyDocRequestId: d.row.original.keyNotesReqId,
                  insurCoId: claim?.insurcoid ?? 1,
                };
                getDxReport(request, setFileDownload, setShowDx);
              }}
            >
              <FaEye />
            </Button>
            <Button
              type='button'
              size='sm'
              variant='secondary'
              title='Edit'
              onClick={() => {
                handleEditNoteRequestClick(d.row.original);
              }}
            >
              <FaEdit />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Amount',
      accessorKey: 'amount',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'SVC From',
      accessorKey: 'dateOfService',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'SVC To',
      accessorKey: 'serviceTo',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: '1st Reg Date',
      accessorKey: 'firstAttempt',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: '2nd Reg Date',
      accessorKey: 'secondAttempt',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Remind On',
      accessorKey: 'remindDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Requested On',
      accessorKey: 'createdOn',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='Documents-Notes-Requests-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Documents-Notes-Requests-modal'
        >
          <FaStickyNote className='pe-1' /> Document Notes Requests
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <PaginatedTable
            table={table}
            columnResizeMode='onChange'
            showFilters={true}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
