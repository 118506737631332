import React, { useState, useEffect } from 'react';
import { FaEdit, FaPlusCircle } from 'react-icons/fa';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import BSControlledSelect from '../Common/BSControlledSelect';
import { UwInsuranceCo } from '../../ApiTypes/UwInsuranceCo';
import UwInsuranceCompanyApi from '../../Api/UwInsuranceCompanyApi';
import { InsCoPremiumHistory } from '../../ApiTypes/InsCoPremiumHistory';
import PremiumHistoryTable from './PremiumHistoryTable';
import EditPremiumHistoryModal from './EditPremiumHistoryModal';

export default function PremiumHistory() {
  const [uwInsCos, setUwInsCos] = useState<UwInsuranceCo[]>([]);
  const [selectedInsurCoId, setSelectedInsurCoId] = useState<number>();
  const [histories, setHistories] = useState<InsCoPremiumHistory[]>([]);
  const [selectedHistory, setSelectedHistory] =
    useState<InsCoPremiumHistory | null>(null);
  const [showEdit, setShowEdit] = useState<boolean>(false);

  useEffect(() => {
    getUwInsCos();
  }, []);

  const getUwInsCos = () => {
    UwInsuranceCompanyApi.getInsCos()
      .then((res) => {
        setUwInsCos(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get insurance companies');
      });
  };

  const getHistory = (insurCoId?: number) => {
    setSelectedInsurCoId(insurCoId);
    if (insurCoId) {
      UwInsuranceCompanyApi.getPremiumHistory(insurCoId)
        .then((res) => {
          setHistories(res.data);
        })
        .catch((err) => {
          console.log(err);
          setHistories([]);
          toast.error('Failed to get history');
        });
    } else {
      setHistories([]);
    }
  };

  const handleDelete = (h: InsCoPremiumHistory) => {
    UwInsuranceCompanyApi.deleteHistory(h)
      .then((res) => {
        if (res.data.success) {
          getHistory(h.insCoId);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to delete history');
      });
  };
  const handleEdit = (h: InsCoPremiumHistory) => {
    setSelectedHistory(h);
    setShowEdit(true);
  };
  const handleNew = () => {
    if (selectedInsurCoId) {
      setSelectedHistory({
        id: 0,
        insCoId: selectedInsurCoId,
        treatyYear: 0,
        premium: 0,
        policyCount: 0,
      });
      setShowEdit(true);
    }
  };

  const addUpdate = (h: InsCoPremiumHistory) => {
    if (h.id > 0) {
      return UwInsuranceCompanyApi.updateHistory(h)
        .then((res) => {
          if (res.data.success) {
            getHistory(selectedInsurCoId);
            setShowEdit(false);
            setSelectedHistory(null);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to update history');
        });
    }
    return UwInsuranceCompanyApi.createHistory(h)
      .then((res) => {
        if (res.data.success) {
          getHistory(selectedInsurCoId);
          setShowEdit(false);
          setSelectedHistory(null);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create history');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaEdit className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Modify Saved Premiums for Accounts</h1>
          </div>
          <div className='ms-3'></div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center gap1Rem pb-3'>
        <BSControlledSelect
          name='insurCoId'
          label='Insurance Company'
          options={uwInsCos}
          value={selectedInsurCoId}
          onChange={getHistory}
          optionMethod={(options: UwInsuranceCo[]) =>
            options.map((o) => (
              <option key={o.insurCoId} value={o.insurCoId}>
                {o.fullName}
              </option>
            ))
          }
        />
        <Button
          type='button'
          variant='primary'
          size='sm'
          className='button-icon-text'
          onClick={handleNew}
        >
          <FaPlusCircle /> New Row
        </Button>
      </div>
      <PremiumHistoryTable
        data={histories}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
      />
      <EditPremiumHistoryModal
        show={showEdit}
        setShow={setShowEdit}
        history={selectedHistory}
        addUpdate={addUpdate}
      />
    </PageScaffold>
  );
}
