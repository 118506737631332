import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { VPolicy } from '../ApiTypes/VPolicy';
import { SyncUwPoliciesRequest } from '../ApiTypes/SyncUwPoliciesRequest';

class PolicyApi {
  static getPoliciesNotInClaims(treatyYear: number) {
    return axios.get<VPolicy[]>(
      `${API_URL}/api/policies/notinclaims?treatyYear=${treatyYear}`,
      getAuthHeader()
    );
  }
  static syncUwPolicies(request: SyncUwPoliciesRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/policies/syncuwpolicies`,
      request,
      getAuthHeader()
    );
  }
}

export default PolicyApi;
