import React from 'react';
import { Field } from 'react-final-form';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import styles from './index.module.css';
import {
  cleanMoney,
  formatNumbers,
  makeMoney,
} from '../../Utils/InputFormatters';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';
import { centsValidation } from '../../Utils/FieldValidation';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import FieldBSRenderPercent from '../Common/FieldBSRenderPercent';
import { FormApi } from 'final-form';
import { ReserveWorksheetFormType } from '../../Types/ReservesWorksheetFormType';

export default function IndemnityTab({
  form,
}: {
  form: FormApi<ReserveWorksheetFormType, Partial<ReserveWorksheetFormType>>;
}) {
  const handlePermanentChange = (value: string) => {
    if (value) {
      let percent = +value / 100;
      form.change('percentageLoss', percent);
    } else {
      form.change('percentageLoss', 0);
    }
  };

  const handleFutureTempTotalExpectedChange = (value: string) => {
    if (value) {
      form.change('percentageLossCrtotal', +value);
    } else {
      form.change('percentageLossCrtotal', 0);
    }
  };

  return (
    <div>
      <div className={`${styles.grid4Columns}`}>
        <Field
          name='disabilityPTD'
          label='Disability Paid To Date'
          type='text'
          disabled
          format={formatNumbers}
          parse={cleanMoney}
          validate={centsValidation}
          component={FieldBSRenderMoney}
        />
      </div>
      <div className={`${styles.grid4ShortFirst}`}>
        <p className='fw-bold m-0'>A. Future Temp. Total Expected</p>
        <Field
          name='futureTempTotalExpected'
          label='WCR'
          type='text'
          onChange={handleFutureTempTotalExpectedChange}
          format={formatNumbers}
          parse={cleanMoney}
          validate={centsValidation}
          component={FieldBSRenderMoney}
        />
        <div className={`${styles.gridLetterField}`}>
          <p className=' m-0'>X</p>
          <Field
            name='futureTempTotalExpectedWeeks'
            label='Weeks'
            type='number'
            component={FieldBSRenderText}
          />
        </div>
        <div className={`${styles.gridLetterField}`}>
          <p className='fw-bold m-0'>A.</p>
          <Field
            name='totalFuture'
            label='Future Temp Total Expected'
            type='text'
            format={formatNumbers}
            parse={cleanMoney}
            // validate={centsValidation}
            disabled
            component={FieldBSRenderMoney}
          />
        </div>
      </div>
      <div className={`${styles.grid4ShortFirst}`}>
        <p className='fw-bold m-0'>B. Permanent Impairment</p>
        <Field
          // onCHange update percentageLoss as whole number
          name='permanantImpairment'
          label='Permanent Impairment'
          type='text'
          onChange={handlePermanentChange}
          component={FieldBSRenderPercent}
        />
        <div />
        <div className={`${styles.gridLetterField}`}>
          <p className='fw-bold m-0'>B.</p>
          <Field
            name='permanentImpairmentTotal'
            label='Permanent Impairment Total'
            type='text'
            format={formatNumbers}
            parse={cleanMoney}
            // validate={centsValidation}
            disabled
            component={FieldBSRenderMoney}
          />
        </div>
      </div>
      <div className={`${styles.grid5Columns} mx-5`}>
        <Field
          // matches permanent impairment as decimal
          name='percentageLoss'
          label='Percentage Loss'
          type='text'
          component={FieldBSRenderPercent}
        />
        <div className={`${styles.gridLetterField}`}>
          <p className='m-0'>X</p>
          <Field
            name='percentageLossWeeks'
            label='Weeks'
            type='number'
            component={FieldBSRenderText}
          />
        </div>
        <div className={`${styles.gridLetterField}`}>
          <p className='m-0'>=</p>
          <Field
            name='percentageLossTotal'
            label='Total Weeks'
            type='number'
            component={FieldBSRenderText}
          />
        </div>
        <div className={`${styles.gridLetterField}`}>
          <p className='m-0'>X</p>
          <Field
            name='percentageLossCrtotal'
            label='CR'
            type='text'
            format={formatNumbers}
            parse={cleanMoney}
            validate={centsValidation}
            component={FieldBSRenderMoney}
          />
        </div>
      </div>
      <div className={`${styles.grid4ShortFirst}`}>
        <p className='fw-bold m-0'>C. TPD Exposure</p>
        <Field
          name='tpdExposure'
          label='TPD Exposure CR'
          type='text'
          format={formatNumbers}
          parse={cleanMoney}
          validate={centsValidation}
          component={FieldBSRenderMoney}
        />
        <div className={`${styles.gridLetterField}`}>
          <p className='m-0'>X</p>
          <Field
            name='tpdExposureWeeks'
            label='Weeks'
            type='number'
            component={FieldBSRenderText}
          />
        </div>
        <div className={`${styles.gridLetterField}`}>
          <p className='fw-bold m-0'>C.</p>
          <Field
            name='tpdTotal'
            label='TPD Exposure'
            type='text'
            format={formatNumbers}
            parse={cleanMoney}
            // validate={centsValidation}
            disabled
            component={FieldBSRenderMoney}
          />
        </div>
      </div>
      <div className={`${styles.grid4ShortFirst}`}>
        <p className='fw-bold m-0'>D. Permanent Total</p>
        <Field
          name='permanentTotalCR'
          label='CR'
          type='text'
          format={formatNumbers}
          parse={cleanMoney}
          validate={centsValidation}
          component={FieldBSRenderMoney}
        />
        <div className={`${styles.gridLetterField}`}>
          <p className='m-0'>X</p>
          <Field
            name='permanentTotalWeeks'
            label='Weeks'
            type='number'
            component={FieldBSRenderText}
          />
        </div>
        <div className={`${styles.gridLetterField}`}>
          <p className='fw-bold m-0'>D.</p>
          <Field
            name='permanentTotal'
            label='Permanent Total'
            type='text'
            format={formatNumbers}
            parse={cleanMoney}
            // validate={centsValidation}
            disabled
            component={FieldBSRenderMoney}
          />
        </div>
      </div>
      <div className={`${styles.grid4ShortFirst}`}>
        <p className='fw-bold m-0'>E. Death Benefits(Funeral, Dependents)</p>
        <div />
        <div />
        <div className={`${styles.gridLetterField}`}>
          <p className='fw-bold m-0'>E.</p>
          <Field
            name='deathBenefits'
            label='Death Benefits'
            type='text'
            format={formatNumbers}
            parse={cleanMoney}
            validate={centsValidation}
            component={FieldBSRenderMoney}
          />
        </div>
      </div>
      <div className={`${styles.grid4ShortFirst}`}>
        <p className='fw-bold m-0'>F. Estimated Settlement</p>
        <div />
        <div />
        <div className={`${styles.gridLetterField}`}>
          <p className='fw-bold m-0'>F.</p>
          <Field
            name='claimantAttorneyFees'
            label='Estimated Settlement'
            type='text'
            format={formatNumbers}
            parse={cleanMoney}
            validate={centsValidation}
            component={FieldBSRenderMoney}
          />
        </div>
      </div>
      <div className={`${styles.grid4ShortFirst}`}>
        <div />
        <div />
        <div />
        <div className={`${styles.gridLetterField}`}>
          <p></p>
          <Field
            name='newIndemnityIncurred'
            label='Total New Indemnity Incurred'
            type='text'
            disabled
            format={makeMoney}
            parse={cleanMoney}
            component={FieldBSRenderMoney}
          />
        </div>
      </div>
      <div className={`${styles.grid4ShortFirst}`}>
        <div />
        <div />
        <div />
        <div className={`${styles.gridLetterField}`}>
          <p></p>
          <Field
            name='indemnityRounded'
            label='Rounded'
            type='text'
            disabled
            format={formatNumbers}
            parse={cleanMoney}
            component={FieldBSRenderMoney}
          />
        </div>
      </div>
      <div className={`${styles.grid4ShortFirst}`}>
        <div />
        <Field
          name='txtCurrentIndemnityReserves'
          label='Current Indemnity Reserves'
          type='text'
          format={makeMoney}
          parse={cleanMoney}
          disabled
          component={FieldBSRenderMoney}
        />
        <Field
          name='txtPreviousIndemnity'
          label='Previous Total Incurred'
          type='text'
          disabled
          format={makeMoney}
          parse={cleanMoney}
          component={FieldBSRenderMoney}
        />
        <div className={`${styles.gridLetterField}`}>
          <p></p>
          <Field
            name='indemnityDifference'
            label='Difference'
            type='text'
            disabled
            format={makeMoney}
            parse={cleanMoney}
            component={FieldBSRenderMoney}
          />
        </div>
      </div>
      <div className={`${styles.grid3Columns}`}>
        <div />
        <Field
          name='remarks'
          label='Indemnity Remarks'
          rows={3}
          component={FieldBSRenderTextArea}
        />
      </div>
    </div>
  );
}
