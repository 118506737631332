import React, { useState, useEffect } from 'react';
import { Modal, Container, Button } from 'react-bootstrap';
import { FaIdCard, FaPlusCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import DocumentsApi from '../../Api/DocumentsApi';
import PharmacyCardApi from '../../Api/PharmacyCardApi';
import { PharmacyCard } from '../../ApiTypes/PharmacyCard';
import PdfModal from '../DocViewModal/DocViewModal';
import { useDocViewModal } from '../DocViewModal/useDocViewModal';
import PharmacyCardModal from '../PharmacyCard/PharmacyCardModal';
import PharmacyCardTable from './PharmacyCardTable';

export default function PharmacyCardsModal({
  show,
  setShow,
  claimNumber,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  claimNumber: string;
}) {
  const [cards, setCards] = useState<PharmacyCard[]>([]);
  const {
    showDocViewModal,
    fileDownload,
    setShowDocViewModal,
    setFileDownload,
  } = useDocViewModal();

  const [showCardModel, setShowCardModel] = useState<boolean>(false);
  const [cardToEdit, setCardToEdit] = useState<PharmacyCard | null>(null);

  useEffect(() => {
    getCards();
  }, [claimNumber]);

  const getCards = () => {
    if (claimNumber) {
      PharmacyCardApi.getPharmacyCardsForClaim(claimNumber)
        .then((res) => {
          setCards(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleViewDoc = (docId: number) => {
    DocumentsApi.getScanDocFile(docId)
      .then((res) => {
        setFileDownload(res.data);
        setShowDocViewModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCard = (card: PharmacyCard) => {
    return PharmacyCardApi.deletePharmacyCard(claimNumber, card.id)
      .then((res) => {
        if (res.data.success) {
          getCards();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to delete card');
      });
  };

  const handleEditCard = (card: PharmacyCard | null) => {
    setCardToEdit(card);
    setShowCardModel(true);
  };

  const submitCard = (fd: FormData, id: number) => {
    if (id > 0) {
      return PharmacyCardApi.updateCard(claimNumber, id, fd)
        .then((res) => {
          if (res.data.success) {
            getCards();
            setCardToEdit(null);
            setShowCardModel(false);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to add card');
        });
    } else {
      return PharmacyCardApi.addCard(claimNumber, fd)
        .then((res) => {
          if (res.data.success) {
            getCards();
            setCardToEdit(null);
            setShowCardModel(false);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to add card');
        });
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='Pharmacy-cards-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Pharmacy-cards-modal'>
          <FaIdCard className='pe-1' /> {`Pharmacy Cards for ${claimNumber}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <div className='d-flex justify-content-center align-items-center mb-3'>
            <Button
              type='button'
              variant='primary'
              size='sm'
              className='button-icon-text'
              onClick={() => {
                handleEditCard(null);
              }}
            >
              <FaPlusCircle /> Add New
            </Button>
          </div>
          <PharmacyCardTable
            cards={cards}
            handleViewDoc={handleViewDoc}
            handleDelete={deleteCard}
            handleEditCard={handleEditCard}
          />
        </Container>
      </Modal.Body>
      <PdfModal
        show={showDocViewModal}
        setShow={setShowDocViewModal}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
      <PharmacyCardModal
        show={showCardModel}
        setShow={setShowCardModel}
        card={cardToEdit}
        claimNumber={claimNumber}
        setCard={setCardToEdit}
        submitCard={submitCard}
      />
    </Modal>
  );
}
