import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { handleError } from ".";
import ClaimApi from "../Api/ClaimApi";
import { fetchEmployerDepartments, fetchEmployerLocationsByPolicyId, fetchEmployerPolicies } from "./EmployerActions";




export const fetchClaimByClaimNumber = createAsyncThunk(
  "FETCH_CLAIM_BY_CLAIM_NUMBER",
  async (claimNo: string, thunkApi) => {
    try {
      const response = await ClaimApi.getClaimByClaimNumber(claimNo);
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchFullClaimByClaimNumber = createAsyncThunk(
  "FETCH_FULL_CLAIM_BY_CLAIM_NUMBER",
  async (claimNo: string, thunkApi) => {
    try {
      const response = await toast.promise(ClaimApi.getFullClaimByClaimNumber(claimNo), {
        pending: `Fetching Claim # ${claimNo}`,
        success: "Success",
        error: `Failed to fetch claim # ${claimNo}`
      });
      thunkApi.dispatch(fetchEmployerDepartments(response.data.claim?.employerId!))
      thunkApi.dispatch(fetchEmployerPolicies(response.data.claim?.employerId!))
      thunkApi.dispatch(fetchEmployerLocationsByPolicyId({ employerId: response.data.claim?.employerId!, policyId: response.data.claim?.policyId! }))
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      toast.error(`Failed to fetch claim # ${claimNo}`)
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);