import React, { useState, useEffect } from 'react';
import { Container, Modal } from 'react-bootstrap';
import { FaFilePdf } from 'react-icons/fa';
import { toast } from 'react-toastify';
import PaymentsApi from '../../Api/PaymentsApi';
import { ScanDocSimple } from '../../ApiTypes/ScanDocSimple';
import { Transaction } from '../../ApiTypes/Transaction';
import { VPaymentTab } from '../../ApiTypes/VPaymentTab';
import styles from './index.module.css';
import TransactionDocTable from './TransactionDocTable';

export default function TransactionDocsModal({
  show,
  setShow,
  selectedPayment,
  claimantName,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  selectedPayment: VPaymentTab | null;
  claimantName: string;
}) {
  const [selectedTransaction, setSelectedTransaction] =
    useState<Transaction | null>(null);
  const [scanDocs, setScanDocs] = useState<ScanDocSimple[]>([]);

  useEffect(() => {
    getTransaction();
  }, [selectedPayment]);

  useEffect(() => {
    getDocs();
  }, [selectedTransaction]);

  const getTransaction = () => {
    if (selectedPayment) {
      PaymentsApi.getPaymentByTxId(selectedPayment.txId)
        .then((res) => {
          setSelectedTransaction(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getDocs = () => {
    if (selectedTransaction) {
      PaymentsApi.GetDocsForTransaction(selectedTransaction.txId)
        .then((res) => {
          setScanDocs(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get scan doc');
        });
    } else {
      setScanDocs([]);
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
        setSelectedTransaction(null);
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='Transaction-Documents-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Transaction-Documents-modal'
        >
          <FaFilePdf className='pe-1' /> {`Transaction - ${claimantName}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p>
            ClaimNo: {selectedPayment?.claimNo} TxId: {selectedPayment?.txId}
          </p>
          <TransactionDocTable scanDocs={scanDocs} />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
