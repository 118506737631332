import { useState } from 'react';
import {
  DevExpressReportRequest,
  DxAvailableReports,
  ExportTypes,
} from '../../ApiTypes/DevExpressReportRequest';

export const useDxReportRequestModal = () => {
  const [showDxReportRequestModal, setShowDxReportRequestModal] =
    useState<boolean>(false);
  const [reportEnumValueDx, setReportEnumValueDx] = useState<number>(0);

  return {
    showDxReportRequestModal,
    setShowDxReportRequestModal,
    reportEnumValueDx,
    setReportEnumValueDx,
  };
};

export const requestDx: DevExpressReportRequest = {
  accountNumber: '',
  claimNumber: '',
  employerId: null,
  fromDate: null,
  asOfDate: null,
  insurCoId: 0,
  startYear: 0,
  endYear: 0,
  report: 0,
  severity: '',
  status: '',
  toDate: null,
  external: false,
  openOnly: false,
  onlyClearedChecks: false,
  userId: '',
  searchText: '',
  minReserveAmount: 0,
  caseManagementId: 0,
  letterId: 0,
  policyId: 0,
  reserveWorksheetId: 0,
  eDITransactionId: 0,
  keyDocRequestId: 0,
  topInjuriesReportType: 0,
  topInjuriesReportFilter: '',
  workStatusId: 0,
  maskSSN: false,
  wC4Id: 0,
  incidentOnly: false,
  useEOMData: true,
  useClaimDataForWc1: false,
  showAdjusterInfo: true,
  treatyYear: 0,
  exportType: ExportTypes.PDF,
};

// const dispatch = useAppDispatch();

// const setShow = (show: boolean) => {
//   dispatch(setShowDocView(show));
// };
// const setFileDownload = (data: FileDownload | null) => {
//   dispatch(setDocViewFileDownload(data));
// };

// const request: DevExpressReportRequest = {
//   ...requestDx,
//   report: DxAvailableReports.rptReserveWorksheet,
//   insurCoId: 1,
// };
// getDxReport(request, setFileDownload, setShow);
