import React, { useState, useEffect } from 'react';
import { FaLocationArrow, FaPlusCircle } from 'react-icons/fa';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import PanelApi from '../../Api/PanelApi';
import { toast } from 'react-toastify';
import { Panel } from '../../ApiTypes/Panel';
import { DoctorsOffice } from '../../ApiTypes/DoctorsOffice';
import PanelTable from './PanelTable';
import DoctorOfficeApi from '../../Api/DoctorOfficeApi';
import { Button } from 'react-bootstrap';

export default function DoctorLocations() {
  const { panelId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [panel, setPanel] = useState<Panel | null>(null);
  const [doctorOffices, setDoctorOffices] = useState<DoctorsOffice[]>([]);
  const [allOffices, setAllOffices] = useState<DoctorsOffice[]>([]);
  const [notIncluded, setNotIncluded] = useState<DoctorsOffice[]>([]);
  const [insCoId, setInsCoId] = useState<number>(0);

  useEffect(() => {
    getById();
    getDoctorOfficesForPanel();
    getAllWithLocation();
  }, [panelId]);
  useEffect(() => {
    getNotIncluded();
  }, [allOffices, doctorOffices]);

  const getNotIncluded = () => {
    const includedIds = doctorOffices.map((x) => x.id);
    const notInc = allOffices.filter((x) => !includedIds.includes(x.id));
    setNotIncluded(notInc);
  };

  const getById = () => {
    if (panelId) {
      PanelApi.getPanelById(+panelId)
        .then((res) => {
          setPanel(res.data);
          setInsCoId(res.data.insCoId ?? 0);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get panel');
        });
    } else {
      setPanel(null);
    }
  };
  const getDoctorOfficesForPanel = () => {
    if (panelId) {
      PanelApi.getDoctorOfficesForPanel(+panelId)
        .then((res) => {
          setDoctorOffices(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get doctor offices');
        });
    } else {
      setDoctorOffices([]);
    }
  };
  const getAllWithLocation = () => {
    DoctorOfficeApi.getAllWithLocation()
      .then((res) => {
        setAllOffices(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get doctor offices');
      });
  };

  const handleAdd = (doctorsOffice: DoctorsOffice) => {
    PanelApi.addOfficeToPanel(+panelId!, doctorsOffice.id)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          getDoctorOfficesForPanel();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed add location');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaLocationArrow className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Add Location</h1>
          </div>
          <div className='ms-3'></div>
        </div>
      </div>
      <div className='d-flex justify-content-center py-3'>
        <Link to={`/doctorlocations/new?panelId=${panelId}`}>
          <Button
            type='button'
            variant='primary'
            size='sm'
            className='button-icon-text'
          >
            <FaPlusCircle /> New Location
          </Button>
        </Link>
      </div>
      <PanelTable data={notIncluded} handleAdd={handleAdd} />
    </PageScaffold>
  );
}
