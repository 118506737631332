export interface PropertyType {
  id: number;
  name: string | null;
  active: boolean | null;
  createDate: string | null;
}

export enum PropertyTypeEnum {
  Misc = 1,
  PassengerCar = 2,
  Business = 3,
  ResidentialHome = 4,
}
