import React from 'react';
import { Form, Spinner, Button } from 'react-bootstrap';
import { Form as RFFForm } from 'react-final-form';
import { toast } from 'react-toastify';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import EdiFroiBottom from './EdiFroiBottom';
import EdiInfo from './EdiInfo';
import styles from './index.module.css';

// frmFROI(string ClaimNo, string maintCode, string MaintDesc, string benefitTypeCode, string BenefitDesc)

export default function FormFroi({
  transaction,
  onFormSubmit,
  closeModal,
  formType,
  maintTypeCode,
  benefitTypeCode,
}: {
  transaction: EdiTransaction | null;
  onFormSubmit: (values: EdiTransaction) => Promise<void>;
  closeModal: () => void;
  formType?: 'FROI' | 'SROI';
  maintTypeCode?: string;
  benefitTypeCode?: string;
}) {
  const onSubmit = (values: EdiTransaction) => {
    return onFormSubmit(values);
  };
  return (
    <RFFForm
      onSubmit={onSubmit}
      initialValues={
        transaction
          ? {
              ...transaction,
              maintTypeCode: maintTypeCode ?? transaction?.maintTypeCode,
            }
          : { maintTypeCode, benefitTypeCode, form: formType }
      }
      render={({ handleSubmit, form, values, submitting }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <EdiInfo
              transaction={transaction}
              formValues={values}
              formType={formType ?? 'FROI'}
              form={form}
              maintTypeCode={maintTypeCode}
              benefitTypeCode={benefitTypeCode}
            />
            <EdiFroiBottom
              form={form}
              formValues={values}
              transaction={transaction}
            />
            <div className={`${styles.edi3Grid}`}>
              <div />
              {/* <Button
                type='button'
                size='sm'
                variant='outline-secondary'
                onClick={() => {
                  // form.reset();
                  // setShow(false)
                  toast.info('Need to implement export to excel');
                }}
              >
                Export
              </Button> */}
              <Button type='submit' size='sm' variant='primary'>
                {submitting ? (
                  <Spinner
                    as='span'
                    animation='grow'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  'Save'
                )}
              </Button>
              <Button
                type='button'
                size='sm'
                variant='secondary'
                onClick={() => {
                  form.reset();
                  closeModal();
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
}
