import React, { useState, useEffect, useCallback } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';
import NcmApi from '../../../Api/NcmApi';
import { CaseManagement } from '../../../ApiTypes/CaseManagement';
import NcmDetail from './NcmDetail';
import NcmTab from './NcmTab';

export default function Ncm({ claimType }: { claimType: number }) {
  let location = useLocation();
  let { claimNumber } = useParams();
  const [key, setKey] = useState<string>('ncm');
  const [ncms, setNcms] = useState<CaseManagement[]>([]);

  useEffect(() => {
    const locationToEventKey = () => {
      const split = location.pathname.split('/');
      if (split[split.length - 1] === 'ncm') {
        setKey('ncm');
      } else {
        setKey(`detail`);
      }
    };
    locationToEventKey();
  }, [location]);

  const getNcms = useCallback(() => {
    if (claimNumber) {
      NcmApi.getNcmForClaim(claimNumber)
        .then((res) => {
          setNcms(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [claimNumber]);

  useEffect(() => {
    getNcms();
  }, [getNcms]);

  return (
    <div className='w-100 h-100'>
      <Tab.Container
        defaultActiveKey='ncmTable'
        activeKey={key}
        id='indemnityTabs'
        onSelect={(k) => setKey(k ?? '')}
      >
        <Nav variant='tabs' className='mb-3'>
          <Nav.Item>
            <NavLink to=''>
              <Nav.Link as='button' eventKey='ncm'>
                NCM
              </Nav.Link>
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as='button' eventKey='detail' disabled>
              Detail
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Routes>
          <Route path='/' element={<NcmTab ncms={ncms} getNcms={getNcms} />} />
          <Route
            path=':ncmId'
            element={<NcmDetail getNcms={getNcms} claimType={claimType} />}
          />
        </Routes>
      </Tab.Container>
    </div>
  );
}
