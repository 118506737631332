import React from 'react'
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap'
import { Field, Form as RFFForm } from "react-final-form"
import { FaPlusCircle } from 'react-icons/fa'
import { Letter } from '../../../ApiTypes/Letter'
import FieldBSRenderText from '../../Common/FieldBSRenderText'
import styles from "./index.module.css"

export default function AddLetterModal({
  insCoId,
  show,
  setShow,
  createNewLetter,
}: {
  insCoId: number,
  show: boolean,
  setShow: (show: boolean) => void,
  createNewLetter: (l: Letter) => Promise<void>
}) {


  const onSubmit = (values: Letter) => {
    return createNewLetter(values)
  }

  return (
    <Modal
      centered
      show={show}
      size="lg"
      onHide={() => {
        setShow(false)
      }}
      aria-labelledby="Add-Letter-Form-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="button-icon-text" id="Add-Letter-Form-modal">
          <FaPlusCircle className="pe-1" /> Add Letter
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={{
              insurCoId: insCoId,
              keyLetterId: 0,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name="letterName"
                    label="Letter Name"
                    type="text"
                    component={FieldBSRenderText}
                  />
                  <div className={styles.buttonDiv}>
                    <Button
                      type="submit"
                      variant='primary'
                      size="sm"
                    >
                      {submitting ?
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        : "Save"
                      }
                    </Button>
                    <Button
                      type="button"
                      variant='secondary'
                      size="sm"
                      onClick={() => {
                        form.restart()
                        setShow(false)
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              )
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  )
}
