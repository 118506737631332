import React, { useState } from 'react';
import { FaUsers } from 'react-icons/fa';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import PageScaffold from '../PageScaffold/PageScaffold';

import styles from './index.module.css';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import ProviderApi from '../../Api/ProviderApi';
import { toast } from 'react-toastify';
import { ProviderCleanupObject } from '../../ApiTypes/ProviderCleanupObject';
import ProviderCleanupTable from './ProviderCleanupTable';
import { SwapProviderIdRequest } from '../../ApiTypes/SwapProviderIdRequest';

export default function ProviderCleanup() {
  const [goodProviders, setGoodProviders] = useState<ProviderCleanupObject[]>(
    []
  );
  const [badProviders, setBadProviders] = useState<ProviderCleanupObject[]>([]);

  const [goodRowSelection, setGoodRowSelection] = useState({});
  const [badRowSelection, setBadRowSelection] = useState({});

  const [selectedGood, setSelectedGood] =
    useState<ProviderCleanupObject | null>(null);
  const [selectedBad, setSelectedBad] = useState<ProviderCleanupObject | null>(
    null
  );
  const [updating, setUpdating] = useState<boolean>(false);

  const onSubmitBad = (values: { criteria: string }) => {
    return ProviderApi.getProvidersBad(values.criteria ?? '')
      .then((res) => {
        setBadProviders(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get bad providers');
      });
  };
  const onSubmitGood = (values: { criteria: string }) => {
    return ProviderApi.getProvidersGood(values.criteria ?? '')
      .then((res) => {
        setGoodProviders(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get bad providers');
      });
  };

  const swapProviders = () => {
    if (selectedBad && selectedGood) {
      const request: SwapProviderIdRequest = {
        badProviderId: selectedBad.providerId,
        goodProviderId: selectedGood.providerId,
      };
      setUpdating(true);
      ProviderApi.swapProviders(request)
        .then((res) => {
          if (res.data.success) {
            setGoodRowSelection({});
            setBadRowSelection({});
            toast.success('Success');
          } else {
            toast.error(res.data.message);
          }
          setUpdating(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to swap provider ids');
          setUpdating(false);
        });
    }
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaUsers className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Provider Cleanup</h1>
          </div>
        </div>
      </div>
      <RFForm
        onSubmit={onSubmitBad}
        initialValues={{}}
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className={styles.grid4}>
                <p className='fw-bold'>Bad Providers: </p>
                <Field
                  name='criteria'
                  type='text'
                  label='Search Criteria'
                  component={FieldBSRenderText}
                />

                <div>
                  <Button type='submit' variant='primary' size='sm'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Search'
                    )}
                  </Button>
                </div>
                <div>
                  <Button
                    type='button'
                    size='sm'
                    variant='outline-primary'
                    onClick={() => {}}
                  >
                    Copy
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      />
      <ProviderCleanupTable
        data={badProviders}
        rowSelection={badRowSelection}
        setRowSelection={setBadRowSelection}
        setSelectedProvider={setSelectedBad}
      />

      <RFForm
        onSubmit={onSubmitGood}
        initialValues={{}}
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className={styles.grid4}>
                <p className='fw-bold'>Good Providers: </p>
                <Field
                  name='criteria'
                  type='text'
                  label='Search Criteria'
                  component={FieldBSRenderText}
                />

                <div>
                  <Button type='submit' variant='primary' size='sm'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Search'
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      />
      <ProviderCleanupTable
        data={goodProviders}
        rowSelection={goodRowSelection}
        setRowSelection={setGoodRowSelection}
        setSelectedProvider={setSelectedGood}
      />
      <div className={`${styles.grid3} py-3`}>
        <p className={styles.right}>Update transactions with provider Id:</p>
        <p className='fw-bold'>{selectedBad?.providerId ?? 'None Selected'}</p>
        <p className={styles.right}>To this new provider Id:</p>
        <p className='fw-bold'>{selectedGood?.providerId ?? 'None Selected'}</p>
        <Button
          className={styles.updateButton}
          type='button'
          variant='primary'
          onClick={swapProviders}
          disabled={!selectedBad || !selectedGood}
        >
          {updating ? (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            'Update'
          )}
        </Button>
      </div>
    </PageScaffold>
  );
}
