import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaFolderOpen } from 'react-icons/fa';
import { toast } from 'react-toastify';
import DepartmentApi from '../../Api/DepartmentApi';
import EmployerApi from '../../Api/EmployerApi';
import { Department } from '../../ApiTypes/Department';
import { Employer } from '../../ApiTypes/Employer';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { useAppSelector } from '../../Reducers/Store';
import BSSelect from '../Common/BSSelect';
import PageScaffold from '../PageScaffold/PageScaffold';
import AddEditDepartment from './AddEditDepartment';
import DepartmentTable from './DepartmentTable';
import styles from './index.module.css';

export default function DepartmentMaintenance() {
  const [employers, setEmployers] = useState<Employer[]>([]);

  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [selectedDepartment, setSelectedDepartment] =
    useState<Department | null>(null);

  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );

  const [accountNumberValue, setAccountNumberValue] = useState<string>('');
  const [employerId, setEmployerId] = useState<number>(0);

  const [fetching, setFetching] = useState(false);

  const handleAccountChange = (accountNumber: string) => {
    setAccountNumberValue(accountNumber);
    if (accountNumber) {
      setFetching(true);
      EmployerApi.getEmployerList({ accountNumber })
        .then((res) => {
          setEmployers(res.data);
          setFetching(false);
        })
        .catch((err) => {
          console.log(err);
          setEmployers([]);
          setFetching(false);
          toast.error('Failed to get employers for account');
        });
    } else {
      setEmployers([]);
    }
  };
  const handleEmployerChange = (employerId: number) => {
    setEmployerId(employerId);
    if (employerId > 0) {
      setFetching(true);
      EmployerApi.getEmployerDepartments(employerId)
        .then((res) => {
          setDepartments(res.data);
          setFetching(false);
        })
        .catch((err) => {
          console.log(err);
          setFetching(false);
          setDepartments([]);
          toast.error('Failed to departments for employer');
        });
    } else {
      setDepartments([]);
    }
  };

  const handleEdit = (department: Department | null) => {
    setSelectedDepartment(department);
    setShowEdit(true);
  };

  const handleSubmit = (values: Department) => {
    if (values.departmentId) {
      return update(values);
    }
    return create(values);
  };

  const create = (values: Department) => {
    return DepartmentApi.create(values)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          handleEmployerChange(employerId);
          setShowEdit(false);
          setSelectedDepartment(null);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create department');
      });
  };

  const update = (values: Department) => {
    return DepartmentApi.update(values)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          handleEmployerChange(employerId);
          setShowEdit(false);
          setSelectedDepartment(null);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update department');
      });
  };

  const handleDelete = (d: Department) => {
    DepartmentApi.delete(d.departmentId)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          handleEmployerChange(d.employerId);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to delete department');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaFolderOpen className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Departments</h1>
          </div>
        </div>
      </div>
      <div className={styles.grid4}>
        <div>
          <BSSelect
            name='accountNumber'
            label='Account'
            onChange={handleAccountChange}
            defaultValue={accountNumberValue}
            options={[...insuranceCompaniesForUser].sort((a, b) =>
              a.shortname!.localeCompare(b.shortname!)
            )}
            optionMethod={(options: InsuranceCompany[]) =>
              options.map((o) => (
                <option key={o.accountnumber!} value={o.accountnumber!}>
                  {`${o.accountnumber} - ${o.shortname}`}
                </option>
              ))
            }
          />
        </div>
        <div>
          <BSSelect
            name='employerId'
            label='Employer'
            onChange={handleEmployerChange}
            defaultValue={employerId}
            options={employers}
            optionMethod={(options: Employer[]) =>
              options.map((o) => (
                <option key={o.employerId!} value={o.employerId!}>
                  {o.name}
                </option>
              ))
            }
          />
        </div>
        <div className={`${styles.width33} ms-2`}>
          {fetching && (
            <Spinner
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          )}
        </div>
        <div>
          <Button
            type='button'
            size='sm'
            onClick={() => {
              if (!employerId) {
                toast.info('Select an employer for the new department');
                return;
              }
              setSelectedDepartment(null);
              setShowEdit(true);
            }}
          >
            Add New
          </Button>
        </div>
      </div>
      <DepartmentTable
        departments={departments}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
      <AddEditDepartment
        show={showEdit}
        setShow={setShowEdit}
        department={selectedDepartment}
        employerId={employerId}
        handleSubmit={handleSubmit}
      />
    </PageScaffold>
  );
}
