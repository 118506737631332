import React from 'react';
import { Container, Form, Modal } from 'react-bootstrap';
import { FaBriefcaseMedical } from 'react-icons/fa';
import { Form as RFForm, Field } from 'react-final-form';
import styles from './index.module.css';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import { parseDatesForServer } from '../../Utils';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import { InsCoLog } from '../../ApiTypes/InsCoLog';

export default function InsCoLogDetails({
  show,
  setShow,
  log,
}: {
  log: InsCoLog | null;
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='InsCoLogDetails-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='InsCoLogDetails-modal'>
          <FaBriefcaseMedical className='pe-1' /> Ins Co Log
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={() => {}}
            initialValues={log ?? {}}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className={styles.grid2}>
                    <Field
                      name='dateLogged'
                      label='Date Logged'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                      readOnly
                      disabled
                    />
                    <Field
                      name='userComments'
                      label='User Comments'
                      type='text'
                      component={FieldBSRenderText}
                      readOnly
                    />
                    <Field
                      name='insurcoid'
                      label='Ins Co Updated'
                      type='text'
                      component={FieldBSRenderText}
                      readOnly
                    />
                    <Field
                      name='systemGeneratedComments'
                      label='System Comments'
                      type='text'
                      component={FieldBSRenderText}
                      readOnly
                    />
                    <Field
                      name='changedBy.userName'
                      label='Updated By'
                      type='text'
                      component={FieldBSRenderText}
                      readOnly
                    />
                    <div />
                    <Field
                      name='parsedOldRecordValues'
                      label='Old Values'
                      rows={10}
                      component={FieldBSRenderTextArea}
                      readOnly
                      disabled
                    />
                    <Field
                      name='parsedNewRecordValues'
                      label='New Values'
                      rows={10}
                      component={FieldBSRenderTextArea}
                      readOnly
                      disabled
                    />
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
