import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaDollarSign } from 'react-icons/fa';
import ExportApi from '../../Api/ExportApi';
import PaymentsApi from '../../Api/PaymentsApi';
import { TransactionSearchRequest } from '../../ApiTypes/TransactionSearchRequest';
import { TransactionSearchResult } from '../../ApiTypes/TransactionSearchResult';
import { useAppSelector } from '../../Reducers/Store';
import { downloadExcel, parseDatesForServer } from '../../Utils';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import styles from './index.module.css';
import { paymentReportExportHandler } from './PaymentReportExportHandler';
import PaymentReportTable from './PaymentReportTable';
import Loading from '../Common/Loading';

export default function PaymentReportModal({
  show,
  setShow,
  claimNumber,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  claimNumber: string;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<TransactionSearchResult[]>([]);
  const [filteredRows, setFilteredRows] = useState<TransactionSearchResult[]>(
    []
  );
  const { payCodes, excessPayCodes } = useAppSelector(
    (state) => state.reference
  );

  useEffect(() => {
    initialSearch();
  }, [claimNumber]);

  const initialSearch = () => {
    if (claimNumber) {
      search({
        claimNumber,
        startDate: null,
        endDate: null,
        bucketsToInclude: ['1', '2', '3', '4'],
      });
    }
  };

  const search = (values: TransactionSearchRequest) => {
    setLoading(true);
    return PaymentsApi.searchTransaction(values)
      .then((res) => {
        setResults(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getFbucketDescription = (id: number) => {
    switch (id) {
      case 1:
        return 'Medical';
      case 2:
        return 'Indemnity';
      case 3:
        return 'Expense';
      case 4:
        return 'Legal';
      default:
        return 'Unknown';
    }
  };

  const getPayCodeDescription = (code: string) => {
    const payCode = [...payCodes, ...excessPayCodes].find(
      (x) => x.payCode === code
    );
    if (payCode) {
      return payCode.description;
    }
    return code;
  };

  const exportToExcel = () => {
    const claimNumber =
      filteredRows.length > 0 ? filteredRows[0].claimNo : results[0].claimNo;
    const rows = filteredRows.length > 0 ? filteredRows : results;
    const exportData = paymentReportExportHandler(
      rows,
      getPayCodeDescription,
      getFbucketDescription
    );
    exportData.fileName = `${claimNumber}-Payment-Report.xls`;

    return ExportApi.exportToExcel(exportData)
      .then((res) => {
        downloadExcel(res.data.fileName, res.data.file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='Payment-report-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Payment-report-modal'>
          <FaDollarSign className='pe-1' /> Payment Details Report
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            keepDirtyOnReinitialize
            onSubmit={search}
            initialValues={{
              claimNumber,
              bucketsToInclude: ['1', '2', '3', '4'],
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className={`${styles.grid3} mb-3`}>
                    <div className={`${styles.grid4}`}>
                      <Field
                        name='bucketsToInclude'
                        label='Medical'
                        type='checkbox'
                        value='1'
                        checked={values?.bucketsToInclude?.includes('1')}
                        component={FieldBSRenderCheckbox}
                      />
                      <Field
                        name='bucketsToInclude'
                        label='Indemnity'
                        type='checkbox'
                        value='2'
                        checked={values?.bucketsToInclude?.includes('2')}
                        component={FieldBSRenderCheckbox}
                      />
                      <Field
                        name='bucketsToInclude'
                        label='Expense'
                        type='checkbox'
                        value='3'
                        checked={values?.bucketsToInclude?.includes('3')}
                        component={FieldBSRenderCheckbox}
                      />
                      <Field
                        name='bucketsToInclude'
                        label='Legal'
                        type='checkbox'
                        value='4'
                        checked={values?.bucketsToInclude?.includes('4')}
                        component={FieldBSRenderCheckbox}
                      />
                    </div>
                    <Field
                      name='startDate'
                      label='Start Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                    <Field
                      name='endDate'
                      label='End Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                    <div className={styles.confirmButtonsDiv}>
                      <Button type='submit' size='sm' variant='primary'>
                        {submitting ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          'Search'
                        )}
                      </Button>
                      <Button
                        type='button'
                        size='sm'
                        variant='outline-secondary'
                        disabled={results.length === 0}
                        onClick={() => {
                          exportToExcel();
                        }}
                      >
                        Export
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          />
          {loading && <Loading />}
          <PaymentReportTable
            data={results}
            setFilteredRows={setFilteredRows}
            getFbucketDescription={getFbucketDescription}
            getPayCodeDescription={getPayCodeDescription}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
