import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { DocPriority } from '../../ApiTypes/DocPriority';
import { DocType } from '../../ApiTypes/DocType';
import { ScanDocsPending } from '../../ApiTypes/ScanDocsPending';
import PendingDocTable from './PendingDocTable';
import styles from './index.module.css';
import BSControlledSelect from '../Common/BSControlledSelect';
import { FaPlusCircle } from 'react-icons/fa';
import { DocumentStatus } from '../../ApiTypes/DocumentStatus';
import { RowSelectionState } from '@tanstack/react-table';
import EmailDocumentsModal from '../EmailDocuments/EmailDocumentsModal';
import { UserEmailList } from '../../ApiTypes/UserEmailList';
import { EmailDocumentsRequest } from '../../ApiTypes/EmailDocumentsRequest';
import DocumentsApi from '../../Api/DocumentsApi';
import { toast } from 'react-toastify';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';

export default function DocumentSide({
  setDocTypeId,
  docTypesFullScannerList,
  docTypes,
  getPending,
  pendingDocs,
  fetching,
  setSelectedDoc,
  statusOverride,
  setStatusOverride,
  docPriorities,
  nameOverride,
  typeOverride,
  priorityOverride,
  setNameOverride,
  setTypeOverride,
  setPriorityOverride,
  docTypeId,
  handleViewDoc,
  selectedDoc,
  rowSelection,
  setRowSelection,
  assignDocToClaim,
  assigning,
  userEmailList,
}: {
  setDocTypeId: (id: string) => void;
  docTypesFullScannerList: DocType[];
  docTypes: DocType[];
  getPending: () => void;
  pendingDocs: ScanDocsPending[];
  fetching: boolean;
  setSelectedDoc: (d: ScanDocsPending | null) => void;
  statusOverride: number;
  setStatusOverride: (status: number) => void;
  docPriorities: DocPriority[];
  nameOverride: string;
  typeOverride: string;
  priorityOverride: string;
  setNameOverride: (t: string) => void;
  setTypeOverride: (t: string) => void;
  setPriorityOverride: (t: string) => void;
  docTypeId: string;
  handleViewDoc: () => void;
  selectedDoc: ScanDocsPending | null;
  rowSelection: RowSelectionState;
  setRowSelection: (obj: object) => void;
  assignDocToClaim: () => Promise<void>;
  assigning: boolean;
  userEmailList: UserEmailList[];
}) {
  const [showEmail, setShowEmail] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

  const handleEmailDocuments = (values: EmailDocumentsRequest) => {
    return DocumentsApi.emailDocs(values)
      .then((res) => {
        if (res.data.success) {
          setShowEmail(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to share documents');
      });
  };

  const handleDelete = () => {
    return DocumentsApi.deleteScanDocPending(selectedDoc?.docId ?? 0)
      .then((res) => {
        setShowDeleteConfirm(false);
        if (res.data.success) {
          getPending();
        } else {
          toast.error('Failed to delete pending document');
        }
      })
      .catch((err) => {
        toast.error('Failed to delete pending document');
        setShowDeleteConfirm(false);
        console.log(err);
      });
  };

  return (
    <div>
      <h4>Document Queue</h4>
      <div className='d-flex align-items-center gap1Rem justify-content-center mb-2'>
        <BSControlledSelect
          name='docTypeId'
          label='Type of Document'
          onChange={setDocTypeId}
          value={docTypeId}
          options={docTypesFullScannerList}
          optionMethod={(options: DocType[]) =>
            options.map((o) => (
              <option key={o.docTypeId} value={o.docTypeId}>
                {o.description}
              </option>
            ))
          }
        />
        <Button type='button' variant='primary' size='sm' onClick={getPending}>
          {fetching ? (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            'Refresh'
          )}
        </Button>
      </div>
      <PendingDocTable
        data={pendingDocs}
        setSelectedDoc={setSelectedDoc}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        setShowEmail={setShowEmail}
        setShowDeleteConfirm={setShowDeleteConfirm}
      />
      <div className='mt-3'>
        <div className={`${styles.docGrid2}`}>
          <div>
            <span>Status Override</span>
            <div className='d-flex gap1Rem'>
              <div className='form-check'>
                <label className='form-check-label'>
                  <input
                    type='radio'
                    className='form-check-input'
                    checked={statusOverride === DocumentStatus.Accepted}
                    value={DocumentStatus.Accepted}
                    onChange={(e) => {
                      setStatusOverride(DocumentStatus.Accepted);
                    }}
                  />
                  Accepted
                </label>
              </div>
              <div className='form-check'>
                <label className='form-check-label'>
                  <input
                    type='radio'
                    className='form-check-input'
                    checked={statusOverride === DocumentStatus.New}
                    value={DocumentStatus.New}
                    onChange={(e) => {
                      setStatusOverride(DocumentStatus.New);
                    }}
                  />
                  New
                </label>
              </div>
            </div>
          </div>
          <BSControlledSelect
            name='docTypeIdOverride'
            label='Type Override'
            onChange={setTypeOverride}
            value={typeOverride}
            options={docTypes}
            optionMethod={(options: DocType[]) =>
              options.map((o) => (
                <option key={o.docTypeId} value={o.docTypeId}>
                  {o.description}
                </option>
              ))
            }
          />
        </div>
        <div className={`${styles.docGrid2}`}>
          <div className={`position-relative ${styles.vFieldHeight}`}>
            <label htmlFor='nameOverride' className='form-label fs-6  m-0'>
              Name Override
            </label>
            <input
              type='text'
              className='form-control form-control-sm'
              placeholder='Name Override'
              value={nameOverride}
              onChange={(e) => {
                setNameOverride(e.target.value);
              }}
            />
          </div>
          <BSControlledSelect
            name='priorityOverride'
            label='Priority'
            onChange={setPriorityOverride}
            value={priorityOverride}
            options={docPriorities}
            optionMethod={(options: DocPriority[]) =>
              options.map((o) => (
                <option key={o.keyDocPriorityId} value={o.keyDocPriorityId}>
                  {o.name}
                </option>
              ))
            }
          />
        </div>
      </div>
      <div className='d-flex justify-content-around align-items-center'>
        <Button
          type='button'
          className='button-icon-text'
          variant='outline-primary'
          size='sm'
          disabled={!selectedDoc}
          onClick={assignDocToClaim}
        >
          {assigning ? (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            <FaPlusCircle />
          )}
          Doc to Claim
        </Button>
      </div>
      <EmailDocumentsModal
        show={showEmail}
        setShow={setShowEmail}
        handleEmailDocuments={handleEmailDocuments}
        userEmailList={userEmailList}
        claimNumber={'PENDING'}
        docs={[]}
        pendingDocs={selectedDoc ? [selectedDoc] : []}
      />
      <DeleteConfirmationModal
        show={showDeleteConfirm}
        setShow={setShowDeleteConfirm}
        setNull={() => null}
        handleDelete={handleDelete}
      />
    </div>
  );
}
