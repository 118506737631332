import React from 'react';
import { Field, Form as RFFForm } from 'react-final-form';
import { Button, Form, Spinner } from 'react-bootstrap';
import styles from './index.module.css';
import { Claim } from '../../../ApiTypes/Claim';
import { useAppDispatch, useAppSelector } from '../../../Reducers/Store';
import FieldBSRenderTextArea from '../../Common/FieldRenderTextArea';
import ClaimApi from '../../../Api/ClaimApi';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { fetchClaimByClaimNumber } from '../../../Actions/ClaimActions';

export default function ClaimReviewStatus() {
  let { claimNumber } = useParams();
  const dispatch = useAppDispatch();
  const { claim } = useAppSelector((state) => state.currentClaimReducer);
  const onSubmit = (values: Claim) => {
    return ClaimApi.updateClaim(values)
      .then((res) => {
        if (res.data.success) {
          dispatch(fetchClaimByClaimNumber(claimNumber!));
          toast.success('Claim updated');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update claim');
      });
  };
  return (
    <div className='w-100 h-100'>
      <div className={`${styles.statusReview}`}>
        <RFFForm
          onSubmit={onSubmit}
          initialValues={claim ?? {}}
          render={({ handleSubmit, form, values, submitting }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Field
                  name='statusEval'
                  label='Status and Evaluation'
                  rows={3}
                  component={FieldBSRenderTextArea}
                />
                <Field
                  name='reservesEval'
                  label='Reserves'
                  rows={3}
                  component={FieldBSRenderTextArea}
                />
                <Field
                  name='actionPlan'
                  label='Action Plan'
                  rows={3}
                  component={FieldBSRenderTextArea}
                />
                <div className='d-flex justify-content-center align-items-center my-5'>
                  <Button type='submit' variant='primary' size='sm'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        />
      </div>
    </div>
  );
}
