import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { AutomatedReportingJob } from '../ApiTypes/AutomatedReportingJob';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class AutomatedReportingApi {
  static getAllJobs() {
    return axios.get<AutomatedReportingJob[]>(
      `${API_URL}/api/automatedemails`,
      getAuthHeader()
    );
  }
  static updateJob(job: AutomatedReportingJob) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/automatedemails/${job.id}`,
      job,
      getAuthHeader()
    );
  }
  static createJob(job: AutomatedReportingJob) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/automatedemails`,
      job,
      getAuthHeader()
    );
  }
  static sendJob(jobId: number, isTest: boolean, testTo: string) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/automatedemails/${jobId}/send?isTest=${isTest}&testTo=${testTo}`,
      {},
      getAuthHeader()
    );
  }
  static sendAllJobs() {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/automatedemails/sendall`,
      {},
      getAuthHeader()
    );
  }
}

export default AutomatedReportingApi;
