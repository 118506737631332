import React from 'react';
import { Form as RFForm, Field } from 'react-final-form';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { FaCheck, FaEdit } from 'react-icons/fa';
import styles from './index.module.css';
import { ExcessCarrier } from '../../ApiTypes/ExcessCarrier';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import { useAppSelector } from '../../Reducers/Store';
import { State } from '../../ApiTypes/State';
import { zipLength } from '../../Utils/FieldValidation';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import {
  formatPhoneNumbers,
  parsePhoneNumber15Length,
} from '../../Utils/InputFormatters';
import ExcessCarrierApi from '../../Api/ExcessCarrierApi';
import { toast } from 'react-toastify';

export default function AddEditExcessCarrier({
  show,
  setShow,
  excessCarrier,
  fetchData,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  excessCarrier: ExcessCarrier | null;
  fetchData: () => void;
}) {
  const { states } = useAppSelector((state) => state.reference);
  const onSubmit = (values: ExcessCarrier) => {
    if (values.excessCarrierId) {
      return updateCarrier(values);
    }
    return createCarrier(values);
  };

  const createCarrier = (values: ExcessCarrier) => {
    return ExcessCarrierApi.create(values)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          fetchData();
          setShow(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create carrier');
      });
  };
  const updateCarrier = (values: ExcessCarrier) => {
    return ExcessCarrierApi.update(values)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          fetchData();
          setShow(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update carrier');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='AddEditExcessCarrier-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='AddEditExcessCarrier-modal'
        >
          <FaEdit className='pe-1' /> Excess Carrier
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={excessCarrier ?? {}}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='name'
                    label='Excess Carrier'
                    type='text'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='address'
                    type='text'
                    label='Address'
                    component={FieldBSRenderText}
                  />
                  <div className={`${styles.flex3}`}>
                    <Field
                      name='city'
                      type='text'
                      label='City'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='state'
                      label='State'
                      options={states}
                      optionMethod={(options: State[]) =>
                        options.map((o) => (
                          <option key={o.stateAbbr} value={o.stateAbbr}>
                            {o.stateAbbr}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                    <Field
                      name='zip'
                      type='text'
                      label='Zip'
                      validate={zipLength}
                      component={FieldBSRenderText}
                      maxLength={5}
                    />
                  </div>
                  <Field
                    name='phone'
                    type='text'
                    label='Cell Phone'
                    component={FieldBSRenderText}
                  />

                  <Field
                    name='webSite'
                    type='text'
                    label='Website'
                    component={FieldBSRenderText}
                  />
                  <div className='d-flex justify-content-center align-items-center gap1Rem py-3'>
                    <Button
                      type='button'
                      size='sm'
                      variant='secondary'
                      onClick={() => {
                        form.reset();
                        form.restart();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type='submit' size='sm' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
