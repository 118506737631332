import { FormApi } from 'final-form';
import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { useAppSelector } from '../../Reducers/Store';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderYesNoSelect from '../Common/FieldBSRenderYesNoSelect';

import styles from './index.module.css';

export default function EdiFroiBottom({
  form,
  formValues,
  transaction,
}: {
  form: FormApi<EdiTransaction, Partial<EdiTransaction>>;
  formValues: Partial<EdiTransaction>;
  transaction: EdiTransaction | null;
}) {
  const { claim } = useAppSelector((state) => state.currentClaimReducer);

  useEffect(() => {
    handleLoad();
  }, [claim, transaction]);

  const handleLoad = () => {
    if (!transaction) {
      if (claim?.disabilityDate) {
        form.change('disabilitydate', claim.disabilityDate);
      }
    }
  };

  return (
    <div className={`${styles.edi3Grid}`}>
      <Field
        name='disabilitydate'
        label='Date of Disability'
        parse={parseDatesForServer}
        component={FieldBSRenderDate}
        validate={requiredField}
      />
      <Field
        name='empPaidSalaryInLieuOfCompensation'
        label='Salary in Lieu of Compensation'
        component={FieldBSRenderYesNoSelect}
      />
      <Field
        name='fullWagesPaidOnDoi'
        label='Full Wages Paid on DOI'
        component={FieldBSRenderYesNoSelect}
      />
    </div>
  );
}
