import React from 'react';
import { Form as RFForm, Field } from 'react-final-form';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import styles from './index.module.css';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import { ReconcileCheckRequest } from '../../ApiTypes/ReconcileCheckRequest';
import ChecksApi from '../../Api/ChecksApi';
import { toast } from 'react-toastify';
import { FormApi } from 'final-form';

export default function QuickReconcile({
  show,
  setShow,
  insurCoId,
  insuranceCompaniesForUser,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  insuranceCompaniesForUser: InsuranceCompany[];
  insurCoId: number;
}) {
  let formInstance: FormApi<
    ReconcileCheckRequest,
    Partial<ReconcileCheckRequest>
  >;

  const onSubmit = (values: ReconcileCheckRequest) => {
    return ChecksApi.reconcileCheck(values)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success! check updated');
          formInstance.restart();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to reconcile check');
      });
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='QuickReconcile-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='QuickReconcile-modal'>
          <FaCheck className='pe-1' /> Quick Reconcile
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              cleared: false,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              formInstance = form;
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='insurCoId'
                    label='Account'
                    options={insuranceCompaniesForUser.filter(
                      (ins) => ins.reconcileChecks
                    )}
                    optionMethod={(options: InsuranceCompany[]) =>
                      options.map((o) => (
                        <option key={o.insurcoid} value={o.insurcoid ?? ''}>
                          {`${o.accountnumber} - ${o.shortname}`}
                        </option>
                      ))
                    }
                    defaultValue={insurCoId}
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='checkNumber'
                    type='text'
                    label='Check Number'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='cleared'
                    type='checkbox'
                    label='Cleared'
                    checked={!!values.cleared}
                    component={FieldBSRenderCheckbox}
                  />
                  <div className='d-flex justify-content-center align-items-center gap1Rem py-3'>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
