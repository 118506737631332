import { createReducer } from '@reduxjs/toolkit';
import { ReferenceReducerType } from '../Types/ReferenceReducerTypes';

import {
  fetchAdjusters,
  fetchBodyParts,
  fetchCounties,
  fetchCauses,
  fetchResults,
  fetchJobClasses,
  fetchStates,
  fetchClaimStatuses,
  fetchMedicares,
  fetchShifts,
  fetchSupervisors,
  fetchInsuranceCompanies,
  fetchClaimNoteTypes,
  fetchPayCodesIsExcess,
  fetchPayCodes,
  fetchNurseCaseManagers,
  fetchCaseManagementResolutionTypes,
  fetchAttorneyTypes,
  fetchLawFirms,
  fetchSettlementTypes,
  fetchDispositionTypes,
  fetchJudges,
  fetchAppealRulings,
  fetchAwardRulings,
  fetchDocTypes,
  fetchDocStatuses,
  fetchEdiCodes,
  fetchEdiBenefitTypeCodes,
  fetchEdiMaintTypeCodes,
  fetchEdiPartOfBodyCodes,
  fetchWorkStatusTypes,
  fetchWorkStatusBodyParts,
  fetchInsuranceCompaniesForUser,
  getPbms,
  getDocPriorities,
  getDocTypesFullScanner,
  fetchEmployerPolicyClassCodes,
  fetchClaimsAssistants,
  fetchIcdCodes,
  fetchPlanInsuranceTypes,
  fetchInsuranceTypes,
  fetchPropertyTypes,
  fetchClaimType,
  fetchStopPaymentOptions,
} from '../Actions/ReferenceActions';

import { clearState } from '../Actions/index';
import { fetchAllClaimCustomOptions } from '../Actions/ClaimCustomOptionActions';

const initialState: ReferenceReducerType = {
  adjusters: [],
  bodyParts: [],
  counties: [],
  causes: [],
  results: [],
  jobClasses: [],
  states: [],
  claimStatuses: [],
  medicares: [],
  shifts: [],
  supervisors: [],
  insuranceCompanies: [],
  insuranceCompaniesForUser: [],
  claimNoteTypes: [],
  payCodes: [],
  excessPayCodes: [],
  nurseCaseManagers: [],
  claimsAssistants: [],
  caseManagementResolutionTypes: [],
  attorneyTypes: [],
  lawFirms: [],
  settlementTypes: [],
  dispositionTypes: [],
  judges: [],
  appealRulings: [],
  awardRulings: [],
  docTypes: [],
  docStatuses: [],
  ediCodes: [],
  ediBenefitTypeCodes: [],
  ediMaintTypeCodes: [],
  FROIEdiMaintTypeCodes: [],
  SROIEdiMaintTypeCodes: [],
  ediPartOfBodyCodes: [],
  workStatusTypes: [],
  workStatusBodyParts: [],
  pbms: [],
  docPriorities: [],
  docTypesFullScannerList: [],
  policyClassCodes: [],
  icdCodes: [],
  planInsuranceType: [],
  insuranceType: [],
  propertyTypes: [],
  claimTypes: [],
  claimCustomOptions: [],
  stopPaymentOptions: [],
};

const ReferenceReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAdjusters.fulfilled, (state, action) => {
      return { ...state, adjusters: action.payload };
    })
    .addCase(fetchBodyParts.fulfilled, (state, action) => {
      return { ...state, bodyParts: action.payload };
    })
    .addCase(fetchCounties.fulfilled, (state, action) => {
      return { ...state, counties: action.payload };
    })
    .addCase(fetchCauses.fulfilled, (state, action) => {
      return { ...state, causes: action.payload };
    })
    .addCase(fetchResults.fulfilled, (state, action) => {
      return { ...state, results: action.payload };
    })
    .addCase(fetchJobClasses.fulfilled, (state, action) => {
      return { ...state, jobClasses: action.payload };
    })
    .addCase(fetchEmployerPolicyClassCodes.fulfilled, (state, action) => {
      return { ...state, policyClassCodes: action.payload };
    })
    .addCase(fetchStates.fulfilled, (state, action) => {
      return { ...state, states: action.payload };
    })
    .addCase(fetchClaimStatuses.fulfilled, (state, action) => {
      return { ...state, claimStatuses: action.payload };
    })
    .addCase(fetchMedicares.fulfilled, (state, action) => {
      return { ...state, medicares: action.payload };
    })
    .addCase(fetchShifts.fulfilled, (state, action) => {
      return { ...state, shifts: action.payload };
    })
    .addCase(fetchInsuranceCompanies.fulfilled, (state, action) => {
      return { ...state, insuranceCompanies: action.payload };
    })
    .addCase(fetchInsuranceCompaniesForUser.fulfilled, (state, action) => {
      return { ...state, insuranceCompaniesForUser: action.payload };
    })
    .addCase(fetchSupervisors.fulfilled, (state, action) => {
      return { ...state, supervisors: action.payload };
    })
    .addCase(fetchClaimNoteTypes.fulfilled, (state, action) => {
      return { ...state, claimNoteTypes: action.payload };
    })
    .addCase(fetchPayCodesIsExcess.fulfilled, (state, action) => {
      return { ...state, excessPayCodes: action.payload };
    })
    .addCase(fetchNurseCaseManagers.fulfilled, (state, action) => {
      return { ...state, nurseCaseManagers: action.payload };
    })
    .addCase(fetchClaimsAssistants.fulfilled, (state, action) => {
      return { ...state, claimsAssistants: action.payload };
    })
    .addCase(fetchPayCodes.fulfilled, (state, action) => {
      return { ...state, payCodes: action.payload };
    })
    .addCase(fetchCaseManagementResolutionTypes.fulfilled, (state, action) => {
      return { ...state, caseManagementResolutionTypes: action.payload };
    })
    .addCase(fetchAttorneyTypes.fulfilled, (state, action) => {
      return { ...state, attorneyTypes: action.payload };
    })
    .addCase(fetchLawFirms.fulfilled, (state, action) => {
      return { ...state, lawFirms: action.payload };
    })
    .addCase(fetchSettlementTypes.fulfilled, (state, action) => {
      return { ...state, settlementTypes: action.payload };
    })
    .addCase(fetchDispositionTypes.fulfilled, (state, action) => {
      return { ...state, dispositionTypes: action.payload };
    })
    .addCase(fetchJudges.fulfilled, (state, action) => {
      return { ...state, judges: action.payload };
    })
    .addCase(fetchAppealRulings.fulfilled, (state, action) => {
      return { ...state, appealRulings: action.payload };
    })
    .addCase(fetchAwardRulings.fulfilled, (state, action) => {
      return { ...state, awardRulings: action.payload };
    })
    .addCase(fetchDocTypes.fulfilled, (state, action) => {
      return { ...state, docTypes: action.payload };
    })
    .addCase(fetchDocStatuses.fulfilled, (state, action) => {
      return { ...state, docStatuses: action.payload };
    })
    .addCase(fetchEdiCodes.fulfilled, (state, action) => {
      return { ...state, ediCodes: action.payload };
    })
    .addCase(fetchEdiBenefitTypeCodes.fulfilled, (state, action) => {
      return { ...state, ediBenefitTypeCodes: action.payload };
    })
    .addCase(fetchEdiPartOfBodyCodes.fulfilled, (state, action) => {
      return { ...state, ediPartOfBodyCodes: action.payload };
    })
    .addCase(fetchWorkStatusTypes.fulfilled, (state, action) => {
      return { ...state, workStatusTypes: action.payload };
    })
    .addCase(fetchWorkStatusBodyParts.fulfilled, (state, action) => {
      return { ...state, workStatusBodyParts: action.payload };
    })
    .addCase(getPbms.fulfilled, (state, action) => {
      return { ...state, pbms: action.payload };
    })
    .addCase(getDocPriorities.fulfilled, (state, action) => {
      return { ...state, docPriorities: action.payload };
    })
    .addCase(getDocTypesFullScanner.fulfilled, (state, action) => {
      return { ...state, docTypesFullScannerList: action.payload };
    })
    .addCase(fetchPropertyTypes.fulfilled, (state, action) => {
      return { ...state, propertyTypes: action.payload };
    })
    .addCase(fetchClaimType.fulfilled, (state, action) => {
      return { ...state, claimTypes: action.payload };
    })
    .addCase(fetchAllClaimCustomOptions.fulfilled, (state, action) => {
      return { ...state, claimCustomOptions: action.payload };
    })
    .addCase(fetchEdiMaintTypeCodes.fulfilled, (state, action) => {
      const froi = action.payload.filter((t) => {
        return t.record?.includes('148') && t.code !== '02';
      });
      const sroi = action.payload.filter((t) => {
        return t.record?.includes('A49') && t.code !== '02';
      });

      const froiSorted = [...froi].sort(
        (a, b) => (a.sortorder ?? 0) - (b.sortorder ?? 0)
      );
      const sroiSorted = [...sroi].sort(
        (a, b) => (a.sortorder ?? 0) - (b.sortorder ?? 0)
      );

      return {
        ...state,
        ediMaintTypeCodes: action.payload,
        FROIEdiMaintTypeCodes: froiSorted,
        SROIEdiMaintTypeCodes: sroiSorted,
      };
    })
    .addCase(fetchIcdCodes.fulfilled, (state, action) => {
      return { ...state, icdCodes: action.payload };
    })
    .addCase(fetchPlanInsuranceTypes.fulfilled, (state, action) => {
      return { ...state, planInsuranceType: action.payload };
    })
    .addCase(fetchInsuranceTypes.fulfilled, (state, action) => {
      return { ...state, insuranceType: action.payload };
    })
    .addCase(fetchStopPaymentOptions.fulfilled, (state, action) => {
      return { ...state, stopPaymentOptions: action.payload };
    })
    .addCase(clearState, (state, action) => {
      return initialState;
    });
});

export default ReferenceReducer;
