import React, { useState, useEffect } from 'react';
import {
  FaChartPie,
  FaChevronCircleDown,
  FaChevronCircleUp,
} from 'react-icons/fa';
import { Form as RFForm, Field } from 'react-final-form';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { Button, Form, Spinner } from 'react-bootstrap';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { parseDatesForServer } from '../../Utils';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import { Link, useParams } from 'react-router-dom';
import { requiredField } from '../../Utils/FieldValidation';
import { addDays, format } from 'date-fns';
import ClaimStatisticApi from '../../Api/ClaimStatisticApi';
import { ClaimStatisticsObj } from '../../ApiTypes/ClaimStatisticObj';
import { DailyCounts } from '../../ApiTypes/DailyCounts';
import LineChart from './LineChart';
import { ChartObj } from '../../ApiTypes/ChartObj';
import PieChart from './PieChart';
import UserApi from '../../Api/UserApi';
import { VwUser } from '../../ApiTypes/VwUser';
import { toast } from 'react-toastify';
import { ComboBoxHelper } from '../../ApiTypes/ComboBoxHelper';
import NonAdjusterPieChart from './NonAdjusterPieChart';
import { useAppSelector } from '../../Reducers/Store';

export default function ClaimStatisticsPage() {
  const { userId } = useParams();
  const [users, setUsers] = useState<VwUser[]>([]);
  const [stat, setStat] = useState<ClaimStatisticsObj | null>(null);
  const [docsForUser, setDocsForUser] = useState<DailyCounts[]>([]);
  const [movedDocs, setMovedDocs] = useState<DailyCounts[]>([]);
  const [ltClaimHistory, setLtClaimHistory] = useState<ChartObj[]>([]);
  const [allClaimHistory, setAllClaimHistory] = useState<ChartObj[]>([]);
  const [allHistory, setAllHistory] = useState<ChartObj[]>([]);
  const [docStatsByStatus, setDocStatsByStatus] = useState<ComboBoxHelper[]>(
    []
  );

  const { userModel } = useAppSelector((state) => state.user);

  // frmClaimStatistic line 51
  let isAdjuster = false;

  const onSubmit = (values: {
    userId: string;
    fromDate: string;
    toDate: string;
  }) => {
    const userId = values.userId ?? '';
    return getStat(userId, values.fromDate, values.toDate);
  };
  useEffect(() => {
    getAdjusters();
  }, []);

  const getStat = (userId: string, fromDate: string, toDate: string) => {
    return ClaimStatisticApi.getStat(userId, fromDate, toDate)
      .then((res) => {
        setStat(res.data);
        getDocs(res.data.adjusterId, fromDate, toDate);
        getLTClaimHistory(res.data.adjusterId, fromDate, toDate);
        getAllClaimHistory(res.data.adjusterId, fromDate, toDate);
        getDocumentStatsByStatus(res.data.userId, fromDate, toDate);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDocs = (adjusterId: number, fromDate: string, toDate: string) => {
    return ClaimStatisticApi.getDocDailyCountsForUser(
      adjusterId,
      fromDate,
      toDate
    )
      .then((res) => {
        setMovedDocs(res.data);
        if (adjusterId && adjusterId > 0 && res.data.length > 0) {
          getMovedDocs(adjusterId, fromDate, toDate);
        } else {
          getAllHistory(stat?.userId ?? '', fromDate, toDate);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getMovedDocs = (
    adjusterId: number,
    fromDate: string,
    toDate: string
  ) => {
    return ClaimStatisticApi.getDocMovedDailyCountsForUser(
      adjusterId,
      fromDate,
      toDate
    )
      .then((res) => {
        setDocsForUser(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllHistory = (userId: string, fromDate: string, toDate: string) => {
    return ClaimStatisticApi.getAllHistory(userId, fromDate, toDate)
      .then((res) => {
        setAllHistory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getLTClaimHistory = (
    adjusterId: number,
    fromDate: string,
    toDate: string
  ) => {
    return ClaimStatisticApi.getLTClaimHistory(adjusterId, fromDate, toDate)
      .then((res) => {
        setLtClaimHistory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllClaimHistory = (
    adjusterId: number,
    fromDate: string,
    toDate: string
  ) => {
    return ClaimStatisticApi.getAllClaimHistory(adjusterId, fromDate, toDate)
      .then((res) => {
        setAllClaimHistory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDocumentStatsByStatus = (
    userId: string,
    fromDate: string,
    toDate: string
  ) => {
    ClaimStatisticApi.getDocumentStatsByStatus(userId, fromDate, toDate)
      .then((res) => {
        setDocStatsByStatus(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAdjusters = () => {
    UserApi.getUsersForMaintenance(true)
      .then((res) => {
        setUsers(res.data.filter((x) => x.userId !== '-1'));
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get users');
      });
  };
  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaChartPie className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Claim Statistics</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <RFForm
          onSubmit={onSubmit}
          initialValues={{
            fromDate: format(addDays(new Date(), -7), 'yyyy-MM-dd'),
            toDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
            userId: userId ?? userModel?.user?.userId,
          }}
          render={({ handleSubmit, form, values, submitting }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div className='d-flex  align-items-center gap1Rem'>
                  <Field
                    name='userId'
                    label='Adjuster'
                    options={users}
                    optionMethod={(options: VwUser[]) =>
                      options.map((o) => (
                        <option key={o.userId} value={o.userId}>
                          {o.userName}
                        </option>
                      ))
                    }
                    // validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='fromDate'
                    type='text'
                    label='Date From'
                    validate={requiredField}
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                  />
                  <Field
                    name='toDate'
                    type='text'
                    label='To Date'
                    validate={requiredField}
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                  />

                  <Button type='submit' variant='primary' size='sm'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Search'
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        />
        <Link to='/adjuster-summary'>
          <Button type='button' variant='outline-primary' size='sm'>
            View Summary
          </Button>
        </Link>
      </div>
      <div className={`${styles.grid2}`}>
        <div>
          <div>
            <div className={`${styles.grid4}`}>
              <p className='fw-bold'></p>
              <p className='fw-bold'>Total</p>
              <p className='fw-bold'>Lost Time</p>
              <p className='fw-bold'>Medical Only</p>

              <p className='fw-bold'>Claims Created</p>
              <p>{stat?.claimsCreated}</p>
              <p>{stat?.claimsCreatedLT}</p>
              <p>{stat?.claimsCreatedMO}</p>

              <p className='fw-bold'>Closed Claims</p>
              <p>{stat?.claimsClosedTotal}</p>
              <p>{stat?.claimsClosedLT}</p>
              <p>{stat?.claimsClosedMO}</p>

              <p className='fw-bold'>Total Claims</p>
              <p>{stat?.totalClaims}</p>
              <p>{stat?.totalClaimsLT}</p>
              <p>{stat?.totalClaimsMO}</p>

              <p className='fw-bold'>Documents Added</p>
              <p>{stat?.documentsAdded}</p>
              <p>{stat?.documentsAddedLT}</p>
              <p>{stat?.documentsAddedMO}</p>

              <p className='fw-bold'>Documents Moved</p>
              <p>{stat?.documentsMoved}</p>
              <p>{stat?.documentsMovedLT}</p>
              <p>{stat?.documentsMovedMO}</p>

              <p className='fw-bold'>Current Inventory</p>
              <p>{stat?.documentsTotal}</p>
              <p>{stat?.documentsTotalLT}</p>
              <p>{stat?.documentsTotalMO}</p>

              <p className='fw-bold'>Closed Inventory</p>
              <p>{stat?.documentsClosedTotal}</p>
              <p>{stat?.documentsClosedLT}</p>
              <p>{stat?.documentsClosedMO}</p>

              <p className='fw-bold'>Sent to Straight Pay</p>
              <p>{stat?.documentsSentToStraightPay}</p>
              <p></p>
              <p></p>

              <p className='fw-bold'>Straight Pay Approved</p>
              <p>{stat?.documentsApprovedForStraightPay}</p>
              <p></p>
              <p></p>

              <p className='fw-bold'>Sent to Comp IQ</p>
              <p>{stat?.documentsSentToCompIQ}</p>
              <p></p>
              <p></p>
            </div>
            <div className={`${styles.grid5}`}>
              <div className='d-flex flex-column align-items-center'>
                <span>{stat?.avgDocsPerDay.toFixed(1)}</span>
                <span className='fw-bold'>
                  Avg Docs
                  <br />
                  per Day
                </span>
              </div>
              <div className='d-flex flex-column align-items-center'>
                <span>{stat?.avgDocsHandled.toFixed(1)}</span>
                <span className='fw-bold'>
                  Avg Docs
                  <br />
                  Handled
                </span>
              </div>
              <div className='d-flex flex-column align-items-center'>
                <span>{stat?.currentUnhandledDocsCount}</span>
                <span className='fw-bold'>
                  Current
                  <br />
                  Docs
                </span>
              </div>
              <div className='d-flex flex-column align-items-center justify-content-between'>
                <span>
                  {stat?.percentDifferent
                    ? `${stat.percentDifferent.toFixed(2)}%`
                    : '0%'}
                </span>
                <span className='fw-bold'>
                  {(stat?.percentDifferent ?? 0) < 0 ? (
                    <FaChevronCircleDown className='text-danger' />
                  ) : (
                    <FaChevronCircleUp className='text-success' />
                  )}
                </span>
              </div>
              <div className='d-flex flex-column align-items-center justify-content-between'>
                <span>
                  {stat?.claimsCreated && stat.numberOfDays
                    ? Math.ceil(stat?.claimsCreated / stat?.numberOfDays)
                    : 0}
                </span>
                <span className='fw-bold'>
                  Avg Claims
                  <br />
                  per Day
                </span>
              </div>
            </div>
          </div>
        </div>
        {stat && stat.adjusterId > 0 ? (
          <PieChart
            allClaimHistory={allClaimHistory}
            ltClaimHistory={ltClaimHistory}
          />
        ) : (
          <NonAdjusterPieChart docStatsByStatus={docStatsByStatus} />
        )}
      </div>
      <hr />
      <LineChart
        data={docsForUser}
        movedData={movedDocs}
        allHistory={allHistory}
        showAllHistory={
          !(stat != null && stat?.adjusterId > 0 && docsForUser.length > 0)
        }
      />
    </PageScaffold>
  );
}
