import { createReducer } from '@reduxjs/toolkit';

import {
  clearState,
  clearSelectedClaim,
} from "../Actions/index"

import {
  fetchAddressById
} from "../Actions/AddressActions"
import { Address } from '../ApiTypes/Address';

type AddressReducerType = {
  addresses: Address[]
}

const initialState: AddressReducerType = {
  addresses: [],
}

const AddressReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAddressById.fulfilled, (state, action) => {
      return { ...state, addresses: [...state.addresses, action.payload] }
    })
    .addCase(clearSelectedClaim, (state, action) => {
      return initialState;
    })
    .addCase(clearState, (state, action) => {
      return initialState;
    })
})

export default AddressReducer