import React from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { FaUsers } from 'react-icons/fa';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';

import styles from './index.module.css';
import { useParams } from 'react-router-dom';
import { requiredField, zipLength } from '../../Utils/FieldValidation';
import { useAppSelector } from '../../Reducers/Store';
import { State } from '../../ApiTypes/State';
import { LawFirm } from '../../ApiTypes/LawFirm';
import { formatPhoneNumbers } from '../../Utils/InputFormatters';

export default function LawFirmAddEditModal({
  show,
  setShow,
  lawFirmToEdit,
  setLawFirmToEdit,
  handleLawFirmAddEdit,
}: {
  show: boolean;
  lawFirmToEdit: LawFirm | null;
  setShow: (show: boolean) => void;
  setLawFirmToEdit: (firm: LawFirm | null) => void;
  handleLawFirmAddEdit: (values: LawFirm) => Promise<void>;
}) {
  let { claimNumber } = useParams();

  const { states } = useAppSelector((state) => state.reference);

  const onSubmit = (values: LawFirm) => {
    console.log(values);
    const copy = { ...values };
    return handleLawFirmAddEdit(copy);
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
        setLawFirmToEdit(null);
      }}
      dialogClassName=''
      aria-labelledby='Edit-Create-Law-Firm-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Edit-Create-Law-Firm-Form-modal'
        >
          <FaUsers className='pe-1' /> Law Firm Add/Edit
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={lawFirmToEdit ?? {}}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='firmName'
                    label='Name'
                    type='text'
                    component={FieldBSRenderText}
                    validate={requiredField}
                  />
                  <Field
                    name='phone'
                    label='Phone'
                    type='text'
                    format={formatPhoneNumbers}
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='fax'
                    label='Fax'
                    type='text'
                    format={formatPhoneNumbers}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='webSite'
                    label='Website'
                    type='text'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='streetAddress'
                    label='Address'
                    type='text'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='streetAddress1'
                    label='Address 1'
                    type='text'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='city'
                    label='City'
                    type='text'
                    component={FieldBSRenderText}
                  />
                  <div className={`${styles.twoColumns}`}>
                    <Field
                      name='state'
                      label='State'
                      options={states}
                      defaultValue='GA'
                      optionMethod={(options: State[]) =>
                        options.map((o) => (
                          <option key={o.stateAbbr} value={o.stateAbbr}>
                            {o.stateAbbr}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                    <Field
                      name='zip'
                      label='Zip'
                      type='text'
                      validate={zipLength}
                      component={FieldBSRenderText}
                      maxLength={5}
                    />
                  </div>
                  <div className='d-flex justify-content-around mt-3'>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        setLawFirmToEdit(null);
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
