import React from 'react';
import { Field } from 'react-final-form';
import { EdiBenefitTypeCode } from '../../ApiTypes/EdiBenefitTypeCode';
import { EdiMaintTypeCode } from '../../ApiTypes/EdiMaintTypeCode';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { useAppSelector } from '../../Reducers/Store';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import styles from './index.module.css';

export default function WelcomePage({
  values,
  disabledFields,
  requiredFiles,
  handleMaintTypeChange,
  getEdiFiles,
}: {
  values: EdiTransaction;
  disabledFields: string[];
  requiredFiles: { [key: string]: boolean }[];
  handleMaintTypeChange: (type: string, form: string) => void;
  getEdiFiles: (formType: string, maintCode: string) => void;
}) {
  const { ediBenefitTypeCodes, FROIEdiMaintTypeCodes, SROIEdiMaintTypeCodes } =
    useAppSelector((state) => state.reference);

  const getBenefitCodes = (values: EdiTransaction) => {
    let maintTypeCode = values?.maintTypeCode;

    if (values.form === 'SROI') {
      if (maintTypeCode === 'EP' || maintTypeCode === 'ER') {
        return ediBenefitTypeCodes.filter(
          (t) => t.code === '250' || t.code === '070'
        );
      }
      if (maintTypeCode === 'IP') {
        return ediBenefitTypeCodes.filter((t) => t.code !== '250');
      }
    }

    return ediBenefitTypeCodes;
  };
  return (
    <div className={`${styles.welcomeGrid}`}>
      <div>
        <Field
          name='claimno'
          label='SBWC Claim #'
          placeholder='No Claim Number Assigned Yet'
          type='text'
          validate={requiredField}
          component={FieldBSRenderText}
        />
        <Field
          name='form'
          label='Form to Submit'
          options={['FROI', 'SROI']}
          validate={requiredField}
          onChange={(form: string) => {
            if (values.maintTypeCode) {
              getEdiFiles(form, values.maintTypeCode);
            }
          }}
          component={FieldBSRenderSelect}
        />
        <Field
          name='disabilitydate'
          type='text'
          label='Disability Date'
          parse={parseDatesForServer}
          component={FieldBSRenderDate}
        />
        <Field
          name='maintTypeCode'
          label='Maint Code'
          options={
            values.form === 'SROI'
              ? SROIEdiMaintTypeCodes
              : FROIEdiMaintTypeCodes
          }
          optionMethod={(options: EdiMaintTypeCode[]) =>
            options.map((o) => (
              <option key={o.code} value={o.code}>
                {o.shortdesc}
              </option>
            ))
          }
          onChange={(type: string) => {
            handleMaintTypeChange(type, values.form);
          }}
          validate={requiredField}
          component={FieldBSRenderSelect}
        />
        <Field
          name='benefitTypeCode'
          label='Benefit Type Code'
          options={getBenefitCodes(values)}
          optionMethod={(options: EdiBenefitTypeCode[]) =>
            options.map((o) => (
              <option key={o.code} value={o.code!}>
                {o.shortdesc}
              </option>
            ))
          }
          disabled={disabledFields.includes('benefitTypeCode')}
          component={FieldBSRenderSelect}
        />
      </div>
      <div className='align-items-stretch'>
        <fieldset className='h-100'>
          <legend>Required Files</legend>
          {requiredFiles.map((x) => {
            var [key, value] = Object.entries(x)[0];
            return (
              <div className='form-check'>
                <label className='form-check-label'>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    checked={value}
                    readOnly
                  />
                  {key}
                </label>
              </div>
            );
          })}
        </fieldset>
      </div>
    </div>
  );
}
