import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { clearSelectedClaim, clearState } from '../../Actions';
import { fetchFullClaimByClaimNumber } from '../../Actions/ClaimActions';
import {
  fetchAdjusters,
  fetchAttorneyTypes,
  fetchBodyParts,
  fetchCaseManagementResolutionTypes,
  fetchCauses,
  fetchClaimNoteTypes,
  fetchClaimStatuses,
  fetchCounties,
  fetchDispositionTypes,
  fetchDocStatuses,
  fetchDocTypes,
  fetchEdiBenefitTypeCodes,
  fetchEdiCodes,
  fetchEdiMaintTypeCodes,
  fetchEdiPartOfBodyCodes,
  fetchInsuranceCompanies,
  fetchJobClasses,
  fetchLawFirms,
  fetchMedicares,
  fetchNurseCaseManagers,
  fetchPayCodes,
  fetchPayCodesIsExcess,
  fetchResults,
  fetchSettlementTypes,
  fetchShifts,
  fetchStates,
  fetchSupervisors,
  fetchWorkStatusBodyParts,
  fetchWorkStatusTypes,
  fetchInsuranceCompaniesForUser,
  getPbms,
  getDocPriorities,
  getDocTypesFullScanner,
  fetchClaimsAssistants,
  fetchPropertyTypes,
  fetchClaimType,
  fetchIcdCodes,
  fetchPlanInsuranceTypes,
  fetchInsuranceTypes,
} from '../../Actions/ReferenceActions';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import AppLayout from '../AppLayout/AppLayout';
import ClaimDetailTopCustom from '../ClaimDetailTopCustom/ClaimDetailTopCustom';
import ErrorBoundary from '../Common/ErrorBoundary';
import Loading from '../Common/Loading';
import OffCanvasCalendar from '../OffCanvasCalendar/OffCanvasCalendar';
import TabBarCustom from '../TabBarCustom/TabBarCustom';
import {
  fetchClaimTypeConfigurations,
  fetchClaimSubTypes,
} from '../../Actions/ClaimTypeConfigurationActions';
import { fetchAllClaimCustomOptions } from '../../Actions/ClaimCustomOptionActions';

export default function BaseClaim({ claimTypeId }: { claimTypeId: number }) {
  const dispatch = useAppDispatch();
  const { claimNumber } = useParams();
  const [loading, setLoading] = useState<boolean>(true);

  const [showClaimantForm, setShowClaimantForm] = useState<boolean>(false);
  const [showEmployerSelect, setShowEmployerSelect] = useState<boolean>(false);
  const [claimType, setClaimType] = useState<number>(claimTypeId);

  const { adjusters, bodyParts, counties, propertyTypes, claimTypes } =
    useAppSelector((state) => state.reference);
  const { claim } = useAppSelector((state) => state.currentClaimReducer);

  useEffect(() => {
    getReferences();
    return () => {
      dispatch(clearState);
    };
  }, []);
  useEffect(() => {
    handleClaimNumber();
    return () => {
      dispatch(clearSelectedClaim);
    };
  }, [claimNumber]);

  const getReferences = async () => {
    if (
      !adjusters.length ||
      !bodyParts.length ||
      !counties.length ||
      !propertyTypes.length ||
      !claimTypes.length
    ) {
      await Promise.all([
        dispatch(fetchPropertyTypes()),
        dispatch(fetchAdjusters()),
        dispatch(fetchBodyParts()),
        dispatch(fetchCounties()),
        dispatch(fetchCauses()),
        dispatch(fetchResults()),
        dispatch(fetchJobClasses()),
        dispatch(fetchStates()),
        dispatch(fetchClaimStatuses()),
        dispatch(fetchMedicares()),
        dispatch(fetchShifts()),
        dispatch(fetchSupervisors()),
        dispatch(fetchInsuranceCompanies()),
        dispatch(fetchClaimNoteTypes()),
        dispatch(fetchPayCodesIsExcess()),
        dispatch(fetchPayCodes()),
        dispatch(fetchNurseCaseManagers()),
        dispatch(fetchCaseManagementResolutionTypes()),
        dispatch(fetchAttorneyTypes()),
        dispatch(fetchLawFirms()),
        dispatch(fetchSettlementTypes()),
        dispatch(fetchDispositionTypes()),
        dispatch(fetchDocTypes()),
        dispatch(fetchDocStatuses()),
        dispatch(fetchEdiCodes()),
        dispatch(fetchEdiBenefitTypeCodes()),
        dispatch(fetchEdiMaintTypeCodes()),
        dispatch(fetchEdiPartOfBodyCodes()),
        dispatch(fetchWorkStatusTypes()),
        dispatch(fetchWorkStatusBodyParts()),
        dispatch(fetchInsuranceCompaniesForUser()),
        dispatch(getPbms()),
        dispatch(getDocPriorities()),
        dispatch(getDocTypesFullScanner()),
        dispatch(fetchClaimsAssistants()),
        dispatch(fetchClaimType()),
        dispatch(fetchAllClaimCustomOptions()),
        dispatch(fetchIcdCodes()),
        dispatch(fetchPlanInsuranceTypes()),
        dispatch(fetchInsuranceTypes()),
      ]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleClaimNumber = () => {
    dispatch(fetchClaimSubTypes(claimTypeId));
    if (claimNumber) {
      dispatch(fetchFullClaimByClaimNumber(claimNumber));
      setClaimType(claimTypeId);
    } else {
      dispatch(fetchClaimTypeConfigurations(claimTypeId));
      dispatch(clearSelectedClaim());
    }
  };

  return (
    <AppLayout>
      <>{loading && <Loading />}</>
      <Container fluid>
        <ErrorBoundary>
          <ClaimDetailTopCustom
            showClaimantForm={showClaimantForm}
            showEmployerSelect={showEmployerSelect}
            setShowClaimantForm={setShowClaimantForm}
            setShowEmployerSelect={setShowEmployerSelect}
            claimType={claimType}
          />
          <hr />
          <div className='d-flex w-100'>
            <TabBarCustom
              showClaimantForm={showClaimantForm}
              showEmployerSelect={showEmployerSelect}
              setShowClaimantForm={setShowClaimantForm}
              setShowEmployerSelect={setShowEmployerSelect}
              claimType={claimType}
            />
            <OffCanvasCalendar />
          </div>
        </ErrorBoundary>
      </Container>
    </AppLayout>
  );
}
