import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LegalApi from '../../../Api/LegalApi';
import { Settlement } from '../../../ApiTypes/Settlement';
import { useAppDispatch } from '../../../Reducers/Store';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import SettlementTable from './SettlementTable';
import SettlementAddEdit from './SettlementAddEdit';

export default function SettlementTab() {
  let { claimNumber } = useParams();
  const dispatch = useAppDispatch();

  const [settlements, setSettlements] = useState<Settlement[]>([])
  const [settlementToDelete, setSettlementToDelete] = useState<Settlement | null>(null)
  const [showDelete, setShowDelete] = useState<boolean>(false)

  const [showSettlementModal, setShowSettlementModal] = useState<boolean>(false);
  const [selectedSettlementId, setSelectedSettlementId] = useState<string | "">("");

  useEffect(() => {
    getSettlements()
  }, [claimNumber])


  const getSettlements = () => {
    if (claimNumber) {
      LegalApi.getSettlementsForClaim(claimNumber)
        .then(res => {
          setSettlements(res.data)
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      setSettlements([])
    }
  }

  const handleSettlementToDelete = (s: Settlement) => {
    setSettlementToDelete(s)
    setShowDelete(true)
  }

  const handleDeleteRecord = () => {
    if (settlementToDelete && claimNumber) {
      return LegalApi.deleteSettlement(settlementToDelete.settlementId, claimNumber)
        .then(res => {
          if (res.data.success) {
            getSettlements()
            setSettlementToDelete(null)
            setShowDelete(false)
          } else {
            toast.error(res.data.message)
          }
        })
        .catch(err => {
          console.log(err)
          toast.error("Failed to delete record")
        })
    }
    return Promise.resolve();
  }

  const removeModal = () => {
    setShowSettlementModal(false);
    setSelectedSettlementId("");
  };

  return (
    <div className='w-100 h-100'>
      <div className='d-flex justify-content-center mb-3'>
        {/* <Link to="new"> */}
          <Button
            type="button"
            variant='primary'
            size="sm"
            onClick={() => {
              setSelectedSettlementId("");
              setShowSettlementModal(true);
            }}
            disabled={claimNumber === undefined}
            className="button-icon-text"
          >
            <FaPlusCircle /> Add New
          </Button>
        {/* </Link> */}
      </div>
      <SettlementTable
        settlements={settlements}
        setSettlementToDelete={handleSettlementToDelete}
        show={showSettlementModal}
        setShow={setShowSettlementModal}
        selectedSettlementId={selectedSettlementId}
        setSelectedSettlementId={setSelectedSettlementId}
      />

      <SettlementAddEdit 
        selectedSettlementId={selectedSettlementId}
        show={showSettlementModal}
        setShow={removeModal}
        getSettlements={getSettlements}
      />

      <DeleteConfirmationModal
        show={showDelete}
        setShow={setShowDelete}
        setNull={setSettlementToDelete}
        handleDelete={handleDeleteRecord}
      />
    </div>
  )
}
