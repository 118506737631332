import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ImportException } from '../ApiTypes/ImportException';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { UpdateBillImportExceptionRequest } from '../ApiTypes/UpdateBillImportExceptionRequest';

class BillImportApi {
  static getImportExceptions() {
    return axios.get<ImportException[]>(
      `${API_URL}/api/billimports/exceptions`,
      getAuthHeader()
    );
  }
  static updateException(request: UpdateBillImportExceptionRequest) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/billimports/exceptions`,
      request,
      getAuthHeader()
    );
  }
  static processException() {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/billimports/exceptions/process`,
      {},
      getAuthHeader()
    );
  }
}
export default BillImportApi;
