import React, { useState, useEffect } from 'react';
import { Alert, Container, Modal, Spinner } from 'react-bootstrap';
import { FaDollarSign } from 'react-icons/fa';
import { Form as RFFForm, Field } from 'react-final-form';
import { Button, Form } from 'react-bootstrap';
import { ClaimReserveBuckets } from '../../../ApiTypes/ClaimReserveBuckets';
import FieldBSRenderText from '../../Common/FieldBSRenderText';
import {
  cleanMoney,
  formatNumbers,
  makeMoney,
} from '../../../Utils/InputFormatters';
import {
  UpdateReservesFormType,
  UpdateReservesType,
} from './UpdateReservesType';
import { Claim } from '../../../ApiTypes/Claim';
import FieldBSRenderTextArea from '../../Common/FieldRenderTextArea';
import {
  centsValidation,
  composeValidators,
  requiredField,
} from '../../../Utils/FieldValidation';
import styles from './index.module.css';
import FieldBSRenderMoney from '../../Common/FieldBSRenderMoney';
import { toast } from 'react-toastify';

export default function ChangeReserveBucketModal({
  show,
  setShow,
  reserveBuckets,
  updateReserves,
  claim,
  userName,
}: {
  show: boolean;
  reserveBuckets: ClaimReserveBuckets[] | null;
  claim: Claim | null;
  userName: string;
  setShow: () => void;
  updateReserves: (values: UpdateReservesFormType) => Promise<void>;
}) {
  const [showIndemnityAlert, setShowIndemnityAlert] = useState<boolean>(false);
  const [showNetChangeAlert, setShowNetChangeAlert] = useState<boolean>(false);
  const [createdInitialValues, setCreatedInitialValues] =
    useState<UpdateReservesFormType | null>(null);

  useEffect(() => {
    setInitialValues();
  }, [reserveBuckets]);

  const setInitialValues = () => {
    if ((reserveBuckets?.length ?? 0) > 0) {
      const it = reserveBuckets?.reduce(
        (acc, curr) => {
          return { ...acc, [curr.description]: { ...curr, change: undefined } };
        },
        { reason: undefined } as {
          [key: string]: UpdateReservesType | undefined;
        }
      );
      setCreatedInitialValues(it!);
    }
  };

  const onSubmit = (values: UpdateReservesFormType) => {
    cleanUp();

    if (claim?.status?.toUpperCase() === 'C') {
      toast.error(
        'You cannot add/edit reserves on a Closed Claim. Please Re-Open this Claim, or Open a new Claim.'
      );
      return;
    }

    let netChange = 0;
    let indChange = 0;

    const copy = { ...values };

    const { Medical, Indemnity, Expense, Legal, reason } = copy;

    indChange = Indemnity?.change ?? 0;
    netChange =
      +(Medical?.change ?? 0) +
      +(Indemnity?.change ?? 0) +
      +(Expense?.change ?? 0) +
      +(Legal?.change ?? 0);

    if (
      claim?.severity !== 'LT' &&
      claim?.severity !== 'AM' &&
      indChange !== 0
    ) {
      setShowIndemnityAlert(true);
      return;
    }

    if (userName !== 'CEDWARDS') {
      // not admin
      if (netChange > 0 && claim?.insurcoid !== 6) {
        setShowNetChangeAlert(true);
        return;
      }
    }

    return updateReserves(copy)
      .then((res) => {
        cleanUp();
      })
      .catch((err) => {
        cleanUp();
      });
  };

  const cleanUp = () => {
    setShowIndemnityAlert(false);
    setShowNetChangeAlert(false);
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow();
        cleanUp();
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='Change-Reserves-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Change-Reserves-modal'>
          <FaDollarSign className='pe-1' /> Change Reserves
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={createdInitialValues ?? {}}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className={`${styles.grid5Col}`}>
                    <Field
                      name='Medical.description'
                      label='Classification'
                      type='text'
                      readOnly
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='Medical.paid'
                      label='Paid'
                      type='text'
                      readOnly
                      format={makeMoney}
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='Medical.reserves'
                      label='Reserves'
                      type='text'
                      readOnly
                      format={makeMoney}
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='Medical.totalIncurred'
                      label='Total Incurred'
                      type='text'
                      readOnly
                      format={makeMoney}
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='Medical.change'
                      label='Change'
                      type='text'
                      format={formatNumbers}
                      parse={cleanMoney}
                      validate={composeValidators(centsValidation)}
                      component={FieldBSRenderMoney}
                    />
                    <Field
                      name='Indemnity.description'
                      label='Classification'
                      type='text'
                      readOnly
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='Indemnity.paid'
                      label='Paid'
                      type='text'
                      readOnly
                      format={makeMoney}
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='Indemnity.reserves'
                      label='Reserves'
                      type='text'
                      readOnly
                      format={makeMoney}
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='Indemnity.totalIncurred'
                      label='Total Incurred'
                      type='text'
                      readOnly
                      format={makeMoney}
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='Indemnity.change'
                      label='Change'
                      type='text'
                      format={formatNumbers}
                      parse={cleanMoney}
                      validate={composeValidators(centsValidation)}
                      component={FieldBSRenderMoney}
                    />
                    <Field
                      name='Expense.description'
                      label='Classification'
                      type='text'
                      readOnly
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='Expense.paid'
                      label='Paid'
                      type='text'
                      readOnly
                      format={makeMoney}
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='Expense.reserves'
                      label='Reserves'
                      type='text'
                      readOnly
                      format={makeMoney}
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='Expense.totalIncurred'
                      label='Total Incurred'
                      type='text'
                      readOnly
                      format={makeMoney}
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='Expense.change'
                      label='Change'
                      type='text'
                      format={formatNumbers}
                      parse={cleanMoney}
                      validate={composeValidators(centsValidation)}
                      component={FieldBSRenderMoney}
                    />
                    <Field
                      name='Legal.description'
                      label='Classification'
                      type='text'
                      readOnly
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='Legal.paid'
                      label='Paid'
                      type='text'
                      readOnly
                      format={makeMoney}
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='Legal.reserves'
                      label='Reserves'
                      type='text'
                      readOnly
                      format={makeMoney}
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='Legal.totalIncurred'
                      label='Total Incurred'
                      type='text'
                      readOnly
                      format={makeMoney}
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='Legal.change'
                      label='Change'
                      type='text'
                      format={formatNumbers}
                      parse={cleanMoney}
                      validate={composeValidators(centsValidation)}
                      component={FieldBSRenderMoney}
                    />
                  </div>
                  <Field
                    name='reason'
                    label='Reserve Update Reason'
                    rows={3}
                    validate={requiredField}
                    component={FieldBSRenderTextArea}
                  />
                  {showIndemnityAlert && (
                    <Alert variant='danger'>
                      <p>
                        You cannot add/edit Indemnity Reserves on a Claim that
                        is not Lost Time or Advanced Medical.
                      </p>
                      <p> Please correct this and try again.</p>
                    </Alert>
                  )}
                  {showNetChangeAlert && (
                    <Alert variant='danger'>
                      <p>
                        You cannot add/edit reserves without using the Reserve
                        Worksheet unless you are simply moving reserves from one
                        bucket to another or simply decreasing them.
                      </p>
                      <p> Please see Carla for help.</p>
                    </Alert>
                  )}
                  <div className='d-flex justify-content-center'>
                    <Button type='submit' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Update'
                      )}
                    </Button>
                    <Button
                      type='button'
                      onClick={() => {
                        form.reset();
                        cleanUp();
                        setShow();
                      }}
                      className='ms-2'
                      variant='secondary'
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
