import React, { useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ChartTypeRegistry,
} from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import styles from './index.module.css';

type chartObject = {
  adjuster: string;
  docCount: number;
};

export default function ManagerBarChart({
  data,
  getAdjusterFromChart,
}: {
  data: chartObject[];
  getAdjusterFromChart: (adjusterName: string) => void;
}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    BarElement,
    ChartDataLabels
  );

  const chartRef = useRef();
  const onClick = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const hit = getElementAtEvent(
      chartRef.current as unknown as ChartJS<keyof ChartTypeRegistry>,
      event
    );
    if (hit.length) {
      const adjuster = data[hit[0].index].adjuster;
      if (adjuster) {
        getAdjusterFromChart(adjuster);
      }
    }
  };

  const getOptions = (): any => ({
    interaction: {
      mode: 'nearest',
      intersect: false,
    },
    maintainAspectRatio: false,

    stacked: false,
    plugins: {
      datalabels: {
        color: '#8E999F',
        anchor: 'end',
        align: 'start',
        font: {
          weight: 'bold',
          size: 20,
        },
      },
      tooltip: {
        callbacks: {},
      },
    },

    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        ticks: {
          beginAtZero: false,
        },
      },
    },
  });

  const getData = (data: chartObject[]): any => {
    return {
      labels: data.map((x) => x.adjuster),
      datasets: [
        {
          label: 'Doc Count',
          data: data.map((d) => d.docCount),
          fill: false,
          type: 'bar',
          backgroundColor: '#0DCAF0',
          borderColor: '#3f95ff',
        },
      ],
    };
  };

  return (
    <div className='mb-3'>
      <div className={`${styles.chartArea}`}>
        {data ? (
          <Bar
            data={getData(data)}
            options={getOptions()}
            ref={chartRef}
            onClick={onClick}
          />
        ) : (
          'No Available Data'
        )}
      </div>
    </div>
  );
}
