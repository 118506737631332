import { addDays, format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { FaShare } from 'react-icons/fa';
import { ScanDocSimple } from '../../ApiTypes/ScanDocSimple';
import { UserEmailList } from '../../ApiTypes/UserEmailList';
import { useAppSelector } from '../../Reducers/Store';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import styles from './index.module.css';
import { SecureShareDocumentRequest } from '../../ApiTypes/SecureShareDocumentRequest';
import CreatableSelect from 'react-select/creatable';
import { SecureShare } from '../../ApiTypes/SecureShare';
import DocumentsApi from '../../Api/DocumentsApi';
import { DocumentType } from '../../ApiTypes/DocumentType';
import TableSpinner from '../Tabs/Documents/TableSpinner';

export default function SecureShareModal({
  show,
  setShow,
  claimNumber,
  docs,
  handleSecureShare,
  userEmailList,
  share,
  toEmailAddresses = [],
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  claimNumber: string;
  docs: ScanDocSimple[];
  userEmailList: UserEmailList[];
  share?: SecureShare | null;
  handleSecureShare: (values: SecureShareDocumentRequest) => Promise<void>;
  toEmailAddresses?: string[];
}) {
  const { userModel } = useAppSelector((state) => state.user);
  const [allDocs, setAllDocs] = useState<ScanDocSimple[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // useEffect(() => {
  //   createDocsForForm();
  // }, [docs, share]);

  const emailList = useMemo(() => {
    let tos: {
      label: string;
      value: string;
    }[] = [];

    if (share) {
      const addresses = share.sentToEmail.split(';');
      tos = addresses.map((x) => {
        return {
          label: x.trim(),
          value: x.trim(),
        };
      });
      console.log(tos);
    }

    const list = userEmailList.map((e) => ({
      label: e.emailAddress ?? '',
      value: e.emailAddress ?? '',
    }));

    return [...tos, ...list];
  }, [share, userEmailList]);
  const [docIdList, setDocIdList] = useState<number[]>([]);

  useEffect(() => {
    createDocsForForm();
    loadDocsList();
  }, [docs, share]);

  const loadDocsList = () => {
    var list: number[] = [];
    docs.forEach((doc) => {
      list.push(doc.docid);
    });
    setDocIdList(list);
  };
  const createDocsForForm = () => {
    if (share) {
      setAllDocs(share.secureShareFiles.map((s) => s.simpleDoc));
    } else {
      setAllDocs(docs);
    }
  };

  const getScanDocsForClaim = () => {
    if (claimNumber) {
      setLoading(true);
      DocumentsApi.getScanDocsForClaim(claimNumber, DocumentType.All)
        .then((res) => {
          res.data.sort((a, b) => {
            const aDate = new Date(a.date ?? '');
            const bDate = new Date(b.date ?? '');
            return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
          });
          setAllDocs(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onSubmit = (values: SecureShareDocumentRequest) => {
    return handleSecureShare(values);
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      aria-labelledby='Secure-Share-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Secure-Share-Form-modal'>
          <FaShare className='pe-1' /> Secure Share
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p>Claim: {claimNumber}</p>
          <RFFForm
            onSubmit={onSubmit}
            validate={(values: SecureShareDocumentRequest) => {
              const errors: {
                [Property in keyof SecureShareDocumentRequest]?: string;
              } = {};

              if (values?.emailAddresses.length === 0) {
                errors.emailAddresses = 'Required';
              }
              return errors;
            }}
            initialValues={
              share
                ? {
                    shareId: share.id,
                    expirationDate: share.expirationDate,
                    from: userModel?.user?.emailAddress ?? '',
                    claimNumber: share.claimNo,
                    docIds: docIdList,
                    emailAddresses: toEmailAddresses,
                  }
                : {
                    expirationDate: format(
                      addDays(new Date(), 3),
                      'yyyy-MM-dd'
                    ),
                    claimNumber,
                    docIds: docIdList,
                    from: userModel?.user?.emailAddress ?? '',
                    emailAddresses: toEmailAddresses,
                  }
            }
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field name='emailAddresses'>
                    {({ input, meta: { touched, error } }) => (
                      <div
                        className={`position-relative ${styles.vFieldHeight}`}
                      >
                        <label
                          htmlFor={input.name}
                          className='form-label fs-6  m-0'
                        >
                          Email Addresses
                        </label>
                        <CreatableSelect
                          name={input.name}
                          placeholder='Send To'
                          isClearable
                          isMulti
                          defaultValue={emailList.slice(
                            0,
                            share?.sentToEmail.split(';').length ?? 0
                          )}
                          onChange={(e) => {
                            if (e) {
                              input.onChange(e.map((m) => m.value));
                            } else {
                              input.onChange(null);
                            }
                          }}
                          styles={{
                            control: (baseStyles: any, state: any) => ({
                              ...baseStyles,
                              borderColor: touched && error && 'red',
                            }),
                          }}
                          options={emailList}
                        />

                        {touched && error && (
                          <span className={`${styles.fieldError} text-danger`}>
                            {error}
                          </span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field
                    name='expirationDate'
                    label='Expiration Date'
                    parse={parseDatesForServer}
                    validate={requiredField}
                    component={FieldBSRenderDate}
                  />

                  <div className='form-check'>
                    <label className='form-check-label'>
                      <input
                        type='checkbox'
                        name='showAllDocs'
                        className='form-check-input'
                        onChange={(e) => {
                          const { value: eventValue, checked } = e.target;
                          if (checked) {
                            getScanDocsForClaim();
                          } else {
                            createDocsForForm();
                          }
                        }}
                      />
                      Show all Docs{' '}
                      {loading && <Spinner size='sm' animation='grow' />}
                    </label>
                  </div>

                  <fieldset className={styles.secureShareDocumentSelect}>
                    <legend>Documents</legend>
                    {allDocs.map((d) => (
                      <Field
                        key={`${d.docid}`}
                        name='docIds'
                        label={d.nameAndDatesText}
                        type='checkbox'
                        value={d.docid}
                        checked={values.docIds.includes(d.docid)}
                        component={FieldBSRenderCheckbox}
                      />
                    ))}
                  </fieldset>
                  <div className={`${styles.buttonDiv} mt-3`}>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
