import React from 'react';
import { Modal, Container, Form, Button, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaEdit } from 'react-icons/fa';
import { GemcLossRunEmail } from '../../ApiTypes/GemcLossRunEmail';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderText from '../Common/FieldBSRenderText';

export default function EditEmailModal({
  show,
  setShow,
  setSelectedEmail,
  submitFunction,
  selectedEmail,
}: {
  show: boolean;
  setShow: (s: boolean) => void;
  selectedEmail: GemcLossRunEmail | null;
  setSelectedEmail: (e: GemcLossRunEmail | null) => void;
  submitFunction: (values: GemcLossRunEmail) => Promise<void>;
}) {
  const onSubmit = (values: GemcLossRunEmail) => {
    return submitFunction(values);
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
        setSelectedEmail(null);
      }}
      aria-labelledby='Loss-Run-Email-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Loss-Run-Email-modal'>
          <FaEdit className='pe-1' /> Edit GEMC Loss Run Email
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={selectedEmail ?? {}}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='emailaddress'
                    label='Email Address'
                    type='email'
                    component={FieldBSRenderText}
                    validate={requiredField}
                  />
                  <Field
                    name='employerName'
                    label='Employer Name'
                    type='text'
                    component={FieldBSRenderText}
                    readOnly
                  />
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex  align-items-center gap1Rem'>
                      <Field
                        name='employerid'
                        label='Employer Id'
                        type='text'
                        component={FieldBSRenderText}
                        validate={requiredField}
                      />
                      <Field
                        name='active'
                        label='Active'
                        type='checkbox'
                        checked={values?.active?.toString() === 'true'}
                        component={FieldBSRenderCheckbox}
                      />
                    </div>
                    <div className='d-flex  align-items-center gap1Rem'>
                      <Button type='submit' variant='primary' size='sm'>
                        {submitting ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          'Save'
                        )}
                      </Button>
                      <Button
                        type='button'
                        variant='secondary'
                        size='sm'
                        onClick={() => {
                          form.reset();
                          setShow(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
