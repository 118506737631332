import React, { useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { FaBan, FaEye, FaRegCheckSquare } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { displayDateOnly } from '../../Utils';
import { PaymentApprovalGridModel } from '../../ApiTypes/PaymentApprovalGridModel';
import IndeterminateCheckbox from '../Common/IndeterminateCheckbox';
import { Button } from 'react-bootstrap';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import accounting from 'accounting';
import { toast } from 'react-toastify';
import PaymentsApi from '../../Api/PaymentsApi';

export default function ApprovePaymentsTable({
  data,
  getTransactions,
  insuranceCompany,
}: {
  getTransactions: () => void;
  data: PaymentApprovalGridModel[];
  insuranceCompany: InsuranceCompany | null;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const [rowSelection, setRowSelection] = React.useState({});
  const [selectedPayments, setSelectedPayments] = useState<
    PaymentApprovalGridModel[]
  >([]);

  useEffect(() => {
    setRowSelection({});
    setColumnFilters([]);
  }, [data]);
  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  const handleRowSelectionChange = () => {
    setSelectedPayments(
      table.getSelectedRowModel().flatRows.map((r) => r.original)
    );
  };

  const handleCheckTransactions = (row: PaymentApprovalGridModel) => {
    if (row.status?.toUpperCase() === 'C') {
      var payments = data
        .filter(
          (x) =>
            row.claimNo === x.claimNo &&
            (new Date(x.approvalDate ?? '').getTime() >
              new Date(x.statusEffectiveDate ?? '').getTime() ||
              x.approvalDate === null)
        )
        .reduce((acc, curr) => (acc += curr.txAmount ?? 0), 0);
      if (payments > 2500) {
        return true;
      }
      return false;
    }
    if (row.approvalState === 'Reserves' && row.status?.toUpperCase() !== 'C') {
      return true;
    }
    return false;
  };

  const tableData = useMemo(() => data, [data]);

  const approveTransactions = () => {
    if (selectedPayments.length === 0) {
      toast.info('Please select the payments that you would like to approve');
      return;
    }

    const promises = selectedPayments.map((s) => {
      return approve(s.txId);
    });
    Promise.allSettled(promises)
      .then((res) => {
        res.forEach((r) => {
          if (r.status === 'rejected') {
            toast.error(r.reason);
          } else {
            if (!r.value.data.success) {
              toast.error(r.value.data.message);
            }
          }
        });
        getTransactions();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const approve = (txId: number) => {
    return PaymentsApi.approvePayments(txId);
  };

  const getBackGroundColor = (r: PaymentApprovalGridModel) => {
    if (r.approvalState === 'Reserves') {
      // r is readonly
      if (r.status?.toUpperCase() === 'C') {
        return 'salmon'; // -> readOnly
      } else {
        // r is not readonly
        return 'khaki';
      }
    } else if ((r.txAmount ?? 0) > 1000) {
      return 'orange';
    } else {
      // readonly false
    }
  };

  const removeApproval = (payment: PaymentApprovalGridModel) => {
    toast('Removing Approval', { type: 'info', toastId: payment.txId });
    return PaymentsApi.unApprovePayment(payment.txId)
      .then((res) => {
        if (res.data.success) {
          toast.update(payment.txId, {
            type: 'success',
            render: 'Success',
          });
          getTransactions();
        } else {
          toast.update(payment.txId, {
            type: 'error',
            render: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.update(payment.txId, {
          type: 'error',
          render: 'Failed to un-approve payment!',
        });
      });
  };

  const columnData: ColumnDef<PaymentApprovalGridModel>[] = [
    {
      header: ({ table }) => (
        <div className='px-3 d-flex gap1Rem'>
          {Object.keys(rowSelection).length}
          {/* <IndeterminateCheckbox
            className='form-check-input'
            {...{
              id: 'paymentApprovalSelectionTotal',
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          /> */}
        </div>
      ),
      id: 'options',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => {
        return (
          <div
            className={`d-flex gap1Rem align-items-center ${getBackGroundColor(
              row.original
            )}`}
          >
            {row.original.pmtApproved === true ? (
              <Button
                type='button'
                variant='outline-success'
                size='sm'
                title='Remove Approval'
                onClick={() => removeApproval(row.original)}
              >
                <FaRegCheckSquare />
              </Button>
            ) : (
              <IndeterminateCheckbox
                className='form-check-input'
                {...{
                  id: row.id,
                  checked: row.getIsSelected(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                  disabled: handleCheckTransactions(row.original),
                }}
              />
            )}

            <Link to={`/claims/${row.original.claimNo}/reserves`}>
              <Button
                type='button'
                variant='outline-primary'
                size='sm'
                className='button-icon-text'
              >
                <FaEye /> Claim
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      header: 'Claim Number',
      accessorKey: 'claimNo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date',
      accessorKey: 'txEnterDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.txEnterDate ?? ''),
    },
    {
      header: 'Status',
      accessorKey: 'status',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 100,
    },
    {
      header: 'Adjuster',
      accessorKey: 'userId',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'SVC From',
      accessorKey: 'serviceFrom',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.serviceFrom ?? ''),
    },
    {
      header: 'SVC To',
      accessorKey: 'serviceTo',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.serviceTo ?? ''),
    },
    {
      header: 'Payee',
      accessorKey: 'payee',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Payable To',
      accessorKey: 'checkPayableTo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Tax Id',
      accessorKey: 'taxId',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Amount',
      accessorFn: (d) => `${d.txAmount}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: ({ row }) => accounting.formatMoney(row.original.txAmount ?? 0),
    },
    {
      header: 'Pay Code',
      accessorKey: 'payCode',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Financial Bucket',
      accessorKey: 'fBucket',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Approved',
      accessorFn: (d) => (d.pmtApproved === true ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Approved By',
      accessorKey: 'pmtApprovedBy',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Source',
      accessorKey: 'source',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Reserves',
      accessorFn: (d) => `${d.reserves}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: ({ row }) => accounting.formatMoney(row.original.reserves ?? 0),
    },
    {
      header: 'State',
      accessorKey: 'approvalState',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, [rowSelection]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <>
      <div className='d-flex justify-content-center align-items-center gap1Rem mt-3 pb-3'>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={() => {
            setRowSelection({});
          }}
        >
          Uncheck All
        </Button>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={() => {
            table.getRowModel().rows.forEach((r) => {
              if (
                r.original.payee === 'Provider' &&
                r.original.pmtApproved !== true
              ) {
                if (
                  r.original.approvalState === 'Reserves' &&
                  r.original.status?.toUpperCase() !== 'C'
                ) {
                  // r.toggleSelected(false);
                } else if (handleCheckTransactions(r.original) === true) {
                } else {
                  r.toggleSelected(true);
                }
              }
            });
          }}
        >
          Check All Provider
        </Button>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={() => {
            table.getRowModel().rows.forEach((r) => {
              if (
                r.original.payee === 'Claimant' &&
                r.original.pmtApproved !== true
              ) {
                r.toggleSelected(true);
              }
            });
          }}
        >
          Check All Claimant
        </Button>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={() => {
            approveTransactions();
          }}
        >
          Save
        </Button>
      </div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
      />
    </>
  );
}
