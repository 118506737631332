import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ExpandedState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { ClaimAuthorizedPhysician } from '../../../ApiTypes/ClaimAuthorizedPhysician';
import { ClaimPhysicianInfo } from '../../../ApiTypes/ClaimPhysicianInfo';
import { Physician } from '../../../ApiTypes/Physician';
import { Button } from 'react-bootstrap';
import {
  FaCaretDown,
  FaCaretRight,
  FaCircle,
  FaEdit,
  FaEye,
  FaPlusCircle,
  FaTrash,
} from 'react-icons/fa';
import { displayDateOnly } from '../../../Utils';
import { PhysicianTypes } from '../../../ApiTypes/PhysicianTypes';
import ClaimAuthorizedPhysicianBodyPartTable from './ClaimAuthorizedPhysicianBodyPartTable';
import ViewAddPhysician from '../../ViewAddPhysician/ViewAddPhysician';
import PhysicianApi from '../../../Api/PhysicianApi';
import { toast } from 'react-toastify';
import AddUpdateClaimAuthorizedPhysician from '../../AddUpdateClaimAuhtorizedPhysicians/AddUpdateClaimAuthorizedPhysician';
import { ClaimPhysicianLog } from '../../../ApiTypes/ClaimPhysicianLog';
import PhysiciansLogTable from './PhysiciansLogTable';

export default function NewPhysicianInfo({
  claimNumber,
  allPhysicians,
  claimAuthorizedPhysicians,
  physicianInfo,
  getClaimAuthorizedPhysicians,
  getAllPhysicians,
  getPhysicianInfo,
  physicianLogs,
}: {
  claimNumber: string;
  allPhysicians: Physician[];
  claimAuthorizedPhysicians: ClaimAuthorizedPhysician[];
  physicianInfo: ClaimPhysicianInfo | null;
  physicianLogs: ClaimPhysicianLog[];
  getClaimAuthorizedPhysicians: () => void;
  getAllPhysicians: () => Promise<void>;
  getPhysicianInfo: () => void;
}) {
  const [showViewAdd, setShowViewAdd] = useState<boolean>(false);
  const [physicianId, setPhysicianId] = useState<number>(0);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [
    selectedClaimAuthorizedPhysician,
    setSelectedClaimAuthorizedPhysician,
  ] = useState<ClaimAuthorizedPhysician | null>(null);
  const [showAddUpdateClaimPhysician, setShowAddUpdateClaimPhysician] =
    useState<boolean>(false);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const tableData = useMemo(
    () => claimAuthorizedPhysicians,
    [claimAuthorizedPhysicians]
  );

  const handleViewAdd = (physicianId: number) => {
    setPhysicianId(physicianId);
    setShowViewAdd(true);
  };

  const submitAddView = (values: Physician) => {
    if (values.id > 0) {
      return updatePhysician(values);
    } else {
      return createPhysician(values);
    }
  };

  const createPhysician = (values: Physician) => {
    return PhysicianApi.createPhysician(values)
      .then((res) => {
        if (res.data.success) {
          setPhysicianId(0);
          setShowViewAdd(false);
          getClaimAuthorizedPhysicians();
          getAllPhysicians();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create new physician');
      });
  };
  const updatePhysician = (values: Physician) => {
    return PhysicianApi.updatePhysician(values)
      .then((res) => {
        if (res.data.success) {
          setPhysicianId(0);
          setShowViewAdd(false);
          getClaimAuthorizedPhysicians();
          getAllPhysicians();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update new physician');
      });
  };

  const removeClaimAuthorizedPhysician = (id: number) => {
    return PhysicianApi.removeClaimAuthorizedPhysician(claimNumber, id)
      .then((res) => {
        if (res.data.success) {
          getClaimAuthorizedPhysicians();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to remove claim authorized physician');
      });
  };

  const columnData: ColumnDef<ClaimAuthorizedPhysician>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 170,
      cell: (d) => {
        return (
          <div
            className={`d-flex justify-content-around ${
              d.row.original.id == selectedClaimAuthorizedPhysician?.id
                ? 'orange'
                : ''
            }`}
          >
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='View/Edit Authorized Physician'
              onClick={() => {
                setSelectedClaimAuthorizedPhysician(d.row.original);
                setShowAddUpdateClaimPhysician(true);
              }}
            >
              <FaEdit />
            </Button>
            <Button
              type='button'
              size='sm'
              variant='danger'
              title='Delete'
              onClick={() => {
                removeClaimAuthorizedPhysician(d.row.original.id);
              }}
            >
              <FaTrash />
            </Button>
            <Button
              type='button'
              size='sm'
              variant='outline-primary'
              title='View Physician Info'
              onClick={() => {
                setSelectedClaimAuthorizedPhysician(d.row.original);
                handleViewAdd(d.row.original.physicianId ?? 0);
              }}
            >
              <FaEye />
            </Button>
          </div>
        );
      },
    },
    {
      header: '',
      id: 'expander',
      size: 30,
      maxSize: 30,
      cell: ({ row }) => (
        <div>
          {row.getCanExpand() ? (
            <Button
              size='sm'
              variant='outline-primary'
              title='Expand'
              onClick={row.getToggleExpandedHandler()}
            >
              {row.getIsExpanded() ? <FaCaretDown /> : <FaCaretRight />}
            </Button>
          ) : (
            <FaCircle />
          )}
        </div>
      ),
    },
    {
      header: 'Name',
      accessorFn: (d) => d.physician?.displayName,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Address',
      accessorFn: (d) => d.address,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Phone',
      accessorFn: (d) => d.physician?.phoneNumber,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date',
      accessorKey: 'dateAdded',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Type',
      accessorFn: (d) => PhysicianTypes[d.physicianTypeId ?? 0],
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Body Part',
      accessorFn: (d) =>
        d.claimAuthorizedPhysicianBodyParts
          ?.map((b) => b.bodyPartName)
          .join(', '),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Authorized?',
      accessorFn: (d) => (d.authorized === true ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 100,
    },
  ];

  const columns = useMemo(() => columnData, [selectedClaimAuthorizedPhysician]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      expanded,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
    getRowCanExpand: (row) =>
      row.original.claimAuthorizedPhysicianBodyParts !== null &&
      row.original.claimAuthorizedPhysicianBodyParts.length > 0,
  });

  return (
    <div>
      <div className='d-flex justify-content-center align-items-center mb-3'>
        <Button
          type='button'
          variant='primary'
          size='sm'
          onClick={() => {
            setSelectedClaimAuthorizedPhysician(null);
            setShowAddUpdateClaimPhysician(true);
          }}
          className='button-icon-text'
        >
          <FaPlusCircle /> Add New
        </Button>
      </div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        createChildTable={ClaimAuthorizedPhysicianBodyPartTable}
        subRowProp={'claimAuthorizedPhysicianBodyParts'}
        selectableRow={true}
        highlightRow={true}
      />
      {physicianLogs.length > 0 && (
        <PhysiciansLogTable physicianLogs={physicianLogs} />
      )}
      <ViewAddPhysician
        show={showViewAdd}
        setShow={setShowViewAdd}
        physicianId={physicianId}
        setPhysicianId={setPhysicianId}
        submitAddView={submitAddView}
      />
      <AddUpdateClaimAuthorizedPhysician
        show={showAddUpdateClaimPhysician}
        setShow={setShowAddUpdateClaimPhysician}
        claimPhysician={selectedClaimAuthorizedPhysician}
        setClaimPhysician={setSelectedClaimAuthorizedPhysician}
        claimNumber={claimNumber}
        allPhysicians={allPhysicians}
        getAllPhysicians={getAllPhysicians}
        getClaimAuthorizedPhysicians={getClaimAuthorizedPhysicians}
      />
    </div>
  );
}
