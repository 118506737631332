import React, { useState, useEffect } from 'react';
import { Form as RFForm, Field } from 'react-final-form';
import styles from './index.module.css';
import { FaLocationArrow, FaPlusCircle } from 'react-icons/fa';
import PageScaffold from '../PageScaffold/PageScaffold';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DoctorsOffice } from '../../ApiTypes/DoctorsOffice';
import DoctorOfficeApi from '../../Api/DoctorOfficeApi';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../Reducers/Store';
import { Button, Form, Spinner } from 'react-bootstrap';
import { State } from '../../ApiTypes/State';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { requiredField, zipLength } from '../../Utils/FieldValidation';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import { DoctorLocation } from '../../ApiTypes/DoctorLocation';
import DoctorLocationApi from '../../Api/DoctorLocationApi';
import queryString from 'query-string';
import PanelApi from '../../Api/PanelApi';

export default function LocationEdit() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { states } = useAppSelector((state) => state.reference);

  const [doctorLocation, setDoctorLocation] = useState<DoctorLocation | null>(
    null
  );
  const [allOffices, setAllOffices] = useState<DoctorsOffice[]>([]);

  const { panelId, doctorId } = queryString.parse(location.search);

  useEffect(() => {
    getDoctorLocation();
    getAllWithLocation();
  }, [id]);

  const getDoctorLocation = () => {
    if (id && id !== 'new') {
      DoctorLocationApi.getDoctorLocationById(+id)
        .then((res) => {
          setDoctorLocation(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get location');
        });
    }
  };

  const getAllWithLocation = () => {
    DoctorOfficeApi.getAllWithLocation()
      .then((res) => {
        setAllOffices(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get doctor offices');
      });
  };

  const onSubmit = (values: DoctorLocation) => {
    if (values.id) {
      return updateLocation(values);
    }
    return createLocation(values);
  };

  const updateLocation = (loc: DoctorLocation) => {
    const { panelId } = queryString.parse(location.search);
    return DoctorLocationApi.updateDoctorLocation(loc)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success location updated');
          if (panelId) {
            handleAdd(+panelId, loc.doctorId ?? 0);
          } else {
            navigate(-1);
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update location');
      });
  };
  const createLocation = (loc: DoctorLocation) => {
    const { panelId } = queryString.parse(location.search);
    return DoctorLocationApi.createDoctorLocation(loc)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success location created');
          if (panelId) {
            handleAdd(+panelId, loc.doctorId ?? 0);
          } else {
            navigate(-1);
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create location');
      });
  };

  const handleAdd = (panelId: number, doctorId: number) => {
    PanelApi.addOfficeToPanel(panelId, doctorId)
      .then((res) => {
        if (res.data.success) {
          navigate(`/panels/${panelId}`);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed add location to panel');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaLocationArrow className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Location</h1>
          </div>
          <div className='ms-3'></div>
        </div>
      </div>
      <RFForm
        onSubmit={onSubmit}
        initialValues={doctorLocation ?? { doctorId: doctorId ? +doctorId : 0 }}
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className={styles.flex2}>
                <Field
                  name='doctorId'
                  label='Office Name'
                  disabled={id && id !== 'new'}
                  options={allOffices}
                  optionMethod={(options: DoctorsOffice[]) =>
                    options.map((o) => (
                      <option key={o.id} value={o.id}>
                        {o.officeName}
                      </option>
                    ))
                  }
                  validate={requiredField}
                  component={FieldBSRenderSelect}
                />
                <div className='d-flex'>
                  {id === 'new' && (
                    <Link to={`/doctorsoffices/new?panelId=${panelId}`}>
                      <Button
                        type='button'
                        variant='outline-primary'
                        size='sm'
                        className='button-icon-text'
                      >
                        <FaPlusCircle /> New Office
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
              <Field
                name='address'
                type='text'
                label='Address'
                component={FieldBSRenderText}
              />
              <Field
                name='address2'
                type='text'
                label='Address 2'
                component={FieldBSRenderText}
              />
              <div className={`${styles.flex3}`}>
                <Field
                  name='city'
                  type='text'
                  label='City'
                  component={FieldBSRenderText}
                />
                <Field
                  name='state'
                  label='State'
                  options={states}
                  optionMethod={(options: State[]) =>
                    options.map((o) => (
                      <option key={o.stateAbbr} value={o.stateAbbr}>
                        {o.stateAbbr}
                      </option>
                    ))
                  }
                  component={FieldBSRenderSelect}
                />
                <Field
                  name='zip'
                  type='text'
                  label='Zip'
                  validate={zipLength}
                  component={FieldBSRenderText}
                  maxLength={5}
                />
              </div>
              <Field
                name='phone'
                type='text'
                label='Phone'
                component={FieldBSRenderText}
              />
              <Field
                name='hours'
                type='text'
                label='Hours'
                component={FieldBSRenderText}
              />
              <div className='d-flex justify-content-center align-items-center gap1Rem py-3'>
                <Button type='submit' variant='primary' size='sm'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    </PageScaffold>
  );
}
