import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaEnvelopeOpen } from 'react-icons/fa';
import DocumentsApi from '../../Api/DocumentsApi';
import { DocsNotesRequestStatus } from '../../ApiTypes/DocumentStatus';
import { ScanDocNotesRequest } from '../../ApiTypes/ScanDocNotesRequest';
import BSRenderText from '../Common/BSRenderText';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import NotesRequestTable from './NotesRequestTable';

export default function DocumentNotesRequest() {
  const [claimNumber, setClaimNumber] = useState<string>('');
  const [fetching, setFetching] = useState<boolean>(false);
  const [statusId, setStatusId] = useState<number>(0);
  const [notesRequests, setNotesRequests] = useState<ScanDocNotesRequest[]>([]);
  useEffect(() => {
    search();
  }, []);
  useEffect(() => {
    search();
  }, [statusId, claimNumber]);

  const search = () => {
    setFetching(true);
    DocumentsApi.getDocNotesRequests(statusId, claimNumber)
      .then((res) => {
        setNotesRequests(res.data);
        setFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setFetching(false);
      });
  };
  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaEnvelopeOpen className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Document Notes Requests</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center gap1Rem'>
        <BSRenderText
          name='claimNo'
          label='Claim Number'
          type='text'
          onChange={setClaimNumber}
          value={claimNumber}
        />
        <div className='form-check'>
          <label className='form-check-label'>
            <input
              type='radio'
              className='form-check-input'
              checked={statusId === DocsNotesRequestStatus.New}
              onChange={() => setStatusId(DocsNotesRequestStatus.New)}
            />
            New Only
          </label>
        </div>
        <div className='form-check'>
          <label className='form-check-label'>
            <input
              type='radio'
              className='form-check-input'
              checked={statusId === DocsNotesRequestStatus.First}
              onChange={() => setStatusId(DocsNotesRequestStatus.First)}
            />
            First
          </label>
        </div>
        <div className='form-check'>
          <label className='form-check-label'>
            <input
              type='radio'
              className='form-check-input'
              checked={statusId === DocsNotesRequestStatus.Second}
              onChange={() => setStatusId(DocsNotesRequestStatus.Second)}
            />
            Second
          </label>
        </div>
        <div className='form-check'>
          <label className='form-check-label'>
            <input
              type='radio'
              className='form-check-input'
              checked={statusId === DocsNotesRequestStatus.Closed}
              onChange={() => setStatusId(DocsNotesRequestStatus.Closed)}
            />
            Closed
          </label>
        </div>
        <div className='form-check'>
          <label className='form-check-label'>
            <input
              type='radio'
              className='form-check-input'
              checked={statusId === DocsNotesRequestStatus.NonResponsive}
              onChange={() => setStatusId(DocsNotesRequestStatus.NonResponsive)}
            />
            No Response
          </label>
        </div>

        <Button type='button' variant='primary' size='sm' onClick={search}>
          {fetching ? (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            'Search'
          )}
        </Button>
      </div>
      <NotesRequestTable data={notesRequests} getRequests={search} />
    </PageScaffold>
  );
}
