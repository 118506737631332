import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { clearSelectedClaim, clearState } from '../../Actions';
import {
  fetchAdjusters,
  fetchAttorneyTypes,
  fetchBodyParts,
  fetchCaseManagementResolutionTypes,
  fetchCauses,
  fetchClaimNoteTypes,
  fetchClaimStatuses,
  fetchCounties,
  fetchDispositionTypes,
  fetchDocStatuses,
  fetchDocTypes,
  fetchEdiBenefitTypeCodes,
  fetchEdiCodes,
  fetchEdiMaintTypeCodes,
  fetchEdiPartOfBodyCodes,
  fetchInsuranceCompanies,
  fetchInsuranceCompaniesForUser,
  fetchJobClasses,
  fetchAppealRulings,
  fetchAwardRulings,
  fetchJudges,
  fetchLawFirms,
  fetchMedicares,
  fetchNurseCaseManagers,
  fetchPayCodes,
  fetchPayCodesIsExcess,
  fetchResults,
  fetchSettlementTypes,
  fetchShifts,
  fetchStates,
  fetchSupervisors,
  fetchWorkStatusBodyParts,
  fetchWorkStatusTypes,
  getPbms,
  getDocPriorities,
  getDocTypesFullScanner,
  fetchClaimsAssistants,
  fetchIcdCodes,
  fetchPlanInsuranceTypes,
  fetchInsuranceTypes,
  fetchClaimType,
  fetchStopPaymentOptions,
} from '../../Actions/ReferenceActions';
import { fetchAllClaimCustomOptions } from '../../Actions/ClaimCustomOptionActions';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import AppLayout from '../AppLayout/AppLayout';
import ErrorBoundary from '../Common/ErrorBoundary';
import Loading from '../Common/Loading';
import OffCanvasCalendar from '../OffCanvasCalendar/OffCanvasCalendar';
import styles from './index.module.css';
import { ClaimTypes } from '../../ApiTypes/ClaimTypeConfiguration';

export default function PageScaffold({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  const { adjusters, bodyParts, counties, claimTypes } = useAppSelector(
    (state) => state.reference
  );

  useEffect(() => {
    getReferences();
    // return () => {
    //   dispatch(clearState);
    //   dispatch(clearSelectedClaim);
    // };
  }, []);

  const getReferences = async () => {
    if (
      !adjusters.length ||
      !bodyParts.length ||
      !counties.length ||
      !claimTypes.length
    ) {
      await Promise.all([
        dispatch(fetchAdjusters()),
        dispatch(fetchBodyParts()),
        dispatch(fetchCounties()),
        dispatch(fetchCauses()),
        dispatch(fetchResults()),
        dispatch(fetchJobClasses()),
        dispatch(fetchStates()),
        dispatch(fetchClaimStatuses()),
        dispatch(fetchMedicares()),
        dispatch(fetchShifts()),
        dispatch(fetchSupervisors()),
        dispatch(fetchInsuranceCompanies()),
        dispatch(fetchClaimNoteTypes()),
        dispatch(fetchPayCodesIsExcess()),
        dispatch(fetchPayCodes()),
        dispatch(fetchNurseCaseManagers()),
        dispatch(fetchCaseManagementResolutionTypes()),
        dispatch(fetchAttorneyTypes()),
        dispatch(fetchLawFirms()),
        dispatch(fetchSettlementTypes()),
        dispatch(fetchDispositionTypes()),
        dispatch(fetchAppealRulings()),
        dispatch(fetchAwardRulings()),
        dispatch(fetchJudges()),
        dispatch(fetchDocTypes()),
        dispatch(fetchDocStatuses()),
        dispatch(fetchEdiCodes()),
        dispatch(fetchEdiBenefitTypeCodes()),
        dispatch(fetchEdiMaintTypeCodes()),
        dispatch(fetchEdiPartOfBodyCodes()),
        dispatch(fetchWorkStatusTypes()),
        dispatch(fetchWorkStatusBodyParts()),
        dispatch(fetchInsuranceCompaniesForUser()),
        dispatch(getPbms()),
        dispatch(getDocPriorities()),
        dispatch(getDocTypesFullScanner()),
        dispatch(fetchClaimsAssistants()),
        dispatch(fetchIcdCodes()),
        dispatch(fetchPlanInsuranceTypes()),
        dispatch(fetchInsuranceTypes()),
        dispatch(fetchClaimType()),
        dispatch(fetchAllClaimCustomOptions()),
        dispatch(fetchStopPaymentOptions()),
      ]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  return (
    <AppLayout>
      <>{loading && <Loading />}</>
      <Container fluid className='d-flex justify-content-end pt-1 h-100'>
        <ErrorBoundary>
          <div className='d-flex w-100'>
            <div className={styles.tabWidth}>{children}</div>
            <OffCanvasCalendar />
          </div>
        </ErrorBoundary>
      </Container>
    </AppLayout>
  );
}
