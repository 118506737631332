import React from 'react';
import { Modal, Container } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import ExcessStatusTable from './ExcessStatusTable';

export default function ExcessStatusModal({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (s: boolean) => void;
}) {
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      aria-labelledby='Excess-Status-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Excess-Status-modal'>
          <FaCheck className='pe-1' /> Excess Status
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <ExcessStatusTable />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
