import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaFileAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { SiteFunctionsEnum } from '../../../ApiTypes/SiteFunctions';
import { useAppSelector } from '../../../Reducers/Store';

export default function DocumentsDropdown() {
  const { userModel } = useAppSelector((state) => state.user);
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant='secondary'
        id='documents'
        className='button-icon-text'
      >
        <FaFileAlt />
        Documents
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Link to='/my-documents'>
          <Dropdown.Item as='button'>My Documents</Dropdown.Item>
        </Link>
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.ScaningCentral && f.enabled
        ) && (
          <Link to='/scanning-central'>
            <Dropdown.Item as='button'>Scanning Central</Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) => f.siteFunctionId === SiteFunctionsEnum.StraightPay && f.enabled
        ) && (
          <Link to='/straight-pay'>
            <Dropdown.Item as='button'>Straight Pay</Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) => f.siteFunctionId === SiteFunctionsEnum.CompIQ && f.enabled
        ) && (
          <>
            <Dropdown.Divider />
            <Link to='/comp-complete-processing'>
              <Dropdown.Item as='button'>CompIQ</Dropdown.Item>
            </Link>
            <Link to='/comp-complete-exceptions'>
              <Dropdown.Item as='button'>Import Exceptions</Dropdown.Item>
            </Link>
            <Dropdown.Divider />
          </>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.EORProcessing && f.enabled
        ) && (
          <Link to='/eor-processing'>
            <Dropdown.Item as='button'>EOR Processing</Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.NCMDocuments && f.enabled
        ) && (
          <Link to='/ncm-documents'>
            <Dropdown.Item as='button'>NCM Documents</Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.ManagerDashboard && f.enabled
        ) && (
          <Link to='/manager-dashboard'>
            <Dropdown.Item as='button'>Manager Dashboard</Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.ScannedDocuments && f.enabled
        ) && (
          <Link to='/recent-activity'>
            <Dropdown.Item as='button'>My Recent Activities</Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.TurnaroundAverages &&
            f.enabled
        ) && (
          <Link to='/turn-around'>
            <Dropdown.Item as='button'>Turnaround Averages</Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.DocNotesRequests && f.enabled
        ) && (
          <Link to='/notes-requests'>
            <Dropdown.Item as='button'>Doc Notes Requests</Dropdown.Item>
          </Link>
        )}
        <Link to='/secure-shares'>
          <Dropdown.Item as='button'>Secure Shares</Dropdown.Item>
        </Link>
      </Dropdown.Menu>
    </Dropdown>
  );
}
