import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { CcExceptionObj } from '../ApiTypes/CcExceptionObj';
import { CompCompleteDoc } from '../ApiTypes/CompCompleteDoc';
import { CompIqFeeResponse } from '../ApiTypes/CompIqFeeResponse';
import { CompIqStatObj } from '../ApiTypes/CompIqStatObj';
import { DailyCounts } from '../ApiTypes/DailyCounts';
import { FinishCompCompleteBillingRequest } from '../ApiTypes/FinishCompCompleteBillingRequest';
import { FixProblemFeeRequest } from '../ApiTypes/FixProblemFeeRequest';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { SimpleClaimObj } from '../ApiTypes/SimpleClaimObj';
import { UpdateCcClaimNumberRequest } from '../ApiTypes/UpdateCcClaimNumberRequest';
import { UpdateControlNumberRequest } from '../ApiTypes/UpdateControlNumberRequest';
import { FileDownload } from '../Types/FileDownLoad';

class CompCompleteApi {
  static getStats(fromDate: string, toDate: string) {
    return axios.get<CompIqStatObj[]>(
      `${API_URL}/api/compcomplete/stats?fromDate=${fromDate}&toDate=${toDate}`,
      getAuthHeader()
    );
  }
  static getChartData(fromDate: string, toDate: string) {
    return axios.get<DailyCounts[]>(
      `${API_URL}/api/compcomplete/chart?fromDate=${fromDate}&toDate=${toDate}`,
      getAuthHeader()
    );
  }
  static getExport(fromDate: string, toDate: string) {
    return axios.get<FileDownload>(
      `${API_URL}/api/compcomplete/export?fromDate=${fromDate}&toDate=${toDate}`,
      getAuthHeader()
    );
  }
  static getCompCompleteProcessing(statusId: number, insurCoId: number) {
    let url = `${API_URL}/api/compcomplete/fulllistcompiq?status=${statusId}`;

    if (insurCoId) {
      url += `&insurCoId=${insurCoId}`;
    }
    return axios.get<CompCompleteDoc[]>(url, getAuthHeader());
  }
  static getCompCompleteDocFile(id: number) {
    return axios.get<FileDownload>(
      `${API_URL}/api/compcomplete/${id}/file`,
      getAuthHeader()
    );
  }
  static getCompCompleteExceptions() {
    return axios.get<CcExceptionObj[]>(
      `${API_URL}/api/compcomplete/exceptions`,
      getAuthHeader()
    );
  }
  static updateClaimNumber(request: UpdateCcClaimNumberRequest) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/compcomplete/exceptions/claimnumber`,
      request,
      getAuthHeader()
    );
  }
  static updateControlNumber(request: UpdateControlNumberRequest) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/compcomplete/exceptions/controlnumber`,
      request,
      getAuthHeader()
    );
  }
  static getFeesForMonth(startDate: string, endDate: string) {
    return axios.get<CompIqFeeResponse>(
      `${API_URL}/api/compiq/feesformonth?startDate=${startDate}&endDate=${endDate}`,
      getAuthHeader()
    );
  }
  static getRelatedClaims(documentControlNumber: string) {
    return axios.get<SimpleClaimObj[]>(
      `${API_URL}/api/compiq/${documentControlNumber}/relatedclaims`,
      getAuthHeader()
    );
  }
  static fixProblemFee(request: FixProblemFeeRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/compiq/fixproblemfee`,
      request,
      getAuthHeader()
    );
  }
  static finishBilling(request: FinishCompCompleteBillingRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/compiq/finishbilling`,
      request,
      getAuthHeader()
    );
  }
}

export default CompCompleteApi;
