import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { FaUsers } from 'react-icons/fa';
import { Form as RFForm, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { parseDatesForServer } from '../../Utils';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import { UserLog } from '../../ApiTypes/UserLog';
import { SearchUserLogsRequest } from '../../ApiTypes/SearchUserLogsRequest';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import UserLogApi from '../../Api/UserLogApi';
import UserLogTable from './UserLogTable';
import UserLogDetails from './UserLogDetails';

export default function UserLogs() {
  const [logs, setLogs] = useState<UserLog[]>([]);
  const [selectedLog, setSelectedLog] = useState<UserLog | null>(null);
  const [showLog, setShowLog] = useState<boolean>(false);

  useEffect(() => {
    getAll();
  }, []);

  const onSubmit = (values: SearchUserLogsRequest) => {
    if (!values.userName && !values.fromDate && !values.toDate) {
      return getAll();
    }
    return UserLogApi.searchUserLogs(values)
      .then((res) => {
        setLogs(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get user logs');
      });
  };

  const getAll = () => {
    return UserLogApi.getAll()
      .then((res) => {
        setLogs(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get user logs');
      });
  };

  const handleView = (l: UserLog) => {
    setSelectedLog(l);
    setShowLog(true);
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaUsers className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>User Logs</h1>
          </div>
        </div>
      </div>
      <RFForm
        onSubmit={onSubmit}
        initialValues={{
          userName: '',
        }}
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className='d-flex justify-content-center align-items-center gap1Rem'>
                <Field
                  name='userName'
                  label='User Name'
                  type='text'
                  component={FieldBSRenderText}
                />
                <Field
                  name='fromDate'
                  label='From Date'
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />
                <Field
                  name='toDate'
                  label='To Date'
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />

                <Button type='submit' variant='primary' size='sm'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Search'
                  )}
                </Button>

                <Button
                  type='button'
                  variant='outline-primary'
                  size='sm'
                  onClick={() => {
                    form.reset();
                    getAll();
                  }}
                >
                  Clear
                </Button>
              </div>
            </Form>
          );
        }}
      />
      <UserLogTable data={logs} handleView={handleView} />
      <UserLogDetails show={showLog} setShow={setShowLog} log={selectedLog} />
    </PageScaffold>
  );
}
