import React from 'react'
import { Table as BSTable } from "react-bootstrap"
import { EdiTransactionLog } from '../../../ApiTypes/EdiTransactionLog'
import { displayDateOnly } from '../../../Utils'





const TransactionLogsTable = (data: EdiTransactionLog[]): JSX.Element => {
  const childColumnData = [
    {
      header: "Date",
      accessorKey: "dateTime",
      cell: (d: string) => displayDateOnly(d),
    },
    {
      header: "Notes",
      accessorKey: "notes",
    },
    {
      header: "Creator",
      accessorKey: "creator",
    },
    {
      header: "Status",
      accessorFn: (d: EdiTransactionLog): string => d.ediStatus?.ediStatusDesc ?? "",
    },
  ]

  return (
    <BSTable
      size="sm"
      striped
      bordered
      hover
      style={{
        width: "auto",
        marginLeft: "2rem"
      }}
    >
      <thead >
        <tr>
          {
            childColumnData.map(h => <th key={h.header}>{h.header}</th>)
          }
        </tr>
      </thead>
      <tbody>
        {
          data.map((d, i) => (
            <tr key={i}>
              {
                childColumnData.map(h => {
                  if (!h.cell) {
                    return (
                      <td
                        style={{
                          whiteSpace: "nowrap"
                        }}
                        key={`${h.header}i`}>
                        {h.accessorFn ? h.accessorFn(d) : `${d[h.accessorKey as keyof typeof d]}`}
                      </td>
                    )
                  } else {
                    return (
                      <td
                        style={{
                          whiteSpace: "nowrap"
                        }}
                        key={`${h.header}i`}>
                        {h.cell(`${d[h.accessorKey as keyof typeof d]}`)}
                      </td>
                    )
                  }
                })
              }
            </tr>
          ))
        }
      </tbody>
    </BSTable>
  )
}

export default TransactionLogsTable;
