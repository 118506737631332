import React from 'react';
import { Modal, Container } from 'react-bootstrap';
import { FaEdit, FaEye } from 'react-icons/fa';
import { Physician } from '../../ApiTypes/Physician';
import ViewAddPhysicianForm from './ViewAddPhysicianForm';

export default function ViewAddPhysician({
  show,
  setShow,
  physicianId,
  setPhysicianId,
  submitAddView,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  physicianId?: number;
  setPhysicianId: (id: number) => void;
  submitAddView: (values: Physician) => Promise<void>;
}) {
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setPhysicianId(0);
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='View-Add-Edit-Physician-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='View-Add-Edit-Physician-Form-modal'
        >
          {physicianId && physicianId > 0 ? (
            <>
              <FaEdit className='pe-1' /> Physician
            </>
          ) : (
            <>
              <FaEye className='pe-1' /> Physician
            </>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <ViewAddPhysicianForm
            show={show}
            setShow={setShow}
            physicianId={physicianId}
            setPhysicianId={setPhysicianId}
            submitAddView={submitAddView}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
