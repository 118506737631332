import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { FaEdit, FaEye } from 'react-icons/fa';
import { CaseManagement } from '../../../ApiTypes/CaseManagement';
import { displayDateOnly } from '../../../Utils';
import accounting from 'accounting';
import { Link } from 'react-router-dom';

export default function NcmTable({
  ncms,
  viewReport,
}: {
  ncms: CaseManagement[];
  viewReport: (ncm: CaseManagement) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => ncms, [ncms]);

  const columnData: ColumnDef<CaseManagement>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 75,
      size: 75,
      cell: (d) => {
        return (
          <div className='d-flex justify-content-around'>
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='View Report'
              onClick={() => {
                viewReport(d.row.original);
              }}
            >
              <FaEye />
            </Button>
            <Link to={`${d.row.original.id}`}>
              <Button type='button' size='sm' variant='primary' title='View'>
                <FaEdit />
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      header: 'User',
      accessorKey: 'userId',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Assigned To',
      accessorKey: 'assignedTo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date Started',
      accessorKey: 'dateStarted',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dateStarted ?? ''),
    },
    {
      header: 'Date Completed',
      accessorKey: 'dateCompleted',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dateCompleted ?? ''),
    },
    {
      header: 'Fee',
      accessorFn: (d) => `${d.fee}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) => accounting.formatMoney(d.row.original.fee ?? 0),
    },
    {
      header: 'Billed',
      accessorFn: (d) => (d.billed === true ? 'YES' : 'NO'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Resolution',
      accessorFn: (d) => d.resolution?.name ?? '',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Total Billed',
      accessorFn: (d) => `${d.totalBilled}`,
      sortingFn: 'alphanumeric',
      filterFn: 'equals',
      cell: (d) => accounting.formatMoney(d.row.original.totalBilled ?? 0),
    },
    {
      header: 'Completed By',
      accessorKey: 'userCompletedId',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        selectableRow={true}
        highlightRow={true}
      />
    </div>
  );
}
