import React, { useEffect, useState } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { FaColumns, FaSearch } from 'react-icons/fa';
import { Form as RFForm, Field } from 'react-final-form';
import { EmployerPolicyPanel } from '../../ApiTypes/EmployerPolicyPanel';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { useAppSelector } from '../../Reducers/Store';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { WcPanel } from '../../ApiTypes/WcPanel';
import WcPanelApi from '../../Api/WcPanelApi';
import { toast } from 'react-toastify';
import { Employer } from '../../ApiTypes/Employer';
import EmployerApi from '../../Api/EmployerApi';
import { EmployerPolicy } from '../../ApiTypes/EmployerPolicy';
import { Location } from '../../ApiTypes/Location';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderFilePicker from '../Common/FieldBSRenderFilePicker';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import { FormApi } from 'final-form';
import BSRenderText from '../Common/BSRenderText';
import { format } from 'date-fns';
import styles from './index.module.css';
import { EmployerPolicyPanelAddUpdateRequest } from '../../ApiTypes/EmployerPolicyPanelAddUpdateRequest';

interface EmployerPolicyPanelFormType {
  accountNumber: string;
  employerId: number;
  policyId: number;
  panelId: number;
  effectiveDate: string;
  locationIds: number[];
  usePanelInfo: boolean;
  useUpdatedPanel: boolean;
  insertCompanyName: boolean;
  positionPage1: number | null;
  positionPage2: number | null;
  file: { file?: File; name?: string };
}

export default function WCPanelPolicyLink({
  show,
  setShow,
  employerPolicyPanel,
  wcPanelId,
  afterSubmit,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  employerPolicyPanel: EmployerPolicyPanel | null;
  wcPanelId?: number;
  afterSubmit: () => void;
}) {
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );

  let formInstance: FormApi<
    EmployerPolicyPanelFormType,
    Partial<EmployerPolicyPanelFormType>
  >;

  useEffect(() => {
    init();
  }, [wcPanelId, employerPolicyPanel]);

  const [wcPanel, setWcPanel] = useState<WcPanel | null>(null);
  // const [wcPanels, setWcPanels] = useState<WcPanel[]>([]);
  const [employers, setEmployers] = useState<Employer[]>([]);
  const [policies, setPolicies] = useState<EmployerPolicy[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);

  const [positionPage1, setPositionPage1] = useState<string>('0');
  const [positionPage2, setPositionPage2] = useState<string>('0');

  const init = () => {
    setEmployers([]);
    setPolicies([]);
    setLocations([]);
    const panelId = employerPolicyPanel
      ? employerPolicyPanel.panelId
      : wcPanelId;

    if (panelId) {
      getWcPanelById(panelId);
      // listWcPanels();
    }

    if (employerPolicyPanel?.policy?.employer?.accountNumber) {
      getEmployersForAccount(
        employerPolicyPanel?.policy?.employer?.accountNumber
      );
    }
    if (employerPolicyPanel?.policy?.employerId) {
      getPoliciesForEmployer(employerPolicyPanel?.policy?.employerId);
    }
    if (employerPolicyPanel?.policyId) {
      getLocationsByPolicyId(employerPolicyPanel?.policyId);
    }
  };

  const getWcPanelById = (panelId: number) => {
    if (panelId) {
      WcPanelApi.getWcPanelById(panelId)
        .then((res) => {
          setWcPanel(res.data);
          if (res.data.positionPage1) {
            setPositionPage1(`${res.data.positionPage1}`);
          }
          if (res.data.positionPage2) {
            setPositionPage2(`${res.data.positionPage2}`);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get Wc Panel');
        });
    }
  };

  // const listWcPanels = () => {
  //   WcPanelApi.listWcPanels()
  //     .then((res) => {
  //       setWcPanels(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error('Failed to list WC Panels');
  //     });
  // };

  const getEmployersForAccount = (accountNumber: string) => {
    if (accountNumber) {
      EmployerApi.getEmployerList({ accountNumber })
        .then((res) => {
          const sorted = [...res.data].sort((a, b) =>
            (a.name ?? '').localeCompare(b.name ?? '')
          );
          setEmployers(sorted);
          setLocations([]);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get employer list');
        });
    }
  };

  const getPoliciesForEmployer = (employerId: number) => {
    if (employerId) {
      EmployerApi.getEmployerPolicies(employerId)
        .then((res) => {
          const sorted = [...res.data].sort(
            (a, b) => b.treatyYear - a.treatyYear
          );
          setPolicies(sorted);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get policies for employer');
        });
    }
  };

  const getLocationsByPolicyId = (policyId: number) => {
    if (policyId) {
      EmployerApi.getLocationsByPolicyId(policyId)
        .then((res) => {
          setLocations(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get locations for policy');
        });
    }
  };

  const getDisplayName = (loc: Location) => {
    let cityStateZip = '';
    if (loc.address) {
      cityStateZip = `${loc.address.city}, ${loc.address.state} ${loc.address.zip}`;
    }
    return `${loc.locationName ?? ''} ${cityStateZip}`;
  };

  const handleUsePanelInfoChange = (checked: boolean) => {
    if (checked) {
      formInstance.change('useUpdatedPanel', false);
      formInstance.change('insertCompanyName', true);
    }
  };
  const handleUseUpdatePanelChange = (checked: boolean) => {
    if (checked) {
      formInstance.change('usePanelInfo', false);
      formInstance.change('insertCompanyName', false);
    } else {
    }
  };

  const generateDoc = (
    wcPanel: WcPanel,
    insertCompanyName: boolean,
    usePanelInfo: boolean,
    useUpdatedPanel: boolean,
    file: { file?: File; name?: string }
  ) => {
    return Promise.resolve(0);
  };

  const onSubmit = async (values: EmployerPolicyPanelFormType) => {
    const now = format(new Date(), 'yyyy-MM-dd');
    const {
      policyId,
      panelId,
      locationIds,
      effectiveDate,
      insertCompanyName,
      usePanelInfo,
      useUpdatedPanel,
      file,
    } = values;
    // const wcPanel = wcPanels.find((x) => x.id === panelId);
    const employerPolicy = policies.find((x) => x.policyId === +policyId);
    if (!wcPanel || !employerPolicy) {
      return;
    }
    const dateAdded = now;
    const panelName = wcPanel?.name ?? '';

    const endDate = employerPolicy?.endDate ?? now;

    const docId = await generateDoc(
      wcPanel,
      insertCompanyName,
      usePanelInfo,
      useUpdatedPanel,
      file
    );

    // TODO
    toast.info('Generate Doc, not implemented');

    const request: EmployerPolicyPanelAddUpdateRequest = {
      id: employerPolicyPanel?.id ?? 0,
      policyId: policyId,
      wcPanelId: panelId,
      dateAdded: dateAdded,
      docId: docId,
      panelName: panelName,
      effectiveDate: effectiveDate,
      endDate: endDate,
      locationIds: locationIds,
    };

    if (employerPolicyPanel?.id) {
      WcPanelApi.updateEmployerPolicyPanel(request)
        .then((res) => {
          if (res.data.success) {
            toast.success('Success');
            setShow(false);
            afterSubmit();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to update Employer Policy Panel');
        });
    } else {
      WcPanelApi.addEmployerPolicyToPanel(request)
        .then((res) => {
          if (res.data.success) {
            toast.success('Success');
            setShow(false);
            afterSubmit();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to add Employer Policy Panel');
        });
    }
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='WCPanelPolicyLink-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='WCPanelPolicyLink-modal'>
          <FaColumns className='pe-1' /> Panel Maintenance
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            keepDirtyOnReinitialize={true}
            onSubmit={onSubmit}
            initialValues={{
              accountNumber:
                employerPolicyPanel?.policy?.employer?.accountNumber ?? '',
              employerId: employerPolicyPanel?.policy?.employerId ?? 0,
              policyId: employerPolicyPanel?.policyId ?? 0,
              panelId: employerPolicyPanel?.panelId ?? wcPanelId ?? 0,
              effectiveDate: employerPolicyPanel?.effectiveDate ?? '',
              positionPage1: wcPanel?.positionPage1,
              positionPage2: wcPanel?.positionPage2,
              usePanelInfo: true,
              useUpdatedPanel: false,
              insertCompanyName: true,
              locationIds: (
                employerPolicyPanel?.employerPolicyPanelLocations ?? []
              ).map((x) => x.locationId),
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              formInstance = form;
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='accountNumber'
                    label='Account'
                    options={insuranceCompaniesForUser}
                    onChange={getEmployersForAccount}
                    optionMethod={(options: InsuranceCompany[]) =>
                      options.map((o) => (
                        <option
                          key={o.accountnumber}
                          value={o.accountnumber ?? ''}
                        >
                          {`${o.accountnumber} - ${o.shortname}`}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='employerId'
                    label='Employer'
                    options={employers}
                    onChange={getPoliciesForEmployer}
                    optionMethod={(options: Employer[]) =>
                      options.map((o) => (
                        <option key={o.employerId} value={o.employerId}>
                          {o.name}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='policyId'
                    label='Policy'
                    options={policies}
                    onChange={getLocationsByPolicyId}
                    optionMethod={(options: EmployerPolicy[]) =>
                      options.map((o) => (
                        <option key={o.policyId} value={o.policyId}>
                          {o.policyNumber}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <div className='mb-3'>
                    <fieldset>
                      <legend>Locations</legend>
                      {locations.map((d) => {
                        return (
                          <Field
                            key={d.locationId}
                            name='locationIds'
                            type='checkbox'
                            label={getDisplayName(d)}
                            checked={values.locationIds.includes(d.locationId)}
                            value={d.locationId}
                            component={FieldBSRenderCheckbox}
                          />
                        );
                      })}
                    </fieldset>
                  </div>
                  <BSRenderText
                    name='wcPanelName'
                    label='Panel'
                    type='text'
                    readOnly
                    value={wcPanel?.name ?? ''}
                  />
                  {/* <Field
                    name='panelId'
                    label='Panel'
                    options={wcPanels}
                    onChange={getWcPanelById}
                    optionMethod={(options: WcPanel[]) =>
                      options.map((o) => (
                        <option key={o.id} value={o.id}>
                          {o.name}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  /> */}
                  <div className='d-flex justify-content-between align-items-center gap1Rem pb-3'>
                    <Field
                      name='usePanelInfo'
                      type='checkbox'
                      label='Use Panel Info'
                      checked={values.usePanelInfo === true}
                      onCheckChange={handleUsePanelInfoChange}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='useUpdatedPanel'
                      type='checkbox'
                      label='Use Updated Panel'
                      onCheckChange={handleUseUpdatePanelChange}
                      checked={values.useUpdatedPanel === true}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='insertCompanyName'
                      type='checkbox'
                      label='Insert Company Name'
                      checked={values.insertCompanyName === true}
                      component={FieldBSRenderCheckbox}
                    />
                  </div>
                  {!values.usePanelInfo && (
                    <Field
                      name='file'
                      type='file'
                      component={FieldBSRenderFilePicker}
                    />
                  )}
                  <Field
                    name='effectiveDate'
                    label='Effective Date'
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                    validate={requiredField}
                  />
                  <div className='d-flex justify-content-around align-items-center gap1Rem'>
                    <div className='d-flex align-items-center gap1Rem'>
                      <BSRenderText
                        name='positionPage1'
                        label='Position Page 1'
                        type='number'
                        value={positionPage1}
                        onChange={setPositionPage1}
                      />
                      <BSRenderText
                        name='positionPage2'
                        label='Position Page 2'
                        type='number'
                        value={positionPage2}
                        onChange={setPositionPage2}
                      />
                      <Button
                        variant='outline-primary'
                        size='sm'
                        type='button'
                        title='Preview'
                        onClick={() => {
                          toast.info('Generate Doc and preview');
                        }}
                      >
                        <FaSearch />
                      </Button>
                    </div>
                    <div>
                      <Button type='submit' variant='primary' size='sm'>
                        {submitting ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          'Save'
                        )}
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
