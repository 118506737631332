import { addHours, addMinutes, format } from 'date-fns';
import { FormApi } from 'final-form';
import React, { useState, useEffect } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaClock } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ReminderApi from '../../Api/ReminderApi';
import ReservesApi from '../../Api/ReservesApi';
import UserApi from '../../Api/UserApi';
import UserPriorityClaimApi from '../../Api/UserPriorityClaimApi';
import { ClaimCountResponse } from '../../ApiTypes/ClaimCountResponse';
import { EntityUser } from '../../ApiTypes/EntityUser';
import { Reminder } from '../../ApiTypes/Reminder';
import { SimpleClaimReserveObject } from '../../ApiTypes/SimpleClaimReserveObject';
import { UserHistory } from '../../ApiTypes/UserHistory';
import { UserPriorityClaim } from '../../ApiTypes/UserPriorityClaim';
import { useAppSelector } from '../../Reducers/Store';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import NegativeReservesTable from './NegativeReservesTable';
import PriorityClaimsTable from './PriorityClaimsTable';
import RecentClaimsTable from './RecentClaimsTable';
import RemindersTable from './RemindersTable';
import BlankModal from '../Common/BlankModal';
import { ClaimNote } from '../../ApiTypes/ClaimNote';
import ConfirmationModal from '../Common/ConfirmationModal';

export default function Reminders() {
  const { userModel } = useAppSelector((state) => state.user);
  const [usersList, setUsersList] = useState<EntityUser[]>([]);
  const [priorityClaims, setPriorityClaims] = useState<UserPriorityClaim[]>([]);
  const [reminders, setReminders] = useState<Reminder[]>([]);
  const [userHistories, setUserHistories] = useState<UserHistory[]>([]);
  const [claimsWithNegativeReserves, setClaimsWithNegativeReserves] = useState<
    SimpleClaimReserveObject[]
  >([]);
  const [claimCounts, setClaimCounts] = useState<ClaimCountResponse | null>(
    null
  );

  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [reminderToComplete, setReminderToComplete] = useState<Reminder | null>(
    null
  );

  let formInstance: FormApi<
    { userId: string; asOfDate: string },
    Partial<{ userId: string; asOfDate: string }>
  >;

  useEffect(() => {
    getUsersList();
    getPriorityClaims();
    loadRecentClaims();
    getClaimCounts();
    getClaimsWithNegativeReserves();
  }, [userModel]);

  const getUsersList = () => {
    UserApi.getUsersList()
      .then((res) => {
        setUsersList(res.data);
        submitForm();
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to users list');
      });
  };

  const getPriorityClaims = () => {
    UserPriorityClaimApi.getUserPriorityClaims()
      .then((res) => {
        setPriorityClaims(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get user priority claims');
      });
  };

  const loadRecentClaims = () => {
    UserApi.getUserHistory(userModel?.user?.userId ?? '', 0, 10)
      .then((res) => {
        setUserHistories(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get recent claims');
      });
  };

  const getClaimCounts = () => {
    UserApi.getUserClaimCounts()
      .then((res) => {
        setClaimCounts(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to claim counts');
      });
  };

  const getClaimsWithNegativeReserves = () => {
    ReservesApi.getClaimsWithNegativeReserves()
      .then((res) => {
        setClaimsWithNegativeReserves(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get claims with negative reserves');
      });
  };

  const loadReminders = (values: { userId: string; asOfDate: string }) => {
    return ReminderApi.getReminders(values.userId, values.asOfDate)
      .then((res) => {
        setReminders(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to load reminders');
      });
  };

  const removePriority = (p: UserPriorityClaim) => {
    UserPriorityClaimApi.updateUserPriorityClaim(p.claimNo!, false)
      .then((res) => {
        getPriorityClaims();
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update priority');
      });
  };

  const markComplete = () => {
    if (reminderToComplete) {
      ReminderApi.markComplete(reminderToComplete.noteId)
        .then((res) => {
          setShowConfirm(false);
          if (res.data.success) {
            toast.success('Success');
            formInstance.submit();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setShowConfirm(false);
          toast.error('Failed to mark complete');
        });
    }
  };

  const submitForm = () => {
    const values = formInstance.getState().values;
    if (values.asOfDate && values.userId) {
      formInstance.submit();
    }
  };

  const onSubmit = (values: { userId: string; asOfDate: string }) => {
    return loadReminders(values);
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaClock className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Reminders Dashboard</h1>
          </div>
        </div>
      </div>
      <div>
        <div className='d-flex justify-content-between align-items-center'>
          <div className={styles.grid3}>
            <p className='fw-bold mb-0'>Open LT: {claimCounts?.ltCount ?? 0}</p>
            <p className='fw-bold mb-0'>Open MO: {claimCounts?.moCount ?? 0}</p>
            <p className='fw-bold mb-0'>Open AM: {claimCounts?.amCount ?? 0}</p>
          </div>
          <p className='fw-bold mb-0'>Reminders: {reminders.length}</p>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              userId: userModel?.user?.userId,
              asOfDate: format(
                addHours(addMinutes(new Date(), 59), 11),
                'yyyy-MM-dd'
              ),
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              formInstance = form;
              return (
                <Form onSubmit={handleSubmit}>
                  <div className='d-flex justify-content-end align-items-center gap1Rem pe-3'>
                    <Field
                      name='userId'
                      label='User'
                      options={usersList}
                      optionMethod={(options: EntityUser[]) =>
                        options.map((o) => (
                          <option key={o.userId} value={o.userId}>
                            {o.userName}
                          </option>
                        ))
                      }
                      validate={requiredField}
                      component={FieldBSRenderSelect}
                    />
                    <Field
                      name='asOfDate'
                      type='text'
                      label='Date To'
                      parse={parseDatesForServer}
                      validate={requiredField}
                      component={FieldBSRenderDate}
                    />
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Search'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </div>
        <RemindersTable
          data={reminders}
          setShowConfirm={setShowConfirm}
          setReminderToComplete={setReminderToComplete}
        />
      </div>
      <PriorityClaimsTable
        data={priorityClaims}
        removePriority={removePriority}
      />
      <NegativeReservesTable data={claimsWithNegativeReserves} />
      <RecentClaimsTable data={userHistories} />
      <ConfirmationModal
        show={showConfirm}
        setShow={setShowConfirm}
        message='Are you sure you want to mark this reminder complete?'
        confirmFunction={markComplete}
      />
    </PageScaffold>
  );
}
