import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Modal } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaPrescriptionBottle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import DrugScreenApi from '../../Api/DrugScreenApi';
import { ClaimDrugScreen } from '../../ApiTypes/ClaimDrugScreens';
import { parseDatesForServer } from '../../Utils';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import styles from './index.module.css';

export default function ClaimDrugScreenModal({
  show,
  setShow,
  claimNumber,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  claimNumber: string;
}) {
  const [drugScreen, setDrugScreen] = useState<ClaimDrugScreen | null>(null);

  useEffect(() => {
    getDrugScreenForClaim();
  }, [claimNumber]);

  const getDrugScreenForClaim = () => {
    if (claimNumber) {
      DrugScreenApi.getForClaim(claimNumber)
        .then((res) => {
          setDrugScreen(res.data.claimNo ? res.data : null);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onSubmit = (values: ClaimDrugScreen) => {
    if (
      values.screenRequested?.toString() !== 'true' &&
      values.screenRequested?.toString() !== 'false'
    ) {
      toast.error('Please select yes or no for drug screen requested');
      return;
    }
    const screen: ClaimDrugScreen = {
      id: 0,
      claimNo: values.claimNo,
      screenRequested: values.screenRequested?.toString() === 'true',
      requestDate: values.requestDate,
      requestFacility: values.requestFacility ?? '',
      requestName: values.requestName ?? '',
      wasPerformed: values.wasPerformed?.toString() === 'true',
      positive:
        values.positive?.toString() === 'true'
          ? true
          : values.positive?.toString() === 'false'
          ? false
          : null,
    };
    return DrugScreenApi.addDrugScreen(screen)
      .then((res) => {
        if (res.data.success) {
          toast.success('Drug screen added');
          getDrugScreenForClaim();
          setShow(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to add drug screen');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='Drug-Screen-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Drug-Screen-modal'>
          <FaPrescriptionBottle className='pe-1' /> Claim Drug Screen
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={
              drugScreen ?? {
                id: 0,
                claimNo: claimNumber,
                screenRequested: 'null',
                wasPerformed: false,
              }
            }
            validate={(values) => {
              const errors: {
                [Property in keyof ClaimDrugScreen]?: string;
              } = {};
              if (
                values.wasPerformed &&
                values.wasPerformed.toString() === 'true'
              ) {
                if ((values?.positive?.toString() ?? '').length < 4) {
                  toast.error('Please select Positive or Negative');
                }
              }
              return errors;
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <fieldset>
                    <legend>Drug Screen Requested</legend>
                    <div className={`${styles.grid3} mb-3`}>
                      <Field
                        name='screenRequested'
                        label='Yes'
                        type='radio'
                        value='true'
                        checked={values?.screenRequested?.toString() === 'true'}
                        component={FieldBSRenderCheckbox}
                      />
                      <Field
                        name='screenRequested'
                        label='No'
                        type='radio'
                        value='false'
                        checked={
                          values?.screenRequested?.toString() === 'false'
                        }
                        component={FieldBSRenderCheckbox}
                      />
                      <Field
                        name='screenRequested'
                        label='Unknown'
                        type='radio'
                        value='null'
                        checked={values?.screenRequested?.toString() === 'null'}
                        component={FieldBSRenderCheckbox}
                      />
                    </div>
                    <Field
                      name='requestDate'
                      label='Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                    <Field
                      name='requestFacility'
                      label='Facility'
                      type='text'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='requestName'
                      label='By Whom'
                      type='text'
                      component={FieldBSRenderText}
                    />
                  </fieldset>
                  <fieldset>
                    <legend>Drug Screen Performed</legend>
                    <div className={`${styles.grid3} mb-3`}>
                      <Field
                        name='wasPerformed'
                        label='Yes'
                        type='checkbox'
                        disabled={
                          values?.screenRequested?.toString() !== 'true'
                        }
                        checked={values?.wasPerformed?.toString() === 'true'}
                        component={FieldBSRenderCheckbox}
                      />
                      <Field
                        name='positive'
                        label='Positive'
                        type='radio'
                        value={true}
                        disabled={
                          values?.screenRequested?.toString() !== 'true' ||
                          values?.wasPerformed?.toString() !== 'true'
                        }
                        checked={values?.positive?.toString() === 'true'}
                        component={FieldBSRenderCheckbox}
                      />
                      <Field
                        name='positive'
                        label='Negative'
                        type='radio'
                        value={false}
                        disabled={
                          values?.screenRequested?.toString() !== 'true' ||
                          values?.wasPerformed?.toString() !== 'true'
                        }
                        checked={values?.positive?.toString() === 'false'}
                        component={FieldBSRenderCheckbox}
                      />
                    </div>
                  </fieldset>
                  <div className={styles.confirmButtonsDiv}>
                    <Button type='submit' size='lg' variant='primary'>
                      Submit
                    </Button>
                    <Button
                      type='button'
                      size='lg'
                      variant='secondary'
                      onClick={() => {
                        form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
