import React, { useMemo, useState, useEffect } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  RowSelectionState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { ScanDoc } from '../../ApiTypes/ScanDoc';
import { displayDateOnly } from '../../Utils';
import { useAppSelector } from '../../Reducers/Store';
import IndeterminateCheckbox from '../Common/IndeterminateCheckbox';
import styles from './index.module.css';

export default function StraightPayTable({
  data,
  getDocs,
  setSelectedDoc,
  rowSelection,
  setRowSelection,
}: {
  data: ScanDoc[];
  getDocs: () => void;
  setSelectedDoc: (d: ScanDoc | null) => void;
  rowSelection: RowSelectionState;
  setRowSelection: (obj: object) => void;
}) {
  const { docStatuses, docTypes, docPriorities } = useAppSelector(
    (state) => state.reference
  );
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  useEffect(() => {
    handleNewData();
  }, [data]);

  const handleNewData = () => {
    setRowSelection({});
  };

  const handleRowSelectionChange = () => {
    setSelectedDoc(
      table.getSelectedRowModel().flatRows.map((r) => r.original)[0]
      // table.getSelectedRowModel().flatRows.map((r) => r.original)[1]
    );
  };

  const getBackGroundColor = (r: boolean) => {
    if (r) {
      return 'orange';
    } else {
      return '';
    }
  };

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<ScanDoc>[] = [
    {
      header: 'Doc Name',
      accessorKey: 'note',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claim Number',
      accessorKey: 'claimno',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claimant',
      accessorKey: 'claimantName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date',
      accessorKey: 'date',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.date ?? ''),
      maxSize: 100,
    },
    {
      header: 'Type',
      accessorFn: (d) =>
        docTypes.find((t) => t.docTypeId === d.doctypeid)?.description ?? 'N/A',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Status',
      accessorFn: (d) =>
        docStatuses.find((t) => t.keyDocStatusId === d.statusId)?.name ?? 'N/A',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(
    () => columnData,
    [docStatuses, docTypes, docPriorities]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',
    enableRowSelection: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
      highlightRow={true}
      selectableRow={true}
    />
  );
}
