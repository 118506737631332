import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { displayDateOnly } from '../../Utils';
import { Button } from 'react-bootstrap';
import { FaEdit, FaPlusCircle, FaEye } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

export default function EdiHistoryTable({
  transactions,
  openEditModal,
}: {
  transactions: EdiTransaction[];
  openEditModal: (t: EdiTransaction) => void;
}) {
  const navigate = useNavigate();
  const [sorting, setSorting] = useState<SortingState>([]);

  const tableData = useMemo(() => transactions, [transactions]);

  const columnData: ColumnDef<EdiTransaction>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      size: 100,
      cell: (d) => {
        return (
          <div className='d-flex align-items-center gap1Rem'>
            <Button
              type='button'
              size='sm'
              variant='outline-primary'
              title='View Claim'
              onClick={() => {
                navigate(`/claims/${d.row.original.claimno}`);
              }}
            >
              <FaEye />
            </Button>
            <Button
              type='button'
              size='sm'
              variant='outline-primary'
              title='Edit EDI Transaction'
              onClick={() => {
                openEditModal(d.row.original);
              }}
            >
              <FaEdit />
            </Button>
            <Link to={`/add-edi/${d.row.original.claimno}`}>
              <Button
                type='button'
                size='sm'
                variant='outline-primary'
                title='New EDI Transaction'
              >
                <FaPlusCircle />
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      header: 'Ref Number',
      accessorFn: (d) => `${d.id}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Status',
      accessorKey: 'submitStatusString',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claim No',
      accessorKey: 'claimno',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claimant',
      accessorKey: 'claimantFullName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Type',
      accessorKey: 'form',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Type Code',
      accessorKey: 'maintTypeCode',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },

    {
      header: 'Event Date',
      accessorKey: 'eventDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.eventDate ?? ''),
    },
    {
      header: 'Benefit',
      accessorKey: 'benefitTypeName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);
  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });
  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
