import React, { useState, useMemo, useEffect } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { TransactionSearchResult } from '../../ApiTypes/TransactionSearchResult';
import { displayDateOnly } from '../../Utils';
import accounting from 'accounting';

export default function PaymentReportTable({
  data,
  setFilteredRows,
  getPayCodeDescription,
  getFbucketDescription,
}: {
  data: TransactionSearchResult[];
  setFilteredRows: (rows: TransactionSearchResult[]) => void;
  getPayCodeDescription: (code: string) => string;
  getFbucketDescription: (id: number) => string;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => data, [data]);

  useEffect(() => {
    setFilteredRows(table.getFilteredRowModel().rows.map((x) => x.original));
  }, [columnFilters]);

  const columnData: ColumnDef<TransactionSearchResult>[] = [
    {
      header: 'Claim No',
      accessorKey: 'claimNo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Service From',
      accessorKey: 'serviceFrom',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'Service To',
      accessorKey: 'serviceTo',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'Pay Code',
      accessorFn: (d) => getPayCodeDescription(d.payCode ?? ''),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Amount',
      accessorFn: (d) => `${d.amount}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) => accounting.formatMoney(d.row.original.amount ?? 0),
      footer: ({ table }) => {
        const paid = table.getFilteredRowModel().rows.reduce((acc, curr) => {
          return (acc += +(curr.original.amount ?? 0));
        }, 0);
        return accounting.formatMoney(paid);
      },
    },
    {
      header: 'Check Number',
      accessorKey: 'checkNumber',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Check Date',
      accessorKey: 'checkDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'Memo',
      accessorKey: 'memo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Approved By',
      accessorKey: 'pmtApprovedBy',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Approval Date',
      accessorKey: 'approvalDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'Manager Approved By',
      accessorKey: 'managerApprovedBy',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Manager Approved Date',
      accessorKey: 'managerApprovedDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'Bucket',
      accessorFn: (d) => getFbucketDescription(d.fbucket ? +d.fbucket : 0),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Check Payable To',
      accessorKey: 'checkPayableTo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Company name',
      accessorKey: 'companyName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Tax Id',
      accessorKey: 'providerTaxId',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];
  const columns = useMemo(() => columnData, [data]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
      showFooter={true}
    />
  );
}
