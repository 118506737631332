import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { LawFirm } from '../../ApiTypes/LawFirm';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function LawFirmTable({
  data,
  handleViewFirm,
}: {
  data: LawFirm[];
  handleViewFirm: (firm: LawFirm) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<LawFirm>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center align-items-center gap1Rem'>
            <Button
              type='button'
              variant='outline-primary'
              size='sm'
              title='View Firm'
              className='button-icon-text'
              onClick={() => {
                handleViewFirm(row.original);
              }}
            >
              Firm
            </Button>
            <Link to={`/lawfirms/${row.original.firmId}/attorneys`}>
              <Button
                type='button'
                variant='outline-primary'
                size='sm'
                title='View Attorneys'
                className='button-icon-text'
              >
                Attorneys
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      header: 'Firm Name',
      accessorKey: 'firmName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Street Address',
      accessorKey: 'streetAddress',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Street Address1',
      accessorKey: 'streetAddress1',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'City',
      accessorKey: 'city',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'State',
      accessorKey: 'state',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 50,
    },
    {
      header: 'Zip',
      accessorKey: 'zip',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Phone',
      accessorKey: 'phone',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Fax',
      accessorKey: 'fax',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Website',
      accessorKey: 'webSite',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
