import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DailyCounts } from '../../ApiTypes/DailyCounts';
import styles from './index.module.css';
import { displayDateOnly } from '../../Utils';

export default function CompIqChart({ data }: { data: DailyCounts[] }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ChartDataLabels
  );

  const getOptions = (): any => ({
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      datalabels: {
        color: '#8E999F',
        anchor: 'end',
        align: 'start',
        font: {
          weight: 'bold',
        },
      },
      tooltip: {
        callbacks: {},
      },
      legend: {
        position: 'top',
        align: 'center',
      },
      // title: {
      //   text: 'Daily Log',
      //   align: 'center',
      //   font: {
      //     size: 24,
      //   },
      //   display: true,
      // },
    },

    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        ticks: {
          beginAtZero: false,
        },
      },
    },
  });

  const getData = (data: DailyCounts[]): any => {
    return {
      labels: data.map((x) => x.argumentName),
      datasets: [
        {
          label: 'Stats',
          data: data.map((x) => x.value),
          fill: false,
          type: 'line',
          backgroundColor: '#3f95ff',
          borderColor: '#3f95ff',
        },
      ],
    };
  };

  return (
    <div className='mb-3'>
      <div className={`${styles.chartArea}`}>
        {data ? (
          <Line data={getData(data)} options={getOptions()} />
        ) : (
          'No Available Data'
        )}
      </div>
    </div>
  );
}
