import React from 'react';
import { Button, Container, Form, Modal } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaUserCheck } from 'react-icons/fa';
import { Adjuster } from '../../ApiTypes/Adjuster';
import { useAppSelector } from '../../Reducers/Store';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';

export default function ChooseAdjuster({
  show,
  setShow,
  insurCoId,
  handleSubmit,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  insurCoId: number;
  handleSubmit: (adjusterId: number) => void;
}) {
  const { adjusters, insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );
  const onSubmit = (values: { adjusterId: number }) => {
    handleSubmit(values.adjusterId);
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='ChooseAdjuster-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='ChooseAdjuster-modal'>
          <FaUserCheck className='pe-1' /> Choose an Adjuster
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              adjusterId: insuranceCompaniesForUser?.find(
                (x) => x.insurcoid === insurCoId
              )?.adjusterId,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='adjusterId'
                    label='Adjuster'
                    options={adjusters
                      .filter((x) => x.active)
                      .sort((a, b) =>
                        (a.firstName ?? '').localeCompare(b.firstName ?? '')
                      )}
                    optionMethod={(options: Adjuster[]) =>
                      options.map((o) => (
                        <option key={o.adjusterId} value={o.adjusterId}>
                          {o.firstName + ' ' + o.lastName}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  />
                  <div className='d-flex justify-content-center'>
                    <Button type='submit' variant='primary' size='sm'>
                      Submit
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
