import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleError } from ".";
import AddressApi from "../Api/AddressApi";

export const fetchAddressById = createAsyncThunk(
  "FETCH_ADDRESS_BY_ID",
  async (id: number, thunkApi) => {
    try {
      const response = await AddressApi.getAddressById(id);
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);