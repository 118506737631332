import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { FaEye } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { WCPanelLocation } from '../../ApiTypes/WcPanelLocation';
import { Link } from 'react-router-dom';

export default function PanelExceptionTable({
  data,
}: {
  data: WCPanelLocation[];
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<WCPanelLocation>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center align-items-center gap1Rem'>
            <Link to={`/wcpanels/${row.original.panelId}`}>
              <Button type='button' variant='primary' size='sm'>
                Create
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      header: 'Policy #',
      accessorFn: (d) => `${d.policyId ?? 0}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Location Name',
      accessorKey: 'locationName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'City',
      accessorKey: 'city',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Zip',
      accessorKey: 'zip',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Employer Name',
      accessorKey: 'employerName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
