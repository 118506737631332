import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Settlement } from '../../../ApiTypes/Settlement';
import { displayDateOnly } from '../../../Utils';
import { makeMoney } from '../../../Utils/InputFormatters';
import { SettlementStatus } from '../../../ApiTypes/SettlementStatus';
import { SettlementPaymentDetail } from '../../../ApiTypes/SettlementPaymentDetail';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function SettlementTable({
  settlements,
  setSettlementToDelete,
  show,
  setShow,
  selectedSettlementId,
  setSelectedSettlementId,
}: {
  settlements: Settlement[];
  setSettlementToDelete: (s: Settlement) => void;
  show: boolean;
  setShow: (show: boolean) => void;
  selectedSettlementId: string | '';
  setSelectedSettlementId: (settlementId: string | '') => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => settlements, [settlements]);

  const getTotalAmount = (list: SettlementPaymentDetail[]) => {
    return list.reduce((acc, curr) => (acc += curr.amount ?? 0), 0);
  };

  const columnData: ColumnDef<Settlement>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 75,
      size: 75,
      cell: (d) => {
        return (
          <div className='d-flex justify-content-around'>
            {/* <Link to={`${d.row.original.settlementId}`} > */}
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='View/Edit'
              onClick={() => {
                setSelectedSettlementId(d.row.original.settlementId.toString());
                setShow(true);
              }}
            >
              <FaEdit />
            </Button>
            {/* </Link> */}
            <Button
              type='button'
              size='sm'
              variant='danger'
              title='Delete'
              onClick={() => {
                if (d.row.original.dateApproved) {
                  toast.error(
                    'Settlement has been approved, you cannot delete this.'
                  );
                } else {
                  setSettlementToDelete(d.row.original);
                }
              }}
            >
              <FaTrash />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Type',
      accessorKey: 'settlementType.name',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date',
      accessorKey: 'dateCreated',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dateCreated ?? ''),
    },
    {
      header: 'Notes',
      accessorKey: 'notes',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Amount',
      accessorFn: (d) => getTotalAmount(d.settlementPaymentDetails).toString(),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) => makeMoney(d.getValue()),
    },
    {
      header: 'MSA',
      accessorFn: (d) => `${d.msaapprovalAmount}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) => makeMoney(d.getValue()),
    },

    {
      header: 'Effective',
      accessorKey: 'dateOfSettlement',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dateOfSettlement ?? ''),
    },
    {
      header: 'Creator',
      accessorKey: 'creator',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Status',
      accessorFn: (d) => (d.status === null ? '' : SettlementStatus[d.status]),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        selectableRow={true}
        highlightRow={true}
      />
    </div>
  );
}
