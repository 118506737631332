import React from 'react';
import styles from './index.module.css';

const redBorder = {
  borderColor: 'red',
};

type FinalRenderFieldProps = {
  label?: string;
  type: string;
  underInputNote?: string;
  required?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  value: string;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  onFocus?: () => void;
  onClick?: () => void;
};

export default function BSRenderText({
  name,
  label,
  type,
  required,
  disabled,
  readOnly,
  className,
  placeholder,
  underInputNote,
  onChange,
  onBlur,
  onFocus,
  onClick,
  value,
}: FinalRenderFieldProps) {
  return (
    <div className={`position-relative ${styles.vFieldHeight}`}>
      <label htmlFor={name} className='form-label fs-6  m-0'>
        {label}
      </label>
      <input
        name={name}
        id={name}
        type={type}
        value={value}
        className='form-control form-control-sm'
        placeholder={placeholder || label}
        onChange={(e) => {
          onChange && onChange(e.target.value);
        }}
        onBlur={(e) => {
          onBlur && onBlur(e.target.value);
        }}
        onFocus={(e) => {
          onFocus && onFocus();
        }}
        onClick={(e) => {
          onClick && onClick();
        }}
        disabled={disabled}
        readOnly={readOnly}
      />
    </div>
  );
}
