import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
// import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
// import { WorkStatus } from '../ApiTypes/WorkStatus';
// import { WorkStatusBodyPart } from '../ApiTypes/WorkStatusBodyPart';
// import { WorkStatusGridObj } from '../ApiTypes/WorkStatusGridObject';
// import { WorkStatusRequest } from '../ApiTypes/WorkStatusRequest';
// import { ReportResponse } from '../ApiTypes/ReportRequest';
import { ClaimSubrogation } from '../ApiTypes/ClaimSubrogation';
import { ClaimSubrogationPayment } from '../ApiTypes/ClaimSubrogationPayment';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { Transaction } from '../ApiTypes/Transaction';

class SubrogationApi {
  static getClaimSubrogations(claimNumber: string) {
    return axios.get<ClaimSubrogation[]>(
      `${API_URL}/api/claims/${claimNumber}/subrogations`,
      getAuthHeader()
    );
  }
  static getClaimSubrogationById(id: number) {
    return axios.get<ClaimSubrogation>(
      `${API_URL}/api/subrogations/${id}`,
      getAuthHeader()
    );
  }
  static updateClaimSubrogation(request: ClaimSubrogation) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/subrogations`,
      request,
      getAuthHeader()
    );
  }
  static deleteClaimSubrogation(id: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/subrogations/${id}`,
      getAuthHeader()
    );
  }
  static getClaimSubrogationPaymentsForSubrogation(id: number) {
    return axios.get<ClaimSubrogationPayment[]>(
      `${API_URL}/api/subrogationpayments/${id}`,
      getAuthHeader()
    );
  }
  static getTransactionsForSubrogation(id: number) {
    return axios.get<Transaction[]>(
      `${API_URL}/api/subrogationtransactions/${id}`,
      getAuthHeader()
    );
  }
  static getClaimSubrogationPaymentById(id: number) {
    return axios.get<ClaimSubrogationPayment>(
      `${API_URL}/api/subrogationpayment/${id}`,
      getAuthHeader()
    );
  }
  static updateClaimSubrogationPayment(request: ClaimSubrogationPayment) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/subrogationpayment`,
      request,
      getAuthHeader()
    );
  }
  static deleteClaimSubrogationPayment(id: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/subrogationpayment/${id}`,
      getAuthHeader()
    );
  }
}

export default SubrogationApi;
