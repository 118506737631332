import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { Witness } from '../ApiTypes/Witness';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class WitnessApi {
  static getWitnessesForClaim(claimNumber: string) {
    return axios.get<Witness[]>(
      `${API_URL}/api/claims/${claimNumber}/witnesses`,
      getAuthHeader()
    );
  }
  static getWitnessById(claimNumber: string, witnessId: number) {
    return axios.get<Witness>(
      `${API_URL}/api/claims/${claimNumber}/witnesses/${witnessId}`,
      getAuthHeader()
    );
  }
  static createWitness(witness: Witness, claimNumber: string) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/witnesses`,
      witness,
      getAuthHeader()
    );
  }
  static updateWitness(witness: Witness, claimNumber: string) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/witnesses/${witness.id}`,
      witness,
      getAuthHeader()
    );
  }
  static deleteWitness(witnessId: number, claimNumber: string) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/witnesses/${witnessId}`,
      getAuthHeader()
    );
  }
}

export default WitnessApi;
