import React from 'react';
import { Form as RFForm, Field } from 'react-final-form';
import { RowSelectionState } from '@tanstack/react-table';
import { ScanDoc } from '../../ApiTypes/ScanDoc';
import { ScanDocEorSearchRequest } from './ScanDocEorSearchRequest';
import { Button, Form, Spinner } from 'react-bootstrap';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import styles from './index.module.css';
import ScanDocTable from './ScanDocTable';
import DocViewModal from '../DocViewModal/DocViewModal';
import { useDocViewModal } from '../DocViewModal/useDocViewModal';
import DocumentsApi from '../../Api/DocumentsApi';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

export default function ScanDocSide({
  loadScanDocs,
  scanDocs,
  scanDocRowSelection,
  setScanDocRowSelection,
  selectedScanDoc,
  setSelectedScanDoc,
}: // handleAttach,
// attaching,
{
  loadScanDocs: (request: ScanDocEorSearchRequest) => void;
  scanDocs: ScanDoc[];
  setScanDocRowSelection: (obj: object) => void;
  scanDocRowSelection: RowSelectionState;
  selectedScanDoc: ScanDoc | null;
  setSelectedScanDoc: (doc: ScanDoc | null) => void;
  // handleAttach: () => void;
  // attaching: boolean;
}) {
  const {
    showDocViewModal,
    fileDownload,
    setShowDocViewModal,
    setFileDownload,
  } = useDocViewModal();

  return (
    <div>
      <div className='mb-3'>
        <RFForm
          onSubmit={loadScanDocs}
          initialValues={{}}
          render={({ handleSubmit, form, values, submitting }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div className={styles.grid3}>
                  <Field
                    name='controlNumber'
                    label='Control Number'
                    type='text'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='claimNumber'
                    label='Claim Number'
                    type='text'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='checkNumber'
                    label='Check Number'
                    type='text'
                    component={FieldBSRenderText}
                  />
                </div>
                <div className={styles.grid3}>
                  <div />
                  <Button
                    type='button'
                    variant='outline-primary'
                    size='sm'
                    onClick={() => {
                      form.reset();
                    }}
                  >
                    Reset
                  </Button>
                  <Button type='submit' variant='primary' size='sm'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Search'
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        />
      </div>
      <ScanDocTable
        data={scanDocs}
        setSelectedDoc={setSelectedScanDoc}
        rowSelection={scanDocRowSelection}
        setRowSelection={setScanDocRowSelection}
      />
      <div className='d-flex justify-content-lg-between align-items-center'>
        <div className='d-flex flex-column'>
          <span>Name: {selectedScanDoc?.note ?? 'Please Select a File'}</span>
          <span>Claimant: {selectedScanDoc?.claimantName ?? ''}</span>
        </div>
        <Link
          to={`/scandocs/${selectedScanDoc?.docId}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          <Button
            type='button'
            variant='outline-primary'
            size='sm'
            disabled={!selectedScanDoc}
          >
            View EOR and Bill
          </Button>
        </Link>
        {/* <Button
          type='button'
          variant='outline-primary'
          size='sm'
          disabled={!selectedScanDoc}
          onClick={() => {
            if (selectedScanDoc) {
              handleAttach();
            }
          }}
        >
          {attaching ? (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            'Attach Selected Document'
          )}
        </Button> */}
      </div>
      <DocViewModal
        show={showDocViewModal}
        setShow={setShowDocViewModal}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
    </div>
  );
}
