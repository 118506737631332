import React from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import styles from './index.module.css';

export default function AuditConfirmModal({
  show,
  setShow,
  handleSubmit,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  handleSubmit: () => Promise<void>;
}) {
  return (
    <Modal
      centered
      show={show}
      size='sm'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='AuditConfirmModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='AuditConfirmModal-modal'>
          <FaCheck className='pe-1' /> Audit Added?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p>
            If an audit was completed, select submit, otherwise select cancel to
            close.
          </p>
          <div className='d-flex justify-content-around mt-3'>
            <Button
              type='button'
              variant='primary'
              size='sm'
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              type='button'
              variant='secondary'
              size='sm'
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
