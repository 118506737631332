import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import {
  AutomatedReportType,
  AutomatedReportingJob,
} from '../../ApiTypes/AutomatedReportingJob';
import { Button } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useAppSelector } from '../../Reducers/Store';
import { displayDateOnly } from '../../Utils';

export default function AutomatedJobsTable({
  data,
  handleDelete,
  handleEdit,
  handleTest,
}: {
  data: AutomatedReportingJob[];
  handleDelete: (job: AutomatedReportingJob) => void;
  handleEdit: (job: AutomatedReportingJob) => void;
  handleTest: (job: AutomatedReportingJob) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const getReportName = (reportType: number) => {
    switch (reportType) {
      case AutomatedReportType.LossRun:
        return 'Loss Run';
      case AutomatedReportType.PremiumAndLoss:
        return 'Premium and Loss';
      default:
        return 'Unknown';
    }
  };

  const columnData: ColumnDef<AutomatedReportingJob>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center align-items-center gap1Rem'>
            <Button
              type='button'
              variant='danger'
              size='sm'
              title='Delete'
              onClick={() => {
                handleDelete(row.original);
              }}
            >
              <FaTrash />
            </Button>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='Edit'
              onClick={() => {
                handleEdit(row.original);
              }}
            >
              <FaEdit />
            </Button>
            <Button
              type='button'
              variant='outline-primary'
              size='sm'
              title='Test'
              className='button-icon-text'
              onClick={() => {
                handleTest(row.original);
              }}
            >
              Test
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Job Id',
      accessorFn: (d) => `${d.id}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 75,
    },
    {
      header: 'Report',
      accessorFn: (d) => getReportName(d.reportType ?? 0),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Account',
      accessorFn: (d) => d.insuranceCompany?.accountnumber ?? 'N/A',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 100,
    },
    {
      header: 'Employer',
      accessorFn: (d) => d.employer?.name ?? 'N/A',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 200,
    },
    {
      header: 'To',
      accessorKey: 'sendTo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 300,
    },
    {
      header: 'Policy Number',
      accessorFn: (d) => d.employer?.clientEmployerId ?? 'N/A',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Start Date',
      accessorKey: 'startDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.startDate ?? ''),
    },
    {
      header: 'End On',
      accessorKey: 'stopDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.stopDate ?? ''),
    },
    {
      header: 'Day',
      accessorFn: (d) => `${d.dayOfMonthToSend}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 75,
    },
    {
      header: 'Creator',
      accessorKey: 'createdBy',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Last Ran On',
      accessorKey: 'lastRunDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.lastRunDate ?? ''),
    },
    {
      header: 'Reports Sent',
      accessorFn: (d) => `${d.automatedReportingJobLogs?.length ?? 0}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 75,
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
