import React from 'react';
import { Button, Container, Form, Modal } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaCheck } from 'react-icons/fa';
import { ScanDoc } from '../../ApiTypes/ScanDoc';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import styles from './index.module.css';

export default function UpdateNcmNotesModal({
  show,
  setShow,
  handleSubmit,
  selectedScanDoc,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  handleSubmit: (note: string) => void;
  selectedScanDoc: ScanDoc | null;
}) {
  const onSubmit = (values: { ncmnotes: string }) => {
    handleSubmit(values.ncmnotes);
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='UpdateNcmNotesModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='UpdateNcmNotesModal-modal'
        >
          <FaCheck className='pe-1' /> Update NCM Notes
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              ncmnotes: selectedScanDoc?.ncmnotes ?? '',
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='ncmnotes'
                    label='Notes'
                    rows={3}
                    validate={requiredField}
                    component={FieldBSRenderTextArea}
                  />
                  <Button type='submit' variant='primary' size='sm'>
                    Submit
                  </Button>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
