import React, { useState } from 'react';
import { Form, Spinner, Button } from 'react-bootstrap';
import { Form as RFFForm } from 'react-final-form';
import { toast } from 'react-toastify';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import EdiInfo from './EdiInfo';
import styles from './index.module.css';
import ProgressReport from './ProgressReport';
import {
  setShowDocView,
  setDocViewFileDownload,
} from '../../Actions/DocViewActions';
import { FileDownload } from '../../Types/FileDownLoad';
import {
  DevExpressReportRequest,
  DxAvailableReports,
} from '../../ApiTypes/DevExpressReportRequest';
import { getDxReport } from '../DocViewModal/useDocViewModal';
import { requestDx } from '../DxReportRequestModal/useDxReportRequestModal';

export default function FormSroiFn({
  transaction,
  onFormSubmit,
  closeModal,
  formType,
  maintTypeCode,
  benefitTypeCode,
}: {
  transaction: EdiTransaction | null;
  onFormSubmit: (values: EdiTransaction) => Promise<void>;
  closeModal: () => void;
  formType?: string;
  maintTypeCode?: string;
  benefitTypeCode?: string;
}) {
  const dispatch = useAppDispatch();
  const [showEditCreateProgressReport, setShowEditCreateProgressReport] =
    useState<boolean>(false);

  const [wc4Id, setWc4Id] = useState<number>(0);

  const { claim, claimant } = useAppSelector(
    (state) => state.currentClaimReducer
  );
  const { userModel } = useAppSelector((state) => state.user);
  const onSubmit = (values: EdiTransaction) => {
    if (!values.wc4id) {
      values.wc4id = wc4Id;
    }
    return onFormSubmit(values);
  };
  const setShowDx = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownload = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };
  const handleSettingWc4Id = (id: number) => {
    setWc4Id(id);
  };

  return (
    <>
      <RFFForm
        onSubmit={onSubmit}
        initialValues={
          transaction
            ? {
                ...transaction,
                maintTypeCode: maintTypeCode ?? transaction?.maintTypeCode,
              }
            : { maintTypeCode, benefitTypeCode, form: formType }
        }
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <EdiInfo
                transaction={transaction}
                formValues={values}
                formType='SROI'
                form={form}
              />
              <div className='d-flex justify-content-around mb-5'>
                <Button
                  type='button'
                  variant='outline-primary'
                  size='lg'
                  onClick={() => {
                    setShowEditCreateProgressReport(true);
                  }}
                >
                  {(transaction?.wc4id ?? 0) > 0
                    ? 'Edit Progress Report'
                    : 'Create Progress Report'}
                </Button>
                <Button
                  type='button'
                  variant='outline-primary'
                  size='lg'
                  onClick={() => {
                    const request: DevExpressReportRequest = {
                      ...requestDx,
                      report: DxAvailableReports.rptEDIProgressReport,
                      wC4Id: transaction?.wc4id ?? 0,
                      userId: userModel?.user?.userId ?? '',
                    };
                    getDxReport(request, setFileDownload, setShowDx);
                  }}
                  disabled={(transaction?.wc4id ?? 0) === 0}
                >
                  View Progress Report
                </Button>
              </div>
              <div className={`${styles.edi3Grid}`}>
                <div />
                {/* <Button
                  type='button'
                  size='sm'
                  variant='outline-secondary'
                  onClick={() => {
                    // TODO not used? ediComparison report
                    toast.info('Report EdiComparison not used');
                  }}
                >
                  Export
                </Button> */}
                <Button
                  type='submit'
                  size='sm'
                  variant='primary'
                  disabled={!values.wc4id && !wc4Id ? true : false}
                >
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button
                  type='button'
                  size='sm'
                  variant='secondary'
                  onClick={() => {
                    form.reset();
                    closeModal();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          );
        }}
      />
      <ProgressReport
        show={showEditCreateProgressReport}
        setShow={setShowEditCreateProgressReport}
        claimNumber={claim?.claimNo ?? ''}
        claimantFirstName={claimant?.firstName ?? ''}
        claimantLastName={claimant?.lastName ?? ''}
        wc4Id={transaction?.wc4id ?? undefined}
        isFn={maintTypeCode === 'FN'}
        setWc4Id={handleSettingWc4Id}
      />
    </>
  );
}
