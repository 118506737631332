import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { VwCheckRegisterLong } from '../../ApiTypes/VwCheckRegisterLong';
import { displayDateOnly } from '../../Utils';
import accounting from 'accounting';

export default function CheckRegisterTable({
  data,
}: {
  data: VwCheckRegisterLong[];
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<VwCheckRegisterLong>[] = [
    {
      header: 'Claim #',
      accessorKey: 'claimNo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claimant',
      accessorKey: 'claimantname',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Employer',
      accessorKey: 'employername',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Check Date',
      accessorKey: 'checkdate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Check #',
      accessorKey: 'checkNumber',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Amount',
      accessorFn: (d) => `${d.txamount}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) => accounting.formatMoney(d.row.original.txamount ?? 0),
    },
    {
      header: 'Code',
      accessorKey: 'paycode',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Payee',
      accessorKey: 'payee',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'SVC From',
      accessorKey: 'serviceFrom',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'SVC To',
      accessorKey: 'serviceTo',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Bucket',
      accessorKey: 'fbucket',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Treaty Year',
      accessorFn: (d) => `${d.treatyyear}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
