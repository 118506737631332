export interface ClaimSearchRequest {
  accountNumber: string;
  adjusterId: number | null;
  claimNumber: string;
  claimStatus: SearchClaimStatus | null;
  employer: string;
  firstName: string;
  injuryFromDate: string | null;
  injuryToDate: string | null;
  insurCoId: number | null;
  lastName: string;
  severity: SearchSeverity | null;
  ssn: string;
  supervisorUserId: string;
  claimTypeId: number | null;
  viewAllRecords: boolean | null;
}

export enum SearchClaimStatus {
  ShowAll = 0,
  ShowClosedOnly = 1,
  ShowOpenReopenOnly = 2,
}

export enum SearchSeverity {
  Any = 0,
  LT = 1,
  MO = 2,
  AM = 3,
  MO_AM = 4,
}
