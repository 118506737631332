import React, { useState, useEffect } from 'react';
import { Form as RFFForm, Field } from 'react-final-form';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import FieldBSRenderText from '../../Common/FieldBSRenderText';

import styles from './index.module.css';
import FieldBSRenderCheckbox from '../../Common/FieldBSRenderCheckbox';
import { useAppDispatch, useAppSelector } from '../../../Reducers/Store';
import { Claim } from '../../../ApiTypes/Claim';
import { parseDatesForServer } from '../../../Utils';
import FieldBSRenderDate from '../../Common/FieldBSRenderDate';
import FieldBSRenderTextArea from '../../Common/FieldRenderTextArea';
import { toast } from 'react-toastify';
import ClaimApi from '../../../Api/ClaimApi';
import {
  composeValidators,
  centsValidation,
} from '../../../Utils/FieldValidation';
import { formatNumbers, cleanMoney } from '../../../Utils/InputFormatters';
import FieldBSRenderMoney from '../../Common/FieldBSRenderMoney';
import { fetchFullClaimByClaimNumber } from '../../../Actions/ClaimActions';

export default function FroiTab({ claimType }: { claimType: number }) {
  let { claimNumber } = useParams();
  const dispatch = useAppDispatch();

  const { claim } = useAppSelector((state) => state.currentClaimReducer);

  const onSubmit = (values: Claim) => {
    if (values.claimNo) {
      return ClaimApi.updateClaim(values)
        .then((res) => {
          if (res.data.success) {
            toast.success('Claim Saved!', { autoClose: 1000 });
            dispatch(fetchFullClaimByClaimNumber(claimNumber ?? ''));
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className='w-100 h-100'>
      <RFFForm
        onSubmit={onSubmit}
        initialValues={claim ?? {}}
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit} className={`${styles.formWidth}`}>
              <div className={`${styles.formRowContainer}`}>
                <div className='d-flex flex-column'>
                  <div className={`${styles.formRow}`}>
                    <Field
                      name='timeOfInjury'
                      label='Time of Injury'
                      type='text'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='isAm'
                      type='radio'
                      label='AM'
                      value='true'
                      checked={values?.isAm?.toString() == 'true'}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='isAm'
                      type='radio'
                      label='PM'
                      value='false'
                      checked={values?.isAm?.toString() == 'false'}
                      component={FieldBSRenderCheckbox}
                    />
                  </div>
                  <div className={`${styles.formRow2}`}>
                    <Field
                      name='locationOfInjury'
                      label='Location of Injury'
                      type='text'
                      component={FieldBSRenderText}
                    />
                  </div>
                  <div className={`${styles.formRow}`}>
                    <Field
                      name='dateEmployeeStoppedWorking'
                      type='text'
                      label='First Day of Work Missed'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                    <Field
                      name='probationary'
                      type='checkbox'
                      label='Probationary?'
                      checked={!!values?.probationary}
                      component={FieldBSRenderCheckbox}
                    />
                  </div>
                </div>
                <div className='d-flex flex-column'>
                  <div className={`${styles.formRow} ${styles.vFieldHeight}`}>
                    <Field
                      name='injuryOnPremies'
                      type='checkbox'
                      label='Injury on Employer Premises'
                      checked={!!values?.injuryOnPremies}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='fullWagesPaidOnDoi'
                      type='checkbox'
                      label='Full Pay on Date of Injury'
                      checked={!!values?.fullWagesPaidOnDoi}
                      component={FieldBSRenderCheckbox}
                    />
                  </div>
                  <div className={`${styles.formRow}`}>
                    <Field
                      name='rtwDate'
                      type='text'
                      label='Returned to Work Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                    <Field
                      name='returnWage'
                      label='Returned to Work Wage'
                      type='text'
                      format={formatNumbers}
                      parse={cleanMoney}
                      validate={composeValidators(centsValidation)}
                      component={FieldBSRenderMoney}
                    />
                  </div>
                  <div className={`${styles.formRow}`}>
                    <Field
                      name='dateOfDeath'
                      type='text'
                      label='Date of Death (If Fatal Injury)'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                  </div>
                </div>
              </div>
              <p>Initial Treatment Given (Optional)</p>
              <div className='mb-3'>
                <div
                  className={`d-flex justify-content-start  ${styles.flexGap1rem}`}
                >
                  <Field
                    name='initialTreatmentGiven'
                    type='radio'
                    label='No Initial Treatment'
                    value={0}
                    checked={values?.initialTreatmentGiven?.toString() === '0'}
                    component={FieldBSRenderCheckbox}
                  />
                  <Field
                    name='initialTreatmentGiven'
                    type='radio'
                    label='Minor By Employer'
                    value={1}
                    checked={values?.initialTreatmentGiven?.toString() === '1'}
                    component={FieldBSRenderCheckbox}
                  />
                  <Field
                    name='initialTreatmentGiven'
                    type='radio'
                    label='Minor: Clinical/Hospital'
                    value={2}
                    checked={values?.initialTreatmentGiven?.toString() === '2'}
                    component={FieldBSRenderCheckbox}
                  />
                </div>
                <div
                  className={`d-flex justify-content-start  ${styles.flexGap1rem}`}
                >
                  <Field
                    name='initialTreatmentGiven'
                    type='radio'
                    label='Emergency Room'
                    value={3}
                    checked={values?.initialTreatmentGiven?.toString() === '3'}
                    component={FieldBSRenderCheckbox}
                  />
                  <Field
                    name='initialTreatmentGiven'
                    type='radio'
                    label='Hospitalized > 24 hrs'
                    value={4}
                    checked={values?.initialTreatmentGiven?.toString() === '4'}
                    component={FieldBSRenderCheckbox}
                  />
                </div>
              </div>
              <div className={`${styles.formRow}`}>
                <Field
                  name='treatingPhysician'
                  label='Treating Physician - Name and Address (Optional)'
                  rows={4}
                  component={FieldBSRenderTextArea}
                />
                <Field
                  name='hospitalTreatingFacility'
                  label='Hospital/Treating Facility - Name and Address (Optional)'
                  rows={4}
                  component={FieldBSRenderTextArea}
                />
              </div>
              <div className='d-flex justify-content-center mt-3'>
                <Button type='submit' variant='primary'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
}
