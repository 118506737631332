import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { MedicareClaimResult } from '../ApiTypes/MedicareClaimResult';
import { MedQueryObj } from '../ApiTypes/MedQueryObj';
import { ReportResponse } from '../ApiTypes/ReportRequest';

class MedicareApi {
  static getQueryObjects(insurCoId: number) {
    return axios.get<MedQueryObj[]>(
      `${API_URL}/api/medicares/queries?insurcoid=${insurCoId}`,
      getAuthHeader()
    );
  }
  static sendQuery(queries: MedQueryObj[], newFormat: boolean) {
    return axios.post<ReportResponse>(
      `${API_URL}/api/medicares?newformat=${newFormat}`,
      queries,
      getAuthHeader()
    );
  }
  static getResults() {
    return axios.get<MedicareClaimResult[]>(
      `${API_URL}/api/medicares/results`,
      getAuthHeader()
    );
  }
  static getResult(id: number) {
    return axios.get<MedicareClaimResult>(
      `${API_URL}/api/medicares/results/${id}`,
      getAuthHeader()
    );
  }
}

export default MedicareApi;
