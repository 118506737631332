import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { DoctorsOffice } from '../ApiTypes/DoctorsOffice';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class DoctorOfficeApi {
  static getAll() {
    return axios.get<DoctorsOffice[]>(
      `${API_URL}/api/doctorsoffices`,
      getAuthHeader()
    );
  }
  static getAllWithLocation() {
    return axios.get<DoctorsOffice[]>(
      `${API_URL}/api/doctorsoffices/withLocation`,
      getAuthHeader()
    );
  }
  static getDoctorsOfficeById(id: number) {
    return axios.get<DoctorsOffice>(
      `${API_URL}/api/doctorsoffices/${id}`,
      getAuthHeader()
    );
  }
  static createDoctorsOffice(office: DoctorsOffice) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/doctorsoffices`,
      office,
      getAuthHeader()
    );
  }
  static updateDoctorsOffice(office: DoctorsOffice) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/doctorsoffices/${office.id}`,
      office,
      getAuthHeader()
    );
  }
}

export default DoctorOfficeApi;
