import React, { useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import styles from './index.module.css';

export default function PdfThumbnail({
  base64Data,
  pageNumber,
  currentPageNumber,
  onThumbnailClick,
}: {
  base64Data: string;
  pageNumber: number;
  currentPageNumber: number;
  onThumbnailClick: (pageNumber: number) => void;
}) {
  const canvasRef = useRef(null);

  const { pdfDocument, pdfPage } = usePdf({
    file: JSON.parse(
      JSON.stringify(`data:application/pdf;base64,${base64Data}`)
    ),
    page: pageNumber,
    canvasRef,
    scale: 0.15,
  });
  return (
    <div
      className={
        pageNumber === currentPageNumber
          ? `${styles.pdfThumbnail_selected}`
          : `${styles.pdfThumbnail}`
      }
      key={pageNumber}
      onClick={() => onThumbnailClick(pageNumber)}
    >
      <canvas ref={canvasRef} />
    </div>
  );
}
