import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { ScanDoc } from '../../ApiTypes/ScanDoc';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { displayDateOnly } from '../../Utils';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import { Link } from 'react-router-dom';
import styles from './index.module.css';
import { FaExpand, FaEye } from 'react-icons/fa';
import { toast } from 'react-toastify';
import DocumentsApi from '../../Api/DocumentsApi';
import { DocumentUpdateStatusRequest } from '../../ApiTypes/DocumentUpdateStatusRequest';
import {
  setDocViewFileDownload,
  setShowDocView,
} from '../../Actions/DocViewActions';
import { FileDownload } from '../../Types/FileDownLoad';

export default function MyDocumentTable({
  data,
  getDocs,
}: {
  data: ScanDoc[];
  getDocs: () => void;
}) {
  const dispatch = useAppDispatch();
  const { docStatuses, docTypes, docPriorities } = useAppSelector(
    (state) => state.reference
  );
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const options = ({ scanDoc }: { scanDoc: ScanDoc }) => {
    return (
      <Popover id={`${scanDoc.docId}`}>
        <Popover.Header as='h3'>{scanDoc.note}</Popover.Header>
        <Popover.Body>
          <div className={`${styles.optionsDiv}`}>
            <Link to={`/claims/${scanDoc.claimno}/scanned-documents/all`}>
              <Button
                type='button'
                variant='outline-primary'
                size='sm'
                title='Go to Claim'
                className='button-icon-text'
              >
                <FaEye /> Claim
              </Button>
            </Link>
            <Link
              to={`/scandocs/${scanDoc.docId}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button
                type='button'
                variant='primary'
                size='sm'
                title='View Doc'
                className='button-icon-text'
                onClick={() => {
                  // handleViewDoc(scanDoc);
                  document.body.click();
                }}
              >
                <FaEye /> Doc
              </Button>
            </Link>

            <Button
              type='button'
              variant='primary'
              size='sm'
              title='Send to straight pay'
              onClick={() => {
                handleStraightPay(scanDoc);
                document.body.click();
              }}
            >
              Straight Pay
            </Button>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='Send to CompIQ'
              onClick={() => {
                handleBillReview(scanDoc);
                document.body.click();
              }}
            >
              CompIQ
            </Button>
            <Button
              type='button'
              variant='success'
              size='sm'
              title='Accept'
              onClick={() => {
                handleApprove(scanDoc);
                document.body.click();
              }}
            >
              Accept
            </Button>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  const handleViewDoc = (doc: ScanDoc) => {
    getScanDocFile(doc.claimno ?? '', doc.docId);
  };

  const setShow = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownload = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  const getScanDocFile = (claimNumber: string, docId: number) => {
    return DocumentsApi.getScanDocFile(docId)
      .then((res) => {
        if (res.data.file.length > 1) {
          setFileDownload(res.data);
          setShow(true);
        } else {
          toast.error('Failed to get document');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get document');
      });
  };

  const handleStraightPay = (doc: ScanDoc) => {
    const status = docStatuses.find((f) => f.name === 'Sent To Straight Pay');

    const data: DocumentUpdateStatusRequest = {
      docId: doc.docId,
      docStatusId: status?.keyDocStatusId ?? 0,
      claimNumber: doc.claimno ?? '',
      note: 'Sent to Straight Pay',
    };

    toast('Updating Status', { type: 'info', toastId: doc.docId });

    DocumentsApi.updateStatus(data)
      .then((res) => {
        if (!res.data.success) {
          toast.update(doc.docId, { type: 'error', render: res.data.message });
          return;
        }
        getDocs();
        toast.update(doc.docId, { type: 'success', render: 'Success' });
      })
      .catch((err) => {
        console.log(err);
        toast.update(doc.docId, {
          type: 'error',
          render: 'Failed to update status',
        });
      });
  };

  const handleBillReview = (doc: ScanDoc) => {
    const status = docStatuses.find((f) => f.name === 'Sent to Bill Review');

    const data: DocumentUpdateStatusRequest = {
      docId: doc.docId,
      docStatusId: status?.keyDocStatusId ?? 0,
      claimNumber: doc.claimno ?? '',
      note: 'Sent to Bill Review',
    };

    toast('Updating Status', { type: 'info', toastId: doc.docId });

    DocumentsApi.updateStatus(data)
      .then((res) => {
        if (res.data.success) {
          getDocs();
          toast.update(doc.docId, { type: 'success', render: 'Success' });
        } else {
          toast.update(doc.docId, {
            type: 'error',
            render: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.update(doc.docId, {
          type: 'error',
          render: 'Failed to update status',
        });
      });
  };

  const handleApprove = (doc: ScanDoc) => {
    const status = docStatuses.find((f) => f.name === 'Accepted');

    const data: DocumentUpdateStatusRequest = {
      docId: doc.docId,
      docStatusId: status?.keyDocStatusId ?? 0,
      claimNumber: doc.claimno ?? '',
      note: 'Marked Approved',
    };

    toast('Updating Status', { type: 'info', toastId: doc.docId });

    DocumentsApi.updateStatus(data)
      .then((res) => {
        if (res.data.success) {
          getDocs();
          toast.update(doc.docId, { type: 'success', render: 'Success' });
        } else {
          toast.update(doc.docId, { type: 'error', render: res.data.message });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.update(doc.docId, {
          type: 'error',
          render: 'Failed to update status',
        });
      });
  };

  const getBackGroundColor = (r: ScanDoc) => {
    const priority =
      docPriorities.find((t) => t.keyDocPriorityId === r.priorityId)?.name ??
      'N/A';
    if (priority === 'High') {
      return 'salmon';
    }
    if (priority === 'Moderate') {
      return 'khaki';
    }
    return '';
  };

  const columnData: ColumnDef<ScanDoc>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      size: 50,
      cell: ({ row }) => {
        return (
          <div
            className={`${styles.actionColumn} ${getBackGroundColor(
              row.original
            )}`}
          >
            <OverlayTrigger
              trigger='click'
              placement='right'
              rootClose
              overlay={options({ scanDoc: row.original })}
            >
              <Button type='button' size='sm' variant='primary' title='View'>
                <FaExpand />
              </Button>
            </OverlayTrigger>
          </div>
        );
      },
    },
    {
      header: 'Doc Name',
      accessorKey: 'note',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claim Number',
      accessorKey: 'claimno',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claimant',
      accessorKey: 'claimantName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date',
      accessorKey: 'date',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.date ?? ''),
      maxSize: 100,
    },
    {
      header: 'Type',
      accessorFn: (d) =>
        docTypes.find((t) => t.docTypeId === d.doctypeid)?.description ?? 'N/A',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Status',
      accessorFn: (d) =>
        docStatuses.find((t) => t.keyDocStatusId === d.statusId)?.name ?? 'N/A',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Priority',
      accessorFn: (d) =>
        docPriorities.find((t) => t.keyDocPriorityId === d.priorityId)?.name ??
        'N/A',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(
    () => columnData,
    [docStatuses, docTypes, docPriorities]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
      selectableRow={true}
      highlightRow={true}
    />
  );
}
