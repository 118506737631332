import React from 'react';
import { toast } from 'react-toastify';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import FormFroi from './FormFroi';
import FormFroiDenial from './FormFroiDenial';
import FormSroi from './FormSroi';
import FormSroi04 from './FormSroi04';
import FormSroiFn from './FormSroiFN';
import FormSroiPd from './FormSroiPd';
import FormSroiPy from './FormSroiPy';
import FormSroiS1 from './FormSroiS1';
import FormSroiEp from './FormSroiEp';

export default function GetFormForNewTransaction({
  onFormSubmit,
  closeModal,
  ipAlreadySent,
  epAlreadySent,
  formType,
  maintTypeCode,
  benefitTypeCode,
  severity,
}: {
  onFormSubmit: (values: EdiTransaction) => Promise<void>;
  closeModal: () => void;
  ipAlreadySent: boolean;
  epAlreadySent: boolean;
  formType: string;
  maintTypeCode: string;
  benefitTypeCode: string;
  severity: string;
}) {
  if (formType === 'FROI') {
    if (maintTypeCode === '00' || maintTypeCode === '01') {
      if (severity === 'MO' || severity === 'AM') {
        //just Add the transaction
        const t: EdiTransaction = {
          id: 0,
          severity: severity,
          maintTypeCode,
          benefitTypeCode,
          form: formType,
          claimno: '',
          submitDate: '',
          submitStatus: 0,
          receiveDate: null,
          receiveStatus: null,
          disabilitydate: null,
          avgWeeklyWage: null,
          weeklyBenefitAmt: null,
          benefitStartDate: null,
          benefitThroughDate: null,
          fullWagesPaidOnDoi: null,
          empPaidSalaryInLieuOfCompensation: null,
          initialReturnToWorkDate: null,
          benefitSuspendDate: null,
          fullDenialReasonCode: null,
          fullDenialEffectiveDate: null,
          partialDenialReasonCode: null,
          denialNarrative: null,
          ackId: null,
          dn0111: null,
          transSetId: null,
          wc4id: null,
          fileType: null,
          benefitCheckDate: null,
          wagePeriodCode: null,
          daysWorkedPerWeek: null,
          lastDayWorked: null,
          dateClaimWentLostTime: null,
          hireDate: null,
          initialTreatmentCode: null,
          dateOfDeath: null,
          dateEmployerHadKnowledge: null,
          dateClaimantAttyNotified: null,
          numberOfPermantImpairments: null,
          dateOfMaxMedImprovement: null,
          numberOfDependents: null,
          numberOfDeathDependentRelationships: null,
          permanentImpairmentMinPaymentIndicator: null,
          anticipatedWageLossIndicator: null,
          physicalRestrictionsIndicator: null,
          releaseSsnindicator: null,
          rtwsameEmployerIndicator: null,
          releaseMedsIndicator: null,
          paymentReasonCode: null,
          paymentAmount: null,
          ppdimpairmentBodyPartCode: null,
          ppdimpairmentWeeks: null,
          penaltyPercentage: null,
          penaltyAmount: null,
          permImpairmentPercentage: null,
          dependentPayeeRelationshipCode: null,
          employeePaidSalaryPriorToAquisitionCode: null,
          workWeekTypeCode: null,
          workDaysScheduledCode: null,
          employeeSecurityId: null,
          ppdimpairmentDays: null,
          lumpSumPaymentReasonCode: null,
          suspensionCheck0: null,
          suspensionCheck1: null,
          suspensionCheck2: null,
          suspensionCheck3: null,
          suspensionCheck4: null,
          suspensionCheck5: null,
          suspensionCheck6: null,
          suspensionCheck7: null,
          suspensionCheck8: null,
          suspensionCheck9: null,
          suspensionCheck10: null,
          suspensionCheck11: null,
          suspensionReducedPay: null,
          suspensionOther: null,
          dateSalaryPaid: null,
          wc3bcheck1: null,
          wc3bcheck2: null,
          wc3bcheck3: null,
          controvertCopySent: null,
          currentBenefitEndDate: null,
          changeEdiTransactionId: null,
          priorEdiTransactionId: null,
          ediStatus: null,
          ediTransactionLogs: null,
          eventDate: '',
          notes: '',
          logStatusString: '',
          logCreator: '',
          logDate: null,
          submitStatusString: '',
          benefitTypeName: '',
          amountOwed: null,
          amountPaid: null,
          overUnderPayment: null,
          weeksOwed: null,
          weeksPaid: null,
          claimantFullName: null,
          claim: null,
          claimant: null,
        };
        onFormSubmit(t);
        closeModal();
      }
      //launch the Original WC1 Screen.
      return (
        <FormFroi
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else if (maintTypeCode === '02') {
      //launch the Original WC1 Screen...BUT Prob should be different.
      return (
        <FormFroi
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else if (maintTypeCode === '04') {
      //launch the Original WC1 Screen.
      return (
        <FormFroiDenial
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else if (maintTypeCode === 'AQ') {
      //launch the Original WC1 Screen...BUT Prob should be different.
      return (
        <FormFroi
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else if (maintTypeCode === 'AU') {
      //launch the Original WC1 Screen...BUT Prob should be different.
      return (
        <FormFroi
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else {
      // fall through
      return (
        <FormFroi
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    }
  } else {
    if (maintTypeCode === '02') {
      return (
        <FormSroi
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType ?? ''}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else if (maintTypeCode === '04') {
      return (
        <FormSroi04
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType ?? ''}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else if (maintTypeCode === 'IP') {
      if (severity !== 'LT') {
        toast.warn(
          'Note: SROI IP should only be used on LT Claims.  Please be sure you are filing the correct form.  See Carla for details.'
        );
      }
      return (
        <FormSroi
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType ?? ''}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else if (maintTypeCode === 'PY') {
      if (severity !== 'LT') {
        toast.warn(
          'Note: SROI PY should only be used on LT Claims.  Please be sure you are filing the correct form.  See Carla for details.'
        );
      }
      return (
        <FormSroiPy
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType ?? ''}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else if (maintTypeCode === 'CB') {
      if (severity !== 'LT') {
        toast.warn(
          'Note: SROI CB should only be used on LT Claims.  Please be sure you are filing the correct form.  See Carla for details.'
        );
      }
      return (
        <FormSroi
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType ?? ''}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else if (
      maintTypeCode === 'RB' ||
      maintTypeCode === 'CO' ||
      maintTypeCode === 'CA'
    ) {
      if (severity !== 'LT') {
        toast.warn(
          'Note: SROI Reinstatements should only be used on LT Claims.  Please be sure you are filing the correct form.  See Carla for details.'
        );
      }
      return (
        <FormSroi
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType ?? ''}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else if (maintTypeCode === 'RE') {
      if (severity !== 'LT') {
        toast.warn(
          'Note: SROI RE should only be used on LT Claims.  Please be sure you are filing the correct form.  See Carla for details.'
        );
      }
      return (
        <FormSroi
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType ?? ''}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else if (
      maintTypeCode === 'S1' ||
      maintTypeCode === 'S2' ||
      maintTypeCode === 'S3' ||
      maintTypeCode === 'S4' ||
      maintTypeCode === 'S5' ||
      maintTypeCode === 'S6' ||
      maintTypeCode === 'S7'
    ) {
      if (severity !== 'LT') {
        toast.warn(
          'Note: SROI Suspensions should only be used on LT Claims.  Please be sure you are filing the correct form.  See Carla for details.'
        );
      }
      //launch the Original WC1 Screen.
      return (
        <FormSroiS1
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType ?? ''}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else if (maintTypeCode === 'FN' || maintTypeCode === 'AN') {
      return (
        <FormSroiFn
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType ?? ''}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else if (maintTypeCode === 'EP') {
      if (severity !== 'LT') {
        toast.warn(
          'Note: SROI EP should only be used on LT Claims.  Please be sure you are filing the correct form.  See Carla for details.'
        );
      }
      return (
        <FormSroiEp
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType ?? ''}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else if (maintTypeCode === 'ER') {
      if (severity !== 'LT') {
        toast.warn(
          'Note: SROI ER should only be used on LT Claims.  Please be sure you are filing the correct form.  See Carla for details.'
        );
      }
      return (
        <FormSroi
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType ?? ''}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else if (maintTypeCode === 'PD') {
      return (
        <FormSroiPd
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType ?? ''}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else if (maintTypeCode === 'PY') {
      if (severity !== 'LT') {
        toast.warn(
          'Note: SROI PY should only be used on LT Claims.  Please be sure you are filing the correct form.  See Carla for details.'
        );
      }
      return (
        <FormSroi
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType ?? ''}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else if (maintTypeCode === 'SA') {
      return (
        <FormSroi
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType ?? ''}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else if (maintTypeCode === 'AP') {
      if (severity !== 'LT') {
        toast.warn(
          'Note: SROI AP should only be used on LT Claims.  Please be sure you are filing the correct form.  See Carla for details.'
        );
      }
      return (
        <FormSroi
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType ?? ''}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    } else {
      // fall through
      return (
        <FormSroi
          transaction={null}
          onFormSubmit={onFormSubmit}
          closeModal={closeModal}
          formType={formType ?? ''}
          maintTypeCode={maintTypeCode}
          benefitTypeCode={benefitTypeCode}
        />
      );
    }
  }
}
