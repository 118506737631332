import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import RecoveryApi from '../../../Api/RecoveryApi';
import ReservesApi from '../../../Api/ReservesApi';
import { ClaimReserveBuckets } from '../../../ApiTypes/ClaimReserveBuckets';
import { SaveGemcexcessTransaction } from '../../../ApiTypes/SaveGemcexcessTransaction';
import { useAppSelector } from '../../../Reducers/Store';
import RecoveryTable from './RecoveryTable';

import styles from './index.module.css';
import { FaPlusCircle } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import EditCreateRecovery from '../../EditCreateRecovery/EditCreateRecovery';
import DeleteConfirmationModal from '../../Common/DeleteConfirmationModal';
import { toast } from 'react-toastify';

export default function RecoveriesTab({ claimType }: { claimType: number }) {
  let { claimNumber } = useParams();
  const [recoveries, setRecoveries] = useState<SaveGemcexcessTransaction[]>([]);
  const [reserveBuckets, setReserveBuckets] = useState<ClaimReserveBuckets[]>(
    []
  );
  const [showEditCreate, setShowEditCreate] = useState<boolean>(false);
  const [selectedRecovery, setSelectedRecovery] =
    useState<SaveGemcexcessTransaction | null>(null);
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const { claim } = useAppSelector((state) => state.currentClaimReducer);

  const getTransactionsForClaim = () => {
    if (claimNumber) {
      RecoveryApi.getTransactionsForClaim(claimNumber, claimType)
        .then((res) => {
          setRecoveries(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getReservesForClaim = () => {
    if (claimNumber) {
      ReservesApi.getClaimReserveBucketsForClaim(claimNumber)
        .then((res) => {
          setReserveBuckets(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getTransactionsForClaim();
    getReservesForClaim();
  }, [claimNumber]);

  const handleDelete = () => {
    if (selectedRecovery && claimNumber) {
      return RecoveryApi.deleteTransaction(claimNumber, selectedRecovery.txid)
        .then((res) => {
          if (res.data.success) {
            setSelectedRecovery(null);
            setShowDelete(false);
            getTransactionsForClaim();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to delete transaction');
        });
    }
    return Promise.resolve();
  };

  const handleDeleteSelect = (r: SaveGemcexcessTransaction) => {
    setSelectedRecovery(r);
    setShowDelete(true);
  };

  return (
    <div>
      <div className={`${styles.checkRow} mb-2`}>
        <Button
          type='button'
          variant='primary'
          size='sm'
          onClick={() => {
            setSelectedRecovery(null);
            setShowEditCreate(true);
          }}
          className='button-icon-text ms-3'
        >
          <FaPlusCircle /> New
        </Button>
      </div>
      <RecoveryTable
        recoveries={recoveries}
        reserveBuckets={reserveBuckets}
        setSelectedRecovery={setSelectedRecovery}
        selectedRecovery={selectedRecovery}
        setShowEditCreate={setShowEditCreate}
        handleDeleteSelect={handleDeleteSelect}
      />
      <EditCreateRecovery
        show={showEditCreate}
        setShow={setShowEditCreate}
        selectedPayment={selectedRecovery}
        getTransactionsForClaim={getTransactionsForClaim}
        getReservesForClaim={getReservesForClaim}
      />
      <DeleteConfirmationModal
        show={showDelete}
        setShow={setShowDelete}
        setNull={setSelectedRecovery}
        handleDelete={handleDelete}
      />
    </div>
  );
}
