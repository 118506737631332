import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import { FaPlusCircle, FaUser } from 'react-icons/fa';
import PageScaffold from '../PageScaffold/PageScaffold';
import { useParams } from 'react-router-dom';
import { UserInsuranceCompanyAccess } from '../../ApiTypes/UserInsuranceCompanyAccess';
import UserInsuranceCompanyAccessApi from '../../Api/UserInsuranceCompanyAccessApi';
import { toast } from 'react-toastify';
import { EntityUser } from '../../ApiTypes/EntityUser';
import UserApi from '../../Api/UserApi';
import UserAccessTable from './UserAccessTable';
import { Button } from 'react-bootstrap';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import AddEditAccessModal from './AddEditAccessModal';
import UserAccessEmployerModal from './UserAccessEmployerModal';

export default function EditUserAccess() {
  const { userId } = useParams();

  const [userAccess, setUserAccess] = useState<UserInsuranceCompanyAccess[]>(
    []
  );
  const [user, setUser] = useState<EntityUser | null>(null);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [selectedAccess, setSelectedAccess] =
    useState<UserInsuranceCompanyAccess | null>(null);

  const [showAddEdit, setShowAddEdit] = useState<boolean>(false);
  const [showLimit, setShowLimit] = useState<boolean>(false);

  useEffect(() => {
    getUserAccess();
    getUser();
  }, [userId]);

  const getUserAccess = () => {
    UserInsuranceCompanyAccessApi.getAccessForUser(userId!)
      .then((res) => setUserAccess(res.data))
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get user access');
      });
  };

  const getUser = () => {
    UserApi.getById(userId!)
      .then((res) => {
        setUser(res.data);
        getUserAccess();
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get user');
      });
  };

  const handleDelete = (access: UserInsuranceCompanyAccess) => {
    setSelectedAccess(access);
    setShowDelete(true);
  };

  const deleteAccess = () => {
    if (selectedAccess) {
      return UserInsuranceCompanyAccessApi.delete(selectedAccess.id)
        .then((res) => {
          if (res.data.success) {
            getUserAccess();
          } else {
            toast.error(res.data.message);
          }
          setShowDelete(false);
        })
        .catch((err) => {
          console.log(err);
          setShowDelete(false);
          toast.error('Failed to delete access');
        });
    }

    return Promise.resolve();
  };

  const handleEdit = (a: UserInsuranceCompanyAccess) => {
    setSelectedAccess(a);
    setShowAddEdit(true);
  };
  const handleNew = () => {
    setSelectedAccess(null);
    setShowAddEdit(true);
  };

  const updateAccess = (userId: string, insurCoId: number) => {
    return UserInsuranceCompanyAccessApi.updateAccess(userId, insurCoId)
      .then((res) => {
        if (res.data.success) {
          setShowAddEdit(false);
          getUserAccess();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update access');
      });
  };

  const handleLimit = (a: UserInsuranceCompanyAccess) => {
    setSelectedAccess(a);
    setShowLimit(true);
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaUser className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>User Access</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center py-3'>
        <Button
          type='button'
          variant='primary'
          size='sm'
          className='button-icon-text'
          onClick={handleNew}
        >
          <FaPlusCircle /> User Access
        </Button>
      </div>
      <UserAccessTable
        data={userAccess}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handleLimit={handleLimit}
      />
      <DeleteConfirmationModal
        show={showDelete}
        setShow={setShowDelete}
        setNull={setSelectedAccess}
        handleDelete={deleteAccess}
      />
      <>
        {showAddEdit && (
          <AddEditAccessModal
            show={showAddEdit}
            setShow={setShowAddEdit}
            id={selectedAccess?.id ?? null}
            userId={userId!}
            userName={user?.userName ?? ''}
            handleSubmit={updateAccess}
          />
        )}
        {showLimit && (
          <UserAccessEmployerModal
            show={showLimit}
            setShow={setShowLimit}
            userAccessId={selectedAccess?.id!}
          />
        )}
      </>
    </PageScaffold>
  );
}
