import { addDays, addSeconds, format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaFileExcel } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ReportApi from '../../Api/ReportApi';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { useAppSelector } from '../../Reducers/Store';
import { downloadExcel, parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import styles from './index.module.css';

interface PremiumAndLossSummaryFormValues {
  insurcoid: number;
  treatyYear: number;
  asOf: string;
  action: string;
}

export default function FundAnalysisModal({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );
  const [years, setYears] = useState<number[]>([]);

  useEffect(() => {
    loadYears();
  }, []);

  const loadYears = () => {
    var ys: number[] = [];
    var thisYear = new Date().getFullYear();
    for (let i = 0; i <= 30; i++) {
      ys.push(thisYear - i);
    }
    setYears(ys);
  };

  const onSubmit = (values: PremiumAndLossSummaryFormValues) => {
    return ReportApi.getFundAnalysis({
      insurCoId: values.insurcoid,
      startYear: values.treatyYear,
      asOf: values.asOf,
    })
      .then((res) => {
        if (res.data.fileName === 'Failed') {
          toast.error('Failed to get fund analysis');
        } else {
          downloadExcel(res.data.fileName, res.data.file);
          setShow(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed  to get fund analysis');
      });
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='FundAnalysisModal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='FundAnalysisModal'>
          <FaFileExcel className='pe-1' /> Fund Analysis Report
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              treatyYear: new Date().getFullYear() - 10,
              asOf: format(
                addSeconds(
                  addDays(new Date(), -(new Date().getDate() - 1)),
                  -1
                ),
                'yyyy-MM-dd'
              ),
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='asOf'
                    type='text'
                    label='As of'
                    parse={parseDatesForServer}
                    validate={requiredField}
                    component={FieldBSRenderDate}
                  />
                  <Field
                    name='insurcoid'
                    label='Account'
                    options={insuranceCompaniesForUser}
                    optionMethod={(options: InsuranceCompany[]) =>
                      options.map((o) => (
                        <option key={o.insurcoid} value={o.insurcoid}>
                          {`${o.accountnumber} - ${o.shortname}`}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='treatyYear'
                    label='Start Year'
                    options={years}
                    optionMethod={(options: number[]) =>
                      options.map((o) => (
                        <option key={o} value={o}>
                          {o}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <div className='d-flex justify-content-between align-items-center'>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Generate'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
