import React from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import styles from './index.module.css';
import PolicyClassCodes from './PolicyClassCodes';

export default function PolicyClassCodesModal({
  show,
  setShow,
  policyId,
  isExcess,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  policyId: number;
  isExcess: boolean;
}) {
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='PolicyClassCodesModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='PolicyClassCodesModal-modal'
        >
          <FaCheck className='pe-1' />
          {policyId > 0 ? 'Policy' : ''} Class Codes
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <PolicyClassCodes policyId={policyId} isExcess={isExcess} />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
