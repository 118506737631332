import React, { useMemo, useState, useEffect } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import IndeterminateCheckbox from '../Common/IndeterminateCheckbox';
import styles from './index.module.css';
import { Link } from 'react-router-dom';
import { displayDateOnly } from '../../Utils';
import accounting from 'accounting';
import { ClaimReservesWorksheetObj } from '../../ApiTypes/ClaimReservesWorksheetObj';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../Reducers/Store';
import { getDxReport } from '../DocViewModal/useDocViewModal';
import {
  setShowDocView,
  setDocViewFileDownload,
} from '../../Actions/DocViewActions';
import { FileDownload } from '../../Types/FileDownLoad';
import {
  DevExpressReportRequest,
  DxAvailableReports,
} from '../../ApiTypes/DevExpressReportRequest';
import { requestDx } from '../DxReportRequestModal/useDxReportRequestModal';

export default function ApproveReservesTable({
  data,
  handleEdit,
  radioType,
  selectedSheets,
  setSelectedSheets,
}: {
  data: ClaimReservesWorksheetObj[];
  radioType: string;
  handleEdit: (wsObj: ClaimReservesWorksheetObj) => void;
  selectedSheets: ClaimReservesWorksheetObj[];
  setSelectedSheets: (sheets: ClaimReservesWorksheetObj[]) => void;
}) {
  const dispatch = useAppDispatch();
  const [rowSelection, setRowSelection] = React.useState({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  const handleRowSelectionChange = () => {
    setSelectedSheets(
      table.getSelectedRowModel().flatRows.map((r) => r.original)
    );
  };

  const setShow = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownload = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  const columnData: ColumnDef<ClaimReservesWorksheetObj>[] = [
    {
      header: ({ table }) => (
        <div className={`${styles.actionColumn}`}>
          {Object.keys(rowSelection).length}
          <IndeterminateCheckbox
            className='form-check-input'
            {...{
              id: 'worksheetSelectionTotal',
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      id: 'options',
      enableSorting: false,
      enableColumnFilter: false,
      size: 300,
      cell: ({ row }) => {
        return (
          <div className={`${styles.actionColumn}`}>
            <IndeterminateCheckbox
              className='form-check-input'
              {...{
                id: row.id,
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
            <Link to={`/claims/${row.original.claimNo}`}>
              <Button
                type='button'
                size='sm'
                variant='primary'
                title='View Claim'
              >
                View Claim
              </Button>
            </Link>
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='View Worksheet'
              onClick={() => {
                const request: DevExpressReportRequest = {
                  ...requestDx,
                  report: DxAvailableReports.rptReserveWorksheet,
                  reserveWorksheetId: row.original.id,
                  insurCoId: 1,
                };
                getDxReport(request, setFileDownload, setShow);
              }}
            >
              View WS
            </Button>
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='Edit/Approve Worksheet'
              disabled={radioType === '4'}
              onClick={() => {
                handleEdit(row.original);
              }}
            >
              Edit/Approve WS
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Claim #',
      accessorKey: 'claimNo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claimant',
      accessorKey: 'claimantName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Submitter',
      accessorKey: 'submitter',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date Submitted',
      accessorKey: 'date',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dateSubmitted ?? ''),
      maxSize: 100,
    },
    {
      header: 'New Reserves Total',
      accessorFn: (d) => `${d.newReserves}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) => accounting.formatMoney(d.getValue() as string),
    },
    {
      header: 'New Indemnity',
      accessorFn: (d) => `${d.indemnityRounded}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
      cell: (d) => accounting.formatMoney(d.getValue() as number),
    },
    {
      header: 'New Medical',
      accessorFn: (d) => `${d.medicalRounded}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
      cell: (d) => accounting.formatMoney(d.getValue() as number),
    },
    {
      header: 'New Legal',
      accessorFn: (d) => `${d.legalRounded}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
      cell: (d) => accounting.formatMoney(d.getValue() as number),
    },
    {
      header: 'New Expense',
      accessorFn: (d) => `${d.expenseRounded}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
      cell: (d) => accounting.formatMoney(d.getValue() as number),
    },
    {
      header: 'Reserve Difference',
      accessorFn: (d) => `${d.reserveDifference}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
      cell: (d) => accounting.formatMoney(d.getValue() as number),
    },
  ];

  const columns = useMemo(() => columnData, [radioType, rowSelection]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
