import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaRegFileWord } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CompCompleteApi from '../../Api/CompCompleteApi';
import DocumentsApi from '../../Api/DocumentsApi';
import { CompCompleteDoc } from '../../ApiTypes/CompCompleteDoc';
import { DocumentStatus } from '../../ApiTypes/DocumentStatus';
import { DocumentUpdateStatusRequest } from '../../ApiTypes/DocumentUpdateStatusRequest';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { useAppSelector } from '../../Reducers/Store';
import BSControlledSelect from '../Common/BSControlledSelect';
import DocViewModal from '../DocViewModal/DocViewModal';
import { useDocViewModal } from '../DocViewModal/useDocViewModal';
import PageScaffold from '../PageScaffold/PageScaffold';
import AddNoteModal from './AddNoteModal';
import CompCompleteTable from './CompCompleteTable';
import styles from './index.module.css';

export default function CompCompleteProcessing() {
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );

  const [fetching, setFetching] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<number>(0);
  const [statusId, setStatusId] = useState(7);
  const [docs, setDocs] = useState<CompCompleteDoc[]>([]);
  const [selectedDoc, setSelectedDoc] = useState<CompCompleteDoc | null>(null);
  const [rowSelection, setRowSelection] = React.useState({});
  const [showAddNotes, setShowAddNotes] = useState<boolean>(false);

  const {
    showDocViewModal,
    fileDownload,
    setShowDocViewModal,
    setFileDownload,
  } = useDocViewModal();

  type docType = {
    description: string;
    id: number;
  };
  const docTypes: docType[] = [
    {
      description: 'New',
      id: DocumentStatus.SentToCompIQ,
    },
    {
      description: 'In Process',
      id: DocumentStatus.CompIQPending,
    },
  ];

  useEffect(() => {
    getDocuments();
  }, [selectedAccount, statusId]);

  useEffect(() => {
    const account =
      insuranceCompaniesForUser.length > 0
        ? insuranceCompaniesForUser[0]
        : null;
    if (account) {
      setSelectedAccount(account.insurcoid);
    }
  }, [insuranceCompaniesForUser]);

  const handleAccountChange = (value: string) => {
    const insurcoid = value ? +value : 0;
    setSelectedAccount(insurcoid);
  };

  const getDocuments = () => {
    if (statusId) {
      setFetching(true);
      CompCompleteApi.getCompCompleteProcessing(statusId, selectedAccount)
        .then((res) => {
          setDocs(res.data);
          setFetching(false);
        })
        .catch((err) => {
          console.log(err);
          setFetching(false);
          toast.error('Failed to get documents');
        });
    }
  };

  const handleViewDoc = () => {
    if (selectedDoc) {
      getDocFile(selectedDoc.docId);
    }
  };

  const getDocFile = (docId: number) => {
    return CompCompleteApi.getCompCompleteDocFile(docId)
      .then((res) => {
        if (res.data.file.length > 1) {
          setFileDownload(res.data);
          setShowDocViewModal(true);
        } else {
          toast.error('Failed to get document');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get document');
      });
  };
  const handleMissingInfo = (notes: string) => {
    if (selectedDoc) {
      const data: DocumentUpdateStatusRequest = {
        docId: selectedDoc.docId,
        docStatusId: DocumentStatus.CompIQMissingInfo,
        claimNumber: selectedDoc.claimno ?? '',
        note: `Bill Review Missing Info: ${notes}`,
      };
      setShowAddNotes(false);
      return updateDoc(data);
    }
  };

  const handleDuplicate = () => {
    if (selectedDoc) {
      const data: DocumentUpdateStatusRequest = {
        docId: selectedDoc.docId,
        docStatusId: DocumentStatus.CompIQDuplicate,
        claimNumber: selectedDoc.claimno ?? '',
        note: 'Bill Review already processed this bill, and has marked it as duplicate.',
      };
      return updateDoc(data);
    }
  };
  const notCompIq = () => {
    if (selectedDoc) {
      const data: DocumentUpdateStatusRequest = {
        docId: selectedDoc.docId,
        docStatusId: DocumentStatus.CompIQDenied,
        claimNumber: selectedDoc.claimno ?? '',
        note: 'Bill Review did not recognize this as a valid Bill',
      };
      return updateDoc(data);
    }
  };
  const handleSendToStraightPay = () => {
    if (selectedDoc) {
      const data: DocumentUpdateStatusRequest = {
        docId: selectedDoc.docId,
        docStatusId: DocumentStatus.SentToStraightPay,
        claimNumber: selectedDoc.claimno ?? '',
        note: 'Sent to Straight Pay from Bill Review',
      };
      return updateDoc(data);
    }
  };
  const markAsComplete = () => {
    if (selectedDoc) {
      const data: DocumentUpdateStatusRequest = {
        docId: selectedDoc.docId,
        docStatusId: DocumentStatus.CompIQApproved,
        claimNumber: selectedDoc.claimno ?? '',
        note: 'Marked Approved By Bill Review',
      };
      return updateDoc(data);
    }
  };

  const updateDoc = (request: DocumentUpdateStatusRequest) => {
    toast('Updating Status', { type: 'info', toastId: request.docId });

    DocumentsApi.updateStatus(request)
      .then((res) => {
        if (!res.data.success) {
          toast.update(request.docId, {
            type: 'error',
            render: res.data.message,
          });
          return;
        }
        getDocuments();
        toast.update(request.docId, {
          type: 'success',
          render: 'Success',
        });
      })
      .catch((err) => {
        console.log(err);
        toast.update(request.docId, {
          type: 'error',
          render: 'Failed to update status',
        });
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.header}`}>
        <div className={`${styles.pageTop} bg-light`}>
          <div className='ps-3 d-flex'>
            <FaRegFileWord className='fs-1 text-primary mt-1' />
            <div className='ms-3'>
              <h1>CompComplete Processing</h1>
              <p>View and approve documents sent to CompIQ for payment</p>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-center align-items-center gap1Rem'>
          <BSControlledSelect
            name='account'
            label='Account'
            onChange={handleAccountChange}
            value={selectedAccount}
            options={insuranceCompaniesForUser}
            optionMethod={(options: InsuranceCompany[]) =>
              options.map((o) => (
                <option key={o.insurcoid} value={o.insurcoid}>
                  {`${o.accountnumber} - ${o.shortname}`}
                </option>
              ))
            }
          />
          <BSControlledSelect
            name='statusId'
            label='Status'
            onChange={setStatusId}
            value={statusId}
            options={docTypes}
            optionMethod={(options: docType[]) =>
              options.map((o) => (
                <option key={o.id} value={o.id}>
                  {o.description}
                </option>
              ))
            }
          />
          <Button
            type='button'
            variant='primary'
            size='sm'
            onClick={getDocuments}
          >
            {fetching ? (
              <Spinner
                as='span'
                animation='grow'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              'Refresh'
            )}
          </Button>
        </div>
        <div className={`${styles.content}`}>
          <CompCompleteTable
            getDocs={getDocuments}
            data={docs}
            setSelectedDoc={setSelectedDoc}
            setRowSelection={setRowSelection}
            rowSelection={rowSelection}
          />
        </div>
        <div className={`${styles.footer} gap1Rem mt-5 `}>
          <Button
            type='button'
            variant='outline-primary'
            size='sm'
            onClick={() => {
              // handleViewDoc();
              if (selectedDoc) {
                document.body.click();
                window.open(
                  `/scandocs/${selectedDoc?.docId}`,
                  `newwindow${selectedDoc?.docId.toString()}`,
                  'width=750,height=750'
                );
              } else {
                toast.error('Please select a document');
              }
            }}
          >
            View Document
          </Button>
          <Link to={selectedDoc ? `/claims/${selectedDoc?.claimno}` : ''}>
            <Button type='button' variant='outline-primary' size='sm'>
              Open Claim
            </Button>
          </Link>
          <Button
            type='button'
            variant='outline-primary'
            size='sm'
            onClick={() => {
              handleDuplicate();
            }}
          >
            Duplicate Bill
          </Button>
          <Button
            type='button'
            variant='outline-primary'
            size='sm'
            onClick={() => {
              notCompIq();
            }}
          >
            Not CompIQ
          </Button>
          <Button
            type='button'
            variant='outline-primary'
            size='sm'
            onClick={() => {
              if (selectedDoc) {
                setShowAddNotes(true);
              }
            }}
          >
            Missing Info
          </Button>
          <Button
            type='button'
            variant='outline-primary'
            size='sm'
            onClick={() => {
              handleSendToStraightPay();
            }}
          >
            Send to Straight Pay
          </Button>
          <Button
            type='button'
            variant='outline-primary'
            size='sm'
            onClick={() => {
              markAsComplete();
            }}
          >
            Mark as Complete
          </Button>
        </div>
      </div>

      <DocViewModal
        show={showDocViewModal}
        setShow={setShowDocViewModal}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
      <AddNoteModal
        show={showAddNotes}
        setShow={setShowAddNotes}
        handleSubmit={handleMissingInfo}
      />
    </PageScaffold>
  );
}
