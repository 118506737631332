import React, { useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { FaFolderOpen } from 'react-icons/fa';
import { Form as RFForm, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { useAppSelector } from '../../Reducers/Store';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { downloadExcel, parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import { addDays, format } from 'date-fns';
import EdiTransactionApi from '../../Api/EdiTransactionApi';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import EdiLogTable from './EdiLogTable';
import EditEdiModal from '../EdiForms/EditEdiModal';
import { FormApi } from 'final-form';
import ExportApi from '../../Api/ExportApi';
import { ediExportHelper } from '../EdiDetailModal/EdiDetailExportHelper';

type EdiLogFormType = {
  allAccounts: boolean;
  insCoId: number;
  to: string;
  from: string;
  errorsOnly: boolean;
};

export default function EdiLog() {
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );
  const [transactions, setTransactions] = useState<EdiTransaction[]>([]);
  const [selectedTransaction, setSelectedTransaction] =
    useState<EdiTransaction | null>(null);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  let formInstance: FormApi<EdiLogFormType, Partial<EdiLogFormType>>;

  const onSubmit = (values: EdiLogFormType) => {
    let insCoId = values.allAccounts ? 0 : values.insCoId;
    return EdiTransactionApi.getEdiLogsByDateRange(
      values.from,
      values.to,
      values.errorsOnly,
      insCoId
    )
      .then((res) => {
        const sorted = [
          ...res.data.sort((a, b) => {
            const aDate = new Date(a.logDate ?? '');
            const bDate = new Date(b.logDate ?? '');
            return bDate < aDate ? -1 : bDate > aDate ? 1 : 0;
          }),
        ];

        sorted.forEach((x) => {
          if (x.ediTransactionLogs) {
            x.ediTransactionLogs.sort((a, b) => {
              const aDate = new Date(a.dateTime ?? '');
              const bDate = new Date(b.dateTime ?? '');
              return bDate < aDate ? -1 : bDate > aDate ? 1 : 0;
            });
          }
        });
        setTransactions(sorted);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get logs');
      });
  };
  const closeEditModal = () => {
    setSelectedTransaction(null);
    setShowEdit(false);
  };

  const openEditModal = (t: EdiTransaction) => {
    setSelectedTransaction(t);
    setShowEdit(true);
  };

  const handleExport = () => {
    const data = ediExportHelper(transactions);
    data.fileName = `EDILog.xls`;

    return ExportApi.exportToExcel(data)
      .then((res) => {
        downloadExcel(res.data.fileName, res.data.file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaFolderOpen className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>EDI Transactions</h1>
          </div>
        </div>
      </div>
      <RFForm
        onSubmit={onSubmit}
        initialValues={{
          allAccounts: true,
          to: format(new Date(), 'yyyy-MM-dd'),
          from: format(addDays(new Date(), -30), 'yyyy-MM-dd'),
          errorsOnly: true,
        }}
        render={({ handleSubmit, form, values, submitting }) => {
          formInstance = form;
          return (
            <Form onSubmit={handleSubmit}>
              <div className='d-flex justify-content-center align-items-center gap1Rem'>
                <Field
                  name='allAccounts'
                  label='All Accounts'
                  type='checkbox'
                  checked={values.allAccounts}
                  component={FieldBSRenderCheckbox}
                />

                <Field
                  name='insCoId'
                  label='Account'
                  disabled={values.allAccounts}
                  options={insuranceCompaniesForUser}
                  optionMethod={(options: InsuranceCompany[]) =>
                    options.map((o) => (
                      <option key={o.insurcoid} value={o.insurcoid}>
                        {`${o.accountnumber} - ${o.shortname}`}
                      </option>
                    ))
                  }
                  component={FieldBSRenderSelect}
                />
                <Field
                  name='from'
                  label='From Date'
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                  validate={requiredField}
                />
                <Field
                  name='to'
                  label='To Date'
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                  validate={requiredField}
                />
                <Field
                  name='errorsOnly'
                  label='Errors Only'
                  type='checkbox'
                  checked={values.errorsOnly}
                  component={FieldBSRenderCheckbox}
                />

                <Button type='submit' variant='primary' size='sm'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Refresh'
                  )}
                </Button>

                <Button
                  type='button'
                  variant='outline-primary'
                  size='sm'
                  disabled={transactions.length === 0}
                  onClick={() => {
                    handleExport();
                  }}
                >
                  Export
                </Button>
              </div>
            </Form>
          );
        }}
      />
      <EdiLogTable data={transactions} openEditModal={openEditModal} />
      <EditEdiModal
        show={showEdit}
        handleCloseModal={closeEditModal}
        transaction={selectedTransaction}
        getTransactions={() => formInstance.submit()}
      />
    </PageScaffold>
  );
}
