import React from 'react';
import { Modal } from 'react-bootstrap';
import { FaFileAlt } from 'react-icons/fa';
import { FileDownload } from '../../Types/FileDownLoad';
import JpgComponent from '../JpgComponent/JpgComponent';
import PdfComponent from '../PdfComponent/PdfComponent';
import PngComponent from '../PngComponent/PngComponent';
import { TIFFViewer } from '../TiffComponent/TiffComponent';

import { ScrollContainer } from 'react-indiana-drag-scroll';
import 'react-indiana-drag-scroll/dist/style.css';

import styles from './index.module.css';
import DownloadComponent from '../DownloadComponent/DownloadComponent';

export default function DocViewModal({
  show,
  setShow,
  base64Data,
  fileName,
  setFileDownload,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  base64Data: string;
  fileName: string;
  setFileDownload?: (f: FileDownload | null) => void;
}) {
  const getComponent = (fileDownload: FileDownload) => {
    if (
      fileDownload?.fileName &&
      fileDownload?.fileName.toLowerCase().endsWith('.jpg')
    ) {
      return (
        <JpgComponent
          fileName={fileDownload?.fileName}
          base64Data={fileDownload?.file}
        />
      );
    } else if (
      fileDownload?.fileName &&
      fileDownload?.fileName.toLowerCase().endsWith('.png')
    ) {
      return (
        <PngComponent
          fileName={fileDownload?.fileName}
          base64Data={fileDownload?.file}
        />
      );
    } else if (
      fileDownload?.fileName &&
      fileDownload?.fileName.toLowerCase().endsWith('.pdf')
    ) {
      return (
        <PdfComponent
          base64Data={fileDownload?.file}
          fileName={fileDownload?.fileName}
        />
      );
    } else if (
      fileDownload?.fileName &&
      (fileDownload?.fileName.toLowerCase().endsWith('.tiff') ||
        fileDownload?.fileName.toLowerCase().endsWith('.tif'))
    ) {
      return (
        <TIFFViewer
          base64Data={fileDownload?.file}
          fileName={fileDownload?.fileName}
        />
      );
    } else {
      return (
        <DownloadComponent
          fileName={fileDownload?.fileName}
          base64Data={fileDownload?.file}
        />
      );
    }
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
        if (setFileDownload) {
          setFileDownload(null);
        }
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='Document-viewer-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Document-viewer-modal'>
          <FaFileAlt className='pe-1' /> {fileName}
        </Modal.Title>
      </Modal.Header>
      <ScrollContainer mouseScroll={{ ignoreElements: 'input, button' }}>
        <Modal.Body id='DocViewModal'>
          {fileName && getComponent({ fileName, file: base64Data })}
          {/* {fileName && fileName.endsWith('.jpg') && (
            <JpgComponent fileName={fileName} base64Data={base64Data} />
          )}
          {fileName && fileName.endsWith('.png') && (
            <PngComponent fileName={fileName} base64Data={base64Data} />
          )}
          {fileName && fileName.endsWith('.pdf') && (
            <PdfComponent base64Data={base64Data} fileName={fileName} />
          )}
          {fileName &&
            (fileName.endsWith('.tiff') || fileName.endsWith('.tif')) && (
              <TIFFViewer base64Data={base64Data} fileName={fileName} />
            )}
          {fileName &&
            (fileName.endsWith('.csv') ||
              fileName.endsWith('.txt') ||
              fileName.endsWith('.wav') ||
              fileName.endsWith('.zip') ||
              fileName.endsWith('.pgp') ||
              fileName.endsWith('.xls') ||
              fileName.endsWith('.xlsx') ||
              fileName.endsWith('.msg') ||
              fileName.endsWith('.iif')) && (
              <DownloadComponent fileName={fileName} base64Data={base64Data} />
            )} */}
        </Modal.Body>
      </ScrollContainer>
    </Modal>
  );
}
