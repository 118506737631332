import React, { useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  RowSelectionState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import IndeterminateCheckbox from '../Common/IndeterminateCheckbox';
import { ProviderCleanupObject } from '../../ApiTypes/ProviderCleanupObject';

export default function ProviderCleanupTable({
  data,
  rowSelection,
  setRowSelection,
  setSelectedProvider,
}: {
  data: ProviderCleanupObject[];
  rowSelection: RowSelectionState;
  setRowSelection: (state: object) => void;
  setSelectedProvider: (provider: ProviderCleanupObject | null) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  const handleRowSelectionChange = () => {
    setSelectedProvider(
      table.getSelectedRowModel().flatRows.map((r) => r.original)[0]
    );
  };

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<ProviderCleanupObject>[] = [
    {
      header: ({ table }) => (
        <div className='px-3 d-flex gap1Rem'>
          {Object.keys(rowSelection).length}
        </div>
      ),
      id: 'options',
      enableSorting: false,
      enableColumnFilter: false,
      size: 100,
      cell: function Cell({ row }) {
        return (
          <div
            className='d-flex gap1Rem align-items-center'
            style={{ width: '100px' }}
          >
            <IndeterminateCheckbox
              className='form-check-input'
              type='checkbox'
              {...{
                id: row.id,
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        );
      },
    },
    {
      header: 'Tax Id',
      accessorKey: 'taxId',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Company Name',
      accessorKey: 'companyName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Provider Id',
      accessorFn: (d) => `${d.providerId}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Transaction Count',
      accessorFn: (d) => `${d.transactionCount}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Address',
      accessorKey: 'address',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'City',
      accessorKey: 'city',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'State',
      accessorKey: 'state',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Zip',
      accessorKey: 'zip',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, [rowSelection]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
