import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { ClaimPhysicianLog } from '../../../ApiTypes/ClaimPhysicianLog';
import { displayDateOnly } from '../../../Utils';

export default function PhysiciansLogTable({
  physicianLogs,
}: {
  physicianLogs: ClaimPhysicianLog[];
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => physicianLogs, [physicianLogs]);

  const columnData: ColumnDef<ClaimPhysicianLog>[] = [
    {
      header: 'Type',
      accessorKey: 'logNotes',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Old Value',
      accessorKey: 'oldValue',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'New Value',
      accessorKey: 'newValue',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date',
      accessorKey: 'dateLogged',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Logged By',
      accessorKey: 'userId',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];
  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <div>
      <h4>Claimant Physician Information Log</h4>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        selectableRow={true}
        highlightRow={true}
      />
    </div>
  );
}
