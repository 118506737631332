import queryString from 'query-string';
import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaFolderOpen } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmployerPolicyApi from '../../Api/EmployerPolicyApi';
import { Employer } from '../../ApiTypes/Employer';
import { EmployerPolicy } from '../../ApiTypes/EmployerPolicy';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { useAppSelector } from '../../Reducers/Store';
import BlankModal from '../Common/BlankModal';
import BSControlledSelect from '../Common/BSControlledSelect';
import EmployerSelect from '../EmployerSelect/EmployerSelect';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import PolicyTable from './PolicyTable';

export default function PoliciesMaintenance() {
  const navigate = useNavigate();
  const location = useLocation();
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );

  const [years, setYears] = useState<number[]>([]);

  const [policies, setPolicies] = useState<EmployerPolicy[]>([]);

  const [fetching, setFetching] = useState(false);

  const [showEmployerSelect, setShowEmployerSelect] = useState<boolean>(false);
  const [selectedEmployer, setSelectedEmployer] = useState<Employer | null>(
    null
  );

  const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false);
  const [removing, setRemoving] = useState<boolean>(false);
  const [showRolloverWarning, setShowRolloverWarning] =
    useState<boolean>(false);
  const [rolling, setRolling] = useState<boolean>(false);

  useEffect(() => {
    loadYears();
  }, []);

  useEffect(() => {
    fetchData();
  }, [location]);

  const loadYears = () => {
    var ys: number[] = [];
    var thisYear = new Date().getFullYear() + 1;
    for (let i = 0; i <= 20; i++) {
      ys.push(thisYear - i);
    }
    setYears(ys);
  };

  const fetchData = () => {
    let search = queryString.parse(location.search);
    if (search && search.account && search.treatyYear) {
      setFetching(true);
      EmployerPolicyApi.list(
        search.account as string,
        +search.treatyYear as number
      )
        .then((res) => {
          setPolicies(res.data);
          setFetching(false);
        })
        .catch((err) => {
          console.log(err);
          setPolicies([]);
          setFetching(false);
          toast.error('Failed to get policies. ');
        });
    } else {
      setPolicies([]);
    }
  };

  const handleAccountChange = (accountNumber: string) => {
    const { account, treatyYear } = queryString.parse(location.search);
    navigate(
      `/policies?account=${accountNumber}&treatyYear=${treatyYear ?? 0}`
    );
  };

  const handleTreatyYearChange = (year: number) => {
    const { account, treatyYear } = queryString.parse(location.search);
    navigate(`/policies?account=${account}&treatyYear=${year}`);
  };

  const handleRemove = (policy: EmployerPolicy) => {
    const updated: EmployerPolicy = {
      ...policy,
      excessPolicyId: null,
      needsSecondaryExcess: false,
      secondaryExcessPolicyId: null,
    };
    return EmployerPolicyApi.updatePolicy(updated)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          fetchData();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update policy');
      });
  };

  const handleDelete = (d: EmployerPolicy) => {
    EmployerPolicyApi.deletePolicy(d.policyId)
      .then((res) => {
        if (res.data.success) {
          toast.success('Deleted');
          fetchData();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to delete');
      });
  };

  const handleEmployerSelectReturn = (employer: Employer) => {
    console.log(employer);
    setSelectedEmployer(employer);
    setShowEmployerSelect(false);
    navigate(
      `/policies/new?accountNumber=${employer.accountNumber}&employerId=${employer.employerId}`
    );
  };

  const warnRemoveAllExcessPolicies = () => {
    setShowDeleteWarning(true);
  };

  const warnRollover = () => {
    setShowRolloverWarning(true);
  };

  const handleRollover = () => {
    setShowRolloverWarning(false);
    const { account, treatyYear } = queryString.parse(location.search);
    if (account && treatyYear) {
      setRolling(true);
      EmployerPolicyApi.rolloverPolicies(
        account as string,
        +treatyYear as number
      )
        .then((res) => {
          if (res.data.success) {
            toast.success('Success');
            fetchData();
          } else {
            toast.error(res.data.message);
          }
          setRolling(false);
        })
        .catch((err) => {
          console.log(err);
          setRolling(false);
          toast.error('Failed to roll over all policies');
        });
    }
  };

  const removeAllExcessPolicies = () => {
    setShowDeleteWarning(false);
    const { account, treatyYear } = queryString.parse(location.search);
    if (account && treatyYear) {
      setRemoving(true);
      EmployerPolicyApi.removeAllExcess(
        account as string,
        +treatyYear as number
      )
        .then((res) => {
          if (res.data.success) {
            toast.success('Success');
            fetchData();
          } else {
            toast.error(res.data.message);
          }
          setRemoving(false);
        })
        .catch((err) => {
          console.log(err);
          setRemoving(false);
          toast.error('Failed to remove all excess');
        });
    }
  };

  let search = queryString.parse(location.search);
  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaFolderOpen className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Policies</h1>
          </div>
        </div>
      </div>
      <div className={styles.grid4}>
        <div>
          <BSControlledSelect
            name='accountNumber'
            label='Account'
            onChange={handleAccountChange}
            value={search.account}
            options={[...insuranceCompaniesForUser].sort((a, b) =>
              a.shortname!.localeCompare(b.shortname!)
            )}
            optionMethod={(options: InsuranceCompany[]) =>
              options.map((o) => (
                <option key={o.accountnumber!} value={o.accountnumber!}>
                  {`${o.accountnumber} - ${o.shortname}`}
                </option>
              ))
            }
          />
        </div>
        <div>
          <BSControlledSelect
            name='treatyYear'
            label='Treaty Year'
            onChange={handleTreatyYearChange}
            value={search.treatyYear}
            options={years}
            optionMethod={(options: number[]) =>
              options.map((o) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))
            }
          />
        </div>
        <div className={`${styles.width33} ms-2`}>
          <Button
            type='button'
            size='sm'
            variant='primary'
            onClick={() => {
              fetchData();
            }}
          >
            {fetching ? (
              <Spinner
                animation='grow'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              'Refresh'
            )}
          </Button>
        </div>
        <div className='d-flex justify-content-between'>
          <Button
            type='button'
            size='sm'
            variant='outline-danger'
            onClick={warnRemoveAllExcessPolicies}
          >
            {removing ? (
              <Spinner
                animation='grow'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              'Remove All Excess Policies'
            )}
          </Button>
          <Button
            type='button'
            size='sm'
            variant='outline-primary'
            onClick={() => {
              if (!search?.account) {
                toast.info('Choose an account');
              } else {
                setShowEmployerSelect(true);
              }
            }}
          >
            New Policy
          </Button>
          <Button
            type='button'
            size='sm'
            variant='outline-primary'
            onClick={warnRollover}
          >
            {rolling ? (
              <Spinner
                animation='grow'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              'Rollover All Policies'
            )}
          </Button>
        </div>
      </div>
      <PolicyTable
        policies={policies}
        handleDelete={handleDelete}
        handleRemove={handleRemove}
        location={location}
      />
      <>
        {showEmployerSelect && (
          <EmployerSelect
            show={showEmployerSelect}
            setShow={setShowEmployerSelect}
            setEmployerToEdit={() => {}}
            employer={{ accountNumber: search?.account } as Employer}
            allowEdit={false}
            allowNew={false}
            returnEmployer={handleEmployerSelectReturn}
          />
        )}
      </>
      <BlankModal
        show={showDeleteWarning}
        setShow={setShowDeleteWarning}
        showCancel={false}
        message={
          <div>
            <p className='fs-2'>
              Are you sure you want to Remove ALL Excess Policies for this
              Treaty Year?
            </p>
            <div className='d-flex justify-content-center py-3'>
              <Button
                type='button'
                size='sm'
                variant='outline-danger'
                onClick={removeAllExcessPolicies}
              >
                Confirm
              </Button>
            </div>
          </div>
        }
      />
      <BlankModal
        show={showRolloverWarning}
        setShow={setShowRolloverWarning}
        showCancel={false}
        message={
          <div>
            <p className='fs-2'>
              Are you sure you want to Roll over ALL Policies for this Account?
            </p>
            <div className='d-flex justify-content-center py-3'>
              <Button
                type='button'
                size='sm'
                variant='outline-danger'
                onClick={handleRollover}
              >
                Confirm
              </Button>
            </div>
          </div>
        }
      />
    </PageScaffold>
  );
}
