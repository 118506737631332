import React, { useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getCoreRowModel,
  ColumnDef,
  ColumnFiltersState,
  RowSelectionState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { FaEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { displayDateOnly } from '../../Utils';
import IndeterminateCheckbox from '../Common/IndeterminateCheckbox';
import { Button, Spinner } from 'react-bootstrap';
import accounting from 'accounting';

import { DeductibleInvoiceObj } from '../../ApiTypes/DeductibleInvoiceObj';
import { toast } from 'react-toastify';
import { addMonths, format } from 'date-fns';
import DeductibleApi from '../../Api/DeductibleApi';

const redBorder = {
  // borderColor: 'red',
  border: '3px solid red',
};

function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}

export default function DeductiblePaymentsTable({
  data,
  setData,
  rowSelection,
  setRowSelection,
  serviceTo,
  reSubmit,
}: {
  data: DeductibleInvoiceObj[];
  serviceTo: string;
  setData: (
    data:
      | DeductibleInvoiceObj[]
      | ((data: DeductibleInvoiceObj[]) => DeductibleInvoiceObj[])
  ) => void;
  rowSelection: RowSelectionState;
  setRowSelection: (obj: object) => void;
  reSubmit: () => void;
}) {
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const [saving, setSaving] = useState<boolean>(false);

  const save = () => {
    const selected = table
      .getSelectedRowModel()
      .flatRows.map((r) => r.original);

    const errors = selected.filter((row) => {
      if (!row.amountPaid) {
        toast.error(`Enter an amount paid for claim ${row.claimNumber}`);
      }
      if (!row.checkNumber) {
        toast.error(`Enter a check number for claim ${row.claimNumber}`);
      }
      return !row.amountPaid || !row.checkNumber;
    });

    if (errors.length > 0) {
      return;
    }

    selected.forEach((row) => (row.details.checkNumber = row.checkNumber));

    const serviceFrom = format(
      addMonths(new Date(serviceTo), -4),
      'yyyy-MM-dd'
    );

    setSaving(true);
    DeductibleApi.processDeductibles({
      invoices: selected,
      serviceTo,
      serviceFrom,
    })
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          reSubmit();
        } else {
          toast.error(res.data.message);
        }
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
        toast.error('Failed to Process Deductibles');
      });
  };

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const columnData: ColumnDef<DeductibleInvoiceObj>[] = [
    {
      header: ({ table }) => (
        <div className='px-3 d-flex gap1Rem'>
          {Object.keys(rowSelection).length}
          <IndeterminateCheckbox
            className='form-check-input'
            {...{
              id: 'totalSelectedInvoiceObjs',
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                const fn = table.getToggleAllRowsSelectedHandler();
                fn(e);

                if (e.target.checked) {
                  data.forEach((row, index) => {
                    table.options.meta?.updateData(
                      index,
                      'amountPaid',
                      row.details.amountDue
                    );
                  });
                } else {
                  data.forEach((row, index) => {
                    table.options.meta?.updateData(index, 'amountPaid', 0);
                    table.options.meta?.updateData(index, 'checkNumber', null);
                  });
                }
              },
            }}
          />
        </div>
      ),
      id: 'options',
      enableSorting: false,
      enableColumnFilter: false,
      size: 100,
      cell: function Cell({ row }) {
        let backgroundColor = 'inherit';
        if (
          row.getIsSelected() &&
          (!row.original.amountPaid || !row.original.checkNumber)
        ) {
          backgroundColor = 'salmon' ?? 'inherit';
        } else if (
          row.getIsSelected() &&
          row.original.amountPaid > row.original.claimTotalPaid
        ) {
          backgroundColor = 'khaki';
        } else {
          backgroundColor = 'inherit';
        }
        return (
          <div
            className={`d-flex gap1Rem align-items-center ${backgroundColor}`}
            style={{ width: '100px' }}
          >
            <IndeterminateCheckbox
              className='form-check-input'
              type='checkbox'
              {...{
                id: row.id,
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  const fn = row.getToggleSelectedHandler();
                  fn(e);

                  if (e.target.checked) {
                    table.options.meta?.updateData(
                      row.index,
                      'amountPaid',
                      row.original.details.amountDue
                    );
                  } else {
                    table.options.meta?.updateData(row.index, 'amountPaid', 0);
                    table.options.meta?.updateData(
                      row.index,
                      'checkNumber',
                      null
                    );
                  }
                },
              }}
            />

            <Link to={`/claims/${row.original.claimNumber}`}>
              <Button
                type='button'
                variant='outline-primary'
                size='sm'
                className='button-icon-text'
              >
                <FaEye />
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      header: 'Claim Number',
      accessorKey: 'claimNumber',
    },
    {
      header: 'Claimant',
      accessorKey: 'claimant',
    },
    {
      header: 'Injury Date',
      accessorKey: 'injuryDate',
      cell: function Cell(d) {
        return displayDateOnly(d.row.original.injuryDate ?? '');
      },
    },
    {
      header: 'Actual Total Paid',
      accessorFn: (d) => `${d.details.claimsPaid}`,
      cell: function Cell({ row }) {
        return accounting.formatMoney(row.original.details.claimsPaid ?? 0);
      },
    },
    {
      header: 'Deductible Paid',
      accessorFn: (d) => `${d.details.deductiblePaid}`,
      cell: function Cell({ row }) {
        return accounting.formatMoney(row.original.details.deductiblePaid ?? 0);
      },
    },
    {
      header: 'Amount Due',
      accessorFn: (d) => `${d.details.amountDue}`,
      cell: function Cell({ row }) {
        return accounting.formatMoney(row.original.details.amountDue ?? 0);
      },
      footer: ({ table }) => {
        const paid = data.reduce((acc, curr) => {
          return (acc += +curr.details.amountDue ?? 0);
        }, 0);
        return accounting.formatMoney(paid);
      },
    },
    {
      header: 'Claim Total',
      accessorFn: (d) => `${d.claimTotalPaid}`,
      cell: function Cell({ row }) {
        return accounting.formatMoney(row.original.claimTotalPaid ?? 0);
      },
    },
    {
      header: 'Amount Paid',
      accessorKey: 'amountPaid',
      cell: function Cell({
        getValue,
        row: { index, getIsSelected, original },
        column: { id },
        table,
      }) {
        const initialValue = getValue();
        // We need to keep and update the state of the cell normally
        const [value, setValue] = React.useState(initialValue);

        // When the input is blurred, we'll call our table meta's updateData function
        const onBlur = () => {
          table.options.meta?.updateData(index, id, value);
          if (+(value as number) > original.claimTotalPaid) {
            toast.warn(
              `Claim ${original.claimNumber} does not Exceed the Payment Amount.`,
              {
                autoClose: false,
              }
            );
          }
        };

        // If the initialValue is changed external, sync it up with our state
        React.useEffect(() => {
          setValue(initialValue);
        }, [initialValue]);

        return (
          <input
            className='form-control form-control-sm'
            value={value as string}
            onChange={(e) => {
              const { value } = e.target;
              setValue(value);
            }}
            onBlur={onBlur}
            disabled={!getIsSelected()}
            style={getIsSelected() && !value ? redBorder : {}}
          />
        );
      },
      footer: ({ table }) => {
        const paid = data.reduce((acc, curr) => {
          return (acc += +curr.amountPaid ?? 0);
        }, 0);
        return accounting.formatMoney(paid);
      },
    },
    {
      header: 'Check Number',
      accessorKey: 'checkNumber',
      cell: function Cell({
        getValue,
        row: { index, getIsSelected },
        column: { id },
        table,
      }) {
        const initialValue = getValue();
        const [value, setValue] = React.useState(initialValue);

        const onBlur = () => {
          table.options.meta?.updateData(index, id, value);
        };

        React.useEffect(() => {
          setValue(initialValue);
        }, [initialValue]);

        return (
          <input
            className='form-control form-control-sm'
            value={value as string}
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
            disabled={!getIsSelected()}
            style={getIsSelected() && !value ? redBorder : {}}
          />
        );
      },
    },
  ];

  const table = useReactTable({
    data,
    columns: columnData,
    state: {
      columnFilters,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',
    enableSorting: false,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    paginateExpandedRows: false,
    autoResetPageIndex,
    // Provide our updateData function to our table meta
    meta: {
      updateData: (rowIndex, columnId, value) => {
        // Skip page index reset until after next rerender
        skipAutoResetPageIndex();
        setData((old: DeductibleInvoiceObj[]) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex]!,
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
    },
  });

  return (
    <>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={false}
        showFooter={true}
        highlightRow={false}
        selectableRow={false}
      />
      <div className='d-flex justify-content-center align-items-center py-3'>
        <Button
          type='submit'
          variant='primary'
          size='sm'
          onClick={save}
          disabled={Object.keys(rowSelection).length === 0}
        >
          {saving ? (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            'Save'
          )}
        </Button>
      </div>
    </>
  );
}
