import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import { PropertyPhoto } from '../../../ApiTypes/PropertyPhoto';
import { displayDateOnly } from '../../../Utils';
import styles from './index.module.css';

export default function PropertyPhotoTable({
  propertyPhotos,
  handlePropertyPhotoToDelete,
  setShowPropertyPhoto,
  setSelectedPhoto,
}: {
  handlePropertyPhotoToDelete: (w: PropertyPhoto) => void;
  setShowPropertyPhoto: (show: boolean) => void;
  setSelectedPhoto: (photo: PropertyPhoto | null) => void;
  propertyPhotos: PropertyPhoto[];
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => propertyPhotos, [propertyPhotos]);

  const columnData: ColumnDef<PropertyPhoto>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      size: 75,
      cell: (d) => {
        return (
          <div className={`${styles.actionButtons}`}>
            <Link
              to={`/scandocs/${d.row.original.docId}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button
                type='button'
                variant='primary'
                size='sm'
                title='View'
                onClick={() => {
                  document.body.click();
                }}
              >
                <FaEye />
              </Button>
            </Link>
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='View/Edit'
              onClick={() => {
                setSelectedPhoto(d.row.original);
                setShowPropertyPhoto(true);
              }}
            >
              <FaEdit />
            </Button>
            <Button
              type='button'
              size='sm'
              variant='danger'
              title='Delete'
              onClick={() => {
                handlePropertyPhotoToDelete(d.row.original);
              }}
            >
              <FaTrash />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'File Name',
      accessorKey: 'doc.note',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date',
      accessorKey: 'doc.date',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) => displayDateOnly(d.row.original.doc?.date ?? ''),
    },

    {
      header: 'Notes',
      accessorKey: 'notes',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div className='w-100'>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
      />
    </div>
  );
}
