import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { FaChevronCircleLeft, FaEdit, FaPlusCircle } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LegalApi from '../../../Api/LegalApi';
import { Settlement } from '../../../ApiTypes/Settlement';
import { SettlementPaymentDetail } from '../../../ApiTypes/SettlementPaymentDetail';
import { SettlementType } from '../../../ApiTypes/SettlementType';
import { useAppSelector } from '../../../Reducers/Store';
import { displayDateOnly, parseDatesForServer } from '../../../Utils';
import { requiredField } from '../../../Utils/FieldValidation';
import FieldBSRenderCheckbox from '../../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../../Common/FieldBSRenderSelect';
import FieldBSRenderTextArea from '../../Common/FieldRenderTextArea';
import SettlementPaymentDetailAddEdit from '../../SettlementPaymentDetailAddEdit/SettlementPaymentDetailAddEdit';

import styles from './index.module.css';
import SettlementPaymentDetailTable from './SettlementPaymentDetailTable';
import { format } from 'date-fns';

export default function SettlementAddEdit({
  selectedSettlementId,
  show,
  setShow,
  getSettlements,
}: {
  selectedSettlementId: string | '';
  show: boolean;
  setShow: () => void;
  getSettlements: () => void;
}) {
  let { claimNumber } = useParams();
  let settlementId = selectedSettlementId;
  let navigate = useNavigate();
  const [settlement, setSettlement] = useState<Settlement | null>(null);
  const [showAddEditDetails, setShowAddEditDetails] = useState<boolean>(false);
  const [selectedDetail, setSelectedDetail] =
    useState<SettlementPaymentDetail | null>(null);

  const { claim, claimant } = useAppSelector(
    (state) => state.currentClaimReducer
  );

  const { settlementTypes } = useAppSelector((state) => state.reference);

  const { userModel } = useAppSelector((state) => state.user);

  useEffect(() => {
    getSettlementById();
  }, [claimNumber, settlementId]);

  const getSettlementById = () => {
    if (claimNumber && settlementId && settlementId !== '') {
      LegalApi.getSettlementById(claimNumber, +settlementId)
        .then((res) => {
          setSettlement(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setSettlement(null);
    }
  };

  const onSubmit = (values: Settlement) => {
    const copy = { ...values };
    copy.claimNumber = claim?.claimNo ?? '';
    copy.creator = userModel?.user?.userId ?? '';
    copy.settlementId = values.settlementId ?? 0;
    copy.settlementPaymentDetails = [];
    copy.settlementType = null;
    if (copy.settlementId) {
      return updateSettlement(copy);
    }
    return createSettlement(copy);
  };

  const updateSettlement = (v: Settlement) => {
    return LegalApi.updateSettlement(v, claimNumber!)
      .then((res) => {
        if (res.data.success) {
          getSettlementById();
          toast.success('Settlement updated!');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update settlement');
      });
  };

  const createSettlement = (v: Settlement) => {
    const claimantName = claimant?.firstName + ' ' + claimant?.lastName;
    const now = format(Date.now(), 'yyyy-MM-dd');
    const newSettlement: Settlement = {
      ...v,
      status: 0,
      claimantName,
      dateCreated: now,
    };
    return LegalApi.createSettlement(newSettlement, claimNumber!)
      .then((res) => {
        if (res.data.success) {
          // navigate(
          //   `/claims/${claimNumber}/legal/settlements/${res.data.affectedEntityIdentifier}`
          // );
          getSettlements();
          setShow();
          toast.success('Settlement created!');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create settlement');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        // runCleanUp();
        setShow();
      }}
      dialogClassName=''
      aria-labelledby='Edit-Create-Settlement-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Edit-Create-Settlement-Form-modal'
        >
          <FaEdit className='pe-1' />
          {settlement ? 'Edit' : 'Create'} Settlement
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className='w-100 h-100'>
            {/* <div className='d-flex  mb-3'>
              <Link to={`/claims/${claimNumber}/legal/settlements`}>
                <Button
                  type='button'
                  variant='primary'
                  size='sm'
                  className='button-icon-text'
                >
                  <FaChevronCircleLeft /> Go Back
                </Button>
              </Link>
            </div> */}
            <div className={`${styles.addEditSettlementTopDetails}`}>
              <p>
                Date:{' '}
                {settlement?.dateCreated
                  ? displayDateOnly(settlement.dateCreated)
                  : displayDateOnly(new Date())}
              </p>
              <p>Claim No: {claim?.claimNo}</p>
              <p>
                Claimant: {claimant?.firstName} {claimant?.lastName}
              </p>
            </div>
            <RFFForm
              onSubmit={onSubmit}
              initialValues={settlement ?? {}}
              render={({ handleSubmit, form, values, submitting }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <div className={`${styles.formRow}`}>
                      <Field
                        name='settlementTypeId'
                        label='Settlement Type'
                        options={settlementTypes}
                        optionMethod={(options: SettlementType[]) =>
                          options.map((o) => (
                            <option
                              key={o.settlementTypeId}
                              value={o.settlementTypeId}
                            >
                              {o.name}
                            </option>
                          ))
                        }
                        component={FieldBSRenderSelect}
                        validate={requiredField}
                      />
                      <Field
                        name='dateOfSettlement'
                        label='SBWC Award Date'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                        validate={requiredField}
                      />
                      <Field
                        name='ismsa'
                        label='Is MSA'
                        type='checkbox'
                        checked={!!values.ismsa}
                        component={FieldBSRenderCheckbox}
                      />
                      <Field
                        name='dateMsaapproved'
                        label='Date MSA Approved'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                    </div>
                    <div className={`${styles.maxWidth700}`}>
                      <Field
                        name='notes'
                        label='Notes'
                        rows={3}
                        component={FieldBSRenderTextArea}
                      />
                    </div>
                    <div className='d-flex mt-2 justify-content-center'>
                      <div className='px-1'>
                        <Button type='submit' variant='primary' size='sm'>
                          {submitting ? (
                            <Spinner
                              as='span'
                              animation='grow'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                            />
                          ) : (
                            'Save'
                          )}
                        </Button>
                      </div>
                      <div className='px-1'>
                        <Button
                          type='button'
                          size='sm'
                          variant='secondary'
                          onClick={() => {
                            // runCleanUp();
                            form.reset();
                            setShow();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            />
            <div className='mb-3 p-2'>
              <Button
                type='button'
                variant='primary'
                size='sm'
                onClick={() => {
                  if (settlement === null || !settlement.settlementId) {
                    toast.error(
                      'Please save a settlement before adding payment details'
                    );
                  } else {
                    setSelectedDetail(null);
                    setShowAddEditDetails(true);
                  }
                }}
                className='button-icon-text m-auto'
              >
                <FaPlusCircle /> Payment Details
              </Button>
            </div>
            <SettlementPaymentDetailTable
              details={settlement?.settlementPaymentDetails ?? []}
              setSelectedDetail={setSelectedDetail}
              setShowAddEditDetails={setShowAddEditDetails}
            />
            <SettlementPaymentDetailAddEdit
              show={showAddEditDetails}
              setShow={setShowAddEditDetails}
              details={selectedDetail}
              getSettlementById={getSettlementById}
              claimantName={claimant?.firstName + ' ' + claimant?.lastName}
              settlementId={settlementId}
              getSettlements={getSettlements}
            />
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
