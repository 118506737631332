import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ReportApi from '../../Api/ReportApi';
import { ReportRequest } from '../../ApiTypes/ReportRequest';
import { FileDownload } from '../../Types/FileDownLoad';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { DevExpressReportRequest } from '../../ApiTypes/DevExpressReportRequest';

export const useDocViewModal = () => {
  const [showDocViewModal, setShowDocViewModal] = useState<boolean>(false);
  const [fileDownload, setFileDownload] = useState<FileDownload | null>(null);

  useEffect(() => {
    handleClose();
  }, [showDocViewModal]);

  const handleClose = () => {
    if (!showDocViewModal) {
      setFileDownload(null);
    }
  };

  return {
    showDocViewModal,
    fileDownload,
    setShowDocViewModal,
    setFileDownload,
  };
};

export const getCrystalReport = async (
  request: ReportRequest,
  setFileDownload: (f: FileDownload) => void,
  setShowDocViewModal: (s: boolean) => void
) => {
  try {
    const res = await toast.promise(ReportApi.getCrystalReport(request), {
      pending: `Fetching Report`,
      success: 'Success',
      error: `Failed to fetch report`,
    });
    if (res.data.success) {
      setFileDownload({
        file: res.data.reportBytes,
        fileName: res.data.reportFileName,
      });
      setShowDocViewModal(true);
    } else {
      toast.error(<ErrorMessage messages={res.data.messages} />);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getDxReport = async (
  request: DevExpressReportRequest,
  setFileDownload: (f: FileDownload) => void,
  setShowDocViewModal: (s: boolean) => void
) => {
  try {
    const res = await toast.promise(ReportApi.getDxReport(request), {
      pending: `Fetching Report`,
      success: 'Success',
      error: `Failed to fetch report`,
    });
    if (res.data.success) {
      setFileDownload({
        file: res.data.reportBytes,
        fileName: res.data.reportFileName,
      });
      setShowDocViewModal(true);
      return res.data;
    } else {
      toast.error(<ErrorMessage messages={res.data.messages} />);
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};
