import React, { useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  RowSelectionState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { useAppSelector } from '../../Reducers/Store';
import { displayDateOnly } from '../../Utils';
import { PaymentBatch } from '../../ApiTypes/PaymentBatch';
import IndeterminateCheckbox from '../Common/IndeterminateCheckbox';
import { isAfter } from 'date-fns';

export default function ViewCheckBatchesTable({
  data,
  printSelected,
  rowSelection,
  setRowSelection,
  setSelectedBatches,
}: {
  data: PaymentBatch[];
  printSelected: (row: PaymentBatch) => void;
  rowSelection: RowSelectionState;
  setRowSelection: (obj: object) => void;
  setSelectedBatches: (batches: PaymentBatch[]) => void;
}) {
  const { userName } = useAppSelector((state) => state.user);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  useEffect(() => {
    handleNewData();
  }, [data]);

  const handleNewData = () => {
    setRowSelection({});
  };

  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  const handleRowSelectionChange = () => {
    setSelectedBatches(
      table.getSelectedRowModel().flatRows.map((r) => r.original)
    );
  };

  const printSelectedUserNames = [
    'lpritchett',
    'alittle',
    'ben',
    'danie_000',
    // 'cedwards',
  ];

  const tableData = useMemo(() => data, [data]);

  const now = new Date();

  const columnData: ColumnDef<PaymentBatch>[] = [
    {
      header: ({ table }) => (
        <div className='px-3 d-flex gap1Rem'>
          {Object.keys(rowSelection).length}
          <IndeterminateCheckbox
            className='form-check-input'
            {...{
              id: 'totalSelection',
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      id: 'options',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 85,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center gap1Rem'>
            <IndeterminateCheckbox
              className='form-check-input'
              {...{
                id: row.id,
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
            <Button
              type='button'
              variant='outline-primary'
              size='sm'
              disabled={
                !printSelectedUserNames.includes(userName.toLowerCase())
              }
              onClick={() => {
                printSelected(row.original);
              }}
            >
              Print
            </Button>
          </div>
        );
      },
    },

    {
      header: 'Acct',
      accessorKey: 'accountnumber',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Batch Id',
      accessorFn: (d) => `${d.pmtBatchId}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Printed',
      accessorFn: (d) => {
        if (!d.batchPrintDate) {
          return 'NO';
        }
        if (isAfter(now, new Date(d.batchPrintDate))) {
          return 'YES';
        } else {
          return 'NO';
        }
      },
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Print Date',
      accessorKey: 'batchPrintDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.batchPrintDate ?? ''),
    },
    {
      header: 'Printed By',
      accessorKey: 'batchPrintedBy',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: '# of Checks',
      accessorFn: (d) => `${d.checkcount}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, [rowSelection]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
