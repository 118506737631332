import React, { useState, useEffect } from 'react';
import accounting from 'accounting';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { FaExchangeAlt } from 'react-icons/fa';
import { TransferPaymentRequest } from '../../ApiTypes/TransferPaymentRequest';
import { VPaymentTab } from '../../ApiTypes/VPaymentTab';

import styles from './index.module.css';
import ClaimApi from '../../Api/ClaimApi';
import { useAppSelector } from '../../Reducers/Store';
import { displayDateOnly } from '../../Utils';
import ReservesApi from '../../Api/ReservesApi';

type Provider = {
  companyName: string;
};

export default function TransferPaymentModal({
  show,
  setShow,
  selectedPayment,
  transferPayment,
  setSelectedPayment,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  selectedPayment: VPaymentTab | null;
  setSelectedPayment: (payment: VPaymentTab | null) => void;
  transferPayment: (values: TransferPaymentRequest) => Promise<void>;
}) {
  const [labelText, setLabelText] = useState<string>('');
  const [messageText, setMessageText] = useState<string>('');
  const [confirmDisabled, setConfirmDisabled] = useState<boolean>(true);
  const [claimNo, setClaimNo] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { employer } = useAppSelector((state) => state.currentClaimReducer);

  useEffect(() => {
    getLabelText();
    setMessageText('');
    setConfirmDisabled(true);
    setClaimNo('');
  }, [selectedPayment]); // provider

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (confirmDisabled) {
      return;
    }
    setSubmitting(true);
    await transferPayment({
      txId: selectedPayment?.txId!,
      newClaimNumber: claimNo,
    });
    setSubmitting(false);
  };

  const getLabelText = () => {
    let text: string = '';
    if (selectedPayment?.txAmount) {
      text = `Transfer Payment in the amount of ${accounting.formatMoney(
        selectedPayment?.txAmount ?? 0
      )} to Claim #`;
    } else {
      text = '';
    }

    setLabelText(text);
  };

  const search = () => {
    if (claimNo) {
      let message = '';
      if (claimNo === selectedPayment?.claimNo) {
        message = 'Transactions can not be transferred to the same claim.';
        setMessageText(message);
        setConfirmDisabled(true);
        return;
      }
      ClaimApi.getFullClaimByClaimNumber(claimNo)
        .then((res) => {
          if (res.status === 204 || !res.data) {
            message = 'Claim Number Not Found';
            setConfirmDisabled(true);
          } else if (res.data) {
            if (res.data.claim?.claimantId === -1) {
              message = 'Invalid Claim Number';
              setConfirmDisabled(true);
            } else if (
              res.data.employer?.accountNumber !== employer?.accountNumber
            ) {
              message = 'Transactions cannot be transferred between accounts.';
              setConfirmDisabled(true);
            } else {
              message = `${res.data.claimant?.firstName} ${
                res.data.claimant?.lastName
              } DOI: ${displayDateOnly(res.data.claim?.injuryDate ?? '')}`;
              // check reserves
              if ((selectedPayment?.txAmount ?? 0) > 0) {
                getReserveBucketsForClaim(
                  claimNo,
                  selectedPayment?.txAmount ?? 0
                );
              } else {
                setConfirmDisabled(false);
              }
            }
          }
          setMessageText(message);
        })
        .catch((err) => {
          console.log(err);
          message = 'An error has ocurred';
          setMessageText(message);
        });
    }
  };

  const getReserveBucketsForClaim = (claimNumber: string, txAmount: number) => {
    if (claimNumber) {
      ReservesApi.getClaimReserveBucketsForClaim(claimNumber)
        .then((res) => {
          const reserveBucket = res.data.find(
            (b) => b.fBucket === selectedPayment?.fBucketCode
          );
          if (reserveBucket) {
            if (txAmount > reserveBucket.reserves) {
              setMessageText(
                `The receiving claim does not have enough reserves. Transfer amount: ${accounting.formatMoney(
                  txAmount
                )} Reserves amount: ${accounting.formatMoney(
                  reserveBucket.reserves
                )} Do you wish to continue?`
              );
              setTimeout(() => {
                setConfirmDisabled(false);
              }, 2000);
            } else {
              setConfirmDisabled(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleClaimNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;
    setClaimNo(value);
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setSelectedPayment(null);
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='Transfer-Payment-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Transfer-Payment-modal'>
          <FaExchangeAlt className='pe-1' /> Transfer Transaction
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p>{labelText}</p>
          <Form onSubmit={onSubmit}>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='w-50'>
                <div className={`position-relative ${styles.vFieldHeight}`}>
                  <label htmlFor='claimNo' className='form-label fs-6  m-0'>
                    Claim Number
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    placeholder='Claim Number'
                    onChange={handleClaimNumberChange}
                  />
                </div>
              </div>
              <div className='w-50 d-flex justify-content-center'>
                <Button
                  type='button'
                  variant='primary'
                  size='sm'
                  onClick={search}
                >
                  Look Up
                </Button>
              </div>
            </div>
            <div className={styles.height50px}>
              <p className='text-danger'>{messageText}</p>
            </div>
            <div className={styles.claimantFormButtonDiv}>
              <Button
                type='button'
                size='lg'
                variant='primary'
                disabled={confirmDisabled}
                onClick={onSubmit}
              >
                {submitting ? (
                  <Spinner
                    as='span'
                    animation='grow'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  'Submit'
                )}
              </Button>
              <Button
                type='button'
                size='lg'
                variant='secondary'
                onClick={() => {
                  setShow(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
