import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { UserPriorityClaim } from '../ApiTypes/UserPriorityClaim';

class UserPriorityClaimApi {
  static updateUserPriorityClaim(claimNumber: string, isPriority: boolean) {
    return axios.post<{ isPriority: boolean }>(
      `${API_URL}/api/claims/${claimNumber}/userpriorityclaims?active=${isPriority}`,
      {},
      getAuthHeader()
    );
  }
  static getUserPriorityClaims() {
    return axios.get<UserPriorityClaim[]>(
      `${API_URL}/api/userpriorityclaims`,
      getAuthHeader()
    );
  }
}

export default UserPriorityClaimApi;
