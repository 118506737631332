import accounting from 'accounting';
import { FormApi } from 'final-form';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { GroupedFees } from '../../ApiTypes/VBillFee';
import { FeeWizardFormType } from './FeeWizardFormType';
import GroupedFeesTable from './GroupedFeesTable';
import styles from './index.module.css';

export default function Page3({
  formInstance,
  values,
  totals,
}: {
  formInstance: FormApi<FeeWizardFormType, Partial<FeeWizardFormType>>;
  values: FeeWizardFormType;
  totals: GroupedFees[];
}) {
  const brFees = totals.reduce((acc, curr) => {
    return (acc += curr.brFees ?? 0);
  }, 0);
  const ppoFees = totals.reduce((acc, curr) => {
    return (acc += curr.ppoFEES ?? 0);
  }, 0);
  const totalFees = totals.reduce((acc, curr) => {
    return (acc += curr.totalFEES ?? 0);
  }, 0);

  return (
    <div>
      <div>
        <Alert variant='info'>
          <Alert.Heading>Step 2: Compare Totals</Alert.Heading>
        </Alert>
      </div>
      <p className='fw-bold mb-0'>Fees Totals:</p>
      <GroupedFeesTable data={totals} />
      <Alert variant='secondary'>
        <div className={`${styles.grid4}`}>
          <p>Totals </p>
          <p>BR Fees: {accounting.formatMoney(brFees)}</p>
          <p>PPO Fees: {accounting.formatMoney(ppoFees)}</p>
          <p>Total Fees: {accounting.formatMoney(totalFees)}</p>
        </div>
        <hr />
        <p className='text-center fw-bold'>Proceed if the totals are correct</p>
      </Alert>
    </div>
  );
}
