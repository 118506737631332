import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LegalApi from '../../../Api/LegalApi';
import { Hearing } from '../../../ApiTypes/Hearing';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import HearingTable from './HearingTable';
import HearingAddEdit from './HearingAddEdit';

export default function HearingsTab() {
  let { claimNumber } = useParams();
  const [hearings, setHearings] = useState<Hearing[]>([]);
  const [hearingToDelete, setHearingToDelete] = useState<Hearing | null>(null);
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const [showHearingModal, setShowHearingModal] = useState<boolean>(false);
  const [selectedHearingId, setSelectedHearingId] = useState<string | ''>('');
  const [hearingIsReset, setHearingIsReset] = useState<boolean>(false);

  useEffect(() => {
    getHearingsForClaim();
  }, [claimNumber]);

  const getHearingsForClaim = () => {
    if (claimNumber) {
      LegalApi.getHearingsForClaim(claimNumber)
        .then((res) => {
          setHearings(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setHearings([]);
    }
  };

  const handleHearingToDelete = (h: Hearing) => {
    setHearingToDelete(h);
    setShowDelete(true);
  };

  const handleDelete = () => {
    if (hearingToDelete && claimNumber) {
      return LegalApi.deleteHearing(hearingToDelete?.hearingId, claimNumber)
        .then((res) => {
          if (res.data.success) {
            getHearingsForClaim();
            setHearingToDelete(null);
            setShowDelete(false);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to delete hearing');
        });
    }
    return Promise.resolve();
  };

  const removeModal = () => {
    setShowHearingModal(false);
    setSelectedHearingId('');
  };

  return (
    <div className='w-100 h-100'>
      <div className='d-flex justify-content-center mb-3'>
        <Button
          type='button'
          variant='primary'
          size='sm'
          onClick={() => {
            setSelectedHearingId('');
            setShowHearingModal(true);
          }}
          disabled={claimNumber === undefined}
          className='button-icon-text'
        >
          <FaPlusCircle /> Add New
        </Button>
      </div>
      <HearingTable
        hearings={hearings}
        handleHearingToDelete={handleHearingToDelete}
        show={showHearingModal}
        setShow={setShowHearingModal}
        selectedHearingId={selectedHearingId}
        setSelectedHearingId={setSelectedHearingId}
        hearingIsReset={setHearingIsReset}
      />

      <HearingAddEdit
        selectedHearingId={selectedHearingId}
        show={showHearingModal}
        setShow={removeModal}
        getHearings={getHearingsForClaim}
        hearingIsReset={hearingIsReset}
      />
      <DeleteConfirmationModal
        show={showDelete}
        setShow={setShowDelete}
        setNull={setHearingToDelete}
        handleDelete={handleDelete}
      />
    </div>
  );
}
