import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { FaBan, FaCheck, FaEye } from 'react-icons/fa';
import { Settlement } from '../../ApiTypes/Settlement';
import accounting from 'accounting';
import { displayDateOnly } from '../../Utils';

export default function ApproveSettlementTable({
  data,
  setSelectedSettlement,
  setShowViewSettlement,
  approveSettlement,
  denySettlement,
}: {
  data: Settlement[];
  setSelectedSettlement: (settlement: Settlement | null) => void;
  setShowViewSettlement: (show: boolean) => void;
  approveSettlement: (settlement: Settlement) => void;
  denySettlement: (settlement: Settlement) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<Settlement>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center align-items-center gap1Rem'>
            <Button
              type='button'
              variant='outline-primary'
              size='sm'
              title='View Settlement'
              onClick={() => {
                setSelectedSettlement(row.original);
                setShowViewSettlement(true);
              }}
            >
              <FaEye />
            </Button>
            <Button
              type='button'
              variant='danger'
              size='sm'
              title='Deny'
              onClick={() => {
                denySettlement(row.original);
              }}
            >
              <FaBan />
            </Button>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='Approve'
              onClick={() => {
                approveSettlement(row.original);
              }}
            >
              <FaCheck />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Claim Number',
      accessorKey: 'claimNumber',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claimant',
      accessorKey: 'claimantName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date',
      accessorKey: 'dateCreated',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dateCreated ?? ''),
    },
    {
      header: 'Amount',
      accessorFn: (d) => {
        var total = d.settlementPaymentDetails.reduce((acc, curr) => {
          return (acc += curr.amount ?? 0);
        }, 0);
        return `${total}`;
      },
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: ({ row, getValue }) =>
        accounting.formatMoney((getValue() as number) ?? 0),
    },
    {
      header: 'Notes',
      accessorKey: 'notes',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Creator',
      accessorKey: 'creator',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Type',
      accessorKey: 'settlementType.name',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date Approved',
      accessorKey: 'dateApproved',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dateApproved ?? ''),
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
