import { format } from 'date-fns';
import { FormApi } from 'final-form';
import React, { useState, useEffect } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaMoneyCheckAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ChecksApi from '../../Api/ChecksApi';
import { Check } from '../../ApiTypes/Check';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import QuickReconcile from './QuickReconcile';
import ReconcileCheckTable from './ReconcileCheckTable';
import {
  DevExpressReportRequest,
  DxAvailableReports,
} from '../../ApiTypes/DevExpressReportRequest';
import { requestDx } from '../DxReportRequestModal/useDxReportRequestModal';
import { getDxReport } from '../DocViewModal/useDocViewModal';
import {
  setDocViewFileDownload,
  setShowDocView,
} from '../../Actions/DocViewActions';
import { FileDownload } from '../../Types/FileDownLoad';

export default function ReconcileChecks() {
  const dispatch = useAppDispatch();
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );
  const [checks, setChecks] = useState<Check[]>([]);
  const [selectedChecks, setSelectedChecks] = useState<Check[]>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [cleared, setCleared] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [showQuickReconcile, setShowQuickReconcile] = useState<boolean>(false);
  const [insurCoId, setInsurCoId] = useState<number>(0);
  let formInstance: FormApi<ReconcileFormType, Partial<ReconcileFormType>>;

  type ReconcileFormType = {
    insurCoId: number;
    cleared: boolean;
    serviceTo: string;
  };

  const getChecks = (values: ReconcileFormType) => {
    setCleared(values.cleared === true);
    setRowSelection({});
    return ChecksApi.getChecksToReconcile(
      values.insurCoId,
      values.cleared === true,
      values.serviceTo
    )
      .then((res) => setChecks(res.data))
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (insuranceCompaniesForUser.length > 0) {
      setInsurCoId(insuranceCompaniesForUser[0].insurcoid);
      formInstance.submit();
    }
  }, [insuranceCompaniesForUser]);

  const now = new Date();
  const initialServiceTo = format(
    new Date(now.getFullYear(), now.getMonth(), 1),
    'yyyy-MM-dd'
  );

  const reconcile = () => {
    if (selectedChecks.length > 0) {
      setSaving(true);
      ChecksApi.reconcileChecks({
        checksToReconcile: selectedChecks.map((c) => c.checkId),
      })
        .then((res) => {
          if (res.data.success) {
            toast.success('Success');
            formInstance.submit();
          } else {
            toast.error(res.data.message);
          }
          setSaving(false);
        })
        .catch((err) => {
          console.log(err);
          setSaving(false);
          toast.error('Failed to reconcile checks');
        });
    }
  };
  const setShowDx = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownload = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaMoneyCheckAlt className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Reconcile Checks</h1>
          </div>
        </div>
      </div>
      <RFForm
        onSubmit={getChecks}
        initialValues={{
          serviceTo: initialServiceTo,
          insurCoId:
            insuranceCompaniesForUser.length > 0
              ? insuranceCompaniesForUser[0].insurcoid
              : 0,
        }}
        render={({ handleSubmit, form, values, submitting }) => {
          formInstance = form;
          return (
            <Form onSubmit={handleSubmit}>
              <div className={styles.grid4}>
                <Field
                  name='insurCoId'
                  label='Account'
                  options={insuranceCompaniesForUser.filter(
                    (ins) => ins.reconcileChecks
                  )}
                  optionMethod={(options: InsuranceCompany[]) =>
                    options.map((o) => (
                      <option key={o.insurcoid} value={o.insurcoid ?? ''}>
                        {`${o.accountnumber} - ${o.shortname}`}
                      </option>
                    ))
                  }
                  onChange={setInsurCoId}
                  validate={requiredField}
                  component={FieldBSRenderSelect}
                />
                <Field
                  name='serviceTo'
                  label='As Of'
                  validate={requiredField}
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />
                <Field
                  name='cleared'
                  type='checkbox'
                  label='Cleared'
                  checked={!!values.cleared}
                  component={FieldBSRenderCheckbox}
                />
                <div>
                  <Button type='submit' variant='primary' size='sm'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Load'
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      />
      <ReconcileCheckTable
        data={checks}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        setSelectedChecks={setSelectedChecks}
      />
      <div className='d-flex justify-content-center align-items-center gap1Rem py-3'>
        <Button
          type='button'
          variant='primary'
          size='sm'
          onClick={() => {
            const request: DevExpressReportRequest = {
              ...requestDx,
              report: DxAvailableReports.rptOutstandingChecks,
              toDate: formInstance.getState().values.serviceTo,
              insurCoId: formInstance.getState().values.insurCoId ?? 1,
              onlyClearedChecks:
                formInstance.getState().values?.cleared ?? false,
            };
            getDxReport(request, setFileDownload, setShowDx);
          }}
          disabled={cleared}
        >
          Print Report
        </Button>
        <Button
          type='button'
          variant='primary'
          size='sm'
          onClick={() => {
            setShowQuickReconcile(true);
          }}
        >
          Quick Rec
        </Button>
        <Button
          type='button'
          variant='primary'
          size='sm'
          onClick={reconcile}
          disabled={Object.keys(rowSelection).length === 0}
        >
          {saving ? (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            'Save Changes'
          )}
        </Button>
      </div>
      <QuickReconcile
        show={showQuickReconcile}
        setShow={setShowQuickReconcile}
        insurCoId={insurCoId}
        insuranceCompaniesForUser={insuranceCompaniesForUser}
      />
    </PageScaffold>
  );
}
