import React from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Colors,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import styles from './index.module.css';
import { ComboBoxHelper } from '../../ApiTypes/ComboBoxHelper';

export default function NonAdjusterPieChart({
  docStatsByStatus,
}: {
  docStatsByStatus: ComboBoxHelper[];
}) {
  ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels, Colors);

  const getOptions = (): any => ({
    stacked: false,
    plugins: {
      datalabels: {
        color: '#8E999F',
        anchor: 'end',
        align: 'start',
        font: {
          weight: 'bold',
        },
      },
      tooltip: {
        callbacks: {},
      },
      legend: {
        position: 'top',
        align: 'center',
      },
      title: {
        text: 'Docs by Status',
        align: 'center',
        font: {
          size: 24,
        },
        display: true,
      },
      colors: {
        enabled: true,
      },
    },
  });

  const getData = (data: ComboBoxHelper[]): any => {
    return {
      labels: data.map((x) => x.display),
      datasets: [
        {
          label: 'Docs by Status',
          data: data.map((x) => x.value),
        },
      ],
    };
  };

  return (
    <div>
      <div className={`${styles.radarChart}`}>
        {docStatsByStatus ? (
          <Pie data={getData(docStatsByStatus)} options={getOptions()} />
        ) : (
          'No Available Data'
        )}
      </div>
    </div>
  );
}
