import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { CheckDetail } from '../ApiTypes/CheckDetail';
import { CheckSaveObj } from '../ApiTypes/CheckSaveObj';
import { CheckSearchRequest } from '../ApiTypes/CheckSearchRequest';
import { DuplicateCheckRequest } from '../ApiTypes/DuplicateCheckRequest';
import { DuplicateTransactionRequest } from '../ApiTypes/DuplicateTransactionRequest';
import { DuplicateTransactionResponse } from '../ApiTypes/DuplicateTransactionResponse';
import { ClaimFinancialHistoryObj } from '../ApiTypes/HistoryObjects';
import { PaymentApprovalGridModel } from '../ApiTypes/PaymentApprovalGridModel';
import { PaymentBatch } from '../ApiTypes/PaymentBatch';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { ScanDocSimple } from '../ApiTypes/ScanDocSimple';
import { SecondaryApprovalModel } from '../ApiTypes/SecondaryApprovalModel';
import { Transaction } from '../ApiTypes/Transaction';
import { TransactionSearchRequest } from '../ApiTypes/TransactionSearchRequest';
import { TransactionSearchResult } from '../ApiTypes/TransactionSearchResult';
import { TransferPaymentRequest } from '../ApiTypes/TransferPaymentRequest';
import { VoidCheckRequest } from '../ApiTypes/VoidCheckRequest';
import { VoidPaymentRequest } from '../ApiTypes/VoidPaymentRequest';
import { VPaymentTab } from '../ApiTypes/VPaymentTab';
import { ReportResponse } from '../ApiTypes/ReportRequest';
import { StopPaymentRequest } from '../ApiTypes/StopPaymentRequest';
import { CheckTransactionStatus } from '../ApiTypes/CheckTransactionStatus';

class PaymentsApi {
  static getPaymentsByClaimNumber(claimNumber: string) {
    return axios.get<VPaymentTab[]>(
      `${API_URL}/api/claims/${claimNumber}/payments`,
      getAuthHeader()
    );
  }
  static getPaymentByTxId(txId: number) {
    return axios.get<Transaction>(
      `${API_URL}/api/payments/${txId}`,
      getAuthHeader()
    );
  }
  static voidPayment(request: VoidPaymentRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/payments/void`,
      request,
      getAuthHeader()
    );
  }
  static stopPaymentRequest(request: StopPaymentRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/payments/stoprequest`,
      request,
      getAuthHeader()
    );
  }
  static getCheckTransactionStatus(txId: number) {
    return axios.get<CheckTransactionStatus>(
      `${API_URL}/api/payments/${txId}/getchecktransactionstatus`,
      getAuthHeader()
    );
  }
  static transferPayment(request: TransferPaymentRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/payments/transfer`,
      request,
      getAuthHeader()
    );
  }
  static checkForDuplicatePayments(request: DuplicateTransactionRequest) {
    return axios.post<DuplicateTransactionResponse>(
      `${API_URL}/api/payments/duplicatecheck`,
      request,
      getAuthHeader()
    );
  }
  static SavePayment(transaction: Transaction) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${transaction.claimNo}/payments`,
      transaction,
      getAuthHeader()
    );
  }
  static GetDocsForTransaction(txId: number) {
    return axios.get<ScanDocSimple[]>(
      `${API_URL}/api/payments/${txId}/docs`,
      getAuthHeader()
    );
  }
  static searchTransaction(request: TransactionSearchRequest) {
    return axios.post<TransactionSearchResult[]>(
      `${API_URL}/api/payments/search`,
      request,
      getAuthHeader()
    );
  }
  static getFinancialHistory(
    claimNumber: string,
    startDate: string,
    endDate: string
  ) {
    return axios.get<ClaimFinancialHistoryObj>(
      `${API_URL}/api/claims/${claimNumber}/payments/financialhistory?startDate=${startDate}&endDate=${endDate}`,
      getAuthHeader()
    );
  }
  static getApprovals(insurCoId: number, onlyMine: boolean) {
    return axios.get<PaymentApprovalGridModel[]>(
      `${API_URL}/api/payments/approvals?insurCoId=${insurCoId}&onlyMine=${onlyMine}`,
      getAuthHeader()
    );
  }
  static approvePayments(txId: number) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/payments/approve/${txId}`,
      {},
      getAuthHeader()
    );
  }
  static unApprovePayment(txId: number) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/payments/unapprove/${txId}`,
      {},
      getAuthHeader()
    );
  }
  static getSecondaryApprovals() {
    return axios.get<SecondaryApprovalModel[]>(
      `${API_URL}/api/payments/secondaryapprovals`,
      getAuthHeader()
    );
  }
  static approveSecondaryApprovals(txId: number) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/payments/secondaryapprovals/${txId}`,
      {},
      getAuthHeader()
    );
  }
  static getTransactionsToPrint() {
    return axios.get<VPaymentTab[]>(
      `${API_URL}/api/payments/transactionstoprint`,
      getAuthHeader()
    );
  }
  static getEorDenials() {
    return axios.get<VPaymentTab[]>(
      `${API_URL}/api/payments/eordenials`,
      getAuthHeader()
    );
  }
  static createPaymentBatch(payments: VPaymentTab[]) {
    return axios.post<ReportResponse[]>(
      `${API_URL}/api/payments/paymentbatch`,
      payments,
      getAuthHeader()
    );
  }
  static printDenials(payments: VPaymentTab[]) {
    return axios.post<ReportResponse>(
      `${API_URL}/api/payments/printdenials`,
      payments,
      getAuthHeader()
    );
  }
  static getPaymentBatches(accountNumber: string) {
    return axios.get<PaymentBatch[]>(
      `${API_URL}/api/payments/paymentbatch?accountNumber=${accountNumber}`,
      getAuthHeader()
    );
  }
  static searchChecks(request: CheckSearchRequest) {
    return axios.post<CheckSaveObj[]>(
      `${API_URL}/api/payments/checks/search`,
      request,
      getAuthHeader()
    );
  }
  static getCheckDetails(checkId: string) {
    return axios.get<CheckDetail[]>(
      `${API_URL}/api/payments/checks/${checkId}/details`,
      getAuthHeader()
    );
  }
  static updateChecksWithNullInsurCo() {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/payments/updatecheckswithnullinsurco`,
      {},
      getAuthHeader()
    );
  }
  static voidOrStopChecks(request: VoidCheckRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/payments/processcheckvoids`,
      request,
      getAuthHeader()
    );
  }
  static duplicateCheck(request: DuplicateCheckRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/payments/checks/duplicatecheck`,
      request,
      getAuthHeader()
    );
  }
  static getPaymentsEnteredToday() {
    return axios.get<VPaymentTab[]>(
      `${API_URL}/api/payments/enteredtoday`,
      getAuthHeader()
    );
  }
  static deletePayment(txId: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/payments/${txId}`,
      getAuthHeader()
    );
  }
}

export default PaymentsApi;
