import { RowSelection } from '@tanstack/react-table';
import React, { useState, useEffect } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaUserNurse } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import DocumentsApi from '../../Api/DocumentsApi';
import { DocumentStatus, NCMDocStatus } from '../../ApiTypes/DocumentStatus';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { NcmUpdateDocumentStatusRequest } from '../../ApiTypes/NcmUpdateDocumentStatusRequest';
import { ScanDoc } from '../../ApiTypes/ScanDoc';
import { UpdateNcmNotesRequest } from '../../ApiTypes/UpdateNcmNotesRequest';
import { useAppSelector } from '../../Reducers/Store';
import BSControlledSelect from '../Common/BSControlledSelect';
import DocViewModal from '../DocViewModal/DocViewModal';
import { useDocViewModal } from '../DocViewModal/useDocViewModal';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import NcmDocumentTable from './NcmDocumentTable';
import UpdateNcmNotesModal from './UpdateNcmNotesModal';

export default function NcmDocuments() {
  const [fetching, setFetching] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<number>(0);
  const [statusId, setStatusId] = useState(1);
  const [scanDocs, setScanDocs] = useState<ScanDoc[]>([]);
  const [selectedScanDoc, setSelectedScanDoc] = useState<ScanDoc | null>(null);
  const [scanDocRowSelection, setScanDocRowSelection] = useState({});
  const [showAddNotes, setShowAddNotes] = useState<boolean>(false);
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );
  const { userName } = useAppSelector((state) => state.user);

  const {
    showDocViewModal,
    fileDownload,
    setShowDocViewModal,
    setFileDownload,
  } = useDocViewModal();

  type docType = {
    description: string;
    id: number;
  };
  const docTypes: docType[] = [
    {
      description: 'Show All',
      id: DocumentStatus.All,
    },
    {
      description: 'New',
      id: DocumentStatus.New,
    },
    {
      description: 'Viewed',
      id: DocumentStatus.PendingApproval,
    },
    {
      description: 'Archived',
      id: DocumentStatus.Rejected,
    },
  ];

  useEffect(() => {
    getDocuments();
  }, [selectedAccount, statusId]);

  useEffect(() => {
    const account =
      insuranceCompaniesForUser.length > 0
        ? insuranceCompaniesForUser[0]
        : null;
    if (account) {
      setSelectedAccount(account.insurcoid);
    }
  }, [insuranceCompaniesForUser]);

  const getDocuments = () => {
    setFetching(true);
    DocumentsApi.getNcmDocuments(statusId, selectedAccount)
      .then((res) => {
        setScanDocs(res.data);
        setFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setFetching(false);
      });
  };

  const handleViewDoc = () => {
    if (selectedScanDoc) {
      getScanDocFile(selectedScanDoc.docId);
    }
  };

  const getScanDocFile = (docId: number) => {
    return DocumentsApi.getScanDocFile(docId)
      .then((res) => {
        if (res.data.file.length) {
          setFileDownload(res.data);
          setShowDocViewModal(true);
        } else {
          toast.error('Failed to get document');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get document');
      });
  };

  const handleAccountChange = (value: string) => {
    const insurcoid = value ? +value : 0;
    setSelectedAccount(insurcoid);
  };

  const handleViewedClick = () => {
    if (selectedScanDoc) {
      const request: NcmUpdateDocumentStatusRequest = {
        docId: selectedScanDoc.docId,
        newType: NCMDocStatus.Viewed,
        notes: `${userName} changed NCM Status to Viewed`,
      };

      return updateDoc(request);
    }
  };

  const handleNewClick = () => {
    if (selectedScanDoc) {
      const request: NcmUpdateDocumentStatusRequest = {
        docId: selectedScanDoc.docId,
        newType: NCMDocStatus.New,
        notes: `${userName} changed NCM Status to New`,
      };

      return updateDoc(request);
    }
  };

  const handleArchiveClick = () => {
    if (selectedScanDoc) {
      const request: NcmUpdateDocumentStatusRequest = {
        docId: selectedScanDoc.docId,
        newType: NCMDocStatus.Archived,
        notes: `${userName} changed NCM Status to Archived`,
      };

      return updateDoc(request);
    }
  };

  const handleDoNotNeedClick = () => {
    if (selectedScanDoc) {
      const request: NcmUpdateDocumentStatusRequest = {
        docId: selectedScanDoc.docId,
        newType: NCMDocStatus.DoNotNeed,
        notes: `${userName} changed NCM Status to Do Not Need`,
      };

      return updateDoc(request);
    }
  };

  const handleUpdateNote = (notes: string) => {
    if (selectedScanDoc) {
      const request: UpdateNcmNotesRequest = {
        docId: selectedScanDoc.docId,
        ncmNotes: notes,
        notes: 'NCM Notes Updated',
      };
      toast('Updating Status', { type: 'info', toastId: request.docId });

      DocumentsApi.updateNcmNotes(request)
        .then((res) => {
          if (!res.data.success) {
            toast.update(request.docId, {
              type: 'error',
              render: res.data.message,
            });
            return;
          }
          getDocuments();
          setShowAddNotes(false);
          toast.update(request.docId, {
            type: 'success',
            render: 'Success',
          });
        })
        .catch((err) => {
          console.log(err);
          toast.update(request.docId, {
            type: 'error',
            render: 'Failed to update ncm notes',
          });
        });
    }
  };

  const updateDoc = (request: NcmUpdateDocumentStatusRequest) => {
    toast('Updating Status', { type: 'info', toastId: request.docId });

    DocumentsApi.updateNcmStatus(request)
      .then((res) => {
        if (!res.data.success) {
          toast.update(request.docId, {
            type: 'error',
            render: res.data.message,
          });
          return;
        }
        getDocuments();
        toast.update(request.docId, {
          type: 'success',
          render: 'Success',
        });
      })
      .catch((err) => {
        console.log(err);
        toast.update(request.docId, {
          type: 'error',
          render: 'Failed to update status',
        });
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaUserNurse className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Nurse Case Management Queue</h1>
            <p>View and approve recently scanned documents</p>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center gap1Rem'>
        <BSControlledSelect
          name='account'
          label='Account'
          onChange={handleAccountChange}
          value={selectedAccount}
          options={insuranceCompaniesForUser}
          optionMethod={(options: InsuranceCompany[]) =>
            options.map((o) => (
              <option key={o.insurcoid} value={o.insurcoid}>
                {`${o.accountnumber} - ${o.shortname}`}
              </option>
            ))
          }
        />
        <BSControlledSelect
          name='statusId'
          label='Status'
          onChange={setStatusId}
          value={statusId}
          options={docTypes}
          optionMethod={(options: docType[]) =>
            options.map((o) => (
              <option key={o.id} value={o.id}>
                {o.description}
              </option>
            ))
          }
        />
        <Button
          type='button'
          variant='primary'
          size='sm'
          onClick={getDocuments}
        >
          {fetching ? (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            'Refresh'
          )}
        </Button>
      </div>
      <NcmDocumentTable
        data={scanDocs}
        rowSelection={scanDocRowSelection}
        setRowSelection={setScanDocRowSelection}
        setSelectedDoc={setSelectedScanDoc}
      />
      <div className='d-flex justify-content-between align-items-center gap1Rem mt-3 pb-3'>
        <Link
          to={`/scandocs/${selectedScanDoc?.docId}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          <Button
            type='button'
            variant='outline-primary'
            size='sm'
            onClick={() => {
              // handleViewDoc();
            }}
          >
            View Document
          </Button>
        </Link>
        <Link to={selectedScanDoc ? `/claims/${selectedScanDoc?.claimno}` : ''}>
          <Button type='button' variant='outline-primary' size='sm'>
            Open Claim
          </Button>
        </Link>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={() => {
            // handleViewedClick();
            if (selectedScanDoc) {
              setShowAddNotes(true);
            }
          }}
        >
          Update NCM Note
        </Button>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={() => {
            handleViewedClick();
          }}
        >
          Viewed
        </Button>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={() => {
            handleNewClick();
          }}
        >
          New
        </Button>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={() => {
            handleArchiveClick();
          }}
        >
          Archive
        </Button>
        <Button
          type='button'
          variant='outline-danger'
          size='sm'
          onClick={() => {
            handleDoNotNeedClick();
          }}
        >
          Do Not Need
        </Button>
      </div>
      <DocViewModal
        show={showDocViewModal}
        setShow={setShowDocViewModal}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
      <UpdateNcmNotesModal
        show={showAddNotes}
        setShow={setShowAddNotes}
        handleSubmit={handleUpdateNote}
        selectedScanDoc={selectedScanDoc}
      />
    </PageScaffold>
  );
}
