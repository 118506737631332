import React, { useState, useEffect, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { useParams } from 'react-router-dom';
import RatingApi from '../../../Api/RatingApi';
import { RatingResponse } from '../../../ApiTypes/RatingResponse';
import { displayDateOnly } from '../../../Utils';
import { Button } from 'react-bootstrap';
import styles from './index.module.css';
import { useAppSelector } from '../../../Reducers/Store';
import { toast } from 'react-toastify';
import { useDocViewModal } from '../../DocViewModal/useDocViewModal';
import PdfModal from '../../DocViewModal/DocViewModal';
import AuditConfirmModal from './AuditConfirmModal';

export default function ClaimReviewSubmissions() {
  const { claimNumber } = useParams();
  const {
    showDocViewModal,
    fileDownload,
    setShowDocViewModal,
    setFileDownload,
  } = useDocViewModal();
  const [responses, setResponses] = useState<RatingResponse[]>([]);
  const [showAuditConfirm, setShowAuditConfirm] = useState(false);

  const { userModel } = useAppSelector((state) => state.user);

  useEffect(() => {
    getRatingsForClaim();
  }, [claimNumber]);

  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => responses, [responses]);

  const handleSubmit = () => {
    return RatingApi.handleRatingSubmitted(claimNumber ?? '')
      .then((res) => {
        setShowAuditConfirm(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleView = (response: RatingResponse) => {
    if (response.filePath) {
      return RatingApi.viewRatingResponse(claimNumber!, response.id)
        .then((res) => {
          setFileDownload(res.data);
          setShowDocViewModal(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error('No file to view');
    }
  };

  const columnData: ColumnDef<RatingResponse>[] = [
    {
      header: '',
      id: 'actions',
      enableColumnFilter: false,
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <div className={styles.actionButtons}>
            <Button
              type='button'
              variant='primary'
              size='sm'
              onClick={() => handleView(row.original)}
            >
              View
            </Button>
            <a
              href={`https://worksheets.georgia-admin.com/public/blank/submit.aspx?key=${row.original.ratingId}&ClaimNumber=${claimNumber}&EmailAddress=${userModel?.user?.emailAddress}&Submitter=${userModel?.user?.userName}&ResponseId=${row.original.id}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button
                type='button'
                variant='primary'
                size='sm'
                onClick={() => setShowAuditConfirm(true)}
              >
                Copy
              </Button>
            </a>
          </div>
        );
      },
    },
    {
      header: 'Name',
      accessorKey: 'ratingName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Comments',
      accessorKey: 'comments',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date Created',
      accessorKey: 'dateSubmitted',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Created By',
      accessorKey: 'submittedBy',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];
  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  const getRatingsForClaim = () => {
    RatingApi.getRatingsForClaim(claimNumber ?? '')
      .then((res) => {
        setResponses(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        selectableRow={true}
        highlightRow={true}
      />
      <PdfModal
        show={showDocViewModal}
        setShow={setShowDocViewModal}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
      <AuditConfirmModal
        show={showAuditConfirm}
        setShow={setShowAuditConfirm}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}
