import { ToastContentProps, toast } from 'react-toastify';
import DocumentsApi from '../../Api/DocumentsApi';
import { Button } from 'react-bootstrap';
import { base64ToBlob } from '../../Utils';

type addPdfType = {
  docTypeId: number;
  claimNumber: string;
  fileName: string;
  base64Data: string;
};

export const handleAddPdfDocumentToClaim = (
  docTypeId: number,
  claimNumber: string,
  fileName: string,
  base64Data: string
) => {
  const blob = base64ToBlob(base64Data, 'application/pdf');
  const fd = new FormData();
  const fileToUpload = new File([blob], fileName, {
    type: 'application/pdf',
  });
  console.log(fileToUpload);
  fd.append('docTypeId', `${docTypeId}`);
  fd.append('claimNumber', claimNumber);
  fd.append('file', fileToUpload);
  return DocumentsApi.uploadDocument(claimNumber, fd)
    .then((res) => {
      if (res.data.success) {
        toast.success(`Saved ${fileName} to Documents`);
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error('Failed to upload document');
    });
};

export const Msg = ({ closeToast, toastProps }: Partial<ToastContentProps>) => (
  <div>
    {(toastProps?.data as { text: string }).text}
    <div className='d-flex justify-content-around align-items-center my-3'>
      <Button
        type='button'
        variant='outline-light'
        size='sm'
        onClick={() => {
          closeToast && closeToast();
          const props = toastProps?.data as addPdfType;
          console.log(props);
          handleAddPdfDocumentToClaim(
            props.docTypeId,
            props.claimNumber,
            props.fileName,
            props.base64Data
          );
        }}
      >
        Save
      </Button>
      <Button
        variant='outline-light'
        size='sm'
        type='button'
        onClick={() => {
          closeToast && closeToast();
        }}
      >
        Cancel
      </Button>
    </div>
  </div>
);
