import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaFolderOpen } from 'react-icons/fa';
import { toast } from 'react-toastify';
import BSSelect from '../Common/BSSelect';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { Adjuster } from '../../ApiTypes/Adjuster';
import ReferenceApi from '../../Api/ReferenceApi';
import AdjustersApi from '../../Api/AdjustersApi';
import { VwDiaryReport } from '../../ApiTypes/VwDiaryReport';
import DiaryTable from './DiaryReportTable';
import ExportApi from '../../Api/ExportApi';
import { downloadExcel } from '../../Utils';
import { diaryExportHandler } from './DiaryExportHandler';

export default function AdjusterDiaryReport() {
  const [adjusters, setAdjusters] = useState<Adjuster[]>([]);
  const [adjusterId, setAdjusterId] = useState<number>(0);
  const [reports, setReports] = useState<VwDiaryReport[]>([]);

  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    getActiveAdjusters();
  }, []);

  const getActiveAdjusters = () => {
    ReferenceApi.getAdjustersActive()
      .then((res) => {
        setAdjusters(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get adjusters');
      });
  };

  const handleAdjusterChange = (id: number) => {
    setAdjusterId(id);
    if (id) {
      getDiaries(id);
    }
  };

  const getDiaries = (id: number) => {
    setFetching(true);
    AdjustersApi.getAdjusterDiaryReport(id)
      .then((res) => {
        setReports(res.data);
        setFetching(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get reports');
        setFetching(false);
      });
  };

  const exportToExcel = () => {
    const exportData = diaryExportHandler(reports);
    exportData.fileName = `DiaryReport.xls`;

    return ExportApi.exportToExcel(exportData)
      .then((res) => {
        downloadExcel(res.data.fileName, res.data.file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaFolderOpen className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Diary Report</h1>
          </div>
        </div>
      </div>
      <div className='d-flex gap1Rem align-items-center'>
        <div>
          <BSSelect
            name='adjusterId'
            label='Adjuster'
            onChange={handleAdjusterChange}
            defaultValue={adjusterId}
            options={[...adjusters].sort((a, b) =>
              a.firstName!.localeCompare(b.firstName!)
            )}
            optionMethod={(options: Adjuster[]) =>
              options.map((o) => (
                <option key={o.adjusterId!} value={o.adjusterId!}>
                  {`${o.firstName} ${o.lastName}`}
                </option>
              ))
            }
          />
        </div>
        <Button
          type='button'
          variant='primary'
          size='sm'
          disabled={!adjusterId}
          onClick={() => {
            getDiaries(adjusterId);
          }}
        >
          {fetching ? (
            <Spinner
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            'Refresh'
          )}
        </Button>
        <div>
          <Button
            type='button'
            size='sm'
            disabled={reports.length === 0}
            onClick={() => {
              exportToExcel();
            }}
          >
            Export
          </Button>
        </div>
      </div>
      <DiaryTable reports={reports} />
    </PageScaffold>
  );
}
