import React, { useEffect, useState } from 'react';
import { FaFolderOpen } from 'react-icons/fa';
import { toast } from 'react-toastify';
import PaymentsApi from '../../Api/PaymentsApi';
import PageScaffold from '../PageScaffold/PageScaffold';
import SecondaryApprovalTable from './SecondaryApprovalTable';
import styles from './index.module.css';
import { SecondaryApprovalModel } from '../../ApiTypes/SecondaryApprovalModel';

export default function SecondaryApproval() {
  const [fetching, setFetching] = useState(false);
  const [approvals, setApprovals] = useState<SecondaryApprovalModel[]>([]);

  useEffect(() => {
    getTransactions();
  }, []);

  const getTransactions = () => {
    setFetching(true);
    PaymentsApi.getSecondaryApprovals()
      .then((res) => {
        setApprovals(res.data);
        setFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setFetching(false);
        toast.error('Failed to get secondary approvals');
      });
  };
  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaFolderOpen className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Secondary Approval</h1>
          </div>
        </div>
      </div>
      <SecondaryApprovalTable
        data={approvals}
        fetching={fetching}
        getTransactions={getTransactions}
      />
    </PageScaffold>
  );
}
