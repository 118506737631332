import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { PharmacyCard } from '../ApiTypes/PharmacyCard';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class PharmacyCardApi {
  static getPharmacyCardsForClaim(claimNumber: string) {
    return axios.get<PharmacyCard[]>(
      `${API_URL}/api/claims/${claimNumber}/pharmacycards`,
      getAuthHeader()
    );
  }
  static deletePharmacyCard(claimNumber: string, cardId: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/pharmacycards/${cardId}`,
      getAuthHeader()
    );
  }
  static addCard(claimNumber: string, data: FormData) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/pharmacycards`,
      data,
      getAuthHeader()
    );
  }
  static updateCard(claimNumber: string, cardId: number, data: FormData) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/pharmacycards/${cardId}`,
      data,
      getAuthHeader()
    );
  }
}

export default PharmacyCardApi;
