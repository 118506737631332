import React from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import styles from './index.module.css';

export default function ConfirmationModal({
  show,
  setShow,
  confirmFunction,
  message,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  confirmFunction: () => void;
  message: string;
}) {
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='ConfirmationModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='ConfirmationModal-modal'>
          <FaCheck className='pe-1' /> Confirm
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p className='text-center'>{message}</p>
          <div className='d-flex justify-content-around mt-3'>
            <Button
              type='button'
              variant='primary'
              size='sm'
              onClick={() => {
                confirmFunction();
              }}
            >
              Confirm
            </Button>
            <Button
              type='button'
              variant='secondary'
              size='sm'
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
