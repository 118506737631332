import React from 'react';
import { Field } from 'react-final-form';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import { parseDatesForServer } from '../../Utils/index';

import styles from './index.module.css';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';

export default function Wc1Benefits({ values }: { values: any }) {
  return (
    <div className='d-flex justify-content-center'>
      <div className='w-50 pe-2'>
        <div
          className={`d-flex justify-content-between align-items-center ${styles.flexGap1rem}`}
        >
          <div className='w-50'>
            <Field
              name='averageWeeklyWage'
              type='text'
              label='Average Weekly Wage'
              component={FieldBSRenderText}
            />
          </div>
          <Field
            name='noDisabilityPaid'
            type='checkbox'
            label='No Disability Paid'
            value={true}
            checked={values?.noDisabilityPaid?.toString() === 'true'}
            component={FieldBSRenderCheckbox}
          />
        </div>
        <div
          className={`d-flex justify-content-between align-items-center ${styles.flexGap1rem}`}
        >
          <div className='w-50'>
            <Field
              name='weeklyBenefit'
              type='text'
              label='Weekly Benefit'
              component={FieldBSRenderText}
            />
          </div>
          <div className='w-50'>
            <Field
              name='controvertDescription'
              type='text'
              label='Benefits Not Paid Because'
              component={FieldBSRenderText}
            />
          </div>
        </div>
        <div
          className={`d-flex justify-content-between align-items-center ${styles.flexGap1rem}`}
        >
          <div className='w-50'>
            <Field
              name='dateOfDisability'
              type='text'
              label='Date of Disability'
              parse={parseDatesForServer}
              component={FieldBSRenderDate}
            />
          </div>
          <div className='w-50'>
            <Field
              name='dateOfFirstPayment'
              type='text'
              label='Date of First Payment'
              parse={parseDatesForServer}
              component={FieldBSRenderDate}
            />
          </div>
        </div>
        <div
          className={`d-flex justify-content-between align-items-center ${styles.flexGap1rem}`}
        >
          <div className='w-50'>
            <Field
              name='compensationPaid'
              type='text'
              label='Compensation Paid'
              component={FieldBSRenderText}
            />
          </div>
          <div className='w-50'>
            <Field
              name='dateSalaryPaid'
              type='text'
              label='Date of Salary Paid'
              parse={parseDatesForServer}
              component={FieldBSRenderDate}
            />
          </div>
        </div>
        <div
          className={`d-flex justify-content-between align-items-center ${styles.flexGap1rem}`}
        >
          <div className='w-50'>
            <Field
              name='penaltyPaid'
              type='text'
              label='Penalty Paid'
              component={FieldBSRenderText}
            />
          </div>
          <div className='w-50'></div>
        </div>
        <div
          className={`d-flex justify-content-between align-items-center ${styles.flexGap1rem}`}
        >
          <div className='w-50'>
            <Field
              name='disabilityLengthLow'
              type='number'
              label='Estimated Disability Length in Weeks (MIN)'
              component={FieldBSRenderText}
            />
          </div>
          <div className='w-50'>
            <Field
              name='disabilityLengthHigh'
              type='number'
              label='Estimated Disability Length in Weeks (MAX)'
              component={FieldBSRenderText}
            />
          </div>
        </div>
        <div
          className={`d-flex justify-content-between align-items-center ${styles.flexGap1rem}`}
        >
          <div className='w-50'>
            <Field
              name='dateBenefitsPayableFrom'
              type='text'
              label='Benefits Payable From'
              component={FieldBSRenderText}
            />
          </div>
          <div className='w-50'>
            <Field
              name='benefitsPayableFor'
              type='text'
              label='Benefits Payable For'
              component={FieldBSRenderText}
            />
          </div>
        </div>
        <div className={`d-flex justify-content-start  ${styles.flexGap1rem}`}>
          <Field
            name='disabilityType'
            type='radio'
            label='Temporary Total Disability'
            value='Temporary Total Disability'
            checked={values?.disabilityType === 'Temporary Total Disability'}
            component={FieldBSRenderCheckbox}
          />
          <Field
            name='disabilityType'
            type='radio'
            label='Temporary Partial Disability'
            value='Temporary Partial Disability'
            checked={values.disabilityType === 'Temporary Partial Disability'}
            component={FieldBSRenderCheckbox}
          />
          <Field
            name='disabilityType'
            type='radio'
            label='Permanent Partial Disability'
            value='Permanent Partial Disability'
            checked={values.disabilityType === 'Permanent Partial Disability'}
            component={FieldBSRenderCheckbox}
          />
        </div>
        <div className={`d-flex justify-content-center  ${styles.flexGap1rem}`}>
          <Field
            name='partialDisabilityPercent'
            type='text'
            label='of'
            component={FieldBSRenderText}
          />
        </div>
      </div>
    </div>
  );
}
