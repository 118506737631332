import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaBriefcase, FaPlusCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LegalApi from '../../Api/LegalApi';
import { Attorney } from '../../ApiTypes/Attorney';
import { LawFirm } from '../../ApiTypes/LawFirm';
import AttorneyAddEditModal from '../AttorneyAddEdit/AttorneyAddEditModal';
import PageScaffold from '../PageScaffold/PageScaffold';
import AttorneyTable from './AttorneyTable';
import styles from './index.module.css';

export default function Attorneys() {
  const { firmId } = useParams();
  const [selectedFirm, setSelectedFirm] = useState<LawFirm | null>(null);
  const [attorneys, setAttorneys] = useState<Attorney[]>([]);
  const [selectedAttorney, setSelectedAttorney] = useState<Attorney | null>(
    null
  );
  const [showAttorneyAddEdit, setShowAttorneyAddEdit] =
    useState<boolean>(false);

  useEffect(() => {
    getLawFirmById();
  }, [firmId]);

  const getLawFirmById = () => {
    if (firmId) {
      getAttorneys(+firmId);
      LegalApi.getLawFirmById(+firmId)
        .then((res) => {
          setSelectedFirm(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get Law Firm');
        });
    }
  };

  const getAttorneys = (firmId: number) => {
    LegalApi.getAttorneysForFirm(firmId)
      .then((res) => {
        setAttorneys(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get attorneys for firm');
      });
  };

  const handleViewAttorney = (atty: Attorney) => {
    setSelectedAttorney(atty);
    setShowAttorneyAddEdit(true);
  };

  const handleNewAttorneyClick = () => {
    setSelectedAttorney(null);
    setShowAttorneyAddEdit(true);
  };

  const handleAttorneyAddEdit = (values: Attorney) => {
    if (!values.firmId) {
      values.firm = null;
    }

    if (values.attorneyId) {
      return LegalApi.updateAttorney(values)
        .then((res) => {
          if (res.data.success) {
            setSelectedAttorney(null);
            setShowAttorneyAddEdit(false);
            getAttorneys(+firmId!);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to update attorney');
        });
    }

    return LegalApi.createAttorney(values)
      .then((res) => {
        if (res.data.success) {
          setSelectedAttorney(null);
          setShowAttorneyAddEdit(false);
          getAttorneys(+firmId!);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create attorney');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaBriefcase className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Attorneys for firm: {selectedFirm?.firmName ?? ''}</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center gap1Rem py-3'>
        <Button
          type='button'
          size='sm'
          variant='primary'
          className='button-icon-text'
          onClick={handleNewAttorneyClick}
        >
          <FaPlusCircle /> New
        </Button>
      </div>
      <AttorneyTable data={attorneys} handleViewAttorney={handleViewAttorney} />
      <>
        {showAttorneyAddEdit && (
          <AttorneyAddEditModal
            show={showAttorneyAddEdit}
            setShow={setShowAttorneyAddEdit}
            attorneyToEdit={selectedAttorney}
            returnToAssign={true}
            setAttorneyToEdit={setSelectedAttorney}
            handleAttorneyAddEdit={handleAttorneyAddEdit}
            handleNewFirmFromAttorneyAddEdit={() => {}}
          />
        )}
      </>
    </PageScaffold>
  );
}
