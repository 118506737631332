import React from 'react';
import { Field } from 'react-final-form';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';

export default function SuspensionPage({
  values,
  disabledFields,
}: {
  values: EdiTransaction;
  disabledFields: string[];
}) {
  return (
    <div>
      <div className='d-flex align-items-center gap1Rem'>
        <Field
          name='benefitSuspendDate'
          label='Benefits will be suspended on'
          parse={parseDatesForServer}
          component={FieldBSRenderDate}
          disabled={disabledFields.includes('benefitSuspendDate')}
        />
      </div>
      <div className='d-flex align-items-center gap1Rem'>
        <Field
          name='initialReturnToWorkDate'
          label='Employee returned to work'
          parse={parseDatesForServer}
          component={FieldBSRenderDate}
          disabled={disabledFields.includes('initialReturnToWorkDate')}
        />
        <Field
          name='physicalRestrictionsIndicator'
          label='With Restrictions'
          type='checkbox'
          checked={values.physicalRestrictionsIndicator}
          component={FieldBSRenderCheckbox}
        />
      </div>
    </div>
  );
}
