import React, { useState, useEffect, useMemo } from 'react';
import { displayDateOnly } from '../../Utils';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  RowSelectionState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import IndeterminateCheckbox from '../Common/IndeterminateCheckbox';
import styles from './index.module.css';
import { VPolicy } from '../../ApiTypes/VPolicy';

export default function VPolicyTable({
  data,
  rowSelection,
  setRowSelection,
  setSelectedPolicies,
}: {
  data: VPolicy[];
  rowSelection: RowSelectionState;
  setRowSelection: (obj: object) => void;
  setSelectedPolicies: (transactions: VPolicy[]) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  const handleRowSelectionChange = () => {
    setSelectedPolicies(
      table.getSelectedRowModel().flatRows.map((r) => r.original)
    );
  };

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<VPolicy>[] = [
    {
      header: ({ table }) => (
        <div className='px-3 d-flex gap1Rem'>
          {Object.keys(rowSelection).length}
          <IndeterminateCheckbox
            className='form-check-input'
            {...{
              id: 'totalRowsSelected',
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      id: 'options',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 85,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center'>
            <IndeterminateCheckbox
              className='form-check-input'
              {...{
                id: row.id,
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        );
      },
    },
    {
      header: 'Policy Id',
      accessorFn: (d) => `${d.policyId}`,
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Policy Number',
      accessorKey: 'policyNo',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Treaty Year',
      accessorFn: (d) => `${d.treatyYear}`,
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Employer',
      accessorKey: 'name',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Start Date',
      accessorKey: 'startDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.startDate ?? ''),
    },
    {
      header: 'End Date',
      accessorKey: 'endDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.endDate ?? ''),
    },
  ];

  const columns = useMemo(() => columnData, [rowSelection]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
      />
    </div>
  );
}
