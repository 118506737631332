import React, { useState, useEffect } from 'react';
import { Field, Form as RFForm } from 'react-final-form';
import { FaCogs } from 'react-icons/fa';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { toast } from 'react-toastify';
import { Button, Form, Spinner } from 'react-bootstrap';
import { SiteFunction } from '../../ApiTypes/SiteFunction';
import SiteFunctionApi from '../../Api/SiteFunctionApi';
import { SiteFunctionGroups } from '../../ApiTypes/SiteFunctions';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { SiteFunctionDefaultGroup } from '../../ApiTypes/SiteFunctionDefaultGroup';
import SiteFunctionDefaultGroupApi from '../../Api/SiteFunctionDefaultGroupApi';
import { UpdateSiteFunctionDefaultGroupRequest } from '../../ApiTypes/UpdateSiteFunctionDefaultGroupRequest';

export default function SiteFunctionGroupSetup() {
  const [siteFunctionDefaultGroups, setSiteFunctionDefaultGroups] = useState<
    SiteFunctionDefaultGroup[]
  >([]);
  const [
    selectedSiteFunctionDefaultGroup,
    setSelectedSiteFunctionDefaultGroup,
  ] = useState<SiteFunctionDefaultGroup | null>(null);
  const [siteFunctions, setSiteFunctions] = useState<SiteFunction[]>([]);

  const [formValues, setFormValues] = useState<{
    siteFunctionDefaultGroupId: number;
    siteFunctionIds: string[];
  }>({
    siteFunctionDefaultGroupId: 0,
    siteFunctionIds: [],
  });

  useEffect(() => {
    getSiteFunctions();
    getSiteFunctionDefaultGroups();
  }, []);

  useEffect(() => {
    handleSetInitialValues();
  }, [selectedSiteFunctionDefaultGroup]);

  const handleSetInitialValues = () => {
    const values = {
      siteFunctionDefaultGroupId: selectedSiteFunctionDefaultGroup?.id ?? 0,
      siteFunctionIds:
        selectedSiteFunctionDefaultGroup?.siteFunctionDefaultGroupFunctions
          .filter((x) => x.enabled)
          .map((x) => `${x.siteFunctionId}`) ?? [],
    };
    setFormValues(values);
  };

  const handleGroupChange = (id: number) => {
    if (id) {
      const selected = siteFunctionDefaultGroups.find((x) => x.id === +id);
      setSelectedSiteFunctionDefaultGroup(selected ?? null);
    } else {
      setSelectedSiteFunctionDefaultGroup(null);
    }
  };

  const getSiteFunctions = () => {
    SiteFunctionApi.list()
      .then((res) => {
        setSiteFunctions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSiteFunctionDefaultGroups = () => {
    SiteFunctionDefaultGroupApi.list()
      .then((res) => {
        setSiteFunctionDefaultGroups(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = (values: {
    siteFunctionDefaultGroupId: string;
    siteFunctionIds: string[];
  }) => {
    const request: UpdateSiteFunctionDefaultGroupRequest = {
      siteFunctionDefaultGroupId: +values.siteFunctionDefaultGroupId,
      siteFunctionIds: values.siteFunctionIds.map((x) => +x),
    };
    return SiteFunctionDefaultGroupApi.update(request)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          getSiteFunctionDefaultGroups();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaCogs className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Site Function Group Setup</h1>
          </div>
          <div className='ms-3'></div>
        </div>
      </div>
      <RFForm
        onSubmit={onSubmit}
        initialValues={formValues}
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div>
                <div className='d-flex justify-content-center'>
                  <Field
                    name='siteFunctionDefaultGroupId'
                    label='Group'
                    options={siteFunctionDefaultGroups}
                    onChange={handleGroupChange}
                    optionMethod={(options: SiteFunctionDefaultGroup[]) =>
                      options.map((o) => (
                        <option key={o.id} value={o.id}>
                          {o.name}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  />
                </div>
                <fieldset className='mb-3'>
                  <legend className='fw-bold fs-3'>Claim Functions</legend>
                  <div className={`${styles.grid5NoHeight}`}>
                    {siteFunctions
                      .filter((x) => x.groupId === SiteFunctionGroups.Claims)
                      .map((x) => {
                        return (
                          <Field
                            key={`${x.siteFunctionId}`}
                            name='siteFunctionIds'
                            type='checkbox'
                            label={`${x.functionName}`}
                            value={`${x.siteFunctionId}`}
                            checked={values.siteFunctionIds.includes(
                              `${x.siteFunctionId}`
                            )}
                            component={FieldBSRenderCheckbox}
                          />
                        );
                      })}
                  </div>
                </fieldset>
                <fieldset className='my-3'>
                  <legend className='fw-bold fs-3'>Report Functions</legend>
                  <div className={`${styles.grid5NoHeight}`}>
                    {siteFunctions
                      .filter((x) => x.groupId === SiteFunctionGroups.Reports)
                      .map((x) => {
                        return (
                          <Field
                            key={`${x.siteFunctionId}`}
                            name='siteFunctionIds'
                            type='checkbox'
                            label={`${x.functionName}`}
                            value={`${x.siteFunctionId}`}
                            checked={values.siteFunctionIds.includes(
                              `${x.siteFunctionId}`
                            )}
                            component={FieldBSRenderCheckbox}
                          />
                        );
                      })}
                  </div>
                </fieldset>
                <fieldset className='my-3'>
                  <legend className='fw-bold fs-3'>Document Functions</legend>
                  <div className={`${styles.grid5NoHeight}`}>
                    {siteFunctions
                      .filter((x) => x.groupId === SiteFunctionGroups.Documents)
                      .map((x) => {
                        return (
                          <Field
                            key={`${x.siteFunctionId}`}
                            name='siteFunctionIds'
                            type='checkbox'
                            label={`${x.functionName}`}
                            value={`${x.siteFunctionId}`}
                            checked={values.siteFunctionIds.includes(
                              `${x.siteFunctionId}`
                            )}
                            component={FieldBSRenderCheckbox}
                          />
                        );
                      })}
                  </div>
                </fieldset>
                <fieldset className='my-3'>
                  <legend className='fw-bold fs-3'>Payment Functions</legend>
                  <div className={`${styles.grid5NoHeight}`}>
                    {siteFunctions
                      .filter((x) => x.groupId === SiteFunctionGroups.Payments)
                      .map((x) => {
                        return (
                          <Field
                            key={`${x.siteFunctionId}`}
                            name='siteFunctionIds'
                            type='checkbox'
                            label={`${x.functionName}`}
                            value={`${x.siteFunctionId}`}
                            checked={values.siteFunctionIds.includes(
                              `${x.siteFunctionId}`
                            )}
                            component={FieldBSRenderCheckbox}
                          />
                        );
                      })}
                  </div>
                </fieldset>
                <fieldset className='my-3'>
                  <legend className='fw-bold fs-3'>Provider Functions</legend>
                  <div className={`${styles.grid5NoHeight}`}>
                    {siteFunctions
                      .filter((x) => x.groupId === SiteFunctionGroups.Providers)
                      .map((x) => {
                        return (
                          <Field
                            key={`${x.siteFunctionId}`}
                            name='siteFunctionIds'
                            type='checkbox'
                            label={`${x.functionName}`}
                            value={`${x.siteFunctionId}`}
                            checked={values.siteFunctionIds.includes(
                              `${x.siteFunctionId}`
                            )}
                            component={FieldBSRenderCheckbox}
                          />
                        );
                      })}
                  </div>
                </fieldset>
                <fieldset className='my-3'>
                  <legend className='fw-bold fs-3'>WCPanel Functions</legend>
                  <div className={`${styles.grid5NoHeight}`}>
                    {siteFunctions
                      .filter((x) => x.groupId === SiteFunctionGroups.WCPanels)
                      .map((x) => {
                        return (
                          <Field
                            key={`${x.siteFunctionId}`}
                            name='siteFunctionIds'
                            type='checkbox'
                            label={`${x.functionName}`}
                            value={`${x.siteFunctionId}`}
                            checked={values.siteFunctionIds.includes(
                              `${x.siteFunctionId}`
                            )}
                            component={FieldBSRenderCheckbox}
                          />
                        );
                      })}
                  </div>
                </fieldset>
                <fieldset className='my-3'>
                  <legend className='fw-bold fs-3'>Setup Functions</legend>
                  <div className={`${styles.grid5NoHeight}`}>
                    {siteFunctions
                      .filter((x) => x.groupId === SiteFunctionGroups.Setup)
                      .map((x) => {
                        return (
                          <Field
                            key={`${x.siteFunctionId}`}
                            name='siteFunctionIds'
                            type='checkbox'
                            label={`${x.functionName}`}
                            value={`${x.siteFunctionId}`}
                            checked={values.siteFunctionIds.includes(
                              `${x.siteFunctionId}`
                            )}
                            component={FieldBSRenderCheckbox}
                          />
                        );
                      })}
                  </div>
                </fieldset>
              </div>
              <div className='d-flex justify-content-center align-items-center py-3'>
                <Button type='submit' size='sm' variant='primary'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    </PageScaffold>
  );
}
