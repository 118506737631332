import { addYears, format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaMoneyCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ClaimantApi from '../../Api/ClaimantApi';
import ClaimApi from '../../Api/ClaimApi';
import DocumentsApi from '../../Api/DocumentsApi';
import ReservesApi from '../../Api/ReservesApi';
import SettingApi from '../../Api/SettingApi';
import { Claim } from '../../ApiTypes/Claim';
import { Claimant } from '../../ApiTypes/Claimant';
import { ClaimPaymentTotals } from '../../ApiTypes/ClaimPaymentTotals';
import { ClaimReserveBuckets } from '../../ApiTypes/ClaimReserveBuckets';
import { DocumentStatus } from '../../ApiTypes/DocumentStatus';
import { DocumentUpdateStatusRequest } from '../../ApiTypes/DocumentUpdateStatusRequest';
import { ScanDoc } from '../../ApiTypes/ScanDoc';
import { ServerSetting } from '../../ApiTypes/ServerSetting';
import BSControlledSelect from '../Common/BSControlledSelect';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import StraightPayForm from './StraightPayForm';
import StraightPayTable from './StraightPayTable';

export default function StraightPay() {
  type docType = {
    description: string;
    id: number;
  };

  const [yearOptions, setYearOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [selectedYear, setSelectedYear] = useState<string>(
    format(addYears(new Date(), -3), 'yyyy-MM-dd')
  );

  const [statusId, setStatusId] = useState(6);
  const [fetching, setFetching] = useState<boolean>(false);
  const [scanDocs, setScanDocs] = useState<ScanDoc[]>([]);
  const [selectedDoc, setSelectedDoc] = useState<ScanDoc | null>(null);
  const [rowSelection, setRowSelection] = React.useState({});
  const [straightPaySetting, setStraightPaySetting] =
    useState<ServerSetting | null>(null);
  const [claimReserveBuckets, setClaimReserveBuckets] = useState<
    ClaimReserveBuckets[]
  >([]);
  const [claimPaymentTotals, setClaimPaymentTotals] =
    useState<ClaimPaymentTotals | null>(null);

  const [claimant, setClaimant] = useState<Claimant | null>(null);
  const [claim, setClaim] = useState<Claim | null>(null);

  useEffect(() => {
    getDocuments();
    loadYears();
  }, [statusId, selectedYear]);

  useEffect(() => {
    getStraightPayReminderSetting();
  }, []);

  useEffect(() => {
    handleLoadDocument();
  }, [selectedDoc]);

  const docTypes: docType[] = [
    {
      description: 'Show All',
      id: DocumentStatus.All,
    },
    {
      description: 'New',
      id: DocumentStatus.SentToStraightPay,
    },
    {
      description: 'Hold',
      id: DocumentStatus.StraightOnHold,
    },
    {
      description: 'Paid',
      id: DocumentStatus.StraightPayApproved,
    },
    {
      description: 'Denied',
      id: DocumentStatus.StraightPayDenied,
    },
  ];

  const loadYears = () => {
    const today = new Date();
    const thisYear = today.getFullYear();
    const diff = thisYear - 2012;
    const years: { value: string; label: string }[] = [];
    for (let i = 1; i <= diff; i++) {
      years.push({
        value: format(addYears(today, -i), 'yyyy-MM-dd'),
        label: `${i}`,
      });
    }
    setYearOptions(years);
  };

  const handleLoadDocument = () => {
    if (selectedDoc) {
      getClaimant(selectedDoc.claimno ?? '');
      getClaim(selectedDoc.claimno ?? '');
      getReserveBucketsForClaim(selectedDoc.claimno ?? '');
      getClaimPaymentTotals(selectedDoc.claimno ?? '');
    } else {
      setClaimant(null);
      setClaim(null);
      setClaimReserveBuckets([]);
      setClaimPaymentTotals(null);
    }
  };

  const getReserveBucketsForClaim = (claimNumber: string) => {
    return ReservesApi.getClaimReserveBucketsForClaim(claimNumber)
      .then((res) => {
        setClaimReserveBuckets(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClaimPaymentTotals = (claimNumber: string) => {
    ReservesApi.getClaimPaymentTotals(claimNumber)
      .then((res) => {
        setClaimPaymentTotals(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getClaimant = (claimNumber: string) => {
    ClaimantApi.getClaimantByClaimNumber(claimNumber)
      .then((res) => {
        setClaimant(res.data);
      })
      .catch((err) => {
        console.log(err);
        setClaimant(null);
        toast.error('Failed to get Claimant');
      });
  };

  const getClaim = (claimNumber: string) => {
    ClaimApi.getClaimByClaimNumber(claimNumber)
      .then((res) => {
        setClaim(res.data);
      })
      .catch((err) => {
        console.log(err);
        setClaim(null);
        toast.error('Failed to get claim');
      });
  };

  const getDocuments = async () => {
    setRowSelection({});
    setSelectedDoc(null);
    try {
      setFetching(true);
      const res = await DocumentsApi.fullListForStraightPay(
        statusId,
        selectedYear,
        
      );

      setScanDocs(res.data);
      setFetching(false);
    } catch (err) {
      console.log(err);
      setFetching(false);
    }
  };

  const getStraightPayReminderSetting = () => {
    SettingApi.getSettingByName({ settingName: 'StraightPayReminder' })
      .then((res) => [setStraightPaySetting(res.data)])
      .catch((err) => {
        console.log(err);
      });
  };

  const updateStraightPayReminder = () => {
    if (straightPaySetting) {
      const updated = {
        ...straightPaySetting,
        value:
          straightPaySetting?.value?.toLocaleLowerCase() === 'true'
            ? 'False'
            : 'True',
      };
      SettingApi.updateSetting(updated)
        .then((res) => {
          if (res.data.success) {
            getStraightPayReminderSetting();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to update reminder');
        });
    }
  };

  const handleSendToCompIq = () => {
    if (selectedDoc) {
      const data: DocumentUpdateStatusRequest = {
        docId: selectedDoc.docId,
        docStatusId: DocumentStatus.SentToCompIQ,
        claimNumber: selectedDoc.claimno ?? '',
        note: 'Sent to Bill Review By Straight Pay',
      };
      return updateDoc(data);
    }
  };

  const handleHold = () => {
    if (selectedDoc) {
      const data: DocumentUpdateStatusRequest = {
        docId: selectedDoc.docId,
        docStatusId: DocumentStatus.StraightOnHold,
        claimNumber: selectedDoc.claimno ?? '',
        note: 'Straight Pay placed this Bill on Hold.',
      };
      return updateDoc(data);
    }
  };

  const handleStraightPayDenied = () => {
    if (selectedDoc) {
      const data: DocumentUpdateStatusRequest = {
        docId: selectedDoc.docId,
        docStatusId: DocumentStatus.StraightPayDenied,
        claimNumber: selectedDoc.claimno ?? '',
        note: 'Straight Pay did not recognize this as a valid Straight Pay Bill',
      };
      return updateDoc(data);
    }
  };
  const handleDuplicate = () => {
    if (selectedDoc) {
      const data: DocumentUpdateStatusRequest = {
        docId: selectedDoc.docId,
        docStatusId: DocumentStatus.StraightPayDuplicate,
        claimNumber: selectedDoc.claimno ?? '',
        note: 'Straight Pay marked this bill as Duplicate Bill.',
      };
      return updateDoc(data);
    }
  };

  const handleAlreadyPaid = () => {
    if (selectedDoc) {
      const data: DocumentUpdateStatusRequest = {
        docId: selectedDoc.docId,
        docStatusId: DocumentStatus.StraightPayApproved,
        claimNumber: selectedDoc.claimno ?? '',
        note: 'Marked Approved By Straight Pay. NOTE: Already Paid.',
      };
      return updateDoc(data);
    }
  };

  const updateDoc = (request: DocumentUpdateStatusRequest) => {
    toast('Updating Status', { type: 'info', toastId: request.docId });

    return DocumentsApi.updateStatus(request)
      .then((res) => {
        if (!res.data.success) {
          toast.update(request.docId, {
            type: 'error',
            render: res.data.message,
          });
          return res.data;
        }
        getDocuments();
        toast.update(request.docId, {
          type: 'success',
          render: 'Success',
        });
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        toast.update(request.docId, {
          type: 'error',
          render: 'Failed to update status',
        });

        return {
          success: false,
          message: 'Failed to update status',
          affectedEntityIdentifier: '',
        };
      });
  };

  const handleCancelClick = () => {
    setRowSelection({});
  };

  return (
    <PageScaffold>
      <div className={styles.header}>
        <div className={`${styles.pageTop} bg-light`}>
          <div className='ps-3 d-flex'>
            <FaMoneyCheck className='fs-1 text-primary ' />
            <div className='ms-3'>
              <h3>Straight Pay</h3>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-around align-items-center'>
          <div className='d-flex justify-content-center align-items-center gap1Rem'>
            <BSControlledSelect
              name='statusId'
              label='Status'
              onChange={setStatusId}
              value={statusId}
              options={docTypes}
              optionMethod={(options: docType[]) =>
                options.map((o) => (
                  <option key={o.id} value={o.id}>
                    {o.description}
                  </option>
                ))
              }
            />
            <BSControlledSelect
              name='numberOfYears'
              label='Number of Years'
              onChange={setSelectedYear}
              value={selectedYear}
              options={yearOptions}
              optionMethod={(options: { value: string; label: string }[]) =>
                options.map((o) => (
                  <option key={o.value} value={o.value}>
                    {o.label}
                  </option>
                ))
              }
            />
            <Button
              type='button'
              variant='primary'
              size='sm'
              onClick={getDocuments}
            >
              {fetching ? (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              ) : (
                'Refresh'
              )}
            </Button>
          </div>
          <div className='form-check'>
            <label className='form-check-label'>
              <input
                name='straightPayReminder'
                type='checkbox'
                className='form-check-input'
                checked={
                  straightPaySetting?.value?.toLocaleLowerCase() === 'true'
                }
                onChange={(e) => {
                  updateStraightPayReminder();
                }}
              />
              Enable Reminder When New Bill Added
            </label>
          </div>
        </div>
        <div className={styles.content}>
          <StraightPayTable
            data={scanDocs}
            getDocs={getDocuments}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            setSelectedDoc={setSelectedDoc}
          />
        </div>
      </div>
      <div className={styles.footer}>
          <StraightPayForm
            claim={claim}
            claimant={claimant}
            selectedDoc={selectedDoc}
            claimPaymentTotals={claimPaymentTotals}
            claimReserveBuckets={claimReserveBuckets}
            handleSendToCompIq={handleSendToCompIq}
            handleHold={handleHold}
            handleStraightPayDenied={handleStraightPayDenied}
            handleDuplicate={handleDuplicate}
            handleAlreadyPaid={handleAlreadyPaid}
            handleCancelClick={handleCancelClick}
            getDocuments={getDocuments}
            updateDoc={updateDoc}
          />
        </div>
    </PageScaffold>
  );
}
