import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaCheckSquare } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ChecksApi from '../../Api/ChecksApi';
import ExportApi from '../../Api/ExportApi';
import { CheckImportObject } from '../../ApiTypes/CheckImportObject';
import { downloadExcel } from '../../Utils';
import PageScaffold from '../PageScaffold/PageScaffold';
import ImportCheckNumberTable from './ImportCheckNumberTable';
import styles from './index.module.css';

export default function ImportCheckNumbers() {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [gettingDifference, setGettingDifference] = useState<boolean>(false);
  const [data, setData] = useState<CheckImportObject[]>([]);
  const [pasteData, setPasteData] = useState<string>('');

  const handlePasteChange = (value: string) => {
    setPasteData(value);
    if (value) {
      parseData(value);
    } else {
      setData([]);
    }
  };

  const parseData = (value: string) => {
    const trimmed = value.trimEnd();
    const split = trimmed.split('\n');
    const imports: CheckImportObject[] = [];
    if (split.length > 0) {
      const columns = split[0].split('\t');
      for (let i = 1; i < split.length; i++) {
        const row = split[i];
        const r = row.split('\t');
        const ck: Partial<CheckImportObject> = {};
        for (let h = 0; h < columns.length; h++) {
          const header = columns[h].toUpperCase();
          if (header.includes('ADDRESS1')) {
            ck.address = r[h];
          }
          if (header.includes('CHECKNUMBER')) {
            ck.checkNumber = r[h];
          }
          if (header.includes('CLAIM')) {
            ck.claimNo = r[h];
          }
          if (header.includes('AMOUNT')) {
            ck.strAmount = r[h];
          }
          if (header.includes('MAILED')) {
            ck.strCheckDate = r[h];
          }
          if (header.includes('SERVICEFROM')) {
            ck.strServiceFrom = r[h];
          }
          if (header.includes('SERVICETO')) {
            ck.strServiceTo = r[h];
          }
          if (header.includes('TXID')) {
            ck.strTransactionId = r[h];
          }
        }
        imports.push(ck as CheckImportObject);
      }
    }
    setData(imports);
    setPasteData('');
  };

  const updateMayo = () => {
    setSubmitting(true);
    ChecksApi.updateMayo({ checks: data })
      .then((res) => {
        if (res.data.countSkipped === 0) {
          toast.success(`${res.data.countImported} checks updated!`);
        } else {
          toast.error(res.data.errorMessage);
        }
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
        toast.error('Update Failed');
      });
  };

  const discoverDifferences = () => {
    setGettingDifference(true);
    ChecksApi.getDifferences({ checks: data })
      .then((res) => {
        setData(res.data);
        setGettingDifference(false);
      })
      .catch((err) => {
        console.log(err);
        setGettingDifference(false);
        toast.error('Failed to get differences');
      });
  };

  const exportHandler = (data: CheckImportObject[]) => {
    const columns = [
      'Claim Number',
      'Check Number',
      'Str Amount',
      'Amount',
      'Str Check Date',
      'Check Date',
      'Str Service From',
      'Service From',
      'Str Service To',
      'Service To',
      'Str Transaction Id',
      'Transaction Id',
      'Address',
    ];

    const rows: any[][] = [];

    data.forEach((t) => {
      const tRow = [
        t.claimNo ?? '',
        t.checkNumber ?? '',
        `${t.strAmount ?? ''}`,
        `${t.amount ?? ''}`,
        t.strCheckDate ?? '',
        t.checkDate ?? '',
        t.strServiceFrom ?? '',
        t.serviceFrom ?? '',
        t.strServiceTo ?? '',
        t.serviceTo ?? '',
        t.strTransactionId ?? '',
        `${t.transactionId ?? ''}`,
        t.address ?? '',
      ];

      rows.push(tRow);
    });

    return {
      columns,
      rows,
      fileName: '',
    };
  };

  const handleExport = () => {
    var toSend = exportHandler(data);
    toSend.fileName = `Differences.xls`;
    return ExportApi.exportToExcel(toSend)
      .then((res) => {
        downloadExcel(res.data.fileName, res.data.file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaCheckSquare className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Import Check Numbers</h1>
          </div>
        </div>
      </div>
      <div className={styles.pasteBin}>
        <div>
          <label htmlFor='pasteBin' className='form-label fs-6  m-0'>
            Paste Here
          </label>
          <div>
            <textarea
              name='pasteBin'
              rows={3}
              className='form-control form-control-sm'
              placeholder='Paste Here'
              value={pasteData}
              onChange={(e) => {
                const { value } = e.target;
                handlePasteChange(value);
              }}
              disabled={false}
            />
          </div>
        </div>
      </div>
      <ImportCheckNumberTable data={data} />
      <div className='d-flex justify-content-around align-items-center py-3'>
        <Button
          type='button'
          variant='primary'
          size='sm'
          disabled={data.length === 0}
          onClick={handleExport}
        >
          Export
        </Button>
        <Button
          type='button'
          variant='primary'
          size='sm'
          onClick={discoverDifferences}
          disabled={data.length === 0}
        >
          {gettingDifference ? (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            'Differences'
          )}
        </Button>
        <Button
          type='button'
          variant='primary'
          size='sm'
          onClick={updateMayo}
          disabled={data.length === 0}
        >
          {submitting ? (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            'Import Data'
          )}
        </Button>
      </div>
    </PageScaffold>
  );
}
