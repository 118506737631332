import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ClaimApi from '../../Api/ClaimApi';
import ReservesApi from '../../Api/ReservesApi';
import { ClaimPaymentTotals } from '../../ApiTypes/ClaimPaymentTotals';
import { ClaimReserveBuckets } from '../../ApiTypes/ClaimReserveBuckets';
import {
  ClaimReservesWorksheet,
  ReserveWorksheetStatus,
} from '../../ApiTypes/ClaimReservesWorksheet';
import { ClaimReservesWorksheetObj } from '../../ApiTypes/ClaimReservesWorksheetObj';
import { FullClaim } from '../../ApiTypes/FullClaim';
import PageScaffold from '../PageScaffold/PageScaffold';
import ReserveWorksheetModal from '../ReserveWorksheet/ReserveWorksheetModal';
import ApproveReservesTable from './ApproveReservesTable';
import styles from './index.module.css';
import { PtdResponse } from '../../ApiTypes/PtdResponse';

export default function ApproveReserves() {
  const [fetching, setFetching] = useState<boolean>(false);
  const [rejecting, setRejecting] = useState<boolean>(false);
  const [radioType, setRadioType] = useState<string>('2');
  const [data, setData] = useState<ClaimReservesWorksheetObj[]>([]);
  const [filtered, setFiltered] = useState<ClaimReservesWorksheetObj[]>([]);

  const [showReserveModal, setShowReserveModal] = useState<boolean>(false);
  const [selectedWs, setSelectedWs] = useState<ClaimReservesWorksheet | null>(
    null
  );

  const [selectedSheets, setSelectedSheets] = useState<
    ClaimReservesWorksheetObj[]
  >([]);

  const [fullClaim, setFullClaim] = useState<FullClaim | null>(null);
  const [claimPaymentTotals, setClaimPaymentTotals] =
    useState<ClaimPaymentTotals | null>(null);
  const [claimReserveBuckets, setClaimReserveBuckets] = useState<
    ClaimReserveBuckets[]
  >([]);
  const [indemnityBucket, setIndemnityBucket] =
    useState<ClaimReserveBuckets | null>(null);
  const [medicalBucket, setMedicalBucket] =
    useState<ClaimReserveBuckets | null>(null);
  const [legalBucket, setLegalBucket] = useState<ClaimReserveBuckets | null>(
    null
  );
  const [expenseBucket, setExpenseBucket] =
    useState<ClaimReserveBuckets | null>(null);

  const [ptdResponse, setPtdResponse] = useState<PtdResponse | null>(null);

  useEffect(() => {
    getSheets();
  }, []);
  useEffect(() => {
    filterData();
  }, [radioType]);
  useEffect(() => {
    filterData();
  }, [data]);

  const filterData = () => {
    const x = data.filter((f) => f.status === +radioType);
    setFiltered(x);
  };

  const handleEdit = (wsObj: ClaimReservesWorksheetObj) => {
    ReservesApi.getWorksheetById(wsObj.id)
      .then((res) => {
        setSelectedWs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    ClaimApi.getFullClaimByClaimNumber(wsObj.claimNo ?? '')
      .then((res) => {
        setFullClaim(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    ReservesApi.getClaimReserveBucketsForClaim(wsObj.claimNo ?? '')
      .then((res) => {
        setClaimReserveBuckets(res.data);
        const i = res.data.find((b) => b.description === 'Indemnity');
        const m = res.data.find((b) => b.description === 'Medical');
        const l = res.data.find((b) => b.description === 'Legal');
        const e = res.data.find((b) => b.description === 'Expense');

        setIndemnityBucket(i ?? null);
        setMedicalBucket(m ?? null);
        setLegalBucket(l ?? null);
        setExpenseBucket(e ?? null);
      })
      .catch((err) => {
        console.log(err);
      });
    ReservesApi.getClaimPaymentTotals(wsObj.claimNo ?? '')
      .then((res) => {
        setClaimPaymentTotals(res.data);
      })
      .catch((err) => console.log(err));

    ReservesApi.getPtdForClaim(wsObj.claimNo ?? '')
      .then((res) => {
        setPtdResponse(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    setShowReserveModal(true);
  };

  const getSheets = () => {
    setFetching(true);
    ReservesApi.getWorksheetsForUser()
      .then((res) => {
        setData(res.data);
        setFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setFetching(false);
      });
  };

  const rejectClick = () => {
    if (selectedSheets.length === 0) {
      toast.error('Please select a worksheet');
    }
    setRejecting(true);
    Promise.all(
      selectedSheets.map((ws) => {
        ws.status = ReserveWorksheetStatus.RejectedBySupervisor;
        return ReservesApi.updateSavedClaimReservesWorksheet(
          ws as ClaimReservesWorksheet
        )
          .then((res) => {
            if (res.data.success) {
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            toast.error('Failed to reject worksheet');
            console.log(err);
          });
      })
    )
      .then(() => {
        setRejecting(false);
        getSheets();
      })
      .catch(() => setRejecting(false));
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaCheck className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>
              Approve Reserve Worksheets{' '}
              {fetching && (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                  className='mb-2'
                />
              )}
            </h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center gap1Rem my-3'>
        <div className='form-check'>
          <label className='form-check-label'>
            <input
              type='radio'
              name='waiting'
              className='form-check-input'
              checked={radioType === '2'}
              onChange={(e) => {
                const { checked } = e.target;
                if (checked) {
                  setRadioType('2');
                }
              }}
            />
            Waiting for Approval
          </label>
        </div>

        <div className='form-check'>
          <label className='form-check-label'>
            <input
              type='radio'
              name='approved'
              className='form-check-input'
              checked={radioType === '4'}
              onChange={(e) => {
                const { checked } = e.target;
                if (checked) {
                  setRadioType('4');
                }
              }}
            />
            Approved
          </label>
        </div>
        <div className='form-check'>
          <label className='form-check-label'>
            <input
              type='radio'
              name='rejected'
              className='form-check-input'
              checked={radioType === '6'}
              onChange={(e) => {
                const { checked } = e.target;
                if (checked) {
                  setRadioType('6');
                }
              }}
            />
            Rejected
          </label>
        </div>
        <div className='d-flex align-items-center gap1Rem ms-5'>
          <Button
            type='button'
            variant='outline-danger'
            size='sm'
            disabled={radioType !== '2'}
            onClick={rejectClick}
          >
            {rejecting ? (
              <Spinner
                as='span'
                animation='grow'
                size='sm'
                role='status'
                aria-hidden='true'
                className='mb-2'
              />
            ) : (
              'Reject'
            )}
          </Button>
        </div>
      </div>
      <ApproveReservesTable
        data={filtered}
        handleEdit={handleEdit}
        radioType={radioType}
        selectedSheets={selectedSheets}
        setSelectedSheets={setSelectedSheets}
      />
      <ReserveWorksheetModal
        show={showReserveModal}
        setShow={setShowReserveModal}
        claim={fullClaim?.claim ?? null}
        claimant={fullClaim?.claimant ?? null}
        employer={fullClaim?.employer ?? null}
        selectedWorksheet={selectedWs}
        getClaimReservesWorksheets={getSheets}
        setSelectedWorksheet={setSelectedWs}
        indemnityBucket={indemnityBucket}
        medicalBucket={medicalBucket}
        legalBucket={legalBucket}
        expenseBucket={expenseBucket}
        claimPaymentTotals={claimPaymentTotals}
        getReserveBucketsForClaim={() => {}}
        getClaimPaymentTotals={() => {}}
        ptdResponse={ptdResponse}
      />
    </PageScaffold>
  );
}
