import React from 'react';
import { saveAs } from 'file-saver';
import { base64ToBlob } from '../../Utils';
import styles from './index.module.css';
import { Button } from 'react-bootstrap';
import { FaFileDownload } from 'react-icons/fa';

export default function JpgComponent({
  base64Data,
  fileName,
}: {
  base64Data: string;
  fileName: string;
}) {
  const download = () => {
    const blob = base64ToBlob(base64Data, 'application/jpg');

    saveAs(blob, fileName);
  };

  if (base64Data) {
    return (
      <div>
        <div className='d-flex justify-content-center align-items-center mb-3'>
          <Button
            type='button'
            onClick={download}
            variant='outline-primary'
            className='button-icon-text'
            style={{
              width: 'auto',
            }}
            size='sm'
            title='Download'
          >
            <FaFileDownload /> Download
          </Button>
        </div>
        <img src={`data:application/jpg;base64,${base64Data}`} alt='Image' />
      </div>
    );
  }
  return <p className={styles.noResults}>Document Not Available</p>;
}
