import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  FilterFn,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { RecurringPayment } from '../../../ApiTypes/RecurringPayment';
import { Button, Form } from 'react-bootstrap';
import { FaEdit, FaEye, FaShare } from 'react-icons/fa';
import accounting from 'accounting';
import { displayDateOnly } from '../../../Utils';
import { differenceInWeeks } from 'date-fns';

const booleanFilter: FilterFn<RecurringPayment> = (
  row,
  columnId,
  value,
  addMeta
) => {
  let item = row.getValue(columnId) as string;
  if (item === null) {
    item = 'false';
  } else {
    item = item.toString();
  }
  return item.includes(value.toLowerCase());
};

export default function RecurringPaymentsTable({
  recurringPayments,
  selectedRP,
  setSelectedRP,
  handleSendDetails,
  setShowRecurringPaymentDetail,
  setShowRecurringPaymentWeeklyDetailModal,
}: {
  recurringPayments: RecurringPayment[];
  selectedRP: RecurringPayment | null;
  setShowRecurringPaymentWeeklyDetailModal: (show: boolean) => void;
  setSelectedRP: (recurringPayment: RecurringPayment | null) => void;
  setShowRecurringPaymentDetail: (show: boolean) => void;
  handleSendDetails: (rp: RecurringPayment) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => recurringPayments, [recurringPayments]);

  const findNumberOfWeeks = (
    startDate: Date,
    endDate: Date,
    lastPaidDate: Date | null
  ) => {
    if (lastPaidDate) {
      return differenceInWeeks(lastPaidDate, startDate, {
        roundingMethod: 'floor',
      });
    }
    return 0;
  };

  const columnData: ColumnDef<RecurringPayment>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      size: 100,
      cell: (d) => {
        return (
          <div
            className={`d-flex justify-content-around ${
              d.row.original.recurringPmtId == selectedRP?.recurringPmtId
                ? 'orange'
                : ''
            }`}
          >
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='Edit'
              onClick={() => {
                setSelectedRP(d.row.original);
                setShowRecurringPaymentDetail(true);
                // setShow(true)
              }}
            >
              <FaEdit />
            </Button>
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='View'
              onClick={() => {
                setSelectedRP(d.row.original);
                setShowRecurringPaymentWeeklyDetailModal(true);
              }}
            >
              <FaEye />
            </Button>
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='Send'
              onClick={() => {
                handleSendDetails(d.row.original);
              }}
            >
              <FaShare />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Active',
      accessorKey: 'active',
      enableSorting: false,
      filterFn: booleanFilter,
      maxSize: 50,
      cell: (d) => (
        <Form.Check
          type='checkbox'
          checked={!!d.row.original.active}
          id='isCleared'
          label=''
          readOnly
        />
      ),
    },
    {
      header: 'Benefit Amount',
      accessorKey: 'amount',
      sortingFn: 'alphanumericCaseSensitive',
      // filterFn: "equals",
      // enableColumnFilter: false,
      // maxSize: 100,
      cell: (d) => accounting.formatMoney(d.row.original.amount ?? 0),
    },
    {
      header: 'Pay Code',
      accessorKey: 'payCode',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 50,
    },
    {
      header: 'Mail To',
      accessorKey: 'mailTo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Benefit Start',
      accessorKey: 'startDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'Benefit End',
      accessorKey: 'endDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'Frequency',
      accessorKey: 'frequency',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 50,
    },
    {
      header: 'Last Date Paid',
      accessorKey: 'lastPaidDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      // maxSize: 100,
    },
    {
      header: 'Weeks Paid',
      accessorFn: (d) =>
        findNumberOfWeeks(
          new Date(d.startDate),
          new Date(d.endDate ?? ''),
          d.lastPaidDate ? new Date(d.lastPaidDate) : null
        ),
      sortingFn: 'alphanumericCaseSensitive',
      maxSize: 50,
      // filterFn: "includesString",
    },
    {
      header: 'Next Svc From',
      accessorKey: 'nextServiceFromDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      // maxSize: 100,
    },
    {
      header: 'Next Svc To',
      accessorKey: 'nextServiceToDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      // maxSize: 100,
    },
    {
      header: 'Comments',
      accessorKey: 'comments',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 100,
    },
  ];

  const columns = useMemo(() => columnData, [selectedRP]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        selectableRow={true}
        highlightRow={true}
      />
    </div>
  );
}
