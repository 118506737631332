import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ClaimLitigation } from '../../../ApiTypes/ClaimLitigation';
import LitigationApi from '../../../Api/LitigationApi';
import LitigationTable from './LitigationTable';
import { useAppDispatch, useAppSelector } from '../../../Reducers/Store';
import {
  fetchCounties,
  fetchJudges,
  fetchStates,
} from '../../../Actions/ReferenceActions';
import { fetchAllClaimCustomOptions } from '../../../Actions/ClaimCustomOptionActions';
import Loading from '../../Common/Loading';

export default function LitigationTab({ claimType }: { claimType: number }) {
  const { claimNumber } = useParams();
  const [data, setData] = useState<ClaimLitigation[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { claimCustomOptions, judges, states, counties } = useAppSelector(
    (state) => state.reference
  );
  const getClaimLitigations = () => {
    if (claimNumber) {
      LitigationApi.getClaimLitigations(claimNumber)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setData([]);
    }
  };

  useEffect(() => {
    getClaimLitigations();
    getReferences();
  }, [claimNumber]);

  const getReferences = async () => {
    if (
      !claimCustomOptions.length ||
      !judges.length ||
      !states.length ||
      !counties.length
    ) {
      await Promise.all([
        dispatch(fetchAllClaimCustomOptions()),
        dispatch(fetchJudges()),
        dispatch(fetchStates()),
        dispatch(fetchCounties()),
      ]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <div>
      <>{loading && <Loading />}</>
      <LitigationTable
        claimNumber={claimNumber ?? ''}
        data={data}
        getClaimLitigations={getClaimLitigations}
      />
    </div>
  );
}
