import React, { useState, useEffect } from 'react'
import { Container, Modal } from 'react-bootstrap'
import { FaCalendarWeek } from 'react-icons/fa'
import { RecurringPayment } from '../../ApiTypes/RecurringPayment'
import { getRecurringPaymentWeeklyDetail, RecurringPaymentWeeklyDetail } from '../../Types/RecurringPaymentWeeklyDetail'
import styles from "./index.module.css"
import RecurringPaymentWeeklyDetailsTable from './RecurringPaymentWeeklyDetailsTable'

export default function RecurringPaymentWeeklyDetailModal({
  show,
  setShow,
  selectedRP,
  setSelectedRP,
}: {
  show: boolean,
  selectedRP: RecurringPayment | null,
  setShow: (show: boolean) => void,
  setSelectedRP: (rp: RecurringPayment | null) => void
}) {
  const [details, setDetails] = useState<RecurringPaymentWeeklyDetail[]>([])

  useEffect(() => {
    setWeeklyDetails()

    return () => {
      setDetails([])
    }
  }, [selectedRP])

  const setWeeklyDetails = () => {
    if (selectedRP) {
      const d = getRecurringPaymentWeeklyDetail(selectedRP)
      setDetails(d)
    }
  }

  return (
    <Modal
      centered
      show={show}
      size="lg"
      onHide={() => {
        setSelectedRP(null)
        setShow(false)
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby="Indemnity-details-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="button-icon-text" id="Indemnity-details-modal">
          <FaCalendarWeek className="pe-1" /> Indemnity Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid >
          <RecurringPaymentWeeklyDetailsTable details={details} />
        </Container>
      </Modal.Body>
    </Modal>
  )
}
