import { Icdcode } from './Icdcode';

export interface ClaimCmsinfoIcdcode {
  id: number;
  claimCmsinfoId: number;
  icdcodeId: number;
  statusId: number;
  isImport: boolean | null;

  icdcode: Icdcode;
}

export enum ICDStatusEnum {
  Unknown = 0,
  Accepted = 1,
  Unrelated = 2,
}
