import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { FaBriefcaseMedical } from 'react-icons/fa';
import { Form as RFForm, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { parseDatesForServer } from '../../Utils';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import { SearchUserLogsRequest } from '../../ApiTypes/SearchUserLogsRequest';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import { InsCoLog } from '../../ApiTypes/InsCoLog';
import InsuranceCompanyLogApi from '../../Api/InsuranceCompanyLogApi';
import InsCoLogTable from './InsCoLogTable';
import InsCoLogDetail from './InsCoLogDetail';

export default function InsuranceCompanyLogs() {
  const [logs, setLogs] = useState<InsCoLog[]>([]);
  const [selectedLog, setSelectedLog] = useState<InsCoLog | null>(null);
  const [showLog, setShowLog] = useState<boolean>(false);

  useEffect(() => {
    getAll();
  }, []);

  const onSubmit = (values: SearchUserLogsRequest) => {
    if (!values.userName && !values.fromDate && !values.toDate) {
      return getAll();
    }
    return InsuranceCompanyLogApi.searchLogs(values)
      .then((res) => {
        setLogs(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get logs');
      });
  };

  const getAll = () => {
    return InsuranceCompanyLogApi.getAll()
      .then((res) => {
        setLogs(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get logs');
      });
  };

  const handleView = (l: InsCoLog) => {
    setSelectedLog(l);
    setShowLog(true);
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaBriefcaseMedical className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Ins.Co. Logs</h1>
          </div>
        </div>
      </div>
      <RFForm
        onSubmit={onSubmit}
        initialValues={{
          userName: '',
        }}
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className='d-flex justify-content-center align-items-center gap1Rem'>
                <Field
                  name='userName'
                  label='User Name / Ins Co Id'
                  type='text'
                  component={FieldBSRenderText}
                />
                <Field
                  name='fromDate'
                  label='From Date'
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />
                <Field
                  name='toDate'
                  label='To Date'
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />

                <Button type='submit' variant='primary' size='sm'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Search'
                  )}
                </Button>

                <Button
                  type='button'
                  variant='outline-primary'
                  size='sm'
                  onClick={() => {
                    form.reset();
                    getAll();
                  }}
                >
                  Clear
                </Button>
              </div>
            </Form>
          );
        }}
      />
      <InsCoLogTable data={logs} handleView={handleView} />
      <InsCoLogDetail show={showLog} setShow={setShowLog} log={selectedLog} />
    </PageScaffold>
  );
}
