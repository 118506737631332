import { format } from 'date-fns';
import React from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { FaEdit } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { VariablePayment } from '../../ApiTypes/VariablePayment';
import { parseDatesForServer } from '../../Utils';
import { centsValidation, requiredField } from '../../Utils/FieldValidation';
import { formatNumbers, cleanMoney } from '../../Utils/InputFormatters';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import styles from './index.module.css';

export default function VariablePaymentModal({
  show,
  setShow,
  payment,
  handleSave,
}: {
  show: boolean;
  setShow: (s: boolean) => void;
  payment: VariablePayment | null;
  handleSave: (values: VariablePayment) => Promise<void>;
}) {
  const { claimNumber } = useParams();
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='TPD-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='TPD-Form-modal'>
          <FaEdit className='pe-1' /> {payment ? 'Edit' : 'New'} TDP
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={handleSave}
            initialValues={
              payment ?? {
                id: 0,
                claimNo: claimNumber,
                createdBy: '',
                dateCreated: format(new Date(), 'yyyy-MM-dd'),
              }
            }
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='claimNo'
                    label='Claim Number'
                    type='text'
                    component={FieldBSRenderText}
                    readOnly
                  />
                  <Field
                    name='startDate'
                    label='Start Date'
                    parse={parseDatesForServer}
                    validate={requiredField}
                    component={FieldBSRenderDate}
                  />
                  <Field
                    name='endDate'
                    label='End Date'
                    parse={parseDatesForServer}
                    validate={requiredField}
                    component={FieldBSRenderDate}
                  />
                  <Field
                    name='amount'
                    label='Amount'
                    type='text'
                    format={formatNumbers}
                    parse={cleanMoney}
                    validate={requiredField}
                    component={FieldBSRenderMoney}
                  />
                  <Field
                    name='memo'
                    label='Memo'
                    rows={3}
                    component={FieldBSRenderTextArea}
                  />
                  <div className={`${styles.buttonDiv} mt-3`}>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
