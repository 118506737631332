import React, { useState, useEffect } from 'react';
import { addDays, addMonths, format } from 'date-fns';
import { FormApi } from 'final-form';
import { Form } from 'react-bootstrap';
import { Form as RFForm } from 'react-final-form';
import logo from '../../Assets/CompComplete.png';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import Page1 from './Page1';
import Navigation from './Navigation';
import Page2 from './Page2';
import { GroupedFees, VBillFee } from '../../ApiTypes/VBillFee';
import { SimpleClaimObj } from '../../ApiTypes/SimpleClaimObj';
import Page3 from './Page3';
import Page4 from './Page4';
import { useAppSelector } from '../../Reducers/Store';
import { FeeWizardFormType } from './FeeWizardFormType';
import CompCompleteApi from '../../Api/CompCompleteApi';
import { toast } from 'react-toastify';
import Loading from '../Common/Loading';
import { FixProblemFeeRequest } from '../../ApiTypes/FixProblemFeeRequest';
import { FinishCompCompleteBillingRequest } from '../../ApiTypes/FinishCompCompleteBillingRequest';

export default function CompIqEomFees() {
  const [fetching, setFetching] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [fees, setFees] = useState<VBillFee[]>([]);
  const [problemFees, setProblemFees] = useState<VBillFee[]>([]);
  const [selectedFee, setSelectedFee] = useState<VBillFee | null>(null);
  const [feeRowSelection, setFeeRowSelection] = useState({});
  const [totals, setTotals] = useState<GroupedFees[]>([]);

  const [claims, setClaims] = useState<SimpleClaimObj[]>([]);

  const { userModel } = useAppSelector((state) => state.user);

  let formInstance: FormApi<FeeWizardFormType, Partial<FeeWizardFormType>>;
  let formValues: FeeWizardFormType;

  useEffect(() => {
    handleSelectedFee();
  }, [selectedFee]);

  const handleSelectedFee = () => {
    if (selectedFee) {
      getRelatedClaims(selectedFee.documentControlNumber ?? '');
    } else {
      setClaims([]);
    }
  };

  const getRelatedClaims = (documentControlNumber: string) => {
    setFetching(true);
    CompCompleteApi.getRelatedClaims(documentControlNumber)
      .then((res) => {
        setClaims(res.data);
        setFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setFetching(false);
        toast.error('Failed to get possible related claims');
      });
  };

  const handleSelectedClaim = (claim: SimpleClaimObj) => {
    if (selectedFee) {
      const request: FixProblemFeeRequest = {
        claimNumber: claim.claimNo,
        documentControlNumber: selectedFee?.documentControlNumber ?? '',
      };
      setFetching(true);

      CompCompleteApi.fixProblemFee(request)
        .then((res) => {
          if (res.data.success) {
            toast.success('Fee has been fixed');
            loadFees(formValues);
            setClaims([]);
            setFeeRowSelection({});
          } else {
            toast.error(res.data.message);
            setFetching(false);
            setClaims([]);
            setFeeRowSelection({});
          }
        })
        .catch((err) => {
          console.log(err);
          setFetching(false);
          setClaims([]);
          setFeeRowSelection({});
          toast.error('Failed to fix fee');
        });
    }
  };

  const shouldFormSubmit = (values: FeeWizardFormType) => {
    if (page === 4) {
      return handleFormSubmit(values);
    }
    if (page === 1) {
      loadFees(values);
    }
    if (page === 2) {
      createGroups();
    }
    nextPage();
  };

  const nextPage = () => {
    setPage(page + 1);
  };
  const previousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleFormSubmit = (values: FeeWizardFormType) => {
    const request: FinishCompCompleteBillingRequest = {
      billFees: fees,
      endDate: format(new Date(values.endDate), 'yyyy-MM-dd'),
      monthDate: format(new Date(values.startDate), 'yyyy-MM-dd'),
      notes: values.notes,
      startDate: format(new Date(values.startDate), 'yyyy-MM-dd'),
    };
    setFetching(true);
    CompCompleteApi.finishBilling(request)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
        } else {
          toast.error(res.data.message);
          setFees([]);
          setProblemFees([]);
          setFeeRowSelection({});
          setTotals([]);
          setClaims([]);
          setPage(1);
        }
        setFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setFetching(false);
        toast.error('Failed to complete request');
      });
  };

  const lastMonth = addMonths(new Date(), -1);
  const initialStartDate = new Date(
    lastMonth.getFullYear(),
    lastMonth.getMonth(),
    1
  );
  const initialEndDate = addDays(addMonths(initialStartDate, 1), -1);

  const cancelClick = () => {
    setPage(1);
    formInstance.restart();
  };

  const loadFees = (values: FeeWizardFormType) => {
    setFetching(true);
    setFeeRowSelection({});
    CompCompleteApi.getFeesForMonth(values.startDate, values.endDate)
      .then((res) => {
        setFees(res.data.allFees);
        setProblemFees(res.data.problemFees);
        setFetching(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get fees');
        setFetching(false);
      });
  };

  const createGroups = () => {
    const groups = fees.reduce((acc, curr) => {
      const group = acc.find((f) => f.clientId === curr.clientId);
      if (group) {
        group.brFees! += curr.brfees ?? 0;
        group.ppoFEES = 0;
        group.totalFEES! += curr.brfees ?? 0;
        return acc;
      }
      const newGroup = {
        clientId: curr.clientId!,
        brFees: curr.brfees ?? 0,
        ppoFEES: 0,
        totalFEES: curr.brfees ?? 0,
      };
      acc.push(newGroup);
      return acc;
    }, [] as GroupedFees[]);
    setTotals(
      groups.sort((a, b) => (a.clientId ?? '').localeCompare(b.clientId ?? ''))
    );
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <img src={logo} alt='CompComplete, LLC' />
        <div className='ms-3'>
          <h1 className='mb-0'>Comp Complete Wizard</h1>
        </div>
      </div>
      <div>
        {fetching && <Loading />}
        <RFForm
          onSubmit={shouldFormSubmit}
          initialValues={{
            month: format(lastMonth, 'MM/yyyy'),
            startDate: format(initialStartDate, 'MM/dd/yyyy'),
            endDate: format(initialEndDate, 'MM/dd/yyyy'),
            notes: `${userModel?.user?.userId} CompComplete Import`,
          }}
          render={({ handleSubmit, form, values, submitting }) => {
            formInstance = form;
            formValues = values;
            return (
              <Form onSubmit={handleSubmit}>
                {page === 1 && <Page1 formInstance={form} values={values} />}
                {page === 2 && (
                  <Page2
                    formInstance={form}
                    values={values}
                    problemFees={problemFees}
                    feeRowSelection={feeRowSelection}
                    setFeeRowSelection={setFeeRowSelection}
                    setSelectedFee={setSelectedFee}
                    claims={claims}
                    handleSelectedClaim={handleSelectedClaim}
                    fetching={fetching}
                  />
                )}
                {page === 3 && (
                  <Page3 formInstance={form} values={values} totals={totals} />
                )}
                {page === 4 && (
                  <Page4 formInstance={form} values={values} fees={fees} />
                )}
                {!fetching && (
                  <Navigation
                    previousPage={previousPage}
                    page={page}
                    cancel={cancelClick}
                  />
                )}
              </Form>
            );
          }}
        />
      </div>
    </PageScaffold>
  );
}
