import React from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { FaFileUpload } from 'react-icons/fa';
import { DocType } from '../../../ApiTypes/DocType';
import { useAppSelector } from '../../../Reducers/Store';
import { requiredField } from '../../../Utils/FieldValidation';
import FieldBSRenderFilePicker from '../../Common/FieldBSRenderFilePicker';
import FieldBSRenderSelect from '../../Common/FieldBSRenderSelect';
import { AddFileFormType } from '../../AddFile/AddFileFormType';

import styles from './index.module.css';
import FieldBSRenderText from '../../Common/FieldBSRenderText';
import { DocumentType } from '../../../ApiTypes/DocumentType';
import { PropertyPhoto } from '../../../ApiTypes/PropertyPhoto';

export default function AddPhotoModal({
  show,
  setShow,
  claimNumber,
  handleAddDocument,
  selectedPhoto,
  claimType,
}: {
  show: boolean;
  claimNumber: string;
  setShow: (show: boolean) => void;
  handleAddDocument: (values: AddFileFormType) => Promise<void>;
  selectedPhoto: PropertyPhoto | null;
  claimType: number;
}) {
  const { docTypes } = useAppSelector((state) => state.reference);

  const onSubmit = (values: AddFileFormType) => {
    return handleAddDocument(values);
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      aria-labelledby='Secure-Share-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Secure-Share-Form-modal'>
          <FaFileUpload className='pe-1' />
          {selectedPhoto ? 'Edit Photo Notes' : 'Upload Property Photo'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {selectedPhoto && (
            <div className='mb-2'>File Name: {selectedPhoto.doc?.note}</div>
          )}
          <RFFForm
            onSubmit={onSubmit}
            initialValues={{
              claimNumber,
              docTypeId: DocumentType.PropertyPhoto,
              action: 'None',
              newName: selectedPhoto?.notes,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  {!selectedPhoto && (
                    <Field
                      name='docTypeId'
                      label='Type'
                      options={docTypes}
                      optionMethod={(options: DocType[]) =>
                        options.map((o) => (
                          <option key={o.docTypeId} value={o.docTypeId}>
                            {o.description}
                          </option>
                        ))
                      }
                      disabled={true}
                      validate={requiredField}
                      component={FieldBSRenderSelect}
                    />
                  )}
                  {!selectedPhoto && (
                    <Field
                      name='doc'
                      type='file'
                      validate={requiredField}
                      component={FieldBSRenderFilePicker}
                      disabled={selectedPhoto}
                    />
                  )}
                  <Field
                    name='newName'
                    label='Notes'
                    type='text'
                    component={FieldBSRenderText}
                  />
                  <div className={`${styles.actionButtons} mt-3`}>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
