import axios from "axios";
import { API_URL, getAuthHeader } from ".";
import { ClaimDrugScreen } from "../ApiTypes/ClaimDrugScreens";
import { ResponseApiModel } from "../ApiTypes/ResponseApiModel";


class DrugScreenApi {
  static getForClaim(claimNumber: string) {
    return axios.get<ClaimDrugScreen>(`${API_URL}/api/claims/${claimNumber}/drugscreens`, getAuthHeader());
  }
  static addDrugScreen(request: ClaimDrugScreen) {
    return axios.post<ResponseApiModel>(`${API_URL}/api/claims/${request.claimNo}/drugscreens`, request, getAuthHeader());
  }
}

export default DrugScreenApi;