import { addDays, addSeconds, format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { Modal, Container, Form, Button } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ReportApi from '../../Api/ReportApi';
import { DeductibleBillingRequest } from '../../ApiTypes/DeductibleBillingRequest';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { useAppSelector } from '../../Reducers/Store';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import styles from './index.module.css';
import { ExportTypes } from '../../ApiTypes/DevExpressReportRequest';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';

export default function DeductibleBillingModal({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (s: boolean) => void;
}) {
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );
  const [years, setYears] = useState<number[]>([]);

  useEffect(() => {
    loadYears();
  }, []);

  const loadYears = () => {
    var ys: number[] = [];
    var thisYear = new Date().getFullYear();
    for (let i = 0; i <= 15; i++) {
      ys.push(thisYear - i);
    }
    setYears(ys);
  };

  const onSubmit = (values: DeductibleBillingRequest) => {
    var request: DeductibleBillingRequest = {
      treatyYear: values.treatyYear,
      treatyYearTo: values.treatyYearTo,
      bookingDate: values.bookingDate ?? null,
      asOf: values.asOf,
      accountNumber: values.accountNumber,
      exportType: values.exportType ? +values.exportType : ExportTypes.PDF,
    };
    setShow(false);
    return ReportApi.getDeductibleBilling(request)
      .then((res) => {
        if (res.data.success) {
          // toast.success('Success');
          setShow(false);
        } else {
          toast.error(<ErrorMessage messages={res.data.messages} />);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get Deductible billing');
      });
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      aria-labelledby='Deductible-Billing-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Deductible-Billing-modal'>
          <FaCheck className='pe-1' /> Deductible Billing
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              asOf: format(
                addSeconds(
                  addDays(new Date(), -(new Date().getDate() - 1)),
                  -1
                ),
                'yyyy-MM-dd'
              ),
              treatyYear: new Date().getFullYear() - 4,
              treatyYearTo: new Date().getFullYear(),
              exportType: ExportTypes.XLS,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='accountNumber'
                    label='Account'
                    options={insuranceCompaniesForUser}
                    optionMethod={(options: InsuranceCompany[]) =>
                      options.map((o) => (
                        <option key={o.accountnumber!} value={o.accountnumber!}>
                          {`${o.accountnumber} - ${o.shortname}`}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='asOf'
                    type='text'
                    label='As of'
                    parse={parseDatesForServer}
                    validate={requiredField}
                    component={FieldBSRenderDate}
                  />
                  <div className={`${styles.grid3}`}>
                    <Field
                      name='treatyYear'
                      label='Treaty Year'
                      options={years}
                      optionMethod={(options: number[]) =>
                        options.map((o) => (
                          <option key={o} value={o}>
                            {o}
                          </option>
                        ))
                      }
                      validate={requiredField}
                      component={FieldBSRenderSelect}
                    />
                    <Field
                      name='treatyYearTo'
                      label='To Treaty Year'
                      options={years}
                      optionMethod={(options: number[]) =>
                        options.map((o) => (
                          <option key={o} value={o}>
                            {o}
                          </option>
                        ))
                      }
                      validate={requiredField}
                      component={FieldBSRenderSelect}
                    />
                    <Field
                      name='bookingDate'
                      label='Booking Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                  </div>
                  <div className='d-flex justify-content-around'>
                    <fieldset className='d-flex justify-content-center gap1Rem'>
                      <legend>File Type</legend>
                      <Field
                        name='exportType'
                        type='radio'
                        label='PDF'
                        value={ExportTypes.PDF}
                        checked={+values.exportType === ExportTypes.PDF}
                        component={FieldBSRenderCheckbox}
                      />
                      <Field
                        name='exportType'
                        type='radio'
                        label='XLS'
                        value={ExportTypes.XLS}
                        checked={+values.exportType === ExportTypes.XLS}
                        component={FieldBSRenderCheckbox}
                      />
                      <Field
                        name='exportType'
                        type='radio'
                        label='WORD'
                        value={ExportTypes.WORD}
                        checked={+values.exportType === ExportTypes.WORD}
                        component={FieldBSRenderCheckbox}
                      />
                    </fieldset>
                  </div>
                  <p className='text-primary'>
                    To simply run the report, leave booking date empty
                  </p>
                  <p className='text-primary'>
                    Documents will be emailed in a zip file.
                  </p>
                  {/* <Field
                      name='action'
                      label='Email Reports'
                      type='radio'
                      value='email'
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='action'
                      label='Save Reports'
                      type='radio'
                      value='save'
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='action'
                      label='View Now'
                      type='radio'
                      value='view'
                      component={FieldBSRenderCheckbox}
                    /> */}
                  <Button type='submit' variant='primary' size='sm'>
                    Generate
                  </Button>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
