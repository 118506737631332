import React, { useMemo } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import CreatableSelect from 'react-select/creatable';
import { EmailDocumentsRequest } from '../../../ApiTypes/EmailDocumentsRequest';
import { EmailLetterRequest } from '../../../ApiTypes/EmailLetterRequest';
import { Letter } from '../../../ApiTypes/Letter';
import { UserEmailList } from '../../../ApiTypes/UserEmailList';
import { UserModel } from '../../../ApiTypes/UserModel';
import { requiredField } from '../../../Utils/FieldValidation';
import FieldBSRenderText from '../../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../../Common/FieldRenderTextArea';

import styles from './index.module.css';

export default function EmailLetterPageThree({
  userModel,
  letter,
  handleFormSubmit,
  setShow,
  userEmailList,
  claimNumber,
}: {
  userModel: UserModel | null;
  letter: Letter | null;
  handleFormSubmit: (values: EmailLetterRequest) => void;
  userEmailList: UserEmailList[];
  setShow: (show: boolean) => void;
  claimNumber: string;
}) {
  const emailList = useMemo(() => {
    const list = userEmailList.map((e) => ({
      label: e.emailAddress ?? '',
      value: e.emailAddress ?? '',
    }));

    return [...list];
  }, [userEmailList]);

  return (
    <div>
      <RFFForm
        validate={(values: EmailLetterRequest) => {
          const errors: {
            [Property in keyof EmailLetterRequest]?: string;
          } = {};

          if (values?.emailAddresses.length === 0) {
            errors.emailAddresses = 'Required';
          }
          return errors;
        }}
        initialValues={{
          attachment: letter ? `${letter.letterName}.pdf` : undefined,
          claimNumber,
        }}
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Field name='emailAddresses'>
                {({ input, meta: { touched, error } }) => (
                  <div className={`position-relative ${styles.vFieldHeight}`}>
                    <label
                      htmlFor={input.name}
                      className='form-label fs-6  m-0'
                    >
                      Email Addresses
                    </label>
                    <CreatableSelect
                      name={input.name}
                      placeholder='Separate emails with a comma (,)'
                      isClearable
                      isMulti
                      onChange={(e) => {
                        if (e) {
                          input.onChange(e.map((m) => m.value));
                        } else {
                          input.onChange(null);
                        }
                      }}
                      styles={{
                        control: (baseStyles: any, state: any) => ({
                          ...baseStyles,
                          borderColor: touched && error && 'red',
                        }),
                      }}
                      options={emailList}
                    />

                    {touched && error && (
                      <span className={`${styles.fieldError} text-danger`}>
                        {error}
                      </span>
                    )}
                  </div>
                )}
              </Field>
              <Field
                name='subject'
                label='Subject'
                type='text'
                validate={requiredField}
                component={FieldBSRenderText}
              />
              <Field
                name='message'
                label='Message'
                rows={5}
                validate={requiredField}
                component={FieldBSRenderTextArea}
              />
              <Field
                name='attachment'
                label='Attachment'
                type='text'
                validate={requiredField}
                component={FieldBSRenderText}
              />
              <div className={`${styles.buttonDiv}`}>
                <Button type='submit' variant='primary' size='sm'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button
                  type='button'
                  variant='primary'
                  size='sm'
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
}
