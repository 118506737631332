import React, { useState, useEffect } from 'react';
import { ClaimNote } from '../../../ApiTypes/ClaimNote';
import DiaryTable from './DiaryTable';
import { useParams } from 'react-router-dom';
import EditCreateNote from '../../EditCreateNote/EditCreateNote';
import ClaimNotesApi from '../../../Api/ClaimNotesApi';
import { toast } from 'react-toastify';
import DiaryCreateQuestionModal from '../../EditCreateNote/DiaryCreateQuestionModal';
import DeleteConfirmationModal from '../../Common/DeleteConfirmationModal';

export default function DiaryTab({ claimType }: { claimType: number }) {
  const [notes, setNotes] = useState<ClaimNote[]>([]);
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const [selectedNote, setSelectedNote] = useState<ClaimNote | null>(null);
  const [showCompleted, setShowCompleted] = useState<boolean>(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [noteToDelete, setNoteToDelete] = useState<number | null>(null);

  const [showQuestion, setShowQuestion] = useState(false);

  let { claimNumber } = useParams();

  useEffect(() => {
    getClaimNotes();
  }, [claimNumber, showCompleted]);

  const removeModal = () => {
    setShowNoteModal(false);
    setSelectedNote(null);
  };

  const handleShowCompletedChange = () => {
    setShowCompleted(!showCompleted);
  };

  const getClaimNotes = () => {
    if (claimNumber) {
      ClaimNotesApi.getDiaryNotesByClaimNumber(claimNumber, showCompleted)
        .then((res) => {
          setNotes(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setNotes([]);
    }
  };

  const deleteNote = () => {
    if (noteToDelete) {
      return ClaimNotesApi.deleteClaimNote(noteToDelete)
        .then((res) => {
          setShowDeleteConfirm(false);
          if (res.data.success) {
            getClaimNotes();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error('There was an error deleting the claim note');
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
      return Promise.resolve();
    }
  };

  const nextNewer = (current: ClaimNote) => {
    const index = notes.findIndex((x) => x.noteId === current.noteId);
    if (index === -1) return;
    if (index - 1 < 0) {
      toast.info('No newer notes');
      return;
    }
    const next = notes[index - 1];
    setSelectedNote(next);
  };
  const nextOlder = (current: ClaimNote) => {
    const index = notes.findIndex((x) => x.noteId === current.noteId);
    if (index === -1) return;
    if (index + 1 > notes.length - 1) {
      toast.info('No older notes');
      return;
    }
    const next = notes[index + 1];
    setSelectedNote(next);
  };

  const afterSubmit = (values: ClaimNote) => {
    if (values.followUpComplete) {
      setShowQuestion(true);
    }
  };

  const createNewDiary = () => {
    setShowQuestion(false);
    setSelectedNote(null);
    setShowNoteModal(true);
  };

  return (
    <div className='h-100'>
      <DiaryTable
        notes={notes}
        deleteNote={deleteNote}
        getClaimNotes={getClaimNotes}
        setSelectedNote={setSelectedNote}
        selectedNote={selectedNote}
        show={showNoteModal}
        setShow={setShowNoteModal}
        showCompleted={showCompleted}
        setShowCompleted={handleShowCompletedChange}
        setShowDeleteConfirm={setShowDeleteConfirm}
        setNoteToDelete={setNoteToDelete}
      />
      <EditCreateNote
        show={showNoteModal}
        setShow={removeModal}
        selectedNote={selectedNote}
        getClaimNotes={getClaimNotes}
        claimNumber={claimNumber ?? ''}
        newer={nextNewer}
        older={nextOlder}
        afterSubmit={afterSubmit}
        claimType={claimType}
        tabFrom='Diary'
      />
      <DiaryCreateQuestionModal
        show={showQuestion}
        setShow={setShowQuestion}
        createDiary={createNewDiary}
      />
      <DeleteConfirmationModal
        show={showDeleteConfirm}
        setShow={setShowDeleteConfirm}
        setNull={() => setNoteToDelete(null)}
        handleDelete={deleteNote}
      />
    </div>
  );
}
