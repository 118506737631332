import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ChartObj } from '../ApiTypes/ChartObj';
import { ClaimStatisticsObj } from '../ApiTypes/ClaimStatisticObj';
import { DailyCounts } from '../ApiTypes/DailyCounts';
import { ComboBoxHelper } from '../ApiTypes/ComboBoxHelper';
import { FileDownload } from '../Types/FileDownLoad';

class ClaimStatisticApi {
  static getStat(userId: string, fromDate: string, toDate: string) {
    return axios.get<ClaimStatisticsObj>(
      `${API_URL}/api/claimstatistics?userId=${userId}&fromDate=${fromDate}&toDate=${toDate}`,
      getAuthHeader()
    );
  }
  static getDocDailyCountsForUser(
    adjusterId: number,
    fromDate: string,
    toDate: string
  ) {
    return axios.get<DailyCounts[]>(
      `${API_URL}/api/claimstatistics/documents?adjusterId=${adjusterId}&fromDate=${fromDate}&toDate=${toDate}`,
      getAuthHeader()
    );
  }
  static getDocMovedDailyCountsForUser(
    adjusterId: number,
    fromDate: string,
    toDate: string
  ) {
    return axios.get<DailyCounts[]>(
      `${API_URL}/api/claimstatistics/documentsmoved?adjusterId=${adjusterId}&fromDate=${fromDate}&toDate=${toDate}`,
      getAuthHeader()
    );
  }
  static getClaimStatisticList(
    fromDate: string,
    toDate: string,
    userId: string
  ) {
    return axios.get<ClaimStatisticsObj[]>(
      `${API_URL}/api/claimstatistics/list?fromDate=${fromDate}&toDate=${toDate}&userId=${userId}`,
      getAuthHeader()
    );
  }
  static exportRawData(fromDate: string, toDate: string, userId: string) {
    return axios.get<FileDownload>(
      `${API_URL}/api/claimstatistics/exportrawdata?fromDate=${fromDate}&toDate=${toDate}&userId=${userId}`,
      getAuthHeader()
    );
  }
  static getAllHistory(userId: string, fromDate: string, toDate: string) {
    return axios.get<ChartObj[]>(
      `${API_URL}/api/claimstatistics/all-history?userId=${userId}&fromDate=${fromDate}&toDate=${toDate}`,
      getAuthHeader()
    );
  }
  static getLTClaimHistory(
    adjusterId: number,
    fromDate: string,
    toDate: string
  ) {
    return axios.get<ChartObj[]>(
      `${API_URL}/api/claimstatistics/lt-claim-history?adjusterId=${adjusterId}&fromDate=${fromDate}&toDate=${toDate}`,
      getAuthHeader()
    );
  }
  static getAllClaimHistory(
    adjusterId: number,
    fromDate: string,
    toDate: string
  ) {
    return axios.get<ChartObj[]>(
      `${API_URL}/api/claimstatistics/all-claim-history?adjusterId=${adjusterId}&fromDate=${fromDate}&toDate=${toDate}`,
      getAuthHeader()
    );
  }
  static getDocumentStatsByStatus(
    userId: string,
    fromDate: string,
    toDate: string
  ) {
    return axios.get<ComboBoxHelper[]>(
      `${API_URL}/api/claimstatistics/documentstatsbystatus?userId=${userId}&fromDate=${fromDate}&toDate=${toDate}`,
      getAuthHeader()
    );
  }
}

export default ClaimStatisticApi;
