export enum DocumentType {
  All = 0,
  Bills = 2,
  LegalDocuments = 3,
  BoardForms = 5,
  Medical = 6,
  Misc = 7,
  QuickScans = 14,
  Recovery = 15,
  Reports = 16,
  ReserveWS = 17,
  CorrBackUp = 18,
  DragAndDrop = 20,
  NewClaims = 21,
  EORProcessed = 69,
  MayoImport = 70,
  EOR = 19,
  Letters = 71,
  Panels = 72,
  UnderwritersImport = 73,
  WorkStatus = 74,
  PropertyPhoto = 76,
}
