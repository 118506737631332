import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ClaimPropertyTable from './ClaimPropertyTable';
import { ClaimProperty } from '../../../ApiTypes/ClaimProperty';
import ClaimPropertyApi from '../../../Api/ClaimPropertyApi';
import DeleteConfirmationModal from '../Legal/DeleteConfirmationModal';
import ClaimPropertyAddEdit from './ClaimPropertyAddEdit';

export default function PropertyInfoTab({ claimType }: { claimType: number }) {
  let { claimNumber } = useParams();
  const [claimProperties, setClaimProperties] = useState<ClaimProperty[]>([]);
  const [claimPropertiesToDelete, setclaimPropertiesToDelete] =
    useState<ClaimProperty | null>(null);
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const [showPropertyModal, setShowPropertyModal] = useState<boolean>(false);
  const [selectedPropertyClaimId, setSelectedPropertyClaimId] = useState<
    string | ''
  >('');

  useEffect(() => {
    getClaimPropertiesForClaim();
  }, [claimNumber]);

  const getClaimPropertiesForClaim = () => {
    if (claimNumber) {
      ClaimPropertyApi.getClaimPropertiesForClaim(claimNumber)
        .then((res) => {
          setClaimProperties(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setClaimProperties([]);
    }
  };

  const handleClaimPropertyToDelete = (cp: ClaimProperty) => {
    setclaimPropertiesToDelete(cp);
    setShowDelete(true);
  };

  const handleDelete = () => {
    if (claimPropertiesToDelete && claimNumber) {
      return ClaimPropertyApi.deleteClaimProperty(
        claimPropertiesToDelete?.id,
        claimNumber
      )
        .then((res) => {
          if (res.data.success) {
            getClaimPropertiesForClaim();
            setclaimPropertiesToDelete(null);
            setShowDelete(false);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to delete Claim Property');
        });
    }
    return Promise.resolve();
  };

  const removeModal = () => {
    setShowPropertyModal(false);
    setSelectedPropertyClaimId('');
  };

  return (
    <div>
      <div className='d-flex justify-content-center align-items-center mb-3'>
        <Button
          type='button'
          variant='primary'
          size='sm'
          onClick={() => {
            setSelectedPropertyClaimId('');
            setShowPropertyModal(true);
          }}
          disabled={claimNumber === undefined}
          className='button-icon-text'
        >
          <FaPlusCircle /> Add New
        </Button>
      </div>
      <ClaimPropertyTable
        claimProperties={claimProperties}
        handleClaimPropertyToDelete={handleClaimPropertyToDelete}
        show={showPropertyModal}
        setShow={setShowPropertyModal}
        selectedPropertyClaimId={selectedPropertyClaimId}
        setSelectedPropertyClaimId={setSelectedPropertyClaimId}
      />
      <ClaimPropertyAddEdit
        claimType={claimType}
        show={showPropertyModal}
        setShow={removeModal}
        selectedPropertyClaimId={selectedPropertyClaimId}
        getClaimProperties={getClaimPropertiesForClaim}
      />
      <DeleteConfirmationModal
        show={showDelete}
        setShow={setShowDelete}
        setNull={setclaimPropertiesToDelete}
        handleDelete={handleDelete}
      />
    </div>
  );
}
