import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { FaEnvelopeOpenText } from 'react-icons/fa';
import { Form as RFForm, Field } from 'react-final-form';
import styles from './index.module.css';
import { AutomatedReportingJob } from '../../ApiTypes/AutomatedReportingJob';
import { useAppSelector } from '../../Reducers/Store';
import { Employer } from '../../ApiTypes/Employer';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { toast } from 'react-toastify';
import EmployerApi from '../../Api/EmployerApi';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import { addYears, format } from 'date-fns';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import { parseDatesForServer } from '../../Utils';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';

export default function EditJob({
  show,
  setShow,
  job,
  handleSubmit,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  job: AutomatedReportingJob | null;
  handleSubmit: (job: AutomatedReportingJob) => Promise<void>;
}) {
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );

  const [employers, setEmployers] = useState<Employer[]>([]);

  useEffect(() => {
    init();
  }, [job]);

  const init = () => {
    if (job) {
      handleAccountChange(job.insurCoId ?? 0);
    }
  };

  const reportTypeOptions = [
    { name: 'Loss Run', value: 1 },
    { name: 'Premium and Loss', value: 2 },
  ];
  const typeOptions = [
    { name: 'Monthly', value: 0 },
    { name: 'Bi-monthly', value: 1 },
    { name: 'Quarterly', value: 2 },
    { name: 'Annually', value: 3 },
  ];

  const handleAccountChange = (insurCoId: number) => {
    if (insurCoId) {
      const company = insuranceCompaniesForUser.find(
        (x) => x.insurcoid == insurCoId
      );
      if (company) {
        EmployerApi.getEmployerList({
          accountNumber: company.accountnumber ?? '',
        })
          .then((res) => {
            setEmployers(res.data);
          })
          .catch((err) => {
            console.log(err);
            setEmployers([]);
            toast.error('Failed to get employers for account');
          });
      } else {
        setEmployers([]);
      }
    } else {
      setEmployers([]);
    }
  };

  const onSubmit = (values: AutomatedReportingJob) => {
    return handleSubmit(values);
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='EditJob-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='EditJob-modal'>
          <FaEnvelopeOpenText className='pe-1' /> Automated Reporting Email
          Setup
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={
              job ?? {
                fromTreatyYear: format(addYears(new Date(), -3), 'yyyy'),
                toTreatyYear: format(new Date(), 'yyyy'),
                startDate: format(new Date(), 'yyyy-MM-dd'),
                fromName: 'Missy Cooley',
              }
            }
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='reportType'
                    label='Report'
                    options={reportTypeOptions}
                    optionMethod={(
                      options: { name: string; value: number }[]
                    ) =>
                      options.map((o) => (
                        <option key={o.value} value={o.value}>
                          {o.name}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='insurCoId'
                    label='Account'
                    options={insuranceCompaniesForUser}
                    onChange={handleAccountChange}
                    optionMethod={(options: InsuranceCompany[]) =>
                      options.map((o) => (
                        <option key={o.insurcoid} value={o.insurcoid}>
                          {`${o.accountnumber} - ${o.shortname}`}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='employerId'
                    label='Employer'
                    options={employers}
                    optionMethod={(options: Employer[]) =>
                      options.map((o) => (
                        <option key={o.employerId} value={o.employerId}>
                          {o.name}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <div className={styles.flex2}>
                    <Field
                      name='fromTreatyYear'
                      label='From Treaty Year'
                      type='text'
                      validate={requiredField}
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='toTreatyYear'
                      label='To Treaty Year'
                      type='text'
                      validate={requiredField}
                      component={FieldBSRenderText}
                    />
                  </div>
                  <Field
                    name='fromEmailAddress'
                    label='From Email'
                    type='text'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='fromName'
                    label='From Name'
                    type='text'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='title'
                    label='Title'
                    type='text'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='sendTo'
                    label='To'
                    type='text'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='ccto'
                    label='CC'
                    type='text'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='subject'
                    label='Subject'
                    type='text'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='emailBody'
                    label='Message'
                    rows={5}
                    validate={requiredField}
                    component={FieldBSRenderTextArea}
                  />
                  <div className={styles.flex2}>
                    <Field
                      name='dayOfMonthToSend'
                      label='Send On'
                      type='number'
                      validate={requiredField}
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='type'
                      label='Type'
                      options={typeOptions}
                      optionMethod={(
                        options: { name: string; value: number }[]
                      ) =>
                        options.map((o) => (
                          <option key={o.value} value={o.value}>
                            {o.name}
                          </option>
                        ))
                      }
                      validate={requiredField}
                      component={FieldBSRenderSelect}
                    />
                  </div>
                  <div className={styles.flex2}>
                    <Field
                      name='startDate'
                      label='Start On'
                      validate={requiredField}
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                    <Field
                      name='stopDate'
                      label='Stop On (Leave empty to run indefinitely)'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                  </div>
                  <div className='d-flex justify-content-center'>
                    <Field
                      name='openOnly'
                      type='checkbox'
                      label='Open Only'
                      checked={values.openOnly === true}
                      component={FieldBSRenderCheckbox}
                    />
                  </div>
                  <div className='d-flex justify-content-center align-items-center gap1Rem py-3'>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
