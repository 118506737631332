import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { CaseManagement } from '../../../ApiTypes/CaseManagement';
import { EntityUser } from '../../../ApiTypes/EntityUser';
import { useAppDispatch, useAppSelector } from '../../../Reducers/Store';
import NcmTable from './NcmTable';
import NcmApi from '../../../Api/NcmApi';
import { toast } from 'react-toastify';
import NcmResolutionModal from './NcmResolutionModal';
import { getDxReport } from '../../DocViewModal/useDocViewModal';
import { fetchHasOpenNcm } from '../../../Actions/ReferenceActions';
import {
  DevExpressReportRequest,
  DxAvailableReports,
} from '../../../ApiTypes/DevExpressReportRequest';
import { requestDx } from '../../DxReportRequestModal/useDxReportRequestModal';
import {
  setDocViewFileDownload,
  setShowDocView,
} from '../../../Actions/DocViewActions';
import { FileDownload } from '../../../Types/FileDownLoad';

export default function NcmTab({
  ncms,
  getNcms,
}: {
  ncms: CaseManagement[];
  getNcms: () => void;
}) {
  let { claimNumber } = useParams();
  const dispatch = useAppDispatch();

  const [assignedTo, setAssignedTo] = useState<EntityUser | undefined>(
    undefined
  );
  const [caseManagementIsOpen, setCaseManagementIsOpen] =
    useState<boolean>(false);
  const [showResolutionModal, setShowResolutionModal] =
    useState<boolean>(false);

  const { insuranceCompany } = useAppSelector(
    (state) => state.currentClaimReducer
  );

  const { nurseCaseManagers } = useAppSelector((state) => state.reference);

  const { userModel } = useAppSelector((state) => state.user);

  const setShowDx = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownloadDx = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  const setUpNcm = useCallback(() => {
    const openNcm = ncms.find((n) => n.dateCompleted === null);
    if (openNcm) {
      const assigned = nurseCaseManagers.find(
        (n) => n.userId === openNcm.assignedTo
      );
      setAssignedTo(assigned);
      setCaseManagementIsOpen(true);
    } else {
      setCaseManagementIsOpen(false);
    }
  }, [ncms, nurseCaseManagers]);

  useEffect(() => {
    setUpNcm();
  }, [setUpNcm]);

  const onAssignedToChange = (userId: string) => {
    if (userId) {
      const user = nurseCaseManagers.find((n) => n.userId === userId);
      setAssignedTo((assignedTo) => user);
    } else {
      setAssignedTo(undefined);
    }
  };

  const onCaseManagementIsOpenChange = async (checked: boolean) => {
    setCaseManagementIsOpen(checked);
    if (!assignedTo) {
      return;
    }
    if (checked && assignedTo) {
      // turn on ncm and submit and refresh
      const cm: CaseManagement = {
        claimNo: claimNumber ?? '',
        userId: userModel?.user?.userId ?? '',
        assignedTo: assignedTo.userId,
        dateStarted: format(new Date(), 'yyyy-MM-dd'),
        fee: insuranceCompany?.ncmfee ?? 0,
        id: 0,
        dateCompleted: null,
        billed: null,
        txId: null,
        resolutionId: null,
        resolutionNotes: null,
        totalBilled: null,
        userCompletedId: null,
        resolution: null,
        user: null,
        userCompleted: null,
      };

      const res = await createNcm(cm);
      if (res && res.data.success) {
      } else {
        setCaseManagementIsOpen(false);
      }
    } else {
      // switch is off - show modal
      setShowResolutionModal(true);
    }
  };

  const createNcm = (ncm: CaseManagement) => {
    return NcmApi.createNcm(ncm, claimNumber!)
      .then((res) => {
        if (res.data.success) {
          getNcms();
          if (claimNumber) {
            dispatch(fetchHasOpenNcm(claimNumber));
          }
        } else {
          toast.error(res.data.message);
        }
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleModalClose = (save: boolean) => {
    setShowResolutionModal(false);
    getNcms();
    if (claimNumber) {
      dispatch(fetchHasOpenNcm(claimNumber));
    }
  };

  const handleResolution = (ncm: CaseManagement) => {
    return NcmApi.resolveNcm(ncm, claimNumber!)
      .then((res) => {
        if (res.data.success) {
          getNcms();
          if (claimNumber) {
            dispatch(fetchHasOpenNcm(claimNumber));
          }
        } else {
          toast.error(res.data.message);
        }
        return res;
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to resolve case management');
      });
  };

  const viewReport = (ncm: CaseManagement) => {
    const request: DevExpressReportRequest = {
      ...requestDx,
      caseManagementId: ncm.id,
      report: DxAvailableReports.rptCaseManagement,
      insurCoId: 1,
    };
    getDxReport(request, setFileDownloadDx, setShowDx);
  };

  return (
    <div>
      <div>
        <h3>Case Management</h3>
        <div className='d-flex align-items-center'>
          <div className='mb-3 me-3'>
            <label htmlFor='assignedTo' className='form-label fs-6  m-0'>
              Assigned To
            </label>
            <select
              id='assignedTo'
              value={assignedTo?.userId}
              onChange={(e) => {
                onAssignedToChange(e.target.value);
              }}
              className='form-select form-select-sm'
            >
              <option value=''>--Select--</option>
              {nurseCaseManagers.map((o) => (
                <option key={o.userId} value={o.userId}>
                  {o.userName}
                </option>
              ))}
            </select>
          </div>
          <Form.Check
            type='switch'
            id='caseManagementIsOpen'
            name='caseManagementIsOpen'
            onChange={(e) => onCaseManagementIsOpenChange(e.target.checked)}
            checked={caseManagementIsOpen}
            label={`Case Management ${
              caseManagementIsOpen ? 'is Open' : 'is Closed'
            }`}
          />
        </div>
      </div>
      <NcmTable ncms={ncms} viewReport={viewReport} />
      <NcmResolutionModal
        ncm={ncms.filter((n) => n.dateCompleted === null)[0]}
        show={showResolutionModal}
        setShow={handleModalClose}
        handleResolution={handleResolution}
      />
    </div>
  );
}
