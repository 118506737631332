import React from 'react';
import { Field } from 'react-final-form';
import styles from './index.module.css';
import {
  cleanMoney,
  formatNumbers,
  makeMoney,
} from '../../Utils/InputFormatters';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';
import { centsValidation } from '../../Utils/FieldValidation';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import { FormApi } from 'final-form';
import { ReserveWorksheetFormType } from '../../Types/ReservesWorksheetFormType';

export default function LegalTab({
  form,
}: {
  form: FormApi<ReserveWorksheetFormType, Partial<ReserveWorksheetFormType>>;
}) {
  return (
    <div>
      <div className={`${styles.grid4Columns}`}>
        <Field
          name='legalPTD' // was legal
          label='Legal Paid To Date'
          type='text'
          disabled
          format={formatNumbers}
          parse={cleanMoney}
          validate={centsValidation}
          component={FieldBSRenderMoney}
        />
      </div>
      <div className={`${styles.grid4Columns}`}>
        <Field
          name='newlegalReserves'
          label='Increase Legal Reserves'
          type='text'
          format={formatNumbers}
          parse={cleanMoney}
          validate={centsValidation}
          component={FieldBSRenderMoney}
        />
      </div>

      <div className={`${styles.grid4Columns}`}>
        <div />
        <div />
        <div />
        <Field
          name='newLegalIncurred'
          label='Total New Legal Incurred'
          type='text'
          disabled
          format={makeMoney}
          parse={cleanMoney}
          component={FieldBSRenderMoney}
        />
      </div>
      <div className={`${styles.grid4Columns}`}>
        <div />
        <div />
        <div />
        <Field
          name='legalRounded'
          label='Rounded'
          type='text'
          disabled
          format={formatNumbers}
          parse={cleanMoney}
          component={FieldBSRenderMoney}
        />
      </div>
      <div className={`${styles.grid4Columns}`}>
        <div />
        <Field
          name='txtCurrentLegalReserves'
          label='Current Legal Reserves'
          type='text'
          disabled
          format={makeMoney}
          parse={cleanMoney}
          component={FieldBSRenderMoney}
        />
        <Field
          name='txtPreviousLegal'
          label='Previous Total Incurred'
          type='text'
          disabled
          format={makeMoney}
          parse={cleanMoney}
          component={FieldBSRenderMoney}
        />
        <Field
          name='legalDifference'
          label='Difference'
          type='text'
          disabled
          format={makeMoney}
          parse={cleanMoney}
          component={FieldBSRenderMoney}
        />
      </div>
      <div className={`${styles.medicalRemarksGrid}`}>
        <div />
        <Field
          name='legalRemarks'
          label='Legal Remarks'
          rows={3}
          component={FieldBSRenderTextArea}
        />
      </div>
    </div>
  );
}
