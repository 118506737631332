import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { EmailDocumentsRequest } from '../ApiTypes/EmailDocumentsRequest';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { AssignPbmRequest } from '../ApiTypes/AssignPbmRequest';
import { Pbm } from '../ApiTypes/Pbm';

class PbmApi {
  static assignPbm(request: AssignPbmRequest) {
    return axios.post<EmailDocumentsRequest>(
      `${API_URL}/api/claims/${request.claimNumber}/pbms`,
      request,
      getAuthHeader()
    );
  }
  static getActivePbms() {
    return axios.get<Pbm[]>(`${API_URL}/api/pbms`, getAuthHeader());
  }
  static getPbmById(pbmId: number) {
    return axios.get<Pbm>(`${API_URL}/api/pbms/${pbmId}`, getAuthHeader());
  }
  static updatePbm(pbm: Pbm) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/pbms/${pbm.pbmid}`,
      pbm,
      getAuthHeader()
    );
  }
  static createPbm(pbm: Pbm) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/pbms`,
      pbm,
      getAuthHeader()
    );
  }
}

export default PbmApi;
