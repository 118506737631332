import React from 'react';
import { Decorator } from 'final-form';
import createDecorator from 'final-form-calculate';
import { ReserveWorksheetFormType } from '../../Types/ReservesWorksheetFormType';

const calculateMedicalDifference = (values: ReserveWorksheetFormType) => {
  let newReserves = 0;

  let futureMedicalPhysician = values.futureMedicalPhysician
    ? +values.futureMedicalPhysician
    : 0;
  let futureMedicalHospital = values.futureMedicalHospital
    ? +values.futureMedicalHospital
    : 0;
  let futureMedicalMileage = values.futureMedicalMileage
    ? +values.futureMedicalMileage
    : 0;
  let futureMedicalMedicalMgmt = values.futureMedicalMedicalMgmt
    ? +values.futureMedicalMedicalMgmt
    : 0;
  let futureMedicalPharmacy = values.futureMedicalPharmacy
    ? +values.futureMedicalPharmacy
    : 0;
  let futureMedicalPhysTherapy = values.futureMedicalPhysTherapy
    ? +values.futureMedicalPhysTherapy
    : 0;

  newReserves =
    futureMedicalPhysician +
    futureMedicalHospital +
    futureMedicalMileage +
    futureMedicalMedicalMgmt +
    futureMedicalPharmacy +
    futureMedicalPhysTherapy;

  let CurrentMedicalReserves = values.txtCurrentMedicalReserves
    ? +values.txtCurrentMedicalReserves
    : 0;

  if (newReserves === 0 && CurrentMedicalReserves > 0) {
    newReserves = CurrentMedicalReserves;
  }

  const medicalDifference = Math.ceil(newReserves - CurrentMedicalReserves);
  const newTi = +(values.medicalPTD ?? 0) + +newReserves;

  return {
    newTi,
    medicalDifference,
  };
};

const calculateLegalDifference = (values: ReserveWorksheetFormType) => {
  let newReserves = values.newlegalReserves ? +values.newlegalReserves : 0;

  let CurrentLegalReserves = values.txtCurrentLegalReserves
    ? +values.txtCurrentLegalReserves
    : 0;

  if (newReserves === 0 && CurrentLegalReserves > 0) {
    newReserves = CurrentLegalReserves;
  }
  const legalDifference = newReserves - CurrentLegalReserves;
  const newTi = +(values.legalPTD ?? 0) + newReserves;

  return {
    newTi,
    legalDifference,
  };
};

const calculateExpenseDifference = (values: ReserveWorksheetFormType) => {
  let newReserves = values.newExpenseReserves ? +values.newExpenseReserves : 0;

  let CurrentExpenseReserves = values.txtCurrentExpenseReserves
    ? +values.txtCurrentExpenseReserves
    : 0;
  if (newReserves === 0 && CurrentExpenseReserves > 0) {
    newReserves = CurrentExpenseReserves;
  }
  const expenseDifference = newReserves - CurrentExpenseReserves;

  const newTi = +(values.expensePTD ?? 0) + newReserves;

  return {
    newTi,
    expenseDifference,
  };
};

const calculateIndemnityDifference = (values: ReserveWorksheetFormType) => {
  let newReserves = 0;

  let totalFuture = values.totalFuture ? +values?.totalFuture : 0;
  let permanentImpairmentTotal = values.permanentImpairmentTotal
    ? +values.permanentImpairmentTotal
    : 0;
  let tpdTotal = values.tpdTotal ? +values.tpdTotal : 0;
  let permanentTotal = values.permanentTotal ? +values.permanentTotal : 0;
  let deathBenefitsTotal = values.deathBenefits ? +values.deathBenefits : 0;
  let claimantAttorneyFees = values.claimantAttorneyFees
    ? +values.claimantAttorneyFees
    : 0;
  newReserves =
    totalFuture +
    permanentImpairmentTotal +
    tpdTotal +
    permanentTotal +
    deathBenefitsTotal +
    claimantAttorneyFees;

  let CurrentIndemnityReserves = values.txtCurrentIndemnityReserves
    ? +values.txtCurrentIndemnityReserves
    : 0;

  if (newReserves === 0 && CurrentIndemnityReserves > 0) {
    newReserves = CurrentIndemnityReserves;
  }

  const indemnityDifference = Math.ceil(newReserves - CurrentIndemnityReserves);
  const newTi = +(values.disabilityPTD ?? 0) + +newReserves;

  return {
    newTi,
    indemnityDifference,
  };
};

const calculateTotalIncurred = (values: ReserveWorksheetFormType) => {
  const expRoundedt = values.expenseRounded ? +values.expenseRounded : 0;
  const legalRoundedt = values.legalRounded ? +values.legalRounded : 0;
  const medicalRoundedt = values.medicalRounded ? +values.medicalRounded : 0;
  const indemnityRoundedt = values.indemnityRounded
    ? +values.indemnityRounded
    : 0;

  const total =
    expRoundedt + legalRoundedt + medicalRoundedt + indemnityRoundedt;

  return total;
};

const calculateTotalDifference = (values: ReserveWorksheetFormType) => {
  const expDiff = values.expenseDifference ? +values.expenseDifference : 0;
  const legalDiff = values.legalDifference ? +values.legalDifference : 0;
  const medicalDiff = values.medicalDifference ? +values.medicalDifference : 0;
  const indemnityDiff = values.indemnityDifference
    ? +values.indemnityDifference
    : 0;

  const total = expDiff + legalDiff + medicalDiff + indemnityDiff;

  return total;
};

// INDEMNITY TAB
const updatePercentageLossWeeks = (values: ReserveWorksheetFormType) => {
  const loss = values.percentageLoss ?? 0;
  const weeks = values.percentageLossWeeks ?? 0;

  return (+loss * +weeks).toFixed(2).replace(/[.,]00$/, '');
};

const updateTPDExposure = (values: ReserveWorksheetFormType) => {
  const val = values.tpdExposure ?? 0;
  const weeks = values.tpdExposureWeeks ?? 0;

  return (+val * +weeks).toFixed(2).replace(/[.,]00$/, '');
};

const updatePermanentImpairment = (values: ReserveWorksheetFormType) => {
  const val = values.percentageLossTotal ? +values.percentageLossTotal : 0;
  const weeks = values.percentageLossCrtotal ?? 0;

  return (+val * +weeks).toFixed(2).replace(/[.,]00$/, '');
};

const updatePermanentTotal = (values: ReserveWorksheetFormType) => {
  const val = values.permanentTotalCR ?? 0;
  const weeks = values.permanentTotalWeeks ?? 0;
  return (+val * +weeks).toFixed(2).replace(/[.,]00$/, '');
};

const updateFutureTemp = (values: ReserveWorksheetFormType) => {
  const val = values.futureTempTotalExpected ?? 0;
  const weeks = values.futureTempTotalExpectedWeeks ?? 0;
  return (+val * +weeks).toFixed(2).replace(/[.,]00$/, '');
};

export const reservesWorksheetCalculator = createDecorator(
  {
    field: [
      'totalFuture',
      'permanentImpairmentTotal',
      'tpdTotal',
      'permanentTotal',
      'deathBenefits',
      'claimantAttorneyFees',
      'disabilityPTD',
    ],
    updates: (value, field, allValues, previousValues) => {
      const { newTi, indemnityDifference } = calculateIndemnityDifference(
        allValues as ReserveWorksheetFormType
      );
      return {
        newIndemnityIncurred: newTi,
        indemnityRounded: Math.ceil(newTi / 100) * 100,
        indemnityDifference: indemnityDifference,
      };
    },
  },
  {
    field: ['percentageLoss', 'percentageLossWeeks'],
    updates: (value, field, allValues, previousValues) => {
      const r = updatePercentageLossWeeks(
        allValues as ReserveWorksheetFormType
      );
      return {
        percentageLossTotal: r,
      };
    },
  },
  {
    field: ['tpdExposure', 'tpdExposureWeeks'],
    updates: (value, field, allValues, previousValues) => {
      const r = updateTPDExposure(allValues as ReserveWorksheetFormType);
      return {
        tpdTotal: r,
      };
    },
  },
  {
    field: ['percentageLossTotal', 'percentageLossCrtotal'],
    updates: (value, field, allValues, previousValues) => {
      const r = updatePermanentImpairment(
        allValues as ReserveWorksheetFormType
      );
      return {
        permanentImpairmentTotal: r,
      };
    },
  },
  {
    field: ['permanentTotalCR', 'permanentTotalWeeks'],
    updates: (value, field, allValues, previousValues) => {
      const r = updatePermanentTotal(allValues as ReserveWorksheetFormType);
      return {
        permanentTotal: r,
      };
    },
  },
  {
    field: ['futureTempTotalExpected', 'futureTempTotalExpectedWeeks'],
    updates: (value, field, allValues, previousValues) => {
      const r = updateFutureTemp(allValues as ReserveWorksheetFormType);
      return {
        totalFuture: r,
      };
    },
  }, // end Indemnity
  {
    field: [
      'futureMedicalPhysician',
      'futureMedicalHospital',
      'futureMedicalMileage',
      'futureMedicalMedicalMgmt',
      'futureMedicalPharmacy',
      'futureMedicalPhysTherapy',
      'medicalPTD',
    ],
    updates: (value, field, allValues, previousValues) => {
      const { newTi, medicalDifference } = calculateMedicalDifference(
        allValues as ReserveWorksheetFormType
      );

      return {
        newMedicalIncurred: newTi,
        medicalRounded: Math.ceil(newTi / 100) * 100,
        medicalDifference: medicalDifference,
      };
    },
  },
  {
    field: ['newlegalReserves', 'legalPTD'],
    updates: (value, field, allValues, previousValues) => {
      const { newTi, legalDifference } = calculateLegalDifference(
        allValues as ReserveWorksheetFormType
      );

      return {
        newLegalIncurred: newTi,
        legalRounded: Math.ceil(newTi / 100) * 100,
        legalDifference: legalDifference,
      };
    },
  },
  {
    field: ['newExpenseReserves', 'expensePTD'],
    updates: (value, field, allValues, previousValues) => {
      const { newTi, expenseDifference } = calculateExpenseDifference(
        allValues as ReserveWorksheetFormType
      );

      return {
        newExpenseIncurred: newTi,
        expenseRounded: Math.ceil(newTi / 100) * 100,
        expenseDifference: expenseDifference,
      };
    },
  },
  {
    field: [
      'indemnityRounded',
      'medicalRounded',
      'legalRounded',
      'expenseRounded',
    ],
    updates: (value, field, allValues, previousValues) => {
      const r = calculateTotalIncurred(allValues as ReserveWorksheetFormType);
      return {
        totalIncurredReserves: r,
      };
    },
  },
  {
    field: [
      'indemnityRounded',
      'medicalRounded',
      'legalRounded',
      'expenseRounded',
    ],
    updates: (value, field, allValues, previousValues) => {
      const r = calculateTotalDifference(allValues as ReserveWorksheetFormType);
      return {
        totalReserveDifference: r,
      };
    },
  }
) as Decorator<ReserveWorksheetFormType, object>;
