import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaFolderOpen, FaPlusCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import {
  fetchInsuranceCompanies,
  fetchInsuranceCompaniesForUser,
} from '../../Actions/ReferenceActions';
import InsuranceCompanyApi from '../../Api/InsuranceCompanyApi';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { useAppDispatch } from '../../Reducers/Store';
import PageScaffold from '../PageScaffold/PageScaffold';
import AddEditInsuranceCompany from './AddEditInsuranceCompany';
import styles from './index.module.css';
import InsuranceCompanyTable from './InsuranceCompanyTable';
import ReferenceApi from '../../Api/ReferenceApi';

export default function InsuranceCompanyMaintenance() {
  const dispatch = useAppDispatch();
  const [insuranceCompanies, setInsuranceCompanies] = useState<
    InsuranceCompany[]
  >([]);

  const [selectedIns, setSelectedIns] = useState<InsuranceCompany | null>(null);
  const [showEdit, setShowEdit] = useState<boolean>(false);

  useEffect(() => {
    getInsuranceCompanies();
  }, []);

  const handleViewEdit = (ins: InsuranceCompany) => {
    setSelectedIns(ins);
    setShowEdit(true);
  };

  const handleAddUpdate = (values: InsuranceCompany) => {
    if (values.insurcoid) {
      return update(values);
    }
    return create(values);
  };

  const update = (values: InsuranceCompany) => {
    return InsuranceCompanyApi.update(values)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          setShowEdit(false);
          getInsuranceCompanies();
          handleChange();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update');
      });
  };

  const create = (values: InsuranceCompany) => {
    return InsuranceCompanyApi.create(values)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          setShowEdit(false);
          getInsuranceCompanies();
          handleChange();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create');
      });
  };

  const getInsuranceCompanies = () => {
    ReferenceApi.getInsuranceCompanies(false)
      .then((res) => {
        setInsuranceCompanies(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = () => {
    dispatch(fetchInsuranceCompanies());
    dispatch(fetchInsuranceCompaniesForUser());
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaFolderOpen className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Insurance Companies</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center pb-3'>
        <Button
          type='button'
          variant='primary'
          size='sm'
          className='button-icon-text'
          onClick={() => {
            setSelectedIns(null);
            setShowEdit(true);
          }}
        >
          <FaPlusCircle /> New
        </Button>
      </div>
      <InsuranceCompanyTable
        data={insuranceCompanies}
        handleViewEdit={handleViewEdit}
      />
      <AddEditInsuranceCompany
        ins={selectedIns}
        show={showEdit}
        setShow={setShowEdit}
        handleAddUpdate={handleAddUpdate}
      />
    </PageScaffold>
  );
}
