import React from 'react';
import styles from './index.module.css';

const redBorder = {
  borderColor: 'red',
};

type FinalRenderFieldProps = {
  input: any;
  label?: string;
  rows?: number;
  underInputNote?: string;
  required?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
  maxLength?: number;
  className?: string;
  onChange: (value: any) => void;
  onBlur: (value: any) => void;
  meta: { touched: boolean; error: string; warning: string };
};

export default function FieldBSRenderTextArea({
  input,
  label,
  rows,
  required,
  disabled,
  maxLength,
  className,
  placeholder,
  underInputNote,
  onChange,
  onBlur,
  meta: { touched, error, warning },
}: FinalRenderFieldProps) {
  return (
    <div className={`position-relative mb-3`}>
      <label htmlFor={input.name} className='form-label fs-6  m-0'>
        {label}
      </label>
      <div>
        <textarea
          {...input}
          id={input.name}
          rows={rows || 1}
          className='form-control form-control-sm'
          placeholder={placeholder || label}
          onChange={(e) => {
            input.onChange(e);
            onChange && onChange(e.target.value);
          }}
          onBlur={(e) => {
            input.onBlur(e);
            onBlur && onBlur(e.target.value);
          }}
          style={touched && error ? redBorder : {}}
          disabled={disabled}
          maxLength={maxLength}
        />
        {touched &&
          ((error && (
            <span className={`${styles.fieldError} text-danger`}>{error}</span>
          )) ||
            (warning && (
              <span className={`${styles.fieldWarning} text-warning`}>
                {warning}
              </span>
            )))}
      </div>
    </div>
  );
}
