import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { VariablePayment } from '../ApiTypes/VariablePayment';

class VariablePaymentsApi {
  static getVariablePaymentsForClaim(claimNumber: string) {
    return axios.get<VariablePayment[]>(
      `${API_URL}/api/claims/${claimNumber}/variablepayments`,
      getAuthHeader()
    );
  }
  static createVariablePayment(payment: VariablePayment) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${payment.claimNo}/variablepayments`,
      payment,
      getAuthHeader()
    );
  }
  static updateVariablePayment(payment: VariablePayment) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${payment.claimNo}/variablepayments/${payment.id}`,
      payment,
      getAuthHeader()
    );
  }
}

export default VariablePaymentsApi;
