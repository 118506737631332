import { RowSelectionState } from '@tanstack/react-table';
import { FormApi } from 'final-form';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { SimpleClaimObj } from '../../ApiTypes/SimpleClaimObj';
import { VBillFee } from '../../ApiTypes/VBillFee';
import { FeeWizardFormType } from './FeeWizardFormType';
import SimpleClaimTable from './SimpleClaimTable';
import VBillFeeTable from './VBillFeeTable';

export default function Page2({
  formInstance,
  values,
  problemFees,
  setSelectedFee,
  feeRowSelection,
  setFeeRowSelection,
  claims,
  handleSelectedClaim,
  fetching,
}: {
  formInstance: FormApi<FeeWizardFormType, Partial<FeeWizardFormType>>;
  values: FeeWizardFormType;
  problemFees: VBillFee[];
  setSelectedFee: (d: VBillFee | null) => void;
  feeRowSelection: RowSelectionState;
  setFeeRowSelection: (obj: object) => void;
  claims: SimpleClaimObj[];
  handleSelectedClaim: (claim: SimpleClaimObj) => void;
  fetching: boolean;
}) {
  const problemMessage =
    "These fees have incorrect Claim No's, please select the correct one from the list of possibles.";
  const noProblemMessage =
    'All fees look like they are tied to valid claims.  You are ok to proceed.';
  return (
    <div>
      <div>
        <Alert variant='info'>
          <Alert.Heading>
            Step 1: Prepare Data and Identify Potential Problem Claims
          </Alert.Heading>
        </Alert>
      </div>
      <div>
        {!fetching && problemFees.length === 0 && (
          <Alert variant='success'>
            <Alert.Heading>{noProblemMessage}</Alert.Heading>
          </Alert>
        )}
        {!fetching && problemFees.length > 0 && (
          <Alert variant='danger'>
            <Alert.Heading>{problemMessage}</Alert.Heading>
          </Alert>
        )}
      </div>
      <p className='fw-bold mb-0'>Fees with Potential Issues:</p>
      <VBillFeeTable
        data={problemFees}
        rowSelection={feeRowSelection}
        setRowSelection={setFeeRowSelection}
        setSelectedFee={setSelectedFee}
      />
      <p className='fw-bold mb-0'>Possible Claim Matches:</p>
      <SimpleClaimTable
        data={claims}
        handleSelectedClaim={handleSelectedClaim}
      />
    </div>
  );
}
