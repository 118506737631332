import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { WorkStatus } from '../ApiTypes/WorkStatus';
import { WorkStatusBodyPart } from '../ApiTypes/WorkStatusBodyPart';
import { WorkStatusGridObj } from '../ApiTypes/WorkStatusGridObject';
import { WorkStatusRequest } from '../ApiTypes/WorkStatusRequest';
import { ReportResponse } from '../ApiTypes/ReportRequest';

class WorkStatusApi {
  static getWorkStatusesForGrid(claimNumber: string) {
    return axios.get<WorkStatusGridObj[]>(
      `${API_URL}/api/claims/${claimNumber}/workstatuses/grid`,
      getAuthHeader()
    );
  }
  static getWorkStatusById(id: number) {
    return axios.get<WorkStatus>(
      `${API_URL}/api/workstatuses/${id}`,
      getAuthHeader()
    );
  }
  static getBodyPartsForWorkStatus(id: number) {
    return axios.get<WorkStatusBodyPart[]>(
      `${API_URL}/api/workstatuses/${id}/bodyparts`,
      getAuthHeader()
    );
  }
  static updateWorkStatus(request: WorkStatusRequest) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/workstatuses/${request?.status?.id}`,
      request,
      getAuthHeader()
    );
  }
  static createWorkStatus(request: WorkStatusRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/workstatuses`,
      request,
      getAuthHeader()
    );
  }
  static deleteWorkStatus(id: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/workstatuses/${id}`,
      getAuthHeader()
    );
  }
  static viewWorkStatusWC104(id: number, claimNumber: string) {
    return axios.get<ReportResponse>(
      `${API_URL}/api/workstatuses/${id}/wc104?claimnumber=${claimNumber}`,
      getAuthHeader()
    );
  }
}

export default WorkStatusApi;
