import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaBriefcase, FaPlusCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { fetchLawFirms } from '../../Actions/ReferenceActions';
import LegalApi from '../../Api/LegalApi';
import { LawFirm } from '../../ApiTypes/LawFirm';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import LawFirmAddEditModal from '../LawFirmAddEdit/LawFirmAddEditModal';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import LawFirmTable from './LawFirmTable';

export default function LawFirms() {
  const dispatch = useAppDispatch();
  const { lawFirms } = useAppSelector((state) => state.reference);
  const [selectedFirm, setSelectedFirm] = useState<LawFirm | null>(null);
  const [showFirmAddEdit, setShowFirmAddEdit] = useState<boolean>(false);

  const getLawFirms = () => {
    dispatch(fetchLawFirms());
  };

  const handleViewFirm = (firm: LawFirm) => {
    setSelectedFirm(firm);
    setShowFirmAddEdit(true);
  };

  const handleNewFirmClick = () => {
    setSelectedFirm(null);
    setShowFirmAddEdit(true);
  };

  const handleLawFirmSubmit = (firm: LawFirm) => {
    if (firm.firmId) {
      return LegalApi.updateLawFirm(firm)
        .then((res) => {
          if (res.data.success) {
            toast.success('Success');
            getLawFirms();
            setSelectedFirm(null);
            setShowFirmAddEdit(false);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to update law firm');
        });
    }
    return LegalApi.createLawFirm(firm)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          getLawFirms();
          setSelectedFirm(null);
          setShowFirmAddEdit(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update law firm');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaBriefcase className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Law Firms</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center gap1Rem py-3'>
        <Button
          type='button'
          size='sm'
          variant='primary'
          className='button-icon-text'
          onClick={handleNewFirmClick}
        >
          <FaPlusCircle /> New
        </Button>
      </div>
      <LawFirmTable data={lawFirms} handleViewFirm={handleViewFirm} />
      <LawFirmAddEditModal
        show={showFirmAddEdit}
        setShow={setShowFirmAddEdit}
        lawFirmToEdit={selectedFirm}
        setLawFirmToEdit={setSelectedFirm}
        handleLawFirmAddEdit={handleLawFirmSubmit}
      />
    </PageScaffold>
  );
}
