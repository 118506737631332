import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ExpandedState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { useParams } from 'react-router-dom';
import { EdiTransaction } from '../../../ApiTypes/EdiTransaction';
import styles from './index.module.css';
import { Button } from 'react-bootstrap';
import {
  FaCaretDown,
  FaCaretRight,
  FaCircle,
  FaEdit,
  FaFilePdf,
  FaShare,
  FaTrash,
} from 'react-icons/fa';
import { displayDateOnly } from '../../../Utils';
import TransactionLogsTable from './TransactionLogsTable';
import { useAppDispatch, useAppSelector } from '../../../Reducers/Store';
import EditEdiModal from '../../EdiForms/EditEdiModal';
import { format } from 'date-fns';
import EdiTransactionApi from '../../../Api/EdiTransactionApi';
import { toast } from 'react-toastify';
import DeleteConfirmationModal from '../../Common/DeleteConfirmationModal';
import { EDIFileType } from '../../../ApiTypes/EdiFileTypeEnum';
import ClaimApi from '../../../Api/ClaimApi';
import { Claim } from '../../../ApiTypes/Claim';
import { Wc1 } from '../../../ApiTypes/Wc1';
import ClaimDataOrWc1Modal from './ClaimDataOrWc1Modal';
import Wc1OrWc2Modal from './Wc1OrWc2Modal';
import ShouldResend1Modal from './ShouldResendModal';
import {
  DevExpressReportRequest,
  DxAvailableReports,
} from '../../../ApiTypes/DevExpressReportRequest';
import { requestDx } from '../../DxReportRequestModal/useDxReportRequestModal';
import { getDxReport } from '../../DocViewModal/useDocViewModal';
import {
  setDocViewFileDownload,
  setShowDocView,
} from '../../../Actions/DocViewActions';
import { FileDownload } from '../../../Types/FileDownLoad';
import { Msg } from '../../Common/AddPdfDocumentToClaim';
import { DocumentType } from '../../../ApiTypes/DocumentType';

export default function EdiTransactionTable({
  transactions,
  getTransactions,
}: {
  transactions: EdiTransaction[];
  getTransactions: () => void;
}) {
  let { claimNumber } = useParams();
  const dispatch = useAppDispatch();
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [showClaimDataOrWc1Modal, setShowClaimDataOrWc1Modal] =
    useState<boolean>(false);

  const [modalData, setModalData] = useState<{
    claim?: Claim;
    transaction?: EdiTransaction;
    wc1?: Wc1 | null;
  }>({});

  const [showWc1OrWc2, setShowWc1OrWc2] = useState<boolean>(false);
  const [transactionToSend, setTransactionToSend] =
    useState<EdiTransaction | null>(null);
  const [showShouldResend, setShowShouldResend] = useState<boolean>(false);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const [selectedTransaction, setSelectedTransaction] =
    useState<EdiTransaction | null>(null);

  const [showEditEdiModal, setShowEditEdiModal] = useState<boolean>(false);

  const tableData = useMemo(() => transactions, [transactions]);

  const { claim } = useAppSelector((state) => state.currentClaimReducer);

  const { userName } = useAppSelector((state) => state.user);

  const setShowDx = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownload = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  const columnData: ColumnDef<EdiTransaction>[] = [
    {
      header: '',
      id: 'options',
      enableSorting: false,
      enableColumnFilter: false,
      minSize: 335,
      maxSize: 350,
      cell: ({ row }) => {
        return (
          <div
            className={`${styles.actionColumn} ${
              row.original.id == selectedTransaction?.id ? 'orange' : ''
            }`}
          >
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='Edit'
              disabled={claim?.isEdiPaperFile === true}
              onClick={() => {
                handleEditEdi(row.original);
              }}
            >
              <FaEdit />
            </Button>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='Send'
              disabled={claim?.isEdiPaperFile === true}
              onClick={() => handleSend(row.original)}
            >
              <FaShare />
            </Button>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='Create 02 Change'
              onClick={() => {
                handle02Change(row.original);
              }}
            >
              Create 02 Change
            </Button>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='View Docs'
              onClick={() => {
                handleViewDoc(row.original);
              }}
            >
              <FaFilePdf />
            </Button>
            <Button
              type='button'
              variant='danger'
              size='sm'
              title='Delete'
              disabled={
                claim?.isEdiPaperFile === true || userName !== 'CEDWARDS'
              }
              onClick={() => {
                setSelectedTransaction(row.original);
                setShowDelete(true);
              }}
            >
              <FaTrash />
            </Button>
          </div>
        );
      },
    },
    {
      header: '',
      id: 'expander',
      size: 30,
      maxSize: 30,
      cell: ({ row }) => (
        <div>
          {row.getCanExpand() ? (
            <Button
              size='sm'
              variant='outline-primary'
              title='Expand'
              onClick={row.getToggleExpandedHandler()}
            >
              {row.getIsExpanded() ? <FaCaretDown /> : <FaCaretRight />}
            </Button>
          ) : (
            <FaCircle />
          )}
        </div>
      ),
    },
    {
      header: 'Id',
      accessorFn: (d) => `${d.id}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Form',
      accessorKey: 'form',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Type',
      accessorKey: 'maintTypeCode',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Benefit',
      accessorKey: 'benefitTypeName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },

    {
      header: 'Date',
      accessorKey: 'submitDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.submitDate ?? ''),
    },
    {
      header: 'Creator',
      accessorKey: 'logCreator',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Status',
      accessorKey: 'logStatusString',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Error',
      accessorKey: 'notes',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, [selectedTransaction]);

  const displayToast = (
    docTypeId: number,
    fileName: string,
    claimNumber: string,
    base64Data: string
  ) => {
    toast(<Msg />, {
      autoClose: false,
      closeOnClick: false,
      hideProgressBar: true,
      draggable: false,
      data: {
        base64Data,
        fileName,
        claimNumber,
        docTypeId,
        text: `Would you like to save a copy of ${fileName} to the Documents tab?"`,
      },
    });
  };

  const handleEditEdi = (t: EdiTransaction) => {
    setSelectedTransaction(t);
    setShowEditEdiModal(true);
  };

  const handleCloseEditEdiModal = () => {
    setShowEditEdiModal(false);
    setSelectedTransaction(null);
  };

  const handle02Change = (t: EdiTransaction) => {
    const updated: EdiTransaction = {
      ...t,
      fileType: 52,
      maintTypeCode: '02',
      submitDate: format(new Date(), 'yyyy-MM-dd'),
      submitStatus: 0, // new status
      ediTransactionLogs: null,
      changeEdiTransactionId: t.id,
      id: 0,
    };

    return create02Transaction(updated);
  };

  const handleViewDoc = async (t: EdiTransaction) => {
    setSelectedTransaction(t);
    if (t.wc4id !== null && t.wc4id > 0) {
      const request: DevExpressReportRequest = {
        ...requestDx,
        report: DxAvailableReports.rptEDIProgressReport,
        wC4Id: t.wc4id,
        userId: userName,
        eDITransactionId: t!.id,
      };
      getDxReport(request, setFileDownload, setShowDx);
      return;
    } else {
      const wc1 = await ClaimApi.getWc1(t.claimno)
        .then((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
      const tFileType = t.fileType;
      if (
        tFileType === EDIFileType.FROI_00 ||
        tFileType === EDIFileType.FROI_04 ||
        tFileType === EDIFileType.FROI_02 ||
        tFileType === EDIFileType.FROI_01
      ) {
        return handleNoWc1(claim!, t, wc1);
      } else if (
        tFileType === EDIFileType.SROI_IP ||
        tFileType === EDIFileType.SROI_EP
      ) {
        return handleWc1OrWc2(claim!, t, wc1);
      } else if (
        tFileType === EDIFileType.SROI_RB ||
        tFileType === EDIFileType.SROI_CB ||
        tFileType === EDIFileType.SROI_PY ||
        tFileType === EDIFileType.SROI_S1 ||
        tFileType === EDIFileType.SROI_S2 ||
        tFileType === EDIFileType.SROI_S3 ||
        tFileType === EDIFileType.SROI_S4 ||
        tFileType === EDIFileType.SROI_S7 ||
        tFileType === EDIFileType.SROI_02 ||
        tFileType === EDIFileType.SROI_ER
      ) {
        const request: DevExpressReportRequest = {
          ...requestDx,
          report: DxAvailableReports.rptWC2,
          eDITransactionId: t.id,
        };
        const success = await getDxReport(request, setFileDownload, setShowDx);
        if (success) {
          displayToast(
            DocumentType.BoardForms,
            success.reportFileName,
            claimNumber!,
            success.reportBytes
          );
        }
        return;
      } else if (
        tFileType === EDIFileType.SROI_PD ||
        tFileType === EDIFileType.SROI_04
      ) {
        const request: DevExpressReportRequest = {
          ...requestDx,
          report: DxAvailableReports.rptWC3,
          eDITransactionId: t.id,
        };
        const res = await getDxReport(request, setFileDownload, setShowDx);
        if (res != null) {
          displayToast(
            DocumentType.BoardForms,
            res.reportFileName,
            claimNumber!,
            res.reportBytes
          );
        }
        return;
      } else if (
        tFileType === EDIFileType.SROI_FN ||
        tFileType === EDIFileType.SROI_AN
      ) {
        if (t.wc4id !== null && t.wc4id > 0) {
          const request: DevExpressReportRequest = {
            ...requestDx,
            report: DxAvailableReports.rptEDIProgressReport,
            wC4Id: t.wc4id,
            userId: userName,
            eDITransactionId: t!.id,
          };
          getDxReport(request, setFileDownload, setShowDx);
          return;
        }
      } else {
        return handleNoWc1(claim!, t, wc1);
      }
    }
  };

  const handleNoWc1 = async (
    claim: Claim,
    t: EdiTransaction,
    wc1: Wc1 | null
  ) => {
    if (!wc1) {
      setModalData({
        claim,
        transaction: t,
        wc1,
      });
      setShowClaimDataOrWc1Modal(true);
    } else {
      const request: DevExpressReportRequest = {
        ...requestDx,
        report: DxAvailableReports.rptWC1,
        claimNumber: claimNumber!,
        // useClaimDataForWc1: true,
        eDITransactionId: t!.id,
      };
      const res = await getDxReport(request, setFileDownload, setShowDx);
      if (res != null) {
        displayToast(
          DocumentType.BoardForms,
          res.reportFileName,
          claimNumber!,
          res.reportBytes
        );
      }
    }
  };

  const handleShowClaimDataResponse = async (showClaimData: boolean) => {
    const { claim, transaction, wc1 } = modalData;
    setShowClaimDataOrWc1Modal(false);
    const request: DevExpressReportRequest = {
      ...requestDx,
      report: DxAvailableReports.rptWC1,
      claimNumber: claimNumber!,
      useClaimDataForWc1: showClaimData,
      eDITransactionId: transaction!.id,
    };
    const res = await getDxReport(request, setFileDownload, setShowDx);
    if (res != null) {
      displayToast(
        DocumentType.BoardForms,
        res.reportFileName,
        claimNumber!,
        res.reportBytes
      );
    }
  };

  const handleWc1OrWc2 = (claim: Claim, t: EdiTransaction, wc1: Wc1 | null) => {
    setModalData({
      claim,
      transaction: t,
      wc1,
    });
    setShowWc1OrWc2(true);
  };

  const handleWc1OrWc2Response = async (form: string) => {
    const { claim, transaction, wc1 } = modalData;
    setShowWc1OrWc2(false);
    if (form === 'wc1') {
      handleNoWc1(claim!, transaction!, wc1 ?? null);
    } else {
      const request: DevExpressReportRequest = {
        ...requestDx,
        report: DxAvailableReports.rptWC2,
        eDITransactionId: transaction!.id,
      };
      const res = await getDxReport(request, setFileDownload, setShowDx);
      if (res != null) {
        displayToast(
          DocumentType.BoardForms,
          res.reportFileName,
          claimNumber!,
          res.reportBytes
        );
      }
    }
  };

  const create02Transaction = (t: EdiTransaction) => {
    return EdiTransactionApi.createEdiTransaction(t.claimno, t)
      .then((res) => {
        if (res.data.success) {
          getTransactions();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to make 02 change');
      });
  };

  const handleDelete = () => {
    if (selectedTransaction) {
      return EdiTransactionApi.deleteEdiTransaction(
        selectedTransaction.claimno,
        selectedTransaction.id
      )
        .then((res) => {
          if (res.data.success) {
            getTransactions();
            setShowDelete(false);
            setSelectedTransaction(null);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to delete transaction');
        });
    }

    return Promise.resolve();
  };

  const handleSend = (t: EdiTransaction) => {
    setSelectedTransaction(t);
    if (
      t.submitStatus === 100 ||
      t.ediTransactionLogs?.find((l) => l.status === 100)
    ) {
      setTransactionToSend(t);
      setShowShouldResend(true);
    } else {
      //  send transaction
      return handleSendingTransaction(t);
    }
  };

  const handleResendResponse = (shouldResend: boolean) => {
    setShowShouldResend(false);
    if (shouldResend) {
      handleSendingTransaction(transactionToSend!);
    } else {
      setTransactionToSend(null);
    }
  };

  const handleSendingTransaction = (t: EdiTransaction) => {
    EdiTransactionApi.sendEdiTransaction(claimNumber!, t.id)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to send transaction');
      });
  };

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      expanded,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
    getRowCanExpand: (row) =>
      row.original.ediTransactionLogs !== null &&
      row.original.ediTransactionLogs.length > 0,
  });

  return (
    <>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        createChildTable={TransactionLogsTable}
        subRowProp={'ediTransactionLogs'}
        selectableRow={true}
        highlightRow={true}
      />
      <EditEdiModal
        show={showEditEdiModal}
        handleCloseModal={handleCloseEditEdiModal}
        transaction={selectedTransaction}
        getTransactions={getTransactions}
      />
      <DeleteConfirmationModal
        show={showDelete}
        setShow={setShowDelete}
        setNull={setSelectedTransaction}
        handleDelete={handleDelete}
      />
      <ClaimDataOrWc1Modal
        show={showClaimDataOrWc1Modal}
        setShow={setShowClaimDataOrWc1Modal}
        handleShowClaimDataResponse={handleShowClaimDataResponse}
      />
      <Wc1OrWc2Modal
        show={showWc1OrWc2}
        setShow={setShowWc1OrWc2}
        handleWc1OrWc2Response={handleWc1OrWc2Response}
      />
      <ShouldResend1Modal
        show={showShouldResend}
        setShow={setShowShouldResend}
        handleResendResponse={handleResendResponse}
      />
    </>
  );
}
