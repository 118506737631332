import { addDays, format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { FaStickyNote } from 'react-icons/fa';
import { ScanDocNotesRequest } from '../../ApiTypes/ScanDocNotesRequest';
import { ScanDocSimple } from '../../ApiTypes/ScanDocSimple';
import { ScanDocNotesRequestFormType } from '../../Types/ScanDocNotesRequestFormType';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import styles from './index.module.css';

export default function RequestDocNotesModal({
  show,
  setShow,
  noteRequest,
  doc,
  claimNumber,
  claimantName,
  handleEditSaveRequestDocNote,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  noteRequest: ScanDocNotesRequest | null;
  doc: ScanDocSimple | null;
  claimNumber: string;
  claimantName: string;
  handleEditSaveRequestDocNote: (
    values: ScanDocNotesRequestFormType
  ) => Promise<void>;
}) {
  const [initialFormValues, setInitialFormValues] = useState<
    Partial<ScanDocNotesRequestFormType>
  >({});

  useEffect(() => {
    createInitialValues();
  }, [noteRequest, doc]);

  const createInitialValues = () => {
    if (noteRequest) {
      setInitialFormValues({
        ...noteRequest,
        save: false,
        remindDate: format(addDays(new Date(), 30), 'yyyy-MM-dd'),
      });
    } else if (doc) {
      setInitialFormValues({
        keyNotesReqId: 0,
        claimantName,
        scanDocId: doc.docid,
        dateOfService: doc.serviceFrom,
        save: false,
        remindDate: format(addDays(new Date(), 30), 'yyyy-MM-dd'),
      });
    } else {
      setInitialFormValues({});
    }
  };

  const onSubmit = (values: ScanDocNotesRequestFormType) => {
    return handleEditSaveRequestDocNote(values);
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='Doctor-Notes-Requests-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Doctor-Notes-Requests-modal'
        >
          <FaStickyNote className='pe-1' /> Request Doctor Notes
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p>Claim Number: {claimNumber}</p>
          <p>Claimant Name: {claimantName}</p>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={initialFormValues}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='dateOfService'
                    label='Service From'
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                    validate={requiredField}
                  />
                  <Field
                    name='serviceTo'
                    label='Service To'
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                    validate={requiredField}
                  />
                  <Field
                    name='amount'
                    label='Amount'
                    type='text'
                    component={FieldBSRenderText}
                  />
                  <div className='mb-3'>
                    <Field
                      name='box1'
                      label='UB04/CMS1500 with CPT Codes and Tax ID #'
                      type='checkbox'
                      checked={values?.box1}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='box2'
                      label='Medical notes, ER records, Test Results'
                      type='checkbox'
                      checked={values?.box2}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='box3'
                      label='Physical Therapy Notes'
                      type='checkbox'
                      checked={values?.box3}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='box4'
                      label='Radiology or Pathology Report'
                      type='checkbox'
                      checked={values?.box4}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='box5'
                      label='Trip Notes'
                      type='checkbox'
                      checked={values?.box5}
                      component={FieldBSRenderCheckbox}
                    />
                  </div>

                  <Field
                    name='remindDate'
                    label='Reminder'
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                    validate={requiredField}
                  />
                  <Field
                    name='address'
                    label='Address'
                    rows={3}
                    component={FieldBSRenderTextArea}
                  />
                  {/* <Field
                    name='save'
                    label='Save a copy to the documents tab?'
                    type='checkbox'
                    checked={values?.save}
                    component={FieldBSRenderCheckbox}
                  /> */}

                  <div className={`${styles.buttonDiv} mt-3`}>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
