import { AutomatedReportingJobLog } from './AutomatedReportingJobLog';
import { Employer } from './Employer';
import { InsuranceCompany } from './InsuranceCompany';

export interface AutomatedReportingJob {
  id: number;
  insurCoId: number | null;
  employerId: number | null;
  fromTreatyYear: number | null;
  toTreatyYear: number | null;
  sendTo: string | null;
  ccto: string | null;
  subject: string | null;
  emailBody: string | null;
  createdBy: string | null;
  dateCreated: string | null;
  dayOfMonthToSend: number | null;
  stopDate: string | null;
  startDate: string | null;
  lastRunDate: string | null;
  type: number | null;
  fromName: string | null;
  title: string | null;
  deleted: boolean | null;
  openOnly: boolean | null;
  fromEmailAddress: string | null;
  reportType: number | null;
  automatedReportingJobLogs: AutomatedReportingJobLog[] | null;
  employer: Employer | null;
  insuranceCompany: InsuranceCompany | null;
}

export enum AutomatedReportSendType {
  Monthly = 0,
  Bimonthly = 1,
  Quarterly = 2,
  Annually = 3,
}

export enum AutomatedReportType {
  LossRun = 1,
  PremiumAndLoss = 2,
}
