import React, { useState, useMemo } from 'react';
import { displayDateOnly } from '../../../Utils';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { ClaimSearchResult } from '../../../ApiTypes/ClaimSearchResult';
import { formatSSN } from '../../../Utils/InputFormatters';
import { Link } from 'react-router-dom';
import { FaExternalLinkAlt, FaLink } from 'react-icons/fa';
import {
  ClaimTypes,
  ClaimTypesURL,
} from '../../../ApiTypes/ClaimTypeConfiguration';
import { useAppSelector } from '../../../Reducers/Store';

export default function ClaimSearchTable({
  data,
  getSelectedClaim,
  setShow,
}: {
  data: ClaimSearchResult[];
  getSelectedClaim?: (result: ClaimSearchResult) => void;
  setShow: (show: boolean) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const { userModel } = useAppSelector((state) => state.user);

  const tableData = useMemo(
    () =>
      data.filter((d) =>
        userModel?.userClaimTypeAccesses?.find(
          (v) => v.claimTypeId === d.claimTypeId
        )
      ),
    [data]
  );

  const columnData: ColumnDef<ClaimSearchResult>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      size: 75,
      maxSize: 75,
      cell: (d) => {
        if (getSelectedClaim) {
          return (
            <Button
              type='button'
              size='sm'
              onClick={() => getSelectedClaim(d.row.original)}
            >
              Select
            </Button>
          );
        } else {
          var claimTypeId = d.row.original.claimTypeId;
          if (!claimTypeId) {
            claimTypeId = ClaimTypes.WorkersComp;
          }
          return (
            <div className='d-flex justify-content-around'>
              <Link
                to={`/${ClaimTypesURL[claimTypeId]}/${d.row.original.claimNo}`}
                onClick={() => setShow(false)}
              >
                <Button type='button' size='sm' title='Open'>
                  <FaLink />
                </Button>
              </Link>
              <Link
                to={`/${ClaimTypesURL[claimTypeId]}/${d.row.original.claimNo}`}
                target='_blank'
              >
                <Button type='button' size='sm' title='Open New Tab'>
                  <FaExternalLinkAlt />
                </Button>
              </Link>
            </div>
          );
        }
      },
    },
    {
      header: 'Account',
      accessorFn: (d) => d.accountNumber,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
    },
    {
      header: 'Claim Type',
      accessorFn: (d) => d.claimTypeName,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claim No',
      accessorFn: (d) => d.claimNo,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
    },
    // {
    //   header: 'Affiliate Claim No',
    //   accessorFn: (d) => d.affiliateClaimNo,
    //   sortingFn: 'alphanumeric',
    //   filterFn: 'includesStringSensitive',
    // },
    {
      header: 'Injury Date',
      accessorKey: 'injuryDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.injuryDate ?? ''),
    },
    {
      header: 'Last Name',
      accessorFn: (d) => d.lastName,
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'First Name',
      accessorFn: (d) => d.firstName,
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'DOB',
      accessorKey: 'dob',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dob ?? ''),
    },
    {
      header: 'Employer',
      accessorKey: 'employerName',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'SSN',
      accessorFn: (d) => formatSSN(d.ssn),
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
    },
    {
      header: 'Status',
      accessorFn: (d) => d.status,
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Adjuster',
      accessorFn: (d) => {
        return d.adjusterName;
      },
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Severity',
      accessorFn: (d) => d.severity,
      sortingFn: 'text',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);
  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
      />
    </div>
  );
}
