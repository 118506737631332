import accounting from "accounting";
import { TransactionSearchResult } from "../../ApiTypes/TransactionSearchResult";
import { displayDateOnly } from "../../Utils";

export const paymentReportExportHandler = (
  transactions: TransactionSearchResult[],
   getPayCodeDescription: (code: string) => string,
    getFbucketDescription: (id: number) => string
    ) => {
  const columns = [
    "Claim No",
    "Service From",
    "Service To",
    "PayCode",
    "Amount",
    "Check Number",
    "Check Date",
    "Memo",
    "Approved By",
    "Approval Date",
    "Manager Approved By",
    "Manager Approved Date",
    "Bucket",
    "Check Payable To",
    "Company Name",
    "Tax Id",

  ]

  const rows: any[][] = [];

   transactions.forEach(t => {
    const tRow =  [
      t.claimNo ?? "",
      displayDateOnly(t.serviceFrom ?? ""),
      displayDateOnly(t.serviceTo ?? ""),
      getPayCodeDescription(t.payCode ?? ''),
      accounting.formatMoney(t.amount ?? 0),
      t.checkNumber ?? "",
      displayDateOnly(t.checkDate ?? ""),
      t.memo ?? "",
      t.pmtApprovedBy ?? "",
      displayDateOnly(t.approvalDate ?? ""),
      t.managerApprovedBy ?? "",
      displayDateOnly(t.managerApprovedDate ?? ""),
      getFbucketDescription(t.fbucket ? +t.fbucket : 0),
      t.checkPayableTo ?? "",
      t.companyName ?? "",
      t.providerTaxId ?? ""
    ]

    rows.push(tRow)
  })

  return {
    columns,
    rows,
    fileName: "",
  }
}


