import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { SettlementPaymentDetail } from '../../../ApiTypes/SettlementPaymentDetail';
import { makeMoney } from '../../../Utils/InputFormatters';

export default function SettlementPaymentDetailTable({
  details,
  setSelectedDetail,
  setShowAddEditDetails,
}: {
  details: SettlementPaymentDetail[];
  setSelectedDetail: (detail: SettlementPaymentDetail | null) => void;
  setShowAddEditDetails: (show: boolean) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => details, [details]);

  const columnData: ColumnDef<SettlementPaymentDetail>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 75,
      size: 75,
      cell: (d) => {
        return (
          <div className='d-flex justify-content-around'>
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='View/Edit'
              onClick={() => {
                setSelectedDetail(d.row.original);
                setShowAddEditDetails(true);
              }}
            >
              <FaEdit />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Type',
      accessorKey: 'typeName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Name',
      accessorKey: 'displayName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Amount',
      accessorFn: (d) => (d.amount ? `${d.amount}` : ''),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) => makeMoney(d.getValue()),
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
      />
    </div>
  );
}
