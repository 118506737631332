import React from 'react';
import { Table as BSTable, Button } from 'react-bootstrap';
import { DoctorLocation } from '../../ApiTypes/DoctorLocation';
import { Link } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';

const SimpleDoctorLocationTable = (data: DoctorLocation[]): JSX.Element => {
  const childColumnData: {
    header: string;
    accessorKey: string;
    cell?: (d: string) => string | undefined;
  }[] = [
    {
      header: 'View/Edit',
      accessorKey: 'action',
    },
    {
      header: 'Address',
      accessorKey: 'address',
    },
    {
      header: 'City',
      accessorKey: 'city',
    },
    {
      header: 'State',
      accessorKey: 'state',
    },
    {
      header: 'Zip',
      accessorKey: 'zip',
    },
  ];

  return (
    <div className='d-flex justify-content-center'>
      <BSTable
        size='sm'
        striped
        bordered
        hover
        style={{
          width: 'auto',
          marginLeft: '2rem',
        }}
      >
        <thead>
          <tr>
            {childColumnData.map((h) => (
              <th key={h.header}>{h.header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <tr key={i}>
              {childColumnData.map((h) => {
                if (h.accessorKey === 'action') {
                  return (
                    <Link to={`/doctorlocations/${d.id}`}>
                      <Button
                        type='button'
                        variant='primary'
                        size='sm'
                        title='View/Edit'
                      >
                        <FaEye />
                      </Button>
                    </Link>
                  );
                }
                if (!h.cell) {
                  return (
                    <td
                      style={{
                        whiteSpace: 'nowrap',
                        padding: '5px',
                      }}
                      key={`${h.header}i`}
                    >
                      {d[h.accessorKey as keyof typeof d] as string}
                    </td>
                  );
                } else {
                  return (
                    <td
                      style={{
                        whiteSpace: 'nowrap',
                        padding: '5px',
                      }}
                      key={`${h.header}i`}
                    >
                      {h.cell(d[h.accessorKey as keyof typeof d]! as string)}
                    </td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </BSTable>
    </div>
  );
};

export default SimpleDoctorLocationTable;
