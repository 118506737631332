import React, { useEffect, useState } from 'react';
import {
  Container,
  Modal,
  Tab,
  Tabs,
  Button,
  Form,
  Spinner,
} from 'react-bootstrap';
import { Form as RFFForm } from 'react-final-form';
import { FaEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';
import EmployerApi from '../../Api/EmployerApi';
import Wc1Api from '../../Api/Wc1Api';
import { Employer } from '../../ApiTypes/Employer';
import { Location } from '../../ApiTypes/Location';
import { Wc1 } from '../../ApiTypes/Wc1';
import { useAppSelector } from '../../Reducers/Store';
import styles from './index.module.css';
import Wc1Benefits from './Wc1Benefits';
import Wc1ClaimantForm from './Wc1ClaimantForm';
import Wc1Documents from './Wc1Documents';
import Wc1EmployerForm from './Wc1EmployerForm';
import Wc1Employment from './Wc1Employment';
import WC1Injury from './WC1Injury';
import Wc1Requestor from './Wc1Requestor';
import WC1Treatment from './WC1Treatment';

export default function EditWc1({
  show,
  setShow,
  wc1,
  getWc1,
}: {
  show: boolean;
  setShow: (value: boolean) => void;
  wc1: Wc1 | null;
  getWc1: () => void;
}) {
  const { states, insuranceCompanies } = useAppSelector(
    (state) => state.reference
  );
  const [employers, setEmployers] = useState<Employer[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);

  useEffect(() => {
    handleLoad();
  }, [wc1]);

  const onSubmit = (values: Wc1) => {
    const copy = { ...values };
    if (copy.wc1id > 0) {
      return update(copy);
    }
  };

  const update = (wc1: Wc1) => {
    Wc1Api.updateWc1(wc1)
      .then((res) => {
        if (res.data.success) {
          setShow(false);
          getWc1();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update Wc1');
      });
  };

  const handleLoad = () => {
    if (wc1) {
      if (wc1.insCoId) {
        getEmployersForInsuranceCompany(wc1.insCoId);
      }
      if (wc1.employerId) {
        getLocationsByEmployerId(wc1.employerId);
      }
    }
  };

  const getEmployersForInsuranceCompany = (id: number) => {
    if (id) {
      const insCo = insuranceCompanies.find((x) => x.insurcoid === id);
      if (insCo) {
        EmployerApi.getEmployerList({ accountNumber: insCo.accountnumber! })
          .then((res) => {
            setEmployers(res.data);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const getLocationsByEmployerId = (id: number) => {
    if (id) {
      EmployerApi.getEmployerLocationsByEmployerId(id)
        .then((res) => {
          setLocations(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => setShow(false)}
      dialogClassName=''
      aria-labelledby='WC1-Edit-Form-modal'
      fullscreen={true}
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='WC1-Edit-Form-modal'>
          <FaEdit className='pe-1' /> Edit WC1
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={wc1 ?? {}}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Tabs
                    defaultActiveKey='employer'
                    id='wc1-tabs'
                    className='mb-3'
                  >
                    <Tab eventKey='employer' title='Employer'>
                      <Wc1EmployerForm
                        states={states}
                        values={values}
                        employers={employers}
                        getEmployersForInsuranceCompany={
                          getEmployersForInsuranceCompany
                        }
                        locations={locations}
                        getLocationsByEmployerId={getLocationsByEmployerId}
                      />
                    </Tab>
                    <Tab eventKey='claimant' title='Claimant'>
                      <Wc1ClaimantForm states={states} values={values} />
                    </Tab>
                    <Tab eventKey='employment' title='Employment'>
                      <Wc1Employment values={values} />
                    </Tab>
                    <Tab eventKey='injury' title='Injury'>
                      <WC1Injury values={values} />
                    </Tab>
                    <Tab eventKey='treatment' title='Treatment'>
                      <WC1Treatment values={values} />
                    </Tab>
                    <Tab eventKey='requestor' title='Requestor'>
                      <Wc1Requestor values={values} />
                    </Tab>
                    <Tab eventKey='benefits' title='Benefits'>
                      <Wc1Benefits values={values} />
                    </Tab>
                    <Tab eventKey='documents' title='Documents'>
                      <Wc1Documents
                        values={values}
                        files={wc1?.wc1files ?? []}
                      />
                    </Tab>
                  </Tabs>
                  <div className={styles.buttonDiv}>
                    <Button type='submit' size='sm' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                    <Button
                      type='button'
                      size='sm'
                      variant='secondary'
                      onClick={() => {
                        form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
