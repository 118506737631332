import React from 'react';
import TopToolbar from '../TopToolbar/TopToolbar';
import styles from './index.module.css';
import DocViewModal from '../DocViewModal/DocViewModal';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import {
  setDocViewFileDownload,
  setShowDocView,
} from '../../Actions/DocViewActions';
import { FileDownload } from '../../Types/FileDownLoad';

export default function AppLayout({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const dispatch = useAppDispatch();
  const { show, fileDownload } = useAppSelector((state) => state.docView);

  const setShow = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownload = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  return (
    <div className={styles.mainScreen}>
      <TopToolbar />
      {children}
      <DocViewModal
        show={show}
        setShow={setShow}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
    </div>
  );
}
