import React from 'react';
import { Modal, Container, Button } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';

export default function ClaimDataOrWc1Modal({
  show,
  setShow,
  handleShowClaimDataResponse,
}: {
  show: boolean;
  setShow: (s: boolean) => void;
  handleShowClaimDataResponse: (showClaimData: boolean) => void;
}) {
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => setShow(false)}
      dialogClassName=''
      aria-labelledby='Show-claim-data-or-wc1-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Show-claim-data-or-wc1-modal'
        >
          <FaEye className='pe-1' /> Data?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p>
            Would you like to use Claim Data instead of Data from the WC1
            Screen?
          </p>
          <div className='d-flex justify-content-around align-items-center'>
            <Button
              type='button'
              variant='outline-primary'
              size='lg'
              onClick={() => handleShowClaimDataResponse(true)}
            >
              Claim Data
            </Button>
            <Button
              type='button'
              variant='outline-primary'
              size='lg'
              onClick={() => handleShowClaimDataResponse(false)}
            >
              WC1 Data
            </Button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
