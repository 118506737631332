import { createAction } from '@reduxjs/toolkit';
import { Claim } from '../ApiTypes/Claim';
import { Claimant } from '../ApiTypes/Claimant';
import { Employer } from '../ApiTypes/Employer';
import { InsuranceCompany } from '../ApiTypes/InsuranceCompany';

export const updateCurrentClaimInReducer = createAction<Claim>(
  'UPDATE_CURRENT_CLAIM_IN_REDUCER'
);

export const updateCurrentClaimantInReducer = createAction<Claimant>(
  'UPDATE_CURRENT_CLAIMANT_IN_REDUCER'
);

export const updateCurrentEmployerInReducer = createAction<Employer>(
  'UPDATE_CURRENT_EMPLOYER_IN_REDUCER'
);
export const updateCurrentEmployerPolicyInReducer = createAction<number>(
  'UPDATE_CURRENT_EMPLOYER_POLICY_IN_REDUCER'
);
export const updateCurrentInsuranceCompanyInReducer =
  createAction<InsuranceCompany>('UPDATE_CURRENT_INSURANCE_COMPANY_IN_REDUCER');
