import React from 'react';
import { Table as BSTable } from 'react-bootstrap';
import { ClaimAuthorizedPhysicianBodyPart } from '../../../ApiTypes/ClaimAuthorizedPhysicianBodyPart';

const ClaimAuthorizedPhysicianBodyPartTable = (
  data: ClaimAuthorizedPhysicianBodyPart[]
): JSX.Element => {
  const childColumnData: {
    header: string;
    accessorKey: string;
    accessorFn?: (d: any) => any;
    cell?: (d: any) => JSX.Element;
  }[] = [
    {
      header: 'Id',
      accessorKey: 'id',
    },
    {
      header: 'Claim Authorized Physician Id',
      accessorKey: 'claimAuthorizedPhysicianId',
    },
    {
      header: 'Body Part Id',
      accessorKey: 'bodyPartId',
    },
    {
      header: 'Body Part Name',
      accessorKey: 'bodyPartName',
    },
  ];

  return (
    <BSTable
      size='sm'
      striped
      bordered
      hover
      style={{
        width: 'auto',
        marginLeft: '2rem',
      }}
    >
      <thead>
        <tr>
          {childColumnData.map((h) => (
            <th className='px-2' key={h.header}>
              {h.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((d, i) => (
          <tr key={i}>
            {childColumnData.map((h) => {
              if (!h.cell) {
                return (
                  <td
                    style={{
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                    }}
                    key={`${h.header}i`}
                  >
                    {h.accessorFn
                      ? h.accessorFn(d)
                      : `${d[h.accessorKey as keyof typeof d]}`}
                  </td>
                );
              } else {
                return (
                  <td
                    style={{
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                    }}
                    key={`${h.header}i`}
                  >
                    {h.cell(`${d[h.accessorKey as keyof typeof d]}`)}
                  </td>
                );
              }
            })}
          </tr>
        ))}
      </tbody>
    </BSTable>
  );
};

export default ClaimAuthorizedPhysicianBodyPartTable;
