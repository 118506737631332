import React from 'react'
import { Spinner } from 'react-bootstrap'
import styles from "./index.module.css"

export default function Loading() {
  return (
    <div className={styles.LoadingContainer}>
      <Spinner animation="grow" />
    </div>
  )
}
