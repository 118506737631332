import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LetterApi from '../../../Api/LetterApi';
import { InsCoLetterLog } from '../../../ApiTypes/InsCoLetterLog';
import { Letter } from '../../../ApiTypes/Letter';
import { useAppSelector } from '../../../Reducers/Store';
import HistoryTable from './HistoryTable';

import Letters from './Letters';

export default function LettersTab({ claimType }: { claimType: number }) {
  let { claimNumber } = useParams();
  const [letters, setLetters] = useState<Letter[]>([]);
  const [letterLogs, setLetterLogs] = useState<InsCoLetterLog[]>([]);
  const [insuranceCompanyId, setInsuranceCompanyId] = useState(0);

  const { claim, insuranceCompany } = useAppSelector(
    (state) => state.currentClaimReducer
  );

  useEffect(() => {
    getLetters();
    getHistory();
    setInCoId();
  }, [claimNumber, claim]);

  const setInCoId = () => {
    if (claim) {
      setInsuranceCompanyId(claim?.insurcoid ?? 0);
    }
  };

  const getLetters = () => {
    if (claimNumber && claim?.insurcoid) {
      LetterApi.getLettersByInsCoId(claim.insurcoid)
        .then((res) => {
          setLetters(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getHistory = () => {
    if (claimNumber) {
      LetterApi.getHistoryForClaim(claimNumber)
        .then((res) => {
          setLetterLogs(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className='w-100 h-100 d-flex'>
      <Letters
        letters={letters}
        insuranceCompanyId={insuranceCompanyId}
        insuranceCompanyName={insuranceCompany?.fullname ?? ''}
        getLetters={getLetters}
        getHistory={getHistory}
        claimType={claimType}
      />
      <div className='w-100 px-3'>
        <HistoryTable letterLogs={letterLogs} />
      </div>
    </div>
  );
}
