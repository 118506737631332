export const LetterFieldOptions = [
  "Adjuster_Email",
  "Adjuster_Fax",
  "Adjuster_FirstName",
  "Adjuster_LastName",
  "Adjuster_Phone",
  "Claim_AccidentCounty",
  "Claim_AccidentState",
  "Claim_ActionPlan",
  "Claim_AsOfDate",
  "Claim_AvgWeeklyWage",
  "Claim_ClaimNo",
  "Claim_ClassCode",
  "Claim_CompRate",
  "Claim_controverted",
  "Claim_DeductiblePaid",
  "Claim_DepartmentName",
  "Claim_Description",
  "Claim_DisabilityDate",
  "Claim_ediclaimno",
  "Claim_excess",
  "Claim_excessrecovery",
  "Claim_expincurred",
  "Claim_exppaid",
  "Claim_exppaidthismonth",
  "Claim_expreserves",
  "Claim_indincurred",
  "Claim_indpaid",
  "Claim_indpaidthismonth",
  "Claim_indreserves",
  "Claim_InjuryBodyPart",
  "Claim_InjuryCause",
  "Claim_InjuryDate",
  "Claim_InjuryResult",
  "Claim_legincurred",
  "Claim_legpaid",
  "Claim_legpaidthismonth",
  "Claim_legreserves",
  "Claim_LocationName",
  "Claim_MedicareStatus",
  "Claim_medincurred",
  "Claim_MedIndPaid",
  "Claim_medpaid",
  "Claim_medpaidthismonth",
  "Claim_medreserves",
  "Claim_Occupation",
  "Claim_ReportDate",
  "Claim_RequestedAuthority",
  "Claim_ReservesEval",
  "Claim_SettlementDemand",
  "Claim_Severity",
  "Claim_sir_amount",
  "Claim_sitf",
  "Claim_SITFRecovery",
  "Claim_Status",
  "Claim_StatusEval",
  "Claim_subro",
  "Claim_SUBRORecovery",
  "Claim_totalincurred",
  "Claim_totalpaid",
  "Claim_totalpaidthismonth",
  "Claim_TotalRecovery",
  "Claim_totalreserves",
  "Claim_treatyyear",
  "Claimant_CellPhone",
  "Claimant_DOB",
  "Claimant_EmploymentDate",
  "Claimant_FirstName",
  "Claimant_HomePhone",
  "Claimant_LastName",
  "Claimant_MailingAddress1",
  "Claimant_MailingAddress2",
  "Claimant_MailingCity",
  "Claimant_MailingState",
  "Claimant_MailingZip",
  "Claimant_MiddleName",
  "Claimant_PhysicalAddress1",
  "Claimant_PhysicalAddress2",
  "Claimant_PhysicalCity",
  "Claimant_PhysicalState",
  "Claimant_PhysicalZip",
  "Claimant_Sex",
  "Claimant_SSN",
  "Claimant_Title",
  "Claimant_WorkPhone",
  "CurrentUser_Email",
  "CurrentUser_Name",
  "CurrentUser_Phone",
  "DatePrintedShort",
  "DatePrintedLong",
  "Employer_Address",
  "Employer_Address2",
  "Employer_AdjusterIdLT",
  "Employer_AdjusterIdMO",
  "Employer_City",
  "Employer_EmailToNotify",
  "Employer_Name",
  "Employer_SBWCID",
  "Employer_SendEmailOnCreate",
  "Employer_SendEmailOnDeny",
  "Employer_SendEmailOnOpen",
  "Employer_State",
  "Employer_TAXID",
  "Employer_WC1ApprovalEmails",
  "Employer_Zip",
  "Excess_accountnumber",
  "Excess_Address",
  "Excess_Audited",
  "Excess_CarrierPolicyNo",
  "Excess_City",
  "Excess_CorridorDeductaible",
  "Excess_deductible",
  "Excess_DefaultSIR",
  "Excess_EndDate",
  "Excess_Name",
  "Excess_Phone",
  "Excess_premium",
  "Excess_StartDate",
  "Excess_State",
  "Excess_TreatyYear",
  "Excess_WebSite",
  "Excess_Zip",
  "InsuCo_accountnumber",
  "InsuCo_Address",
  "InsuCo_Address2",
  "InsuCo_City",
  "InsuCo_DefaultNatureOfBusiness",
  "InsuCo_fullname",
  "InsuCo_inscotaxid",
  "InsuCo_NCMFee",
  "InsuCo_NotifyOnNewClaims",
  "InsuCo_SBWCID",
  "InsuCo_shortname",
  "InsuCo_State",
  "InsuCo_WC1ApprovalEmails",
  "InsuCo_Zip",
  "Policy_deductible",
  "Policy_EndDate",
  "Policy_PolicyNumber",
  "Policy_premium",
  "Policy_StartDate",
  "Policy_TreatyYear",
]