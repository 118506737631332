import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form as RFFForm, Field } from 'react-final-form';
import ClaimantApi from '../../Api/ClaimantApi';
import { ClaimantDependent } from '../../ApiTypes/ClaimantDependent';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import ClaimantDependentsTable from './ClaimantDependentsTable';
import { toast } from 'react-toastify';

export default function DeathDependentsModal({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const { claimNumber } = useParams();
  const [dependents, setDependents] = useState<ClaimantDependent[]>([]);

  const getDependents = useCallback(() => {
    if (claimNumber) {
      ClaimantApi.getClaimantDependantsForClaim(claimNumber)
        .then((res) => {
          setDependents(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [claimNumber]);

  useEffect(() => {
    getDependents();
  }, [getDependents]);

  const relationshipOptions = [
    {
      label: 'Widow',
      value: '2',
    },
    {
      label: 'Widower',
      value: '3',
    },
    {
      label: 'Son/Daughter',
      value: '4',
    },
    {
      label: 'Brother/Sister',
      value: '5',
    },
    {
      label: 'Mother/Father',
      value: '6',
    },
    {
      label: 'Disabled Child',
      value: '7',
    },
    {
      label: 'Jurisdiction Fund/Estate',
      value: '8',
    },
    {
      label: 'Other',
      value: '9',
    },
  ];

  const onSubmit = (values: ClaimantDependent) => {
    console.log(values);
    values.id = 0;
    return createDependent(values);
  };

  const deleteDependent = (d: ClaimantDependent) => {
    return ClaimantApi.deleteClaimantDependantFromClaim(claimNumber!, d.id)
      .then((res) => {
        if (res.data.success) {
          getDependents();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to delete dependent');
      });
  };

  const createDependent = (d: ClaimantDependent) => {
    return ClaimantApi.createClaimantDependantForClaim(claimNumber!, d)
      .then((res) => {
        if (res.data.success) {
          getDependents();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create dependent');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => setShow(false)}
      dialogClassName=''
      aria-labelledby='Employer-Add-Edit-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Employer-Add-Edit-Form-modal'
        >
          <FaEdit className='pe-1' /> Employer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={{
              claimNo: claimNumber!,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='relationshipCode'
                    label='Relationship'
                    options={relationshipOptions}
                    optionMethod={(
                      options: { label: string; value: string }[]
                    ) =>
                      options.map((o) => (
                        <option key={o.value} value={o.value}>
                          {o.label}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='numberOrder'
                    label='Number'
                    type='number'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <div className='d-flex justify-content-center align-items-center my-3'>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
          <ClaimantDependentsTable
            show={show}
            setShow={setShow}
            dependents={dependents}
            getDependents={getDependents}
            deleteDependent={deleteDependent}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
