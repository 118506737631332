import React, { useEffect, useState } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaPlusCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import LegalApi from '../../Api/LegalApi';
import { Settlement } from '../../ApiTypes/Settlement';
import { SettlementPaymentDetail } from '../../ApiTypes/SettlementPaymentDetail';
import { SettlementType } from '../../ApiTypes/SettlementType';
import { useAppSelector } from '../../Reducers/Store';
import { displayDateOnly, parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import SettlementPaymentDetailTable from '../Tabs/Legal/SettlementPaymentDetailTable';
import styles from './index.module.css';
import SettlementPaymentDetailAddEdit from './SettlementPaymentDetailsAddEdit';

export default function ViewSettlementModal({
  show,
  setShow,
  claimNumber,
  claimantName,
  settlementId,
  refetch,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  claimNumber: string | null;
  claimantName: string | null;
  settlementId: number | null;
  refetch: () => void;
}) {
  const { settlementTypes } = useAppSelector((state) => state.reference);

  const { userModel } = useAppSelector((state) => state.user);

  const [settlement, setSettlement] = useState<Settlement | null>(null);
  const [selectedDetail, setSelectedDetail] =
    useState<SettlementPaymentDetail | null>(null);
  const [showAddEditDetails, setShowAddEditDetails] = useState<boolean>(false);

  useEffect(() => {
    getSettlementById();
  }, [claimNumber, settlementId]);

  const getSettlementById = () => {
    if (claimNumber && settlementId) {
      LegalApi.getSettlementById(claimNumber, settlementId)
        .then((res) => {
          setSettlement(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setSettlement(null);
    }
  };

  const onSubmit = (values: Settlement) => {
    const copy = { ...values };
    copy.creator = userModel?.user?.userId ?? '';
    copy.settlementPaymentDetails = [];
    copy.settlementType = null;
    return updateSettlement(copy);
  };
  const updateSettlement = (v: Settlement) => {
    return LegalApi.updateSettlement(v, claimNumber!)
      .then((res) => {
        if (res.data.success) {
          getSettlementById();
          refetch();
          setShow(false);
          toast.success('Settlement updated!');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update settlement');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='ViewSettlementModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='ViewSettlementModal-modal'
        >
          <FaPlusCircle className='pe-1' /> Add Settlement Info
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <div className={`${styles.addEditSettlementTopDetails}`}>
            <p>
              Date:{' '}
              {settlement?.dateCreated
                ? displayDateOnly(settlement.dateCreated)
                : displayDateOnly(new Date())}
            </p>
            <p>Claim No: {claimNumber ?? ''}</p>
            <p>Claimant: {claimantName ?? ''}</p>
          </div>
          <RFForm
            onSubmit={onSubmit}
            initialValues={settlement ?? {}}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className={`${styles.formRow}`}>
                    <Field
                      name='settlementTypeId'
                      label='Settlement Type'
                      options={settlementTypes}
                      optionMethod={(options: SettlementType[]) =>
                        options.map((o) => (
                          <option
                            key={o.settlementTypeId}
                            value={o.settlementTypeId}
                          >
                            {o.name}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                      validate={requiredField}
                    />
                    <Field
                      name='dateOfSettlement'
                      label='SBWC Award Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                      validate={requiredField}
                    />
                    <Field
                      name='ismsa'
                      label='Is MSA'
                      type='checkbox'
                      checked={!!values.ismsa}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='dateMsaapproved'
                      label='Date MSA Approved'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                  </div>
                  <div className={`${styles.maxWidth700}`}>
                    <Field
                      name='notes'
                      label='Notes'
                      rows={3}
                      component={FieldBSRenderTextArea}
                    />
                  </div>
                  <div className={`${styles.maxWidth700} py-3`}>
                    <Button
                      type='submit'
                      variant='primary'
                      size='sm'
                      className='m-auto d-block'
                    >
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
          <div className='mb-3'>
            <Button
              type='button'
              variant='primary'
              size='sm'
              onClick={() => {
                if (settlement == null || !settlement.settlementId) {
                  toast.error(
                    'Please save a settlement before adding payment details'
                  );
                } else {
                  setSelectedDetail(null);
                  setShowAddEditDetails(true);
                }
              }}
              className='button-icon-text m-auto'
            >
              <FaPlusCircle /> Payment Details
            </Button>
          </div>
          <SettlementPaymentDetailTable
            details={settlement?.settlementPaymentDetails ?? []}
            setSelectedDetail={setSelectedDetail}
            setShowAddEditDetails={setShowAddEditDetails}
          />
          <SettlementPaymentDetailAddEdit
            show={showAddEditDetails}
            setShow={setShowAddEditDetails}
            details={selectedDetail}
            getSettlementById={getSettlementById}
            claimNumber={claimNumber}
            settlementId={settlementId}
            refetch={refetch}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
