import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { Form as RFFForm, Field } from 'react-final-form';
import { Button, Form } from 'react-bootstrap';
import FieldBSRenderTextHorizontal from '../../Common/FieldBSRenderTextHorizontal';
import FieldBSRenderDateHorizontal from '../../Common/FieldBSRenderDateHorizontal';
import FieldBSRenderSelectHorizontal from '../../Common/FieldBSRenderSelectHorizontal';
import FieldBSRenderCheckbox from '../../Common/FieldBSRenderCheckbox';
import styles from '../index.module.css';
import ClaimSearchTable from './ClaimSearchTable';
import {
  ClaimSearchRequest,
  SearchClaimStatus,
  SearchSeverity,
} from '../../../ApiTypes/ClaimSearchRequest';
import { ClaimSearchResult } from '../../../ApiTypes/ClaimSearchResult';
import { Adjuster } from '../../../ApiTypes/Adjuster';
import { EntityUser } from '../../../ApiTypes/EntityUser';
import { parseDatesForServer } from '../../../Utils';
import { InsuranceCompany } from '../../../ApiTypes/InsuranceCompany';
import { formatSSN, removeNonDigits } from '../../../Utils/InputFormatters';
import { ClaimType } from '../../../ApiTypes/ClaimType';
import { UserModel } from '../../../ApiTypes/UserModel';

export default function ClaimSearchModal({
  show,
  setShow,
  search,
  adjusters,
  insuranceCompanies,
  supervisors,
  searchResults,
  setSearchResults,
  handleSelectedClaim,
  user,
  claimTypes,
}: {
  show: boolean;
  insuranceCompanies: InsuranceCompany[];
  adjusters: Adjuster[];
  supervisors: EntityUser[];
  searchResults: ClaimSearchResult[];
  handleSelectedClaim?: (result: ClaimSearchResult) => void;
  setSearchResults: (results: ClaimSearchResult[]) => void;
  setShow: (show: boolean) => void;
  search: (values: ClaimSearchRequest) => void;
  user: UserModel | null;
  claimTypes: ClaimType[];
}) {
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => setShow(false)}
      dialogClassName={styles.largeModal}
      aria-labelledby='Claim-search-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Claim-search-modal'>
          <FaSearch className='pe-1' /> Claim Search
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <RFFForm
            onSubmit={search}
            initialValues={{
              severity: SearchSeverity.Any,
              claimStatus: SearchClaimStatus.ShowAll,
            }}
            render={({ handleSubmit, form, values, pristine, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className='d-flex justify-content-around'>
                    <div>
                      <Field
                        name='firstName'
                        type='text'
                        label='First Name'
                        component={FieldBSRenderTextHorizontal}
                      />
                      <Field
                        name='lastName'
                        type='text'
                        label='Last Name'
                        component={FieldBSRenderTextHorizontal}
                      />
                      <Field
                        name='claimNumber'
                        type='text'
                        label='Claim #'
                        component={FieldBSRenderTextHorizontal}
                      />
                      <Field
                        name='affiliateClaimNo'
                        type='text'
                        label='Net Claim #'
                        component={FieldBSRenderTextHorizontal}
                      />
                      <Field
                        name='ssn'
                        type='text'
                        label='SSN'
                        parse={removeNonDigits}
                        format={formatSSN}
                        component={FieldBSRenderTextHorizontal}
                      />
                    </div>
                    <div>
                      <Field
                        name='accountNumber'
                        label='Account'
                        options={[
                          ...insuranceCompanies
                            .map((x) => x)
                            .sort(
                              (a, b) => +a.accountnumber! - +b.accountnumber!
                            ),
                        ]}
                        optionMethod={(options: InsuranceCompany[]) =>
                          options.map((o) => (
                            <option
                              key={o.accountnumber!}
                              value={o.accountnumber!}
                            >
                              {`${o.accountnumber} - ${o.shortname}`}
                            </option>
                          ))
                        }
                        component={FieldBSRenderSelectHorizontal}
                      />
                      <Field
                        name='employer'
                        type='text'
                        label='Employer'
                        component={FieldBSRenderTextHorizontal}
                      />
                      <div className='d-flex'>
                        <Field
                          name='injuryFromDate'
                          type='text'
                          label='Date Range'
                          parse={parseDatesForServer}
                          component={FieldBSRenderDateHorizontal}
                        />
                        <div className={styles.width2rem}></div>
                        <Field
                          name='injuryToDate'
                          type='text'
                          label='to'
                          parse={parseDatesForServer}
                          component={FieldBSRenderDateHorizontal}
                        />
                      </div>
                      <div className='d-flex'>
                        <Field
                          name='adjusterId'
                          label='Adjuster'
                          options={adjusters}
                          optionMethod={(options: Adjuster[]) =>
                            options.map((o) => (
                              <option key={o.adjusterId} value={o.adjusterId}>
                                {o.firstName + ' ' + o.lastName}
                              </option>
                            ))
                          }
                          component={FieldBSRenderSelectHorizontal}
                        />
                        <div className={styles.width2rem}></div>
                        <Field
                          name='supervisorId'
                          label='Supervisor'
                          options={supervisors}
                          optionMethod={(options: EntityUser[]) =>
                            options.map((o) => (
                              <option key={o.userId} value={o.userId}>
                                {o.userName}
                              </option>
                            ))
                          }
                          component={FieldBSRenderSelectHorizontal}
                        />
                      </div>
                    </div>
                    <div className='d-flex flex-column'>
                      <div>
                        <Field
                          name='claimTypeId'
                          label='Claim Type'
                          options={claimTypes
                            .filter((c) =>
                              user?.userClaimTypeAccesses?.find(
                                (v) => v.claimTypeId === c.id
                              )
                            )
                            .sort()}
                          optionMethod={(options: ClaimType[]) =>
                            options.map((o) => (
                              <option key={o.name} value={o.id}>
                                {o.name}
                              </option>
                            ))
                          }
                          component={FieldBSRenderSelectHorizontal}
                        />
                      </div>
                      <div className='d-flex justify-content-around'>
                        <div className='px-2'>
                          <Field
                            name='severity'
                            type='radio'
                            label='Any'
                            value={SearchSeverity.Any}
                            parse={(value: string, name: string) => +value}
                            checked={values.severity === SearchSeverity.Any}
                            component={FieldBSRenderCheckbox}
                          />
                          <Field
                            name='severity'
                            type='radio'
                            label='LT'
                            parse={(value: string, name: string) => +value}
                            value={SearchSeverity.LT}
                            checked={values.severity === SearchSeverity.LT}
                            component={FieldBSRenderCheckbox}
                          />
                          <Field
                            name='severity'
                            type='radio'
                            label='MO'
                            parse={(value: string, name: string) => +value}
                            value={SearchSeverity.MO}
                            checked={values.severity === SearchSeverity.MO}
                            component={FieldBSRenderCheckbox}
                          />
                          <Field
                            name='severity'
                            type='radio'
                            label='AM'
                            parse={(value: string, name: string) => +value}
                            value={SearchSeverity.AM}
                            checked={values.severity === SearchSeverity.AM}
                            component={FieldBSRenderCheckbox}
                          />
                          <Field
                            name='severity'
                            type='radio'
                            label='MO/AM'
                            parse={(value: string, name: string) => +value}
                            value={SearchSeverity.MO_AM}
                            checked={values.severity === SearchSeverity.MO_AM}
                            component={FieldBSRenderCheckbox}
                          />
                        </div>
                        <div>
                          <Field
                            name='claimStatus'
                            type='radio'
                            label='Any'
                            parse={(value: string, name: string) => +value}
                            value={SearchClaimStatus.ShowAll}
                            checked={
                              values.claimStatus === SearchClaimStatus.ShowAll
                            }
                            component={FieldBSRenderCheckbox}
                          />
                          <Field
                            name='claimStatus'
                            type='radio'
                            label='Open/Re-Open'
                            parse={(value: string, name: string) => +value}
                            value={SearchClaimStatus.ShowOpenReopenOnly}
                            checked={
                              values.claimStatus ===
                              SearchClaimStatus.ShowOpenReopenOnly
                            }
                            component={FieldBSRenderCheckbox}
                          />
                          <Field
                            name='claimStatus'
                            type='radio'
                            label='Closed'
                            parse={(value: string, name: string) => +value}
                            value={SearchClaimStatus.ShowClosedOnly}
                            checked={
                              values.claimStatus ===
                              SearchClaimStatus.ShowClosedOnly
                            }
                            component={FieldBSRenderCheckbox}
                          />
                          <div className='d-flex align-items-center mt-3'>
                            <Field
                              name='viewAllRecords'
                              type='checkbox'
                              label='View All'
                              defaultChecked={values?.viewAllRecords}
                              component={FieldBSRenderCheckbox}
                            />
                            {/* number of returned claims */}
                            <h2 className='px-2 m-0'>
                              {
                                searchResults.filter((d) =>
                                  user?.userClaimTypeAccesses?.find(
                                    (v) => v.claimTypeId === d.claimTypeId
                                  )
                                ).length
                              }
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-around mt-2'>
                        <Button
                          type='submit'
                          variant='secondary'
                          disabled={submitting}
                        >
                          {submitting ? (
                            <Spinner
                              as='span'
                              animation='grow'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                            />
                          ) : (
                            'Search'
                          )}
                        </Button>
                        <Button
                          type='button'
                          onClick={() => {
                            form.reset();
                            setSearchResults([]);
                          }}
                          className='ms-2'
                          variant='secondary'
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          />
          <ClaimSearchTable
            data={searchResults}
            getSelectedClaim={handleSelectedClaim}
            setShow={setShow}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
