import React, { useState, useEffect } from 'react';
import { Form as RFForm, Field } from 'react-final-form';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { FaCheck, FaUser } from 'react-icons/fa';
import styles from './index.module.css';
import { UserClaimTypeAccess } from '../../ApiTypes/UserClaimTypeAccess';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../Reducers/Store';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import UserClaimTypeAccessApi from '../../Api/UserClaimTypeAccessApi';
import { ClaimType } from '../../ApiTypes/ClaimType';

export default function AddEditClaimTypeAccessModal({
  show,
  setShow,
  id,
  userId,
  userName,
  handleSubmit,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  id: number | null;
  userId: string;
  userName: string;
  handleSubmit: (claimTypeAccess: UserClaimTypeAccess) => Promise<void>;
}) {
  const { claimTypes } = useAppSelector((state) => state.reference);
  const [access, setAccess] = useState<UserClaimTypeAccess | null>(null);

  useEffect(() => {
    getAccess();
  }, [id]);

  const getAccess = () => {
    if (id) {
      UserClaimTypeAccessApi.getUserClaimTypeAccessById(id)
        .then((res) => {
          setAccess(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get claim type access');
        });
    } else {
      setAccess(null);
    }
  };

  const onSubmit = (values: UserClaimTypeAccess) => {
    values.userId = userId;
    if (id) {
      values.id = id;
    }
    return handleSubmit(values);
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='AddEditClaimTypeAccessModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='AddEditClaimTypeAccessModal-modal'
        >
          <FaUser className='pe-1' /> User Access
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p className='fw-bold text-center'>{`${
            id ? 'Edit' : 'Add'
          } Claim Type Access for User ID:  ${userId} - ${userName}`}</p>
          <p>current: {access?.claimType?.name ?? ''}</p>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              claimTypeId: access?.claimTypeId,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='claimTypeId'
                    label='Claim Type'
                    options={claimTypes}
                    optionMethod={(options: ClaimType[]) =>
                      options.map((o) => (
                        <option key={o.id} value={o.id ?? ''}>
                          {`${o.name}`}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <div className='d-flex justify-content-center align-items-center gap1Rem py-3'>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => setShow(false)}
                    >
                      Cancel
                    </Button>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : access ? (
                        'Update'
                      ) : (
                        'Add'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
