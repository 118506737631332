export interface EdiStatusItem {
  ediStatus: number;
  ediStatusDesc: string;
}

export enum EDIStatus 
{
    New = 0,
    FROISubmitted = 1,
    SROISubmitted = 2,
    Canceled = 3,
    FROIReSubmitted = 4,
    SROIReSubmitted = 5,
    Errors = 9,
    AcknowledgementReceivedSuccess = 100,
    RequiredFilesMissing = 6
}