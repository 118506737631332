import React, { useState, useMemo } from 'react';
import { displayDateOnly } from '../../Utils';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { ClaimSearchResult } from '../../ApiTypes/ClaimSearchResult';

export default function LinkClaimSearchTable({
  data,
  getSelectedClaim,
}: {
  data: ClaimSearchResult[];
  getSelectedClaim: (claimSearchResult: ClaimSearchResult) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<ClaimSearchResult>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      size: 75,
      maxSize: 75,
      cell: (d) => {
        return (
          <Button
            type='button'
            size='sm'
            onClick={() => getSelectedClaim(d.row.original)}
          >
            Select
          </Button>
        );
      },
    },
    {
      header: 'Claim No',
      accessorFn: (d) => d.claimNo,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
    },
    {
      header: 'First Name',
      accessorFn: (d) => d.firstName,
      sortingFn: 'text',
      filterFn: 'includesString',
    },

    {
      header: 'Last Name',
      accessorFn: (d) => d.lastName,
      sortingFn: 'text',
      filterFn: 'includesString',
    },

    {
      header: 'Injury Date',
      accessorKey: 'injuryDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.injuryDate ?? ''),
    },
    {
      header: 'Status',
      accessorFn: (d) => d.status,
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Severity',
      accessorFn: (d) => d.severity,
      sortingFn: 'text',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
      />
    </div>
  );
}
