import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { RecurringPaymentWeeklyDetail } from '../../Types/RecurringPaymentWeeklyDetail';
import { displayDateOnly } from '../../Utils';
import accounting from 'accounting';

export default function RecurringPaymentWeeklyDetailsTable({
  details,
}: {
  details: RecurringPaymentWeeklyDetail[];
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const tableData = useMemo(() => details, [details]);

  const columnData: ColumnDef<RecurringPaymentWeeklyDetail>[] = [
    {
      header: 'Week',
      accessorKey: 'week',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 50,
    },
    {
      header: 'Start',
      accessorKey: 'start',
      enableSorting: false,
      enableColumnFilter: false,
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'End',
      accessorKey: 'end',
      enableSorting: false,
      enableColumnFilter: false,
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'Amount',
      accessorKey: 'amount',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 100,
      cell: (d) =>
        d.getValue() ? accounting.formatMoney(d.getValue() as string) : '',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      // columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    // onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={false}
    />
  );
}
