import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { Department } from '../ApiTypes/Department';
import { Employer } from '../ApiTypes/Employer';
import { EmployerPolicy } from '../ApiTypes/EmployerPolicy';
import { EmployerSearchRequest } from '../ApiTypes/EmployerSearchRequest';
import { Location } from '../ApiTypes/Location';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class EmployerApi {
  static getEmployerById(id: number) {
    return axios.get<Employer>(
      `${API_URL}/api/employers/${id}`,
      getAuthHeader()
    );
  }
  static getEmployerDepartments(id: number) {
    return axios.get<Department[]>(
      `${API_URL}/api/employers/${id}/departments`,
      getAuthHeader()
    );
  }
  static getEmployerPolicies(id: number) {
    return axios.get<EmployerPolicy[]>(
      `${API_URL}/api/employers/${id}/policies`,
      getAuthHeader()
    );
  }
  static getEmployerLocationsByPolicyId(employerId: number, policyId: number) {
    return axios.get<Location[]>(
      `${API_URL}/api/employers/${employerId}/policies/${policyId}/locations`,
      getAuthHeader()
    );
  }
  static getEmployerLocationsByEmployerId(employerId: number) {
    return axios.get<Location[]>(
      `${API_URL}/api/employers/${employerId}/locations`,
      getAuthHeader()
    );
  }
  static getLocationsByPolicyId(id: number) {
    return axios.get<Location[]>(
      `${API_URL}/api/employerpolicies/${id}/locations`,
      getAuthHeader()
    );
  }
  static getEmployerList(search: EmployerSearchRequest) {
    return axios.post<Employer[]>(
      `${API_URL}/api/employers/list`,
      search,
      getAuthHeader()
    );
  }
  static createEmployer(employer: Employer) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/employers`,
      employer,
      getAuthHeader()
    );
  }
  static updateEmployer(employer: Employer) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/employers/${employer.employerId}`,
      employer,
      getAuthHeader()
    );
  }

  static createEmployerPolicy(policy: EmployerPolicy) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/employerpolicies`,
      policy,
      getAuthHeader()
    );
  }
  static getEmployerPolicyByTreatyYear(id: number, injuryDate: string) {
    return axios.get<EmployerPolicy>(
      `${API_URL}/api/employers/${id}/policies/treatyyear?injuryDate=${injuryDate}`,
      getAuthHeader()
    );
  }
}

export default EmployerApi;
