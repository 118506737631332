import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { FaColumns, FaPlusCircle } from 'react-icons/fa';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { toast } from 'react-toastify';
import { Button, Spinner } from 'react-bootstrap';
import BSControlledSelect from '../Common/BSControlledSelect';
import { useAppSelector } from '../../Reducers/Store';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import PanelApi from '../../Api/PanelApi';
import { Panel } from '../../ApiTypes/Panel';
import PanelListTable from './PanelListTable';
import { useLocation, useNavigate } from 'react-router-dom';
import CreatePanelModal from './CreatePanelModal';

export default function PanelList() {
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );
  const [panels, setPanels] = useState<Panel[]>([]);

  const [selectedInsurCoId, setSelectedInsurCoId] = useState<number>(0);
  const [fetching, setFetching] = useState<boolean>(false);
  const [showAddPanel, setShowAddPanel] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    onLocationChange();
  }, [location]);

  const onLocationChange = () => {
    if (location.search) {
      const { insurCoId } = queryString.parse(location.search);
      setSelectedInsurCoId(insurCoId ? +insurCoId : 0);
      if (insurCoId && +insurCoId > 0) {
        getPanels(+insurCoId);
      } else {
        setPanels([]);
      }
    } else {
      setSelectedInsurCoId(0);
      setPanels([]);
    }
  };

  const onInsChange = (insurCoId: number) => {
    if (insurCoId && insurCoId > 0) {
      navigate(`/panels?insurCoId=${insurCoId}`);
    } else {
      navigate(`/panels`);
    }
  };

  const getPanels = (insurCoId: number) => {
    setFetching(true);
    PanelApi.getPanelList(insurCoId)
      .then((res) => {
        setPanels(res.data);
        setFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setFetching(false);
        toast.error('Failed to get panels ');
      });
  };

  const handleAddPanel = () => {
    if (selectedInsurCoId) {
      setShowAddPanel(true);
    }
  };

  const createPanel = (values: Panel) => {
    return PanelApi.createPanel(values)
      .then((res) => {
        if (res.data.success) {
          navigate(`/panels/${res.data.affectedEntityIdentifier}`);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create panel');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaColumns className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Panel List</h1>
          </div>
          <div className='ms-3'></div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center gap1Rem pb-3'>
        <BSControlledSelect
          name='insurCoId'
          label='Account'
          options={insuranceCompaniesForUser}
          value={selectedInsurCoId}
          onChange={onInsChange}
          optionMethod={(options: InsuranceCompany[]) =>
            options.map((o) => (
              <option key={o.insurcoid} value={o.insurcoid}>
                {`${o.accountnumber} - ${o.shortname}`}
              </option>
            ))
          }
        />
        <Button
          type='button'
          variant='primary'
          size='sm'
          className='button-icon-text'
          onClick={() => getPanels(selectedInsurCoId)}
        >
          {fetching ? (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            'Refresh'
          )}
        </Button>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          className='button-icon-text'
          disabled={!selectedInsurCoId}
          onClick={handleAddPanel}
        >
          <FaPlusCircle /> Panel
        </Button>
      </div>
      <PanelListTable data={panels} />
      <CreatePanelModal
        show={showAddPanel}
        setShow={setShowAddPanel}
        insurCoId={selectedInsurCoId}
        createPanel={createPanel}
      />
    </PageScaffold>
  );
}
