import { differenceInDays, endOfDay, isBefore } from 'date-fns';
import React, { useState, useEffect } from 'react';
import {
  Container,
  Modal,
  Button,
  Form,
  Accordion,
  Alert,
  Spinner,
} from 'react-bootstrap';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import { Field, Form as RFFForm } from 'react-final-form';
import { toast } from 'react-toastify';
import ProviderApi from '../../Api/ProviderApi';
import { Address } from '../../ApiTypes/Address';
import { PayCodeItem } from '../../ApiTypes/PayCodeItem';
import { ProviderSearchResult } from '../../ApiTypes/ProviderSearchResult';
import { RecurringPayment } from '../../ApiTypes/RecurringPayment';
import { useAppSelector } from '../../Reducers/Store';
import { parseDatesForServer, setAddressTextString } from '../../Utils';
import {
  centsValidation,
  composeValidators,
  requiredField,
} from '../../Utils/FieldValidation';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import ProviderLookUpForm from '../ProviderLookup/ProviderLookUpForm';

import styles from './index.module.css';
import RecurringPaymentsApi from '../../Api/RecurringPaymentsApi';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';
import { formatNumbers, cleanMoney } from '../../Utils/InputFormatters';

export default function RecurringPaymentDetail({
  show,
  setShow,
  selectedRP,
  setSelectedRP,
  getRecurringPaymentsForClaim,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  selectedRP: RecurringPayment | null;
  setSelectedRP: (recurringPayment: RecurringPayment | null) => void;
  getRecurringPaymentsForClaim: () => void;
}) {
  const { payCodes } = useAppSelector((state) => state.reference);
  const { claim, claimant } = useAppSelector(
    (state) => state.currentClaimReducer
  );

  const recurringPayCodePayCodes = ['10', '11', '13', '14', '16', '26'];

  const [provider, setProvider] = useState<ProviderSearchResult | null>(null);
  const [claimantAddress, setClaimantAddress] = useState<string>('');
  const [providerAddress, setProviderAddress] = useState<string>('');
  const [payeeName, setPayeeName] = useState<string>('');
  const [activeKey, setActiveKey] = useState<AccordionEventKey>('');
  const [show7DayAlert, setShow7DayAlert] = useState<boolean>(false);

  useEffect(() => {
    getProvider();
    getClaimantAddress();
  }, [selectedRP, claimant]);

  let formValues: RecurringPayment;

  const onSubmit = (values: RecurringPayment) => {
    if (values.recurringPmtId) {
      return update(values);
    }
    return save(values);
  };

  const save = (values: RecurringPayment) => {
    if (values.payee === 'P' && provider) {
      values.providerId = provider.providerId;
    }
    return RecurringPaymentsApi.saveNewRecurringPayment(values)
      .then((res) => {
        if (res.data.success) {
          getRecurringPaymentsForClaim();
          cleanUp();
          setShow(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to save');
      });
  };

  const update = (values: RecurringPayment) => {
    if (values.payee === 'P' && provider) {
      values.providerId = provider.providerId;
    }
    return RecurringPaymentsApi.updateRecurringPayment(values)
      .then((res) => {
        if (res.data.success) {
          getRecurringPaymentsForClaim();
          cleanUp();
          setShow(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update');
      });
  };

  const handlePayeeChange = (value: string) => {
    if (value === 'C') {
      getClaimantAddress();
      setPayeeName(`${claimant?.firstName} ${claimant?.lastName}`);
    }
    if (value === 'P') {
      getProviderAddress(provider ?? undefined);
      if (provider) {
        setPayeeName(provider.companyName);
      }
      setPayeeName('');
    }
  };

  const getProvider = () => {
    if (
      selectedRP &&
      selectedRP.providerId &&
      selectedRP.payee.toUpperCase() === 'P'
    ) {
      ProviderApi.getProviderById(selectedRP?.providerId)
        .then((res) => {
          const p = res.data.find(
            (p) => p.providerId === selectedRP.providerId
          );
          if (!p) {
            toast.error('Failed to get Provider');
          }
          setProvider(p ?? null);
          setPayeeName(p?.companyName ?? '');
          getProviderAddress(p);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getClaimantAddress = () => {
    let address: Address | null = null;
    if (claimant && claimant.usePaymentAddress) {
      address = claimant?.paymentAddress ?? null;
    } else {
      address = claimant?.mainAddress ?? null;
    }
    setAddressTextString(address, setClaimantAddress);
  };

  const getProviderAddress = (provider?: ProviderSearchResult) => {
    let address: Address | null = null;
    if (provider) {
      const { address1, address2, city, state, zip } = provider;
      address = {
        address1,
        address2,
        city,
        state,
        zip,
        addressId: 0,
        addressName: '',
        ownerId: 0,
        ownerTable: '',
      };

      setAddressTextString(address, setProviderAddress);
    } else {
      setProviderAddress('');
    }
  };

  const handleSelectedProvider = (provider: ProviderSearchResult) => {
    setProvider(provider);
    getProviderAddress(provider);
    setPayeeName(provider.companyName);
    setActiveKey('');
  };

  const handleServiceToOnChange = (value: string) => {
    if (formValues.nextServiceFromDate && value) {
      if (
        differenceInDays(
          new Date(value),
          new Date(formValues.nextServiceFromDate)
        ) > 7
      ) {
        setShow7DayAlert(true);
      } else {
        setShow7DayAlert(false);
      }
    }
  };

  const cleanUp = () => {
    setSelectedRP(null);
    setProvider(null);
    setPayeeName('');
    setProviderAddress('');
    setClaimantAddress('');
    setActiveKey('');
    setShow7DayAlert(false);
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
        cleanUp();
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='Recurring-payment-detail-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Recurring-payment-detail-modal'
        >
          Recurring Payment Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid className='w-100 d-flex'>
          <div className='w-50 pe-2'>
            <RFFForm
              onSubmit={onSubmit}
              initialValues={
                selectedRP ??
                ({
                  claimNo: claim?.claimNo,
                  payCode: '10',
                  active: true,
                  frequency: 'W',
                } as Partial<RecurringPayment>)
              }
              validate={(values) => {
                const errors: {
                  [Property in keyof RecurringPayment]?: string;
                } = {};

                if (!values?.startDate) {
                  errors.startDate = 'Required';
                }
                if (
                  values?.startDate &&
                  isBefore(
                    new Date(values.startDate),
                    new Date(claim?.injuryDate ?? '')
                  )
                ) {
                  errors.startDate =
                    'The starting date cannot be before the date of injury.';
                }

                if (!values?.endDate) {
                  errors.endDate = 'Required';
                }
                if (
                  values?.endDate &&
                  values?.startDate &&
                  isBefore(new Date(values.endDate), new Date(values.startDate))
                ) {
                  errors.startDate =
                    'The starting date cannot be before the end date.';
                }

                if (!values?.nextPrintDate) {
                  errors.nextPrintDate = 'Required';
                }
                if (
                  values?.nextPrintDate &&
                  isBefore(new Date(values.nextPrintDate), new Date())
                ) {
                  errors.nextPrintDate =
                    'Next print date cannot be prior to today.';
                }

                if (!values.nextServiceFromDate) {
                  errors.nextServiceFromDate = 'Required';
                }
                if (!values.nextServiceToDate) {
                  errors.nextServiceToDate = 'Required';
                }

                return errors;
              }}
              render={({ handleSubmit, form, values, submitting }) => {
                const payeeFieldState = form.getFieldState(
                  'payee' as keyof RecurringPayment
                )!;
                formValues = values;
                return (
                  <Form onSubmit={handleSubmit}>
                    <div className={styles.flex}>
                      <Field
                        name='active'
                        label='Active'
                        type='checkbox'
                        checked={!!values?.active}
                        component={FieldBSRenderCheckbox}
                      />
                      <fieldset className={`${styles.height80px} py-0`}>
                        <div
                          style={
                            payeeFieldState?.submitFailed &&
                            payeeFieldState?.invalid
                              ? {
                                  border: '1px solid red',
                                  borderRadius: '.25rem',
                                }
                              : {}
                          }
                        >
                          <legend className='col-form-label col-sm-2 py-0'>
                            Payee
                          </legend>
                          <Field
                            name='payee'
                            type='radio'
                            label='Claimant'
                            value='C'
                            checked={values?.payee?.toUpperCase() === 'C'}
                            onCheckChange={handlePayeeChange}
                            component={FieldBSRenderCheckbox}
                          />
                          <Field
                            name='payee'
                            type='radio'
                            label='Provider'
                            value='P'
                            checked={values?.payee?.toUpperCase() === 'P'}
                            onCheckChange={handlePayeeChange}
                            component={FieldBSRenderCheckbox}
                          />
                        </div>
                      </fieldset>
                      <Field
                        name='payCode'
                        label='Pay Code'
                        options={payCodes.filter((x) =>
                          recurringPayCodePayCodes.includes(x.payCode)
                        )}
                        optionMethod={(options: PayCodeItem[]) =>
                          options.map((o) => (
                            <option key={o.payCode} value={o.payCode}>
                              {o.description}
                            </option>
                          ))
                        }
                        component={FieldBSRenderSelect}
                      />
                    </div>
                    <div className={`position-relative ${styles.vFieldHeight}`}>
                      <label
                        htmlFor='payeeName'
                        className='form-label fs-6  m-0'
                      >
                        Payee
                      </label>
                      <input
                        type='text'
                        name='payeeName'
                        id='payeeName'
                        value={payeeName}
                        className='form-control form-control-sm'
                        placeholder='Payee'
                        onFocus={() => {
                          if (values?.payee?.toUpperCase() === 'P') {
                            setActiveKey('0');
                          }
                        }}
                        readOnly
                        disabled={values?.payee?.toUpperCase() === 'C'}
                      />
                    </div>
                    <div className={styles.height160px}>
                      <div className={`position-relative mb-3`}>
                        <label
                          htmlFor='mailingAddress'
                          className='form-label fs-6  m-0'
                        >
                          Mailing Address
                        </label>
                        <div>
                          <textarea
                            name='mailingAddress'
                            id='mailingAddress'
                            rows={5}
                            value={
                              values?.payee?.toUpperCase() === 'C'
                                ? claimantAddress
                                : values?.payee?.toUpperCase() === 'P'
                                ? providerAddress
                                : ''
                            }
                            className='form-control form-control-sm'
                            placeholder='Mailing Address'
                            disabled={true}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.grid}`}>
                      <Field
                        name='amount'
                        label='Amount'
                        type='text'
                        format={formatNumbers}
                        parse={cleanMoney}
                        validate={composeValidators(
                          centsValidation,
                          requiredField
                        )}
                        component={FieldBSRenderMoney}
                      />
                      <Field
                        name='startDate'
                        label='Start Date'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                        startOfDay={true}
                      />
                      <Field
                        name='endDate'
                        label='End Date'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                        endOfDay={true}
                      />
                      <Field
                        name='frequency'
                        label='Frequency'
                        options={[
                          'Weekly',
                          'Bi - Weekly',
                          'Monthly',
                          'One - Time Payment',
                        ]}
                        optionMethod={(options: string[]) =>
                          options.map((o) => (
                            <option key={o} value={o.substring(0, 1)}>
                              {o}
                            </option>
                          ))
                        }
                        component={FieldBSRenderSelect}
                      />
                      <Field
                        name='nextServiceFromDate'
                        label='Next Svc From'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                      <Field
                        name='nextServiceToDate'
                        label='Next Scv To'
                        parse={parseDatesForServer}
                        onBlur={handleServiceToOnChange}
                        onChange={handleServiceToOnChange}
                        component={FieldBSRenderDate}
                      />
                    </div>
                    <Alert variant='info' show={show7DayAlert}>
                      <p>
                        The service dates seem to be longer than one week, is
                        this is what you intended.
                      </p>
                    </Alert>
                    <Field
                      name='nextPrintDate'
                      label='Next Print Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                    <Field
                      name='comments'
                      label='Comments'
                      rows={3}
                      component={FieldBSRenderTextArea}
                    />
                    <div className={`${styles.grid}`}>
                      <Field
                        name='waitingPeriodStart'
                        label='Waiting Period Start'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                      <Field
                        name='waitingPeriodEnd'
                        label='Waiting Period End'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                      <Field
                        name='waitingPeriodToBePaidOn'
                        label='Waiting Period To Be Paid On'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                    </div>
                    <div className={styles.flexButtons}>
                      <Button type='submit' variant='primary' size='sm'>
                        {submitting ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          'Save'
                        )}
                      </Button>
                      <Button
                        type='button'
                        size='sm'
                        variant='secondary'
                        onClick={() => {
                          setShow(false);
                          cleanUp();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                );
              }}
            />
          </div>
          <div className='w-50 ps-2'>
            <Accordion
              onSelect={(eventKey: AccordionEventKey) => setActiveKey(eventKey)}
              activeKey={activeKey}
              className='mt-3'
            >
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Provider Look Up</Accordion.Header>
                <Accordion.Body>
                  <ProviderLookUpForm
                    selectProvider={handleSelectedProvider}
                    selectedPayment={null}
                    disabled={false}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
