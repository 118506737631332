import React, { useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  RowSelectionState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { Wc1 } from '../../../ApiTypes/Wc1';
import { FaBan, FaCheckSquare, FaEdit, FaEye } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { displayDateOnly } from '../../../Utils';
import { useAppSelector } from '../../../Reducers/Store';
import IndeterminateCheckbox from '../../Common/IndeterminateCheckbox';

export default function Wc1Table({
  data,
  rowSelection,
  setRowSelection,
  setSelectedWc1,
}: {
  data: Wc1[];
  rowSelection: RowSelectionState;
  setRowSelection: (obj: object) => void;
  setSelectedWc1: (w: Wc1 | null) => void;
}) {
  const { insuranceCompanies } = useAppSelector((state) => state.reference);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  const handleRowSelectionChange = () => {
    setSelectedWc1(
      table.getSelectedRowModel().flatRows.map((r) => r.original)[0]
    );
  };

  const columnData: ColumnDef<Wc1>[] = [
    {
      header: ({ table }) => (
        <div className='px-3 d-flex gap1Rem'>
          {Object.keys(rowSelection).length}
        </div>
      ),
      id: 'options',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 50,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center'>
            <IndeterminateCheckbox
              className='form-check-input'
              {...{
                id: row.id,
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        );
      },
    },
    {
      header: 'Ins Co',
      accessorFn: (d) =>
        insuranceCompanies.find((x) => x.insurcoid === d.insCoId)?.shortname ??
        'N/A',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claim No',
      accessorKey: 'claimNo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date of Injury',
      accessorKey: 'dateOfInjury',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Employer Name',
      accessorKey: 'employerName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Requestor',
      accessorKey: 'requestor',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Emergency?',
      accessorFn: (d) => (d.isEmergency === true ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 100,
    },
    {
      header: 'Claimant First Name',
      accessorKey: 'claimantFirstName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claimant Last Name',
      accessorKey: 'claimantLastName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Gender',
      accessorKey: 'claimantGender',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 100,
    },
    {
      header: 'Claimant State',
      accessorKey: 'claimantState',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claimant Birthday',
      accessorKey: 'claimantBirthday',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Body Part Affected',
      accessorKey: 'bodyPartAffected',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Comments',
      accessorKey: 'comments',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, [insuranceCompanies, rowSelection]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
