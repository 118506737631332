export enum EDIFileType
{
    FROI_00 = 3,
    SROI_IP = 9,
    SROI_CB = 11,
    SROI_04 = 13,
    FROI_01 = 14,
    FROI_04 = 17,
    SROI_AN = 18,
    SROI_FN = 20,
    SROI_S1 = 23,
    SROI_S7 = 25,
    SROI_RB = 28,
    SROI_S2 = 32,
    SROI_02 = 30,
    SROI_PY = 42,
    SROI_PD = 33,
    SROI_EP = 46,
    SROI_S3 = 48,
    SROI_S4 = 50,
    FROI_02 = 52,
    SROI_ER = 54,
    FROI_AQ = 56,
    FROI_AU = 58,
    SROI_AP = 64
}