import React, { useState, useEffect } from 'react';
import { Button, Container, Modal, Spinner } from 'react-bootstrap';
import { FaThumbsUp } from 'react-icons/fa';
import styles from './index.module.css';
import BSRenderText from '../Common/BSRenderText';
import ApprovalCodeApi from '../../Api/ApprovalCodeApi';
import { toast } from 'react-toastify';

export default function ApprovalCodeModal({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const [approvalCode, setApprovalCode] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    getCode();
  }, []);

  const getCode = () => {
    ApprovalCodeApi.getCode()
      .then((res) => {
        if (res.data.success) {
          setApprovalCode(res.data.affectedEntityIdentifier);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get code');
      });
  };

  const generate = () => {
    setSubmitting(true);
    ApprovalCodeApi.updateCode()
      .then((res) => {
        if (res.data.success) {
          getCode();
        } else {
          toast.error(res.data.message);
        }
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
        toast.error('Failed to generate code');
      });
  };

  const copyCode = async () => {
    try {
      await navigator.clipboard.writeText(approvalCode);
      toast.success('Code Copied');
      setShow(false);
    } catch (error) {
      toast.error('Failed to copy code. ');
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='ApprovalCodeModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='ApprovalCodeModal-modal'>
          <FaThumbsUp className='pe-1' /> Approval Code
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p>Approval codes will automatically update after use</p>
          <BSRenderText
            name='approvalCode'
            label='Current Code'
            value={approvalCode}
            readOnly
            type='text'
          />
          <div className='d-flex justify-content-around align-items-center'>
            <Button
              type='button'
              variant='secondary'
              size='sm'
              onClick={() => setShow(false)}
            >
              Cancel
            </Button>
            <Button
              type='button'
              variant='outline-success'
              size='sm'
              onClick={copyCode}
            >
              Copy
            </Button>
            <Button
              type='button'
              size='sm'
              variant='primary'
              onClick={generate}
            >
              {submitting ? (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              ) : (
                'Generate New Code'
              )}
            </Button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
