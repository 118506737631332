import { addDays, format } from 'date-fns';
import React, { useEffect } from 'react';
import {
  Modal,
  Container,
  Form,
  Button,
  Spinner,
  Alert,
} from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaIdCard, FaInfoCircle } from 'react-icons/fa';
import { PharmacyCard } from '../../ApiTypes/PharmacyCard';
import { useAppSelector } from '../../Reducers/Store';
import { parseDatesForServer } from '../../Utils';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderFilePicker from '../Common/FieldBSRenderFilePicker';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import styles from './index.module.css';
import { PharmacyCardFormType } from './PharmacyCardFormType';

export default function PharmacyCardModal({
  show,
  setShow,
  claimNumber,
  card,
  setCard,
  submitCard,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  claimNumber: string;
  card: PharmacyCard | null;
  setCard: (card: PharmacyCard | null) => void;
  submitCard: (values: FormData, id: number) => Promise<void>;
}) {
  useEffect(() => {}, [claimNumber]);

  const { claim } = useAppSelector((state) => state.currentClaimReducer);

  const onSubmit = (values: PharmacyCardFormType) => {
    const copy = { ...values };

    const newCard = {
      Id: copy.id,
      ClaimNo: copy.claimNo,
      Name: copy.name,
      DateAdded: null,
      Active: null,
      AddedBy: null,
      DocId: copy.docId,
      ImagePath: null,
      BinNumber: copy.binNumber,
      PcnNumber: copy.pcnNumber,
      GroupNumber: copy.groupNumber,
      IdNumber: copy.idNumber,
      CompanyName: copy.companyName,
      CustomerPhoneNumber: copy.customerPhoneNumber,
      PharmacistPhoneNumber: copy.pharmacistPhoneNumber,
      StartDate: copy.startDate,
      EndDate: copy.endDate,
      DateModified: null,
      ModifiedBy: null,
      Doc: null,
    };

    const fd = new FormData();
    if (copy.file) {
      const { file } = copy.file;
      let fileToUpload = file;
      fileToUpload = new File([file], `${file.name}`, { type: file.type });
      fd.append('file', fileToUpload);
    }
    var cardString = JSON.stringify(newCard);
    fd.append('pharmacyCard', cardString);

    return submitCard(fd, copy.id);
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setCard(null);
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='Pharmacy-card-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Pharmacy-card-modal'>
          <FaIdCard className='pe-1' /> Add/Edit Pharmacy Card
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={
              card ?? {
                id: 0,
                claimNo: claimNumber,
                startDate: format(new Date(), 'yyyy-MM-dd'),
                endDate: format(addDays(new Date(), 90), 'yyyy-MM-dd'),
              }
            }
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='name'
                    type='text'
                    label='Name'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='companyName'
                    label='Company'
                    type='text'
                    component={FieldBSRenderText}
                    disabled={claim?.pbmId === 6}
                  />
                  <Field
                    name='binNumber'
                    label='BIN'
                    type='text'
                    component={FieldBSRenderText}
                    disabled={claim?.pbmId === 6}
                  />
                  <Field
                    name='pcnNumber'
                    label='PCN'
                    type='text'
                    component={FieldBSRenderText}
                    disabled={claim?.pbmId === 6}
                  />
                  <Field
                    name='groupNumber'
                    label='Group'
                    type='text'
                    component={FieldBSRenderText}
                    disabled={claim?.pbmId === 6}
                  />
                  <Field
                    name='idNumber'
                    label='ID'
                    type='text'
                    component={FieldBSRenderText}
                    disabled={claim?.pbmId === 6}
                  />
                  <Field
                    name='customerPhoneNumber'
                    label='Customer Support Number'
                    type='text'
                    component={FieldBSRenderText}
                    disabled={claim?.pbmId === 6}
                  />
                  <Field
                    name='pharmacistPhoneNumber'
                    label='Tech Support Number'
                    type='text'
                    component={FieldBSRenderText}
                    disabled={claim?.pbmId === 6}
                  />
                  <Field
                    name='file'
                    type='file'
                    component={FieldBSRenderFilePicker}
                  />
                  <Field
                    name='startDate'
                    type='text'
                    label='Start'
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                  />
                  <Field
                    name='endDate'
                    type='text'
                    label='End'
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                  />
                  <Alert variant='info'>
                    <div className='button-icon-text'>
                      <FaInfoCircle />
                      <p className='mb-0 ms-2'>
                        To cancel this card, set the end date to anytime in the
                        past.
                      </p>
                    </div>
                  </Alert>
                  <div className={`${styles.buttonDiv} my-3`}>
                    <Button type='submit' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      onClick={() => {
                        setCard(null);
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
