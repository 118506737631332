import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { SiteFunction } from '../ApiTypes/SiteFunction';

class SiteFunctionApi {
  static list() {
    return axios.get<SiteFunction[]>(
      `${API_URL}/api/sitefunctions`,
      getAuthHeader()
    );
  }
}

export default SiteFunctionApi;
