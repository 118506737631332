import React, { useState, useEffect } from 'react';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import logo from '../../Assets/CompComplete.png';
import { FileObject } from '../../ApiTypes/FileObject';
import DocumentsApi from '../../Api/DocumentsApi';
import { toast } from 'react-toastify';
import { ScanDoc } from '../../ApiTypes/ScanDoc';
// import EorQueue from './EorQueue';
import ScanDocSide from './ScanDocSide';
import { ScanDocEorSearchRequest } from './ScanDocEorSearchRequest';

export default function EorProcessing() {
  const [fetchingEors, setFetchingEors] = useState(false);
  // const [attaching, setAttaching] = useState<boolean>(false);

  const [eors, setEors] = useState<FileObject[]>([]);
  const [selectedEor, setSelectedEor] = useState<FileObject | null>(null);
  const [eorRowSelection, setEorRowSelection] = useState({});

  const [scanDocs, setScanDocs] = useState<ScanDoc[]>([]);
  const [selectedScanDoc, setSelectedScanDoc] = useState<ScanDoc | null>(null);
  const [scanDocRowSelection, setScanDocRowSelection] = useState({});

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    loadEors();
  };

  const loadEors = () => {
    setFetchingEors(true);
    DocumentsApi.loadEors()
      .then((res) => {
        setEors(res.data);
        setFetchingEors(false);
      })
      .catch((err) => {
        console.log(err);
        setFetchingEors(false);
        toast.error('Failed to get EORs');
      });
  };

  const loadScanDocs = (request: ScanDocEorSearchRequest) => {
    return DocumentsApi.getDocumentsWithControlNumber(
      request.checkNumber,
      request.controlNumber,
      request.claimNumber
    )
      .then((res) => {
        setScanDocs(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get documents');
      });
  };

  // const handleAttach = () => {
  //   if (!selectedEor || !selectedScanDoc) {
  //     toast.error('Must choose an EOR and a File');
  //     return;
  //   }
  //   setAttaching(true);
  //   DocumentsApi.attachEorToDoc({
  //     fileObj: selectedEor,
  //     docId: selectedScanDoc.docId,
  //   })
  //     .then((res) => {
  //       if (res.data.success) {
  //         toast.success('EOR attached!');
  //         setScanDocs([]);
  //         init();
  //       } else {
  //         toast.error('Failed to attach EOR to Document');
  //       }
  //       setAttaching(false);
  //     })
  //     .catch((err) => {
  //       setAttaching(false);
  //       console.log(err);
  //       toast.error('Failed to attach EOR to Document');
  //     });
  // };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <img src={logo} alt='CompComplete, LLC' />
        <div className='ms-3'>
          <h1 className='mb-0'>EOR Processing</h1>
        </div>
      </div>
      {/* <div className={styles.grid2}> */}
      <div>
        {/* <EorQueue
          loadEors={loadEors}
          eors={eors}
          fetching={fetchingEors}
          selectedEor={selectedEor}
          setSelectedEor={setSelectedEor}
          eorRowSelection={eorRowSelection}
          setEorRowSelection={setEorRowSelection}
        /> */}
        <ScanDocSide
          loadScanDocs={loadScanDocs}
          scanDocs={scanDocs}
          scanDocRowSelection={scanDocRowSelection}
          setScanDocRowSelection={setScanDocRowSelection}
          selectedScanDoc={selectedScanDoc}
          setSelectedScanDoc={setSelectedScanDoc}
          // handleAttach={handleAttach}
          // attaching={attaching}
        />
      </div>
    </PageScaffold>
  );
}
