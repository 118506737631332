import React, { useState, useEffect } from 'react';
import { Alert, Button, Container, Modal } from 'react-bootstrap';
import { FaCheck, FaFolderOpen } from 'react-icons/fa';
import { toast } from 'react-toastify';
import DocumentsApi from '../../Api/DocumentsApi';
import UserEmailListApi from '../../Api/UserEmailListApi';
import { SecureShare } from '../../ApiTypes/SecureShare';
import { SecureShareDocumentRequest } from '../../ApiTypes/SecureShareDocumentRequest';
import { UserEmailList } from '../../ApiTypes/UserEmailList';
import { useAppSelector } from '../../Reducers/Store';
import styles from './index.module.css';
import SecureShareModal from './SecureShareModal';
import SharedFilesTable from './SharedFilesTable';

export default function SharedFilesModal({
  show,
  setShow,
  claimNumber,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  claimNumber?: string;
}) {
  const [secureShares, setSecureShares] = useState<SecureShare[]>([]);
  const [shareToDelete, setShareToDelete] = useState<SecureShare | null>(null);
  const [shareToEdit, setShareToEdit] = useState<SecureShare | null>(null);
  const [userEmailList, setUserEmailList] = useState<UserEmailList[]>([]);

  const { userModel } = useAppSelector((state) => state.user);

  useEffect(() => {
    getSS();
  }, [claimNumber]);
  useEffect(() => {
    getUserEmailList();
  }, [userModel]);

  const getSS = () => {
    if (claimNumber) {
      getSecureSharesForClaim(claimNumber);
    }
    // else {
    //   getSecureSharesForUser();
    // }
  };

  const getSecureSharesForUser = () => {
    DocumentsApi.getSecureShareForUser()
      .then((res) => {
        setSecureShares(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSecureSharesForClaim = (claimNo: string) => {
    return DocumentsApi.getSecureShareForClaim(claimNo)
      .then((res) => {
        setSecureShares(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserEmailList = () => {
    if (userModel?.user?.userId) {
      UserEmailListApi.getEmailList(userModel?.user?.userId)
        .then((res) => {
          setUserEmailList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleDelete = () => {
    if (shareToDelete) {
      return DocumentsApi.deleteSecureShare(shareToDelete.id)
        .then((res) => {
          if (res.data.success) {
            getSS();
            setShareToDelete(null);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to delete secure share');
        });
    }
  };

  const handleSecureShare = (values: SecureShareDocumentRequest) => {
    return DocumentsApi.updateSecureShare(values)
      .then((res) => {
        if (res.data.success) {
          setShareToEdit(null);
          getSS();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to share documents');
      });
  };

  const setEditShare = (s: SecureShare) => {
    setShareToEdit(s);
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='Confirm-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Confirm-modal'>
          <FaFolderOpen className='pe-1' /> Shared Files
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p>Files shared with 3rd parties.</p>
          <SharedFilesTable
            sharedFiles={secureShares}
            setEditShare={setEditShare}
            setShareToDelete={setShareToDelete}
          />
          <SecureShareModal
            show={shareToEdit != null}
            setShow={(s: boolean) => setShareToEdit(null)}
            claimNumber={claimNumber ?? ''}
            docs={[]}
            handleSecureShare={handleSecureShare}
            userEmailList={userEmailList}
            share={shareToEdit}
          />
          {shareToDelete && (
            <Alert
              variant='danger'
              className={`mt-3 text-center ${styles.alertPos}`}
              dismissible
              onClose={() => {
                setShareToDelete(null);
              }}
            >
              <p>Confirm that you would like to delete this secure share</p>
              <Button
                type='button'
                variant='danger'
                size='sm'
                onClick={() => {
                  handleDelete();
                }}
              >
                Delete
              </Button>
            </Alert>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}
