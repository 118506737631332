import React, { useState, useEffect, useCallback } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import PhysicianApi from '../../../Api/PhysicianApi';
import { ClaimAuthorizedPhysician } from '../../../ApiTypes/ClaimAuthorizedPhysician';
import { ClaimPhysicianInfo } from '../../../ApiTypes/ClaimPhysicianInfo';
import { ClaimPhysicianLog } from '../../../ApiTypes/ClaimPhysicianLog';
import { Physician } from '../../../ApiTypes/Physician';
import NewPhysicianInfo from './NewPhysicianInfo';
import OldPhysicianInfo from './OldPhysicianInfo';

export default function PhysiciansTab({ claimType }: { claimType: number }) {
  let location = useLocation();
  let { claimNumber } = useParams();
  let navigate = useNavigate();
  const [key, setKey] = useState<string>('new');

  const [allPhysicians, setAllPhysicians] = useState<Physician[]>([]);
  const [claimAuthorizedPhysicians, setClaimAuthorizedPhysicians] = useState<
    ClaimAuthorizedPhysician[]
  >([]);
  const [physicianInfo, setPhysicianInfo] = useState<ClaimPhysicianInfo | null>(
    null
  );
  const [physicianLogs, setPhysicianLogs] = useState<ClaimPhysicianLog[]>([]);

  useEffect(() => {
    const locationToEventKey = () => {
      const split = location.pathname.split('/');
      if (split.length === 4 && split[3] === 'physicians') {
        navigate('new');
      } else {
        setKey(split[4]);
      }
    };
    locationToEventKey();
  }, [location, navigate]);

  const getClaimAuthorizedPhysicians = useCallback(() => {
    if (claimNumber) {
      PhysicianApi.getClaimAuthorizedPhysicians(claimNumber)
        .then((res) => {
          setClaimAuthorizedPhysicians(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setClaimAuthorizedPhysicians([]);
    }
  }, [claimNumber]);

  const getPhysicianLogs = useCallback(
    (physicianInfoId: number) => {
      PhysicianApi.getPhysicianLog(claimNumber!, physicianInfoId)
        .then((res) => {
          setPhysicianLogs(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [claimNumber]
  );

  const getPhysicianInfo = useCallback(() => {
    if (claimNumber) {
      PhysicianApi.getPhysicianInfo(claimNumber)
        .then((res) => {
          setPhysicianInfo(res.data);
          getPhysicianLogs(res.data.keyPhysicianInfoId);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setPhysicianInfo(null);
      setPhysicianLogs([]);
    }
  }, [claimNumber, getPhysicianLogs]);

  useEffect(() => {
    getAllPhysicians();
    getClaimAuthorizedPhysicians();
    getPhysicianInfo();
  }, [getClaimAuthorizedPhysicians, getPhysicianInfo]);

  const getAllPhysicians = () => {
    return PhysicianApi.getAll()
      .then((res) => {
        setAllPhysicians(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className='w-100 h-100'>
      <Tab.Container
        defaultActiveKey='all'
        activeKey={key}
        id='reviewTabs'
        onSelect={(k) => setKey(k ?? '')}
      >
        <Nav variant='tabs' className='mb-3'>
          {/* <Nav.Item>
            <NavLink to='old'>
              <Nav.Link as='button' eventKey='old'>
                Old Physician Info
              </Nav.Link>
            </NavLink>
          </Nav.Item> */}
          <Nav.Item>
            <NavLink to='new'>
              <Nav.Link as='button' eventKey='new'>
                New Physician Info
              </Nav.Link>
            </NavLink>
          </Nav.Item>
        </Nav>
        <Routes>
          <Route
            path='old'
            element={
              <OldPhysicianInfo
                claimNumber={claimNumber ?? ''}
                allPhysicians={allPhysicians}
                claimAuthorizedPhysicians={claimAuthorizedPhysicians}
                physicianInfo={physicianInfo}
                getClaimAuthorizedPhysicians={getClaimAuthorizedPhysicians}
                getAllPhysicians={getAllPhysicians}
                getPhysicianInfo={getPhysicianInfo}
              />
            }
          />
          <Route
            path='new'
            element={
              <NewPhysicianInfo
                claimNumber={claimNumber ?? ''}
                allPhysicians={allPhysicians}
                claimAuthorizedPhysicians={claimAuthorizedPhysicians}
                physicianInfo={physicianInfo}
                getClaimAuthorizedPhysicians={getClaimAuthorizedPhysicians}
                getAllPhysicians={getAllPhysicians}
                getPhysicianInfo={getPhysicianInfo}
                physicianLogs={physicianLogs}
              />
            }
          />
        </Routes>
      </Tab.Container>
    </div>
  );
}
