import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { CompIqStatObj } from '../../ApiTypes/CompIqStatObj';
import {
  FaEye,
  FaFileExcel,
  FaFilePdf,
  FaFileWord,
  FaPlayCircle,
  FaVoicemail,
} from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { displayDateOnly } from '../../Utils';
import { toast } from 'react-toastify';

export default function CompIqStatsTable({ data }: { data: CompIqStatObj[] }) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const getIcon = (type: string) => {
    switch (type) {
      case 'pdf':
        return <FaFilePdf />;
      case 'word':
        return <FaFileWord />;
      case 'excel':
        return <FaFileExcel />;
      case 'play':
        return <FaPlayCircle />;
      case 'voice':
        return <FaVoicemail />;
      default:
        return <FaFilePdf />;
    }
  };

  const columnData: ColumnDef<CompIqStatObj>[] = [
    {
      header: 'Claim No',
      accessorKey: 'claimNo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claimant',
      accessorKey: 'claimantName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date Added',
      accessorKey: 'dateAdded',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dateAdded ?? ''),
    },
    {
      header: 'SVC From',
      accessorKey: 'serviceFrom',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.serviceFrom ?? ''),
    },
    {
      header: 'SVC To',
      accessorKey: 'serviceTo',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.serviceTo ?? ''),
    },
    {
      header: 'Icon',
      accessorKey: 'docIcon',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 50,
      cell: (d) => getIcon(d.row.original.docIcon),
    },
    {
      header: 'Description',
      accessorKey: 'description',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Priority',
      accessorKey: 'priority',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date Sent',
      accessorKey: 'dateSent',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dateSent ?? ''),
    },
    {
      header: 'Status',
      accessorKey: 'status',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
