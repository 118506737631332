import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { useAppSelector } from '../../../Reducers/Store';
import { parseDatesForServer } from '../../../Utils';
import { centsValidation, requiredField } from '../../../Utils/FieldValidation';
import FieldBSRenderDate from '../../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../../Common/FieldRenderTextArea';

import styles from './index.module.css';
import { toast } from 'react-toastify';
import { ClaimSubrogation } from '../../../ApiTypes/ClaimSubrogation';
import FieldBSRenderMoney from '../../Common/FieldBSRenderMoney';
import { cleanMoney, formatNumbers } from '../../../Utils/InputFormatters';
import SubrogationApi from '../../../Api/SubrogationApi';
import SubrogationPaymentTable from './SubrogationPaymentTable';
import { ClaimSubrogationPayment } from '../../../ApiTypes/ClaimSubrogationPayment';
import { Adjuster } from '../../../ApiTypes/Adjuster';
import {
  ClaimCustomOption,
  OptionTypeEnum,
} from '../../../ApiTypes/ClaimCustomOption';
import { Transaction } from '../../../ApiTypes/Transaction';
import FieldBSRenderCheckbox from '../../Common/FieldBSRenderCheckbox';

export default function AddEditSubrogationModal({
  show,
  setShow,
  claimSubrogation,
  setSelectedSubrogation,
  claimNumber,
  getClaimSubrogations,
}: {
  show: boolean;
  setShow: (s: boolean) => void;
  claimSubrogation: ClaimSubrogation | null;
  setSelectedSubrogation: (c: ClaimSubrogation) => void;
  claimNumber: string;
  getClaimSubrogations: () => void;
}) {
  const { adjusters, claimCustomOptions } = useAppSelector(
    (state) => state.reference
  );

  const [csp, setCsp] = useState<ClaimSubrogationPayment[]>([]);
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const getClaimSubrogationPaymentsForSubrogation = () => {
    if (claimNumber && claimSubrogation) {
      SubrogationApi.getTransactionsForSubrogation(claimSubrogation.id)
        .then((res) => {
          setTransactions(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setTransactions([]);
    }
  };

  useEffect(() => {
    getClaimSubrogationPaymentsForSubrogation();
  }, [claimSubrogation]);

  const onSubmit = (values: ClaimSubrogation) => {
    return updateClaimSubrogation(values);
  };

  const updateClaimSubrogation = (request: ClaimSubrogation) => {
    if (request.settlementAmount?.toString() === '') {
      request.settlementAmount = null;
    }
    if (request.lienAmount?.toString() === '') {
      request.lienAmount = null;
    }
    if (request.creditAmount?.toString() === '') {
      request.creditAmount = null;
    }
    return SubrogationApi.updateClaimSubrogation(request)
      .then((res) => {
        if (res.data.success) {
          toast.success('Subrogation Saved!');
          // setShow(false);
          if (!claimSubrogation) {
            request.id = Number(res.data.affectedEntityIdentifier);
            setSelectedSubrogation(request);
          }
          getClaimSubrogations();
          setShow(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update Subrogation');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='xl'
      onHide={() => {
        setShow(false);
      }}
      aria-labelledby='Claim-Subrogation-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Claim-Subrogation-Form-modal'
        >
          Subrogation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={
              claimSubrogation
                ? {
                    ...claimSubrogation,
                    logChanges: true,
                  }
                : {
                    claimNo: claimNumber,
                    logChanges: true,
                  }
            }
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <div>
                      {/* left side */}
                      <div className='w-100 justify-content-between d-flex'>
                        <div className={`${styles.column} pe-3`}>
                          <Field
                            name='subrogationTypeId'
                            label='Subrogation Type'
                            options={claimCustomOptions.filter(
                              (x) => x.type === OptionTypeEnum.SubrogationTypes
                            )}
                            optionMethod={(options: ClaimCustomOption[]) =>
                              options.map((o) => (
                                <option key={o.id} value={o.id?.toString()}>
                                  {o.name}
                                </option>
                              ))
                            }
                            component={FieldBSRenderSelect}
                            // validate={requiredField}
                          />
                          <Field
                            name='subroAdjusterId'
                            label='Subrogation Adjuster'
                            options={adjusters
                              .filter((x) => x.active || x.active === null)
                              .sort((a, b) =>
                                (a.lastName ?? '').localeCompare(
                                  b.lastName ?? ''
                                )
                              )}
                            optionMethod={(options: Adjuster[]) =>
                              options.map((o) => (
                                <option key={o.adjusterId} value={o.adjusterId}>
                                  {o.lastName + ', ' + o.firstName}
                                </option>
                              ))
                            }
                            component={FieldBSRenderSelect}
                            // validate={requiredField}
                          />
                          <Field
                            name='referalDate'
                            label='Referral Date'
                            // validate={requiredField}
                            parse={parseDatesForServer}
                            component={FieldBSRenderDate}
                          />
                          <Field
                            name='counselAssignedDate'
                            label='Counsel Assigned'
                            // validate={requiredField}
                            parse={parseDatesForServer}
                            component={FieldBSRenderDate}
                          />
                          <Field
                            name='servedDate'
                            label='Served'
                            // validate={requiredField}
                            parse={parseDatesForServer}
                            component={FieldBSRenderDate}
                          />
                          <Field
                            name='caseName'
                            label='Case Name'
                            type='text'
                            component={FieldBSRenderText}
                          />
                          <Field
                            name='resultTypeId'
                            label='Result'
                            options={claimCustomOptions.filter(
                              (x) =>
                                x.type === OptionTypeEnum.SubrogationResults
                            )}
                            optionMethod={(options: ClaimCustomOption[]) =>
                              options.map((o) => (
                                <option key={o.id} value={o.id?.toString()}>
                                  {o.name}
                                </option>
                              ))
                            }
                            component={FieldBSRenderSelect}
                            validate={requiredField}
                          />
                          <Field
                            name='responsibleTypeId'
                            label='Responsible For'
                            options={claimCustomOptions.filter(
                              (x) =>
                                x.type ===
                                OptionTypeEnum.SubrogationResponsibleFors
                            )}
                            optionMethod={(options: ClaimCustomOption[]) =>
                              options.map((o) => (
                                <option key={o.id} value={o.id?.toString()}>
                                  {o.name}
                                </option>
                              ))
                            }
                            component={FieldBSRenderSelect}
                            // validate={requiredField}
                          />
                        </div>
                        {/* right side */}
                        <div className={`${styles.column} pe-3`}>
                          <Field
                            name='statusId'
                            label='Status'
                            options={claimCustomOptions.filter(
                              (x) =>
                                x.type === OptionTypeEnum.SubrogationStatuses
                            )}
                            optionMethod={(options: ClaimCustomOption[]) =>
                              options.map((o) => (
                                <option key={o.id} value={o.id?.toString()}>
                                  {o.name}
                                </option>
                              ))
                            }
                            component={FieldBSRenderSelect}
                            // validate={requiredField}
                          />
                          <div style={{ height: '80px' }}></div>
                          <Field
                            name='filedDate'
                            label='Filed'
                            // validate={requiredField}
                            parse={parseDatesForServer}
                            component={FieldBSRenderDate}
                          />
                          <Field
                            name='ninetyDayDate'
                            label='90 Day Letter Date'
                            // validate={requiredField}
                            parse={parseDatesForServer}
                            component={FieldBSRenderDate}
                          />
                          <Field
                            name='statuteDate'
                            label='Statute Date'
                            // validate={requiredField}
                            parse={parseDatesForServer}
                            component={FieldBSRenderDate}
                          />
                          <Field
                            name='docketNumber'
                            label='Docket #'
                            type='text'
                            component={FieldBSRenderText}
                          />
                          <Field
                            name='lienNoticeDate'
                            label='Lien Notice Sent Date'
                            // validate={requiredField}
                            parse={parseDatesForServer}
                            component={FieldBSRenderDate}
                          />
                        </div>
                        <div className={`${styles.column} align-self-end pe-3`}>
                          <div className='w-100 d-flex'>
                            <div className='w-50 pe-3'>
                              <Field
                                name='lienAmount'
                                label='Lien Amount'
                                type='text'
                                // disabled={values.isLein !== true}
                                format={formatNumbers}
                                parse={cleanMoney}
                                validate={centsValidation}
                                component={FieldBSRenderMoney}
                              />
                            </div>
                            <div className='w-50 pe-3'>
                              <Field
                                name='paidAmount'
                                label='Paid Amount'
                                type='text'
                                initialValue={transactions.reduce(function (
                                  acc,
                                  b
                                ) {
                                  return acc + (b.amount || 0);
                                },
                                0)}
                                format={formatNumbers}
                                validate={centsValidation}
                                component={FieldBSRenderMoney}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className='w-100 pe-3'>
                            <Field
                              name='amountRemaining'
                              label='Amount Remaining'
                              type='text'
                              initialValue={
                                +(values.lienAmount || 0) +
                                +transactions.reduce(function (acc, b) {
                                  return acc + (b.amount || 0);
                                }, 0)
                              }
                              format={formatNumbers}
                              validate={centsValidation}
                              component={FieldBSRenderMoney}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <Field
                        name='settlementTerms'
                        label='Settlement Terms'
                        rows={3}
                        component={FieldBSRenderTextArea}
                      />
                      <div className={`${styles.column} pe-3`}>
                        <Field
                          name='closedDate'
                          label='Closed Date'
                          // validate={requiredField}
                          parse={parseDatesForServer}
                          component={FieldBSRenderDate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.actionButtons}`}>
                    <Field
                      name='logChanges'
                      type='checkbox'
                      label='Add Change Log Note on Save'
                      checked={values.logChanges === true}
                      component={FieldBSRenderCheckbox}
                    />
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  {/* payments table */}
                  {claimSubrogation && claimSubrogation.id > 0 && (
                    <div className='mt-5'>
                      <SubrogationPaymentTable data={transactions} />
                    </div>
                  )}
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
