import React from 'react';
import { Field } from 'react-final-form';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';

import styles from './index.module.css';

export default function Wc1Requestor({ values }: { values: any }) {
  return (
    <div className='d-flex justify-content-center'>
      <div className='w-50 pe-2'>
        <Field
          name='requestor'
          type='text'
          label='Your Name'
          component={FieldBSRenderText}
        />
        <Field
          name='requestorTelephone'
          type='text'
          label='Your Phone Number (For Follow up Questions)'
          component={FieldBSRenderText}
        />
        <Field
          name='requestorEmail'
          type='text'
          label='Your Email Address (For Receipt Confirmation)'
          component={FieldBSRenderText}
        />
        <div
          className={`d-flex justify-content-start mb-3 ${styles.flexGap1rem}`}
        >
          <Field
            name='isEmergency'
            type='checkbox'
            label='Is An Emergency'
            value={true}
            checked={values?.emergency?.toString() === 'true'}
            component={FieldBSRenderCheckbox}
          />
        </div>
        <Field
          name='comments'
          label='Additional Comments'
          rows={4}
          component={FieldBSRenderTextArea}
        />
        {/* <Field
          name='sendEmail'
          type='checkbox'
          label='Send Email'
          checked={values?.sendEmail}
          component={FieldBSRenderCheckbox}
        /> */}
      </div>
    </div>
  );
}
