import { CellContext } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { ScanDocSimple } from '../../../ApiTypes/ScanDocSimple';
import { Button } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';

export default function EditableDocStatusCell({
  getValue,
  row,
  column,
  table,
}: CellContext<ScanDocSimple, unknown>) {
  let initialValue = getValue();
  // let statuses: string[] = [
  //   initialValue as string,
  //   'Change -> Sent To Straight Pay',
  //   'Change -> Sent to Bill Review',
  //   'Change -> Accepted',
  // ];

  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (row.getIsSelected() && !table.options.meta?.enableMultiple) {
    return (
      <div
        className='d-flex gap-2 p-2'
        style={{ background: 'white', borderRadius: '4px' }}
      >
        {/* <select
          name='docTypeId'
          value={value as string}
          onChange={(e) => {
            setValue(e.target.value);
            table.options.meta?.updateData(
              row.index,
              column.id,
              e.target.value
            );
          }}
        >
          {statuses.map((o) => (
            <option key={o} value={o}>
              {o}
            </option>
          ))}
        </select> */}
        <Button
          type='button'
          size='sm'
          variant='primary'
          title='Approve'
          onClick={() => {
            table.options.meta?.updateRowDocumentProperties!(
              row.index,
              'Change -> Accepted'
            );
          }}
        >
          A
        </Button>
        <Button
          type='button'
          size='sm'
          variant='primary'
          title='Send To Bill Review'
          onClick={() => {
            table.options.meta?.updateRowDocumentProperties!(
              row.index,
              'Change -> Sent to Bill Review'
            );
          }}
        >
          BR
        </Button>
        <Button
          type='button'
          size='sm'
          variant='primary'
          title='Send To Straight Pay'
          onClick={() => {
            table.options.meta?.updateRowDocumentProperties!(
              row.index,
              'Change -> Sent To Straight Pay'
            );
          }}
        >
          SP
        </Button>
      </div>
    );
  } else {
    if (getValue()) {
      return (
        <div onClick={() => row.toggleSelected()}>
          {(getValue() as string) ?? ''}
        </div>
      );
    } else {
      return <div onClick={() => row.toggleSelected()}>-</div>;
    }
  }
}
