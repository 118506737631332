import React from 'react';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  RadialLinearScale,
  PointElement,
  LineElement,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import styles from './index.module.css';
import { displayDateOnly } from '../../Utils';
import { ChartObj } from '../../ApiTypes/ChartObj';

export default function PieChart({
  ltClaimHistory,
  allClaimHistory,
}: {
  ltClaimHistory: ChartObj[];
  allClaimHistory: ChartObj[];
}) {
  ChartJS.register(
    ArcElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
    RadialLinearScale,
    PointElement,
    LineElement
  );

  const getOptions = (): any => ({
    stacked: false,
    plugins: {
      datalabels: {
        color: '#8E999F',
        anchor: 'end',
        align: 'start',
        font: {
          weight: 'bold',
        },
      },
      tooltip: {
        callbacks: {},
      },
      legend: {
        position: 'top',
        align: 'center',
      },
      title: {
        text: 'Claim Count by Day',
        align: 'center',
        font: {
          size: 24,
        },
        display: true,
      },
    },
  });
  function onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }

  const getData = (lt: ChartObj[], all: ChartObj[]): any => {
    const allDates = [...lt, ...all].map((x) => x.name).filter(onlyUnique);

    const filledLt = allDates.map((x) => {
      var item = lt.find((d) => d.name === x);
      if (item) {
        return item.value;
      }
      return 0;
    });
    const filledAll = allDates.map((x) => {
      var item = all.find((d) => d.name === x);
      if (item) {
        return item.value;
      }
      return 0;
    });

    return {
      labels: allDates.map((x) => displayDateOnly(x)).filter(onlyUnique),
      datasets: [
        {
          label: 'Total New Claims',
          data: filledAll,
          backgroundColor: '#e4581b',
          borderColor: '#e4581b',
        },
        {
          label: 'Total New LT Claims',
          data: filledLt,
          backgroundColor: '#3f95ff',
          borderColor: '#3f95ff',
        },
      ],
    };
  };

  return (
    <div>
      <div className={`${styles.radarChart}`}>
        {ltClaimHistory ? (
          <Radar
            data={getData(ltClaimHistory, allClaimHistory)}
            options={getOptions()}
          />
        ) : (
          'No Available Data'
        )}
      </div>
    </div>
  );
}
