import React from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaCheck, FaColumns } from 'react-icons/fa';
import styles from './index.module.css';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import { requiredField } from '../../Utils/FieldValidation';
import { Panel } from '../../ApiTypes/Panel';

export default function CreatePanelModal({
  show,
  setShow,
  insurCoId,
  createPanel,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  insurCoId: number;
  createPanel: (values: Panel) => Promise<void>;
}) {
  const onSubmit = (values: Panel) => {
    return createPanel(values);
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='CreatePanelModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='CreatePanelModal-modal'>
          <FaColumns className='pe-1' /> Create Panel
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              insCoId: insurCoId,
              id: 0,
              treatyYear: new Date().getFullYear().toString(),
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='panelName'
                    type='text'
                    label='Panel Name'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='treatyYear'
                    type='text'
                    label='Treaty Year'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <div className='d-flex justify-content-center align-items-center gap1Rem my-3'>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => setShow(false)}
                    >
                      Cancel
                    </Button>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
