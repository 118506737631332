import React from 'react'
import { Button } from 'react-bootstrap';
import { useRouteError, Link } from "react-router-dom";
import logo from "../../Assets/gas-logo-silver-001.png"
import styles from "./index.module.css"

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);
  return (
    <div className={styles.mainScreen}>
      <div className={`p-3 ${styles.loginHeader} d-flex justify-content-center`}>
        <div className={`${styles.logoDiv} pb-3`}>
          <img src={logo} alt="Georgia Administrative Services Logo" />
        </div>
      </div>
      <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
        <div className={styles.loginForm}>
          <div className='text-center'>{(error as any)?.statusText || (error as any)?.message}</div>
          {!error &&
            <div className='text-center'>Page Not Found!</div>
          }

          <div className='d-flex justify-content-center mt-3'>
            <Link to="/">
              <Button className={styles.gasBlue} variant="light">
                Return to Claim
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>

  )
}
