import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { Provider } from '../ApiTypes/Provider';
import { ProviderCleanupObject } from '../ApiTypes/ProviderCleanupObject';
import { ProviderSearchRequest } from '../ApiTypes/ProviderSearchRequest';
import { ProviderSearchResult } from '../ApiTypes/ProviderSearchResult';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { SwapProviderIdRequest } from '../ApiTypes/SwapProviderIdRequest';

class ProviderApi {
  static searchProviders(request: ProviderSearchRequest) {
    return axios.post<ProviderSearchResult[]>(
      `${API_URL}/api/providers`,
      request,
      getAuthHeader()
    );
  }
  static getProviderById(providerId: number) {
    return axios.get<ProviderSearchResult[]>(
      `${API_URL}/api/providers/${providerId}`,
      getAuthHeader()
    );
  }
  static updateRecentStraightPay(providerId: number) {
    return axios.get<ResponseApiModel>(
      `${API_URL}/api/providers/${providerId}/updatestraightpayrecent`,
      getAuthHeader()
    );
  }
  static getProviderForEdit(providerId: number) {
    return axios.get<Provider>(
      `${API_URL}/api/providers/${providerId}/edit`,
      getAuthHeader()
    );
  }
  static addNewProvider(provider: Provider) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/providers/add`,
      provider,
      getAuthHeader()
    );
  }
  static uploadLien(data: FormData) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/providers/lien`,
      data,
      getAuthHeader()
    );
  }
  static uploadFile(data: FormData) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/providers/files`,
      data,
      getAuthHeader()
    );
  }
  static updateProvider(provider: Provider) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/providers/${provider.providerId}`,
      provider,
      getAuthHeader()
    );
  }
  static deleteProviderFile(id: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/providers/files/${id}`,
      getAuthHeader()
    );
  }
  static deleteProvider(id: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/providers/${id}`,
      getAuthHeader()
    );
  }

  static getProvidersBad(searchFactor: string) {
    return axios.get<ProviderCleanupObject[]>(
      `${API_URL}/api/providers/bad?searchFactor=${searchFactor}`,
      getAuthHeader()
    );
  }
  static getProvidersGood(searchFactor: string) {
    return axios.get<ProviderCleanupObject[]>(
      `${API_URL}/api/providers/good?searchFactor=${searchFactor}`,
      getAuthHeader()
    );
  }
  static swapProviders(request: SwapProviderIdRequest) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/providers/swap`,
      request,
      getAuthHeader()
    );
  }
}

export default ProviderApi;
