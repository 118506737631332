import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ClaimAuthorizedPhysician } from '../ApiTypes/ClaimAuthorizedPhysician';
import { ClaimPhysicianInfo } from '../ApiTypes/ClaimPhysicianInfo';
import { ClaimPhysicianLog } from '../ApiTypes/ClaimPhysicianLog';
import { Physician } from '../ApiTypes/Physician';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class PhysicianApi {
  static getAll() {
    return axios.get<Physician[]>(`${API_URL}/api/physicians`, getAuthHeader());
  }
  static getClaimAuthorizedPhysicians(claimNumber: string) {
    return axios.get<ClaimAuthorizedPhysician[]>(
      `${API_URL}/api/claims/${claimNumber}/physicians`,
      getAuthHeader()
    );
  }
  static getPhysicianInfo(claimNumber: string) {
    return axios.get<ClaimPhysicianInfo>(
      `${API_URL}/api/claims/${claimNumber}/physicians/info`,
      getAuthHeader()
    );
  }
  static getPhysicianLog(claimNumber: string, physicianInfoId: number) {
    return axios.get<ClaimPhysicianLog[]>(
      `${API_URL}/api/claims/${claimNumber}/physicians/info/${physicianInfoId}/logs`,
      getAuthHeader()
    );
  }
  static getPhysicianById(id: number) {
    return axios.get<Physician>(
      `${API_URL}/api/physicians/${id}`,
      getAuthHeader()
    );
  }
  static updatePhysician(physician: Physician) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/physicians/${physician.id}`,
      physician,
      getAuthHeader()
    );
  }
  static createPhysician(physician: Physician) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/physicians/`,
      physician,
      getAuthHeader()
    );
  }
  static removeClaimAuthorizedPhysician(claimNumber: string, id: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/physicians/${id}`,
      getAuthHeader()
    );
  }
  static addClaimAuthorizedPhysician(cap: ClaimAuthorizedPhysician) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${cap.claimNo}/physicians`,
      cap,
      getAuthHeader()
    );
  }
  static updateClaimAuthorizedPhysician(cap: ClaimAuthorizedPhysician) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${cap.claimNo}/physicians/${cap.id}`,
      cap,
      getAuthHeader()
    );
  }
}

export default PhysicianApi;
