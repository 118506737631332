import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { EmailLetterRequest } from '../ApiTypes/EmailLetterRequest';
import { InsCoLetterLog } from '../ApiTypes/InsCoLetterLog';
import { Letter } from '../ApiTypes/Letter';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { ReportResponse } from '../ApiTypes/ReportRequest';

class LetterApi {
  static getLettersByInsCoId(insCoId: number) {
    return axios.get<Letter[]>(
      `${API_URL}/api/letters/${insCoId}`,
      getAuthHeader()
    );
  }
  static getHistoryForClaim(claimNumber: string) {
    return axios.get<InsCoLetterLog[]>(
      `${API_URL}/api/claims/${claimNumber}/letters`,
      getAuthHeader()
    );
  }
  static createNewLetter(letter: Letter) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/letters`,
      letter,
      getAuthHeader()
    );
  }
  static updateLetter(letter: Letter, claimNumber: string) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/letters/${letter.keyLetterId}`,
      letter,
      getAuthHeader()
    );
  }
  static emailLetter(request: EmailLetterRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${request.claimNumber}/letters/email`,
      request,
      getAuthHeader()
    );
  }
  // static getLetterPreview(
  //   claimNumber: string,
  //   keyLetterId: number,
  //   option: number
  // ) {
  //   return axios.get<FileDownload>(
  //     `${API_URL}/api/claims/${claimNumber}/letters/${keyLetterId}/preview/${option}`,
  //     getAuthHeader()
  //   );
  // }
  static getLetterPrint(
    claimNumber: string,
    keyLetterId: number,
    option: number
  ) {
    return axios.get<ReportResponse>(
      `${API_URL}/api/claims/${claimNumber}/letters/${keyLetterId}/print/${option}`,
      getAuthHeader()
    );
  }
  // static getLetterEnvelopePreview(claimNumber: string, keyLetterId: number) {
  //   return axios.get<FileDownload>(
  //     `${API_URL}/api/claims/${claimNumber}/letters/${keyLetterId}/preview/envelope`,
  //     getAuthHeader()
  //   );
  // }
  static getLetterEnvelopePrint(claimNumber: string, keyLetterId: number) {
    return axios.get<ReportResponse>(
      `${API_URL}/api/claims/${claimNumber}/letters/${keyLetterId}/print/envelope`,
      getAuthHeader()
    );
  }
}

export default LetterApi;
