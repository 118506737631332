import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { PropertyPhoto } from '../ApiTypes/PropertyPhoto';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class PropertyPhotoApi {
  static getPropertyPhotosForClaim(claimPropertyId: string) {
    return axios.get<PropertyPhoto[]>(
      `${API_URL}/api/claimproperty/${claimPropertyId}/propertyphoto`,
      getAuthHeader()
    );
  }
  static getPropertyPhotoById(claimPropertyId: string, propertyphotoid: string) {
    return axios.get<PropertyPhoto>(
      `${API_URL}/api/claimproperty/${claimPropertyId}/propertyphoto/${propertyphotoid}`,
      getAuthHeader()
    );
  }
  // static createPropertyPhoto(photo: PropertyPhoto, claimNumber: string) {
  //   return axios.post<ResponseApiModel>(
  //     `${API_URL}/api/claims/${claimNumber}/propertyphoto`,
  //     photo,
  //     getAuthHeader()
  //   );
  // }
  // static updatePropertyPhoto(photo: PropertyPhoto, claimNumber: string) {
  //   return axios.put<ResponseApiModel>(
  //     `${API_URL}/api/claims/${claimNumber}/propertyphoto/${photo.id}`,
  //     photo,
  //     getAuthHeader()
  //   );
  // }
  static deletePropertyPhoto(propertyphotoid: string, claimPropertyId: string) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/claimproperty/${claimPropertyId}/propertyphoto/${propertyphotoid}`,
      getAuthHeader()
    );
  }
  static createPropertyPhoto(property: PropertyPhoto) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claimproperty/${property.claimPropertyId}/propertyphoto`,
      property,
      getAuthHeader()
    );
  }
  static updatePropertyPhoto(property: PropertyPhoto) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claimproperty/${property.claimPropertyId}/propertyphoto/${property.id}`,
      property,
      getAuthHeader()
    );
  }
}

export default PropertyPhotoApi;
