import { FormApi } from 'final-form';
import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import RecurringPaymentsApi from '../../Api/RecurringPaymentsApi';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { useAppSelector } from '../../Reducers/Store';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import { formatNumbers, cleanMoney } from '../../Utils/InputFormatters';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';

import styles from './index.module.css';

export default function EpForm({
  values,
  form,
  transaction,
}: {
  values: Partial<EdiTransaction>;
  form: FormApi<EdiTransaction, Partial<EdiTransaction>>;
  transaction: EdiTransaction | null;
}) {
  const { claim } = useAppSelector((state) => state.currentClaimReducer);

  useEffect(() => {
    handleLoad();
  }, [claim, transaction]);

  const handleLoad = () => {
    if (!transaction) {
      if (claim?.disabilityDate) {
        form.change('disabilitydate', claim.disabilityDate);
      } else if (claim?.claimNo) {
        RecurringPaymentsApi.getRecurringPaymentsByClaimNumber(claim?.claimNo)
          .then((res) => {
            if (res.data.length > 0) {
              res.data.sort((a, b) => {
                const aDate = new Date(a.startDate);
                const bDate = new Date(b.startDate);
                return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
              });
              form.change('disabilitydate', res.data[0].startDate);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  return (
    <div>
      <div className={`${styles.edi2Grid}`}>
        <Field
          name='benefitStartDate'
          label='Employer Paid Benefits From'
          parse={parseDatesForServer}
          component={FieldBSRenderDate}
          validate={requiredField}
        />
        <Field
          name='benefitThroughDate'
          label='Employer Paid Benefits To'
          parse={parseDatesForServer}
          component={FieldBSRenderDate}
          validate={requiredField}
        />
        <Field
          name='weeklyBenefitAmt'
          label={`${
            (values?.weeklyBenefitAmt ?? 0) > 0 ? '🗹' : '🗆'
          } Benefits are being paid at the rate of`}
          type='text'
          placeholder=' '
          format={formatNumbers}
          parse={cleanMoney}
          component={FieldBSRenderMoney}
        />

        <Field
          name='avgWeeklyWage'
          label='per week based on an average weekly wage of'
          placeholder=' '
          type='text'
          format={formatNumbers}
          parse={cleanMoney}
          component={FieldBSRenderMoney}
        />
        <Field
          name='benefitCheckDate'
          label='Date Salary was Paid'
          parse={parseDatesForServer}
          component={FieldBSRenderDate}
        />
        <Field
          name='disabilitydate'
          label='Date of Disability'
          parse={parseDatesForServer}
          component={FieldBSRenderDate}
        />
      </div>
    </div>
  );
}
