import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaClipboard } from 'react-icons/fa';
import styles from './index.module.css';
import { useAppSelector } from '../../Reducers/Store';
import { ReassignAccountRequest } from '../../ApiTypes/ReassignAccountRequest';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { Adjuster } from '../../ApiTypes/Adjuster';
import ReferenceApi from '../../Api/ReferenceApi';
import { toast } from 'react-toastify';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import { SearchSeverity } from '../../ApiTypes/ClaimSearchRequest';
import InsuranceCompanyApi from '../../Api/InsuranceCompanyApi';

export default function ReassignAccount({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );
  const [activeAdjusters, setActiveAdjusters] = useState<Adjuster[]>([]);

  useEffect(() => {
    getActiveAdjusters();
  }, []);

  const getActiveAdjusters = () => {
    ReferenceApi.getAdjustersActive()
      .then((res) => {
        setActiveAdjusters(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get adjusters');
      });
  };

  const onSubmit = (values: ReassignAccountRequest) => {
    return InsuranceCompanyApi.reassignAccount(values)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          setShow(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to reassign');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='ReassignAccount-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='ReassignAccount-modal'>
          <FaClipboard className='pe-1' /> Reassign Account
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{}}
            validate={(values: ReassignAccountRequest) => {
              const errors: {
                [Property in keyof ReassignAccountRequest]?: string;
              } = {};

              if (!values?.severity) {
                errors.severity = 'Required';
              }

              return errors;
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              const severityFieldState = form.getFieldState(
                'severity' as keyof ReassignAccountRequest
              )!;
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='insCoId'
                    label='Account'
                    options={insuranceCompaniesForUser}
                    optionMethod={(options: InsuranceCompany[]) =>
                      options.map((o) => (
                        <option key={o.insurcoid} value={o.insurcoid ?? ''}>
                          {`${o.accountnumber} - ${o.shortname}`}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <fieldset className='mb-2'>
                    <legend className=''>Severity</legend>
                    <div
                      className=''
                      style={
                        severityFieldState?.submitFailed &&
                        severityFieldState?.invalid
                          ? {
                              border: '1px solid red',
                              borderRadius: '.25rem',
                            }
                          : {}
                      }
                    >
                      <div className='d-flex justify-content-around'>
                        <Field
                          name='severity'
                          type='radio'
                          label='Any'
                          value='0'
                          checked={values?.severity == SearchSeverity.Any}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='severity'
                          type='radio'
                          label='Lost Time'
                          value='1'
                          checked={values?.severity == SearchSeverity.LT}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='severity'
                          type='radio'
                          label='Medical Only'
                          value='2'
                          checked={values?.severity == SearchSeverity.MO}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='severity'
                          type='radio'
                          label='Advanced Med Only'
                          value='3'
                          checked={values?.severity == SearchSeverity.AM}
                          component={FieldBSRenderCheckbox}
                        />
                      </div>
                    </div>
                  </fieldset>
                  <Field
                    name='oldAdjusterId'
                    label='Old Adjuster'
                    options={activeAdjusters}
                    optionMethod={(options: Adjuster[]) =>
                      options.map((o) => (
                        <option key={o.adjusterId} value={o.adjusterId ?? ''}>
                          {o.firstName + ' ' + o.lastName}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='newAdjusterId'
                    label='New Adjuster'
                    options={activeAdjusters}
                    optionMethod={(options: Adjuster[]) =>
                      options.map((o) => (
                        <option key={o.adjusterId} value={o.adjusterId ?? ''}>
                          {o.firstName + ' ' + o.lastName}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <div className='d-flex justify-content-center align-items-center gap1Rem py-3'>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
