import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { PropertyType } from '../ApiTypes/PropertyType';

class PropertyTypeApi {
  static getPropertyTypes() {
    return axios.get<PropertyType[]>(
      `${API_URL}/api/propertytype`,
      getAuthHeader()
    );
  }
  static createPropertyType(propertyType: PropertyType) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/propertytype`,
      propertyType,
      getAuthHeader()
    );
  }
  static updatePropertyType(propertyType: PropertyType) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/propertytype/${propertyType.id}`,
      propertyType,
      getAuthHeader()
    );
  }
}
export default PropertyTypeApi;
