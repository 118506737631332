import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { DoctorLocation } from '../ApiTypes/DoctorLocation';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class DoctorLocationApi {
  static getDoctorLocationsByDoctorId(id: number) {
    return axios.get<DoctorLocation[]>(
      `${API_URL}/api/doctorlocations/${id}`,
      getAuthHeader()
    );
  }
  static getDoctorLocationById(id: number) {
    return axios.get<DoctorLocation>(
      `${API_URL}/api/doctorlocations/${id}/location`,
      getAuthHeader()
    );
  }
  static updateDoctorLocation(location: DoctorLocation) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/doctorlocations/${location.id}`,
      location,
      getAuthHeader()
    );
  }
  static createDoctorLocation(location: DoctorLocation) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/doctorlocations`,
      location,
      getAuthHeader()
    );
  }
}

export default DoctorLocationApi;
