import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { CaseManagement } from '../ApiTypes/CaseManagement';
import { ClaimNote } from '../ApiTypes/ClaimNote';
import { NCMDashboardChartObj } from '../ApiTypes/NCMDashboardChartObj';
import { NcmDashboardObj } from '../ApiTypes/NcmDashboardObj';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class NcmApi {
  static getNcmForClaim(claimNumber: string) {
    return axios.get<CaseManagement[]>(
      `${API_URL}/api/claims/${claimNumber}/nursecasemanagements`,
      getAuthHeader()
    );
  }
  static generateInvoice(
    claimNumber: string,
    caseManagementId: number,
    noteId: number
  ) {
    return axios.get<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/nursecasemanagements/${caseManagementId}/notes/${noteId}/invoice`,
      getAuthHeader()
    );
  }
  static getNcmById(claimNumber: string, caseManagementId: number) {
    return axios.get<CaseManagement>(
      `${API_URL}/api/claims/${claimNumber}/nursecasemanagements/${caseManagementId}`,
      getAuthHeader()
    );
  }
  static getNotesForNcm(caseManagementId: number, claimNumber: string) {
    return axios.get<ClaimNote[]>(
      `${API_URL}/api/claims/${claimNumber}/nursecasemanagements/${caseManagementId}/notes`,
      getAuthHeader()
    );
  }
  static createNcm(caseManagement: CaseManagement, claimNumber: string) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/nursecasemanagements`,
      caseManagement,
      getAuthHeader()
    );
  }
  static updateNcm(caseManagement: CaseManagement, claimNumber: string) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/nursecasemanagements/${caseManagement.id}`,
      caseManagement,
      getAuthHeader()
    );
  }
  static resolveNcm(caseManagement: CaseManagement, claimNumber: string) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/nursecasemanagements/${caseManagement.id}/resolve`,
      caseManagement,
      getAuthHeader()
    );
  }
  static hasOpenNcm(claimNumber: string) {
    return axios.get<{ hasOpen: boolean }>(
      `${API_URL}/api/claims/${claimNumber}/nursecasemanagements/hasopenncm`,
      getAuthHeader()
    );
  }
  static getDashboard(manager: string) {
    return axios.get<NcmDashboardObj[]>(
      `${API_URL}/api/nursecasemanagements/${manager}`,
      getAuthHeader()
    );
  }
  static reassign(id: number, userId: string) {
    return axios.get<ResponseApiModel>(
      `${API_URL}/api/nursecasemanagements/${id}/reassign/${userId}`,
      getAuthHeader()
    );
  }
  static getChartObjs(manager: string, days: number) {
    return axios.get<NCMDashboardChartObj[]>(
      `${API_URL}/api/nursecasemanagements/${manager}/days/${days}`,
      getAuthHeader()
    );
  }
}

export default NcmApi;
