import React from 'react';
import { Table as BSTable, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import { EdiTransactionLog } from '../../ApiTypes/EdiTransactionLog';
import { displayDateOnly } from '../../Utils';
import { EDIStatus } from '../../ApiTypes/EdiStatus';

const LogTable = (data: EdiTransactionLog[]): JSX.Element => {
  const childColumnData: {
    header: string;
    accessorKey: string;
    cell?: (d: string) => string | undefined;
  }[] = [
    {
      header: 'Claim Number',
      accessorKey: 'claimNo',
    },
    {
      header: 'Date',
      accessorKey: 'dateTime',
      cell: (d: string) => displayDateOnly(d),
    },
    {
      header: 'Creator',
      accessorKey: 'creator',
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: (d: string) => EDIStatus[+d],
    },
    {
      header: 'Notes',
      accessorKey: 'notes',
    },
  ];

  return (
    <div className='d-flex justify-content-start'>
      <BSTable
        size='sm'
        striped
        bordered
        hover
        style={{
          width: 'auto',
          marginLeft: '2rem',
        }}
      >
        <thead>
          <tr>
            {childColumnData.map((h) => (
              <th key={h.header}>{h.header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <tr key={i}>
              {childColumnData.map((h) => {
                if (!h.cell) {
                  return (
                    <td
                      style={{
                        whiteSpace: 'nowrap',
                        padding: '5px',
                      }}
                      key={`${h.header}i`}
                    >
                      {d[h.accessorKey as keyof typeof d] as string}
                    </td>
                  );
                } else {
                  return (
                    <td
                      style={{
                        whiteSpace: 'nowrap',
                        padding: '5px',
                      }}
                      key={`${h.header}i`}
                    >
                      {h.cell(d[h.accessorKey as keyof typeof d]! as string)}
                    </td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </BSTable>
    </div>
  );
};

export default LogTable;
