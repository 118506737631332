import { removeNonDigits } from './InputFormatters';

export const requiredField = (value: any) =>
  value !== undefined && value !== '' && value !== null
    ? undefined
    : 'Required';
    
export const getValidator = (isRequired: any) =>
  isRequired ? (value: any) => (value ? undefined : "Required") : () => {};

export const zipLength = (value: any) => {
  if (!value || value === '' || value === ' ') {
    return undefined;
  }
  return removeNonDigits(value).length < 5
    ? 'Zip must be at least 5 digits'
    : removeNonDigits(value).length > 9
    ? 'Zip must be no longer than 9 digits'
    : undefined;
};

export const centsValidation = (value: any) => {
  if (!value) return undefined;
  let [dollars, cents] = `${value}`.split('.');
  if (cents && cents.length > 2) {
    return 'Check number format';
  }
  return undefined;
};

export const composeValidators =
  (...validators: ((value: any) => string | undefined)[]) =>
  (value: any) =>
    validators.reduce(
      (error: undefined | string, validator) => error || validator(value),
      undefined
    );
