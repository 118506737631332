import React from 'react';
import { Field } from 'react-final-form';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import { formatNumbers, cleanMoney } from '../../Utils/InputFormatters';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';

export default function BenefitPage({
  values,
  disabledFields,
}: {
  values: EdiTransaction;
  disabledFields: string[];
}) {
  return (
    <div>
      <div className='d-flex align-items-center gap1Rem'>
        <Field
          name='avgWeeklyWage'
          label='Avg Weekly Wage'
          placeholder=' '
          type='text'
          format={formatNumbers}
          parse={cleanMoney}
          component={FieldBSRenderMoney}
        />
        <Field
          name='fullWagesPaidOnDoi'
          label='Full Wages Paid on DOI'
          type='checkbox'
          checked={values.fullWagesPaidOnDoi}
          component={FieldBSRenderCheckbox}
        />
      </div>
      <div className='d-flex align-items-center gap1Rem'>
        <Field
          name='weeklyBenefitAmt'
          label='Weekly Benefit'
          placeholder=' '
          type='text'
          format={formatNumbers}
          parse={cleanMoney}
          validate={requiredField}
          component={FieldBSRenderMoney}
        />
      </div>
      <div className='d-flex align-items-center gap1Rem'>
        <Field
          name='benefitStartDate'
          label='Payable From'
          parse={parseDatesForServer}
          component={FieldBSRenderDate}
          validate={requiredField}
        />
        <Field
          name='benefitThroughDate'
          label='Payable Through'
          parse={parseDatesForServer}
          component={FieldBSRenderDate}
          validate={requiredField}
        />
      </div>
      <div className='d-flex align-items-center gap1Rem'>
        <Field
          name='benefitCheckDate'
          label='Check Issue Date'
          parse={parseDatesForServer}
          component={FieldBSRenderDate}
        />
      </div>
    </div>
  );
}
