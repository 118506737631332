import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaEdit, FaRecycle, FaTrash } from 'react-icons/fa';
import { displayDateOnly } from '../../../Utils';
import { Witness } from '../../../ApiTypes/Witness';

export default function WitnessTable({
  witnesses,
  handleWitnessToDelete,
  show,
  setShow,
  selectedWitnessId,
  setSelectedWitnessId,
}: {
  handleWitnessToDelete: (w: Witness) => void;
  witnesses: Witness[];
  show: boolean;
  setShow: (show: boolean) => void;
  selectedWitnessId: string | '';
  setSelectedWitnessId: (witnessId: string | '') => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => witnesses, [witnesses]);

  const columnData: ColumnDef<Witness>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 75,
      size: 75,
      cell: (d) => {
        return (
          <div className='d-flex justify-content-around'>
            {/* <Link to={`${d.row.original.id}`}> */}
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='View/Edit'
              onClick={() => {
                setSelectedWitnessId(d.row.original.id.toString());
                setShow(true);
              }}
            >
              <FaEdit />
            </Button>
            {/* </Link> */}
            <Button
              type='button'
              size='sm'
              variant='danger'
              title='Delete'
              onClick={() => {
                handleWitnessToDelete(d.row.original);
              }}
            >
              <FaTrash />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'First Name',
      accessorKey: 'firstName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Last Name',
      accessorKey: 'lastName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Phone Number',
      accessorKey: 'phoneNumber',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Email',
      accessorKey: 'email',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Notes',
      accessorKey: 'notes',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        selectableRow={true}
        highlightRow={true}
      />
    </div>
  );
}
