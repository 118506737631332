import React from 'react';
import { Button } from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

export default function Navigation({
  page,
  previousPage,
  cancel,
}: {
  page: number;
  previousPage: () => void;
  cancel: () => void;
}) {
  return (
    <div className={`d-flex justify-content-around align-items-center py-3`}>
      <Button
        type='button'
        variant='primary'
        size='sm'
        className='button-icon-text'
        disabled={page === 1}
        onClick={previousPage}
      >
        <FaChevronLeft />
        Previous
      </Button>
      <Button type='button' variant='outline-danger' size='sm' onClick={cancel}>
        Cancel
      </Button>
      <Button
        type='submit'
        variant='primary'
        size='sm'
        className='button-icon-text'
      >
        {page === 4 ? 'Submit' : 'Next'}
        <FaChevronRight />
      </Button>
    </div>
  );
}
