import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ExcessCarrierPolicyClassCodeApi from '../../Api/ExcessCarrierPolicyClassCodeApi';
import PolicyClassCodeApi from '../../Api/PolicyClassCodeApi';
import { ExcessCarrierPolicyClassCode } from '../../ApiTypes/ExcessCarrierPolicyClassCode';
import { PolicyClassCode } from '../../ApiTypes/PolicyClassCode';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import PolicyClassCodeTable from './PolicyClassCodeTable';
import ClassCodesModal from '../ClassCodes/ClassCodesModal';
import ExcessCarrierPolicyClassCodeTable from './ExcessCarrierPolicyClassCodeTable';
import ClassCodeApi from '../../Api/ClassCodeApi';
import { ClassCodeItem } from '../../ApiTypes/ClassCode';

export default function PolicyClassCodes({
  policyId,
  isExcess,
}: {
  policyId: number;
  isExcess: boolean;
}) {
  const [pClassCodes, setPClassCodes] = useState<PolicyClassCode[]>([]);
  const [ecpClassCodes, setEcpClassCodes] = useState<
    ExcessCarrierPolicyClassCode[]
  >([]);

  const [classCodes, setClassCodes] = useState<ClassCodeItem[]>([]);

  const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false);
  const [selectedPolicyClassCode, setSelectedPolicyClassCode] =
    useState<PolicyClassCode | null>(null);
  const [selectedEcpClassCode, setSelectedEcpClassCode] =
    useState<ExcessCarrierPolicyClassCode | null>(null);

  const [showClassCodes, setShowClassCodes] = useState(false);

  useEffect(() => {
    getClassCodes();
  }, [policyId, isExcess]);

  const getClassCodes = () => {
    if (policyId > 0) {
      if (isExcess) {
        getExcessCarrierPolicyClassCodes();
      } else {
        getPolicyClassCodes();
      }
    } else {
      getCodes();
    }
  };

  const getPolicyClassCodes = () => {
    PolicyClassCodeApi.getForPolicy(policyId)
      .then((res) => {
        setPClassCodes(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get class codes');
      });
  };
  const getCodes = () => {
    ClassCodeApi.getClassCodes()
      .then((res) => {
        setClassCodes(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get class codes');
      });
  };

  const getExcessCarrierPolicyClassCodes = () => {
    ExcessCarrierPolicyClassCodeApi.getForPolicy(policyId)
      .then((res) => {
        setEcpClassCodes(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get class codes');
      });
  };

  const updatePolicyClassCode = (
    classCode: PolicyClassCode | ExcessCarrierPolicyClassCode
  ) => {
    if (!isExcess) {
      PolicyClassCodeApi.update(classCode as PolicyClassCode)
        .then((res) => {
          if (res.data.success) {
            toast.success('Policy Class Code updated');
          } else {
            toast.error(res.data.message);
            getPolicyClassCodes();
          }
        })
        .catch((err) => {
          console.log(err);
          getPolicyClassCodes();
          toast.error('Failed to update Policy Class Code');
        });
    } else {
      return ExcessCarrierPolicyClassCodeApi.update(
        classCode as ExcessCarrierPolicyClassCode
      )
        .then((res) => {
          if (res.data.success) {
            toast.success('Success');
            getExcessCarrierPolicyClassCodes();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to update');
        });
    }
  };

  const handleDeleteWarning = (
    classCode: PolicyClassCode | ExcessCarrierPolicyClassCode
  ) => {
    setShowDeleteWarning(true);
    if (!isExcess) {
      setSelectedPolicyClassCode(classCode as PolicyClassCode);
    } else {
      setSelectedEcpClassCode(classCode as ExcessCarrierPolicyClassCode);
    }
  };

  const deletePolicyClassCode = () => {
    if (selectedPolicyClassCode) {
      setShowDeleteWarning(false);
      return PolicyClassCodeApi.delete(selectedPolicyClassCode.keyCodeId)
        .then((res) => {
          if (res.data.success) {
            toast.success('Policy Class Code deleted');
            getPolicyClassCodes();
          } else {
            toast.error(res.data.message);
          }
          setShowDeleteWarning(false);
        })
        .catch((err) => {
          console.log(err);
          setShowDeleteWarning(false);
          toast.error('Failed to delete Policy Class Code');
        });
    }
    if (selectedEcpClassCode) {
      setShowDeleteWarning(false);
      return ExcessCarrierPolicyClassCodeApi.delete(selectedEcpClassCode.id)
        .then((res) => {
          if (res.data.success) {
            toast.success('Success');
            getExcessCarrierPolicyClassCodes();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to delete');
        });
    }

    return Promise.resolve();
  };

  return (
    <div>
      <div className='d-flex justify-content-center py-3'>
        {policyId > 0 && (
          <Button
            type='button'
            variant='primary'
            size='sm'
            className='button-icon-text'
            onClick={() => {
              setShowClassCodes(true);
            }}
          >
            <FaPlusCircle /> Add
          </Button>
        )}
      </div>
      {!isExcess ? (
        <PolicyClassCodeTable
          codes={pClassCodes}
          setData={setPClassCodes}
          updatePolicyClassCode={updatePolicyClassCode}
          handleDeleteWarning={handleDeleteWarning}
        />
      ) : (
        <ExcessCarrierPolicyClassCodeTable
          codes={ecpClassCodes}
          setData={setEcpClassCodes}
          updatePolicyClassCode={updatePolicyClassCode}
          handleDeleteWarning={handleDeleteWarning}
        />
      )}
      <DeleteConfirmationModal
        show={showDeleteWarning}
        setShow={setShowDeleteWarning}
        setNull={setSelectedPolicyClassCode}
        handleDelete={deletePolicyClassCode}
      />
      <ClassCodesModal
        show={showClassCodes}
        setShow={setShowClassCodes}
        policyId={policyId}
        isExcess={isExcess}
        refreshData={getClassCodes}
      />
    </div>
  );
}
