import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { InsCoEmployerEmailFile } from '../ApiTypes/InsCoEmployerEmailFile';
import { FileDownload } from '../Types/FileDownLoad';

class InsCoEmployerEmailFilesApi {
  static create(emailFile: InsCoEmployerEmailFile) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/InsCoEmployerEmailFile`,
      emailFile,
      getAuthHeader()
    );
  }
  static update(emailFile: InsCoEmployerEmailFile) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/InsCoEmployerEmailFile/${emailFile.id}`,
      emailFile,
      getAuthHeader()
    );
  }
  static delete(emailFile: InsCoEmployerEmailFile) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/InsCoEmployerEmailFile/${emailFile.id}`,
      getAuthHeader()
    );
  }
  static getEmailFileDownload(emailFileId: number) {
    return axios.get<FileDownload>(
      `${API_URL}/api/InsCoEmployerEmailFile/Download/${emailFileId}`,
      getAuthHeader()
    );
  }
  static getEmailFile(emailFileId: number) {
    return axios.get<InsCoEmployerEmailFile>(
      `${API_URL}/api/InsCoEmployerEmailFile/${emailFileId}`,
      getAuthHeader()
    );
  }
  static getEmailFiles(insCoId: number | 0, employerId: number | 0) {
    return axios.get<InsCoEmployerEmailFile[]>(
      `${API_URL}/api/InsCoEmployerEmailFile?insCoId=${insCoId}&employerId=${employerId}`,
      getAuthHeader()
    );
  }
  static uploadDocument(data: FormData) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/InsCoEmployerEmailFile/UploadFile`,
      data,
      getAuthHeader()
    );
  }
}

export default InsCoEmployerEmailFilesApi;
