import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaBook } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { SiteFunctionsEnum } from '../../../ApiTypes/SiteFunctions';
import { useAppSelector } from '../../../Reducers/Store';

export default function ProvidersDropdown() {
  const { userModel } = useAppSelector((state) => state.user);
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant='secondary'
        id='providers'
        className='button-icon-text'
      >
        <FaBook />
        Providers
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.ProviderMaintenance &&
            f.enabled
        ) && (
          <Link to='/provider-maintenance'>
            <Dropdown.Item as='button'>Provider Maintenance</Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) => f.siteFunctionId === SiteFunctionsEnum.LawFirms && f.enabled
        ) && (
          <Link to='/lawfirms'>
            <Dropdown.Item as='button'>Law Firms</Dropdown.Item>
          </Link>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.ProviderCleanup && f.enabled
        ) && (
          <Link to='/provider-cleanup'>
            <Dropdown.Item as='button'>Provider Cleanup</Dropdown.Item>
          </Link>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
