import accounting from 'accounting';
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaMoneyCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ExportApi from '../../Api/ExportApi';
import PaymentsApi from '../../Api/PaymentsApi';
import { VPaymentTab } from '../../ApiTypes/VPaymentTab';
import { displayDateOnly, downloadExcel } from '../../Utils';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import PaymentsTable from './PaymentsTable';

export default function PaymentsEnteredToday() {
  const [transactions, setTransactions] = useState<VPaymentTab[]>([]);

  useEffect(() => {
    getTransactions();
  }, []);

  const exportDoc = () => {
    var toSend = exportHandler(transactions);
    toSend.fileName = `Transactions.xls`;
    return ExportApi.exportToExcel(toSend)
      .then((res) => {
        downloadExcel(res.data.fileName, res.data.file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const exportHandler = (data: VPaymentTab[]) => {
    const columns = [
      'TxDate',
      'SVC From',
      'SVC To',
      'Payee',
      'Payee Name',
      'Tax Id',
      'TX Amount',
      'Check #',
      'Pay Code',
      'Financial Bucket',
      'Status',
      'Cleared',
    ];

    const rows: any[][] = [];

    data.forEach((t) => {
      const tRow = [
        displayDateOnly(t.txDate ?? ''),
        displayDateOnly(t.serviceFrom ?? ''),
        displayDateOnly(t.serviceTo ?? ''),
        t.payee,
        t.checkPayableTo,
        t.taxId,
        accounting.formatMoney(t.txAmount ?? 0),
        t.checkNumber,
        t.payCode,
        t.fBucket,
        t.status,
        t.isCleared ? 'Yes' : 'No',
      ];

      rows.push(tRow);
    });

    return {
      columns,
      rows,
      fileName: '',
    };
  };

  const getTransactions = () => {
    PaymentsApi.getPaymentsEnteredToday()
      .then((res) => {
        if (res.data.length === 0) {
          toast.info('No Payments Entered Today');
        } else {
          setTransactions(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get payments');
      });
  };
  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaMoneyCheck className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Payments Entered Today</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center py-3'>
        <Button
          type='button'
          size='sm'
          variant='primary'
          onClick={() => {
            exportDoc();
          }}
        >
          Export
        </Button>
      </div>
      <PaymentsTable data={transactions} />
    </PageScaffold>
  );
}
