import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import {
  FaPlusCircle,
  FaEdit,
  FaEye,
  FaPrint,
  FaEnvelopeOpen,
} from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LetterApi from '../../../Api/LetterApi';
import UserEmailListApi from '../../../Api/UserEmailListApi';
import { Letter } from '../../../ApiTypes/Letter';
import { LetterLogoOptions } from '../../../ApiTypes/LetterLogoOptions';
import { UserEmailList } from '../../../ApiTypes/UserEmailList';
import { useAppDispatch, useAppSelector } from '../../../Reducers/Store';
import BSSelect from '../../Common/BSSelect';
import AddLetterModal from './AddLetterModal';
import EditLetterModal from './EditLetterModal';
import EmailLetterModal from './EmailLetterModal';

import styles from './index.module.css';
import LetterOptions from './LetterOptions';
import {
  DevExpressReportRequest,
  DxAvailableReports,
} from '../../../ApiTypes/DevExpressReportRequest';
import { requestDx } from '../../DxReportRequestModal/useDxReportRequestModal';
import { getDxReport } from '../../DocViewModal/useDocViewModal';
import {
  setDocViewFileDownload,
  setShowDocView,
} from '../../../Actions/DocViewActions';
import { FileDownload } from '../../../Types/FileDownLoad';
import UserApi from '../../../Api/UserApi';
import { fetchCurrentUser } from '../../../Actions';

export default function Letters({
  letters,
  insuranceCompanyId,
  insuranceCompanyName,
  getLetters,
  getHistory,
  claimType,
}: {
  letters: Letter[];
  getLetters: () => void;
  getHistory: () => void;
  insuranceCompanyName: string;
  insuranceCompanyId: number;
  claimType: number;
}) {
  let { claimNumber } = useParams();
  const dispatch = useAppDispatch();
  const [keyLetterId, setKeyLetterId] = useState<number>(0);
  const [showAddLetter, setShowAddLetter] = useState<boolean>(false);
  const [showEditLetter, setShowEditLetter] = useState<boolean>(false);
  const [letterToEdit, setLetterToEdit] = useState<Letter | null>(null);
  const [showLetterOptions, setShowLetterOptions] = useState<boolean>(false);
  const [showViewLetterOptions, setShowViewLetterOptions] =
    useState<boolean>(false);
  const [letterToPrint, setLetterToPrint] = useState<Letter | null>(null);
  const [showEmailLetter, setShowEmailLetter] = useState<boolean>(false);
  const [letterToEmail, setLetterToEmail] = useState<Letter | null>(null);
  const [userEmailList, setUserEmailList] = useState<UserEmailList[]>([]);
  const [letterToView, setLetterToView] = useState<Letter | null>(null);

  const { userModel } = useAppSelector((state) => state.user);

  useEffect(() => {
    getUserEmailList();
  }, [userModel]);

  const getUserEmailList = () => {
    if (userModel?.user?.userId) {
      UserEmailListApi.getEmailList(userModel?.user?.userId)
        .then((res) => {
          setUserEmailList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const setShowDx = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownload = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  const createNewLetter = (letter: Letter) => {
    return LetterApi.createNewLetter(letter)
      .then((res) => {
        if (res.data.success) {
          setKeyLetterId(+res.data.affectedEntityIdentifier);
          setShowAddLetter(false);
          getLetters();
          getHistory();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create new letter');
      });
  };

  const handleEditLetter = () => {
    if (keyLetterId) {
      const letter = letters.find((l) => l.keyLetterId === +keyLetterId);
      setLetterToEdit(letter ?? null);
      setShowEditLetter(true);
    }
  };

  const handlePrintLetter = () => {
    if (keyLetterId) {
      const letter = letters.find((l) => l.keyLetterId === +keyLetterId);
      setLetterToPrint(letter ?? null);
      setShowLetterOptions(true);
    }
  };

  const handlePrintLetterAfterOptions = (value: number) => {
    setShowLetterOptions(false);
    return LetterApi.getLetterPrint(claimNumber!, keyLetterId, value)
      .then((res) => {
        if (res.data.success) {
          setFileDownload({
            file: res.data.reportBytes,
            fileName: res.data.reportFileName,
          });
          setShowDx(true);
          getLetters();
          getHistory();
        } else {
          toast.error('Failed to get letter to print');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get letter to print');
      });
  };

  const handleViewLetter = () => {
    if (keyLetterId) {
      const letter = letters.find((l) => l.keyLetterId === +keyLetterId);
      setLetterToView(letter ?? null);
      setShowViewLetterOptions(true);
    }
  };

  const handleViewLetterAfterOptions = (value: number) => {
    setShowViewLetterOptions(false);
    const request: DevExpressReportRequest = {
      ...requestDx,
      claimNumber: claimNumber!,
      letterId: keyLetterId,
      insurCoId: 1,
      userId: userModel?.user?.userId ?? '',
    };

    if (value === LetterLogoOptions.GASLogo) {
      request.report = DxAvailableReports.rptGASLetter;
    } else if (value === LetterLogoOptions.AccountLogo) {
      if (insuranceCompanyId === 4)
        request.report = DxAvailableReports.rptHCLetter;
      else if (insuranceCompanyId === 7)
        request.report = DxAvailableReports.rptGEWCTLetter;
      else if (insuranceCompanyId === 6)
        request.report = DxAvailableReports.rptGADALetter;
      else request.report = DxAvailableReports.rptGeneralLetter;
    } else {
      request.report = DxAvailableReports.rptGeneralLetter;
    }

    getDxReport(request, setFileDownload, setShowDx);
  };

  const handleViewEnvelope = () => {
    if (keyLetterId) {
      const request: DevExpressReportRequest = {
        ...requestDx,
        report: DxAvailableReports.rptEnvelope,
        claimNumber: claimNumber!,
        letterId: keyLetterId,
        insurCoId: 1,
      };
      getDxReport(request, setFileDownload, setShowDx);
    }
  };

  const handlePrintEnvelope = () => {
    if (keyLetterId) {
      return LetterApi.getLetterEnvelopePrint(claimNumber!, keyLetterId)
        .then((res) => {
          if (res.data.success) {
            setFileDownload({
              file: res.data.reportBytes,
              fileName: res.data.reportFileName,
            });
            setShowDx(true);
          } else {
            toast.error('Failed to get envelope to print');
          }
          getHistory();
          getLetters();
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get envelope to print');
        });
    }
  };

  const handleEmailLetterSelect = () => {
    if (keyLetterId) {
      const letter = letters.find((l) => l.keyLetterId === +keyLetterId);
      setLetterToEmail(letter ?? null);
      setShowEmailLetter(true);
    }
  };

  const handleUpdateEmailAddress = (values: { emailAddress: string }) => {
    return UserApi.updateEmail({
      userId: userModel?.user?.userId ?? '',
      emailAddress: values.emailAddress,
    })
      .then((res) => {
        if (res.data.success) {
          dispatch(fetchCurrentUser());
          return true;
        } else {
          toast.error(res.data.message);
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update email address');
        return false;
      });
  };

  const saveEditedLetter = (l: Letter) => {
    return LetterApi.updateLetter(l, claimNumber!)
      .then((res) => {
        if (res.data.success) {
          getLetters();
          getHistory();
          setLetterToEdit(null);
          setShowEditLetter(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update letter');
      });
  };

  return (
    <div className={`${styles.letterSide}`}>
      <BSSelect
        name='keyLetterId'
        label='Letter'
        onChange={setKeyLetterId}
        defaultValue={keyLetterId}
        options={letters
          .filter((c) => c.claimTypeId === claimType || c.claimTypeId === null)
          .sort((a, b) => a.letterName!.localeCompare(b.letterName!))}
        optionMethod={(options: Letter[]) =>
          options.map((o) => (
            <option key={o.keyLetterId} value={o.keyLetterId}>
              {o.letterName}
            </option>
          ))
        }
      />
      <Button
        type='button'
        variant='outline-secondary'
        size='sm'
        onClick={() => {
          setLetterToEdit(null);
          setShowAddLetter(true);
        }}
        className='button-icon-text'
      >
        <FaPlusCircle /> Add Letter
      </Button>
      <Button
        type='button'
        variant='outline-secondary'
        size='sm'
        onClick={() => {
          handleEditLetter();
        }}
        className='button-icon-text'
      >
        <FaEdit /> Edit Letter
      </Button>
      <Button
        type='button'
        variant='outline-secondary'
        size='sm'
        onClick={() => handleViewLetter()}
        className='button-icon-text'
      >
        <FaEye /> Preview Letter
      </Button>
      <Button
        type='button'
        variant='outline-secondary'
        size='sm'
        onClick={() => handlePrintLetter()}
        className='button-icon-text'
      >
        <FaPrint /> Print Letter
      </Button>
      <Button
        type='button'
        variant='outline-secondary'
        size='sm'
        onClick={() => handleEmailLetterSelect()}
        className='button-icon-text'
      >
        <FaEnvelopeOpen /> Email
      </Button>
      <Button
        type='button'
        variant='outline-secondary'
        size='sm'
        onClick={() => handleViewEnvelope()}
        className='button-icon-text'
      >
        <FaEye /> Preview Envelope
      </Button>
      <Button
        type='button'
        variant='outline-secondary'
        size='sm'
        onClick={() => {
          handlePrintEnvelope();
        }}
        className='button-icon-text'
      >
        <FaPrint /> Print Envelope
      </Button>
      <AddLetterModal
        show={showAddLetter}
        setShow={setShowAddLetter}
        insCoId={insuranceCompanyId}
        createNewLetter={createNewLetter}
        claimType={claimType}
      />
      <EditLetterModal
        show={showEditLetter}
        setShow={setShowEditLetter}
        insuranceCompanyName={insuranceCompanyName}
        letter={letterToEdit}
        saveLetter={saveEditedLetter}
        claimType={claimType}
      />
      <LetterOptions
        show={showLetterOptions}
        setShow={setShowLetterOptions}
        handleSelection={handlePrintLetterAfterOptions}
      />
      <LetterOptions
        show={showViewLetterOptions}
        setShow={setShowViewLetterOptions}
        handleSelection={handleViewLetterAfterOptions}
      />
      <EmailLetterModal
        show={showEmailLetter}
        setShow={setShowEmailLetter}
        keyLetterId={keyLetterId}
        letter={letterToEmail}
        userEmailList={userEmailList}
        getLetters={getLetters}
        getHistory={getHistory}
        handleUpdateEmailAddress={handleUpdateEmailAddress}
      />
    </div>
  );
}
