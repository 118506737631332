import React, { useEffect, useMemo, useState } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { FaShare } from 'react-icons/fa';
import { ScanDocSimple } from '../../ApiTypes/ScanDocSimple';
import { UserEmailList } from '../../ApiTypes/UserEmailList';
import { useAppSelector } from '../../Reducers/Store';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import styles from './index.module.css';
import CreatableSelect from 'react-select/creatable';
import { EmailDocumentsRequest } from '../../ApiTypes/EmailDocumentsRequest';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import { ScanDocsPending } from '../../ApiTypes/ScanDocsPending';

export default function EmailDocumentsModal({
  show,
  setShow,
  claimNumber,
  docs,
  pendingDocs = [],
  handleEmailDocuments,
  userEmailList,
  subject = '',
  body = '',
  toEmailAddresses = [],
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  claimNumber: string;
  docs: ScanDocSimple[];
  pendingDocs?: ScanDocsPending[];
  userEmailList: UserEmailList[];
  handleEmailDocuments: (values: EmailDocumentsRequest) => Promise<void>;
  subject?: string;
  body?: string;
  toEmailAddresses?: string[];
}) {
  const emailList = useMemo(() => {
    const tos = toEmailAddresses.map((t) => ({
      label: t,
      value: t,
    }));

    const list = userEmailList.map((e) => ({
      label: e.emailAddress ?? '',
      value: e.emailAddress ?? '',
    }));

    return [...tos, ...list];
  }, [toEmailAddresses, userEmailList]);

  const [docIdList, setDocIdList] = useState<number[]>([]);

  useEffect(() => {
    loadDocsList();
  }, [docs]);

  const loadDocsList = () => {
    var list: number[] = [];
    docs.forEach((doc) => {
      list.push(doc.docid);
    });
    pendingDocs.forEach((doc) => {
      list.push(doc.docId);
    });
    setDocIdList(list);
  };
  const { userModel } = useAppSelector((state) => state.user);

  const onSubmit = (values: EmailDocumentsRequest) => {
    return handleEmailDocuments(values);
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      aria-labelledby='Email-Documents-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Email-Documents-Form-modal'
        >
          <FaShare className='pe-1' /> Email
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p>Claim: {claimNumber}</p>
          <RFFForm
            onSubmit={onSubmit}
            validate={(values: EmailDocumentsRequest) => {
              const errors: {
                [Property in keyof EmailDocumentsRequest]?: string;
              } = {};

              if (values?.emailAddresses.length === 0) {
                errors.emailAddresses = 'Required';
              }
              return errors;
            }}
            initialValues={{
              subject,
              message: body,
              claimNumber,
              docIds: docIdList,
              from: userModel?.user?.emailAddress ?? '',
              saveNote: true,
              emailAddresses: toEmailAddresses,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field name='emailAddresses'>
                    {({ input, meta: { touched, error } }) => (
                      <div
                        className={`position-relative ${styles.vFieldHeight}`}
                      >
                        <label
                          htmlFor={input.name}
                          className='form-label fs-6  m-0'
                        >
                          Email Addresses
                        </label>
                        <CreatableSelect
                          name={input.name}
                          placeholder='Send To'
                          isClearable
                          isMulti
                          defaultValue={emailList.slice(
                            0,
                            toEmailAddresses.length
                          )}
                          onChange={(e) => {
                            if (e) {
                              input.onChange(e.map((m) => m.value));
                            } else {
                              input.onChange(null);
                            }
                          }}
                          styles={{
                            control: (baseStyles: any, state: any) => ({
                              ...baseStyles,
                              borderColor: touched && error && 'red',
                            }),
                          }}
                          options={emailList}
                        />

                        {touched && error && (
                          <span className={`${styles.fieldError} text-danger`}>
                            {error}
                          </span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field
                    name='subject'
                    label='Subject'
                    type='text'
                    component={FieldBSRenderText}
                    // validate={requiredField}
                  />
                  <Field
                    name='message'
                    label='Message'
                    rows={3}
                    component={FieldBSRenderTextArea}
                    // validate={requiredField}
                  />
                  <fieldset>
                    <legend>Documents</legend>
                    {docs.map((d) => (
                      <Field
                        key={`${d.docid}`}
                        name='docIds'
                        label={d.nameAndDatesText}
                        type='checkbox'
                        value={d.docid}
                        checked={values.docIds.includes(d.docid)}
                        component={FieldBSRenderCheckbox}
                      />
                    ))}
                    {pendingDocs.map((d) => (
                      <Field
                        key={`${d.docId}`}
                        name='docIds'
                        label={d.filename}
                        type='checkbox'
                        value={d.docId}
                        checked={values.docIds.includes(d.docId)}
                        component={FieldBSRenderCheckbox}
                      />
                    ))}
                  </fieldset>
                  <div className={`${styles.buttonDiv} mt-3`}>
                    <Field
                      name='saveNote'
                      label='Log to Notes?'
                      type='checkbox'
                      checked={values.saveNote}
                      component={FieldBSRenderCheckbox}
                    />
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
