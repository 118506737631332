import React from 'react'
import PageScaffold from '../PageScaffold/PageScaffold'
import styles from "./index.module.css"

export default function EmptyScreen() {

  return (
    <PageScaffold>
      <div className='w-100 h-100 p-5 d-flex justify-content-center align-items-center'>
        <p className={styles.noResults}>Georgia Administrative Services </p>
      </div>
    </PageScaffold>

  )
}
