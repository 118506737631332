import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { useAppSelector } from '../../../Reducers/Store';
import { parseDatesForServer } from '../../../Utils';
import { centsValidation, requiredField } from '../../../Utils/FieldValidation';
import FieldBSRenderDate from '../../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../../Common/FieldRenderTextArea';

import styles from './index.module.css';
import { toast } from 'react-toastify';
import { ClaimLitigation } from '../../../ApiTypes/ClaimLitigation';
import FieldBSRenderMoney from '../../Common/FieldBSRenderMoney';
import { cleanMoney, formatNumbers } from '../../../Utils/InputFormatters';
import LitigationApi from '../../../Api/LitigationApi';
import {
  ClaimCustomOption,
  OptionTypeEnum,
} from '../../../ApiTypes/ClaimCustomOption';
import FieldBSRenderCheckbox from '../../Common/FieldBSRenderCheckbox';
import { State } from '../../../ApiTypes/State';
import { County } from '../../../ApiTypes/County';
import { Judge } from '../../../ApiTypes/Judge';
import { FaPlus } from 'react-icons/fa';
import AddJudge from './AddJudge';

export default function AddEditLitigationModal({
  show,
  setShow,
  claimLitigation,
  setSelectedLitigation,
  claimNumber,
  getClaimLitigations,
}: {
  show: boolean;
  setShow: (s: boolean) => void;
  claimLitigation: ClaimLitigation | null;
  setSelectedLitigation: (c: ClaimLitigation) => void;
  claimNumber: string;
  getClaimLitigations: () => void;
}) {
  const { claimCustomOptions, judges, states, counties } = useAppSelector(
    (state) => state.reference
  );
  const { claimant } = useAppSelector((state) => state.currentClaimReducer);
  const [countiesForState, setCountiesForState] = useState<County[]>([]);
  const [showAddJudge, setShowAddJudge] = useState<boolean>(false);

  useEffect(() => {
    if (claimLitigation && claimLitigation.state) {
      getCounties(claimLitigation.state);
    }
  }, [claimLitigation]);

  const getCounties = (state: string) => {
    if (state) {
      const countiesNeeded = counties.filter(
        (c) =>
          c.stateName === states.find((s) => s.stateAbbr === state)?.stateName
      );
      setCountiesForState(countiesNeeded);
    } else {
      setCountiesForState([]);
    }
  };

  const onSubmit = (values: ClaimLitigation) => {
    return updateClaimLitigation(values);
  };

  const updateClaimLitigation = (request: ClaimLitigation) => {
    if (request.demand?.toString() === '') {
      request.demand = null;
    }
    if (request.statutoryDemand?.toString() === '') {
      request.statutoryDemand = null;
    }
    if (request.postJudgmentInterest?.toString() === '') {
      request.postJudgmentInterest = null;
    }
    if (request.attorneyFeeAward?.toString() === '') {
      request.attorneyFeeAward = null;
    }
    if (request.otherFine?.toString() === '') {
      request.otherFine = null;
    }
    if (request.settlementAmount?.toString() === '') {
      request.settlementAmount = null;
    }
    return LitigationApi.updateClaimLitigation(request)
      .then((res) => {
        if (res.data.success) {
          toast.success('Litigation Saved!');
          // setShow(false);
          if (!claimLitigation) {
            request.id = Number(res.data.affectedEntityIdentifier);
            setSelectedLitigation(request);
          }
          getClaimLitigations();
          setShow(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update Litigation');
      });
  };

  return (
    <div>
      <Modal
        centered
        show={show}
        size='xl'
        onHide={() => {
          setShow(false);
        }}
        aria-labelledby='Claim-Litigation-Form-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title
            className='button-icon-text'
            id='Claim-Litigation-Form-modal'
          >
            Litigation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <RFFForm
              onSubmit={onSubmit}
              initialValues={
                claimLitigation
                  ? {
                      ...claimLitigation,
                      logChanges: true,
                    }
                  : {
                      claimNo: claimNumber,
                      injuredPartyName:
                        claimant?.firstName + ' ' + claimant?.lastName,
                      logChanges: true,
                    }
              }
              render={({ handleSubmit, form, values, submitting }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <div>
                      <div>
                        <div className='w-100'>
                          <div className='w-100 d-flex'>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='caseName'
                                label='Case Name'
                                type='text'
                                component={FieldBSRenderText}
                                validate={requiredField}
                              />
                            </div>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='caseNo'
                                label='Case #'
                                type='text'
                                component={FieldBSRenderText}
                              />
                            </div>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='injuredPartyName'
                                label='Injured Party'
                                type='text'
                                component={FieldBSRenderText}
                              />
                            </div>
                          </div>
                          <div className='w-100 d-flex'>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='noticeOfRepDate'
                                label='Notice Of Rep'
                                // validate={requiredField}
                                parse={parseDatesForServer}
                                component={FieldBSRenderDate}
                              />
                            </div>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='assignedDefenseDate'
                                label='Assigned Defense Date'
                                // validate={requiredField}
                                parse={parseDatesForServer}
                                component={FieldBSRenderDate}
                              />
                            </div>
                            <div className={`${styles.column} pe-3 my-auto`}>
                              <Field
                                name='proSe'
                                type='checkbox'
                                label='Pro Se'
                                checked={values.proSe === true}
                                component={FieldBSRenderCheckbox}
                              />
                            </div>
                          </div>
                          <div className='w-100 d-flex'>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='filedDate'
                                label='Filed'
                                // validate={requiredField}
                                parse={parseDatesForServer}
                                component={FieldBSRenderDate}
                              />
                            </div>
                          </div>
                          <div className='w-100 d-flex'>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='jurisdictionId'
                                label='Jurisdiction'
                                options={claimCustomOptions
                                  .filter(
                                    (x) =>
                                      x.type ===
                                      OptionTypeEnum.LitigationJurisdiction
                                  )
                                  .sort((a, b) =>
                                    (a.name ?? '').localeCompare(b.name ?? '')
                                  )}
                                optionMethod={(options: ClaimCustomOption[]) =>
                                  options.map((o) => (
                                    <option key={o.id} value={o.id?.toString()}>
                                      {o.name}
                                    </option>
                                  ))
                                }
                                component={FieldBSRenderSelect}
                              />
                            </div>
                            <div className={`${styles.column} pe-3 d-flex`}>
                              <div className='w-100'>
                                <Field
                                  name='judgeId'
                                  label='Judge'
                                  options={[...judges].sort((a, b) =>
                                    (a.lastName ?? '').localeCompare(
                                      b.lastName ?? ''
                                    )
                                  )}
                                  optionMethod={(options: Judge[]) =>
                                    options.map((o) => (
                                      <option
                                        key={o.id}
                                        value={o.id?.toString()}
                                      >
                                        {o.lastName}, {o.firstName}
                                      </option>
                                    ))
                                  }
                                  component={FieldBSRenderSelect}
                                  // validate={requiredField}
                                />
                              </div>
                              <div className='my-auto'>
                                <Button
                                  type='button'
                                  variant='primary'
                                  size='sm'
                                  title='Edit'
                                  onClick={() => {
                                    setShowAddJudge(true);
                                  }}
                                >
                                  <FaPlus />
                                </Button>
                              </div>
                            </div>
                            {/* <div className={`${styles.column} pe-3`}>
                            <Field
                              name='plaintiffName'
                              label='Plaintiff Name'
                              type='text'
                              component={FieldBSRenderText}
                            />
                          </div> */}
                          </div>
                          <div className='w-100 d-flex'>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='venueCity'
                                label='Venue City'
                                type='text'
                                component={FieldBSRenderText}
                              />
                            </div>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='state'
                                label='State'
                                options={states}
                                optionMethod={(options: State[]) =>
                                  options.map((o) => (
                                    <option
                                      key={o.stateAbbr}
                                      value={o.stateAbbr}
                                    >
                                      {o.stateAbbr}
                                    </option>
                                  ))
                                }
                                onChange={getCounties}
                                component={FieldBSRenderSelect}
                              />
                            </div>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='county'
                                label='County'
                                options={countiesForState}
                                optionMethod={(options: County[]) =>
                                  options.map((o) => (
                                    <option key={o.id} value={o.countyName}>
                                      {o.countyName}
                                    </option>
                                  ))
                                }
                                component={FieldBSRenderSelect}
                              />
                            </div>
                          </div>
                          <div className='w-100 d-flex'>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='demand'
                                label='Initial Demand'
                                type='text'
                                format={formatNumbers}
                                parse={cleanMoney}
                                validate={centsValidation}
                                component={FieldBSRenderMoney}
                              />
                            </div>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='demandDate'
                                label='Initial Demand Date'
                                // validate={requiredField}
                                parse={parseDatesForServer}
                                component={FieldBSRenderDate}
                              />
                            </div>
                          </div>
                          <div className='w-100 d-flex'>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='statutoryDemand'
                                label='Statutory Demand'
                                type='text'
                                format={formatNumbers}
                                parse={cleanMoney}
                                validate={centsValidation}
                                component={FieldBSRenderMoney}
                              />
                            </div>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='statutoryDemandDate'
                                label='Statutory Demand Date'
                                // validate={requiredField}
                                parse={parseDatesForServer}
                                component={FieldBSRenderDate}
                              />
                            </div>
                          </div>
                          <div className='w-100 d-flex'>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='courtDispositionDate'
                                label='Court Disposition Date'
                                // validate={requiredField}
                                parse={parseDatesForServer}
                                component={FieldBSRenderDate}
                              />
                            </div>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='trialResultId'
                                label='Trial Result'
                                options={claimCustomOptions
                                  .filter(
                                    (x) =>
                                      x.type ===
                                      OptionTypeEnum.LitigationTrialResult
                                  )
                                  .sort((a, b) =>
                                    (a.name ?? '').localeCompare(b.name ?? '')
                                  )}
                                optionMethod={(options: ClaimCustomOption[]) =>
                                  options.map((o) => (
                                    <option key={o.id} value={o.id?.toString()}>
                                      {o.name}
                                    </option>
                                  ))
                                }
                                component={FieldBSRenderSelect}
                                // validate={requiredField}
                              />
                            </div>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='msjresultId'
                                label='Motion Filed'
                                options={claimCustomOptions
                                  .filter(
                                    (x) =>
                                      x.type ===
                                      OptionTypeEnum.LitigationMSJResult
                                  )
                                  .sort((a, b) =>
                                    (a.name ?? '').localeCompare(b.name ?? '')
                                  )}
                                optionMethod={(options: ClaimCustomOption[]) =>
                                  options.map((o) => (
                                    <option key={o.id} value={o.id?.toString()}>
                                      {o.name}
                                    </option>
                                  ))
                                }
                                component={FieldBSRenderSelect}
                                // validate={requiredField}
                              />
                            </div>
                          </div>
                          <div className='w-100 d-flex'>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='postJudgmentInterest'
                                label='Post-Judgment Interest'
                                type='text'
                                format={formatNumbers}
                                parse={cleanMoney}
                                validate={centsValidation}
                                component={FieldBSRenderMoney}
                              />
                            </div>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='attorneyFeeAward'
                                label='Attorney Fee Award'
                                type='text'
                                format={formatNumbers}
                                parse={cleanMoney}
                                validate={centsValidation}
                                component={FieldBSRenderMoney}
                              />
                            </div>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='otherFine'
                                label='Other Fine or Penalty'
                                type='text'
                                format={formatNumbers}
                                parse={cleanMoney}
                                validate={centsValidation}
                                component={FieldBSRenderMoney}
                              />
                            </div>
                          </div>
                          <div className='w-100 d-flex'>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='awardDate'
                                label='Award Date'
                                // validate={requiredField}
                                parse={parseDatesForServer}
                                component={FieldBSRenderDate}
                              />
                            </div>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='settlementDate'
                                label='Settlement Date'
                                // validate={requiredField}
                                parse={parseDatesForServer}
                                component={FieldBSRenderDate}
                              />
                            </div>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='settlementAmount'
                                label='Settlement Amount'
                                type='text'
                                format={formatNumbers}
                                parse={cleanMoney}
                                validate={centsValidation}
                                component={FieldBSRenderMoney}
                              />
                            </div>
                          </div>
                          <div className='w-100 pe-3'>
                            <Field
                              name='settlementTerms'
                              label='Settlement Terms'
                              rows={3}
                              component={FieldBSRenderTextArea}
                            />
                          </div>
                          <div className='w-100 d-flex'>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='appealingPartyId'
                                label='Appealing Party'
                                options={claimCustomOptions
                                  .filter(
                                    (x) =>
                                      x.type ===
                                      OptionTypeEnum.LitigationAppealingParty
                                  )
                                  .sort((a, b) =>
                                    (a.name ?? '').localeCompare(b.name ?? '')
                                  )}
                                optionMethod={(options: ClaimCustomOption[]) =>
                                  options.map((o) => (
                                    <option key={o.id} value={o.id?.toString()}>
                                      {o.name}
                                    </option>
                                  ))
                                }
                                component={FieldBSRenderSelect}
                                // validate={requiredField}
                              />
                            </div>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='appealDate'
                                label='Appeal Date'
                                // validate={requiredField}
                                parse={parseDatesForServer}
                                component={FieldBSRenderDate}
                              />
                            </div>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='appealResultId'
                                label='Appeal Result'
                                options={claimCustomOptions
                                  .filter(
                                    (x) =>
                                      x.type ===
                                      OptionTypeEnum.LitigationAppealResult
                                  )
                                  .sort((a, b) =>
                                    (a.name ?? '').localeCompare(b.name ?? '')
                                  )}
                                optionMethod={(options: ClaimCustomOption[]) =>
                                  options.map((o) => (
                                    <option key={o.id} value={o.id?.toString()}>
                                      {o.name}
                                    </option>
                                  ))
                                }
                                component={FieldBSRenderSelect}
                                // validate={requiredField}
                              />
                            </div>
                          </div>
                          <div className='w-100 d-flex'>
                            <div className={`${styles.column} pe-3`}>
                              <Field
                                name='recordOnAppealRecDate'
                                label='Record On Appeal Rec'
                                // validate={requiredField}
                                parse={parseDatesForServer}
                                component={FieldBSRenderDate}
                              />
                            </div>
                            {/* <div className={`${styles.column} pe-3 my-auto`}>
                            <Field
                              name='appealed'
                              type='checkbox'
                              label='Appealed'
                              checked={values.appealed === true}
                              component={FieldBSRenderCheckbox}
                            />
                          </div> */}
                          </div>
                          <div className='w-100 pe-3'>
                            <Field
                              name='issueDescription'
                              label='Issue Description'
                              type='text'
                              component={FieldBSRenderText}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.actionButtons}`}>
                      <Field
                        name='logChanges'
                        type='checkbox'
                        label='Add Change Log Note on Save'
                        checked={values.logChanges === true}
                        component={FieldBSRenderCheckbox}
                      />
                      <Button type='submit' variant='primary' size='sm'>
                        {submitting ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          'Save'
                        )}
                      </Button>
                      <Button
                        type='button'
                        variant='secondary'
                        size='sm'
                        onClick={() => {
                          form.reset();
                          setShow(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                );
              }}
            />
          </Container>
        </Modal.Body>
      </Modal>
      <AddJudge show={showAddJudge} setShow={setShowAddJudge}></AddJudge>
    </div>
  );
}
