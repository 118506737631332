import React from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { FaFileUpload } from 'react-icons/fa';
import { DocType } from '../../ApiTypes/DocType';
import { useAppSelector } from '../../Reducers/Store';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderFilePicker from '../Common/FieldBSRenderFilePicker';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';

import styles from './index.module.css';

type AddFileBasicFormType = {
  name: string;
  file: { file: File; name: string };
  type: string;
};

export default function AddFileBasic({
  show,
  setShow,
  types,
  handleAddDocument,
}: {
  show: boolean;
  types: { value: string; label: string }[];
  setShow: (show: boolean) => void;
  handleAddDocument: (values: AddFileBasicFormType) => Promise<void>;
}) {
  const onSubmit = (values: AddFileBasicFormType) => {
    return handleAddDocument(values);
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      aria-labelledby='Secure-Share-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Secure-Share-Form-modal'>
          <FaFileUpload className='pe-1' /> Upload File
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={{}}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='name'
                    type='text'
                    label='Name'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='file'
                    type='file'
                    validate={requiredField}
                    component={FieldBSRenderFilePicker}
                  />
                  <Field
                    name='fileType'
                    label='Type'
                    options={types}
                    optionMethod={(
                      options: { value: string; label: string }[]
                    ) =>
                      options.map((o) => (
                        <option key={o.value} value={o.value}>
                          {o.label}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <div className={`${styles.buttonDiv} mt-3`}>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
