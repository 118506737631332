import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { InsuranceCompany } from '../ApiTypes/InsuranceCompany';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { ReassignAccountRequest } from '../ApiTypes/ReassignAccountRequest';

class InsuranceCompanyApi {
  static create(ins: InsuranceCompany) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/insurancecompanies`,
      ins,
      getAuthHeader()
    );
  }
  static update(ins: InsuranceCompany) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/insurancecompanies/${ins.insurcoid}`,
      ins,
      getAuthHeader()
    );
  }
  static getForAnotherUser(userId: string) {
    return axios.get<InsuranceCompany[]>(
      `${API_URL}/api/insurancecompanies/${userId}/list`,
      getAuthHeader()
    );
  }
  static getById(insurCoId: number) {
    return axios.get<InsuranceCompany>(
      `${API_URL}/api/insurancecompanies/${insurCoId}`,
      getAuthHeader()
    );
  }
  static reassignAccount(request: ReassignAccountRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/insurancecompanies/reassignaccounts`,
      request,
      getAuthHeader()
    );
  }
}

export default InsuranceCompanyApi;
