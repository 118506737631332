import React, { useState, useEffect } from 'react';
import { Form as RFForm, Field } from 'react-final-form';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { FaCheck, FaUser } from 'react-icons/fa';
import styles from './index.module.css';
import { UserInsuranceCompanyAccess } from '../../ApiTypes/UserInsuranceCompanyAccess';
import UserInsuranceCompanyAccessApi from '../../Api/UserInsuranceCompanyAccessApi';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../Reducers/Store';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';

export default function AddEditAccessModal({
  show,
  setShow,
  id,
  userId,
  userName,
  handleSubmit,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  id: number | null;
  userId: string;
  userName: string;
  handleSubmit: (userId: string, insurCoId: number) => Promise<void>;
}) {
  const { insuranceCompanies } = useAppSelector((state) => state.reference);
  const [access, setAccess] = useState<UserInsuranceCompanyAccess | null>(null);

  useEffect(() => {
    getAccess();
  }, [id]);

  const getAccess = () => {
    if (id) {
      UserInsuranceCompanyAccessApi.getById(id)
        .then((res) => {
          setAccess(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get user access');
        });
    }
  };

  const onSubmit = (values: { insurCoId: number }) => {
    return handleSubmit(userId, values.insurCoId);
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='AddEditAccessModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='AddEditAccessModal-modal'>
          <FaUser className='pe-1' /> User Access
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p className='fw-bold text-center'>{`${
            id ? 'Edit' : 'Add'
          } User Access for User ID:  ${userId} - ${userName}`}</p>
          <p>
            current: {access?.insurCoId ?? ''} -{' '}
            {access?.insurCo?.fullname ?? ''}
          </p>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              insurCoId: access?.insurCoId,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='insurCoId'
                    label='Account'
                    options={insuranceCompanies
                      .filter((ins) => ins.active)
                      .sort((a, b) => a.shortname!.localeCompare(b.shortname!))}
                    optionMethod={(options: InsuranceCompany[]) =>
                      options.map((o) => (
                        <option key={o.insurcoid} value={o.insurcoid ?? ''}>
                          {`${o.accountnumber} - ${o.shortname}`}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <div className='d-flex justify-content-center align-items-center gap1Rem py-3'>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => setShow(false)}
                    >
                      Cancel
                    </Button>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : access ? (
                        'Update'
                      ) : (
                        'Add'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
