import React from 'react';
import { Button, Container, Modal, Form, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaUserNurse } from 'react-icons/fa';
import { EntityUser } from '../../ApiTypes/EntityUser';
import { NcmDashboardObj } from '../../ApiTypes/NcmDashboardObj';
import { useAppSelector } from '../../Reducers/Store';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import styles from './index.module.css';

export default function ReassignNcm({
  show,
  setShow,
  ncm,
  submitForm,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  ncm: NcmDashboardObj | null;
  submitForm: (values: {
    userId: string;
    claimNumber: string;
    current: string;
  }) => Promise<void>;
}) {
  const { nurseCaseManagers } = useAppSelector((state) => state.reference);

  const onSubmit = (values: {
    userId: string;
    claimNumber: string;
    current: string;
  }) => {
    return submitForm(values);
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='ReassignNcm-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='ReassignNcm-modal'>
          <FaUserNurse className='pe-1' /> Reassign
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              claimNumber: ncm?.claimNo,
              current: ncm
                ? nurseCaseManagers.find((x) => x.userId === ncm?.assignedTo)
                    ?.userName
                : '',
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='claimNumber'
                    label='Claim Number'
                    type='text'
                    readOnly
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='current'
                    label='Current Manager'
                    type='text'
                    readOnly
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='userId'
                    label='New Manager'
                    options={nurseCaseManagers}
                    optionMethod={(options: EntityUser[]) =>
                      options.map((o) => (
                        <option key={o.userId} value={o.userId}>
                          {o.userName}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <div className='d-flex justify-content-center align-items-center my-3'>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
