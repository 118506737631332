import React, { useState, useRef } from 'react';
import { Button } from 'react-bootstrap';

import HtmlEditor, { Toolbar, Item } from 'devextreme-react/html-editor';

import 'devextreme/dist/css/dx.light.css';

import { FaEnvelope } from 'react-icons/fa';
import { toast } from 'react-toastify';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import SettingApi from '../../Api/SettingApi';
import { ServerSetting } from '../../ApiTypes/ServerSetting';
import { EmailOptions } from './EmailOptions';
import Loading from '../Common/Loading';
import { NativeEventInfo } from 'devextreme/events';
import { ValueChangedInfo } from 'devextreme/ui/editor/editor';
import dxHtmlEditor from 'devextreme/ui/html_editor';

export default function ManageEmails() {
  const textRef = useRef<HtmlEditor | null>(null);
  const subjectRef = useRef<HtmlEditor | null>(null);
  const [fetching, setFetching] = useState<boolean>(false);
  const [selectedEmail, setSelectedEmail] = useState<string>('');

  const [emailSubjectSetting, setEmailSubjectSetting] =
    useState<ServerSetting | null>(null);
  const [emailTextSetting, setEmailTextSetting] =
    useState<ServerSetting | null>(null);

  const [emailText, setEmailText] = useState<string>('');
  const [emailSubject, setEmailSubject] = useState<string>('');

  var emailList = [
    'WC1 Submitted Email',
    'WC1 Claim Open Email',
    'WC1 Claim Denied Email',
  ];

  const handleEmailSelect = (emailName: string) => {
    setSelectedEmail(emailName);
    if (emailName) {
      setFetching(true);
      var emailSubjectName = emailName.replace('Email', 'Subject');
      Promise.all([getEmailText(emailName), getEmailSubject(emailSubjectName)])
        .then(() => setFetching(false))
        .catch(() => setFetching(false));
    }
  };

  const getEmailText = (emailName: string) => {
    return SettingApi.getSettingByName({ settingName: emailName })
      .then((res) => {
        setEmailTextSetting(res.data);
        setEmailText(res.data.value ?? '');
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get email');
      });
  };
  const getEmailSubject = (emailName: string) => {
    return SettingApi.getSettingByName({ settingName: emailName })
      .then((res) => {
        setEmailSubjectSetting(res.data);
        setEmailSubject(res.data.value ?? '');
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get email');
      });
  };

  const updateSetting = (setting: ServerSetting) => {
    return SettingApi.updateSetting(setting)
      .then((res) => {
        if (res.data.success) {
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update');
      });
  };

  const handleOptionChange = (value: string) => {
    var selection = textRef.current?.instance.getSelection();
    if (value && selection) {
      textRef.current?.instance.insertText(selection.index, `[${value}]`, {});
    }
  };

  const handleOptionChangeEnvelope = (value: string) => {
    var selection = subjectRef.current?.instance.getSelection();
    if (value && selection) {
      subjectRef.current?.instance.insertText(
        selection.index,
        `[${value}]`,
        {}
      );
    }
  };

  const onSubmit = () => {
    if (emailTextSetting && emailSubjectSetting) {
      setFetching(true);
      return Promise.all([
        updateSetting({ ...emailTextSetting, value: emailText }),
        updateSetting({ ...emailSubjectSetting, value: emailSubject }),
      ])
        .then((res) => {
          setFetching(false);
        })
        .catch(() => setFetching(false));
    }
  };

  return (
    <PageScaffold>
      <>{fetching && <Loading />}</>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaEnvelope className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Manage Emails</h1>
          </div>
        </div>
      </div>
      <div className='d-flex'>
        <fieldset>
          <legend>Emails</legend>

          {emailList.map((x) => (
            <div className='form-check'>
              <label className='form-check-label'>
                <input
                  key={x}
                  type='radio'
                  name='email'
                  className='form-check-input'
                  checked={selectedEmail === x}
                  onChange={(e) => {
                    const { checked } = e.target;
                    if (checked) {
                      handleEmailSelect(x);
                    }
                  }}
                />
                {x}
              </label>
            </div>
          ))}
        </fieldset>
        <div className={`${styles.centerContent}`}>
          <div className={`${styles.topWindow}`}>
            <HtmlEditor
              ref={textRef}
              height='500px'
              width='800px'
              value={emailText}
              onValueChanged={(
                e: NativeEventInfo<dxHtmlEditor, Event> & ValueChangedInfo
              ) => {
                setEmailText(e.value);
              }}
              valueType='html'
            >
              <Toolbar>
                <Item name='bold' />
                <Item name='italic' />
                <Item name='alignCenter' />
                <Item name='color' />
                <Item name='link' />
                <Item name='undo' />
                <Item name='redo' />
              </Toolbar>
            </HtmlEditor>
            <div className={`${styles.buttonColumn}`}>
              {EmailOptions.map((o) => (
                <Button
                  key={`1${o}`}
                  type='button'
                  variant='outline-primary'
                  size='sm'
                  onClick={() => handleOptionChange(o)}
                >
                  {o}
                </Button>
              ))}
            </div>
          </div>
          <hr />
          <div className={`${styles.topWindow}`}>
            <div className={`${styles.envelopeWindow}`}>
              <HtmlEditor
                ref={subjectRef}
                height='300px'
                width='800px'
                value={emailSubject}
                onValueChanged={(
                  e: NativeEventInfo<dxHtmlEditor, Event> & ValueChangedInfo
                ) => setEmailSubject(e.value)}
                valueType='html'
              />
            </div>
            <div className={`${styles.buttonColumn} ${styles.maxHeight300}`}>
              {EmailOptions.map((o) => (
                <Button
                  key={`2${o}`}
                  type='button'
                  variant='outline-primary'
                  size='sm'
                  onClick={() => handleOptionChangeEnvelope(o)}
                >
                  {o}
                </Button>
              ))}
            </div>
          </div>
          <div className={styles.buttonDiv}>
            <Button
              type='button'
              variant='primary'
              size='sm'
              onClick={onSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </PageScaffold>
  );
}
