import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  ExpandedState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import {
  FaCaretDown,
  FaCaretRight,
  FaCircle,
  FaEye,
  FaPlusCircle,
  FaTrash,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { DoctorsOffice } from '../../ApiTypes/DoctorsOffice';
import SimpleDoctorLocationTable from './SimpleDoctorLocationTable';

export default function PanelTable({
  data,
  handleRemove,
  handleAdd,
}: {
  data: DoctorsOffice[];
  handleRemove?: (l: DoctorsOffice) => void;
  handleAdd?: (l: DoctorsOffice) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<DoctorsOffice>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      size: 75,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center align-items-center gap1Rem'>
            {handleAdd && (
              <Button
                type='button'
                variant='primary'
                size='sm'
                title='Add to Panel'
                onClick={() => {
                  handleAdd(row.original);
                }}
              >
                <FaPlusCircle />
              </Button>
            )}
            {handleRemove && (
              <Button
                type='button'
                variant='danger'
                size='sm'
                title='Remove from Panel'
                onClick={() => {
                  handleRemove(row.original);
                }}
              >
                <FaTrash />
              </Button>
            )}
          </div>
        );
      },
    },
    {
      header: '',
      id: 'expander',
      size: 25,
      cell: ({ row }) => (
        <div>
          {row.getCanExpand() ? (
            <Button
              size='sm'
              variant='outline-primary'
              title='Expand'
              onClick={row.getToggleExpandedHandler()}
            >
              {row.getIsExpanded() ? <FaCaretDown /> : <FaCaretRight />}
            </Button>
          ) : (
            <FaCircle />
          )}
        </div>
      ),
    },
    {
      header: 'Office Name',
      accessorKey: 'officeName',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      expanded,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
    getRowCanExpand: (row) =>
      row.original.doctorLocations !== null &&
      row.original.doctorLocations.length > 0,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
      createChildTable={SimpleDoctorLocationTable}
      subRowProp={'doctorLocations'}
    />
  );
}
