import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { FaPlusCircle } from 'react-icons/fa';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { useAppSelector } from '../../Reducers/Store';
import LegalApi from '../../Api/LegalApi';
import { Attorney } from '../../ApiTypes/Attorney';
import { LawFirm } from '../../ApiTypes/LawFirm';
import { centsValidation, requiredField } from '../../Utils/FieldValidation';
import { AttorneyType } from '../../ApiTypes/AttorneyType';
import { AttorneyToClaimant } from '../../ApiTypes/AttorneyToClaimant';

import styles from './index.module.css';
import { AssignAttorneyToClaimantFormType } from '../../Types/AssignAttorneyToClaimantFormType';
import { toast } from 'react-toastify';
import { FormApi } from 'final-form';
import { parseDatesForServer } from '../../Utils';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import { formatNumbers, cleanMoney } from '../../Utils/InputFormatters';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';

export default function AssignAttorneyToClaimModal({
  show,
  setShow,
  assignTo,
  assignAttorneyToClaimant,
  handleNewLawFirmFromAssign,
  handleNewAttorneyFromAssign,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  assignTo: AssignAttorneyToClaimantFormType | null;
  assignAttorneyToClaimant: (data: AttorneyToClaimant) => Promise<void>;
  handleNewLawFirmFromAssign: () => void;
  handleNewAttorneyFromAssign: (id: number) => void;
}) {
  const [attorneys, setAttorneys] = useState<Attorney[]>([]);

  const { claim, claimant } = useAppSelector(
    (state) => state.currentClaimReducer
  );

  const { lawFirms, attorneyTypes } = useAppSelector(
    (state) => state.reference
  );

  let formInstance: FormApi<
    AssignAttorneyToClaimantFormType,
    Partial<AssignAttorneyToClaimantFormType>
  >;

  useEffect(() => {
    handleInitialState();
  }, [assignTo]);

  const handleInitialState = () => {
    if (assignTo && assignTo.firmId) {
      getAttorneysForFirm(assignTo.firmId).then((res) => {
        if (assignTo.attorneyId) {
          formInstance.change('attorneyId', assignTo.attorneyId);
        }
      });
    }
  };

  const getAttorneysForFirm = (id: number) => {
    return LegalApi.getAttorneysForFirm(id)
      .then((res) => {
        setAttorneys(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFirmChange = (id: number) => {
    if (id) {
      getAttorneysForFirm(id);
    } else {
      setAttorneys([]);
    }
  };

  const onSubmit = (values: AssignAttorneyToClaimantFormType) => {
    const copy = { ...values };

    const attorney = attorneys.find((a) => a.attorneyId === +copy.attorneyId);
    const type = attorneyTypes.find((t) => t.attorneyTypeId === +copy.typeId!);

    let assign: AttorneyToClaimant = {
      ...copy,

      attorney: attorney!,
      type: type!,
      dateHired: copy.dateHired ? copy.dateHired : null,
      dateTerminated: copy.dateTerminated ? copy.dateTerminated : null,
      lienAmount: copy.lienAmount ? copy.lienAmount : null,
    };

    // console.log({ assign });

    return assignAttorneyToClaimant(assign);
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='Assign-Attorney-To-Claim-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Assign-Attorney-To-Claim-Form-modal'
        >
          <FaPlusCircle className='pe-1' /> Assign Attorney to Claim
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <p>Claim No: {claim?.claimNo}</p>
          <p>
            Claimant: {claimant?.firstName} {claimant?.lastName}
          </p>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={assignTo ?? {}}
            render={({ handleSubmit, form, values, submitting }) => {
              formInstance = form;
              return (
                <Form onSubmit={handleSubmit}>
                  <div className={`${styles.addRow}`}>
                    <Field
                      name='firmId'
                      label='Name'
                      options={lawFirms}
                      optionMethod={(options: LawFirm[]) =>
                        options.map((o) => (
                          <option key={o.firmId} value={o.firmId}>
                            {o.firmName}
                          </option>
                        ))
                      }
                      onChange={handleFirmChange}
                      component={FieldBSRenderSelect}
                      validate={requiredField}
                    />
                    <Button
                      type='button'
                      variant='primary'
                      size='sm'
                      onClick={() => handleNewLawFirmFromAssign()}
                      className='button-icon-text'
                    >
                      <FaPlusCircle /> New
                    </Button>
                  </div>
                  <div className={`${styles.addRow}`}>
                    <Field
                      name='attorneyId'
                      label='Attorney'
                      options={attorneys}
                      optionMethod={(options: Attorney[]) =>
                        options.map((o) => (
                          <option key={o.attorneyId} value={o.attorneyId}>
                            {o.lastName + ', ' + o.firstName}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                      validate={requiredField}
                    />
                    <Button
                      type='button'
                      variant='primary'
                      size='sm'
                      onClick={() => {
                        if (!values.firmId) {
                          toast.error(
                            'Please select a firm before creating an attorney'
                          );
                        } else {
                          handleNewAttorneyFromAssign(values.firmId);
                        }
                      }}
                      className='button-icon-text'
                    >
                      <FaPlusCircle /> New
                    </Button>
                  </div>
                  <Field
                    name='typeId'
                    label='Type'
                    options={attorneyTypes}
                    optionMethod={(options: AttorneyType[]) =>
                      options.map((o) => (
                        <option key={o.attorneyTypeId} value={o.attorneyTypeId}>
                          {o.attorneyTypeName}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                    validate={requiredField}
                  />
                  <div className='d-flex gap1Rem align-items-center'>
                    <Field
                      name='dateHired'
                      label='Hire Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                      validate={requiredField}
                    />
                    <Field
                      name='dateTerminated'
                      label='Terminated Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                  </div>
                  <div className='d-flex gap1Rem align-items-center'>
                    <Field
                      name='isLein'
                      type='checkbox'
                      label='Lein'
                      checked={values.isLein === true}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='lienAmount'
                      label='Amount'
                      type='text'
                      disabled={values.isLein !== true}
                      format={formatNumbers}
                      parse={cleanMoney}
                      validate={centsValidation}
                      component={FieldBSRenderMoney}
                    />
                  </div>
                  <div className='d-flex justify-content-around mt-3'>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
