import axios from "axios";
import { API_URL, getAuthHeader } from ".";
import { Address } from "../ApiTypes/Address";


class AddressApi {
  static getAddressById(id: number) {
    return axios.get<Address>(`${API_URL}/api/addresses/${id}`, getAuthHeader());
  }
}

export default AddressApi;