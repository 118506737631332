import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ReportRequest } from '../../ApiTypes/ReportRequest';
import ClaimFinancialHistoryModal from '../ClaimFinancialHistory/ClaimFinancialHistoryModal';
import PaymentReportModal from '../PaymentReport/PaymentReportModal';
import { AvailableReport, FileFormat } from '../../ApiTypes/ReportRequest';
import { DocumentType } from '../../ApiTypes/DocumentType';
import {
  getCrystalReport,
  getDxReport,
  useDocViewModal,
} from '../DocViewModal/useDocViewModal';
import DocViewModal from '../DocViewModal/DocViewModal';
import {
  DevExpressReportRequest,
  DxAvailableReports,
  ExportTypes,
} from '../../ApiTypes/DevExpressReportRequest';

import { Msg } from '../Common/AddPdfDocumentToClaim';
import { useAppSelector } from '../../Reducers/Store';
import { toast } from 'react-toastify';

export default function ClaimReportButton({
  setAttach,
  setShowWc1ReportOptions,
}: {
  setAttach: (attach: boolean) => void;
  setShowWc1ReportOptions: (show: boolean) => void;
}) {
  const { claimNumber } = useParams();
  const { claim } = useAppSelector((state) => state.currentClaimReducer);
  const { userModel } = useAppSelector((state) => state.user);
  const [showPaymentReport, setShowPaymentReport] = useState<boolean>(false);
  const {
    showDocViewModal,
    fileDownload,
    setShowDocViewModal,
    setFileDownload,
  } = useDocViewModal();

  const dxRequest: DevExpressReportRequest = {
    accountNumber: '',
    claimNumber: '',
    employerId: null,
    fromDate: null,
    asOfDate: null,
    insurCoId: claim?.insurcoid ?? 1,
    startYear: 0,
    endYear: 0,
    external: false,
    openOnly: false,
    report: DxAvailableReports.rptWCLossRun,
    severity: '',
    status: '',
    toDate: null,
    userId: '',
    minReserveAmount: 0,
    caseManagementId: 0,
    letterId: 0,
    policyId: 0,
    reserveWorksheetId: 0,
    eDITransactionId: 0,
    keyDocRequestId: 0,
    searchText: '',
    onlyClearedChecks: false,
    topInjuriesReportType: 0,
    topInjuriesReportFilter: '',
    workStatusId: 0,
    maskSSN: false,
    wC4Id: 0,
    incidentOnly: false,
    useEOMData: true,
    useClaimDataForWc1: false,
    showAdjusterInfo: true,
    treatyYear: 0,
    exportType: ExportTypes.PDF,
  };

  const [showFinancialHistory, setShowFinancialHistory] =
    useState<boolean>(false);

  const request: ReportRequest = {
    accountNumber: '',
    claimNumber: '',
    checkNumber: '',
    employerId: null,
    exportFormat: FileFormat.PortableDocFormat,
    fromDate: null,
    insurCoId: null,
    report: AvailableReport.PaymentHistory,
    severity: '',
    status: '',
    toDate: null,
    userId: '',
    claimsByAccountNumberAndTreatyYearCount: 0,
    claimsByEmployerAndTreatyYearCount: 0,
    treatyYear: null,
    treatyYearTo: null,
    genericCheckReportName: null,
    paymentBatchIds: null,
    fromName: '',
    openOnly: false,
    title: '',
  };

  const displayToast = (
    docTypeId: number,
    fileName: string,
    claimNumber: string,
    base64Data: string
  ) => {
    toast(<Msg />, {
      autoClose: false,
      closeOnClick: false,
      hideProgressBar: true,
      draggable: false,
      data: {
        base64Data,
        fileName,
        claimNumber,
        docTypeId,
        text: `Would you like to save a copy of ${fileName} to the Documents tab?"`,
      },
    });
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant='light'
        id='claimReports'
        className='button-icon-text'
      >
        Claim Reports
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          as='button'
          onClick={() => {
            if (claimNumber) {
              request.claimNumber = claimNumber;
              request.report = AvailableReport.PaymentHistory;
              getCrystalReport(request, setFileDownload, setShowDocViewModal);
            }
          }}
        >
          Payment History
        </Dropdown.Item>
        <Dropdown.Item
          as='button'
          onClick={() => {
            if (claimNumber) {
              request.claimNumber = claimNumber;
              request.report = AvailableReport.Notes;
              getCrystalReport(request, setFileDownload, setShowDocViewModal);
            }
          }}
        >
          Notes
        </Dropdown.Item>
        <Dropdown.Item
          as='button'
          onClick={() => {
            if (claimNumber) {
              request.claimNumber = claimNumber;
              request.report = AvailableReport.ClaimStatus;
              getCrystalReport(request, setFileDownload, setShowDocViewModal);
            }
          }}
        >
          Claim Status Report
        </Dropdown.Item>
        <Dropdown.Item
          as='button'
          onClick={() => {
            if (claimNumber) {
              request.claimNumber = claimNumber;
              request.report = AvailableReport.ReservesHistory;
              getCrystalReport(request, setFileDownload, setShowDocViewModal);
            }
          }}
        >
          Reserves History - Formatted
        </Dropdown.Item>

        <Dropdown drop='end'>
          <Dropdown.Toggle
            variant='light'
            id='claimReports'
            className='button-icon-text'
          >
            Board Forms
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              as='button'
              onClick={() => {
                if (claimNumber) {
                  dxRequest.claimNumber = claimNumber;
                  dxRequest.insurCoId = claim?.insurcoid ?? 1;
                  dxRequest.userId = userModel?.user?.userId ?? '';
                  dxRequest.report = DxAvailableReports.rptWC6;
                  getDxReport(dxRequest, setFileDownload, setShowDocViewModal);
                }
              }}
            >
              WC-6
            </Dropdown.Item>
            <Dropdown.Item
              as='button'
              onClick={() => {
                if (claimNumber) {
                  dxRequest.claimNumber = claimNumber;
                  dxRequest.insurCoId = claim?.insurcoid ?? 1;
                  dxRequest.report = DxAvailableReports.rptWC207;
                  getDxReport(dxRequest, setFileDownload, setShowDocViewModal);
                }
              }}
            >
              WC-207
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown.Item
          as='button'
          onClick={() => {
            if (claimNumber) {
              dxRequest.claimNumber = claimNumber;
              dxRequest.insurCoId = claim?.insurcoid ?? 1;
              dxRequest.report = DxAvailableReports.rptExccessRecoveryReq;
              getDxReport(dxRequest, setFileDownload, setShowDocViewModal);
            }
          }}
        >
          Excess Recovery Request
        </Dropdown.Item>
        <Dropdown.Item
          as='button'
          onClick={async () => {
            if (claimNumber) {
              dxRequest.claimNumber = claimNumber;
              dxRequest.insurCoId = claim?.insurcoid ?? 1;
              dxRequest.report = DxAvailableReports.rptGAAckLetterFederated;
              const success = await getDxReport(
                dxRequest,
                setFileDownload,
                setShowDocViewModal
              );
              const docTypeId = DocumentType.Misc;
              const fileName = 'Federated New Claim Letter.pdf';
              if (success) {
                displayToast(
                  docTypeId,
                  fileName,
                  claimNumber,
                  success.reportBytes
                );
              }
            }
          }}
        >
          Federated Letter
        </Dropdown.Item>
        <Dropdown.Item
          as='button'
          onClick={async () => {
            if (claimNumber) {
              dxRequest.claimNumber = claimNumber;
              dxRequest.report = DxAvailableReports.rptMileageClaim;
              const success = await getDxReport(
                dxRequest,
                setFileDownload,
                setShowDocViewModal
              );
              const docTypeId = DocumentType.Misc;
              const fileName = 'Mileage Claim Form.pdf';
              if (success) {
                displayToast(
                  docTypeId,
                  fileName,
                  claimNumber,
                  success.reportBytes
                );
              }
            }
          }}
        >
          Mileage Form
        </Dropdown.Item>
        <Dropdown.Item
          as='button'
          onClick={() => {
            if (claimNumber) {
              dxRequest.claimNumber = claimNumber;
              dxRequest.report = DxAvailableReports.rptNewClaimReport;
              getDxReport(dxRequest, setFileDownload, setShowDocViewModal);
            }
          }}
        >
          New Claim Report
        </Dropdown.Item>
        <Dropdown.Item
          as='button'
          onClick={() => {
            if (claimNumber) {
              dxRequest.claimNumber = claimNumber;
              dxRequest.report = DxAvailableReports.rptClaimAuditReport;
              getDxReport(dxRequest, setFileDownload, setShowDocViewModal);
            }
          }}
        >
          Audit Report
        </Dropdown.Item>
        <Dropdown.Item
          as='button'
          onClick={() => {
            setShowPaymentReport(true);
          }}
        >
          Payment Report
        </Dropdown.Item>
        <Dropdown.Item
          as='button'
          onClick={() => {
            setShowFinancialHistory(true);
          }}
        >
          Financial History
        </Dropdown.Item>
      </Dropdown.Menu>
      {showPaymentReport && (
        <PaymentReportModal
          show={showPaymentReport}
          setShow={setShowPaymentReport}
          claimNumber={claimNumber ?? ''}
        />
      )}
      <DocViewModal
        show={showDocViewModal}
        setShow={setShowDocViewModal}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
      {showFinancialHistory && (
        <ClaimFinancialHistoryModal
          setShow={setShowFinancialHistory}
          show={showFinancialHistory}
          claimNumber={claimNumber ?? ''}
        />
      )}
    </Dropdown>
  );
}
