import React, { useState, useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import { saveAs } from 'file-saver';
import PdfThumbnail from './PdfThumbnail';
import styles from './index.module.css';
import { Button } from 'react-bootstrap';
import { FaArrowLeft, FaArrowRight, FaFileDownload } from 'react-icons/fa';
import { base64ToBlob } from '../../Utils';
import DocumentsApi from '../../Api/DocumentsApi';
import { toast } from 'react-toastify';

const PdfComponent = ({
  base64Data,
  fileName,
}: {
  base64Data: string;
  fileName: string;
}) => {
  const [page, setPage] = useState<number>(1);
  const canvasRef = useRef(null);

  const { pdfDocument, pdfPage } = usePdf({
    file: JSON.parse(
      JSON.stringify(`data:application/pdf;base64,${base64Data}`)
    ),
    page,
    canvasRef,
    scale: 2,
  });

  const downloadPdf = () => {
    const blob = base64ToBlob(base64Data, 'application/pdf');

    saveAs(blob, fileName);
  };

  const handlePrevious = () => {
    setPage(page - 1);
  };

  const handleNext = () => {
    setPage(page + 1);
  };

  const renderStepPagination = (page: number, pages: number) => {
    if (pages === 1) {
      return (
        <div className={styles.pdfNavigation}>
          <Button
            type='button'
            onClick={downloadPdf}
            variant='outline-primary'
            className='button-icon-text'
            style={{
              width: 'auto',
            }}
            size='sm'
            title='Download'
          >
            <FaFileDownload /> Download
          </Button>
        </div>
      );
    }
    return (
      <div className={styles.pdfNavigation}>
        <Button
          type='button'
          onClick={handlePrevious}
          disabled={page === 1}
          variant='outline-primary'
          className='button-icon-text'
          size='sm'
        >
          <FaArrowLeft /> Previous
        </Button>
        <Button
          type='button'
          onClick={downloadPdf}
          variant='outline-primary'
          className='button-icon-text'
          style={{
            width: 'auto',
          }}
          size='sm'
          title='Download'
        >
          <FaFileDownload />
        </Button>
        <Button
          type='button'
          onClick={handleNext}
          disabled={page === pdfDocument?.numPages}
          variant='outline-primary'
          className='button-icon-text'
          size='sm'
        >
          Next
          <FaArrowRight />
        </Button>
      </div>
    );
  };

  const onThumbnailClick = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const renderPagination = (page: number, pages: number) => {
    if (pages === 1) {
      return <div />;
    }
    return (
      <div className={styles.pdfThumbnailContainer}>
        {renderThumbnails(pages)}
      </div>
    );
  };
  const renderThumbnails = (pages: number) => {
    const thumbnails = [];
    for (let i = 1; i < pages + 1; i++) {
      thumbnails.push(
        <PdfThumbnail
          key={i}
          pageNumber={i}
          currentPageNumber={page}
          base64Data={base64Data}
          onThumbnailClick={onThumbnailClick}
        />
      );
    }
    return thumbnails;
  };
  return (
    <div
      className={
        pdfDocument?.numPages === 1 ? styles.width : styles.width_navigation
      }
      // style={
      //   canvasRef.current
      //     ? {
      //         width: (
      //           canvasRef.current as HTMLCanvasElement
      //         ).style.getPropertyValue('width'),
      //       }
      //     : {}
      // }
    >
      {!pdfDocument && <p className={styles.noResults}>PDF Not Available</p>}
      {pdfDocument &&
        pdfDocument.numPages &&
        renderStepPagination(page, pdfDocument.numPages)}
      <div className={styles.pdfNavBesidePdf}>
        {pdfDocument &&
          pdfDocument.numPages &&
          renderPagination(page, pdfDocument.numPages)}
        <div style={{ width: '100%', padding: '5px' }}>
          <canvas ref={canvasRef} />
        </div>
      </div>
    </div>
  );
};

export default PdfComponent;
