import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { Claimant } from '../ApiTypes/Claimant';
import { ClaimantDependent } from '../ApiTypes/ClaimantDependent';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class ClaimantApi {
  static getClaimantById(id: number) {
    return axios.get<Claimant>(
      `${API_URL}/api/claimants/${id}`,
      getAuthHeader()
    );
  }
  static createClaimant(claimant: Claimant) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claimants`,
      claimant,
      getAuthHeader()
    );
  }
  static updateClaimant(claimant: Claimant) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claimants/${claimant.claimantId}`,
      claimant,
      getAuthHeader()
    );
  }
  static getClaimantDependantsForClaim(claimNumber: string) {
    return axios.get<ClaimantDependent[]>(
      `${API_URL}/api/claims/${claimNumber}/claimants/dependents`,
      getAuthHeader()
    );
  }
  static createClaimantDependantForClaim(
    claimNumber: string,
    dependent: ClaimantDependent
  ) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/claimants/dependents`,
      dependent,
      getAuthHeader()
    );
  }
  static deleteClaimantDependantFromClaim(
    claimNumber: string,
    dependentId: number
  ) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/claimants/dependents/${dependentId}`,
      getAuthHeader()
    );
  }
  static getClaimantByClaimNumber(claimNumber: string) {
    return axios.get<Claimant>(
      `${API_URL}/api/claims/${claimNumber}/claimant`,
      getAuthHeader()
    );
  }
}

export default ClaimantApi;
