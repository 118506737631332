import React from 'react';
import { Field } from 'react-final-form';
import { Employer } from '../../ApiTypes/Employer';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { Location } from '../../ApiTypes/Location';
import { State } from '../../ApiTypes/State';
import { useAppSelector } from '../../Reducers/Store';
import { requiredField, zipLength } from '../../Utils/FieldValidation';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import styles from './index.module.css';

export default function Wc1EmployerForm({
  states,
  values,
  employers,
  locations,
  getEmployersForInsuranceCompany,
  getLocationsByEmployerId,
}: {
  states: State[];
  employers: Employer[];
  values: any;
  locations: Location[];
  getEmployersForInsuranceCompany: (id: number) => void;
  getLocationsByEmployerId: (id: number) => void;
}) {
  const { insuranceCompanies } = useAppSelector((state) => state.reference);

  return (
    <div className='d-flex'>
      <div className='w-50 pe-2'>
        <Field
          name='insCoId'
          label='Insurance Company/Self Insured Name'
          options={insuranceCompanies}
          onChange={(id: string) => getEmployersForInsuranceCompany(+id)}
          optionMethod={(options: InsuranceCompany[]) =>
            options.map((o) => (
              <option key={o.insurcoid} value={o.insurcoid}>
                {`${o.accountnumber} - ${o.fullname}`}
              </option>
            ))
          }
          validate={requiredField}
          component={FieldBSRenderSelect}
        />
        <Field
          name='employerName'
          type='text'
          label='Employer Name'
          component={FieldBSRenderText}
        />
        <Field
          name='employerId'
          label='Employer'
          options={employers}
          onChange={(id: string) => getLocationsByEmployerId(+id)}
          optionMethod={(options: Employer[]) =>
            options.map((o) => (
              <option key={o.employerId} value={o.employerId}>
                {o.name}
              </option>
            ))
          }
          validate={requiredField}
          component={FieldBSRenderSelect}
        />
        <Field
          name='location'
          label='Location Name'
          options={locations}
          optionMethod={(options: Location[]) =>
            options.map((o) => (
              <option key={o.locationId} value={o.locationId}>
                {o.locationName}
              </option>
            ))
          }
          component={FieldBSRenderSelect}
        />
        <Field
          name='employerNatureOfBusiness'
          type='text'
          label='Nature of Business'
          component={FieldBSRenderText}
        />
        <Field
          name='supervisorName'
          type='text'
          label='Supervisor Name'
          component={FieldBSRenderText}
        />
      </div>
      <div className='w-50 ps-2'>
        <Field
          name='employerAddress'
          type='text'
          label='Address 1'
          component={FieldBSRenderText}
        />
        <Field
          name='employerAddress2'
          type='text'
          label='Address 2'
          component={FieldBSRenderText}
        />
        <div className={`d-flex ${styles.address3} ${styles.address3children}`}>
          <Field
            name='employerCity'
            type='text'
            label='City'
            component={FieldBSRenderText}
          />
          <Field
            name='employerState'
            label='State'
            options={states}
            optionMethod={(options: State[]) =>
              options.map((o) => (
                <option key={o.stateAbbr} value={o.stateAbbr}>
                  {o.stateAbbr}
                </option>
              ))
            }
            component={FieldBSRenderSelect}
          />
          <Field
            name='employerZip'
            type='text'
            label='Zip'
            validate={zipLength}
            component={FieldBSRenderText}
            maxLength={5}
          />
        </div>

        <Field
          name='employerPhone'
          type='text'
          label='Supervisor Phone'
          component={FieldBSRenderText}
        />
        <Field
          name='employerEmail'
          type='text'
          label='Supervisor Email'
          component={FieldBSRenderText}
        />
      </div>
    </div>
  );
}
