export const EmailOptions = [
  'Claimant_First_Name',
  'Claimant_Middle_Initial',
  'Claimant_Last_Name',
  'Claimant_Phone_Number',
  'Claimant_Email',
  'Date_Of_Injury',
  'Requestor',
  'Requestor_Email',
  'Claim_Number',
  'Confirmation_Number',
  'Comments',
  'Cause',
  'Adjuster',
  'Adjuster_Email',
  'Adjuster_Phone_Number',
  'Member_Name',
];
