// import React from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { FaFileUpload } from 'react-icons/fa';
import { DocType } from '../../ApiTypes/DocType';
import { useAppSelector } from '../../Reducers/Store';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderFilePicker from '../Common/FieldBSRenderFilePicker';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { AddFileFormType } from './AddFileFormType';

import styles from './index.module.css';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import { parseDatesForServer } from '../../Utils';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import { format } from 'date-fns';
import { ClaimTypes } from '../../ApiTypes/ClaimTypeConfiguration';
// import { FormApi } from 'final-form';

export default function AddFile({
  show,
  setShow,
  claimNumber,
  handleAddDocument,
  claimType,
}: {
  show: boolean;
  claimNumber: string;
  setShow: (show: boolean) => void;
  handleAddDocument: (values: AddFileFormType) => Promise<void>;
  claimType: number;
}) {
  const { docTypes } = useAppSelector((state) => state.reference);
  // let formInstance: FormApi<AddFileFormType, Partial<AddFileFormType>>;

  const onSubmit = (values: AddFileFormType) => {
    return handleAddDocument(values);
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      aria-labelledby='Add-File-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Add-File-Form-modal'>
          <FaFileUpload className='pe-1' /> Upload File
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={{
              claimNumber,
              docTypeId: 2,
              action: 'None',
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              // formInstance = form;
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='docTypeId'
                    label='Type'
                    options={docTypes
                      .filter(
                        (c) =>
                          c.claimTypeId === null || c.claimTypeId === claimType
                      )
                      .sort((a, b) =>
                        a.description!.localeCompare(b.description!)
                      )}
                    optionMethod={(options: DocType[]) =>
                      options.map((o) => (
                        <option key={o.docTypeId} value={o.docTypeId}>
                          {o.description}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='doc'
                    type='file'
                    validate={requiredField}
                    component={FieldBSRenderFilePicker}
                  />
                  <Field
                    name='newName'
                    label='Re-Name/Description'
                    type='text'
                    component={FieldBSRenderText}
                  />
                  {claimType === ClaimTypes.WorkersComp && (
                    <div className='d-flex gap1Rem'>
                      <Field
                        name='serviceFrom'
                        label='Service From'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                        onChange={(value: string) => {
                          form.change('serviceTo', value);
                        }}
                        onBlur={(value: string) => {
                          if (value) {
                            form.change(
                              'serviceTo',
                              format(new Date(value), 'yyyy-MM-dd')
                            );
                          }
                        }}
                      />
                      <Field
                        name='serviceTo'
                        label='Service To'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                    </div>
                  )}
                  <div className='d-flex justify-content-around'>
                    <Field
                      name='action'
                      type='radio'
                      label='Approve'
                      value='Accepted'
                      checked={values.action === 'Accepted'}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='action'
                      type='radio'
                      label='Bill Review'
                      value='Sent to Bill Review'
                      checked={values.action === 'Sent to Bill Review'}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='action'
                      type='radio'
                      label='Straight Pay'
                      value='Sent To Straight Pay'
                      checked={values.action === 'Sent To Straight Pay'}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='action'
                      type='radio'
                      label='None'
                      value='None'
                      checked={values.action === 'None'}
                      component={FieldBSRenderCheckbox}
                    />
                  </div>
                  <div className={`${styles.buttonDiv} mt-3`}>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
