import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import {
  ClaimReservesWorksheet,
  ReserveWorksheetStatus,
} from '../../../ApiTypes/ClaimReservesWorksheet';
import { displayDateOnly } from '../../../Utils';
import accounting from 'accounting';
import { FaEdit, FaEye } from 'react-icons/fa';

export default function WorksheetTable({
  getReserveBucketsForClaim,
  getClaimPaymentTotals,
  getClaimReservesWorksheets,
  worksheets,
  setShowWorksheetModal,
  setSelectedWorksheet,
  handleViewWorksheet,
  selectedWorksheet,
}: {
  worksheets: ClaimReservesWorksheet[];
  getReserveBucketsForClaim: () => void;
  getClaimPaymentTotals: () => void;
  getClaimReservesWorksheets: () => void;
  setShowWorksheetModal: (show: boolean) => void;
  setSelectedWorksheet: (sheet: ClaimReservesWorksheet | null) => void;
  selectedWorksheet: ClaimReservesWorksheet | null;
  handleViewWorksheet: (sheet: ClaimReservesWorksheet) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => worksheets, [worksheets]);

  const columnData: ColumnDef<ClaimReservesWorksheet>[] = [
    {
      header: '',
      id: 'worksheet',
      enableSorting: false,
      enableColumnFilter: false,
      size: 75,
      maxSize: 75,
      cell: (d) => {
        return (
          <div
            className={`d-flex justify-content-around ${
              d.row.original.id == selectedWorksheet?.id ? 'orange' : ''
            }`}
          >
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='View'
              onClick={() => {
                setSelectedWorksheet(d.row.original);
                handleViewWorksheet(d.row.original);
              }}
            >
              <FaEye />
            </Button>
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='Edit'
              onClick={() => {
                setSelectedWorksheet(d.row.original);
                setShowWorksheetModal(true);
              }}
            >
              <FaEdit />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Date Submitted',
      accessorKey: 'dateSubmitted',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 125,
    },
    {
      header: 'New Medical',
      accessorFn: (d) => `${d.medicalRoundedOverride}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
      cell: (d) => accounting.formatMoney(d.getValue() as number),
    },
    {
      header: 'New Indemnity',
      accessorFn: (d) => `${d.indemnityRoundedOverride}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
      cell: (d) => accounting.formatMoney(d.getValue() as number),
    },
    {
      header: 'New Legal',
      accessorFn: (d) => `${d.legalRoundedOverride}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
      cell: (d) => accounting.formatMoney(d.getValue() as number),
    },
    {
      header: 'New Expense',
      accessorFn: (d) => `${d.expenseRoundedOverride}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesStringSensitive',
      cell: (d) => accounting.formatMoney(d.getValue() as number),
    },
    {
      header: 'Status',
      accessorFn: (d) => (d.status ? ReserveWorksheetStatus[d.status] : 'none'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 75,
      // cell: d => accounting.formatMoney(d.getValue() as number),
    },
  ];

  const columns = useMemo(() => columnData, [selectedWorksheet]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        selectableRow={true}
        highlightRow={true}
      />
    </div>
  );
}
