import { FormApi } from 'final-form';
import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaMoneyCheckAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import LegalApi from '../../Api/LegalApi';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { Settlement } from '../../ApiTypes/Settlement';
import { useAppSelector } from '../../Reducers/Store';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import EditEdiModal from '../EdiForms/EditEdiModal';
import PageScaffold from '../PageScaffold/PageScaffold';
import ApproveSettlementTable from './ApproveSettlementTable';
import styles from './index.module.css';
import ViewSettlementModal from './ViewSettlementModal';
import { EmailDocumentsRequest } from '../../ApiTypes/EmailDocumentsRequest';
import EmailDocumentsModal from '../EmailDocuments/EmailDocumentsModal';
import UserEmailListApi from '../../Api/UserEmailListApi';
import DocumentsApi from '../../Api/DocumentsApi';
import { UserEmailList } from '../../ApiTypes/UserEmailList';

export default function ApproveSettlements() {
  const { insuranceCompaniesForUser, insuranceCompanies } = useAppSelector(
    (state) => state.reference
  );

  const { userModel } = useAppSelector((state) => state.user);

  const [data, setData] = useState<Settlement[]>([]);
  const [selectedSettlement, setSelectedSettlement] =
    useState<Settlement | null>(null);
  const [showViewSettlement, setShowViewSettlement] = useState<boolean>(false);
  const [ediTransaction, setEdiTransaction] = useState<EdiTransaction | null>(
    null
  );
  const [emailDocumentsRequest, setEmailDocumentsRequest] =
    useState<EmailDocumentsRequest | null>(null);

  const [userEmailList, setUserEmailList] = useState<UserEmailList[]>([]);

  const [showEmailDocumentsModal, setShowEmailDocumentsModal] =
    useState<boolean>(false);

  let formInstance: FormApi<
    { status: string; insurCoId: string },
    Partial<{ status: string; insurCoId: string }>
  >;
  useEffect(() => {
    getUserEmailList();
  }, [userModel]);

  const getUserEmailList = () => {
    if (userModel?.user?.userId) {
      UserEmailListApi.getEmailList(userModel?.user?.userId)
        .then((res) => {
          setUserEmailList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onSubmit = (values: { status: string; insurCoId: string }) => {
    return LegalApi.getSettlementsToApprove(+values.insurCoId, +values.status)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get settlements');
      });
  };

  const refetch = () => {
    formInstance.submit();
  };

  const approveSettlement = (settlement: Settlement) => {
    toast('Approving', {
      type: 'info',
      toastId: settlement.settlementId,
    });
    LegalApi.approveSettlement(settlement.settlementId)
      .then((res) => {
        setEdiTransaction(res.data.transaction!);
        setEmailDocumentsRequest(res.data);
        toast.update(settlement.settlementId, {
          type: 'success',
          render: 'Approved',
        });
        refetch();
      })
      .catch((err) => {
        console.log(err);
        toast.update(settlement.settlementId, {
          type: 'error',
          render: 'Failed to approve settlement',
        });
      });
  };

  const denySettlement = (settlement: Settlement) => {
    toast('Updating Status', {
      type: 'info',
      toastId: settlement.settlementId,
    });
    LegalApi.updateSettlementStatus({
      settlementId: settlement.settlementId,
      status: 2,
    })
      .then((res) => {
        if (res.data.success) {
          toast.update(settlement.settlementId, {
            type: 'success',
            render: 'Status Updated',
          });
          refetch();
        } else {
          toast.update(settlement.settlementId, {
            type: 'error',
            render: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.update(settlement.settlementId, {
          type: 'error',
          render: 'Failed to update status',
        });
      });
  };

  const closeEdiModal = () => {
    setEdiTransaction(null);
    setShowEmailDocumentsModal(true);
  };

  const handleEmailDocuments = (values: EmailDocumentsRequest) => {
    return DocumentsApi.emailDocs(values)
      .then((res) => {
        if (res.data.success) {
          setShowEmailDocumentsModal(false);
          setEmailDocumentsRequest(null);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to send email');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaMoneyCheckAlt className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Settlements to Approve</h1>
          </div>
        </div>
      </div>
      <RFForm
        onSubmit={onSubmit}
        initialValues={{
          status: '0',
          insurCoId:
            insuranceCompaniesForUser.length > 0
              ? insuranceCompaniesForUser[0].insurcoid
              : 0,
        }}
        render={({ handleSubmit, form, values, submitting }) => {
          formInstance = form;
          return (
            <Form onSubmit={handleSubmit}>
              <div className={styles.grid3}>
                <Field
                  name='insurCoId'
                  label='Account'
                  options={insuranceCompaniesForUser}
                  optionMethod={(options: InsuranceCompany[]) =>
                    options.map((o) => (
                      <option key={o.insurcoid} value={o.insurcoid ?? ''}>
                        {`${o.accountnumber} - ${o.shortname}`}
                      </option>
                    ))
                  }
                  validate={requiredField}
                  component={FieldBSRenderSelect}
                />
                <div className='d-flex gap1Rem'>
                  <Field
                    name='status'
                    type='radio'
                    label='Approved'
                    value={1}
                    checked={values.status === '1'}
                    component={FieldBSRenderCheckbox}
                  />
                  <Field
                    name='status'
                    type='radio'
                    label='Un-Approved'
                    value={0}
                    checked={values.status === '0'}
                    component={FieldBSRenderCheckbox}
                  />
                  <Field
                    name='status'
                    type='radio'
                    label='All'
                    value={3}
                    checked={values.status === '3'}
                    component={FieldBSRenderCheckbox}
                  />
                  <Field
                    name='status'
                    type='radio'
                    label='Rejected'
                    value={2}
                    checked={values.status === '2'}
                    component={FieldBSRenderCheckbox}
                  />
                </div>

                <div className='d-flex justify-content-center align-items-center'>
                  <Button type='submit' variant='primary' size='sm'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Refresh'
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      />
      <ApproveSettlementTable
        data={data}
        denySettlement={denySettlement}
        approveSettlement={approveSettlement}
        setSelectedSettlement={setSelectedSettlement}
        setShowViewSettlement={setShowViewSettlement}
      />
      <ViewSettlementModal
        show={showViewSettlement}
        setShow={setShowViewSettlement}
        refetch={refetch}
        claimNumber={selectedSettlement?.claimNumber ?? null}
        claimantName={selectedSettlement?.claimantName ?? null}
        settlementId={selectedSettlement?.settlementId ?? null}
      />
      <EditEdiModal
        show={ediTransaction !== null}
        handleCloseModal={closeEdiModal}
        transaction={ediTransaction}
        getTransactions={() => {}}
      />
      <EmailDocumentsModal
        show={showEmailDocumentsModal}
        setShow={setShowEmailDocumentsModal}
        claimNumber={emailDocumentsRequest?.claimNumber ?? ''}
        docs={[]}
        handleEmailDocuments={handleEmailDocuments}
        userEmailList={userEmailList}
        subject={emailDocumentsRequest?.subject ?? ''}
        body={emailDocumentsRequest?.message ?? ''}
        toEmailAddresses={emailDocumentsRequest?.emailAddresses ?? []}
      />
    </PageScaffold>
  );
}
