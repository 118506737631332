import { useState } from 'react';

export const useReportRequestModal = () => {
  const [showReportRequestModal, setShowReportRequestModal] =
    useState<boolean>(false);
  const [reportEnumValue, setReportEnumValue] = useState<number>(0);

  return {
    showReportRequestModal,
    setShowReportRequestModal,
    reportEnumValue,
    setReportEnumValue,
  };
};
