import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { Rating } from '../ApiTypes/Rating';
import { RatingResponse } from '../ApiTypes/RatingResponse';
import { FileDownload } from '../Types/FileDownLoad';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class RatingApi {
  static getRatingsForCompany(id: number) {
    return axios.get<Rating[]>(`${API_URL}/api/ratings/${id}`, getAuthHeader());
  }
  static getRatingsForClaim(claimNumber: string) {
    return axios.get<RatingResponse[]>(
      `${API_URL}/api/claims/${claimNumber}/ratings`,
      getAuthHeader()
    );
  }
  static handleRatingSubmitted(claimNumber: string) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/ratings`,
      {},
      getAuthHeader()
    );
  }
  static viewRatingResponse(claimNumber: string, id: number) {
    return axios.get<FileDownload>(
      `${API_URL}/api/claims/${claimNumber}/ratings/${id}/view`,
      getAuthHeader()
    );
  }
}

export default RatingApi;
