import axios from "axios";
import { API_URL, getAuthHeader } from ".";
import { UserEmailList } from "../ApiTypes/UserEmailList";


class UserEmailListApi {
  static getEmailList(userId: string) {
    return axios.get<UserEmailList[]>(`${API_URL}/api/useremaillists/${userId}`, getAuthHeader());
  }
}

export default UserEmailListApi;