import { ClaimNoteType } from './ClaimNoteType';

export interface ClaimNote {
  noteId: number;
  claimNo: string;
  noteText: string;
  noteType: number | null;
  followUp: boolean;
  followUpComplete: boolean;
  noteDate: string;
  followUpDate: string | null;
  userId: string;
  assignedTo: string | null;
  noteEnteredDate: string;
  followUpCompleteDate: string | null;
  paymentNumber: string | null;
  caseManagementId: number | null;
  docId: number | null;
  importId: string | null;
  completedBy: string | null;
  completedOn: string | null;
  claimNoteType: ClaimNoteType | null;
}

export enum NoteType {
  General = 0,
  Legal = 3,
  Recovery = 7,
  Employer = 10,
  RTW = 12,
  SITF = 13,
  BenefitCalculation = 16,
  Controvert = 20,
  FileReview = 25,
  SUBRO = 33,
  Surveillance = 35,
  PhysicalTherapy = 41,
  ATP = 43,
  ReviewForClosure = 44,
  BoardForms = 48,
  SupervisorDiaryReview = 49,
  SupervisorInstructions = 50,
  Bills = 51,
  Employee = 52,
  Excess = 53,
  Medical = 54,
  NCMRehab = 55,
  SettlementAward = 56,
  SOIAttempt = 57,
  ActionPlanFileReview = 58,
  CMSMedicare = 59,
  Surgery = 60,
  Compensability = 61,
  EDIBoardForms = 62,
  DisabilityStatus = 63,
  TransferLT = 64,
  AdjusterDiary = 65,

  // CMS = 67,
  // Property = 68,
  // Subrogation = 69,
  // Litigation = 70,
}
