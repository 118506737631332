import React from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { FaPlusCircle } from 'react-icons/fa';
import { Letter } from '../../../ApiTypes/Letter';
import FieldBSRenderText from '../../Common/FieldBSRenderText';
import styles from './index.module.css';
import { useAppSelector } from '../../../Reducers/Store';
import { ClaimType } from '../../../ApiTypes/ClaimType';
import FieldBSRenderSelectHorizontal from '../../Common/FieldBSRenderSelectHorizontal';

export default function AddLetterModal({
  insCoId,
  show,
  setShow,
  createNewLetter,
  claimType,
}: {
  insCoId: number;
  show: boolean;
  setShow: (show: boolean) => void;
  createNewLetter: (l: Letter) => Promise<void>;
  claimType: number;
}) {
  const { claimTypes } = useAppSelector((state) => state.reference);

  const onSubmit = (values: Letter) => {
    return createNewLetter(values);
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      aria-labelledby='Add-Letter-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Add-Letter-Form-modal'>
          <FaPlusCircle className='pe-1' /> Add Letter
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={{
              insurCoId: insCoId,
              keyLetterId: 0,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='letterName'
                    label='Letter Name'
                    type='text'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='claimTypeId'
                    label='Claim Type'
                    options={claimTypes.filter((c) => c.id === claimType)}
                    optionMethod={(options: ClaimType[]) =>
                      options.map((o) => (
                        <option key={o.name} value={o.id}>
                          {o.name}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelectHorizontal}
                  />
                  <div className={styles.buttonDiv}>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        form.restart();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
