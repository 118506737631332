import React, { useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  RowSelectionState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { ClassCodeItem } from '../../ApiTypes/ClassCode';
import IndeterminateCheckbox from '../Common/IndeterminateCheckbox';

function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}

export default function ClassCodeTable({
  codes,
  setData,
  updateClassCode,
  setSelectedCodes,
  rowSelection,
  setRowSelection,
  hasPolicyId,
}: {
  codes: ClassCodeItem[];
  setData: (
    data: ClassCodeItem[] | ((data: ClassCodeItem[]) => ClassCodeItem[])
  ) => void;
  updateClassCode: (classCode: ClassCodeItem) => void;
  setSelectedCodes: (codes: ClassCodeItem[]) => void;
  rowSelection: RowSelectionState;
  setRowSelection: (o: object) => void;
  hasPolicyId: boolean;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  const handleRowSelectionChange = () => {
    setSelectedCodes(
      table.getSelectedRowModel().flatRows.map((r) => r.original)
    );
  };

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const tableData = useMemo(() => codes, [codes]);

  const selectionColumnData: ColumnDef<ClassCodeItem>[] = [
    {
      header: ({ table }) => (
        <div className='px-3 d-flex gap1Rem'>
          {Object.keys(rowSelection).length}
          <IndeterminateCheckbox
            className='form-check-input'
            {...{
              id: 'totalSelected',
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      id: 'options',
      enableSorting: false,
      enableColumnFilter: false,
      size: 75,
      cell: ({ row }) => {
        return (
          <div className='d-flex gap1Rem justify-content-center align-items-center'>
            <IndeterminateCheckbox
              className='form-check-input'
              {...{
                id: row.id,
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        );
      },
    },
  ];

  const columnData: ColumnDef<ClassCodeItem>[] = [
    {
      header: 'Class Code Id',
      accessorFn: (d) => `${d.classCodeId}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 100,
    },
    {
      header: 'Class Code',
      accessorKey: 'classCode',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 100,
    },
    {
      header: 'Description',
      accessorKey: 'description',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'SIR Amount',
      id: 'sirAmount',
      accessorFn: (d) => `${d.sirAmount ?? ''}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: function Cell({
        getValue,
        row: { index, getIsSelected, original },
        column: { id },
        table,
      }) {
        const initialValue = getValue();
        // We need to keep and update the state of the cell normally
        const [value, setValue] = React.useState(initialValue);

        // When the input is blurred, we'll call our table meta's updateData function
        const onUpdate = () => {
          table.options.meta?.updateData(index, id, value);
        };

        // If the initialValue is changed external, sync it up with our state
        React.useEffect(() => {
          setValue(initialValue);
        }, [initialValue]);

        return (
          <div className='input-group'>
            <input
              className='form-control form-control-sm'
              value={value as string}
              onChange={(e) => setValue(e.target.value)}
            />
            <button
              className='btn btn-outline-primary btn-sm'
              type='button'
              onClick={onUpdate}
            >
              Update
            </button>
          </div>
        );
      },
    },
  ];

  const columns = useMemo(
    () => (hasPolicyId ? [...selectionColumnData, ...columnData] : columnData),
    [hasPolicyId, rowSelection]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
    autoResetPageIndex,
    // Provide our updateData function to our table meta
    meta: {
      updateData: (rowIndex, columnId, value) => {
        // Skip page index reset until after next rerender
        skipAutoResetPageIndex();
        setData((old: ClassCodeItem[]) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              var updated = {
                ...old[rowIndex]!,
                [columnId]: value,
              };
              updateClassCode(updated);
              return updated;
            }
            return row;
          })
        );
      },
    },
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
