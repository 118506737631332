import React, { useState } from 'react';
import { Spinner, Form, Button } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaCalendarAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import MedicareApi from '../../Api/MedicareApi';
import { NoteType } from '../../ApiTypes/ClaimNote';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { MedQueryObj } from '../../ApiTypes/MedQueryObj';
import { useAppSelector } from '../../Reducers/Store';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import EditCreateNote from '../EditCreateNote/EditCreateNote';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import MedicareTable from './MedicareTable';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { useDocViewModal } from '../DocViewModal/useDocViewModal';
import DocViewModal from '../DocViewModal/DocViewModal';
import { ClaimTypes } from '../../ApiTypes/ClaimTypeConfiguration';

export default function Medicare() {
  const [details, setDetails] = useState<MedQueryObj[]>([]);
  const [showAddNote, setShowAddNote] = useState<boolean>(false);
  const [selectedQuery, setSelectedQuery] = useState<MedQueryObj | null>(null);
  const [newFormat, setNewFormat] = useState<boolean>(true);
  const [selectedQueries, setSelectedQueries] = useState<MedQueryObj[]>([]);

  const {
    showDocViewModal,
    fileDownload,
    setShowDocViewModal,
    setFileDownload,
  } = useDocViewModal();

  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );

  const onSubmit = (values: { insurCoId: number }) => {
    return getDetails(values.insurCoId);
  };

  const getDetails = (insurCoId: number) => {
    return MedicareApi.getQueryObjects(insurCoId)
      .then((res) => {
        setDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get details');
      });
  };
  const handleAddNoteClick = (q: MedQueryObj) => {
    setSelectedQuery(q);
    setShowAddNote(true);
  };
  const closeAddModal = () => {
    setShowAddNote(false);
    setSelectedQuery(null);
  };

  const send = () => {
    if (selectedQueries.length === 0) {
      toast.info('No Queries selected');
      return;
    }

    MedicareApi.sendQuery(selectedQueries, newFormat)
      .then((res) => {
        if (res.data.success) {
          if (newFormat) {
            toast.success('File has been uploaded');
          } else {
            toast.success('File can be viewed and downloaded');
            setFileDownload({
              file: res.data.reportBytes,
              fileName: res.data.reportFileName,
            });
            setShowDocViewModal(true);
          }
        } else {
          toast.error(<ErrorMessage messages={res.data.messages} />);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to send medicare query');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaCalendarAlt className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Medicare Monthly Files - Exam Works</h1>
          </div>
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-between'>
        <RFForm
          onSubmit={onSubmit}
          initialValues={{}}
          render={({ handleSubmit, form, values, submitting }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div className='d-flex  align-items-center gap1Rem'>
                  <Field
                    name='insurCoId'
                    label='Insurance Company'
                    options={[
                      ...insuranceCompaniesForUser
                        .map((x) => x)
                        .sort((a, b) => +a.accountnumber! - +b.accountnumber!),
                    ]}
                    optionMethod={(options: InsuranceCompany[]) =>
                      options.map((o) => (
                        <option key={o.insurcoid!} value={o.insurcoid!}>
                          {`${o.accountnumber} - ${o.shortname}`}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />

                  <Button type='submit' variant='primary' size='sm'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Search'
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        />
        <div className='form-check'>
          <label className='form-check-label'>
            <input
              type='checkbox'
              className='form-check-input'
              checked={newFormat}
              onChange={(e) => {
                setNewFormat((newFormat) => !newFormat);
              }}
            />
            New Exam Works Format
          </label>
        </div>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={() => send()}
        >
          Send
        </Button>
      </div>
      <MedicareTable
        data={details}
        handleAddNoteClick={handleAddNoteClick}
        selectedQueries={selectedQueries}
        setSelectedQueries={setSelectedQueries}
      />
      <EditCreateNote
        show={showAddNote}
        setShow={closeAddModal}
        selectedNote={null}
        getClaimNotes={() => {}}
        claimNumber={selectedQuery?.claimNo ?? ''}
        noteType={NoteType.CMSMedicare}
        claimType={ClaimTypes.All}
        tabFrom=''
      />
      <DocViewModal
        show={showDocViewModal}
        setShow={setShowDocViewModal}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
    </PageScaffold>
  );
}
