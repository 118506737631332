import { format } from 'date-fns';
import React from 'react';
import { Alert, Button, Container, Form, Modal } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaUpload } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ExportApi from '../../Api/ExportApi';
import { downloadZip, parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';

export default function MeccExport({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const onSubmit = async (values: { asOf: string }) => {
    try {
      setShow(false);
      const res = await toast.promise(ExportApi.exportMecc(values.asOf), {
        pending: `Exporting`,
        success: 'Success',
        error: `Failed to export data`,
      });
      if (res.data.file) {
        downloadZip(res.data.fileName, res.data.file);
      } else {
        toast.error('Failed to export data');
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='MeccExport-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='MeccExport-modal'>
          <FaUpload className='pe-1' /> Export MECC Data
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Alert variant='info' className='text-center'>
            Files will be uploaded
          </Alert>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              asOf: format(new Date(), 'yyyy-MM-dd'),
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='asOf'
                    type='text'
                    label='As Of Date'
                    validate={requiredField}
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                  />
                  <div className='d-flex justify-content-center align-items-center'>
                    <Button type='submit' variant='primary' size='sm'>
                      Submit
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
