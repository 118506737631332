import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { displayDateOnly } from '../../Utils';
import { GroupedFees } from '../../ApiTypes/VBillFee';
import accounting from 'accounting';

export default function GroupedFeesTable({
  data,
}: // handleSelectedClaim,
{
  data: GroupedFees[];
  // handleSelectedClaim: (claim: GroupedFees) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<GroupedFees>[] = [
    // {
    //   header: '',
    //   id: 'actions',
    //   enableSorting: false,
    //   enableColumnFilter: false,
    //   maxSize: 70,
    //   cell: ({ row }) => {
    //     return (
    //       <Button
    //         type='button'
    //         variant='primary'
    //         size='sm'
    //         onClick={() => {
    //           // handleSelectedClaim(row.original);
    //         }}
    //       >
    //         Select
    //       </Button>
    //     );
    //   },
    // },
    {
      header: 'Client',
      accessorKey: 'clientId',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'BR Fees',
      accessorFn: (d) => `${d.brFees}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: ({ row }) => accounting.formatMoney(row.original.brFees ?? 0),
    },
    {
      header: 'PPO Fees',
      accessorFn: (d) => `${d.ppoFEES}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: ({ row }) => accounting.formatMoney(row.original.ppoFEES ?? 0),
    },
    {
      header: 'Total Fees',
      accessorFn: (d) => `${d.totalFEES}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: ({ row }) => accounting.formatMoney(row.original.totalFEES ?? 0),
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
