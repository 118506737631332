import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { UserModel } from '../../../ApiTypes/UserModel';
import { requiredField } from '../../../Utils/FieldValidation';
import FieldBSRenderText from '../../Common/FieldBSRenderText';

export default function EmailLetterPageOne({
  userModel,
  handleEmailSubmit,
}: {
  userModel: UserModel | null;
  handleEmailSubmit: (values: { emailAddress: string }) => Promise<boolean>;
}) {
  return (
    <div>
      <RFFForm
        onSubmit={handleEmailSubmit}
        initialValues={{
          emailAddress: `${userModel?.user?.userId}@georgia-admin.com`,
        }}
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Field
                name='emailAddress'
                label='Email Address'
                type='text'
                validate={requiredField}
                component={FieldBSRenderText}
              />
              <div>
                <Button type='submit' variant='primary' size='sm'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
}
