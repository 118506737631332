import React from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';

export default function BlankModal({
  show,
  setShow,
  message,
  showCancel = true,
}: {
  show: boolean;
  message: JSX.Element;
  setShow: (show: boolean) => void;
  showCancel?: boolean;
}) {
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='Confirm-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Confirm-modal'>
          <FaCheck className='pe-1' /> Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {message}
          <div className='d-flex justify-content-center align-items-center my-5'>
            {showCancel && (
              <Button
                type='button'
                size='lg'
                variant='secondary'
                onClick={() => {
                  setShow(false);
                }}
              >
                Cancel
              </Button>
            )}
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
