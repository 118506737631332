import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ApproveReserveWorksheetRequest } from '../ApiTypes/ApproveReserveWorksheetRequest';
import { ClaimPaymentTotals } from '../ApiTypes/ClaimPaymentTotals';
import { ClaimReserveBuckets } from '../ApiTypes/ClaimReserveBuckets';
import { ClaimReservesWorksheet } from '../ApiTypes/ClaimReservesWorksheet';
import { ClaimReservesWorksheetObj } from '../ApiTypes/ClaimReservesWorksheetObj';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { SimpleClaimReserveObject } from '../ApiTypes/SimpleClaimReserveObject';
import { UpdateReservesRequest } from '../Components/Tabs/Reserves/UpdateReservesType';
import { PtdResponse } from '../ApiTypes/PtdResponse';
import { ClaimReserveBucketsUpdate } from '../ApiTypes/ClaimReserveBucketsUpdate';

class ReservesApi {
  static getClaimReserveBucketsForClaim(claimNumber: string) {
    return axios.get<ClaimReserveBuckets[]>(
      `${API_URL}/api/claims/${claimNumber}/reserves`,
      getAuthHeader()
    );
  }
  static getClaimPaymentTotals(claimNumber: string) {
    return axios.get<ClaimPaymentTotals>(
      `${API_URL}/api/claims/${claimNumber}/paymenttotals`,
      getAuthHeader()
    );
  }
  static getClaimReservesWorksheetsForClaim(claimNumber: string) {
    return axios.get<ClaimReservesWorksheet[]>(
      `${API_URL}/api/claims/${claimNumber}/reserves/worksheet`,
      getAuthHeader()
    );
  }
  static saveClaimReservesWorksheet(ws: ClaimReservesWorksheet) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${ws.claimNo}/reserves/worksheet`,
      ws,
      getAuthHeader()
    );
  }
  static updateSavedClaimReservesWorksheet(ws: ClaimReservesWorksheet) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${ws.claimNo}/reserves/worksheet/${ws.id}`,
      ws,
      getAuthHeader()
    );
  }
  static approveClaimReservesWorksheet(
    request: ApproveReserveWorksheetRequest,
    claimNo: string
  ) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNo}/reserves/worksheet/approve`,
      request,
      getAuthHeader()
    );
  }

  static updateReserves(claimNumber: string, request: UpdateReservesRequest) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/reserves`,
      request,
      getAuthHeader()
    );
  }
  static updateReservesNew(
    claimNumber: string,
    request: ClaimReserveBucketsUpdate
  ) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/reserves/update`,
      request,
      getAuthHeader()
    );
  }
  static getWorksheetsForUser() {
    return axios.get<ClaimReservesWorksheetObj[]>(
      `${API_URL}/api/reserves/worksheets`,
      getAuthHeader()
    );
  }
  static getWorksheetById(id: number) {
    return axios.get<ClaimReservesWorksheet>(
      `${API_URL}/api/reserves/worksheets/${id}`,
      getAuthHeader()
    );
  }
  static getClaimsWithNegativeReserves() {
    return axios.get<SimpleClaimReserveObject[]>(
      `${API_URL}/api/reserves/negatives`,
      getAuthHeader()
    );
  }
  static getPtdForClaim(claimNumber: string) {
    return axios.get<PtdResponse>(
      `${API_URL}/api/claims/${claimNumber}/reserves/ptd`,
      getAuthHeader()
    );
  }
}

export default ReservesApi;
