import React from 'react';
import { FaFileUpload } from 'react-icons/fa';
import styles from './index.module.css';

type FormFileType = { file?: File; name?: string };

const handleChange =
  (handler: any) =>
  ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>): FormFileType | undefined => {
    if (files === null || files.length < 1) return undefined;

    return handler(files.length ? { file: files[0], name: files[0].name } : {});
  };

type FinalRenderFieldProps = {
  input: any;
  label?: string;
  underInputNote?: string;
  required?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
  className?: string;
  meta: { touched: boolean; error: string; warning: string };
  accept?: string;
};

const RenderFilePicker = ({
  input: { onChange, onBlur, value: omitValue, name, ...inputProps },
  meta: { error, touched, warning },
  ...props
}: FinalRenderFieldProps) => {
  return (
    <div className={`position-relative ${styles.vFieldHeight}`}>
      <label className='form-label fs-6  m-0' htmlFor={name}></label>
      <input
        type='file'
        name={name}
        id={name}
        className='form-control form-control-sm'
        onChange={handleChange(onChange)}
        onBlur={handleChange(onBlur)}
        {...inputProps}
        {...props}
      />
      {touched &&
        ((error && (
          <span className={`${styles.fieldError} text-danger`}>{error}</span>
        )) ||
          (warning && (
            <span className={`${styles.fieldWarning} text-warning`}>
              {warning}
            </span>
          )))}
    </div>
  );
};

export default RenderFilePicker;
