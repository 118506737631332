import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { Check } from '../ApiTypes/Check';
import { CheckDocuments } from '../ApiTypes/CheckDocuments';
import { CheckImportObject } from '../ApiTypes/CheckImportObject';
import { CheckRegisterRequest } from '../ApiTypes/CheckRegisterRequest';
import { MayoCheckImportResult } from '../ApiTypes/MayoCheckImportResult';
import { ReconcileCheckRequest } from '../ApiTypes/ReconcileCheckRequest';
import { ReprintCheckRequest } from '../ApiTypes/ReprintCheckRequest';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { VwCheckRegisterLong } from '../ApiTypes/VwCheckRegisterLong';
import { FileDownload } from '../Types/FileDownLoad';
import { ReportResponse } from '../ApiTypes/ReportRequest';

class ChecksApi {
  static getLongChecksForAccountByDate(request: CheckRegisterRequest) {
    return axios.post<VwCheckRegisterLong[]>(
      `${API_URL}/api/checks/checksforaccount`,
      request,
      getAuthHeader()
    );
  }
  static getExcelLongChecksForAccountByDate(request: CheckRegisterRequest) {
    return axios.post<FileDownload>(
      `${API_URL}/api/checks/checksforaccount/excel`,
      request,
      getAuthHeader()
    );
  }
  static getMountainValley(request: CheckRegisterRequest) {
    return axios.post<FileDownload>(
      `${API_URL}/api/checks/mvpositivepay`,
      request,
      getAuthHeader()
    );
  }
  static getGada(request: CheckRegisterRequest) {
    return axios.post<FileDownload>(
      `${API_URL}/api/checks/gadapositivepay`,
      request,
      getAuthHeader()
    );
  }
  static getSunTrust(request: CheckRegisterRequest) {
    return axios.post<FileDownload>(
      `${API_URL}/api/checks/suntrustpositivepay`,
      request,
      getAuthHeader()
    );
  }
  static getRegionsPosPay(request: CheckRegisterRequest) {
    return axios.post<FileDownload>(
      `${API_URL}/api/checks/regionspositivepay`,
      request,
      getAuthHeader()
    );
  }
  static getQbFile(request: CheckRegisterRequest, includeDetails: boolean) {
    return axios.post<FileDownload>(
      `${API_URL}/api/checks/qb?includeDetails=${includeDetails}`,
      request,
      getAuthHeader()
    );
  }
  static convertQbFile(request: FormData) {
    return axios.post<FileDownload>(
      `${API_URL}/api/checks/convert`,
      request,
      getAuthHeader()
    );
  }
  static reprintChecks(request: ReprintCheckRequest) {
    return axios.post<CheckDocuments>(
      `${API_URL}/api/checks/reprint`,
      request,
      getAuthHeader()
    );
  }
  static getChecksToReconcile(
    insurCoId: number,
    cleared: boolean,
    serviceTo: string
  ) {
    return axios.get<Check[]>(
      `${API_URL}/api/checks/gettoreconcile?insuranceCompanyId=${insurCoId}&cleared=${cleared}&to=${serviceTo}`,
      getAuthHeader()
    );
  }
  static reconcileChecks(checks: { checksToReconcile: string[] }) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/checks/reconcile`,
      checks,
      getAuthHeader()
    );
  }
  static reconcileCheck(request: ReconcileCheckRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/checks/reconcilecheck`,
      request,
      getAuthHeader()
    );
  }
  static updateMayo(checks: { checks: CheckImportObject[] }) {
    return axios.post<MayoCheckImportResult>(
      `${API_URL}/api/checks/updatemayo`,
      checks,
      getAuthHeader()
    );
  }

  static getDifferences(checks: { checks: CheckImportObject[] }) {
    return axios.post<CheckImportObject[]>(
      `${API_URL}/api/checks/discoverdifferences`,
      checks,
      getAuthHeader()
    );
  }
  static printBatch(pmtBatchId: string) {
    return axios.get<ReportResponse>(
      `${API_URL}/api/checks/printbatch/${pmtBatchId}`,
      getAuthHeader()
    );
  }
}

export default ChecksApi;
