export enum DocumentStatus {
  All = -1,
  New = 1,
  PendingApproval = 2,
  Accepted = 3,
  Rejected = 4,
  ReScanReuired = 5,
  SentToStraightPay = 6,
  SentToCompIQ = 7,
  StraightPayApproved = 8,
  CompIQApproved = 9,
  StraightPayDenied = 10,
  CompIQDenied = 11,
  Viewed = 12,
  CompIQPending = 13,
  CompIQMissingInfo = 14,
  CompIQDuplicate = 15,
  StraightOnHold = 16,
  StraightPayDuplicate = 17,
}

export enum NCMDocStatus {
  New = 1,
  Viewed = 2,
  DoNotNeed = 3,
  Archived = 4,
}

export enum DocsNotesRequestStatus {
  New = 0,
  First = 1,
  Second = 2,
  Closed = 3,
  NonResponsive = 4,
}
