import React from 'react';
import { Field } from 'react-final-form';
import { State } from '../../ApiTypes/State';
import { parseDatesForServer } from '../../Utils';
import { zipLength } from '../../Utils/FieldValidation';
import { removeNonDigits, formatSSN } from '../../Utils/InputFormatters';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import styles from './index.module.css';

export default function Wc1ClaimantForm({
  states,
  values,
}: {
  states: State[];
  values: any;
}) {
  return (
    <div className='d-flex'>
      <div className='w-50 pe-2'>
        <Field
          name='claimantFirstName'
          label='Employee First Name'
          type='text'
          component={FieldBSRenderText}
        />
        <Field
          name='claimantLastName'
          type='text'
          label='Employee Last Name'
          component={FieldBSRenderText}
        />
        <Field
          name='claimantMi'
          label='Middle Initial'
          type='text'
          component={FieldBSRenderText}
        />
        <Field
          name='claimantSsn'
          label='SSN'
          parse={removeNonDigits}
          format={formatSSN}
          type='text'
          component={FieldBSRenderText}
        />
        <Field
          name='claimantBirthday'
          label='DOB'
          parse={parseDatesForServer}
          component={FieldBSRenderDate}
        />
        <div className={`d-flex justify-content-start ${styles.flexGap1rem}`}>
          <Field
            name='claimantGender'
            type='radio'
            label='Male'
            value='M'
            checked={values?.claimantGender === 'M'}
            component={FieldBSRenderCheckbox}
          />
          <Field
            name='claimantGender'
            type='radio'
            label='Female'
            value='F'
            checked={values.claimantGender === 'F'}
            component={FieldBSRenderCheckbox}
          />
        </div>
      </div>
      <div className='w-50 ps-2'>
        <Field
          name='claimantAddress'
          type='text'
          label='Address 1'
          component={FieldBSRenderText}
        />
        <Field
          name='claimantAddress1'
          type='text'
          label='Address 2'
          component={FieldBSRenderText}
        />
        <div className={`d-flex ${styles.address3} ${styles.address3children}`}>
          <Field
            name='claimantCity'
            type='text'
            label='City'
            component={FieldBSRenderText}
          />
          <Field
            name='claimantState'
            label='State'
            options={states}
            optionMethod={(options: State[]) =>
              options.map((o) => (
                <option key={o.stateAbbr} value={o.stateAbbr}>
                  {o.stateAbbr}
                </option>
              ))
            }
            component={FieldBSRenderSelect}
          />
          <Field
            name='claimantZip'
            type='text'
            label='Zip'
            validate={zipLength}
            component={FieldBSRenderText}
            maxLength={5}
          />
        </div>

        <Field
          name='claimantPhoneNumber'
          type='text'
          label='Phone'
          component={FieldBSRenderText}
        />
        <Field
          name='claimantEmail'
          type='text'
          label='Email'
          component={FieldBSRenderText}
        />
      </div>
    </div>
  );
}
