import accounting from 'accounting';
import React, { useState, useEffect } from 'react';
import { Modal, Container, Button, Form, Spinner } from 'react-bootstrap';
import { Form as RFFForm, Field } from 'react-final-form';
import { FaShare } from 'react-icons/fa';
import { toast } from 'react-toastify';
import RecurringPaymentsApi from '../../../Api/RecurringPaymentsApi';
import { EmailDocumentsRequest } from '../../../ApiTypes/EmailDocumentsRequest';
import { RecurringPayment } from '../../../ApiTypes/RecurringPayment';
import { SendIndemnityDetailsRequest } from '../../../ApiTypes/SendIndemnityDetailsRequest';
import { useAppSelector } from '../../../Reducers/Store';
import {
  getRecurringPaymentWeeklyDetail,
  RecurringPaymentWeeklyDetail,
} from '../../../Types/RecurringPaymentWeeklyDetail';
import { displayDateOnly, parseDatesForServer } from '../../../Utils';
import { formatNumbers, cleanMoney } from '../../../Utils/InputFormatters';
import FieldBSRenderCheckbox from '../../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../../Common/FieldBSRenderDate';
import FieldBSRenderMoney from '../../Common/FieldBSRenderMoney';
import FieldBSRenderTextArea from '../../Common/FieldRenderTextArea';
import RecurringPaymentWeeklyDetailsTable from '../../RecurringPaymentWeeklyDetailModal/RecurringPaymentWeeklyDetailsTable';

import styles from './index.module.css';

interface RecurringPaymentsDetailsFormType extends RecurringPayment {
  includeBenefits: boolean;
  includeSuspended: boolean;
  disabilityDate: string;
}

export default function SendIndemnityDetailsModal({
  show,
  setShow,
  rp,
  setSelectedRP,
  getRecurringPaymentsForClaim,
  handleSendIndemnityResponse,
}: {
  show: boolean;
  rp: RecurringPayment | null;
  setShow: (s: boolean) => void;
  setSelectedRP: (rp: RecurringPayment | null) => void;
  getRecurringPaymentsForClaim: () => void;
  handleSendIndemnityResponse: (send: EmailDocumentsRequest) => void;
}) {
  const { claim, claimant } = useAppSelector(
    (state) => state.currentClaimReducer
  );

  const [details, setDetails] = useState<RecurringPaymentWeeklyDetail[]>([]);

  useEffect(() => {
    setWeeklyDetails();

    return () => {
      setDetails([]);
    };
  }, [rp]);

  const setWeeklyDetails = () => {
    if (rp) {
      const d = getRecurringPaymentWeeklyDetail(rp);
      setDetails(d);
    } else {
      setDetails([]);
    }
  };

  const { payCodes } = useAppSelector((state) => state.reference);

  const onSubmit = (values: RecurringPaymentsDetailsFormType) => {
    sendRequest(values);
  };

  const sendRequest = (values: RecurringPaymentsDetailsFormType) => {
    const { includeBenefits, includeSuspended, disabilityDate, ...rest } =
      values;
    const send: SendIndemnityDetailsRequest = {
      rp: rest,
      includeBenefits: values.includeBenefits,
      includeSuspended: values.includeSuspended,
    };
    return RecurringPaymentsApi.sendIndemnityDetails(send)
      .then((res) => {
        if (res.data.subject !== 'Failed') {
          handleSendIndemnityResponse(res.data);
          getRecurringPaymentsForClaim();
          setSelectedRP(null);
          setDetails([]);
          setShow(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setSelectedRP(null);
        setDetails([]);
        setShow(false);
      }}
      aria-labelledby='Send-Indemnity-Details-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Send-Indemnity-Details-modal'
        >
          <FaShare className='pe-1' /> Send Indemnity Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <div className={`${styles.grid4}`}>
            <p>Claimant Name</p>
            <p>
              {claimant?.firstName} {claimant?.lastName}
            </p>
            <p>Benefit Type</p>
            <p>
              {payCodes.find((p) => p.payCode === rp?.payCode)?.description}
            </p>
            <p>Claim #</p>
            <p>{claim?.claimNo}</p>
            <p>AWW</p>
            <p>{accounting.formatMoney(claim?.avgWeeklyWage ?? 0)}</p>
            <p>D/A</p>
            <p>{displayDateOnly(claim?.injuryDate ?? '')}</p>
            <p>WCR</p>
            <p>{accounting.formatMoney(claim?.hourlyWage ?? 0)}</p>
            <p>Date of Disability</p>
            <p>{displayDateOnly(claim?.disabilityDate ?? '')}</p>
          </div>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={{
              includeBenefits: true,
              includeSuspended: false,
              disabilityDate: claim?.disabilityDate,
              ...rp,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className='d-flex justify-content-center align-items-center mb-3'>
                    <Field
                      name='includeBenefits'
                      label='Indemnity Benefits are due as outlined'
                      type='checkbox'
                      checked={values.includeBenefits == true}
                      component={FieldBSRenderCheckbox}
                    />
                  </div>
                  <div className={`${styles.grid2}`}>
                    <Field
                      name='disabilityDate'
                      label='Date of Disability'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                    <div></div>
                    <Field
                      name='amount'
                      label='Amount'
                      type='text'
                      format={formatNumbers}
                      parse={cleanMoney}
                      component={FieldBSRenderMoney}
                    />
                    <Field
                      name='dueBuy'
                      label='Due By'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                    <Field
                      name='startDate'
                      label='Start Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                    <Field
                      name='endDate'
                      label='End Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                  </div>
                  <Field
                    name='mailTo'
                    label='Checks should be mailed to'
                    rows={3}
                    component={FieldBSRenderTextArea}
                  />
                  <div>
                    <p className='fw-bold'>To Avoid Penalties</p>
                    <RecurringPaymentWeeklyDetailsTable details={details} />
                  </div>
                  <Field
                    name='comments'
                    label='Comments'
                    rows={3}
                    component={FieldBSRenderTextArea}
                  />
                  <div className={`${styles.grid3}`}>
                    <Field
                      name='waitingPeriodStart'
                      label='Waiting Period Start'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                    <Field
                      name='waitingPeriodEnd'
                      label='Waiting Period End'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                    <Field
                      name='waitingPeriodToBePaidOn'
                      label='Waiting Period to be paid on'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                  </div>
                  <div className='d-flex justify-content-center align-items-center mb-3'>
                    <Field
                      name='includeSuspended'
                      label='Indemnity Benefits Suspended'
                      type='checkbox'
                      checked={values.includeSuspended == true}
                      component={FieldBSRenderCheckbox}
                    />
                  </div>
                  <div className={`${styles.grid2}`}>
                    <Field
                      name='suspendedOn'
                      label='Suspend On'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                    <Field
                      name='returnToWork'
                      label='Returned to work'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                    />
                  </div>
                  <Field
                    name='returnToWorkWithNotice'
                    label='Able to return to work provide required 10 day notice'
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                  />
                  <Field
                    name='suspendedComments'
                    label='Comments'
                    rows={3}
                    component={FieldBSRenderTextArea}
                  />
                  <div className={styles.buttonDiv}>
                    <Button type='submit' size='sm' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Send'
                      )}
                    </Button>
                    <Button
                      type='button'
                      size='sm'
                      variant='secondary'
                      onClick={() => {
                        form.reset();
                        setSelectedRP(null);
                        setDetails([]);
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
