import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { SaveGemcexcessTransaction } from '../ApiTypes/SaveGemcexcessTransaction';

class RecoveryApi {
  static getTransactionsForClaim(claimNumber: string, claimTypeId: number) {
    return axios.get<SaveGemcexcessTransaction[]>(
      `${API_URL}/api/claims/${claimNumber}/recoveries/${claimTypeId}`,
      getAuthHeader()
    );
  }
  static updateTransaction(transaction: SaveGemcexcessTransaction) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${transaction.claimNo}/recoveries/${transaction.txid}`,
      transaction,
      getAuthHeader()
    );
  }
  static createTransaction(transaction: SaveGemcexcessTransaction) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${transaction.claimNo}/recoveries`,
      transaction,
      getAuthHeader()
    );
  }
  static deleteTransaction(claimNumber: string, txid: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/recoveries/${txid}`,
      getAuthHeader()
    );
  }
}

export default RecoveryApi;
