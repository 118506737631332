import React, { forwardRef, useEffect } from 'react';
import UTIF from 'utif2';
import { saveAs } from 'file-saver';
import {
  FaArrowLeft,
  FaArrowRight,
  FaFileDownload,
  FaRedoAlt,
} from 'react-icons/fa';
import styles from './index.module.css';
import { Button } from 'react-bootstrap';
import { base64ToBlob } from '../../Utils';
import TiffThumbnail from './TiffThumbnail';

type Ref = HTMLImageElement;

export const TIFFViewer = forwardRef<
  Ref,
  {
    base64Data: string;
    fileName: string;
  }
>(function TiffFileViewer({ base64Data, fileName, ...rest }, ref) {
  // states
  const [_tiff] = React.useState(base64Data);
  const [, setTiffs] = React.useState<HTMLCanvasElement[]>([]);
  const [pages, setPages] = React.useState<HTMLImageElement[]>([]);
  const [thumbs, setThumbs] = React.useState<HTMLImageElement[]>([]);
  const [page, setPage] = React.useState<number>(0);
  const [deg, setDeg] = React.useState<number>(0);
  const [width, setWidth] = React.useState<number>(0);

  // refs
  const imgRef = React.useRef<HTMLImageElement>(null);

  function imgLoaded(response: ArrayBuffer) {
    var ifds = UTIF.decode(response);
    const _thumbs: HTMLImageElement[] = [];
    const _pages: HTMLImageElement[] = [];
    const _tiffs = ifds.map(function (ifd, index) {
      UTIF.decodeImage(response, ifd);
      var rgba = UTIF.toRGBA8(ifd);
      var canvas = document.createElement('canvas');
      canvas.width = ifd.width;
      canvas.height = ifd.height;
      var ctx = canvas.getContext('2d');
      var img = ctx!.createImageData(ifd.width, ifd.height);
      img.data.set(rgba);
      ctx!.putImageData(img, 0, 0);
      const image = new Image();
      image.src = canvas.toDataURL();
      _thumbs.push(image);
      const cloned = image.cloneNode(true) as HTMLImageElement;
      _pages.push(cloned);
      return canvas;
    });

    setPages(_pages);
    setTiffs(_tiffs);
    setThumbs(_thumbs);
  }

  function _base64ToArrayBuffer(base64: string) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  async function displayTIFF(tiffUrl: string) {
    var response = _base64ToArrayBuffer(base64Data);
    imgLoaded(response);
  }

  const handlePreviousClick = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleNextClick = () => {
    if (page < pages.length - 1) {
      setPage(page + 1);
    }
  };
  const downloadTif = () => {
    const blob = base64ToBlob(base64Data, 'image/tif');

    saveAs(blob, fileName);
  };

  const handleRotate = () => {
    const img = pages[page];
    const newDegrees = deg + 90;
    setDeg(newDegrees);
    imgRef.current!.style.transform = `rotate(${newDegrees}deg)`;
  };

  useEffect(() => {
    displayTIFF(_tiff);
  }, [_tiff]);

  useEffect(() => {
    const modalBody = document.getElementById('DocViewModal');
    if (modalBody) {
      setWidth(modalBody.clientWidth ?? 0);
    }
  }, [page, pages]);

  const renderStepPagination = (page: number, pages: HTMLImageElement[]) => {
    if (pages.length === 1) {
      return (
        <div className={styles.pdfNavigation}>
          <Button
            type='button'
            onClick={downloadTif}
            variant='outline-primary'
            className='button-icon-text'
            style={{
              width: 'auto',
            }}
            size='sm'
            title='Download'
          >
            <FaFileDownload /> Download
          </Button>
        </div>
      );
    }
    return (
      <div className={styles.pdfNavigation}>
        <Button
          type='button'
          onClick={handlePreviousClick}
          disabled={page === 0}
          variant='outline-primary'
          className='button-icon-text'
          size='sm'
        >
          <FaArrowLeft /> Previous
        </Button>
        <Button
          type='button'
          onClick={downloadTif}
          variant='outline-primary'
          className='button-icon-text'
          style={{
            width: 'auto',
          }}
          size='sm'
          title='Download'
        >
          <FaFileDownload />
        </Button>
        <Button
          type='button'
          onClick={handleRotate}
          variant='outline-primary'
          className='button-icon-text'
          style={{
            width: 'auto',
          }}
          size='sm'
          title='Rotate'
        >
          <FaRedoAlt />
        </Button>
        <Button
          type='button'
          onClick={handleNextClick}
          disabled={page === pages.length - 1}
          variant='outline-primary'
          className='button-icon-text'
          size='sm'
        >
          Next
          <FaArrowRight />
        </Button>
      </div>
    );
  };

  const onThumbnailClick = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const renderPagination = (page: number, pages: HTMLImageElement[]) => {
    if (pages.length === 1) {
      return <div />;
    }
    return (
      <div className={styles.pdfThumbnailContainer}>
        {renderThumbnails(pages)}
      </div>
    );
  };
  const renderThumbnails = (pages: HTMLImageElement[]) => {
    const thumbnails = [];
    for (let i = 0; i < pages.length; i++) {
      thumbnails.push(
        <TiffThumbnail
          key={i}
          pageNumber={i}
          currentPageNumber={page}
          canvas={pages[i]}
          onThumbnailClick={onThumbnailClick}
        />
      );
    }
    return thumbnails;
  };

  return (
    <div className={styles.width}>
      {!_tiff && <p className={styles.noResults}>Tiff Not Available</p>}
      {_tiff && pages.length && renderStepPagination(page, pages)}
      <div className={styles.pdfNavBesidePdf}>
        {_tiff && thumbs.length && renderPagination(page, thumbs)}
        <div className='w-100 h-100'>
          <img
            alt='doc'
            src={pages[page]?.src ?? ''}
            ref={imgRef}
            style={{ objectFit: 'contain' }}
          />
        </div>
      </div>
    </div>
  );
});
