import React, { useState, useEffect } from 'react';
import { Form as RFForm, Field } from 'react-final-form';
import styles from './index.module.css';
import { FaPrescriptionBottle } from 'react-icons/fa';
import PageScaffold from '../PageScaffold/PageScaffold';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../Reducers/Store';
import { Button, Form, Spinner } from 'react-bootstrap';
import { State } from '../../ApiTypes/State';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { requiredField, zipLength } from '../../Utils/FieldValidation';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import { Pbm } from '../../ApiTypes/Pbm';
import PbmApi from '../../Api/PbmApi';
import DeleteConfirmationModal from '../Tabs/Legal/DeleteConfirmationModal';

export default function AddEditPbm() {
  const { pbmId } = useParams();

  const { states } = useAppSelector((state) => state.reference);
  const [pbm, setPbm] = useState<Pbm | null>(null);
  const [showDelete, setShowDelete] = useState<boolean>(false);

  useEffect(() => {
    getPbm();
  }, [pbmId]);

  const getPbm = () => {
    if (pbmId && pbmId !== 'new') {
      PbmApi.getPbmById(+pbmId)
        .then((res) => {
          setPbm(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get PBM');
        });
    }
  };

  const onSubmit = (values: Pbm) => {
    if (values.pbmid) {
      return updatePbm(values);
    }
    return createPbm(values);
  };

  const updatePbm = (values: Pbm) => {
    return PbmApi.updatePbm(values)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success PBM updated');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update PBM');
      });
  };
  const createPbm = (values: Pbm) => {
    return PbmApi.createPbm(values)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success PBM created');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create PBM');
      });
  };

  const handleDelete = () => {
    setShowDelete(false);
    if (pbm) {
      pbm.isActive = false;
      return updatePbm(pbm);
    }
    return Promise.resolve();
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaPrescriptionBottle className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>PBM</h1>
          </div>
          <div className='ms-3'></div>
        </div>
      </div>
      <RFForm
        onSubmit={onSubmit}
        initialValues={pbm ?? { isActive: true }}
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Field
                name='name'
                type='text'
                label='Name'
                validate={requiredField}
                component={FieldBSRenderText}
              />
              <Field
                name='address'
                type='text'
                label='Address'
                component={FieldBSRenderText}
              />

              <div className={`${styles.flex3}`}>
                <Field
                  name='city'
                  type='text'
                  label='City'
                  component={FieldBSRenderText}
                />
                <Field
                  name='state'
                  label='State'
                  options={states}
                  optionMethod={(options: State[]) =>
                    options.map((o) => (
                      <option key={o.stateAbbr} value={o.stateAbbr}>
                        {o.stateAbbr}
                      </option>
                    ))
                  }
                  component={FieldBSRenderSelect}
                />
                <Field
                  name='zipCode'
                  type='text'
                  label='Zip Code'
                  validate={zipLength}
                  component={FieldBSRenderText}
                  maxLength={5}
                />
              </div>
              <Field
                name='phone'
                type='text'
                label='Phone'
                component={FieldBSRenderText}
              />
              <Field
                name='emailAddress'
                type='text'
                label='Email Address'
                component={FieldBSRenderText}
              />
              <div className='d-flex justify-content-center align-items-center gap1Rem py-3'>
                {pbm && (
                  <Button
                    type='button'
                    variant='danger'
                    size='sm'
                    onClick={() => setShowDelete(true)}
                  >
                    Archive
                  </Button>
                )}
                <Button type='submit' variant='primary' size='sm'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      />
      <DeleteConfirmationModal
        show={showDelete}
        setShow={setShowDelete}
        setNull={() => {}}
        handleDelete={handleDelete}
      />
    </PageScaffold>
  );
}
