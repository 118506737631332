import React, { useState, useEffect, useCallback } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import ClaimReviewExternalAudits from './ClaimReviewExternalAudits';
import ClaimReviewStatus from './ClaimReviewStatus';
import ClaimReviewSubmissions from './ClaimReviewSubmissions';

export default function ReviewTab({ claimType }: { claimType: number }) {
  let location = useLocation();
  let navigate = useNavigate();
  const [key, setKey] = useState<string>('status');

  const locationToEventKey = useCallback(() => {
    const split = location.pathname.split('/');
    if (split.length === 4 && split[3] === 'claim-review') {
      navigate('status');
    } else {
      setKey(split[4]);
    }
  }, [location, navigate]);

  useEffect(() => {
    locationToEventKey();
  }, [locationToEventKey]);

  return (
    <div className='w-100 h-100'>
      <Tab.Container
        defaultActiveKey='all'
        activeKey={key}
        id='reviewTabs'
        onSelect={(k) => setKey(k ?? '')}
      >
        <Nav variant='tabs' className='mb-3'>
          <Nav.Item>
            <NavLink to='status'>
              <Nav.Link as='button' eventKey='status'>
                Status Report
              </Nav.Link>
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to='audits'>
              <Nav.Link as='button' eventKey='audits'>
                External Audits
              </Nav.Link>
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to='submissions'>
              <Nav.Link as='button' eventKey='submissions'>
                Submissions
              </Nav.Link>
            </NavLink>
          </Nav.Item>
        </Nav>
        <Routes>
          <Route path='status' element={<ClaimReviewStatus />} />
          <Route path='audits' element={<ClaimReviewExternalAudits />} />
          <Route path='submissions' element={<ClaimReviewSubmissions />} />
        </Routes>
      </Tab.Container>
    </div>
  );
}
