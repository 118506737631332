import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { SiteFunctionDefaultGroup } from '../ApiTypes/SiteFunctionDefaultGroup';
import { UpdateSiteFunctionDefaultGroupRequest } from '../ApiTypes/UpdateSiteFunctionDefaultGroupRequest';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class SiteFunctionDefaultGroupApi {
  static list() {
    return axios.get<SiteFunctionDefaultGroup[]>(
      `${API_URL}/api/sitefunctiondefaultgroups`,
      getAuthHeader()
    );
  }
  static update(request: UpdateSiteFunctionDefaultGroupRequest) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/sitefunctiondefaultgroups/${request.siteFunctionDefaultGroupId}`,
      request,
      getAuthHeader()
    );
  }
}

export default SiteFunctionDefaultGroupApi;
