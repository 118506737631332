import { createReducer } from '@reduxjs/toolkit';
import { Claim } from '../ApiTypes/Claim';
import { Claimant } from '../ApiTypes/Claimant';
import { ClaimExcessPolicyInfo } from '../ApiTypes/ClaimExcessPolicyInfo';
import { ClaimPhysicianInfo } from '../ApiTypes/ClaimPhysicianInfo';
import { EdiTransaction } from '../ApiTypes/EdiTransaction';
import { Employer } from '../ApiTypes/Employer';
import { InsuranceCompany } from '../ApiTypes/InsuranceCompany';

import {
  fetchClaimByClaimNumber,
  fetchFullClaimByClaimNumber,
} from '../Actions/ClaimActions';

import {
  updateCurrentClaimInReducer,
  updateCurrentInsuranceCompanyInReducer,
  updateCurrentClaimantInReducer,
  updateCurrentEmployerInReducer,
  updateCurrentEmployerPolicyInReducer,
} from '../Actions/CurrentClaimActions';

import { clearState, clearSelectedClaim } from '../Actions/index';

import {
  fetchHasOpenNcm,
  updateUserPriorityClaim,
} from '../Actions/ReferenceActions';
import { ClaimTypeConfiguration } from '../ApiTypes/ClaimTypeConfiguration';
import { ClaimTypeTab } from '../ApiTypes/ClaimTypeTab';
import { ClaimProperty } from '../ApiTypes/ClaimProperty';
import {
  fetchClaimTypeConfigurations,
  fetchClaimSubTypes,
} from '../Actions/ClaimTypeConfigurationActions';
import { ClaimSubType } from '../ApiTypes/ClaimSubType';

type CurrentClaimReducerType = {
  claim: Claim | null;
  claimant: Claimant | null;
  claimExcessPolicy: ClaimExcessPolicyInfo | null;
  ediTransactions: EdiTransaction[] | null;
  employer: Employer | null;
  insuranceCompany: InsuranceCompany | null;
  physicianInfo: ClaimPhysicianInfo | null;
  hasOpenNcm: boolean;
  isUserPriorityClaim: boolean;
  isTpd: boolean;
  claimTypeTab: ClaimTypeTab[] | null;
  claimTypeConfiguration: ClaimTypeConfiguration[] | null;
  claimProperty: ClaimProperty | null;
  claimSubTypes: ClaimSubType[] | null;
};

const initialState: CurrentClaimReducerType = {
  claim: null,
  claimant: null,
  claimExcessPolicy: null,
  ediTransactions: [],
  employer: null,
  insuranceCompany: null,
  physicianInfo: null,
  hasOpenNcm: false,
  isUserPriorityClaim: false,
  isTpd: false,
  claimTypeTab: [],
  claimTypeConfiguration: [],
  claimProperty: null,
  claimSubTypes: [],
};

const CurrentClaimReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchFullClaimByClaimNumber.fulfilled, (state, action) => {
      return {
        ...state,
        claim: action.payload?.claim,
        claimant: action.payload?.claimant,
        claimExcessPolicy: action.payload?.claimExcessPolicy,
        ediTransactions: action.payload?.ediTransactions ?? [],
        employer: action.payload?.employer,
        insuranceCompany: action.payload?.insuranceCompany,
        physicianInfo: action.payload?.physicianInfo,
        hasOpenNcm: action.payload?.hasOpenNcm,
        isUserPriorityClaim: action.payload?.isUserPriorityClaim,
        isTpd: action.payload?.isTpd,
        claimTypeTab: action.payload?.claimTypeTab ?? [],
        claimTypeConfiguration: action.payload?.claimTypeConfiguration ?? [],
      };
    })
    .addCase(fetchClaimByClaimNumber.fulfilled, (state, action) => {
      return { ...state, claim: action?.payload };
    })
    .addCase(fetchClaimTypeConfigurations.fulfilled, (state, action) => {
      return { ...state, claimTypeConfiguration: action?.payload };
    })
    .addCase(updateCurrentClaimInReducer, (state, action) => {
      return { ...state, claim: action.payload };
    })
    .addCase(updateCurrentClaimantInReducer, (state, action) => {
      return {
        ...state,
        claimant: action.payload,
        claim: {
          ...state.claim,
          claimantId: action.payload.claimantId,
        } as Claim,
      };
    })
    .addCase(updateCurrentEmployerInReducer, (state, action) => {
      return {
        ...state,
        employer: action.payload,
        claim: {
          ...state.claim,
          employerId: action.payload.employerId,
        } as Claim,
      };
    })
    .addCase(updateCurrentEmployerPolicyInReducer, (state, action) => {
      return {
        ...state,
        claim: {
          ...state.claim,
          policyId: +action.payload,
        } as Claim,
      };
    })
    .addCase(updateCurrentInsuranceCompanyInReducer, (state, action) => {
      return {
        ...state,
        insuranceCompany: action.payload,
        claim: {
          ...state.claim,
          insurcoid: action.payload.insurcoid,
        } as Claim,
      };
    })
    .addCase(fetchHasOpenNcm.fulfilled, (state, action) => {
      return { ...state, hasOpenNcm: action.payload.hasOpen };
    })
    .addCase(updateUserPriorityClaim.fulfilled, (state, action) => {
      return { ...state, isUserPriorityClaim: action.payload.isPriority };
    })
    .addCase(fetchClaimSubTypes.fulfilled, (state, action) => {
      return { ...state, claimSubTypes: action?.payload };
    })
    .addCase(clearSelectedClaim, (state, action) => {
      return initialState;
    })
    .addCase(clearState, (state, action) => {
      return initialState;
    });
});

export default CurrentClaimReducer;
