import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaFolderOpen } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function WcPanelsDropdown() {
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant='secondary'
        id='WC-Panels'
        className='button-icon-text'
      >
        <FaFolderOpen />
        WC Panels
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Link to='/wcpanels'>
          <Dropdown.Item as='button'>View Panels</Dropdown.Item>
        </Link>
        <Link to='/wcpanel-approvals'>
          <Dropdown.Item as='button'>Panel Approval</Dropdown.Item>
        </Link>
        <Link to='/wcpanel-exceptions'>
          <Dropdown.Item as='button'>Panel Exceptions</Dropdown.Item>
        </Link>
      </Dropdown.Menu>
    </Dropdown>
  );
}
