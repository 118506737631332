import React, { useState, useEffect } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import WcPanelApi from '../../Api/WcPanelApi';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { WcPanel } from '../../ApiTypes/WcPanel';
import { useAppSelector } from '../../Reducers/Store';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderFilePicker from '../Common/FieldBSRenderFilePicker';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import BSRenderText from '../Common/BSRenderText';
import styles from './index.module.css';
import { EmployerPolicyPanel } from '../../ApiTypes/EmployerPolicyPanel';
import { WcPanelCoverage } from '../../ApiTypes/WcPanelCoverage';
import CoverageTable from './CoverageTable';
import { FaPlusCircle } from 'react-icons/fa';
import { FormApi } from 'final-form';
import { useNavigate } from 'react-router-dom';
import { WcPanelFormType } from './WcPanelFormType';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';

export default function PanelDetails({
  panel,
  panelId,
  linkedInsuranceCompanies,
  employerPolicyPanels,
  panelCoverages,
  handleViewPanel,
  getLinkedInsuranceCompanies,
  getPanelCoverages,
  fetchAll,
}: {
  panel: WcPanel | null;
  panelId?: string;
  linkedInsuranceCompanies: InsuranceCompany[];
  employerPolicyPanels: EmployerPolicyPanel[];
  panelCoverages: WcPanelCoverage[];
  handleViewPanel: () => void;
  getLinkedInsuranceCompanies: () => void;
  getPanelCoverages: () => void;
  fetchAll: () => void;
}) {
  const navigate = useNavigate();
  const activeInsuranceCompanies = useAppSelector((state) =>
    state.reference.insuranceCompanies.filter((x) => x.active)
  );

  const [checkedInsuranceCompanies, setCheckedInsuranceCompanies] = useState<
    number[]
  >([]);

  let coverageForm: FormApi<
    { coverageArea: string },
    Partial<{ coverageArea: string }>
  >;

  let formInstance: FormApi<WcPanelFormType, Partial<WcPanelFormType>>;

  useEffect(() => {
    init();
  }, [linkedInsuranceCompanies]);

  const init = () => {
    setCheckedInsuranceCompanies([
      ...linkedInsuranceCompanies.map((x) => x.insurcoid),
    ]);
  };

  const handleInsuranceCompanyChange = (id: number) => {
    const index = checkedInsuranceCompanies.indexOf(id);
    if (index > -1) {
      checkedInsuranceCompanies.splice(index, 1);
      setCheckedInsuranceCompanies(checkedInsuranceCompanies);
      removeInsuranceCompanyFromPanel(id);
    } else {
      setCheckedInsuranceCompanies((c) => [...c, id]);
      addInsuranceCompanyToPanel(id);
    }
  };

  const removeInsuranceCompanyFromPanel = (insurCoId: number) => {
    if (panelId && panelId !== 'new') {
      WcPanelApi.removeInsuranceCompanyFromPanel(+panelId, insurCoId)
        .then((res) => {
          if (!res.data.success) {
            toast.error(res.data.message);
          }
          getLinkedInsuranceCompanies();
        })
        .catch((err) => {
          console.log(err);
          getLinkedInsuranceCompanies();
          toast.error('Failed to remove insurance company from panel');
        });
    }
  };
  const addInsuranceCompanyToPanel = (insurCoId: number) => {
    if (panelId && panelId !== 'new') {
      WcPanelApi.addInsuranceCompanyToPanel(+panelId, insurCoId)
        .then((res) => {
          if (!res.data.success) {
            toast.error(res.data.message);
          }
          getLinkedInsuranceCompanies();
        })
        .catch((err) => {
          console.log(err);
          getLinkedInsuranceCompanies();
          toast.error('Failed to add insurance company to panel');
        });
    }
  };

  const removeCoverage = (coverage: WcPanelCoverage) => {
    if (panelId && panelId !== 'new') {
      WcPanelApi.deleteCoverage(coverage.id)
        .then((res) => {
          if (!res.data.success) {
            toast.error(res.data.message);
          }
          getPanelCoverages();
        })
        .catch((err) => {
          console.log(err);
          getPanelCoverages();
          toast.error('Failed to delete coverage from panel');
        });
    }
  };

  const addCoverage = (values: { coverageArea: string }) => {
    if (panelId && panelId !== 'new') {
      return WcPanelApi.addCoverage({
        coverageName: values.coverageArea,
        wcPanelId: +panelId,
      })
        .then((res) => {
          if (!res.data.success) {
            toast.error(res.data.message);
          } else {
            coverageForm.restart();
          }
          getPanelCoverages();
        })
        .catch((err) => {
          console.log(err);
          getPanelCoverages();
          toast.error('Failed to add coverage to panel');
        });
    }
    return Promise.resolve();
  };

  const onSubmit = async (values: WcPanelFormType) => {
    const { file, ...rest } = values;
    const copy = { ...rest };

    copy.doc = null;
    if (file?.file) {
      const docId = await handleUpload(file, copy.name ?? '');
      copy.docId = docId;
    }
    if (panelId && panelId !== 'new') {
      return updatePanel(copy, copy.autoAssign);
    }
    return createPanel(copy, copy.autoAssign);
  };

  const createPanel = (values: WcPanel, autoAssign: boolean) => {
    return WcPanelApi.createWcPanel(values)
      .then(async (res) => {
        if (res.data.success) {
          toast.success('Panel Created');
          formInstance.restart();
          if (autoAssign) {
            await autoAssignLocations(
              +res.data.affectedEntityIdentifier,
              values.year!
            );
          }
          navigate(`/wcpanels/${res.data.affectedEntityIdentifier}`);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create WC Panel');
      });
  };
  const updatePanel = (values: WcPanel, autoAssign: boolean) => {
    return WcPanelApi.updateWcPanel(values)
      .then(async (res) => {
        if (res.data.success) {
          toast.success('Panel updated');
          formInstance.restart();
          if (autoAssign) {
            await autoAssignLocations(
              +res.data.affectedEntityIdentifier,
              values.year!
            );
          }
          fetchAll();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update WC Panel');
      });
  };

  const autoAssignLocations = (wcPanelId: number, year: number) => {
    return WcPanelApi.autoAssignLocations(wcPanelId, year)
      .then((res) => {
        if (res.data.success) {
        } else {
          toast.error(res.data.message);
        }
        return true;
      })
      .catch((err) => {
        console.log(err);
        return true;
        toast.error('Failed to auto assign locations');
      });
  };

  const handleUpload = (file: { file?: File; name?: string }, name: string) => {
    const fd = new FormData();
    let newName = name.replace('/', '_');
    let fileToUpload = file.file;
    fileToUpload = new File([file.file!], `${file.name}`, {
      type: file.file!.type,
    });
    fd.append('name', newName);
    fd.append('file', fileToUpload);
    return WcPanelApi.uploadWcPanelDoc(fd)
      .then((res) => {
        if (res.data.success) {
          return +res.data.affectedEntityIdentifier;
        } else {
          toast.error(res.data.message);
          return 0;
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to upload document');
        return 0;
      });
  };

  return (
    <div>
      <div className={`${styles.grid2} pb-3`}>
        <div>
          <RFForm
            onSubmit={onSubmit}
            initialValues={
              panel
                ? { ...panel, file: {}, autoAssign: false }
                : { file: {}, autoAssign: false }
            }
            render={({ handleSubmit, form, values, submitting }) => {
              formInstance = form;
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='name'
                    label='Name'
                    type='text'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='notes'
                    label='Notes'
                    rows={3}
                    component={FieldBSRenderTextArea}
                  />
                  {panel && panel.doc?.filename && (
                    <BSRenderText
                      type='text'
                      name='fileName'
                      label='Current File Name'
                      readOnly
                      value={panel.doc.filename!}
                    />
                  )}
                  <Field
                    name='file'
                    type='file'
                    component={FieldBSRenderFilePicker}
                  />
                  <Field
                    name='year'
                    label='Year'
                    type='text'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='autoAssign'
                    label='Auto Assign Locations?'
                    type='checkbox'
                    checked={values.autoAssign === true}
                    component={FieldBSRenderCheckbox}
                  />
                  <div className='d-flex justify-content-center align-items-center gap1Rem py-3'>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    {panel && panel.docId && (
                      <Button
                        type='button'
                        variant='outline-primary'
                        size='sm'
                        className='button-icon-text'
                        onClick={handleViewPanel}
                      >
                        View Panel
                      </Button>
                    )}
                  </div>
                </Form>
              );
            }}
          />
          <hr />
          {panelId && panelId !== 'new' ? (
            <RFForm
              onSubmit={addCoverage}
              initialValues={{}}
              render={({ handleSubmit, form, values, submitting }) => {
                coverageForm = form;
                return (
                  <Form onSubmit={handleSubmit}>
                    <div className='d-flex justify-content-center align-items-center gap1Rem py-3'>
                      <Field
                        name='coverageArea'
                        label='Coverage Area'
                        type='text'
                        validate={requiredField}
                        component={FieldBSRenderText}
                      />
                      <Button
                        type='submit'
                        variant='outline-primary'
                        size='sm'
                        className='button-icon-text'
                      >
                        {submitting ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          <>
                            <FaPlusCircle /> Coverage
                          </>
                        )}
                      </Button>
                      <Button
                        type='button'
                        variant='outline-primary'
                        size='sm'
                        className='button-icon-text'
                        onClick={() => {
                          form.restart();
                        }}
                      >
                        Clear
                      </Button>
                    </div>
                  </Form>
                );
              }}
            />
          ) : (
            <p className='text-center fw-bold'>
              Save new WC Panel before adding coverages
            </p>
          )}
          <CoverageTable
            data={panelCoverages}
            removeCoverage={removeCoverage}
          />
        </div>
        <div>
          <fieldset>
            <legend>Insurance Companies</legend>
            {panelId && panelId !== 'new' ? (
              activeInsuranceCompanies.map((d) => {
                return (
                  <div className='form-check' key={`${d.insurcoid}`}>
                    <label className='form-check-label'>
                      <input
                        name='insurCoIds'
                        type='checkbox'
                        className='form-check-input'
                        checked={checkedInsuranceCompanies.includes(
                          d.insurcoid
                        )}
                        onChange={(e) => {
                          handleInsuranceCompanyChange(d.insurcoid);
                        }}
                      />
                      {d.fullname}
                    </label>
                  </div>
                );
              })
            ) : (
              <p className='text-center fw-bold'>
                Save new WC Panel before adding insurance companies
              </p>
            )}
          </fieldset>
        </div>
      </div>
    </div>
  );
}
