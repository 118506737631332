import React, { useState } from 'react';
import { Alert, Button, Container, Modal } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import styles from './index.module.css';
import { StopPaymentOption } from '../../ApiTypes/StopPaymentOption';
import { useAppSelector } from '../../Reducers/Store';
import { StylesConfig } from 'react-select';

export default function VCheckExplanation({
  show,
  setShow,
  numberOfChecks,
  action,
  submitFunction,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  numberOfChecks: number;
  action: string;
  submitFunction: (explanation: string, action: string) => void;
}) {
  const [explanation, setExplanation] = useState<string>('');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const { stopPaymentOptions } = useAppSelector((state) => state.reference);
  const handleInitialSubmit = () => {
    if (!explanation) {
      toast.error('Please include an explanation');
      return;
    }
    setShowAlert(true);
  };

  // const dropDownStyles: StylesConfig<StopPaymentOption> = {
  //   control: (styles) => ({ ...styles, cursor: 'pointer'})
  // }

  const dropDownStyles = {
    control: () => ({ cursor: 'pointer' }),
    option: () => ({ cursor: 'pointer' }),
  };

  const handleStopPaymentOptionSelected = (value: string) => {
    setExplanation(value);
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
        setShowAlert(false);
        setExplanation('');
      }}
      dialogClassName=''
      aria-labelledby='VCheckExplanation-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='VCheckExplanation-modal'>
          <FaCheck className='pe-1' /> Check Status Update
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <div className={`position-relative mb-3`}>
            <label
              htmlFor='stopPaymentOptions'
              className='form-label fs-6  m-0'
            >
              Stop Payment Options
            </label>
            <select
              name='stopPaymentOptions'
              className='form-select form-select-sm '
              {...stopPaymentOptions}
              onChange={(e) => {
                handleStopPaymentOptionSelected(e.target.value);
              }}
            >
              <option value=''>--Select--</option>
              {stopPaymentOptions.map((e, key) => {
                return (
                  <option key={key} value={e.description}>
                    {e.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className={`position-relative mb-3`}>
            <label htmlFor='explanation' className='form-label fs-6  m-0'>
              Reason for updating the check status
            </label>
            <div>
              <textarea
                value={explanation}
                name='explanation'
                rows={3}
                className='form-control form-control-sm'
                placeholder='Explanation'
                onChange={(e) => {
                  setExplanation(e.target.value);
                }}
              />
            </div>
          </div>
          <div className='d-flex justify-content-center align-items-center'>
            {!showAlert && (
              <Button
                type='button'
                variant='primary'
                size='sm'
                onClick={() => {
                  handleInitialSubmit();
                }}
              >
                Submit
              </Button>
            )}
          </div>
          {showAlert && (
            <Alert
              variant='info'
              className='mt-3 text-center'
              dismissible
              onClose={() => {
                setShowAlert(false);
                setShow(false);
              }}
            >
              <p>{`${numberOfChecks} checks selected!`}</p>
              <p>
                Are you sure you want to{' '}
                {action === 'stop' ? 'stop payment on' : action} these checks?
              </p>
              <div className='d-flex justify-content-center gap1Rem'>
                <Button
                  type='button'
                  onClick={() => {
                    setShowAlert(false);
                    setShow(false);
                  }}
                  variant='secondary'
                >
                  Cancel
                </Button>
                <Button
                  type='button'
                  onClick={() => {
                    submitFunction(explanation, action);
                    setShowAlert(false);
                    setShow(false);
                  }}
                  variant='primary'
                >
                  Submit
                </Button>
              </div>
            </Alert>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}
