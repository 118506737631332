import React from 'react';
import { Alert, Button, Container, Modal } from 'react-bootstrap';
import { FaUpload } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ExportApi from '../../Api/ExportApi';
import { downloadCsv, downloadPgp } from '../../Utils';

export default function YkkExport({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const exportYkk = async () => {
    setShow(false);
    try {
      const res = await toast.promise(ExportApi.exportYkk(), {
        pending: `Exporting`,
        success: 'Success',
        error: `Failed to export data`,
      });
      res.data.map((d) => {
        if (d.fileName.endsWith('csv')) {
          downloadCsv(d.fileName, d.file);
        }
        if (d.fileName.endsWith('pgp')) {
          downloadPgp(d.fileName, d.file);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='YkkExport-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='YkkExport-modal'>
          <FaUpload className='pe-1' /> YKK Export
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Alert variant='info' className='text-center'>
            Files will be uploaded
          </Alert>
          <div className='d-flex justify-content-center align-items-center'>
            <Button
              type='button'
              variant='primary'
              size='sm'
              onClick={() => {
                exportYkk();
              }}
            >
              Upload
            </Button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
