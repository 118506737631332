import accounting from 'accounting';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { displayDateOnly } from '../../Utils';
import { EDIStatus } from '../../ApiTypes/EdiStatus';

export const ediExportHelper = (transactions: EdiTransaction[]) => {
  const columns = [
    'XCN',
    'Claim Number',
    'DOI',
    'Claimant',
    'Form',
    'Maint Type Code',
    'Date',
    'Creator',
    'Status',
    'Error',
  ];

  const rows: any[][] = [];

  transactions.forEach((t) => {
    const tRow = [
      t.claim?.ediClaimNo,
      t.claimno,
      displayDateOnly(t.claim?.injuryDate ?? ''),
      `${t.claimant?.firstName} ${t.claimant?.lastName}`,
      t.form,
      t.maintTypeCode,
      displayDateOnly(t.logDate ?? ''),
      t.logCreator,
      t.logStatusString,
      t.notes,
    ];

    rows.push(tRow);

    if (t.ediTransactionLogs) {
      t.ediTransactionLogs.forEach((x) => {
        const logRow = [
          '',
          x.claimNo,
          '',
          '',
          '',
          '',
          displayDateOnly(x.dateTime ?? ''),
          x.creator,
          EDIStatus[x.status!] ?? '',
          x.notes,
        ];

        rows.push(logRow);
      });
    }
  });

  return {
    columns,
    rows,
    fileName: '',
  };
};
