import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { UserInsuranceCompanyAccess } from '../ApiTypes/UserInsuranceCompanyAccess';

class UserInsuranceCompanyAccessApi {
  static getAccessForUser(userId: string) {
    return axios.get<UserInsuranceCompanyAccess[]>(
      `${API_URL}/api/userinsurancecompanyaccess?userId=${userId}`,
      getAuthHeader()
    );
  }
  static getById(id: number) {
    return axios.get<UserInsuranceCompanyAccess>(
      `${API_URL}/api/userinsurancecompanyaccess/${id}`,
      getAuthHeader()
    );
  }
  static delete(id: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/userinsurancecompanyaccess/${id}`,
      getAuthHeader()
    );
  }
  static updateAccess(userId: string, insurCoId: number) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/userinsurancecompanyaccess?userId=${userId}&insurCoId=${insurCoId}`,
      {},
      getAuthHeader()
    );
  }
  static deleteEmployerAccess(id: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/useremployeraccesses/${id}`,
      getAuthHeader()
    );
  }
  static createEmployerAccess(id: number, employerId: number) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/useremployeraccesses?userAccessId=${id}&employerId=${employerId}`,
      {},
      getAuthHeader()
    );
  }
}

export default UserInsuranceCompanyAccessApi;
