import { FormApi } from 'final-form';
import React from 'react';
import { Field } from 'react-final-form';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { parseDatesForServer } from '../../Utils';
import { formatNumbers, cleanMoney } from '../../Utils/InputFormatters';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';
import FieldBSRenderPercent from '../Common/FieldBSRenderPercent';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';

import styles from './index.module.css';

export default function LumpSumForm({
  values,
  form,
  transaction,
}: {
  form: FormApi<EdiTransaction, Partial<EdiTransaction>>;
  values: Partial<EdiTransaction>;
  transaction: EdiTransaction | null;
}) {
  const lumpSumOptions = [
    {
      label: 'Settlement Full',
      value: 'SF',
    },
    {
      label: 'Settlement Partial',
      value: 'SP',
    },
    {
      label: 'Agreement Stipulated',
      value: 'SS',
    },
    {
      label: 'Award',
      value: 'AW',
    },
    {
      label: 'Advance',
      value: 'AD',
    },
  ];

  return (
    <div>
      <Field
        name='lumpSumPaymentReasonCode'
        label='Lump Sum Settlement Code'
        options={lumpSumOptions}
        optionMethod={(options: { label: string; value: string }[]) =>
          options.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))
        }
        disabled={values.benefitTypeCode !== '500'}
        component={FieldBSRenderSelect}
      />
      <div className={`${styles.edi2Grid}`}>
        <Field
          name='benefitCheckDate'
          label='Check Date'
          parse={parseDatesForServer}
          component={FieldBSRenderDate}
        />
        <Field
          name='paymentAmount'
          label='Check Amount'
          type='text'
          format={formatNumbers}
          parse={cleanMoney}
          component={FieldBSRenderMoney}
        />
      </div>
      <div className={`${styles.edi3Grid}`}>
        <div className='form-check'>
          <label className='form-check-label'>
            <input
              name='penalty'
              type='checkbox'
              className='form-check-input'
              checked={(values?.penaltyAmount ?? 0) > 0}
              readOnly
            />
            Include a
          </label>
        </div>
        <Field
          name='penaltyPercentage'
          label='Penalty Percent'
          placeholder='Penalty Percent'
          type='text'
          component={FieldBSRenderPercent}
        />
        <Field
          name='penaltyAmount'
          label='Penalty Amount'
          type='text'
          format={formatNumbers}
          parse={cleanMoney}
          component={FieldBSRenderMoney}
        />
      </div>
    </div>
  );
}
