import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';
import { displayDateOnly } from '../../Utils';
import { InsCoLog } from '../../ApiTypes/InsCoLog';

export default function InsCoLogTable({
  data,
  handleView,
}: {
  data: InsCoLog[];
  handleView: (l: InsCoLog) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<InsCoLog>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      size: 75,
      cell: ({ row }) => {
        return (
          <Button
            type='button'
            variant='primary'
            size='sm'
            onClick={() => {
              handleView(row.original);
            }}
          >
            <FaEye />
          </Button>
        );
      },
    },
    {
      header: 'Date Logged',
      accessorKey: 'dateLogged',
      sortingFn: 'datetime',
      filterFn: 'equals',
      size: 100,
      cell: (d) => displayDateOnly(d.row.original.dateLogged ?? ''),
    },
    {
      header: 'Ins Co Id',
      accessorFn: (d) => `${d.insurcoid}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 75,
    },
    {
      header: 'Data',
      accessorKey: 'dataToDisplay',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 300,
    },
    {
      header: 'Comments',
      accessorKey: 'userComments',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Changed By',
      accessorKey: 'changedBy.userName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 100,
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
