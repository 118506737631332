import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import accounting from 'accounting';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { NCMDashboardChartObj } from '../../ApiTypes/NCMDashboardChartObj';
import styles from './index.module.css';
import { addDays } from 'date-fns';
import { displayDateOnly } from '../../Utils';

export default function NcmDashboardChart({
  data,
  days,
}: {
  days: number;
  data: NCMDashboardChartObj[];
}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ChartDataLabels
  );

  const colors = ['#3f95ff', '#e4581b', '#8E999F'];

  const getData = (propsData: NCMDashboardChartObj[]): any => {
    const ms = data.reduce((acc, curr) => {
      if (!acc.includes(curr.assignedTo)) {
        acc.push(curr.assignedTo);
      }
      return acc;
    }, [] as string[]);

    const dateList: Date[] = [];
    for (let x = days; x > 0; x--) {
      var date = addDays(new Date(), -x);
      dateList.push(date);
    }

    return {
      labels: dateList.map((x) => displayDateOnly(x)),
      datasets: ms.map((m, i) => {
        return {
          label: m,
          data: dateList.map((d) => {
            const list = data.filter(
              (f) => (!f.date || new Date(f.date) > d) && f.assignedTo === m
            );
            return list.length;
          }),
          fill: false,
          type: 'line',
          backgroundColor: colors[i],
          borderColor: colors[i],
        };
      }),
    };
  };

  const getOptions = (): any => ({
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    maintainAspectRatio: false,
    stacked: false,
    plugins: {
      datalabels: {
        color: '#8E999F',
        anchor: 'end',
        align: 'start',
        font: {
          weight: 'bold',
        },
      },
      tooltip: {},
      legend: {
        position: 'right',
        align: 'start',
      },
    },

    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        ticks: {
          beginAtZero: false,
        },
      },
    },
  });

  return (
    <div>
      <div className={`${styles.chartArea}`}>
        {data ? (
          <Line data={getData(data)} options={getOptions()} />
        ) : (
          'No Available Data'
        )}
      </div>
    </div>
  );
}
