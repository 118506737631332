import React, { useState } from 'react';
import { FaPlusCircle, FaUsers } from 'react-icons/fa';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import PageScaffold from '../PageScaffold/PageScaffold';

import styles from './index.module.css';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import ProviderApi from '../../Api/ProviderApi';
import { ProviderSearchRequest } from '../../ApiTypes/ProviderSearchRequest';
import { ProviderSearchResult } from '../../ApiTypes/ProviderSearchResult';
import ProviderMaintenanceTable from './ProviderMaintenanceTable';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import { FormApi } from 'final-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function ProviderMaintenance() {
  const [providers, setProviders] = useState<ProviderSearchResult[]>([]);
  const [selectedProvider, setSelectedProvider] =
    useState<ProviderSearchResult | null>(null);

  const [showDelete, setShowDelete] = useState<boolean>(false);

  let formInstance: FormApi<
    ProviderSearchRequest,
    Partial<ProviderSearchRequest>
  >;

  const searchProviders = (values: ProviderSearchRequest) => {
    return ProviderApi.searchProviders(values)
      .then((res) => {
        setProviders(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onSubmit = (values: ProviderSearchRequest) => {
    return searchProviders(values);
  };

  const handleDeleteProviderClick = (provider: ProviderSearchResult) => {
    setSelectedProvider(provider);
    setShowDelete(true);
  };

  const deleteProvider = () => {
    if (selectedProvider) {
      return ProviderApi.deleteProvider(selectedProvider?.providerId)
        .then((res) => {
          if (res.data.success) {
            setSelectedProvider(null);
            setShowDelete(false);
            formInstance.submit();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to delete provider');
        });
    }
    return Promise.resolve();
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaUsers className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Provider Maintenance</h1>
          </div>
        </div>
      </div>
      <div className={styles.grid2}>
        <RFForm
          onSubmit={onSubmit}
          initialValues={{
            activeOnly: false,
          }}
          render={({ handleSubmit, form, values, submitting }) => {
            formInstance = form;
            return (
              <Form onSubmit={handleSubmit}>
                <div className={styles.grid4}>
                  <Field
                    name='name'
                    type='text'
                    label='Search Name'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='taxId'
                    type='text'
                    label='Search Tax Id'
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='activeOnly'
                    type='checkbox'
                    label='Active Only'
                    component={FieldBSRenderCheckbox}
                  />
                  <div>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Search'
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        />
        <div>
          <Link to='/provider-maintenance/new'>
            <Button type='button' variant='outline-primary' size='sm'>
              <FaPlusCircle /> New
            </Button>
          </Link>
        </div>
      </div>
      <ProviderMaintenanceTable
        data={providers}
        deleteProvider={handleDeleteProviderClick}
      />
      <DeleteConfirmationModal
        show={showDelete}
        setShow={setShowDelete}
        setNull={setSelectedProvider}
        handleDelete={deleteProvider}
      />
    </PageScaffold>
  );
}
