import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../Reducers/Store';
import { ClaimNote } from '../../../ApiTypes/ClaimNote';
import { CaseManagement } from '../../../ApiTypes/CaseManagement';
import NcmApi from '../../../Api/NcmApi';
import styles from './index.module.css';
import FieldBSRenderText from '../../Common/FieldBSRenderText';
import FieldBSRenderDate from '../../Common/FieldBSRenderDate';
import { cleanMoney, makeMoney } from '../../../Utils/InputFormatters';
import FieldBSRenderSelect from '../../Common/FieldBSRenderSelect';
import { CaseManagementResolutionType } from '../../../ApiTypes/CaseManagementResolutionType';
import NcmNoteTable from './NcmNoteTable';
import EditCreateNote from '../../EditCreateNote/EditCreateNote';
import { FaPlusCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { parseDatesForServer } from '../../../Utils';

export default function NcmDetail({
  getNcms,
  claimType,
}: {
  getNcms: () => void;
  claimType: number;
}) {
  let { claimNumber, ncmId } = useParams();
  const [notesForNcm, setNotesForNcm] = useState<ClaimNote[]>([]);
  const [selectedNote, setSelectedNote] = useState<ClaimNote | null>(null);
  const [ncm, setNcm] = useState<CaseManagement | null>(null);
  const [showNote, setShowNote] = useState<boolean>(false);

  const { caseManagementResolutionTypes } = useAppSelector(
    (state) => state.reference
  );

  const { userModel } = useAppSelector((state) => state.user);

  const getNotesForNcm = useCallback(() => {
    if (ncmId && claimNumber) {
      NcmApi.getNotesForNcm(+ncmId, claimNumber)
        .then((res) => {
          setNotesForNcm(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setNotesForNcm([]);
    }
  }, [ncmId, claimNumber]);

  const getNcm = useCallback(() => {
    if (claimNumber && ncmId) {
      NcmApi.getNcmById(claimNumber, +ncmId)
        .then((res) => {
          setNcm(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [ncmId, claimNumber]);

  useEffect(() => {
    getNcm();
    getNotesForNcm();
  }, [getNcm, getNotesForNcm]);

  const closeNote = () => {
    setSelectedNote(null);
    setShowNote(false);
  };

  const onSubmit = (values: CaseManagement) => {
    return updateNcm(values);
  };

  const updateNcm = (ncm: CaseManagement) => {
    return NcmApi.updateNcm(ncm, claimNumber!)
      .then((res) => {
        if (res.data.success) {
          getNcms();
        } else {
          toast.error(res.data.message);
        }
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const generateInvoice = (note: ClaimNote) => {
    return NcmApi.generateInvoice(claimNumber!, +ncmId!, note.noteId)
      .then((res) => {
        if (res.data.success) {
          toast.success('Invoice Generated!');
          getNcms();
        } else {
          toast.error('Failed to generate invoice');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to generate Invoice');
      });
  };

  return (
    <div>
      <RFFForm
        onSubmit={onSubmit}
        initialValues={ncm ?? {}}
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className={`${styles.grid3}`}>
                <Field
                  name='userId'
                  label='User'
                  type='text'
                  component={FieldBSRenderText}
                  disabled
                />
                <Field
                  name='assignedTo'
                  label='Assigned To'
                  type='text'
                  component={FieldBSRenderText}
                  disabled
                />
                <Field
                  name='dateStarted'
                  label='Date Started'
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />
                <Field
                  name='dateCompleted'
                  label='Date Completed'
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />
                <Field
                  name='fee'
                  label='Fee'
                  type='text'
                  component={FieldBSRenderText}
                  format={makeMoney}
                  parse={cleanMoney}
                  disabled
                />
                <Field
                  name='billed'
                  label='Billed'
                  options={[
                    {
                      name: 'YES',
                      value: 'true',
                    },
                    {
                      name: 'NO',
                      value: '',
                    },
                  ]}
                  optionMethod={(options: { name: string; value: string }[]) =>
                    options.map((o) => (
                      <option key={o.name} value={o.value}>
                        {o.name}
                      </option>
                    ))
                  }
                  showNullOption={false}
                  component={FieldBSRenderSelect}
                  disabled
                />
                <Field
                  name='resolutionId'
                  label='Resolution'
                  component={FieldBSRenderSelect}
                  options={caseManagementResolutionTypes}
                  optionMethod={(options: CaseManagementResolutionType[]) =>
                    options.map((o) => (
                      <option key={o.id} value={o.id}>
                        {o.name}
                      </option>
                    ))
                  }
                  disabled
                />
                <Field
                  name='totalBilled'
                  label='Total Billed'
                  type='text'
                  component={FieldBSRenderText}
                  format={makeMoney}
                  parse={cleanMoney}
                  disabled
                />
                <Field
                  name='userCompletedId'
                  label='Completed By'
                  type='text'
                  component={FieldBSRenderText}
                  disabled
                />
              </div>
              <div className='d-flex justify-content-center'>
                <Button type='submit' variant='primary' size='sm'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Submit'
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      />
      <div className='mt-3'>
        <Button
          type='button'
          variant='primary'
          size='sm'
          className='button-icon-text m-2'
          onClick={() => setShowNote(true)}
          disabled={userModel?.user?.isNcm !== true}
        >
          <FaPlusCircle /> Note
        </Button>
        <NcmNoteTable
          notes={notesForNcm}
          selectedNote={selectedNote}
          setSelectedNote={setSelectedNote}
          setShow={setShowNote}
          generateInvoice={generateInvoice}
        />
      </div>
      <EditCreateNote
        selectedNote={selectedNote}
        show={showNote}
        setShow={closeNote}
        getClaimNotes={getNotesForNcm}
        claimNumber={claimNumber ?? ''}
        claimType={claimType}
        tabFrom=''
      />
    </div>
  );
}
