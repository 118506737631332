import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { EmailDocumentsRequest } from '../ApiTypes/EmailDocumentsRequest';
import { IsClaimTpdResponse } from '../ApiTypes/IsClaimTpdResponse';
import { RecurringPayment } from '../ApiTypes/RecurringPayment';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { SendIndemnityDetailsRequest } from '../ApiTypes/SendIndemnityDetailsRequest';
import { VRecurringPayment } from '../ApiTypes/VRecurringPayment';

class RecurringPaymentsApi {
  static getRecurringPaymentsByClaimNumber(claimNumber: string) {
    return axios.get<RecurringPayment[]>(
      `${API_URL}/api/claims/${claimNumber}/recurringpayments`,
      getAuthHeader()
    );
  }
  static saveNewRecurringPayment(recurringPayment: RecurringPayment) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/recurringpayments`,
      recurringPayment,
      getAuthHeader()
    );
  }
  static updateRecurringPayment(recurringPayment: RecurringPayment) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/recurringpayments/${recurringPayment.recurringPmtId}`,
      recurringPayment,
      getAuthHeader()
    );
  }
  static sendIndemnityDetails(request: SendIndemnityDetailsRequest) {
    return axios.post<EmailDocumentsRequest>(
      `${API_URL}/api/recurringpayments/${request.rp.recurringPmtId}`,
      request,
      getAuthHeader()
    );
  }
  static getRecurringPayments(accountNumber: string, asOf: string) {
    return axios.get<VRecurringPayment[]>(
      `${API_URL}/api/recurringpayments/${accountNumber}?asOfDate=${asOf}`,
      getAuthHeader()
    );
  }
  static processRecurringPayments(accountNumber: string, asOf: string) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/recurringpayments/process/${accountNumber}?asOfDate=${asOf}`,
      {},
      getAuthHeader()
    );
  }
  static isClaimTpd(claimNumber: string) {
    return axios.get<IsClaimTpdResponse>(
      `${API_URL}/api/claims/${claimNumber}/istpd`,
      getAuthHeader()
    );
  }
  static insertReminders() {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/recurringpayments/insertreminders`,
      {},
      getAuthHeader()
    );
  }
  static getMaxBenefitForDate(injuryDate: string, isTpd: boolean) {
    return axios.get<{ maxForDate: number }>(
      `${API_URL}/api/getmaxbenefitbydate?injuryDate=${injuryDate}&isTpd=${isTpd}`,
      getAuthHeader()
    );
  }
}

export default RecurringPaymentsApi;
