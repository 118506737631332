import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaMoneyCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import PaymentsApi from '../../Api/PaymentsApi';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { PaymentBatch } from '../../ApiTypes/PaymentBatch';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import BSControlledSelect from '../Common/BSControlledSelect';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import ViewCheckBatchesTable from './ViewCheckBatchesTable';
import {
  setDocViewFileDownload,
  setShowDocView,
} from '../../Actions/DocViewActions';
import { FileDownload } from '../../Types/FileDownLoad';
import {
  AvailableReport,
  FileFormat,
  ReportRequest,
} from '../../ApiTypes/ReportRequest';
import ChecksApi from '../../Api/ChecksApi';
import ReportApi from '../../Api/ReportApi';
import { downloadPdf } from '../../Utils';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

export default function ViewCheckBatches() {
  const dispatch = useAppDispatch();
  const { insuranceCompanies } = useAppSelector((state) => state.reference);
  const { userName } = useAppSelector((state) => state.user);
  const [selectedAccount, setSelectedAccount] = useState<string>('');
  const [showAll, setShowAll] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);

  const [batches, setBatches] = useState<PaymentBatch[]>([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedBatches, setSelectedBatches] = useState<PaymentBatch[]>([]);

  const allAccountsUserNames = ['asmith', 'ben', 'danie_000'];

  useEffect(() => {
    fetchCheckBatches();
  }, [selectedAccount, showAll]);

  const setShowDx = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownload = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  const printSelected = (row: PaymentBatch) => {
    ChecksApi.printBatch(`${row.pmtBatchId}`)
      .then((res) => {
        if (res.data.success) {
          setFileDownload({
            file: res.data.reportBytes,
            fileName: res.data.reportFileName,
          });
          setShowDx(true);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get batch');
      });
  };
  const fetchCheckBatches = () => {
    let accountNumber = selectedAccount;
    if (allAccountsUserNames.includes(userName.toLowerCase())) {
      accountNumber = showAll ? 'all' : selectedAccount;
    }
    if (accountNumber) {
      setFetching(true);
      PaymentsApi.getPaymentBatches(accountNumber)
        .then((res) => {
          setBatches(res.data);
          setFetching(false);
        })
        .catch((err) => {
          setFetching(false);
          console.log(err);
        });
    }
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaMoneyCheck className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>View Check Batches</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center gap1Rem'>
        <BSControlledSelect
          name='account'
          label='Account'
          onChange={(acc) => {
            setSelectedAccount(acc);
          }}
          value={selectedAccount}
          options={insuranceCompanies}
          optionMethod={(options: InsuranceCompany[]) =>
            options.map((o) => (
              <option key={o.accountnumber} value={o.accountnumber ?? ''}>
                {`${o.accountnumber} - ${o.shortname}`}
              </option>
            ))
          }
        />
        {allAccountsUserNames.includes(userName.toLowerCase()) && (
          <div className='form-check'>
            <label className='form-check-label'>
              <input
                type='checkbox'
                name='showAll'
                className='form-check-input'
                checked={showAll}
                onChange={(e) => {
                  setShowAll(!showAll);
                }}
                disabled={
                  !allAccountsUserNames.includes(userName.toLowerCase())
                }
              />
              Show All Accounts
            </label>
          </div>
        )}
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={() => {
            fetchCheckBatches();
          }}
        >
          {fetching ? (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            'Refresh'
          )}
        </Button>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          disabled={selectedBatches.length === 0}
          onClick={async () => {
            const accountNumber = showAll ? 'all' : selectedAccount;
            const printDates: string[] = [];
            const pmtBatchIds = selectedBatches.map((x) => {
              if (x.batchPrintDate) {
                printDates.push(x.batchPrintDate ?? '');
              }
              return x.pmtBatchId;
            });

            printDates.sort((a, b) => {
              const aDate = new Date(a ?? '');
              const bDate = new Date(b ?? '');
              return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
            });

            const toDate = printDates[0];
            const fromDate = printDates[printDates.length - 1];

            var request: ReportRequest = {
              accountNumber,
              claimNumber: '',
              checkNumber: '',
              claimsByAccountNumberAndTreatyYearCount: 0,
              claimsByEmployerAndTreatyYearCount: 0,
              employerId: null,
              exportFormat: FileFormat.PortableDocFormat,
              fromDate,
              genericCheckReportName: '',
              insurCoId: null,
              paymentBatchIds: pmtBatchIds,
              report: AvailableReport.CheckRegisterNew3,
              severity: '',
              status: '',
              treatyYear: null,
              treatyYearTo: null,
              toDate,
              userId: '',
              fromName: '',
              openOnly: false,
              title: '',
            };

            try {
              const res = await toast.promise(
                ReportApi.getCrystalReport(request),
                {
                  pending: `Fetching Report`,
                  success: 'Success',
                  error: `Failed to fetch report`,
                }
              );
              if (res.data.success) {
                downloadPdf(res.data.reportFileName, res.data.reportBytes);
              } else {
                toast.error(<ErrorMessage messages={res.data.messages} />);
              }
            } catch (error) {
              console.log(error);
            }
          }}
        >
          Print Registers
        </Button>
      </div>
      <ViewCheckBatchesTable
        data={batches}
        printSelected={printSelected}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        setSelectedBatches={setSelectedBatches}
      />
    </PageScaffold>
  );
}
