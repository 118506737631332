import React, { useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { MedQueryObj } from '../../ApiTypes/MedQueryObj';
import { Button } from 'react-bootstrap';
import styles from './index.module.css';
import { FaEye, FaPlusCircle } from 'react-icons/fa';
import IndeterminateCheckbox from '../Common/IndeterminateCheckbox';
import { displayDateOnly } from '../../Utils';
import { Link } from 'react-router-dom';

export default function MedicareTable({
  data,
  handleAddNoteClick,
  selectedQueries,
  setSelectedQueries,
}: {
  data: MedQueryObj[];
  handleAddNoteClick: (q: MedQueryObj) => void;
  selectedQueries: MedQueryObj[];
  setSelectedQueries: (q: MedQueryObj[]) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  const handleRowSelectionChange = () => {
    setSelectedQueries(
      table.getSelectedRowModel().flatRows.map((r) => r.original)
    );
  };

  const columnData: ColumnDef<MedQueryObj>[] = [
    {
      header: ({ table }) => (
        <div className={`${styles.actionColumn}`}>
          {Object.keys(rowSelection).length}
          <IndeterminateCheckbox
            className='form-check-input'
            {...{
              id: 'totalSelectedRows',
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      id: 'options',
      enableSorting: false,
      enableColumnFilter: false,
      size: 250,
      cell: ({ row }) => {
        const ssn = row.original.ssn;
        let showDanger = false;
        if (ssn.length < 9 || ssn === '000000000' || ssn === '111111111') {
          showDanger = true;
        }
        return (
          <div
            className={`${styles.actionColumn} ${
              showDanger ? 'bg-danger' : ''
            }`}
          >
            <IndeterminateCheckbox
              className='form-check-input'
              {...{
                id: row.id,
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
            <Link to={`/claims/${row.original.claimNo}`}>
              <Button
                className='button-icon-text'
                type='button'
                size='sm'
                variant='primary'
                title='View'
              >
                <FaEye /> Claim
              </Button>
            </Link>
            <Button
              className='button-icon-text'
              type='button'
              size='sm'
              variant='primary'
              title='View'
              onClick={() => {
                handleAddNoteClick(row.original);
              }}
            >
              <FaPlusCircle /> Note
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Adjuster',
      accessorKey: 'adjusterName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claim No',
      accessorKey: 'claimNo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'First Name',
      accessorKey: 'firstName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Last Name',
      accessorKey: 'lastName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'DOI',
      accessorKey: 'doi',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.doi ?? ''),
    },
    {
      header: 'DOB',
      accessorKey: 'dob',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dob ?? ''),
    },
    {
      header: 'SSN',
      accessorKey: 'ssn',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: ({ row }) => {
        const ssn = row.original.ssn;
        if (ssn.length < 9 || ssn === '000000000' || ssn === '111111111') {
          return <p className='bg-danger text-bg-danger'>{ssn}</p>;
        }
        return <p>{ssn}</p>;
      },
    },
    {
      header: 'Severity',
      accessorKey: 'severity',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Status',
      accessorKey: 'status',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'RREID',
      accessorKey: 'rreid',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, [rowSelection]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
