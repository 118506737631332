import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { FileDownload } from '../Types/FileDownLoad';

class ExportApi {
  static exportToExcel(data: object) {
    return axios.post<FileDownload>(
      `${API_URL}/api/exportexcel`,
      data,
      getAuthHeader()
    );
  }
  static exportMecc(asOf: string) {
    return axios.get<FileDownload>(
      `${API_URL}/api/exports/mecc?asOf=${asOf}`,
      getAuthHeader()
    );
  }
  static exportUssu(asOf: string) {
    return axios.get<FileDownload>(
      `${API_URL}/api/exports/ussu?asOf=${asOf}`,
      getAuthHeader()
    );
  }
  static exportMidlands() {
    return axios.get<FileDownload>(
      `${API_URL}/api/exports/midlands`,
      getAuthHeader()
    );
  }
  static exportSafetyNational(asOf: string) {
    return axios.get<FileDownload>(
      `${API_URL}/api/exports/safetynational?asOf=${asOf}`,
      getAuthHeader()
    );
  }
  static exportYkk() {
    return axios.get<FileDownload[]>(
      `${API_URL}/api/exports/ykk`,
      getAuthHeader()
    );
  }
}

export default ExportApi;
