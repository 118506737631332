import React, { useMemo, useState, useEffect } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  RowSelectionState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { ScanDoc } from '../../ApiTypes/ScanDoc';
import IndeterminateCheckbox from '../Common/IndeterminateCheckbox';

export default function ScanDocTable({
  data,
  setSelectedDoc,
  rowSelection,
  setRowSelection,
}: {
  data: ScanDoc[];
  setSelectedDoc: (d: ScanDoc | null) => void;
  rowSelection: RowSelectionState;
  setRowSelection: (obj: object) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  useEffect(() => {
    handleNewData();
  }, [data]);

  const handleNewData = () => {
    setRowSelection({});
  };

  const handleRowSelectionChange = () => {
    setSelectedDoc(
      table.getSelectedRowModel().flatRows.map((r) => r.original)[0]
    );
  };

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<ScanDoc>[] = [
    {
      header: '',
      id: 'options',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 50,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center'>
            <IndeterminateCheckbox
              className='form-check-input'
              {...{
                id: row.id,
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        );
      },
    },
    {
      header: 'Doc Name',
      accessorKey: 'note',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claim Number',
      accessorKey: 'claimno',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claimant',
      accessorKey: 'claimantName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Control Num',
      accessorKey: 'compIqcontrolNum',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
