import { ClaimType } from './ClaimType';
import { ClaimTab } from './ClaimTab';

export interface ClaimTypeConfiguration {
  id: number;
  claimTypeId: number;
  claimTabId: number;
  fieldName: string;
  labelName: string;
  visible: boolean;
  required: boolean;

  //References
  claimType: ClaimType | null;
  claimTab: ClaimTab | null;
}

export enum ClaimTypes {
  All = 0,
  WorkersComp = 1,
  GeneralLiability = 2,
  AutomobileLiability = 4,
  Indemnification = 5,
  PropertyAPD = 6,
}

export enum ClaimTypesString {
  'All' = 0,
  'Workers Comp' = 1,
  'General Liability' = 2,
  'Automobile Liability' = 4,
  'Indemnification' = 5,
  'Property & APD' = 6,
}

export enum ClaimTypesURL {
  'claims' = 1,
  'general-liability-claims' = 2,
  'auto-liability-claims' = 4,
  'indemnification' = 5,
  'property-and-apd' = 6,
}
