import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaCheckDouble } from 'react-icons/fa';
import { toast, ToastContentProps } from 'react-toastify';
import PaymentsApi from '../../Api/PaymentsApi';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { VPaymentTab } from '../../ApiTypes/VPaymentTab';
import { useAppSelector } from '../../Reducers/Store';
import BSControlledSelect from '../Common/BSControlledSelect';
import PageScaffold from '../PageScaffold/PageScaffold';
import CheckBatchesTable from './CheckBatchesTable';
import styles from './index.module.css';
import { downloadPdf, downloadCrystal, downloadAny } from '../../Utils';
import Loading from '../Common/Loading';

export default function CheckBatches() {
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );

  const [transactions, setTransactions] = useState<VPaymentTab[]>([]);
  const [denials, setDenials] = useState<VPaymentTab[]>([]);
  const [filtered, setFiltered] = useState<VPaymentTab[]>([]);
  const [ics, setIcs] = useState<InsuranceCompany[]>([]);
  const [fetching, setFetching] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<number>(0);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedTransactions, setSelectedTransactions] = useState<
    VPaymentTab[]
  >([]);
  const [disableCreateBatchButton, setDisableCreateBatchButton] =
    useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getTransactionsToPrint();
    getEorDenials();
  }, []);

  useEffect(() => {
    icsList();
  }, [transactions, insuranceCompaniesForUser]);

  useEffect(() => {
    filterTransactions();
  }, [transactions, selectedAccount]);

  const getTransactionsToPrint = () => {
    setFetching(true);
    PaymentsApi.getTransactionsToPrint()
      .then((res) => {
        setTransactions(res.data);
        filterTransactions();
        setFetching(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setFetching(false);
        console.log(err);
        toast.error('Failed to get approvals: ' + err);
      });
  };

  const getEorDenials = () => {
    PaymentsApi.getEorDenials()
      .then((res) => {
        setDenials(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterTransactions = () => {
    const f = transactions.filter((t) => t.insurCoId === +selectedAccount);
    setFiltered(f);
  };

  const icsList = () => {
    const companies = insuranceCompaniesForUser
      .filter((i) => transactions.some((t) => t.insurCoId === i.insurcoid))
      .sort((a, b) => (a.shortname ?? '').localeCompare(b.shortname ?? ''));
    setIcs(companies);
  };

  const Msg = ({ closeToast, toastProps }: Partial<ToastContentProps>) => (
    <div>
      {(toastProps?.data as { text: string }).text}
      <div className='d-flex justify-content-around align-items-center my-3'>
        <Button
          type='button'
          variant='outline-light'
          size='sm'
          onClick={() => {
            closeToast && closeToast();
            createCheckBatch();
          }}
        >
          Continue
        </Button>
        <Button
          variant='outline-light'
          size='sm'
          type='button'
          onClick={closeToast}
        >
          Cancel
        </Button>
      </div>
    </div>
  );

  const validateCheckBatch = () => {
    // setDisableCreateBatchButton(true);
    setIsLoading(true);
    if (selectedTransactions.length === 0) {
      toast.info('Please select checks to print.');
      // setDisableCreateBatchButton(false);
      setIsLoading(false);
      return;
    }
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const today = new Date();
    const dayOfWeek = daysOfWeek[today.getDay()];
    const company = ics.find((i) => i.insurcoid === +selectedAccount);
    if (company?.checkPrintDay && company.checkPrintDay !== dayOfWeek) {
      toast(<Msg />, {
        autoClose: false,
        closeOnClick: false,
        hideProgressBar: true,
        draggable: false,
        data: {
          text: `The specified day of the week to print checks for this account is ${company.checkPrintDay}, are you sure you want to proceed?`,
        },
      });
    } else {
      toast.info('Creating Batch.');
      // setDisableCreateBatchButton(true);

      createCheckBatch();
    }
  };

  const createCheckBatch = () => {
    const promises = [];
    // let transactionsLeft = selectedTransactions;
    // const batch06cc = transactionsLeft.filter(
    //   (c) => c.payCode === '06-MISC. MEDICAL' && c.internalProviderId === 13645
    // );
    // if (batch06cc && batch06cc.length > 0) {
    //   promises.push(createBatch(batch06cc));
    //   const batch06ccTxIds = batch06cc.map((x) => x.txId);
    //   transactionsLeft = transactionsLeft.filter(
    //     (x) => !batch06ccTxIds.includes(x.txId)
    //   );
    // }
    // const batch08cc = transactionsLeft.filter(
    //   (x) => x.payCode === '08-EXPENSE-MISC.' && x.internalProviderId === 13645
    // );
    // if (batch08cc && batch08cc.length > 0) {
    //   promises.push(createBatch(batch08cc));
    //   const batch08ccTxIds = batch08cc.map((x) => x.txId);
    //   transactionsLeft = transactionsLeft.filter(
    //     (x) => !batch08ccTxIds.includes(x.txId)
    //   );
    // }

    if (selectedTransactions && selectedTransactions.length > 0) {
      promises.push(createBatch(selectedTransactions));
    }

    Promise.allSettled(promises)
      .then((res) => {
        setRowSelection({});
        getTransactionsToPrint();
        setDisableCreateBatchButton(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createBatch = (payments: VPaymentTab[]) => {
    return PaymentsApi.createPaymentBatch(payments)
      .then((res) => {
        for (let index = 0; index < res.data.length; index++) {
          const response = res.data[index];

          if (response.success) {
            toast.info('Batch creation successful.');

            if (response.reportBytes) {
              if (response.isCrystal) {
                downloadCrystal(response.reportFileName, response.reportBytes);
              } else if (response.isPDF) {
                downloadPdf(response.reportFileName, response.reportBytes);
              } else {
                downloadAny(response.reportFileName, response.reportBytes);
              }
            }
            // setDisableCreateBatchButton(false);
            toast.info(response.messages.join(', '));
          } else {
            toast.info('Batch creation failed.');
            toast.error(response.messages.join(', '));
            // setDisableCreateBatchButton(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const printDenials = () => {
    if (denials.length === 0) {
      toast.info('No denials to print');
    }
    return PaymentsApi.printDenials(denials)
      .then((res) => {
        if (res.data.success) {
          downloadPdf(res.data.reportFileName, res.data.reportBytes);
          toast.success('Success');
        } else {
          toast.error(res.data.messages.join(', '));
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('An error has occurred');
      });
  };
  return (
    <PageScaffold>
      <>{isLoading && <Loading />}</>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaCheckDouble className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Create Check Batches</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center gap1Rem'>
        <BSControlledSelect
          name='account'
          label='Account'
          onChange={setSelectedAccount}
          value={selectedAccount}
          options={ics}
          optionMethod={(options: InsuranceCompany[]) =>
            options.map((o) => (
              <option key={o.insurcoid} value={o.insurcoid}>
                {o.accountnumber + ' - ' + o.shortname}
              </option>
            ))
          }
        />
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          disabled={denials.length === 0}
          onClick={() => {
            printDenials();
          }}
        >
          Print Denials
        </Button>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={() => {
            setDisableCreateBatchButton(true);
            setIsLoading(true);
            validateCheckBatch();
          }}
          disabled={disableCreateBatchButton}
        >
          Create Batch
        </Button>
        <Button
          type='button'
          variant='primary'
          size='sm'
          onClick={getTransactionsToPrint}
        >
          {fetching ? (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            'Refresh'
          )}
        </Button>
      </div>
      <CheckBatchesTable
        data={filtered}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        setSelectedTransactions={setSelectedTransactions}
      />
    </PageScaffold>
  );
}
