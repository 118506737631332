import React from 'react';
import { Field, Form as RFFForm } from 'react-final-form';
import { Button, Form, Spinner } from 'react-bootstrap';
import styles from './index.module.css';
import { ClaimAuthorizedPhysician } from '../../../ApiTypes/ClaimAuthorizedPhysician';
import { ClaimPhysicianInfo } from '../../../ApiTypes/ClaimPhysicianInfo';
import { Physician } from '../../../ApiTypes/Physician';
import FieldBSRenderText from '../../Common/FieldBSRenderText';
import FieldBSRenderDate from '../../Common/FieldBSRenderDate';
import { parseDatesForServer } from '../../../Utils';
import PhysiciansLog from './PhysiciansLogTable';

export default function OldPhysicianInfo({
  claimNumber,
  allPhysicians,
  claimAuthorizedPhysicians,
  physicianInfo,
  getClaimAuthorizedPhysicians,
  getAllPhysicians,
  getPhysicianInfo,
}: {
  claimNumber: string;
  allPhysicians: Physician[];
  claimAuthorizedPhysicians: ClaimAuthorizedPhysician[];
  physicianInfo: ClaimPhysicianInfo | null;
  getClaimAuthorizedPhysicians: () => void;
  getAllPhysicians: () => void;
  getPhysicianInfo: () => void;
}) {
  const onSubmit = (values: ClaimPhysicianInfo) => {
    console.log(values);
  };

  return (
    <div className='w-100 h-100'>
      <h4>Claimant Physician Information</h4>
      <RFFForm
        onSubmit={onSubmit}
        initialValues={physicianInfo ?? {}}
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className={`${styles.grid2}`}>
                <Field
                  name='authorizedPhysicians'
                  label='Authorized Physicians'
                  type='text'
                  component={FieldBSRenderText}
                />
                <Field
                  name='referredTo'
                  label='Referred To'
                  type='text'
                  component={FieldBSRenderText}
                />
                <Field
                  name='claimantsIme'
                  label='Claimants IME'
                  type='text'
                  component={FieldBSRenderText}
                />
                <Field
                  name='dateImeChosen'
                  label='Claimants IME Date'
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />
                <Field
                  name='gasIme'
                  label='Internal IME'
                  type='text'
                  component={FieldBSRenderText}
                />
                <Field
                  name='dateGasImechosen'
                  label='Internal IME Date'
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />
                <Field
                  name='deniedTreatment'
                  label='Denied Treatment'
                  type='text'
                  component={FieldBSRenderText}
                />
                <Field
                  name='dateDeniedTreatment'
                  label='Denied Treatment Date'
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />
                <Field
                  name='claimantsOneTimeChange'
                  label='Claimant 1-Time Change'
                  type='text'
                  component={FieldBSRenderText}
                />
              </div>
              <div className='d-flex justify-content-center align-items-center my-3'>
                <Button type='submit' variant='primary' size='sm'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
}
