import { FormApi } from 'final-form';
import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { FaChevronCircleLeft, FaEdit } from 'react-icons/fa';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Witness } from '../../../ApiTypes/Witness';
import { useAppSelector } from '../../../Reducers/Store';
import { requiredField } from '../../../Utils/FieldValidation';
import FieldBSRenderTextArea from '../../Common/FieldRenderTextArea';

import styles from './index.module.css';
import WitnessApi from '../../../Api/WitnessApi';
import FieldBSRenderTextHorizontal from '../../Common/FieldBSRenderTextHorizontal';
import { ClaimTypesURL } from '../../../ApiTypes/ClaimTypeConfiguration';

export default function WitnessAddEdit({
  claimType,
  selectedWitnessId,
  show,
  setShow,
  getWitnesses,
}: {
  claimType: number;
  selectedWitnessId: string | '';
  show: boolean;
  setShow: () => void;
  getWitnesses: () => void;
}) {
  let { claimNumber } = useParams();
  let witnessId = selectedWitnessId;
  let navigate = useNavigate();
  let location = useLocation();

  const [witness, setWitness] = useState<Witness | null>(null);

  let formInstance: FormApi<Witness, Partial<Witness>>;

  const { claim, claimant } = useAppSelector(
    (state) => state.currentClaimReducer
  );

  const { userModel } = useAppSelector((state) => state.user);

  useEffect(() => {
    getWitnessById();
  }, [claimNumber, witnessId, location]);

  const getWitnessById = () => {
    if (claimNumber && witnessId && witnessId !== '') {
      WitnessApi.getWitnessById(claimNumber, +witnessId)
        .then((res) => {
          setWitness(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setWitness(null);
    }
  };

  const onSubmit = (values: Witness) => {
    const copy = { ...values };
    copy.claimNo = claimNumber ?? '';
    copy.createdBy = userModel?.user?.userId ?? '';
    if (copy.id) {
      return updateWitness(copy);
    }
    return createWitness(copy);
  };

  const createWitness = (v: Witness) => {
    v.id = 0;
    return WitnessApi.createWitness(v, claimNumber!)
      .then((res) => {
        if (res.data.success) {
          // navigate(
          //   `/${ClaimTypesURL[claimType]}/${claimNumber}/witnesses/${res.data.affectedEntityIdentifier}`
          // );
          getWitnesses();
          setShow();

          toast.success('Success');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create witness');
      });
  };

  const updateWitness = (v: Witness) => {
    return WitnessApi.updateWitness(v, claimNumber!)
      .then((res) => {
        if (res.data.success) {
          getWitnessById();
          toast.success('Success');
          getWitnesses();
          setShow();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create witness');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        // runCleanUp();
        setShow();
      }}
      dialogClassName=''
      aria-labelledby='Edit-Create-Witness-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Edit-Create-Witness-Form-modal'
        >
          <FaEdit className='pe-1' />
          {witness ? 'Edit' : 'Create'} Witness
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className='w-100 h-100'>
            <div className={`${styles.addEditSettlementTopDetails}`}>
              <p>Claim No: {claim?.claimNo}</p>
              <p>
                Claimant: {claimant?.firstName} {claimant?.lastName}
              </p>
            </div>

            <RFFForm
              onSubmit={onSubmit}
              initialValues={
                witness ?? {
                  witnessId: 0,
                }
              }
              render={({ handleSubmit, form, submitting }) => {
                formInstance = form;
                return (
                  <Form onSubmit={handleSubmit}>
                    <div className={`${styles.formRow}`}>
                      <Field
                        name='firstName'
                        label='First Name'
                        component={FieldBSRenderTextHorizontal}
                      />
                      <Field
                        name='lastName'
                        label='Last Name'
                        component={FieldBSRenderTextHorizontal}
                      />
                    </div>
                    <div className={`${styles.formRow}`}>
                      <Field
                        name='phoneNumber'
                        label='Phone Number'
                        component={FieldBSRenderTextHorizontal}
                      />
                      <Field
                        name='email'
                        label='Email'
                        component={FieldBSRenderTextHorizontal}
                      />
                    </div>
                    <div className={`${styles.maxWidth750}`}>
                      <Field
                        name='notes'
                        label='Notes'
                        rows={3}
                        component={FieldBSRenderTextArea}
                      />
                    </div>
                    <div className={`${styles.actionButtons}`}>
                      <Button type='submit' variant='primary' size='sm'>
                        {submitting ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          'Save'
                        )}
                      </Button>
                      <Button
                        type='button'
                        size='sm'
                        variant='secondary'
                        onClick={() => {
                          // runCleanUp();
                          form.reset();
                          setShow();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                );
              }}
            />
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
