import { FormApi } from 'final-form';
import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { EdiBenefitTypeCode } from '../../ApiTypes/EdiBenefitTypeCode';
import { EdiMaintTypeCode } from '../../ApiTypes/EdiMaintTypeCode';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { useAppSelector } from '../../Reducers/Store';
import { displayDateOnly } from '../../Utils';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import styles from './index.module.css';

export default function EdiInfo({
  transaction,
  formValues,
  form,
  formType,
  maintTypeCode,
  benefitTypeCode,
}: {
  transaction: EdiTransaction | null;
  formValues: Partial<EdiTransaction>;
  form: FormApi<EdiTransaction, Partial<EdiTransaction>>;
  formType: 'FROI' | 'SROI';
  maintTypeCode?: string;
  benefitTypeCode?: string;
}) {
  const {
    ediMaintTypeCodes,
    ediBenefitTypeCodes,
    FROIEdiMaintTypeCodes,
    SROIEdiMaintTypeCodes,
  } = useAppSelector((state) => state.reference);

  const { claim, claimant, employer, insuranceCompany } = useAppSelector(
    (state) => state.currentClaimReducer
  );

  const getMaintTypeCodeDescription = () => {
    if (transaction) {
      const type = ediMaintTypeCodes.find(
        (c) => c.code === transaction.maintTypeCode
      );
      if (type) {
        return type.shortdesc;
      }
      return 'N/A';
    }
    if (maintTypeCode) {
      const type = ediMaintTypeCodes.find((c) => c.code === maintTypeCode);
      if (type) {
        return type.shortdesc;
      }
      return 'N/A';
    }
    return 'N/A';
  };

  const getBenefitTypeCodeDescription = () => {
    if (transaction) {
      const type = ediBenefitTypeCodes.find(
        (c) => c.code === transaction.benefitTypeCode
      );
      if (type) {
        return type.shortdesc;
      }
      return 'N/A';
    }
    if (benefitTypeCode) {
      const type = ediBenefitTypeCodes.find((c) => c.code === benefitTypeCode);
      if (type) {
        return type.shortdesc;
      }
      return 'N/A';
    }
    return 'N/A';
  };

  const getBenefitCodes = (values: Partial<EdiTransaction>) => {
    // may need to send along a type for new sroi
    let maintTypeCode = values?.maintTypeCode;

    if (formType === 'SROI') {
      if (maintTypeCode === 'EP' || maintTypeCode === 'ER') {
        return ediBenefitTypeCodes.filter(
          (t) => t.code === '250' || t.code === '070'
        );
      }
      if (maintTypeCode === 'IP') {
        return ediBenefitTypeCodes.filter((t) => t.code !== '250');
      }
    }

    return ediBenefitTypeCodes;
  };

  return (
    <div>
      <h2>
        {transaction ? 'Edit' : 'New'} {formType} Submission
      </h2>
      <h5>
        {getMaintTypeCodeDescription()} - {getBenefitTypeCodeDescription()}
      </h5>
      <p className='fw-bold'>Claim Number: {claim?.claimNo}</p>
      <div className={`${styles.ediInfoGrid}`}>
        <p>Claimant Name:</p>
        <p>
          {claimant?.firstName} {claimant?.lastName}
        </p>
        <p>Date of Injury:</p>
        <p>{displayDateOnly(claim?.injuryDate ?? '')}</p>
        <p>Claimant SSN:</p>
        <p>{claimant?.ssn}</p>
        <p>Initial Disability Date:</p>
        <p>
          {claim?.disabilityDate
            ? displayDateOnly(claim.disabilityDate)
            : 'Not Entered'}
        </p>
        <p>Reported On:</p>
        <p>{displayDateOnly(claim?.reportDate ?? '')}</p>
        <p>RTW On:</p>
        <p>
          {claim?.returnedToWork
            ? displayDateOnly(claim.returnedToWork ?? '')
            : 'Not Entered'}
        </p>
        <p>Employer:</p>
        <p>{employer?.name}</p>
        <p>Employer TaxId:</p>
        <p>{employer?.taxid}</p>
        <p>Insurance Co:</p>
        <p>{insuranceCompany?.fullname}</p>
        <p>Insurance Co TaxId:</p>
        <p>{insuranceCompany?.inscotaxid}</p>
      </div>
      <div className={`${styles.ediTypesGrid}`}>
        <Field
          name='maintTypeCode'
          label='Maint Code'
          options={
            formType === 'SROI' ? SROIEdiMaintTypeCodes : FROIEdiMaintTypeCodes
          }
          optionMethod={(options: EdiMaintTypeCode[]) =>
            options.map((o) => (
              <option key={o.code} value={o.code}>
                {o.shortdesc}
              </option>
            ))
          }
          component={FieldBSRenderSelect}
        />
        <Field
          name='benefitTypeCode'
          label='Benefit Code'
          options={getBenefitCodes(formValues)}
          optionMethod={(options: EdiBenefitTypeCode[]) =>
            options.map((o) => (
              <option key={o.code} value={o.code!}>
                {o.shortdesc}
              </option>
            ))
          }
          disabled={
            formValues.maintTypeCode === 'AN' ||
            formValues.maintTypeCode === 'FN'
          }
          component={FieldBSRenderSelect}
        />
      </div>
    </div>
  );
}
