import { addDays, addHours, format, parse } from 'date-fns';
import { addSeconds } from 'date-fns/esm';
import React, { useState } from 'react';
import { Button, Container, Modal, Form } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ChecksApi from '../../Api/ChecksApi';
import ReportApi from '../../Api/ReportApi';
import { CheckRegisterRequest } from '../../ApiTypes/CheckRegisterRequest';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import {
  AvailableReport,
  FileFormat,
  ReportRequest,
} from '../../ApiTypes/ReportRequest';
import { VwCheckRegisterLong } from '../../ApiTypes/VwCheckRegisterLong';
import { useAppSelector } from '../../Reducers/Store';
import {
  downloadExcel,
  downloadIif,
  downloadPdf,
  downloadTxt,
  parseDatesForServer,
} from '../../Utils';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import CheckRegisterTable from './CheckRegisterTable';
import styles from './index.module.css';

export default function CheckRegisterModal({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const { insuranceCompanies } = useAppSelector((state) => state.reference);
  const [checks, setChecks] = useState<VwCheckRegisterLong[]>([]);
  let formValues: CheckRegisterRequest;

  const pdfRequest: ReportRequest = {
    accountNumber: '',
    claimNumber: '',
    checkNumber: '',
    claimsByAccountNumberAndTreatyYearCount: 0,
    claimsByEmployerAndTreatyYearCount: 0,
    employerId: null,
    exportFormat: FileFormat.PortableDocFormat,
    fromDate: null,
    insurCoId: null,
    report: null,
    severity: '',
    status: '',
    treatyYear: null,
    treatyYearTo: null,
    toDate: null,
    userId: '',
    genericCheckReportName: null,
    paymentBatchIds: null,
    fromName: '',
    openOnly: false,
    title: '',
  };

  const onSubmit = (values: CheckRegisterRequest) => {
    console.log(values);
    return ChecksApi.getLongChecksForAccountByDate(values)
      .then((res) => {
        setChecks(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get checks');
      });
  };

  const shortFormatClick = async () => {
    if (formValues.accountNumber && formValues.fromDate && formValues.toDate) {
      const request = { ...pdfRequest };
      request.accountNumber = formValues.accountNumber;
      request.fromDate = formValues.fromDate;
      request.toDate = formValues.toDate;
      request.report = AvailableReport.CheckRegisterNew;
      getPdf(request);
    } else {
      toast.info(
        'Please select an account number, from date, and to date, before running report'
      );
    }
  };
  const longFormatClick = async () => {
    if (formValues.accountNumber && formValues.fromDate && formValues.toDate) {
      const insCo = insuranceCompanies.find(
        (f) => f.accountnumber === formValues.accountNumber
      );
      const request = { ...pdfRequest };

      request.insurCoId = insCo?.insurcoid ?? 0;
      request.fromDate = formValues.fromDate;
      request.toDate = formValues.toDate;
      request.report = AvailableReport.CheckRegister;
      getPdf(request);
    } else {
      toast.info(
        'Please select an account number, from date, and to date, before running report'
      );
    }
  };

  const getPdf = async (request: ReportRequest) => {
    try {
      const res = await toast.promise(ReportApi.getCrystalReport(request), {
        pending: `Fetching Report`,
        success: 'Success',
        error: `Failed to fetch report`,
      });
      if (res.data.success) {
        downloadPdf(res.data.reportFileName, res.data.reportBytes);
      } else {
        toast.error(<ErrorMessage messages={res.data.messages} />);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const gadaClick = async () => {
    if (formValues.accountNumber && formValues.fromDate && formValues.toDate) {
      const request: CheckRegisterRequest = {
        accountNumber: formValues.accountNumber,
        fromDate: formValues.fromDate,
        toDate: formValues.toDate
          ? format(
              addSeconds(addDays(new Date(formValues.toDate), 1), -1),
              'yyy-MM-dd'
            )
          : '',
        batchNumber: formValues.batchNumber ?? '',
        checkNumber: formValues.checkNumber ?? '',
        includeNew: formValues.includeNew,
      };
      try {
        const res = await toast.promise(ChecksApi.getGada(request), {
          pending: `Fetching Report`,
          success: 'Success',
          error: `Failed to fetch report`,
        });
        if (res.data.fileName !== 'Failed') {
          downloadExcel(res.data.fileName, res.data.file);
        } else {
          toast.error('Failed to get report');
        }
      } catch (error) {
        console.log(error);
        toast.error('Failed to get report');
      }
    } else {
      toast.info(
        'Please select an account number, from date, and to date, before running report'
      );
    }
  };

  const sunTrustClick = async () => {
    if (formValues.fromDate && formValues.toDate) {
      const request: CheckRegisterRequest = {
        accountNumber: formValues.accountNumber,
        fromDate: formValues.fromDate,
        toDate: formValues.toDate
          ? format(
              addSeconds(addDays(new Date(formValues.toDate), 1), -1),
              'yyy-MM-dd'
            )
          : '',
        batchNumber: formValues.batchNumber ?? '',
        checkNumber: formValues.checkNumber ?? '',
        includeNew: formValues.includeNew,
      };
      try {
        const res = await toast.promise(ChecksApi.getSunTrust(request), {
          pending: `Fetching Report`,
          success: 'Success',
          error: `Failed to fetch report`,
        });
        if (res.data.fileName !== 'Nothing Added') {
          downloadTxt(res.data.fileName, res.data.file);
        } else {
          toast.info(
            'There were no checks that have not been uploaded to Positive Pay.  They have all been previously uploaded.'
          );
        }
      } catch (error) {
        console.log(error);
        toast.error('Failed to get report');
      }
    } else {
      toast.info(
        'Please select a from date, and to date, before running report'
      );
    }
  };
  const regionsClick = async () => {
    if (formValues.accountNumber && formValues.fromDate && formValues.toDate) {
      const request: CheckRegisterRequest = {
        accountNumber: formValues.accountNumber,
        fromDate: formValues.fromDate,
        toDate: formValues.toDate
          ? format(
              addSeconds(addDays(new Date(formValues.toDate), 1), -1),
              'yyy-MM-dd'
            )
          : '',
        batchNumber: formValues.batchNumber ?? '',
        checkNumber: formValues.checkNumber ?? '',
        includeNew: formValues.includeNew,
      };
      try {
        const res = await toast.promise(ChecksApi.getRegionsPosPay(request), {
          pending: `Fetching Report`,
          success: 'Success',
          error: `Failed to fetch report`,
        });
        if (res.data.fileName !== 'Nothing Added') {
          downloadTxt(res.data.fileName, res.data.file);
        } else {
          toast.info(
            'There were no checks that have not been uploaded to Positive Pay.  They have all been previously uploaded.'
          );
        }
      } catch (error) {
        console.log(error);
        toast.error('Failed to get report');
      }
    } else {
      toast.info(
        'Please select an account number, from date, and to date, before running report'
      );
    }
  };

  const mountainValleyClick = async () => {
    if (formValues.accountNumber && formValues.fromDate && formValues.toDate) {
      const request: CheckRegisterRequest = {
        accountNumber: formValues.accountNumber,
        fromDate: formValues.fromDate,
        toDate: formValues.toDate
          ? format(
              addSeconds(addDays(new Date(formValues.toDate), 1), -1),
              'yyy-MM-dd'
            )
          : '',
        batchNumber: formValues.batchNumber ?? '',
        checkNumber: formValues.checkNumber ?? '',
        includeNew: formValues.includeNew,
      };
      try {
        const res = await toast.promise(ChecksApi.getMountainValley(request), {
          pending: `Fetching Report`,
          success: 'Success',
          error: `Failed to fetch report`,
        });
        if (res.data.fileName !== 'Failed') {
          downloadExcel(res.data.fileName, res.data.file);
        } else {
          toast.error('Failed to get report');
        }
      } catch (error) {
        console.log(error);
        toast.error('Failed to get report');
      }
    } else {
      toast.info(
        'Please select an account number, from date, and to date, before running report'
      );
    }
  };
  const exportClick = async () => {
    if (formValues.accountNumber && formValues.fromDate && formValues.toDate) {
      const request: CheckRegisterRequest = {
        accountNumber: formValues.accountNumber,
        fromDate: formValues.fromDate,
        toDate: formValues.toDate
          ? format(
              addSeconds(addDays(new Date(formValues.toDate), 1), -1),
              'yyy-MM-dd'
            )
          : '',
        batchNumber: formValues.batchNumber ?? '',
        checkNumber: formValues.checkNumber ?? '',
        includeNew: formValues.includeNew,
      };
      try {
        const res = await toast.promise(
          ChecksApi.getExcelLongChecksForAccountByDate(request),
          {
            pending: `Fetching Report`,
            success: 'Success',
            error: `Failed to fetch report`,
          }
        );
        if (res.data.fileName !== 'Failed') {
          downloadExcel(res.data.fileName, res.data.file);
        } else {
          toast.error('Failed to get report');
        }
      } catch (error) {
        console.log(error);
        toast.error('Failed to get report');
      }
    } else {
      toast.info(
        'Please select an account number, from date, and to date, before running report'
      );
    }
  };
  const getQbFile = async (includeDetails: boolean) => {
    if (formValues.accountNumber && formValues.fromDate && formValues.toDate) {
      const request: CheckRegisterRequest = {
        accountNumber: formValues.accountNumber,
        fromDate: formValues.fromDate,
        toDate: formValues.toDate
          ? format(
              addSeconds(addDays(new Date(formValues.toDate), 1), -1),
              'yyy-MM-dd'
            )
          : '',
        batchNumber: formValues.batchNumber ?? '',
        checkNumber: formValues.checkNumber ?? '',
        includeNew: formValues.includeNew,
      };
      try {
        const res = await toast.promise(
          ChecksApi.getQbFile(request, includeDetails),
          {
            pending: `Fetching Report`,
            success: 'Success',
            error: `Failed to fetch report`,
          }
        );
        if (res.data.fileName !== 'Failed') {
          downloadIif(res.data.fileName, res.data.file);
        } else {
          toast.error('Failed to get report');
        }
      } catch (error) {
        console.log(error);
        toast.error('Failed to get report');
      }
    } else {
      toast.info(
        'Please select an account number, from date, and to date, before running report'
      );
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName={styles.largeModal}
      aria-labelledby='CheckRegisterModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='CheckRegisterModal-modal'>
          <FaCheck className='pe-1' /> Check Register
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              fromDate: format(new Date(), 'yyyy-MM-dd'),
              toDate: format(
                addHours(addDays(new Date(), 1), -1),
                'yyyy-MM-dd'
              ),
              includeNew: false,
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              formValues = values;
              return (
                <Form onSubmit={handleSubmit}>
                  <div className={`${styles.grid7}`}>
                    <Field
                      name='accountNumber'
                      label='Account'
                      options={[
                        ...insuranceCompanies
                          .map((x) => x)
                          .sort(
                            (a, b) => +a.accountnumber! - +b.accountnumber!
                          ),
                      ]}
                      optionMethod={(options: InsuranceCompany[]) =>
                        options.map((o) => (
                          <option
                            key={o.accountnumber!}
                            value={o.accountnumber!}
                          >
                            {`${o.accountnumber} - ${o.shortname}`}
                          </option>
                        ))
                      }
                      component={FieldBSRenderSelect}
                    />
                    <Field
                      name='fromDate'
                      type='text'
                      label='From Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                      startOfDay={true}
                    />
                    <Field
                      name='toDate'
                      type='text'
                      label='To Date'
                      parse={parseDatesForServer}
                      component={FieldBSRenderDate}
                      endOfDay={true}
                    />
                    <Field
                      name='batchNumber'
                      type='text'
                      label='Batch'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='checkNumber'
                      type='text'
                      label='Check #'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='includeNew'
                      type='checkbox'
                      label='Include New'
                      checked={values?.includeNew === true}
                      component={FieldBSRenderCheckbox}
                    />
                    <div className='d-flex justify-content-center align-items-center gap1Rem'>
                      <Button type='submit' variant='primary' size='sm'>
                        Search
                      </Button>
                      <Button
                        type='button'
                        variant='secondary'
                        size='sm'
                        onClick={() => {
                          form.reset();
                        }}
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          />
          <CheckRegisterTable data={checks} />
          <div className={`${styles.grid8} my-3`}>
            <div className='d-flex justify-content-center align-items-center gap1Rem'>
              <Button
                type='button'
                variant='outline-primary'
                size='sm'
                onClick={() => {
                  getQbFile(false);
                }}
              >
                Export QB File
              </Button>
              <Button
                type='button'
                variant='outline-primary'
                size='sm'
                onClick={() => {
                  getQbFile(true);
                }}
              >
                Export QB File w/ Detailed Line Items
              </Button>
            </div>
            <Button
              type='button'
              variant='outline-primary'
              size='sm'
              onClick={mountainValleyClick}
            >
              Mountain Valley Positive Pay Files
            </Button>
            <Button
              type='button'
              variant='outline-primary'
              size='sm'
              onClick={gadaClick}
            >
              GADA Positive Pay Files
            </Button>
            <Button
              type='button'
              variant='outline-primary'
              size='sm'
              onClick={sunTrustClick}
            >
              SunTrust Positive Pay Files
            </Button>
            <Button
              type='button'
              variant='outline-primary'
              size='sm'
              onClick={regionsClick}
            >
              Regions Positive Pay Files
            </Button>
            <Button
              type='button'
              variant='outline-primary'
              size='sm'
              onClick={shortFormatClick}
            >
              View Short Format Report
            </Button>
            <Button
              type='button'
              variant='outline-primary'
              size='sm'
              onClick={longFormatClick}
            >
              View Long Format Report
            </Button>
            <Button
              type='button'
              variant='outline-primary'
              size='sm'
              onClick={exportClick}
            >
              Export CSV
            </Button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
