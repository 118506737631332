import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import WcPanelApi from '../../Api/WcPanelApi';
import { EmployerPolicyPanel } from '../../ApiTypes/EmployerPolicyPanel';
import { WcPanel } from '../../ApiTypes/WcPanel';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import WCPanelPolicyLink from '../WCPanelPolicyLink/WCPanelPolicyLink';
import EmployersAssignedTable from './EmployersAssignedTable';

export default function EmployersAssigned({
  panel,
  employerPolicyPanels,
  fetchAll,
  handleViewEmployerDoc,
}: {
  panel: WcPanel | null;
  employerPolicyPanels: EmployerPolicyPanel[];
  fetchAll: () => void;
  handleViewEmployerDoc: (docId: number) => void;
}) {
  const [clearing, setClearing] = useState(false);
  const [includeApproved, setIncludeApproved] = useState<boolean>(false);
  const [showAddEditLink, setShowAddEditLink] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedEmployerPolicyPanel, setSelectedEmployerPolicyPanel] =
    useState<EmployerPolicyPanel | null>(null);

  const handleEditEmployerPolicyPanel = (p: EmployerPolicyPanel) => {
    setSelectedEmployerPolicyPanel(p);
    setShowAddEditLink(true);
  };
  const handleAddEmployerPolicyPanel = () => {
    setSelectedEmployerPolicyPanel(null);
    setShowAddEditLink(true);
  };

  const handleDelete = (p: EmployerPolicyPanel) => {
    setSelectedEmployerPolicyPanel(p);
    setShowDelete(true);
  };

  const deleteEmployerPolicyPanel = () => {
    if (selectedEmployerPolicyPanel) {
      return WcPanelApi.removeEmployerPolicyPanel(
        selectedEmployerPolicyPanel.panelId!,
        selectedEmployerPolicyPanel.id
      )
        .then((res) => {
          if (res.data.success) {
            toast.success('Success');
            setShowDelete(false);
            fetchAll();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to Delete');
        });
    } else {
      return Promise.resolve();
    }
  };

  const handleClearAutoAssigned = () => {
    setClearing(true);
    WcPanelApi.clearAutoAssignLocations(panel?.id!, includeApproved)
      .then((res) => {
        if (res.data.success) {
          fetchAll();
        } else {
          toast.error(res.data.message);
        }
        setClearing(false);
      })
      .catch((err) => {
        console.log(err);
        setClearing(false);
        toast.error('Failed to clear auto assigned');
      });
  };
  return (
    <div>
      <div className='d-flex justify-content-around align-items-center py-3'>
        <div className='d-flex gap1Rem align-items-center'>
          <Button
            type='button'
            variant='outline-primary'
            size='sm'
            onClick={handleClearAutoAssigned}
          >
            {clearing ? (
              <Spinner
                as='span'
                animation='grow'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              'Clear Auto Assigned'
            )}
          </Button>
          <div className='form-check'>
            <label className='form-check-label'>
              <input
                name='includeApproved'
                type='checkbox'
                className='form-check-input'
                checked={includeApproved}
                onChange={(e) => {
                  setIncludeApproved((includeApproved) => !includeApproved);
                }}
              />
              Include Approved
            </label>
          </div>
        </div>
        <Button
          type='button'
          variant='primary'
          size='sm'
          className='button-icon-text'
          onClick={handleAddEmployerPolicyPanel}
        >
          <FaPlusCircle /> Employer
        </Button>
      </div>
      <EmployersAssignedTable
        data={employerPolicyPanels}
        handleViewEmployerDoc={handleViewEmployerDoc}
        handleDelete={handleDelete}
        handleEditEmployerPolicyPanel={handleEditEmployerPolicyPanel}
      />
      <WCPanelPolicyLink
        show={showAddEditLink}
        setShow={setShowAddEditLink}
        wcPanelId={panel?.id}
        employerPolicyPanel={selectedEmployerPolicyPanel}
        afterSubmit={fetchAll}
      />
      <DeleteConfirmationModal
        show={showDelete}
        setShow={setShowDelete}
        setNull={setSelectedEmployerPolicyPanel}
        handleDelete={deleteEmployerPolicyPanel}
      />
    </div>
  );
}
