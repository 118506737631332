import { format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaEnvelopeOpen } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { GemcLossRunEmailRequest } from '../../ApiTypes/GemcLossRunEmailRequest';
import GemcLossRunsApi from '../../Api/GemcLossRunsApi';
import { GemcLossRunEmail } from '../../ApiTypes/GemcLossRunEmail';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import PageScaffold from '../PageScaffold/PageScaffold';
import EditEmailModal from './EditEmailModal';
import GemcLossRunEmailTable from './GemcLossRunEmailTable';

import styles from './index.module.css';

export default function SendGemcLossRuns() {
  const [gemcEmails, setGemcEmails] = useState<GemcLossRunEmail[]>([]);
  const [gemcRequest, setGemcRequest] =
    useState<GemcLossRunEmailRequest | null>(null);
  const [selectedEmail, setSelectedEmail] = useState<GemcLossRunEmail | null>(
    null
  );
  const [showEmailEdit, setShowEmailEdit] = useState<boolean>(false);
  const [fetchingEmails, setFetchingEmails] = useState<boolean>(false);

  useEffect(() => {
    getGemcEmails();
    getGemcRequest();
  }, []);

  const getGemcRequest = () => {
    GemcLossRunsApi.getRequest()
      .then((res) => {
        setGemcRequest(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getGemcEmails = () => {
    setFetchingEmails(true);
    GemcLossRunsApi.getEmails()
      .then((res) => {
        setGemcEmails(res.data);
        setFetchingEmails(false);
      })
      .catch((err) => {
        console.log(err);
        setFetchingEmails(false);
      });
  };

  const handleSendTest = (email: GemcLossRunEmail) => {
    const { startDate, endDate } = getStartAndEndDate();
    return GemcLossRunsApi.TestEmail(email, startDate, endDate)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to send test email');
      });
  };

  const handleEditEmail = (email: GemcLossRunEmail | null) => {
    setSelectedEmail(email);
    setShowEmailEdit(true);
  };

  const handleEmailSubmit = (values: GemcLossRunEmail) => {
    return GemcLossRunsApi.updateEmail(values)
      .then((res) => {
        if (res.data.success) {
          getGemcEmails();
          setShowEmailEdit(false);
          setSelectedEmail(null);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update email');
      });
  };

  const getStartAndEndDate = () => {
    var now = new Date();
    var startDate = format(new Date(now.getFullYear(), 0, 1), 'yyyy-MM-dd');
    var endDate = format(new Date(now.getFullYear(), 11, 31), 'yyyy-MM-dd');

    return {
      startDate,
      endDate,
    };
  };

  const handleRequestSubmit = (values: GemcLossRunEmailRequest) => {
    values.emails = [];
    return GemcLossRunsApi.updateRequest(values)
      .then((res) => {
        if (res.data.success) {
          getGemcRequest();
          toast.success('Success');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update email parameters');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaEnvelopeOpen className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>
              GEMC Loss Runs - Send Email{' '}
              {fetchingEmails && (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </h1>
            <p>Use this screen to create and send GEMC Loss Run Emails</p>
          </div>
        </div>
      </div>
      <GemcLossRunEmailTable
        emails={gemcEmails}
        handleEmailEdit={handleEditEmail}
        handleSendTest={handleSendTest}
      />
      <div className={`${styles.max800}`}>
        <RFForm
          onSubmit={handleRequestSubmit}
          initialValues={
            gemcRequest
              ? {
                  ...gemcRequest,
                  ...getStartAndEndDate(),
                }
              : {
                  ...getStartAndEndDate(),
                }
          }
          render={({ handleSubmit, form, values, submitting }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Field
                  name='subject'
                  label='Subject'
                  type='text'
                  validate={requiredField}
                  component={FieldBSRenderText}
                />
                <Field
                  name='message'
                  label='Message'
                  rows={5}
                  validate={requiredField}
                  component={FieldBSRenderTextArea}
                />
                <Field
                  name='fromEmail'
                  label='From Email'
                  type='text'
                  validate={requiredField}
                  component={FieldBSRenderText}
                />
                <Field
                  name='cCs'
                  label='CC Emails (Separate multiples with a semi-colon ";")'
                  type='text'
                  component={FieldBSRenderText}
                />
                <div className={`${styles.grid3}`}>
                  <Field
                    name='startDate'
                    type='text'
                    label='Start Date'
                    validate={requiredField}
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                  />
                  <Field
                    name='endDate'
                    type='text'
                    label='End Date'
                    validate={requiredField}
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                  />
                  <div className='d-flex  align-items-center gap1Rem'>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Send'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        form.reset();
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        />
      </div>
      <EditEmailModal
        show={showEmailEdit}
        setShow={setShowEmailEdit}
        selectedEmail={selectedEmail}
        setSelectedEmail={setSelectedEmail}
        submitFunction={handleEmailSubmit}
      />
    </PageScaffold>
  );
}
