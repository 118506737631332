import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { EmployerPolicyPanel } from '../ApiTypes/EmployerPolicyPanel';
import { EmployerPolicyPanelAddUpdateRequest } from '../ApiTypes/EmployerPolicyPanelAddUpdateRequest';
import { EmployerPolicyPanelLocation } from '../ApiTypes/EmployerPolicyPanelLocation';
import { InsuranceCompany } from '../ApiTypes/InsuranceCompany';
import { AddPanelCoverageRequest } from '../ApiTypes/PanelCoverageRequest';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { WcPanel } from '../ApiTypes/WcPanel';
import { WcPanelCoverage } from '../ApiTypes/WcPanelCoverage';
import { WCPanelLocation } from '../ApiTypes/WcPanelLocation';
import { FileDownload } from '../Types/FileDownLoad';

class WcPanelApi {
  static getEmployerPolicyPanelById(id: number) {
    return axios.get<EmployerPolicyPanel>(
      `${API_URL}/api/employerpolicypanels/${id}`,
      getAuthHeader()
    );
  }
  static viewEmployerPolicyPanelById(claimNumber: string, id: number) {
    return axios.get<FileDownload>(
      `${API_URL}/api/claims/${claimNumber}/employerpolicypanels/${id}/view`,
      getAuthHeader()
    );
  }
  static tryAddEmployerPolicyPanel(claimNumber: string) {
    return axios.get<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/employerpolicypanels/tryadd`,
      getAuthHeader()
    );
  }
  static listWcPanels() {
    return axios.get<WcPanel[]>(
      `${API_URL}/api/wcpanels/list`,
      getAuthHeader()
    );
  }

  static searchWcPanels(
    year: number,
    searchByYearOnly: boolean,
    insurCoId?: number
  ) {
    return axios.get<WcPanel[]>(
      `${API_URL}/api/wcpanels/find?year=${year}&searchByYearOnly=${searchByYearOnly}&insurCoId=${insurCoId}`,
      getAuthHeader()
    );
  }
  static deletePanelItem(id: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/wcpanels/${id}`,
      getAuthHeader()
    );
  }
  static getWcPanelById(id: number) {
    return axios.get<WcPanel>(`${API_URL}/api/wcpanels/${id}`, getAuthHeader());
  }
  static createWcPanel(panel: WcPanel) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/wcpanels`,
      panel,
      getAuthHeader()
    );
  }
  static updateWcPanel(panel: WcPanel) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/wcpanels/${panel.id}`,
      panel,
      getAuthHeader()
    );
  }
  static getLinkedInsuranceCompanies(id: number) {
    return axios.get<InsuranceCompany[]>(
      `${API_URL}/api/wcpanels/${id}/linkedinsurancecompanies`,
      getAuthHeader()
    );
  }
  static getEmployerPolicyPanelsForPanel(id: number) {
    return axios.get<EmployerPolicyPanel[]>(
      `${API_URL}/api/wcpanels/${id}/employerpolicypanels`,
      getAuthHeader()
    );
  }
  static getPanelCoverages(id: number) {
    return axios.get<WcPanelCoverage[]>(
      `${API_URL}/api/wcpanels/${id}/coverages`,
      getAuthHeader()
    );
  }
  static deleteCoverage(id: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/wcpanels/coverages/${id}`,
      getAuthHeader()
    );
  }
  static addCoverage(request: AddPanelCoverageRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/wcpanels/coverages`,
      request,
      getAuthHeader()
    );
  }
  static removeInsuranceCompanyFromPanel(panelId: number, insurCoId: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/wcpanels/${panelId}/insurancecompany/${insurCoId}`,
      getAuthHeader()
    );
  }
  static addInsuranceCompanyToPanel(panelId: number, insurCoId: number) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/wcpanels/${panelId}/insurancecompany/${insurCoId}`,
      {},
      getAuthHeader()
    );
  }
  static uploadWcPanelDoc(fd: FormData) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/wcpanels/uploadpaneldoc`,
      fd,
      getAuthHeader()
    );
  }
  static autoAssignLocations(id: number, year: number) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/wcpanels/${id}/autoassign/${year}`,
      {},
      getAuthHeader()
    );
  }
  static clearAutoAssignLocations(id: number, includeApproved: boolean) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/wcpanels/${id}/clearautoassigned?includeApproved=${includeApproved}`,
      {},
      getAuthHeader()
    );
  }
  static getEmployerPolicyPanelLocationsToBeApproved() {
    return axios.get<EmployerPolicyPanelLocation[]>(
      `${API_URL}/api/employerpolicypanellocations/needapproved`,
      getAuthHeader()
    );
  }
  static approveLocation(locationId: number, panelId: number) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/employerpolicypanellocations/approve?locationId=${locationId}&panelId=${panelId}`,
      {},
      getAuthHeader()
    );
  }
  static addEmployerPolicyToPanel(
    request: EmployerPolicyPanelAddUpdateRequest
  ) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/wcpanels/${request.wcPanelId}/employerpolicypanels`,
      request,
      getAuthHeader()
    );
  }
  static updateEmployerPolicyPanel(
    request: EmployerPolicyPanelAddUpdateRequest
  ) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/wcpanels/${request.wcPanelId}/employerpolicypanels/${request.id}`,
      request,
      getAuthHeader()
    );
  }
  static removeEmployerPolicyPanel(panelId: number, id: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/wcpanels/${panelId}/employerpolicypanels/${id}`,
      getAuthHeader()
    );
  }
  static getPanelExceptions(insurCoId: number, treatyYear: number) {
    return axios.get<WCPanelLocation[]>(
      `${API_URL}/api/wcpanels/exceptions`,
      getAuthHeader()
    );
  }
}

export default WcPanelApi;
