import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { ClaimNoteType } from '../ApiTypes/ClaimNoteType';

class ClaimNoteTypesApi {
  static getClaimNoteTypes() {
    return axios.get<ClaimNoteType[]>(
      `${API_URL}/api/claimnotetypes`,
      getAuthHeader()
    );
  }
  static createClaimNoteType(noteType: ClaimNoteType) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claimnotetypes`,
      noteType,
      getAuthHeader()
    );
  }
  static updateClaimNoteType(noteType: ClaimNoteType) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claimnotetypes/${noteType.id}`,
      noteType,
      getAuthHeader()
    );
  }
}
export default ClaimNoteTypesApi;
