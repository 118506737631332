import accounting from 'accounting';

export const removeNonDigits = (value: string) =>
  value ? value.replace(/\D/g, '') : value;

export const cleanMoney = (value: string) =>
  value.length > 0 ? value.replace(/^(-)|[^0-9.]+/g, '$1') : value ?? '';

export const formatMoney = (value: any) =>
  new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
  }).format(value ? +value : 0);

export const formatNumbers = (value: any) => {
  if (!value) {
    return value;
  }
  const hasSeparator = `${value}`.includes('.');
  const [before, after] = `${value}`.split('.');

  let formattedBefore = '';
  let formattedAfter = '';

  if (before !== '-') {
    formattedBefore = new Intl.NumberFormat().format(+cleanMoney(before));
  }
  if (before === '-') {
    formattedBefore = before;
  }
  if (after !== '' && !isNaN(+after)) {
    let afterString = '0.' + after;
    formattedAfter = afterString;
  }
  if (formattedAfter) {
    return `${formattedBefore}${formattedAfter.slice(1)}`;
  }
  if (hasSeparator) {
    return `${formattedBefore}.`;
  }
  return formattedBefore ?? '';
};

export const formatSSN = (value: string) => {
  let input = value || '';
  input = input.replace(/\D/g, '');

  input = input.substring(0, 9);
  const size = input.length;

  if (size < 4) {
    return input;
  }
  if (size < 6) {
    return `${input.substring(0, 3)}-${input.substring(3, 5)}`;
  }

  return `${input.substring(0, 3)}-${input.substring(3, 5)}-${input.substring(
    5
  )}`;
};

export const parsePhoneNumber15Length = (value: string) => {
  let input = value || '';
  input.replace('(', '');
  input.replace(')', '-');
  input.replace(' ', '');
  return input;
};

export const formatPhoneNumbers = (value: string) => {
  let input = value || '';
  input = input.replace(/\D/g, '');

  // Trim the remaining input to ten characters, to preserve phone number format
  input = input.substring(0, 10);

  const size = input.length;

  if (size === 0) {
    return input;
  }
  if (size < 4) {
    return `(${input}`;
  }
  if (size < 7) {
    return `(${input.substring(0, 3)}) ${input.substring(3, 6)}`;
  }

  return `(${input.substring(0, 3)}) ${input.substring(
    3,
    6
  )} - ${input.substring(6, 10)}`;
};

export const makeMoney = (value: any) => {
  if (value) {
    return accounting.formatMoney(value);
  }
  return value;
};


export const formatTime = (value: any) => {
  console.log(value);
  if(!value)
    return "";
  if(!value.parsed)
    return value.raw;
  
  const val = value.replace("T", " ");
  const m = val.parsed.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})$/);
  console.log("m1: " + m[1] + " m2: " + m[2] + " m3: " + m[3] + " m4: " + m[4] + " m5: " + m[5]);
  if(m?.length && m?.length > 0)
    return `${m[4]}:${m[5]}`;
  else 
    return "";
};

export const parseTime = (value: any) => {
  if(!value)
    return undefined;
  const m = value.match(/^(\d{2}):(\d{2})$/);
  if(m)
    return { parsed: null, raw: value };
};