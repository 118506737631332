import { addDays, format } from 'date-fns';
import React, { useState } from 'react';
import { Modal, Container, Form, Button, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaRegIdCard } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { AssignPbmRequest } from '../../ApiTypes/AssignPbmRequest';
import DocumentsApi from '../../Api/DocumentsApi';
import PbmApi from '../../Api/PbmApi';
import { FileDownload } from '../../Types/FileDownLoad';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';

import styles from './index.module.css';

export default function RxBridgeRequestModal({
  show,
  setShow,
  claimNumber,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  claimNumber: string;
}) {
  const [fileDownload, setFileDownload] = useState<FileDownload | null>(null);
  const onSubmit = (values: AssignPbmRequest) => {
    return PbmApi.assignPbm(values)
      .then((res) => {
        if (res.data.subject !== 'Failed') {
          toast.success('PBM and Card Added');
          DocumentsApi.getScanDocFile(+res.data.message)
            .then((res) => {
              setFileDownload(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to assign PBM');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
        setFileDownload(null);
      }}
      aria-labelledby='RxBridge-Request-Modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='PDF-modal'>
          <FaRegIdCard className='pe-1' /> RxBridge Request {claimNumber}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <div className='w-100 d-flex justify-content-between gap1Rem'>
            <div className={`${styles.width50}`}>
              <RFForm
                onSubmit={onSubmit}
                initialValues={{
                  claimNumber,
                  pbmId: 6,
                  startDate: format(new Date(), 'yyyy-MM-dd'),
                  endDate: format(addDays(new Date(), 90), 'yyyy-MM-dd'),
                }}
                render={({ handleSubmit, form, values, submitting }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Field
                        name='startDate'
                        label='Start'
                        type='text'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                        validate={requiredField}
                      />
                      <Field
                        name='endDate'
                        label='End'
                        type='text'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                        validate={requiredField}
                      />
                      <div className='d-flex justify-content-center align-items-center'>
                        <Button type='submit' size='sm' variant='primary'>
                          {submitting ? (
                            <Spinner
                              as='span'
                              animation='grow'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                            />
                          ) : (
                            'Send Request'
                          )}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              />
            </div>
            <div className={`${styles.width50}`}>
              {fileDownload && (
                <img
                  src={`data:application/jpg;base64,${fileDownload.file}`}
                  alt='Pharmacy Card'
                />
              )}
            </div>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
