import React, { Component, ErrorInfo, ReactNode } from "react";

interface State {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}
interface Props {
  children?: ReactNode;
}

export default class ErrorBoundary extends Component<Props, State> {
  state: State = { error: null, errorInfo: null };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    console.log({ error, errorInfo });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div style={{ padding: "1rem", margin: "auto" }}>
          <h2>Something went wrong</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}
