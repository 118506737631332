import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import { ExcessCarrierPolicyClassCode } from '../../ApiTypes/ExcessCarrierPolicyClassCode';

function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}

export default function ExcessCarrierPolicyClassCodeTable({
  codes,
  setData,
  updatePolicyClassCode,
  handleDeleteWarning,
}: {
  codes: ExcessCarrierPolicyClassCode[];
  setData: (
    data:
      | ExcessCarrierPolicyClassCode[]
      | ((
          data: ExcessCarrierPolicyClassCode[]
        ) => ExcessCarrierPolicyClassCode[])
  ) => void;
  updatePolicyClassCode: (
    policyClassCode: ExcessCarrierPolicyClassCode
  ) => void;
  handleDeleteWarning: (policyClassCode: ExcessCarrierPolicyClassCode) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const tableData = useMemo(() => codes, [codes]);

  const columnData: ColumnDef<ExcessCarrierPolicyClassCode>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      size: 75,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center align-items-center gap1Rem'>
            <Button
              type='button'
              variant='danger'
              size='sm'
              title='Delete'
              onClick={() => {
                handleDeleteWarning(row.original);
              }}
            >
              <FaTrash />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Policy Id',
      accessorFn: (d) => `${d.excessCarrierPolicyId}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Class Code Id',
      accessorFn: (d) => `${d.classCodeId}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Class Code',
      accessorKey: 'classCode',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Description',
      accessorKey: 'classCodeItem.description',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Default SIR',
      id: 'defaultSir',
      accessorFn: (d) => `${d.defaultSir ?? 0}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: function Cell({
        getValue,
        row: { index, getIsSelected, original },
        column: { id },
        table,
      }) {
        const initialValue = getValue();
        // We need to keep and update the state of the cell normally
        const [value, setValue] = React.useState(initialValue);

        // When the input is blurred, we'll call our table meta's updateData function
        const onUpdate = () => {
          table.options.meta?.updateData(index, id, value);
        };

        // If the initialValue is changed external, sync it up with our state
        React.useEffect(() => {
          setValue(initialValue);
        }, [initialValue]);

        return (
          <div className='input-group'>
            <input
              className='form-control form-control-sm'
              value={value as string}
              onChange={(e) => setValue(e.target.value)}
            />
            <button
              className='btn btn-outline-primary btn-sm'
              type='button'
              onClick={onUpdate}
            >
              Update
            </button>
          </div>
        );
      },
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
    autoResetPageIndex,
    // Provide our updateData function to our table meta
    meta: {
      updateData: (rowIndex, columnId, value) => {
        // Skip page index reset until after next rerender
        skipAutoResetPageIndex();
        setData((old: ExcessCarrierPolicyClassCode[]) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              var updated = {
                ...old[rowIndex]!,
                [columnId]: value,
              };
              updatePolicyClassCode(updated);
              return updated;
            }
            return row;
          })
        );
      },
    },
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
