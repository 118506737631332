import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import {
  FaArrowLeft,
  FaArrowRight,
  FaCheck,
  FaEdit,
  FaMinus,
  FaTrash,
} from 'react-icons/fa';
import {
  ClaimCmsinfoIcdcode,
  ICDStatusEnum,
} from '../../../ApiTypes/ClaimCmsinfoIcdcode';

export default function ICDDiagnosisCodesTable({
  data,
  handleDelete,
  handleEdit,
  handleStatusChange,
  icdCodeStatus,
}: {
  data: ClaimCmsinfoIcdcode[];
  handleDelete: (h: ClaimCmsinfoIcdcode) => void;
  handleEdit: (h: ClaimCmsinfoIcdcode) => void;
  handleStatusChange: (code: ClaimCmsinfoIcdcode, status: number) => void;
  icdCodeStatus: number;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<ClaimCmsinfoIcdcode>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      size: 75,
      cell: ({ row }) => {
        return (
          <div>
            {icdCodeStatus === ICDStatusEnum.Unknown && (
              <div className='d-flex justify-content-center align-items-center gap1Rem'>
                <Button
                  type='button'
                  variant='success'
                  title='Accepted'
                  size='sm'
                  onClick={() => {
                    handleStatusChange(row.original, ICDStatusEnum.Accepted);
                  }}
                >
                  <FaCheck />
                </Button>
                <Button
                  type='button'
                  variant='danger'
                  title='Unrelated'
                  size='sm'
                  onClick={() => {
                    handleStatusChange(row.original, ICDStatusEnum.Unrelated);
                  }}
                >
                  <FaMinus />
                </Button>
              </div>
            )}
            {icdCodeStatus !== ICDStatusEnum.Unknown && (
              <div className='d-flex justify-content-center align-items-center gap-1'>
                {icdCodeStatus === ICDStatusEnum.Accepted && (
                  <Button
                    type='button'
                    variant='warning'
                    title='Change To Unrelated'
                    size='sm'
                    onClick={() => {
                      handleStatusChange(row.original, ICDStatusEnum.Unrelated);
                    }}
                  >
                    <FaArrowRight />
                  </Button>
                )}
                {icdCodeStatus === ICDStatusEnum.Unrelated && (
                  <Button
                    type='button'
                    variant='warning'
                    title='Change To Accepted'
                    size='sm'
                    onClick={() => {
                      handleStatusChange(row.original, ICDStatusEnum.Accepted);
                    }}
                  >
                    <FaArrowLeft />
                  </Button>
                )}
                <Button
                  type='button'
                  variant='primary'
                  title='Edit'
                  size='sm'
                  onClick={() => {
                    handleEdit(row.original);
                  }}
                >
                  <FaEdit />
                </Button>
                <Button
                  type='button'
                  variant='danger'
                  title='Delete'
                  size='sm'
                  onClick={() => {
                    handleDelete(row.original);
                  }}
                  disabled={row.original.isImport === true}
                >
                  <FaTrash />
                </Button>
              </div>
            )}
          </div>
        );
      },
    },
    {
      header: 'Code',
      accessorFn: (d) => `${d.icdcode.name}`,
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      header: 'Description',
      accessorFn: (d) => `${d.icdcode.description}`,
      sortingFn: 'text',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={false}
      showPagination={false}
      selectableRow={true}
      highlightRow={true}
    />
  );
}
