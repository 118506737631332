import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { UwInsuranceCo } from '../ApiTypes/UwInsuranceCo';
import { InsCoPremiumHistory } from '../ApiTypes/InsCoPremiumHistory';

class UwInsuranceCompanyApi {
  static getInsCos(insurCoId?: number) {
    var url = `${API_URL}/api/uwinsurancecompanies`;
    if (insurCoId) {
      url += `?insurCoId=${insurCoId}`;
    }
    return axios.get<UwInsuranceCo[]>(url, getAuthHeader());
  }
  static getPremiumHistory(insurCoId: number) {
    return axios.get<InsCoPremiumHistory[]>(
      `${API_URL}/api/uwinsurancecompanypremiumhistory/${insurCoId}`,
      getAuthHeader()
    );
  }
  static deleteHistory(history: InsCoPremiumHistory) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/uwinsurancecompanypremiumhistory/${history.id}`,
      getAuthHeader()
    );
  }
  static updateHistory(history: InsCoPremiumHistory) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/uwinsurancecompanypremiumhistory/${history.id}`,
      history,
      getAuthHeader()
    );
  }
  static createHistory(history: InsCoPremiumHistory) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/uwinsurancecompanypremiumhistory`,
      history,
      getAuthHeader()
    );
  }
}

export default UwInsuranceCompanyApi;
