import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { handleError } from '.';
import ClaimTypeConfigurationApi from '../Api/ClaimTypeConfigurationApi';

export const fetchClaimTypeTabs = createAsyncThunk(
  'FETCH_CLAIM_tYPE_TABS_BY_CLAIM_TYPE',
  async (claimType: number, thunkApi) => {
    try {
      const response = await ClaimTypeConfigurationApi.getClaimTypeTabs(
        claimType
      );
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchClaimTypeConfigurations = createAsyncThunk(
  'FETCH_CLAIM_TYPE_CONFIGURATIONS',
  async (claimTypeId: number, thunkApi) => {
    try {
      const response =
        await ClaimTypeConfigurationApi.getClaimTypeConfigurations(claimTypeId);
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchClaimSubTypes = createAsyncThunk(
  'FETCH_CLAIM_SUB_TYPES',
  async (claimTypeId: number, thunkApi) => {
    try {
      const response = await ClaimTypeConfigurationApi.getClaimSubTypes(
        claimTypeId
      );
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
