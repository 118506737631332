import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import WitnessTable from './WitnessTable';
import { Witness } from '../../../ApiTypes/Witness';
import WitnessApi from '../../../Api/WitnessApi';
import DeleteConfirmationModal from '../Legal/DeleteConfirmationModal';
import WitnessAddEdit from './WitnessAddEdit';

export default function WitnessesTab({ claimType }: { claimType: number }) {
  let { claimNumber } = useParams();
  const [witnesses, setWitnesses] = useState<Witness[]>([]);
  const [witnessToDelete, setWitnessToDelete] = useState<Witness | null>(null);
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const [showWitnessModal, setShowWitnessModal] = useState<boolean>(false);
  const [selectedWitnessId, setSelectedWitnessId] = useState<string | "">("");

  useEffect(() => {
    getWitnessesForClaim();
  }, [claimNumber]);

  const getWitnessesForClaim = () => {
    if (claimNumber) {
      WitnessApi.getWitnessesForClaim(claimNumber)
        .then((res) => {
          setWitnesses(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setWitnesses([]);
    }
  };

  const handleWitnessToDelete = (w: Witness) => {
    setWitnessToDelete(w);
    setShowDelete(true);
  };

  const handleDelete = () => {
    if (witnessToDelete && claimNumber) {
      return WitnessApi.deleteWitness(witnessToDelete?.id, claimNumber)
        .then((res) => {
          if (res.data.success) {
            getWitnessesForClaim();
            setWitnessToDelete(null);
            setShowDelete(false);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to delete Witness');
        });
    }
    return Promise.resolve();
  };

  const removeModal = () => {
    setShowWitnessModal(false);
    setSelectedWitnessId("");
  };

  return (
    <div className='w-100 h-100'>
      <div className='d-flex justify-content-center mb-3'>
        {/* <Link to='new'> */}
          <Button
            type='button'
            variant='primary'
            size='sm'
            onClick={() => {
              setSelectedWitnessId("");
              setShowWitnessModal(true);
            }}
            disabled={claimNumber === undefined}
            className='button-icon-text'
          >
            <FaPlusCircle /> Add New
          </Button>
        {/* </Link> */}
      </div>
      <WitnessTable
        witnesses={witnesses}
        handleWitnessToDelete={handleWitnessToDelete}
        show={showWitnessModal}
        setShow={setShowWitnessModal}
        selectedWitnessId={selectedWitnessId}
        setSelectedWitnessId={setSelectedWitnessId}
      />
      <WitnessAddEdit 
        claimType={claimType}
        selectedWitnessId={selectedWitnessId}
        show={showWitnessModal}
        setShow={removeModal}
        getWitnesses={getWitnessesForClaim}
      />
      <DeleteConfirmationModal
        show={showDelete}
        setShow={setShowDelete}
        setNull={setWitnessToDelete}
        handleDelete={handleDelete}
      />
    </div>
  );
}
