import React from 'react';
import { Field } from 'react-final-form';
import { parseDatesForServer } from '../../Utils';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderText from '../Common/FieldBSRenderText';

import styles from './index.module.css';

export default function WC1Injury({ values }: { values: any }) {
  return (
    <div className='d-flex justify-content-center'>
      <div className='w-50 pe-2'>
        <div className='d-flex justify-content-between align-items-center'>
          <Field
            name='dateOfInjury'
            label='Date of Injury'
            parse={parseDatesForServer}
            component={FieldBSRenderDate}
          />
          <Field
            name='timeOfInjury'
            label='Time of Injury'
            type='text'
            component={FieldBSRenderText}
          />
          <div
            className={`d-flex justify-content-center  ${styles.flexGap1rem}`}
          >
            <Field
              name='isAm'
              type='checkbox'
              label='AM'
              checked={values?.isAm?.toString() === 'true'}
              component={FieldBSRenderCheckbox}
            />
          </div>
        </div>
        <Field
          name='locationOfInjury'
          label='Location of Injury'
          type='text'
          component={FieldBSRenderText}
        />
        <Field
          name='injuryCounty'
          type='text'
          label='County of Injury'
          component={FieldBSRenderText}
        />
        <div className='d-flex justify-content-between align-items-center'>
          <div className={styles.width35}>
            <Field
              name='dateEmployerHadNotice'
              label='Date Injury Reported to Employer'
              parse={parseDatesForServer}
              component={FieldBSRenderDate}
            />
          </div>
          <div className={styles.width35}>
            <Field
              name='dateEmployeeStoppedWorking'
              label='First Day of Work Missed'
              parse={parseDatesForServer}
              component={FieldBSRenderDate}
            />
          </div>
        </div>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <div
            className={`d-flex justify-content-center  ${styles.flexGap1rem}`}
          >
            <Field
              name='employeePaidOnDoi'
              type='checkbox'
              label='Full Pay on Date of Injury'
              checked={values?.employeePaidOnDoi?.toString() === 'true'}
              component={FieldBSRenderCheckbox}
            />
          </div>
          <div
            className={`d-flex justify-content-center  ${styles.flexGap1rem}`}
          >
            <Field
              name='injuryOnPremies'
              type='checkbox'
              label='Injury Occur on Employer Premises'
              checked={values?.injuryOnPremies?.toString() === 'true'}
              component={FieldBSRenderCheckbox}
            />
          </div>
        </div>
        <Field
          name='typeOfInjury'
          label='Type of Injury/Illness (i.e., contusion, strain, fracture)'
          type='text'
          component={FieldBSRenderText}
        />
        <Field
          name='bodyPartAffected'
          type='text'
          label='Body Part Affected (i.e., left upper arm, low back, right lower leg)'
          component={FieldBSRenderText}
        />
        <Field
          name='descriptionOfInjury'
          type='text'
          label='How Injury Occurred (i.e., fell down, twisted ankle on curb)'
          component={FieldBSRenderText}
        />
        <Field
          name='dateOfDeath'
          label='Date of Death (If Fatal Injury)'
          parse={parseDatesForServer}
          component={FieldBSRenderDate}
        />
      </div>
    </div>
  );
}
