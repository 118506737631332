import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Form as RFFForm, Field } from 'react-final-form';
import {
  ClaimSearchRequest,
  SearchSeverity,
  SearchClaimStatus,
} from '../../ApiTypes/ClaimSearchRequest';
import { removeNonDigits, formatSSN } from '../../Utils/InputFormatters';
import FieldBSRenderText from '../Common/FieldBSRenderText';

export default function ClaimSearch({
  search,
}: {
  search: (values: ClaimSearchRequest) => Promise<void>;
}) {
  return (
    <RFFForm
      onSubmit={search}
      initialValues={{
        severity: SearchSeverity.Any,
        claimStatus: SearchClaimStatus.ShowAll,
      }}
      render={({ handleSubmit, form, values, pristine, submitting }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center gap-3'>
                <h4>Attach Document to Claim</h4>
              </div>
              <div className='d-flex justify-content-end align-items-center gap1Rem'>
                <Button
                  type='button'
                  variant='outline-secondary'
                  size='sm'
                  onClick={() => {
                    form.reset();
                  }}
                >
                  Reset
                </Button>
                <Button type='submit' variant='primary' size='sm'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Search'
                  )}
                </Button>
              </div>
            </div>
            <div className='d-flex justify-content-around gap1Rem'>
              <Field
                name='firstName'
                type='text'
                label='First Name'
                component={FieldBSRenderText}
              />
              <Field
                name='lastName'
                type='text'
                label='Last Name'
                component={FieldBSRenderText}
              />
              <Field
                name='claimNumber'
                type='text'
                label='Claim #'
                component={FieldBSRenderText}
              />
              <Field
                name='ssn'
                type='text'
                label='SSN'
                parse={removeNonDigits}
                format={formatSSN}
                component={FieldBSRenderText}
              />
            </div>
          </Form>
        );
      }}
    />
  );
}
