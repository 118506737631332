import React, { useState, useMemo, useEffect } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { ScanDocNotesRequest } from '../../ApiTypes/ScanDocNotesRequest';
import { FaEdit, FaEye } from 'react-icons/fa';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getDxReport, useDocViewModal } from '../DocViewModal/useDocViewModal';
import DocumentsApi from '../../Api/DocumentsApi';
import { toast } from 'react-toastify';
import DocViewModal from '../DocViewModal/DocViewModal';
import RequestDocNotesModal from '../RequestDocNotes/RequestDocNotesModal';
import { ScanDocNotesRequestFormType } from '../../Types/ScanDocNotesRequestFormType';
import styles from './index.module.css';
import IndeterminateCheckbox from '../Common/IndeterminateCheckbox';
import { displayDateOnly } from '../../Utils';
import { DocsNotesRequestStatus } from '../../ApiTypes/DocumentStatus';
import { format } from 'date-fns';
import {
  DevExpressReportRequest,
  DxAvailableReports,
} from '../../ApiTypes/DevExpressReportRequest';
import { requestDx } from '../DxReportRequestModal/useDxReportRequestModal';
import { useAppDispatch } from '../../Reducers/Store';
import {
  setDocViewFileDownload,
  setShowDocView,
} from '../../Actions/DocViewActions';
import { FileDownload } from '../../Types/FileDownLoad';

export default function NotesRequestTable({
  data,
  getRequests,
}: {
  data: ScanDocNotesRequest[];
  getRequests: () => void;
}) {
  const dispatch = useAppDispatch();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [rowSelection, setRowSelection] = React.useState({});
  const [selectedRequests, setSelectedRequests] = useState<
    ScanDocNotesRequest[]
  >([]);

  const {
    showDocViewModal,
    fileDownload,
    setShowDocViewModal,
    setFileDownload,
  } = useDocViewModal();

  const [showEditRequest, setShowEditRequest] = useState<boolean>(false);
  const [requestToEdit, setRequestToEdit] =
    useState<ScanDocNotesRequest | null>(null);

  useEffect(() => {
    setRowSelection({});
  }, [data]);
  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  const setShowDx = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownloadDx = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  const handleRowSelectionChange = () => {
    setSelectedRequests(
      table.getSelectedRowModel().flatRows.map((r) => r.original)
    );
  };

  const handleEditRequest = (request: ScanDocNotesRequest) => {
    setRequestToEdit(request);
    setShowEditRequest(true);
  };

  const handleCloseEditRequest = (show: boolean) => {
    setRequestToEdit(null);
    setShowEditRequest(false);
  };

  const handleEditSaveRequestDocNote = (
    values: ScanDocNotesRequestFormType
  ) => {
    return DocumentsApi.updateNotesRequestForDoc(
      values,
      values?.scanDoc?.claimno ?? '',
      values.save
    )
      .then((res) => {
        if (res.data.success) {
          handleCloseEditRequest(false);
          getRequests();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update notes request');
      });
  };

  const handleStatusUpdateRequestDocNote = (
    values: ScanDocNotesRequestFormType
  ) => {
    return DocumentsApi.updateNotesRequestForDoc(
      values,
      values?.scanDoc?.claimno ?? '',
      values.save
    )
      .then((res) => {
        if (res.data.success) {
          getRequests();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update notes request');
      });
  };

  const tableData = useMemo(() => data, [data]);

  const getScanDocFile = (docId: number) => {
    return DocumentsApi.getScanDocFile(docId)
      .then((res) => {
        if (res.data.file.length) {
          setFileDownload(res.data);
          setShowDocViewModal(true);
        } else {
          toast.error('Failed to get document');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get document');
      });
  };

  const markFirst = () => {
    if (selectedRequests.length === 0) return;

    selectedRequests.forEach((r) => {
      const values: ScanDocNotesRequestFormType = {
        ...r,
        save: false,
        status: DocsNotesRequestStatus.First,
        firstAttempt: format(new Date(), 'yyyy-MM-dd'),
      };
      handleStatusUpdateRequestDocNote(values);
    });
  };

  const markSecond = () => {
    if (selectedRequests.length === 0) return;

    selectedRequests.forEach((r) => {
      const values: ScanDocNotesRequestFormType = {
        ...r,
        save: false,
        status: DocsNotesRequestStatus.Second,
        secondAttempt: format(new Date(), 'yyyy-MM-dd'),
      };
      handleStatusUpdateRequestDocNote(values);
    });
  };

  const markNonResponsive = () => {
    if (selectedRequests.length === 0) return;

    selectedRequests.forEach((r) => {
      const values: ScanDocNotesRequestFormType = {
        ...r,
        save: false,
        status: DocsNotesRequestStatus.Closed,
        recievedOn: format(new Date(), 'yyyy-MM-dd'),
      };
      handleStatusUpdateRequestDocNote(values);
    });
  };

  const markReceived = () => {
    if (selectedRequests.length === 0) return;

    selectedRequests.forEach((r) => {
      const values: ScanDocNotesRequestFormType = {
        ...r,
        save: false,
        status: DocsNotesRequestStatus.Closed,
        recievedOn: format(new Date(), 'yyyy-MM-dd'),
      };
      handleStatusUpdateRequestDocNote(values);
    });
  };

  const columnData: ColumnDef<ScanDocNotesRequest>[] = [
    {
      header: ({ table }) => (
        <div className='px-3 d-flex gap1Rem'>
          {Object.keys(rowSelection).length}
          <IndeterminateCheckbox
            className='form-check-input'
            {...{
              id: 'totalNotesSelected',
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      id: 'options',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => {
        const button = document.getElementById(`${row.original.keyNotesReqId}`);
        const td = button?.closest('td');
        if (td) {
          td.style.overflow = 'visible';
        }
        return (
          <div className='d-flex gap1Rem'>
            <IndeterminateCheckbox
              className='form-check-input'
              {...{
                id: row.id,
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
            <DropdownButton
              id={`${row.original.keyNotesReqId}`}
              title='Options'
              size='sm'
            >
              <Link to={`/claims/${row.original.scanDoc?.claimno}`}>
                <Dropdown.Item
                  as='button'
                  className='button-icon-text justify-content-start'
                >
                  <FaEye /> Claim
                </Dropdown.Item>
              </Link>
              <Link
                to={`/scandocs/${row.original.scanDocId!}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Dropdown.Item
                  as='button'
                  className='button-icon-text justify-content-start'
                  onClick={() => {
                    // getScanDocFile(row.original.scanDocId!);
                  }}
                >
                  <FaEye /> Doc
                </Dropdown.Item>
              </Link>
              <Dropdown.Item
                as='button'
                className='button-icon-text justify-content-start'
                onClick={() => {
                  const request: DevExpressReportRequest = {
                    ...requestDx,
                    report: DxAvailableReports.rptDoctorNotesRequest,
                    keyDocRequestId: row.original.keyNotesReqId,
                    insurCoId: 1,
                  };
                  getDxReport(request, setFileDownloadDx, setShowDx);
                }}
              >
                <FaEye /> Letter
              </Dropdown.Item>
              <Dropdown.Item
                as='button'
                className='button-icon-text justify-content-start'
                onClick={() => {
                  handleEditRequest(row.original);
                }}
              >
                <FaEdit /> Request
              </Dropdown.Item>
            </DropdownButton>
          </div>
        );
      },
    },
    {
      header: 'Claimant',
      accessorKey: 'claimantName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claim #',
      accessorKey: 'scanDoc.claimno',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Amount',
      accessorKey: 'amount',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Service Date',
      accessorKey: 'dateOfService',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.dateOfService ?? ''),
    },
    {
      header: 'Remind Date',
      accessorKey: 'remindDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.remindDate ?? ''),
    },
    {
      header: 'Requested On',
      accessorKey: 'createdOn',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.createdOn ?? ''),
    },
    {
      header: '1st Request',
      accessorKey: 'firstAttempt',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.firstAttempt ?? ''),
    },
    {
      header: '2nd Request',
      accessorKey: 'secondAttempt',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.secondAttempt ?? ''),
    },
    {
      header: 'Requested By',
      accessorKey: 'createdBy',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, [rowSelection]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
      />
      <div className=' py-3 d-flex justify-content-center align-items-center gap1Rem'>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          title='Mark First'
          onClick={() => {
            markFirst();
          }}
        >
          Mark First
        </Button>
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          title='Mark Second'
          onClick={() => {
            markSecond();
          }}
        >
          Mark Second
        </Button>
        <Button
          type='button'
          variant='outline-danger'
          size='sm'
          title='Mark Non Responsive'
          onClick={() => {
            markNonResponsive();
          }}
        >
          Non Responsive
        </Button>
        <Button
          type='button'
          variant='outline-success'
          size='sm'
          title='Mark Received'
          onClick={() => {
            markReceived();
          }}
        >
          Received
        </Button>
      </div>
      <DocViewModal
        show={showDocViewModal}
        setShow={setShowDocViewModal}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
      <RequestDocNotesModal
        show={showEditRequest}
        setShow={handleCloseEditRequest}
        noteRequest={requestToEdit}
        doc={null}
        claimNumber={requestToEdit?.scanDoc?.claimno ?? ''}
        claimantName={requestToEdit?.claimantName ?? ''}
        handleEditSaveRequestDocNote={handleEditSaveRequestDocNote}
      />
    </>
  );
}
