import React from 'react'
import { Button, Container, Modal } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import styles from "./index.module.css"

export default function ConfirmModal({
  show,
  setShow,
  message,
  confirm,
}: {
  show: boolean,
  message: JSX.Element,
  setShow: (show?: boolean) => void,
  confirm: () => void,
}) {
  return (
    <Modal
      centered
      show={show}
      size="lg"
      onHide={() => {
        setShow(false)
      }}
      dialogClassName=""
      aria-labelledby="Confirm-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="button-icon-text" id="Confirm-modal">
          <FaCheck className="pe-1" /> Confirm
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {message}
          <div className={styles.confirmButtonsDiv}>
            <Button
              type="button"
              size="lg"
              variant='primary'
              onClick={confirm}
            >
              Confirm
            </Button>
            <Button
              type="button"
              size="lg"
              variant='secondary'
              onClick={() => {
                setShow(false)
              }}
            >
              Cancel
            </Button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  )
}
