import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { VPaymentTab } from '../../ApiTypes/VPaymentTab';
import { displayDateOnly } from '../../Utils';
import accounting from 'accounting';

export default function PaymentsTable({ data }: { data: VPaymentTab[] }) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<VPaymentTab>[] = [
    {
      header: 'TX Date',
      accessorKey: 'txDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'SVC From',
      accessorKey: 'serviceFrom',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'SVC To',
      accessorKey: 'serviceTo',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'Payee',
      accessorKey: 'payee',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Payee Name',
      accessorKey: 'checkPayableTo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Tax Id',
      accessorKey: 'taxId',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 100,
    },
    {
      header: 'TX Amount',
      accessorFn: (d) => `${d.txAmount}`,
      sortingFn: 'alphanumericCaseSensitive',
      filterFn: 'includesString',
      maxSize: 100,
      cell: (d) => accounting.formatMoney(d.row.original.txAmount ?? 0),
    },
    {
      header: 'Check Amount',
      accessorFn: (d) => `${d.checkAmount}`,
      sortingFn: 'alphanumericCaseSensitive',
      filterFn: 'includesString',
      maxSize: 120,
      cell: (d) => accounting.formatMoney(d.row.original.checkAmount ?? 0),
    },
    {
      header: 'Check #',
      accessorKey: 'checkNumber',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 100,
    },
    {
      header: 'Pay Code',
      accessorKey: 'payCode',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Financial Bucket',
      accessorKey: 'fBucket',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 130,
    },
    {
      header: 'Status',
      accessorKey: 'status',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 75,
    },
    {
      header: 'Cleared',
      accessorFn: (d) => (d.isCleared ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 75,
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
