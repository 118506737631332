import { format } from 'date-fns';
import { FormApi } from 'final-form';
import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { EdiCode } from '../../ApiTypes/EdiCode';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { useAppSelector } from '../../Reducers/Store';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderYesNoSelect from '../Common/FieldBSRenderYesNoSelect';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';

import styles from './index.module.css';

export default function DenialForm({
  values,
  partialDenial,
  transaction,
  formType,
  form,
}: {
  partialDenial: boolean;
  values: Partial<EdiTransaction>;
  transaction: EdiTransaction | null;
  formType: 'FROI' | 'SROI';
  form: FormApi<EdiTransaction, Partial<EdiTransaction>>;
}) {
  const { ediCodes } = useAppSelector((state) => state.reference);

  useEffect(() => {
    handleLoad();
  }, [transaction, formType]);

  const handleLoad = () => {
    if (!transaction) {
      form.change('denialNarrative', 'All other 34-9 defenses reserved.');
    }
    if (transaction && !transaction.fullDenialEffectiveDate) {
      form.change('fullDenialEffectiveDate', format(new Date(), 'yyyy-MM-dd'));
    }
  };

  return (
    <div className='mb-3'>
      <div className={`${styles.edi2Grid}`}>
        <Field
          name='fullDenialEffectiveDate'
          label='Denial Effective Date'
          parse={parseDatesForServer}
          component={FieldBSRenderDate}
          validate={requiredField}
        />
        <Field
          name='fullWagesPaidOnDoi'
          label='Full Wages Paid on DOI'
          component={FieldBSRenderYesNoSelect}
        />
      </div>
      <Field
        name='wc3bcheck1'
        type='checkbox'
        label='1. This serves as notice, pursuant to O. C. G. A. §34-9-221. that the right to compensation in this claim is being controverted on the following specific grounds:'
        component={FieldBSRenderCheckbox}
      />
      <Field
        name='wc3bcheck2'
        type='checkbox'
        label='2. This is notice, pursuant to O.C.G.A. §34-9-200 and Board Rule 205(b), that the compensability of the following medical treatment/test is being controverted for the following reasons:'
        component={FieldBSRenderCheckbox}
      />
      <Field
        name='wc3bcheck3'
        type='checkbox'
        label='3. If only part of the claim is being controverted, state the specific part of the claim and the reason(s) it is being controverted:'
        component={FieldBSRenderCheckbox}
      />
      <div className='mt-3'>
        {!partialDenial && (
          <Field
            name='fullDenialReasonCode'
            label='Denial Reason'
            options={ediCodes.filter((c) => c.dn === '0198')}
            optionMethod={(options: EdiCode[]) =>
              options.map((o) => (
                <option key={o.code} value={o.code!}>
                  {o.description}
                </option>
              ))
            }
            validate={requiredField}
            component={FieldBSRenderSelect}
          />
        )}
        {partialDenial && (
          <Field
            name='partialDenialReasonCode'
            label='Denial Reason'
            options={ediCodes.filter((c) => c.dn === '0294')}
            optionMethod={(options: EdiCode[]) =>
              options.map((o) => (
                <option key={o.code} value={o.code!}>
                  {o.description}
                </option>
              ))
            }
            validate={requiredField}
            component={FieldBSRenderSelect}
          />
        )}
        <Field
          name='denialNarrative'
          label='Denial Narrative'
          rows={3}
          validate={requiredField}
          component={FieldBSRenderTextArea}
        />
      </div>
    </div>
  );
}
