import React, { useState, useEffect } from 'react';
import { FaMoneyCheck } from 'react-icons/fa';
import { Field, Form as RFFForm } from 'react-final-form';
import ReservesApi from '../../Api/ReservesApi';
import { Address } from '../../ApiTypes/Address';
import { ClaimPaymentTotals } from '../../ApiTypes/ClaimPaymentTotals';
import { ClaimReserveBuckets } from '../../ApiTypes/ClaimReserveBuckets';
import { ProviderSearchResult } from '../../ApiTypes/ProviderSearchResult';
import { useAppSelector } from '../../Reducers/Store';
import {
  displayDateOnly,
  parseDatesForServer,
  setAddressTextString,
} from '../../Utils';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { Transaction } from '../../ApiTypes/Transaction';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { PayCodeItem } from '../../ApiTypes/PayCodeItem';
import { requiredField } from '../../Utils/FieldValidation';
import { formatNumbers, cleanMoney } from '../../Utils/InputFormatters';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import { Claimant } from '../../ApiTypes/Claimant';
import { Claim } from '../../ApiTypes/Claim';
import accounting from 'accounting';
import { FormApi } from 'final-form';
import ClaimSearch from './ClaimSearch';
import ClaimApi from '../../Api/ClaimApi';
import { ClaimSearchResult } from '../../ApiTypes/ClaimSearchResult';
import { toast } from 'react-toastify';
import ClaimantApi from '../../Api/ClaimantApi';
import ProviderLookupModal from '../ProviderLookup/ProviderLookupModal';
import { format, isBefore } from 'date-fns';
import { DuplicateTransactionRequest } from '../../ApiTypes/DuplicateTransactionRequest';
import PaymentsApi from '../../Api/PaymentsApi';

export default function PaymentEntry({ txType }: { txType?: string }) {
  const { payCodes } = useAppSelector((state) => state.reference);
  const { userName } = useAppSelector((state) => state.user);

  const [claimReserveBuckets, setClaimReserveBuckets] = useState<
    ClaimReserveBuckets[]
  >([]);
  const [claimPaymentTotals, setClaimPaymentTotals] =
    useState<ClaimPaymentTotals | null>(null);

  const [claimant, setClaimant] = useState<Claimant | null>(null);
  const [claim, setClaim] = useState<Claim | null>(null);
  const [provider, setProvider] = useState<ProviderSearchResult | null>(null);

  const [claimantAddress, setClaimantAddress] = useState<string>('');
  const [providerAddress, setProviderAddress] = useState<string>('');

  const [selectedPayCode, setSelectedPayCode] = useState<PayCodeItem | null>(
    null
  );
  const [payCodeEnabled, setPayCodeEnabled] = useState<boolean>(true);
  const [showCheckDate, setShowCheckDate] = useState<boolean>(true);
  const [showCheckNumber, setShowCheckNumber] = useState<boolean>(true);
  const [reserveAmt, setReserveAmt] = useState<number>(0);
  const [fBucketText, setFBucketText] = useState<string>('');

  const [showClaimSearch, setShowClaimSearch] = useState<boolean>(false);
  const [showProviderSearch, setShowProviderSearch] = useState<boolean>(false);

  const [
    showAdminAmountGreaterThanReserves,
    setShowAdminAmountGreaterThanReserves,
  ] = useState<JSX.Element | null>(null);
  const [showDuplicateWarning, setShowDuplicateWarning] =
    useState<JSX.Element | null>(null);
  const [showRefundGreaterWarning, setShowRefundGreaterWarning] =
    useState<JSX.Element | null>(null);
  const [showRecoveryIsMoreThanBucket, setShowRecoveryIsMoreThanBucket] =
    useState<JSX.Element | null>(null);

  interface formType extends Transaction {
    claimantName: string;
    keepClaim: boolean;
    keepProvider: boolean;
    keepNone: boolean;
  }

  let formInstance: FormApi<formType, Partial<formType>>;

  useEffect(() => {
    handleReimbursementType();
  }, [txType]);

  const handleReimbursementType = () => {
    reset();
    resetClaim();
    resetProvider();
    if (txType && txType === 'REIMBURSEMENT') {
      setPayCodeEnabled(false);
      formInstance.change('payCode', 'IM');
      handlePayCodeChange('IM');
      setShowCheckDate(true);
      setShowCheckNumber(true);
    } else {
      setPayCodeEnabled(true);
      formInstance.change('payCode', '');
      handlePayCodeChange('');
      setShowCheckDate(true);
      setShowCheckNumber(true);
    }
  };

  const getClaimantAddress = (claimant: Claimant) => {
    let address: Address | null = null;
    if (claimant && claimant.usePaymentAddress) {
      address = claimant?.paymentAddress ?? null;
    } else {
      address = claimant?.mainAddress ?? null;
    }
    setAddressTextString(address, setClaimantAddress);
  };

  const getProviderAddress = (provider?: ProviderSearchResult) => {
    let address: Address | null = null;
    if (provider) {
      const { address1, address2, city, state, zip } = provider;
      address = {
        address1,
        address2,
        city,
        state,
        zip,
        addressId: 0,
        addressName: '',
        ownerId: 0,
        ownerTable: '',
      };
      setAddressTextString(address, setProviderAddress);
    }
  };

  const getFBucketText = (payCode?: PayCodeItem) => {
    let text = 'FBucket';
    let reserveBucket: ClaimReserveBuckets | undefined;
    if (payCode) {
      reserveBucket = claimReserveBuckets.find(
        (b) => b.fBucket === payCode?.fBucket
      );
    }
    if (reserveBucket) {
      text = `${reserveBucket.description} - Reserves: ${accounting.formatMoney(
        reserveBucket.reserves
      )}`;
      setReserveAmt(reserveBucket.reserves);
    }
    setFBucketText(text);
  };

  const checkTxType = (payCode?: string) => {
    let code: PayCodeItem | undefined;
    if (payCode) {
      code = payCodes.find((p) => p.payCode === payCode);
      setSelectedPayCode(code ?? null);
      getFBucketText(code);
    }

    if (code?.txType === 'RECOVERY' || code?.txType === 'REIMBURSEMENT') {
      setShowCheckDate(true);
      setShowCheckNumber(true);
    } else {
      setShowCheckDate(false);
      setShowCheckNumber(false);
    }
  };

  const handlePayCodeChange = (payCode?: string) => {
    checkTxType(payCode);
  };

  const handleSelectedClaim = (claim: ClaimSearchResult) => {
    ClaimApi.getClaimByClaimNumber(claim.claimNo)
      .then((res) => {
        setClaim(res.data);
        getClaimant(res.data.claimantId ?? 0);
        getReserves(res.data.claimNo);
        getClaimPaymentTotals(res.data.claimNo);
        setShowClaimSearch(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get claim');
      });
  };

  const getClaimant = (claimantId: number) => {
    ClaimantApi.getClaimantById(claimantId)
      .then((res) => {
        setClaimant(res.data);
        getClaimantAddress(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get claimant');
      });
  };
  const getReserves = (claimNumber: string) => {
    ReservesApi.getClaimReserveBucketsForClaim(claimNumber)
      .then((res) => {
        setClaimReserveBuckets(res.data);
        if (selectedPayCode) {
          handlePayCodeChange(selectedPayCode.payCode);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to reserve buckets for claim');
      });
  };
  const getClaimPaymentTotals = (claimNumber: string) => {
    ReservesApi.getClaimPaymentTotals(claimNumber)
      .then((res) => {
        setClaimPaymentTotals(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to claim payment totals');
      });
  };

  const handleProviderSelect = (result: ProviderSearchResult) => {
    setProvider(result);
    getProviderAddress(result);
    setShowProviderSearch(false);
  };

  const stopAllSubmitting = () => {
    formInstance.change('keepClaim', false);
    formInstance.change('keepProvider', false);
    formInstance.change('keepNone', false);
  };

  const onSubmit = async (values: formType) => {
    if (!claimant || !claim) {
      toast.error('Please, choose a claim.');
      stopAllSubmitting();
      return;
    }
    if (
      !values.payee ||
      (values.payee?.toUpperCase() === 'C' && !claimant) ||
      (values.payee?.toUpperCase() === 'P' && !provider)
    ) {
      toast.error('Please, choose a provider');
      stopAllSubmitting();
      return;
    }
    if (selectedPayCode?.txType === 'PAYMENT') {
      if (!values.serviceFrom || !values.serviceTo) {
        toast.error('Please enter a service from and to date. ');
        stopAllSubmitting();
        return;
      }
      if (isBefore(new Date(values.serviceTo), new Date(values.serviceFrom))) {
        toast.error('Service From Date must be less than Service To date.');
        stopAllSubmitting();
        return;
      }
      if (
        isBefore(new Date(values.serviceFrom), new Date(claim.injuryDate ?? ''))
      ) {
        toast.error(
          'Service From date must be greater or equal to injury date.'
        );
        stopAllSubmitting();
        return;
      }
    }
    if (selectedPayCode?.txType !== 'PAYMENT') {
      if (!values.checkNumber) {
        toast.error('Please, enter a check number');
        stopAllSubmitting();
        return;
      }
      if (!values.checkDate) {
        toast.error('Please, enter a check date');
        stopAllSubmitting();
        return;
      }
    }

    if (selectedPayCode?.txType === 'PAYMENT') {
      let taxId: string = '';
      if (values.payee.toUpperCase() === 'P') {
        taxId = provider?.taxId ?? '';
      } else {
        taxId = claimant?.ssn ?? '';
      }

      let request: DuplicateTransactionRequest = {
        amount: values.amount ?? 0,
        claimNumber: claim.claimNo!,
        fromDate: values.serviceFrom!,
        taxId: taxId,
        toDate: values.serviceTo!,
      };

      const dupResponse = await PaymentsApi.checkForDuplicatePayments(
        request
      ).catch((err) => {
        console.log(err);
      });
      if (dupResponse && dupResponse.data.duplicateExists) {
        let savedResolve:
          | ((value: boolean | PromiseLike<boolean>) => void)
          | null = null;
        const valueReturned = new Promise<boolean>((resolve, reject) => {
          savedResolve = resolve;
        });
        const { enteredBy, txEnterDate } = dupResponse.data;

        const alert = (
          <Alert
            variant='danger'
            className='mt-3'
            dismissible
            onClose={() => {
              savedResolve && savedResolve(false);
              setShowDuplicateWarning(null);
            }}
          >
            <p>{`Possible duplicate with transaction`}</p>
            <p>{`entered on ${displayDateOnly(
              txEnterDate ?? ''
            )} by ${enteredBy}`}</p>
            <p>Would you like to Proceed?</p>
            <div className='d-flex justify-content-around'>
              <Button
                type='button'
                onClick={() => {
                  savedResolve && savedResolve(true);
                  setShowDuplicateWarning(null);
                }}
                variant='danger'
              >
                Yes
              </Button>
            </div>
          </Alert>
        );
        setShowDuplicateWarning(alert);

        const res = await valueReturned;
        if (!res) {
          stopAllSubmitting();
          return;
        }
      }
    }

    let totalPaid =
      (claimPaymentTotals?.medical ?? 0) +
      (claimPaymentTotals?.legal ?? 0) +
      (claimPaymentTotals?.indemnity ?? 0) +
      (claimPaymentTotals?.expense ?? 0);
    if (+values.amount! < 0) {
      if (+values.amount! + totalPaid < 0) {
        const alert = (
          <Alert
            variant='danger'
            className='mt-3'
            dismissible
            onClose={() => setShowRefundGreaterWarning(null)}
          >
            <p>
              Amount of refund/reimbursement is greater than the amount of
              payments.
            </p>
            <p>{`Total Paid: ${accounting.formatMoney(totalPaid)}`}</p>
            <p>{`Medical Paid: ${accounting.formatMoney(
              claimPaymentTotals?.medical ?? 0
            )}`}</p>
            <p>{`Indemnity Paid: ${accounting.formatMoney(
              claimPaymentTotals?.indemnity ?? 0
            )}`}</p>
            <p>{`Expense Paid: ${accounting.formatMoney(
              claimPaymentTotals?.expense ?? 0
            )}`}</p>
            <p>{`Legal Paid: ${accounting.formatMoney(
              claimPaymentTotals?.legal ?? 0
            )}`}</p>
          </Alert>
        );
        setShowRefundGreaterWarning(alert);
        stopAllSubmitting();
        return false;
      } else {
        let amountOk: boolean = true;
        switch (selectedPayCode?.fBucket) {
          case '1':
            if (+values.amount! + (claimPaymentTotals?.medical ?? 0) < 0) {
              amountOk = false;
            }
            break;
          case '2':
            if (+values.amount! + (claimPaymentTotals?.indemnity ?? 0) < 0) {
              amountOk = false;
            }
            break;
          case '3':
            if (+values.amount! + (claimPaymentTotals?.expense ?? 0) < 0) {
              amountOk = false;
            }
            break;
          case '4':
            if (+values.amount! + (claimPaymentTotals?.legal ?? 0) < 0) {
              amountOk = false;
            }
            break;
        }

        if (!amountOk) {
          const alert = (
            <Alert
              variant='danger'
              className='mt-3'
              dismissible
              onClose={() => setShowRecoveryIsMoreThanBucket(null)}
            >
              <p>
                The amount of the recovery is more than the amount of payments
                for the financial bucket,
              </p>
              <p>
                however the total amount may be able to be split between other
                buckets.
              </p>
              <p>{`Medical Paid: ${accounting.formatMoney(
                claimPaymentTotals?.medical ?? 0
              )}`}</p>
              <p>{`Indemnity Paid: ${accounting.formatMoney(
                claimPaymentTotals?.indemnity ?? 0
              )}`}</p>
              <p>{`Expense Paid: ${accounting.formatMoney(
                claimPaymentTotals?.expense ?? 0
              )}`}</p>
              <p>{`Legal Paid: ${accounting.formatMoney(
                claimPaymentTotals?.legal ?? 0
              )}`}</p>
            </Alert>
          );
          setShowRecoveryIsMoreThanBucket(alert);
          stopAllSubmitting();
          return false;
        }
      }
    }

    if (
      +values?.amount! > reserveAmt &&
      selectedPayCode?.txType === 'PAYMENT'
    ) {
      let savedResolve:
        | ((value: boolean | PromiseLike<boolean>) => void)
        | null = null;
      const valueReturned = new Promise<boolean>((resolve, reject) => {
        savedResolve = resolve;
      });

      const alert = (
        <Alert
          variant='danger'
          className='mt-3'
          dismissible
          onClose={() => {
            savedResolve && savedResolve(false);
            setShowAdminAmountGreaterThanReserves(null);
          }}
        >
          <p>{`The amount of the transaction ${accounting.formatMoney(
            values.amount ?? 0
          )} is greater than the reserve amount ${accounting.formatMoney(
            reserveAmt
          )}`}</p>
          <p>Please increase reserves before entering additional payments.</p>
          <p>Would you like to Proceed?</p>
          <div className='d-flex justify-content-around'>
            <Button
              type='button'
              onClick={() => {
                savedResolve && savedResolve(true);
                setShowAdminAmountGreaterThanReserves(null);
              }}
              variant='danger'
            >
              Yes
            </Button>
          </div>
        </Alert>
      );

      setShowAdminAmountGreaterThanReserves(alert);

      const res = await valueReturned;
      if (!res) {
        stopAllSubmitting();
        return;
      }
    }
    const today = format(new Date(), 'yyyy-MM-dd');

    const status =
      selectedPayCode?.txType === 'PAYMENT'
        ? 'N'
        : selectedPayCode?.txType === 'RECOVERY' ||
          selectedPayCode?.txType === 'REIMBURSEMENT'
        ? 'R'
        : 'N';
    let transaction: Transaction = {
      txId: 0,
      claimNo: claim.claimNo,
      txDate: today,
      txEnterDate: today,
      payee: values.payee,
      providerId:
        values.payee.toUpperCase() === 'P'
          ? provider?.providerId ?? 0
          : claimant.claimantId,
      serviceFrom: values.serviceFrom,
      serviceTo: values.serviceTo,
      payCode: values.payCode,
      checkId: null,
      amount: values.amount ?? 0,
      checkNumber: values.checkNumber,
      checkDate: values.checkDate,
      memo: values.memo,
      fbucket: selectedPayCode?.fBucket ?? '',
      paymentNumber: null,
      status: status,
      bankId: null,
      pmtApproved: null,
      pmtApprovedBy: null,
      pmtBatchId: null,
      source: null,
      importId: null,
      documentControlNumber: null,
      enteredBy: userName,
      alteredBy: null,
      docId: null,
      approvalDate: null,
      managerApproved: null,
      managerApprovedBy: null,
      managerApprovedDate: null,
      requireSecondaryApproval: null,
      claimSubrogationId: values.claimSubrogationId,
    };
    return saveTransaction(transaction, values);
  };

  const saveTransaction = (transaction: Transaction, values: formType) => {
    return PaymentsApi.SavePayment(transaction)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          return handleCleanup(values);
        } else {
          toast.error(res.data.message ?? 'Failed to save transaction');
          stopAllSubmitting();
        }
      })
      .catch((err) => {
        console.log(err);
        stopAllSubmitting();
        toast.error('Failed to save transaction');
      });
  };

  const handleCleanup = (values: formType) => {
    if (values.keepNone) {
      reset();
      resetClaim();
      resetProvider();
      stopAllSubmitting();
    }
    if (values.keepClaim) {
      reset();
      resetProvider();
      stopAllSubmitting();
    }
    if (values.keepProvider) {
      reset();
      resetClaim();
      stopAllSubmitting();
    }
    formInstance.restart();
    handleReimbursementType();
  };

  const reset = () => {
    setShowAdminAmountGreaterThanReserves(null);
    setShowDuplicateWarning(null);
    setShowRefundGreaterWarning(null);
  };

  const resetClaim = () => {
    setClaim(null);
    setClaimant(null);
    setClaimReserveBuckets([]);
    setClaimPaymentTotals(null);
    setClaimantAddress('');
    setFBucketText('');
  };

  const resetProvider = () => {
    setProvider(null);
    setProviderAddress('');
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaMoneyCheck className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Payment</h1>
          </div>
        </div>
      </div>
      <RFFForm
        onSubmit={onSubmit}
        initialValues={{
          amount: 0,
          keepClaim: false,
          keepProvider: false,
          keepNone: false,
        }}
        validate={(values) => {
          const errors: {
            [Property in keyof formType]?: string;
          } = {};

          if (!values?.payee) {
            errors.payee = 'Required';
          }
          return errors;
        }}
        render={({ handleSubmit, form, values, submitting }) => {
          const payeeFieldState = form.getFieldState(
            'payee' as keyof formType
          )!;
          formInstance = form;
          return (
            <Form onSubmit={handleSubmit}>
              <div className={`${styles.grid3}`}>
                <fieldset className={`py-0`}>
                  <legend className='col-form-label col-sm-2 py-0'>
                    Payee
                  </legend>
                  <div
                    className='d-flex align-items-center gap1Rem'
                    style={
                      payeeFieldState?.submitFailed && payeeFieldState?.invalid
                        ? {
                            border: '1px solid red',
                            borderRadius: '.25rem',
                          }
                        : {}
                    }
                  >
                    <Field
                      name='payee'
                      type='radio'
                      label='Claimant'
                      value='C'
                      checked={values?.payee?.toUpperCase() === 'C'}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='payee'
                      type='radio'
                      label='Provider'
                      value='P'
                      checked={values?.payee?.toUpperCase() === 'P'}
                      component={FieldBSRenderCheckbox}
                    />
                  </div>
                </fieldset>
                {showCheckNumber ? (
                  <Field
                    name='checkNumber'
                    type='text'
                    label='Check Number'
                    component={FieldBSRenderText}
                  />
                ) : (
                  <div
                    className={`${styles.height80px} d-flex align-items-center`}
                  >
                    <p>Check # - assigned after printing</p>
                  </div>
                )}
                {showCheckDate ? (
                  <Field
                    name='checkDate'
                    label='Check Date'
                    parse={parseDatesForServer}
                    component={FieldBSRenderDate}
                  />
                ) : (
                  <div
                    className={`${styles.height80px} d-flex align-items-center`}
                  >
                    <p>Check Date - assigned after printing</p>
                  </div>
                )}
              </div>
              <div className={`${styles.grid3}`}>
                <div className={`position-relative ${styles.vFieldHeight}`}>
                  <label htmlFor='claimNo' className='form-label fs-6  m-0'>
                    Claim Number
                  </label>
                  <input
                    type='text'
                    name='claimNo'
                    value={claim ? claim.claimNo : ''}
                    className='form-control form-control-sm'
                    placeholder='Claim Number'
                    onClick={() => {
                      setShowClaimSearch(true);
                    }}
                    readOnly
                  />
                </div>

                <div className={`position-relative ${styles.vFieldHeight}`}>
                  <label
                    htmlFor='claimantName'
                    className='form-label fs-6  m-0'
                  >
                    Claimant
                  </label>
                  <input
                    type='text'
                    name='claimantName'
                    value={
                      claimant
                        ? `${claimant?.firstName ?? ''} ${
                            claimant?.lastName ?? ''
                          }`
                        : ''
                    }
                    className='form-control form-control-sm'
                    placeholder='Claimant'
                    readOnly
                  />
                </div>

                <div className={`position-relative ${styles.vFieldHeight}`}>
                  <label
                    htmlFor='checkPayableTo'
                    className='form-label fs-6  m-0'
                  >
                    Payee Info
                  </label>
                  <input
                    type='text'
                    name='checkPayableTo'
                    value={
                      values.payee?.toUpperCase() === 'C'
                        ? `${claimant?.firstName ?? ''} ${
                            claimant?.lastName ?? ''
                          }`
                        : values.payee?.toUpperCase() === 'P'
                        ? provider?.companyName ?? ''
                        : ''
                    }
                    className='form-control form-control-sm'
                    placeholder='Payee Info'
                    onClick={() => {
                      if (values?.payee?.toUpperCase() === 'P') {
                        setShowProviderSearch(true);
                      }
                    }}
                    readOnly
                    disabled={values?.payee?.toUpperCase() === 'C'}
                  />
                </div>
              </div>
              <div className={`${styles.grid4}`}>
                <Field
                  name='payCode'
                  label='Pay Code'
                  options={payCodes}
                  onChange={handlePayCodeChange}
                  optionMethod={(options: PayCodeItem[]) =>
                    options.map((o) => (
                      <option key={o.payCode} value={o.payCode}>
                        {o.description}
                      </option>
                    ))
                  }
                  validate={requiredField}
                  disabled={!payCodeEnabled}
                  component={FieldBSRenderSelect}
                />
                <Field
                  name='amount'
                  label='Amount'
                  type='text'
                  format={formatNumbers}
                  parse={cleanMoney}
                  validate={requiredField}
                  component={FieldBSRenderMoney}
                />
                <Field
                  name='serviceFrom'
                  label='Service From'
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />
                <Field
                  name='serviceTo'
                  label='Service To'
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />
              </div>
              <div className={`${styles.grid3}`}>
                <div className={styles.height160px}>
                  <div className={`position-relative mb-3`}>
                    <label
                      htmlFor='mailingAddress'
                      className='form-label fs-6  m-0'
                    >
                      Mailing Address
                    </label>
                    <div>
                      <textarea
                        name='mailingAddress'
                        rows={5}
                        value={
                          values?.payee?.toUpperCase() === 'C'
                            ? claimantAddress
                            : values?.payee?.toUpperCase() === 'P'
                            ? providerAddress
                            : ''
                        }
                        className='form-control form-control-sm'
                        placeholder='Mailing Address'
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <Field
                  name='memo'
                  type='text'
                  label='Memo'
                  component={FieldBSRenderText}
                />
                <div
                  className={` d-flex align-items-center justify-content-center`}
                >
                  <p>{fBucketText}</p>
                </div>
              </div>
              {showAdminAmountGreaterThanReserves !== null &&
                showAdminAmountGreaterThanReserves}
              {showDuplicateWarning !== null && showDuplicateWarning}
              {showRefundGreaterWarning !== null && showRefundGreaterWarning}
              {showRecoveryIsMoreThanBucket !== null &&
                showRecoveryIsMoreThanBucket}
              <div className={`${styles.buttonDiv}`}>
                <Button
                  type='button'
                  size='sm'
                  variant='outline-primary'
                  onClick={() => {
                    // setSubmittingKeepProvider(true);
                    form.change('keepProvider', true);
                    form.submit();
                  }}
                  disabled={submitting}
                >
                  {values.keepProvider && submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Save and keep Provider'
                  )}
                </Button>
                <Button
                  type='button'
                  onClick={() => {
                    // setSubmittingKeepClaim(true);
                    form.change('keepClaim', true);
                    form.submit();
                  }}
                  size='sm'
                  variant='outline-primary'
                  disabled={submitting}
                >
                  {values.keepClaim && submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Save and keep Claimant'
                  )}
                </Button>
                <Button
                  type='button'
                  size='sm'
                  variant='outline-primary'
                  onClick={() => {
                    // setSubmittingKeepNone(true);
                    form.change('keepNone', true);
                    form.submit();
                  }}
                  disabled={submitting}
                >
                  {values.keepNone && submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Save and Clear'
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      />
      <ClaimSearch
        show={showClaimSearch}
        setShow={setShowClaimSearch}
        handleSelection={handleSelectedClaim}
      />
      <ProviderLookupModal
        show={showProviderSearch}
        setShow={setShowProviderSearch}
        selectedPayment={null}
        disabled={false}
        selectProvider={handleProviderSelect}
      />
    </PageScaffold>
  );
}
