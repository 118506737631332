import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { FaFileExcel, FaTrash } from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import EdiTransactionApi from '../../../Api/EdiTransactionApi';
import ExportApi from '../../../Api/ExportApi';
import { EdiTransaction } from '../../../ApiTypes/EdiTransaction';
import { useAppDispatch, useAppSelector } from '../../../Reducers/Store';
import { downloadExcel } from '../../../Utils';
import DeathDependentsModal from '../../DeathDependents/DeathDependentsModal';
import NewEdiModal from '../../EdiForms/NewEdiModal';
import EdiTransactionTable from './EdiTransactionTable';
import { exportHandler } from './ExportHandler';
import queryString from 'query-string';
import styles from './index.module.css';
import ClaimApi from '../../../Api/ClaimApi';
import { fetchFullClaimByClaimNumber } from '../../../Actions/ClaimActions';
import { Claim } from '../../../ApiTypes/Claim';
import DeleteConfirmationModal from '../../Common/DeleteConfirmationModal';

export default function SbwcTab({ claimType }: { claimType: number }) {
  let { claimNumber } = useParams();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const { final } = queryString.parse(location.search);

  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<EdiTransaction[]>([]);
  const [ipAlreadySent, setIpAlreadySent] = useState<boolean>(false);
  const [epAlreadySent, setEpAlreadySent] = useState<boolean>(false);
  const [showNewEdiModal, setShowNewEdiModal] = useState<boolean>(false);
  const [showDeathDependents, setShowDeathDependents] =
    useState<boolean>(false);
  const [ediClaimNo, setEdiClaimNo] = useState<string>('');

  const { claim, insuranceCompany, employer } = useAppSelector(
    (state) => state.currentClaimReducer
  );
  const { policies } = useAppSelector((state) => state.employer);

  const getTransactions = useCallback(() => {
    if (claimNumber) {
      EdiTransactionApi.getEdiTransactionsForClaim(claimNumber)
        .then((res) => {
          setTransactions(res.data);
          setIpEp(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [claimNumber]);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  useEffect(() => {
    setEdiClaimNoFromClaim();
  }, [claim]);

  const setEdiClaimNoFromClaim = () => {
    if (claim) {
      setEdiClaimNo(claim.ediClaimNo ?? '');
    }
  };

  const updateSbwcClaimNumber = () => {
    if (claim) {
      if (!ediClaimNo) {
        toast.error('Please enter a claim number. ');
        return;
      }
      const values: Claim = { ...claim, ediClaimNo };
      return ClaimApi.updateClaim(values)
        .then((res) => {
          if (res.data.success) {
            toast.success('Claim Saved!', { autoClose: 1000 });
            if (claimNumber) {
              dispatch(fetchFullClaimByClaimNumber(claimNumber!));
            }
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const deleteSBWCClaimNumber = () => {
    if (claim) {
      const values: Claim = { ...claim, ediClaimNo: '' };
      return ClaimApi.updateClaim(values)
        .then((res) => {
          if (res.data.success) {
            toast.success('Claim Saved!', { autoClose: 1000 });
            setShowDelete(false);
            if (claimNumber) {
              dispatch(fetchFullClaimByClaimNumber(claimNumber!));
            }
          } else {
            setShowDelete(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setShowDelete(false);
    return Promise.resolve();
  };

  const setIpEp = (transactions: EdiTransaction[]) => {
    if (transactions.length > 0) {
      const ip = transactions.filter((t) => t.maintTypeCode === 'IP');
      const ep = transactions.filter((t) => t.maintTypeCode === 'EP');

      setIpAlreadySent(ip.length > 0);
      setEpAlreadySent(ep.length > 0);

      if (final) {
        canEdiBeSent();
        navigate(location.pathname);
      }
    }
  };

  const handleNewEdi = () => {
    setShowNewEdiModal(true);
  };

  const handleCloseNewEdiModal = () => {
    setShowNewEdiModal(false);
  };

  const canEdiBeSent = () => {
    const employerPolicy = policies.find((p) => p.policyId === claim?.policyId);
    if (insuranceCompany?.accountnumber === '066') {
      if (insuranceCompany.inscotaxid === employer?.taxid) {
        return toast.error(
          'For this EDI Transaction we MUST have a valid TAX ID for the insurance company that does not match the Employer TAX ID.  Please update the information and try again.'
        );
      }
    }
    if (!employerPolicy?.startDate) {
      return toast.error(
        "There seems to be a error with the Start Date for this Claim's Policy.  Please verify it has a valid Start Date and try again"
      );
    }
    if (!employerPolicy?.endDate) {
      return toast.error(
        "There seems to be a error with the End Date for this Claim's Policy.  Please verify it has a valid End Date and try again"
      );
    }
    if (!claim?.injuryDate) {
      return toast.error(
        "There seems to be a error with the Injury Date for this Claim's Policy.  Please verify it has a valid Date of Injury and try again"
      );
    }

    handleNewEdi();
  };

  const exportToExcel = (data: object) => {
    return ExportApi.exportToExcel(data)
      .then((res) => {
        downloadExcel(res.data.fileName, res.data.file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePaperFileChange = () => {
    if (claim === null) return;
    const updated = { ...claim };
    if (updated?.isEdiPaperFile) {
      updated.isEdiPaperFile = false;
    } else {
      updated.isEdiPaperFile = true;
    }

    ClaimApi.updateClaim(updated)
      .then(() => {
        dispatch(fetchFullClaimByClaimNumber(claimNumber ?? ''));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className='w-100 h-100'>
      <div
        className={`${styles.topRow} mb-3 ${
          claim?.isEdiPaperFile === true ? 'text-bg-warning py-3' : ''
        }`}
      >
        <div className={`position-relative ${styles.vFieldHeight}`}>
          <label htmlFor='ediClaimNo' className='form-label fs-6  m-0'>
            SBWC Claim #
          </label>
          <input
            id='ediClaimNo'
            className='form-control form-control-sm'
            placeholder='No Claim Number Assigned'
            value={ediClaimNo}
            onChange={(e) => {
              setEdiClaimNo(e.target.value);
            }}
            disabled={claim?.ediClaimNo !== null && claim?.ediClaimNo !== ''}
            // readOnly={readOnly}
          />
          {/* {touched &&
            ((error && (
              <span className={`${styles.fieldError} text-danger`}>
                {error}
              </span>
            )) ||
              (warning && (
                <span className={`${styles.fieldWarning} text-warning`}>
                  {warning}
                </span>
              )))} */}
        </div>
        {/* <p className='mb-0'>
          SBWC Claim #{' '}
          {claim?.ediClaimNo
            ? claim.ediClaimNo
            : 'No Claim Number Assigned Yet'}
        </p> */}
        <Button
          type='button'
          variant='danger'
          size='sm'
          onClick={() => {
            setShowDelete(true);
          }}
          disabled={!claim?.ediClaimNo}
          title='Delete SBWC Claim Number'
        >
          <FaTrash />
        </Button>
        <Button
          type='button'
          variant='primary'
          size='sm'
          onClick={() => {
            updateSbwcClaimNumber();
          }}
          disabled={claim?.ediClaimNo !== null && claim?.ediClaimNo !== ''}
          title='Submit SBWC Claim Number'
        >
          Submit
        </Button>
        <div className='form-check'>
          <label className='form-check-label'>
            <input
              className='form-check-input'
              type='checkbox'
              id='isEdiPaperFile'
              checked={!!claim?.isEdiPaperFile}
              onClick={() => {
                handlePaperFileChange();
              }}
            />
            is EDI Paper File
          </label>
        </div>
        <Button
          type='button'
          variant={claim?.isEdiPaperFile === true ? 'secondary' : 'primary'}
          size='sm'
          onClick={() => {
            setShowDeathDependents(true);
          }}
          disabled={claim?.isEdiPaperFile === true || !claim?.dateOfDeath}
        >
          Death Dependents
        </Button>
        <Button
          type='button'
          variant={claim?.isEdiPaperFile === true ? 'secondary' : 'primary'}
          size='sm'
          onClick={() => canEdiBeSent()}
          disabled={claim?.isEdiPaperFile === true}
        >
          New EDI Transaction
        </Button>
        <Button
          type='button'
          variant={claim?.isEdiPaperFile === true ? 'secondary' : 'primary'}
          size='sm'
          title='Export to Excel'
          className='button-icon-text'
          onClick={() => {
            const data = exportHandler(transactions);
            data.fileName = `${claimNumber}-EDILog.xls`;
            exportToExcel(data);
          }}
        >
          <FaFileExcel /> Export
        </Button>
      </div>
      <EdiTransactionTable
        transactions={transactions}
        getTransactions={getTransactions}
      />
      <NewEdiModal
        show={showNewEdiModal}
        handleCloseModal={handleCloseNewEdiModal}
        ipAlreadySent={ipAlreadySent}
        epAlreadySent={epAlreadySent}
        getTransactions={getTransactions}
      />
      <DeathDependentsModal
        show={showDeathDependents}
        setShow={setShowDeathDependents}
      />
      <DeleteConfirmationModal
        show={showDelete}
        setShow={setShowDelete}
        handleDelete={deleteSBWCClaimNumber}
        setNull={() => null}
      />
    </div>
  );
}
