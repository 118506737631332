import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ClassCodeItem } from '../ApiTypes/ClassCode';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class ClassCodeApi {
  static getForPolicy(policyId: number, unassigned: boolean) {
    return axios.get<ClassCodeItem[]>(
      `${API_URL}/api/classcodes?policyId=${policyId}&unassigned=${unassigned}`,
      getAuthHeader()
    );
  }
  static getClassCodes() {
    return axios.get<ClassCodeItem[]>(
      `${API_URL}/api/classcodes/list`,
      getAuthHeader()
    );
  }
  static update(classCode: ClassCodeItem) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/classcodes/${classCode.classCodeId}`,
      classCode,
      getAuthHeader()
    );
  }
}

export default ClassCodeApi;
