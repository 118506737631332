import React from 'react';
import { useAppSelector } from '../../Reducers/Store';
import { Form, Spinner, Button } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';

import styles from './index.module.css';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { EdiMaintTypeCode } from '../../ApiTypes/EdiMaintTypeCode';
import { EdiBenefitTypeCode } from '../../ApiTypes/EdiBenefitTypeCode';
import { requiredField } from '../../Utils/FieldValidation';
import { NewEdiFormValues } from './NewEdiFormValues';

export default function NewEdiForm({
  closeModal,
  ipAlreadySent,
  epAlreadySent,
  onFormSubmit,
}: {
  closeModal: () => void;
  ipAlreadySent: boolean;
  epAlreadySent: boolean;
  onFormSubmit: (values: NewEdiFormValues) => void;
}) {
  const { ediBenefitTypeCodes, FROIEdiMaintTypeCodes, SROIEdiMaintTypeCodes } =
    useAppSelector((state) => state.reference);

  const { claim } = useAppSelector((state) => state.currentClaimReducer);

  const getBenefitCodes = (values: NewEdiFormValues) => {
    let maintTypeCode = values?.maintTypeCode;

    if (values.formType === 'SROI') {
      if (maintTypeCode === 'EP' || maintTypeCode === 'ER') {
        return ediBenefitTypeCodes.filter(
          (t) => t.code === '250' || t.code === '070'
        );
      }
      if (maintTypeCode === 'IP') {
        return ediBenefitTypeCodes.filter((t) => t.code !== '250');
      }
    }

    return ediBenefitTypeCodes;
  };

  return (
    <div>
      <h2>New EDI Submission</h2>
      <RFFForm
        onSubmit={onFormSubmit}
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className={`${styles.edi3Grid}`}>
                <Field
                  name='formType'
                  label='Form to Submit'
                  options={['FROI', 'SROI']}
                  validate={requiredField}
                  component={FieldBSRenderSelect}
                />

                <Field
                  name='maintTypeCode'
                  label='Maint Code'
                  options={
                    values.formType === 'SROI'
                      ? SROIEdiMaintTypeCodes
                      : FROIEdiMaintTypeCodes
                  }
                  optionMethod={(options: EdiMaintTypeCode[]) =>
                    options.map((o) => (
                      <option key={o.code} value={o.code}>
                        {o.shortdesc}
                      </option>
                    ))
                  }
                  validate={requiredField}
                  component={FieldBSRenderSelect}
                />
                <Field
                  name='benefitTypeCode'
                  label='Benefit Type Code'
                  options={getBenefitCodes(values)}
                  optionMethod={(options: EdiBenefitTypeCode[]) =>
                    options.map((o) => (
                      <option key={o.code} value={o.code!}>
                        {o.shortdesc}
                      </option>
                    ))
                  }
                  disabled={
                    values.formType !== 'SROI' ||
                    values.maintTypeCode === 'AN' ||
                    values.maintTypeCode === 'FN' ||
                    claim?.severity === 'MO' ||
                    claim?.severity === 'AM'
                  }
                  component={FieldBSRenderSelect}
                />
              </div>
              <div className='d-flex justify-content-center gap1Rem'>
                <Button type='submit' size='sm' variant='primary'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button
                  type='button'
                  size='sm'
                  variant='secondary'
                  onClick={() => {
                    form.reset();
                    closeModal();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
}
