import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import styles from './index.module.css';
import { VwDocTurnaroundGrouped } from '../../ApiTypes/VwDocTurnaroundGrouped';

export default function TurnAroundChart({
  data,
}: {
  data: VwDocTurnaroundGrouped[];
}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    BarElement
    // ChartDataLabels
  );

  const getOptions = (): any => ({
    interaction: {
      mode: 'nearest',
      intersect: false,
    },
    maintainAspectRatio: false,

    stacked: false,
    plugins: {
      // datalabels: {
      //   color: '#8E999F',
      //   anchor: 'end',
      //   align: 'start',

      //   font: {
      //     weight: 'bold',
      //     size: 20,
      //   },
      // },
      tooltip: {
        callbacks: {},
      },
    },

    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        ticks: {
          beginAtZero: false,
        },
      },
    },
  });

  const getData = (data: VwDocTurnaroundGrouped[]): any => {
    return {
      labels: data.map((x) => `${x.name} to ${x.newName}`),
      datasets: [
        {
          label: 'Avg Hours',
          data: data.map((d) => d.avgHours),
          fill: false,
          type: 'bar',
          backgroundColor: '#0DCAF0',
          borderColor: '#3f95ff',
        },
      ],
    };
  };

  return (
    <div className='mb-3'>
      <div className={`${styles.chartArea}`}>
        {data ? (
          <Bar data={getData(data)} options={getOptions()} />
        ) : (
          'No Available Data'
        )}
      </div>
    </div>
  );
}
