import accounting from "accounting";
import { ClaimReserveBuckets } from "../../../ApiTypes/ClaimReserveBuckets";

export const reservesExportHandler = (reserves: ClaimReserveBuckets[]) => {
  const columns = [
    "Classification", "Paid", "Reserves", "Original Reserves", "Total Incurred"
  ]

  const rows: any[][] = [];

   reserves.forEach(t => {
    const tRow =  [
     t.description,
     accounting.formatMoney(t.paid),
     accounting.formatMoney(t.reserves),
     accounting.formatMoney(t.originalReserves),
     accounting.formatMoney(t.totalIncurred),
    ]

    rows.push(tRow)
  })

  return {
    columns,
    rows,
    fileName: "",
  }
}


