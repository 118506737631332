import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchLawFirms } from '../../../Actions/ReferenceActions';
import LegalApi from '../../../Api/LegalApi';
import { Attorney } from '../../../ApiTypes/Attorney';
import { AttorneyToClaimant } from '../../../ApiTypes/AttorneyToClaimant';
import { LawFirm } from '../../../ApiTypes/LawFirm';
import { useAppDispatch } from '../../../Reducers/Store';
import { AssignAttorneyToClaimantFormType } from '../../../Types/AssignAttorneyToClaimantFormType';
import AssignAttorneyToClaimModal from '../../AssignAttorneyToClaim/AssignAttorneyToClaimModal';
import AttorneyAddEditModal from '../../AttorneyAddEdit/AttorneyAddEditModal';
import LawFirmAddEditModal from '../../LawFirmAddEdit/LawFirmAddEditModal';
import AttorneysTable from './AttorneysTable';
import DeleteConfirmationModal from './DeleteConfirmationModal';

export default function AttorneysTab() {
  let { claimNumber } = useParams();
  const dispatch = useAppDispatch();
  const [attorneys, setAttorneys] = useState<AttorneyToClaimant[]>([]);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [recordToDelete, setRecordToDelete] =
    useState<AttorneyToClaimant | null>(null);
  const [showAttorneyAddEdit, setShowAttorneyAddEdit] =
    useState<boolean>(false);
  const [attorneyToEdit, setAttorneyToEdit] = useState<Attorney | null>(null);
  const [attorneyToResume, setAttorneyToResume] = useState<Attorney | null>(
    null
  );

  const [showLawFirmAddEdit, setShowLawFirmAddEdit] = useState<boolean>(false);
  const [lawFirmToEdit, setLawFirmToEdit] = useState<LawFirm | null>(null);

  const [showAssignAttorneyToClaim, setShowAssignAttorneyToClaim] =
    useState<boolean>(false);
  const [assignTo, setAssignTo] =
    useState<AssignAttorneyToClaimantFormType | null>(null);
  const [returnToAssign, setReturnToAssign] = useState<boolean>(false);

  useEffect(() => {
    getAttorneys();
  }, [claimNumber]);

  const getAttorneys = () => {
    if (claimNumber) {
      LegalApi.getAttorneysForClaim(claimNumber)
        .then((res) => {
          setAttorneys(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setAttorneys([]);
    }
  };

  const handleDeleteRecord = () => {
    if (recordToDelete && claimNumber) {
      return LegalApi.deleteAttorneyToClaimantRecord(
        recordToDelete.attorneyToClaimantId,
        claimNumber
      )
        .then((res) => {
          if (res.data.success) {
            getAttorneys();
            setRecordToDelete(null);
            setShowDelete(false);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to delete record');
        });
    }
    return Promise.resolve();
  };

  const handleNewLawFirmFromAssign = () => {
    setAttorneyToResume(null);
    setAttorneyToEdit(null);
    setShowAttorneyAddEdit(false);
    setShowAssignAttorneyToClaim(false);
    setLawFirmToEdit(null);
    setShowLawFirmAddEdit(true);
  };

  const handleNewFirmFromAttorneyAddEdit = () => {
    setAttorneyToResume({ ...attorneyToEdit } as Attorney);
    setShowAttorneyAddEdit(false);
    setShowLawFirmAddEdit(true);
  };

  const handleNewLawFirmFromAssignSubmit = (firm: LawFirm) => {
    if (firm.firmId) {
      return LegalApi.updateLawFirm(firm)
        .then((res) => {
          if (res.data.success) {
            dispatch(fetchLawFirms());
            setShowLawFirmAddEdit(false);
            const assignTO: AssignAttorneyToClaimantFormType = {
              firmId: +res.data.affectedEntityIdentifier,
              attorneyId: 0,
              typeId: 0,
              attorneyToClaimantId: 0,
              claimNo: claimNumber ?? '',
              isTerminated: null,
              dateTerminated: null,
              isLein: null,
              lienAmount: null,
              dateHired: null,
              attorney: null,
              type: null,
            };
            setAssignTo(assignTO);
            setShowAssignAttorneyToClaim(true);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to update law firm');
        });
    }

    return LegalApi.createLawFirm(firm)
      .then((res) => {
        if (res.data.success) {
          dispatch(fetchLawFirms());
          setShowLawFirmAddEdit(false);
          const assignTO: AssignAttorneyToClaimantFormType = {
            firmId: +res.data.affectedEntityIdentifier,
            attorneyId: 0,
            typeId: 0,
            attorneyToClaimantId: 0,
            claimNo: claimNumber ?? '',
            isTerminated: null,
            dateTerminated: null,
            isLein: null,
            lienAmount: null,
            dateHired: null,
            attorney: null,
            type: null,
          };
          setAssignTo(assignTO);
          setShowAssignAttorneyToClaim(true);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update law firm');
      });
  };

  const handleLawFirmAddEdit = (firm: LawFirm) => {
    // redirect to creating a new firm from assign
    if (!attorneyToResume) {
      return handleNewLawFirmFromAssignSubmit(firm);
    }

    if (firm.firmId) {
      return LegalApi.updateLawFirm(firm)
        .then((res) => {
          if (res.data.success) {
            dispatch(fetchLawFirms());
            let atty = {
              ...attorneyToResume,
              firmId: +res.data.affectedEntityIdentifier,
              firm: null,
            };
            setAttorneyToEdit(atty as Attorney);
            setShowLawFirmAddEdit(false);
            setShowAttorneyAddEdit(true);
            setAttorneyToResume(null);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to update law firm');
        });
    }

    return LegalApi.createLawFirm(firm)
      .then((res) => {
        if (res.data.success) {
          dispatch(fetchLawFirms());
          let atty = {
            ...attorneyToResume,
            firmId: +res.data.affectedEntityIdentifier,
            firm: null,
          };
          setAttorneyToEdit(atty as Attorney);
          setShowLawFirmAddEdit(false);
          setShowAttorneyAddEdit(true);
          setAttorneyToResume(null);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update law firm');
      });
  };

  const handleAttorneyAddEdit = (values: Attorney) => {
    if (!values.firmId) {
      values.firm = null;
    }

    if (values.attorneyId) {
      return LegalApi.updateAttorney(values)
        .then((res) => {
          if (res.data.success) {
            setAttorneyToEdit(null);
            setShowAttorneyAddEdit(false);
            getAttorneys();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to update attorney');
        });
    }

    return LegalApi.createAttorney(values)
      .then((res) => {
        if (res.data.success) {
          if (returnToAssign) {
            const assignTO: AssignAttorneyToClaimantFormType = {
              firmId: values.firmId ?? 0,
              attorneyId: +res.data.affectedEntityIdentifier,
              typeId: 0,
              attorneyToClaimantId: 0,
              claimNo: claimNumber ?? '',
              isTerminated: null,
              dateTerminated: null,
              isLein: null,
              lienAmount: null,
              dateHired: null,
              attorney: null,
              type: null,
            };
            setAssignTo(assignTO);
            setAttorneyToEdit(null);
            setShowAttorneyAddEdit(false);
            getAttorneys();
            setReturnToAssign(false);
            setShowAssignAttorneyToClaim(true);
          } else {
            setAttorneyToEdit(null);
            setShowAttorneyAddEdit(false);
            getAttorneys();
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create attorney');
      });
  };

  const assignAttorneyToClaimant = (attorneyToClaimant: AttorneyToClaimant) => {
    if (attorneyToClaimant.attorneyToClaimantId > 0) {
      return LegalApi.updateAttorneyToClaimant(attorneyToClaimant)
        .then((res) => {
          if (res.data.success) {
            getAttorneys();
            setShowAssignAttorneyToClaim(false);
            setAssignTo(null);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to update assigned attorney to claimant');
        });
    }

    return LegalApi.assignAttorneyToClaimant(attorneyToClaimant)
      .then((res) => {
        if (res.data.success) {
          getAttorneys();
          setShowAssignAttorneyToClaim(false);
          setAssignTo(null);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to assign attorney to claimant');
      });
  };

  const handleNewAttorneyFromAssign = (firmId: number) => {
    let attorney: Attorney = {
      attorneyId: 0,
      firmId: firmId,
      firstName: null,
      lastName: null,
      streetAddress: null,
      streetAddress1: null,
      city: null,
      state: null,
      zip: null,
      phone: null,
      cellPhone: null,
      fax: null,
      emailAddress: null,
      internalNotes: null,
      firm: null,
    };
    setAttorneyToEdit(attorney);
    setReturnToAssign(true);
    setShowAssignAttorneyToClaim(false);
    setShowAttorneyAddEdit(true);
  };

  const handleAssignNewAttorneyToClaimant = () => {
    setShowAssignAttorneyToClaim(true);
    const assignTO: AssignAttorneyToClaimantFormType = {
      firmId: 0,
      attorneyId: 0,
      typeId: 0,
      attorneyToClaimantId: 0,
      claimNo: claimNumber ?? '',
      isTerminated: null,
      dateTerminated: null,
      isLein: null,
      lienAmount: null,
      dateHired: null,
      attorney: null,
      type: null,
    };
    setAssignTo(assignTO);
  };

  const handleEditViewAttorneyToClaimant = (ac: AttorneyToClaimant) => {
    setAssignTo({
      ...ac,
      firmId: ac.attorney?.firmId ?? 0,
    });
    setShowAssignAttorneyToClaim(true);
  };

  return (
    <div className='w-100 h-100'>
      <div className='d-flex justify-content-center mb-3'>
        <Button
          type='button'
          variant='primary'
          size='sm'
          onClick={() => handleAssignNewAttorneyToClaimant()}
          className='button-icon-text'
        >
          <FaPlusCircle /> Add New
        </Button>
      </div>
      <AttorneysTable
        attorneys={attorneys}
        getAttorneys={getAttorneys}
        setShowDelete={setShowDelete}
        setShowAttorney={setShowAttorneyAddEdit}
        setAttorneyToEdit={setAttorneyToEdit}
        handleEditViewAttorneyToClaimant={handleEditViewAttorneyToClaimant}
        setRecordToDelete={setRecordToDelete}
      />
      <DeleteConfirmationModal
        show={showDelete}
        setShow={setShowDelete}
        setNull={setRecordToDelete}
        handleDelete={handleDeleteRecord}
      />
      <AttorneyAddEditModal
        show={showAttorneyAddEdit}
        setShow={setShowAttorneyAddEdit}
        attorneyToEdit={attorneyToEdit}
        returnToAssign={returnToAssign}
        setAttorneyToEdit={setAttorneyToEdit}
        handleAttorneyAddEdit={handleAttorneyAddEdit}
        handleNewFirmFromAttorneyAddEdit={handleNewFirmFromAttorneyAddEdit}
      />
      <LawFirmAddEditModal
        show={showLawFirmAddEdit}
        setShow={setShowLawFirmAddEdit}
        lawFirmToEdit={lawFirmToEdit}
        setLawFirmToEdit={setLawFirmToEdit}
        handleLawFirmAddEdit={handleLawFirmAddEdit}
      />
      <AssignAttorneyToClaimModal
        show={showAssignAttorneyToClaim}
        setShow={setShowAssignAttorneyToClaim}
        assignTo={assignTo}
        handleNewLawFirmFromAssign={handleNewLawFirmFromAssign}
        handleNewAttorneyFromAssign={handleNewAttorneyFromAssign}
        assignAttorneyToClaimant={assignAttorneyToClaimant}
      />
    </div>
  );
}
