import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ClaimCmsinfoIcdcode } from '../ApiTypes/ClaimCmsinfoIcdcode';
import { ClaimCmsinfo } from '../ApiTypes/ClaimCmsinfo';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class ClaimCmsinfoApi {
  static GetClaimCmsinfoForClaim(claimNumber: string) {
    return axios.get<ClaimCmsinfo>(
      `${API_URL}/api/claims/${claimNumber}/cmsinfo`,
      getAuthHeader()
    );
  }
  static UpdateClaimCmsinfo(claimNumber: string, data: ClaimCmsinfo) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/cmsinfo`,
      data,
      getAuthHeader()
    );
  }

  static getClaimCmsinfoIcdCodes(claimNumber: string, claimCmsInfoId: number) {
    return axios.get<ClaimCmsinfoIcdcode[]>(
      `${API_URL}/api/claims/${claimNumber}/claimcmsinfoicdcode/${claimCmsInfoId}`,
      getAuthHeader()
    );
  }

  static updateClaimCmsinfoIcdCode(
    claimNumber: string,
    data: ClaimCmsinfoIcdcode
  ) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/claimcmsinfoicdcode`,
      data,
      getAuthHeader()
    );
  }

  static deleteClaimCmsinfoIcdCode(
    claimNumber: string,
    claimcmsinfoicdcodeid: number
  ) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/claimcmsinfoicdcode/${claimcmsinfoicdcodeid}`,
      getAuthHeader()
    );
  }
}

export default ClaimCmsinfoApi;
