import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaColumns } from 'react-icons/fa';
import { toast } from 'react-toastify';
import WcPanelApi from '../../Api/WcPanelApi';
import { EmployerPolicyPanelLocation } from '../../ApiTypes/EmployerPolicyPanelLocation';
import PageScaffold from '../PageScaffold/PageScaffold';
import WCPanelPolicyLink from '../WCPanelPolicyLink/WCPanelPolicyLink';
import ApprovalTable from './ApprovalTable';
import styles from './index.module.css';

export default function PanelApproval() {
  const [fetching, setFetching] = useState<boolean>(false);
  const [needApproved, setNeedApproved] = useState<
    EmployerPolicyPanelLocation[]
  >([]);

  const [showLink, setShowLink] = useState<boolean>(false);
  const [selectedApproval, setSelectedApproval] =
    useState<EmployerPolicyPanelLocation | null>(null);

  useEffect(() => {
    getApprovals();
  }, []);

  const getApprovals = () => {
    setFetching(true);
    WcPanelApi.getEmployerPolicyPanelLocationsToBeApproved()
      .then((res) => {
        setNeedApproved(res.data);
        setFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setFetching(false);
        toast.error('Failed to get locations needing to be approved');
      });
  };

  const handleView = (d: EmployerPolicyPanelLocation) => {
    setSelectedApproval(d);
    setShowLink(true);
  };
  const handleApprove = (d: EmployerPolicyPanelLocation) => {
    WcPanelApi.approveLocation(d.locationId!, d.employerPolicyPanelId!)
      .then((res) => {
        if (res.data.success) {
          getApprovals();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to approve location');
      });
  };
  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaColumns className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>WC Approval</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center py-3'>
        <Button
          type='button'
          variant='primary'
          size='sm'
          onClick={getApprovals}
        >
          {fetching ? (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            'Refresh'
          )}
        </Button>
      </div>
      <ApprovalTable
        data={needApproved}
        handleApprove={handleApprove}
        handleView={handleView}
      />
      <WCPanelPolicyLink
        show={showLink}
        setShow={setShowLink}
        wcPanelId={selectedApproval?.employerPolicyPanel?.panelId ?? undefined}
        employerPolicyPanel={selectedApproval?.employerPolicyPanel ?? null}
        afterSubmit={getApprovals}
      />
    </PageScaffold>
  );
}
