import React from 'react';
import { AddEditUserFormType } from './AddEditUserFormType';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import styles from './index.module.css';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import { Button, Form } from 'react-bootstrap';
import { FormApi } from 'final-form';
import { Form as RFForm, Field } from 'react-final-form';

export default function UserAccessForm({
  values,
  selectAll,
  isNew,
  activeInsuranceCompanies,
  form,
}: {
  form: FormApi<AddEditUserFormType, Partial<AddEditUserFormType>>;
  values: AddEditUserFormType;
  selectAll: () => void;
  isNew: boolean;
  activeInsuranceCompanies: InsuranceCompany[];
}) {
  return (
    <div className='w-100'>
      <div>
        <div className='d-flex justify-content-around align-items-center'>
          <Button
            type='button'
            variant='outline-primary'
            size='sm'
            onClick={selectAll}
          >
            Select All
          </Button>
        </div>
        <fieldset>
          <legend className='fw-bold fs-3'>User Access</legend>
          <div className={`${styles.grid3NoHeight}`}>
            {activeInsuranceCompanies.map((x) => {
              return (
                <Field
                  key={`${x.insurcoid}`}
                  name='insurCoIds'
                  type='checkbox'
                  label={`${x.fullname}`}
                  value={`${x.insurcoid}`}
                  checked={values.insurCoIds.includes(`${x.insurcoid}`)}
                  component={FieldBSRenderCheckbox}
                />
              );
            })}
          </div>
        </fieldset>
      </div>
    </div>
  );
}
