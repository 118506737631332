import { createReducer } from '@reduxjs/toolkit';

import { clearState } from '../Actions/index';
import { FileDownload } from '../Types/FileDownLoad';
import {
  setShowDocView,
  setDocViewFileDownload,
} from '../Actions/DocViewActions';

type DocViewReducerType = {
  show: boolean;
  fileDownload: FileDownload | null;
};

const initialState: DocViewReducerType = {
  show: false,
  fileDownload: null,
};

const DocViewReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setShowDocView, (state, action) => {
      return { ...state, show: action.payload };
    })
    .addCase(setDocViewFileDownload, (state, action) => {
      return { ...state, fileDownload: action.payload };
    })
    .addCase(clearState, (state, action) => {
      return initialState;
    });
});

export default DocViewReducer;
