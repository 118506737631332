import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import { FaRegCalendar } from "react-icons/fa"
import { add } from 'date-fns'

import { Button, Offcanvas } from 'react-bootstrap';


export default function OffCanvasCalendar() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const today = new Date();
  const nextMonth = add(today, { months: 1 })
  return (
    <div >
      <>
        <Button variant="outline-primary" onClick={handleShow}>
          <div className='button-icon-text'>
            <FaRegCalendar />
          </div>
        </Button>
        <div className='h-100 m-1 mt-2 bg-light rounded-top'>

        </div>

        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Calendar</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <DatePicker
                selected={today}
                onChange={() => true}
                startDate={today}
                selectsRange
                inline
              />
              <DatePicker
                selected={nextMonth}
                onChange={() => true}
                startDate={nextMonth}
                selectsRange
                inline
              />

            </div>

          </Offcanvas.Body>
        </Offcanvas>
      </>
    </div>
  )
}
