import ReferenceApi from '../Api/ReferenceApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleError } from '.';
import UserEmailListApi from '../Api/UserEmailListApi';
import NcmApi from '../Api/NcmApi';
import UserPriorityClaimApi from '../Api/UserPriorityClaimApi';
import PolicyClassCodeApi from '../Api/PolicyClassCodeApi';
import PropertyTypeApi from '../Api/PropertyTypeApi';

export const fetchAdjusters = createAsyncThunk(
  'FETCH_ADJUSTERS',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getAdjusters();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const fetchPayCodesIsExcess = createAsyncThunk(
  'FETCH_PAYCODES_EXCESS',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getPayCodes(true);
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const fetchPayCodes = createAsyncThunk(
  'FETCH_PAYCODES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getPayCodes(false);
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchBodyParts = createAsyncThunk(
  'FETCH_BODY_PARTS',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getBodyParts();
      const sorted = [
        ...response.data.sort((a, b) => {
          const reg = /[A-Z]/;
          const first = a.bodyPartDescription.match(reg);
          const second = b.bodyPartDescription.match(reg);
          return first![0].localeCompare(second![0]);
        }),
      ];
      return sorted;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchCounties = createAsyncThunk(
  'FETCH_COUNTIES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getCounties();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchCauses = createAsyncThunk(
  'FETCH_CAUSES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getCauses();
      const sorted = [
        ...response.data.sort((a, b) => a.category.localeCompare(b.category)),
      ];
      return sorted;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchStopPaymentOptions = createAsyncThunk(
  'FETCH_STOPPAYMENTOPTIONS',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getStopPaymentOptions();

      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchResults = createAsyncThunk(
  'FETCH_RESULTS',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getResults();
      const sorted = [
        ...response.data.sort((a, b) => a.category.localeCompare(b.category)),
      ];
      return sorted;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchJobClasses = createAsyncThunk(
  'FETCH_JOB_CLASSES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getJobClasses();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const fetchEmployerPolicyClassCodes = createAsyncThunk(
  'FETCH_JOB_CLASSES_FOR_EMPLOYER_POLICY',
  async (policyId: number, thunkApi) => {
    try {
      const response = await PolicyClassCodeApi.getForPolicy(policyId);
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchStates = createAsyncThunk(
  'FETCH_STATES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getStates();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchClaimStatuses = createAsyncThunk(
  'FETCH_CLAIM_STATUSES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getClaimStatues();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const fetchMedicares = createAsyncThunk(
  'FETCH_MEDICARES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getMedicares();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const fetchShifts = createAsyncThunk(
  'FETCH_SHIFTS',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getShifts();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const fetchSupervisors = createAsyncThunk(
  'FETCH_SUPERVISORS',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getSupervisors();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const fetchNurseCaseManagers = createAsyncThunk(
  'FETCH_NURSE_CASE_MANAGERS',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getNurseCaseManagers();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const fetchClaimsAssistants = createAsyncThunk(
  'FETCH_CLAIMS_ASSISTANTS',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getClaimsAssistants();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const fetchPropertyTypes = createAsyncThunk(
  'FETCH_PROPERTY_TYPES',
  async (_, thunkApi) => {
    try {
      const response = await PropertyTypeApi.getPropertyTypes();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const fetchInsuranceCompanies = createAsyncThunk(
  'FETCH_INSURANCE_COMPANIES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getInsuranceCompanies();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const fetchInsuranceCompaniesForUser = createAsyncThunk(
  'FETCH_INSURANCE_COMPANIES_FOR_USER',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getInsuranceCompaniesForUser();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchClaimNoteTypes = createAsyncThunk(
  'FETCH_CLAIM_NOTE_TYPES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getClaimNoteTypes();
      const sorted = [
        ...response.data.sort((a, b) => {
          const aName = a?.name.toUpperCase();
          const bName = b?.name.toUpperCase();
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        }),
      ];
      return sorted;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchCaseManagementResolutionTypes = createAsyncThunk(
  'FETCH_CASE_MANAGEMENT_RESOLUTION_TYPES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getCaseManagementResolutionTypes();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchAttorneyTypes = createAsyncThunk(
  'FETCH_ATTORNEY_TYPES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getAttorneyTypes();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchLawFirms = createAsyncThunk(
  'FETCH_LAW_FIRMS',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getLawFirms();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchSettlementTypes = createAsyncThunk(
  'FETCH_SETTLEMENT_TYPES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getSettlementTypes();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchDispositionTypes = createAsyncThunk(
  'FETCH_DISPOSITION_TYPES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getDispositionTypes();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchJudges = createAsyncThunk(
  'FETCH_JUDGES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getJudges();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchAppealRulings = createAsyncThunk(
  'FETCH_APPEAL_RULINGS',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getAppealRulings();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchAwardRulings = createAsyncThunk(
  'FETCH_AWARD_RULINGS',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getAwardRulings();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchDocTypes = createAsyncThunk(
  'FETCH_DOC_TYPES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getDocTypes();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchDocStatuses = createAsyncThunk(
  'FETCH_DOC_STATUSES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getDocStatuses();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchEdiCodes = createAsyncThunk(
  'FETCH_EDI_CODES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getEdiCodes();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchEdiBenefitTypeCodes = createAsyncThunk(
  'FETCH_EDI_BENEFIT_TYPE_CODES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getEdiBenefitTypeCodes();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchEdiMaintTypeCodes = createAsyncThunk(
  'FETCH_EDI_MAINT_TYPE_CODES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getEdiMaintTypeCodes();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchEdiPartOfBodyCodes = createAsyncThunk(
  'FETCH_EDI_PART_OF_BODY_CODES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getEdiPartOfBodyCodes();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchWorkStatusTypes = createAsyncThunk(
  'FETCH_WORK_STATUS_TYPES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getWorkStatusTypes();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchWorkStatusBodyParts = createAsyncThunk(
  'FETCH_WORK_STATUS_BODY_PARTS',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getWorkStatusBodyParts();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const fetchHasOpenNcm = createAsyncThunk(
  'FETCH_HAS_OPEN_NCM',
  async (claimNumber: string, thunkApi) => {
    try {
      const response = await NcmApi.hasOpenNcm(claimNumber);
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const updateUserPriorityClaim = createAsyncThunk(
  'FETCH_UPDATE_USER_PRIORITY_CLAIM',
  async (
    {
      claimNumber,
      active,
    }: {
      claimNumber: string;
      active: boolean;
    },
    thunkApi
  ) => {
    try {
      const response = await UserPriorityClaimApi.updateUserPriorityClaim(
        claimNumber,
        active
      );
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const getPbms = createAsyncThunk('FETCH_PBMS', async (_, thunkApi) => {
  try {
    const response = await ReferenceApi.getPbms();
    return response.data;
  } catch (error) {
    const { errorMessage } = handleError(error);
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const getDocPriorities = createAsyncThunk(
  'FETCH_DOC_PRIORITIES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getDocPriorities();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const getDocTypesFullScanner = createAsyncThunk(
  'FETCH_DOC_TYPES_FULL_SCANNER',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getDocTypesFullScanner();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const fetchIcdCodes = createAsyncThunk(
  'FETCH_ICD_CODES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getICDCodes();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const fetchPlanInsuranceTypes = createAsyncThunk(
  'FETCH_PLAN_INSURANCE_TYPES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getPlanInsuranceTypes();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const fetchInsuranceTypes = createAsyncThunk(
  'FETCH_INSURANCE_TYPES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getInsuranceTypes();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const fetchClaimType = createAsyncThunk(
  'FETCH_CLAIM_TYPES',
  async (_, thunkApi) => {
    try {
      const response = await ReferenceApi.getClaimTypes();
      return response.data;
    } catch (error) {
      const { errorMessage } = handleError(error);
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
