import { EdiTransaction } from '../../../ApiTypes/EdiTransaction';
import { displayDateOnly } from '../../../Utils';

export const exportHandler = (transactions: EdiTransaction[]) => {
  const columns = [
    'Id',
    'Form',
    'Type',
    'Benefit',
    'Date',
    'Creator',
    'Status',
    'Error',
  ];

  const rows: any[][] = [];

  transactions.forEach((t) => {
    const tRow = [
      `${t.id}`,
      t.form,
      t.maintTypeCode,
      t.benefitTypeName,
      displayDateOnly(t.submitDate),
      t.logCreator,
      t.logStatusString,
      t.notes,
    ];

    rows.push(tRow);

    if (t.ediTransactionLogs) {
      t.ediTransactionLogs?.forEach((l) => {
        const log = [
          '',
          '',
          '',
          '',
          displayDateOnly(l.dateTime ?? '') ?? '',
          l.creator ?? '',
          l.ediStatus?.ediStatusDesc ?? '',
          l.notes ?? '',
        ];

        rows.push(log);
      });
    }
  });

  return {
    columns,
    rows,
    fileName: '',
  };
};
