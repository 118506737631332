import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { FaEye } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { MedicareClaimResultsDetail } from '../../ApiTypes/MedicareClaimResultsDetail';
import { Link } from 'react-router-dom';

export default function DetailsTable({
  data,
}: {
  data: MedicareClaimResultsDetail[];
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<MedicareClaimResultsDetail>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      size: 50,
      cell: ({ row }) => {
        return (
          <Link to={`/claims/${row.original.claimNumber}`}>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='View Claim'
            >
              <FaEye />
            </Button>
          </Link>
        );
      },
    },
    {
      header: 'Detail Id',
      accessorFn: (d) => `${d.detailId}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Record Indicator',
      accessorKey: 'recordIndicator',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Row Number',
      accessorKey: 'rowNumber',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Section 111 Reporter Id',
      accessorKey: 'section111ReporterId',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'ICN',
      accessorKey: 'icn',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Error Code',
      accessorKey: 'errorCode',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Error Description',
      accessorKey: 'errorDescription',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claim Number',
      accessorKey: 'claimNumber',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Adjuster Last Name',
      accessorKey: 'adjusterLastName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Adjuster First Name',
      accessorKey: 'adjusterFirstName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
    />
  );
}
