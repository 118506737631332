export interface ReportRequest {
  accountNumber: string;
  checkNumber: string;
  claimNumber: string;
  claimsByAccountNumberAndTreatyYearCount: number;
  claimsByEmployerAndTreatyYearCount: number;
  employerId: number | null;
  exportFormat: FileFormat;
  fromDate: string | null;
  fromName: string;
  genericCheckReportName: string | null;
  insurCoId: number | null;
  openOnly: boolean;
  paymentBatchIds: number[] | null;
  report: AvailableReport | null;
  severity: string;
  status: string;
  title: string;
  treatyYear: number | null;
  treatyYearTo: number | null;
  toDate: string | null;
  userId: string;
}

export const ReportRequestFields = [
  'accountNumber',
  'claimNumber',
  'employerId',
  'insurCoId',
  'severity',
  'status',
  'userId',
  'fromDate',
  'toDate',
  'treatyYear',
  'treatyYearTo',
];

export interface ReportResponse {
  messages: string[];
  reportBytes: string;
  reportFileName: string;
  isCrystal: boolean;
  isPDF: boolean;
  success: boolean;
}

export enum AvailableReport {
  CheckRegister,
  CheckRegisterAllAccounts,
  CheckRegisterExcludeSplc,
  CheckRegisterIncludeSplc,
  CheckRegisterIndOnly,
  CheckRegisterNew,
  CheckRegisterNew3,
  CheckRegisterSplcOnly,
  ClaimListingByAdjuster,
  ClaimListingByEmployer,
  ClaimListingByYear,
  ClaimStatus,
  ClaimStatusReportAllClaimsCurrent,
  CsaLossRunDetailbyMemberWithCover,
  DiaryReport,
  GemcLossRun,
  GenericCheck,
  LossRunByProgramAndDeptWithDetailForOpen,
  LossRunByProgramWithDetailForOpen,
  LossRunDetailAllEmployersAllClaims,
  LossRunDetailAllEmployersAllClaimsByPolicyDate,
  LossRunDetailbyMemberWithCover,
  LossRunDetailIncludeClosed,
  LossRunReportByMemberExternalOld,
  LossRunReportByMemberInternalOld,
  LossRunSummaryOld,
  Notes,
  PaymentHistory,
  PaymentsByPayCodeAsOf,
  ReservesHistory,
  ReservesHistoryAsOf,
  SettlementReport,
}

export enum FileFormat {
  NoFormat,
  CrystalReport,
  RichText,
  WordForWindows,
  Excel,
  PortableDocFormat,
  HTML32,
  HTML40,
  ExcelRecord,
  Text,
  CharacterSeparatedValues,
  TabSeperatedText,
  EditableRTF,
  Xml,
  RPTR,
  ExcelWorkbook,
  XLSXPagebased,
  XLSXRecord,
}
