import React, { useState, useEffect, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { ExcessStatusObj } from '../../ApiTypes/ExcessStatusObj';
import accounting from 'accounting';
import { displayDateOnly } from '../../Utils';
import ClaimApi from '../../Api/ClaimApi';
import Loading from '../Common/Loading';

export default function ExcessStatusTable() {
  const [data, setData] = useState<ExcessStatusObj[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => data, [data]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    ClaimApi.getExcessStatus()
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const columnData: ColumnDef<ExcessStatusObj>[] = [
    {
      header: 'Claim No',
      accessorKey: 'claimNo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Claimant',
      accessorKey: 'claimantName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Injury Date',
      accessorKey: 'injurydate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.injurydate ?? ''),
    },
    {
      header: 'Adjuster',
      accessorKey: 'adjusterName',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Total Paid',
      accessorFn: (d) => `${d.totalPaid}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) => accounting.formatMoney(d.row.original.totalPaid),
    },
    {
      header: 'Total Reserves',
      accessorFn: (d) => `${d.totalReserves}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) => accounting.formatMoney(d.row.original.totalReserves),
    },
    {
      header: 'Total Incurred',
      accessorFn: (d) => `${d.totalIncurred}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) => accounting.formatMoney(d.row.original.totalIncurred),
    },
    {
      header: 'SIR',
      accessorFn: (d) => `${d.sir}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) => accounting.formatMoney(d.row.original.sir),
    },
    {
      header: 'Percent',
      accessorFn: (d) => `${d.percent.toFixed(2)}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div>
      {loading && <Loading />}
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
      />
    </div>
  );
}
