import { ClaimSearchResult } from '../../ApiTypes/ClaimSearchResult';

export const exportHandler = (data: ClaimSearchResult[]) => {
  const columns = [
    'Account',
    'Claim Number',
    'Injury Date',
    'Last Name',
    'First Name',
    'DOB',
    'Employer',
    'SSN',
    'Status',
    'Adjuster',
    'Severity',
  ];

  const rows: any[][] = [];

  data.forEach((t) => {
    const tRow = [
      t.accountNumber,
      t.claimNo,
      t.injuryDate,
      t.lastName,
      t.firstName,
      t.dob,
      t.employerName,
      t.ssn,
      t.status,
      t.adjusterName,
      t.severity,
    ];

    rows.push(tRow);
  });

  return {
    columns,
    rows,
    fileName: '',
  };
};
