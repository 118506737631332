import React from 'react'
import { Form as RFFForm, Field } from "react-final-form"
import { Button, Container, Form, Modal } from 'react-bootstrap'
import styles from "./index.module.css"
import { FaCalculator } from 'react-icons/fa'
import FieldBSRenderDate from '../Common/FieldBSRenderDate'
import FieldBSRenderText from '../Common/FieldBSRenderText'
import { differenceInBusinessDays, differenceInWeeks, isBefore } from 'date-fns'
import { FormApi } from 'final-form'
import accounting from 'accounting'
import { centsValidation } from '../../Utils/FieldValidation'
import { formatNumbers, cleanMoney } from '../../Utils/InputFormatters'
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney'

type CalculatorType = {
  startDate: string,
  endDate: string,
  weeks: number,
  days: number,
  totalDays: number,
  wcr: number,
  totalAmount: string,
}

export default function Calculator({
  show,
  setShow,
}: {
  show: boolean,
  setShow: (show: boolean) => void

}) {
  let formInstance: FormApi<CalculatorType, Partial<CalculatorType>>;
  const onSubmit = (values: CalculatorType) => {
    const { totalDays, weeks, days } = calc(new Date(values.startDate), new Date(values.endDate))

    const multiplier = days * 0.2;
    let wcr = values.wcr ?? 0;
    const totalAmount = (wcr * weeks) + (wcr * multiplier)
    console.log({ wcr, multiplier, totalAmount })

    formInstance.change("weeks", weeks)
    formInstance.change("days", days)
    formInstance.change("totalDays", totalDays)
    formInstance.change("totalAmount", accounting.formatMoney(totalAmount))
  }

  const calc = (start: Date, end: Date) => {
    let weeks = 0;
    let days = 0;
    let totalDays = 0;
    totalDays = differenceInBusinessDays(end, start);
    weeks = differenceInWeeks(end, start, { roundingMethod: "floor" })
    days = totalDays % 5;

    console.log({ totalDays, weeks, days })
    return {
      totalDays,
      weeks,
      days,
    }
  }

  return (
    <Modal
      centered
      show={show}
      size="lg"
      onHide={() => setShow(false)}
      aria-labelledby="Claimant-Form-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="button-icon-text" id="Claim-Form-modal">
          <FaCalculator className="pe-1" /> Calculator
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={{}}
            validate={values => {
              const errors: {
                [Property in keyof CalculatorType]?: string;
              } = {};

              if (!values.startDate) {
                errors.startDate = "Required"
              }
              if (!values.endDate) {
                errors.endDate = "Required"
              }
              if (values.startDate && values.endDate && isBefore(new Date(values.endDate), (new Date(values.startDate)))) {
                errors.startDate = "Start Date must be before End Date"
                errors.endDate = "Start Date must be before End Date"
              }

              return errors;
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              formInstance = form;
              return (
                <Form onSubmit={handleSubmit} >
                  <div className={`${styles.dateGrid}`}>
                    <Field
                      name="startDate"
                      label="Start Date"
                      component={FieldBSRenderDate}
                    />
                    <Field
                      name="endDate"
                      label="End Date"
                      component={FieldBSRenderDate}
                    />
                    <Field
                      name="weeks"
                      label="Weeks"
                      type="number"
                      component={FieldBSRenderText}
                      disabled
                    />
                    <Field
                      name="days"
                      label="Days"
                      type="number"
                      component={FieldBSRenderText}
                      disabled
                    />
                  </div>
                  <Field
                    name="totalDays"
                    label="Total Work Days"
                    type="number"
                    component={FieldBSRenderText}
                    disabled
                  />
                  <Field
                    name="wcr"
                    label="WCR"
                    type="text"
                    format={formatNumbers}
                    parse={cleanMoney}
                    validate={centsValidation}
                    component={FieldBSRenderMoney}
                  />
                  <Field
                    name="totalAmount"
                    label="Total"
                    type="text"
                    component={FieldBSRenderText}
                    disabled
                  />
                  <div className='d-flex justify-content-around'>
                    <Button
                      type="submit"
                      size="sm"
                      variant='primary'
                    >
                      Calculate
                    </Button>
                  </div>

                </Form>
              )
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  )
}
