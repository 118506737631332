import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { DocumentUpdateStatusRequest } from '../ApiTypes/DocumentUpdateStatusRequest';
import { EmailDocumentsRequest } from '../ApiTypes/EmailDocumentsRequest';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { ScanDocNotesRequest } from '../ApiTypes/ScanDocNotesRequest';
import { ScanDocSimple } from '../ApiTypes/ScanDocSimple';
import { SecureShareDocumentRequest } from '../ApiTypes/SecureShareDocumentRequest';
import { ScanDocNotesRequestFormType } from '../Types/ScanDocNotesRequestFormType';
import { DocumentClaimNumberChangeRequest } from '../ApiTypes/DocumentClaimNumberChangeRequest';
import { FileDownload } from '../Types/FileDownLoad';
import { SecureShare } from '../ApiTypes/SecureShare';
import { ExportDocumentsRequest } from '../ApiTypes/ExportDocumentsRequest';
import { ScanDoc } from '../ApiTypes/ScanDoc';
import { ScanDocsPending } from '../ApiTypes/ScanDocsPending';
import { AddDocumentRequest } from '../ApiTypes/AddDocumentRequest';
import { FileObject } from '../ApiTypes/FileObject';
import { NcmUpdateDocumentStatusRequest } from '../ApiTypes/NcmUpdateDocumentStatusRequest';
import { UpdateNcmNotesRequest } from '../ApiTypes/UpdateNcmNotesRequest';
import { ScanDocGridObject } from '../ApiTypes/ScanDocGridObject';
import { ScanDocsLog } from '../ApiTypes/ScanDocsLog';
import { VwDocTurnaroundGrouped } from '../ApiTypes/VwDocTurnaroundGrouped';
import { GetFileByPathRequest } from '../ApiTypes/GetFIleByPathRequest';
import { AttachEORToDocRequest } from '../ApiTypes/AttachEORToDocRequest';
import { UpdateScanDocRequest } from '../ApiTypes/UpdateScanDocRequest';

class DocumentsApi {
  static getScanDocsForClaim(claimNumber: string, docType: number) {
    return axios.get<ScanDocSimple[]>(
      `${API_URL}/api/claims/${claimNumber}/scandocs?documenttype=${docType}`,
      getAuthHeader()
    );
  }
  static deleteDocument(claimNumber: string, docId: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/scandocs/${docId}`,
      getAuthHeader()
    );
  }
  static uploadDocument(claimNumber: string, data: FormData) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/scandocs`,
      data,
      getAuthHeader()
    );
  }
  static secureShareDocs(request: SecureShareDocumentRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/scandocs/secureshares`,
      request,
      getAuthHeader()
    );
  }
  static updateSecureShare(request: SecureShareDocumentRequest) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/scandocs/secureshares/${request.shareId}`,
      request,
      getAuthHeader()
    );
  }
  static emailDocs(request: EmailDocumentsRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/scandocs/email`,
      request,
      getAuthHeader()
    );
  }
  static getNotesRequestsForDoc(docId: number) {
    return axios.get<ScanDocNotesRequest[]>(
      `${API_URL}/api/scandocs/${docId}/notesrequests`,
      getAuthHeader()
    );
  }
  static createNotesRequestForDoc(
    request: ScanDocNotesRequestFormType,
    claimNumber: string,
    save: boolean
  ) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/scandocs/${request.scanDocId}/notesrequests?save=${save}`,
      request,
      getAuthHeader()
    );
  }
  static updateNotesRequestForDoc(
    request: ScanDocNotesRequestFormType,
    claimNumber: string,
    save: boolean
  ) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/scandocs/${request.scanDocId}/notesrequests/${request.keyNotesReqId}?save=${save}`,
      request,
      getAuthHeader()
    );
  }
  static changeClaimNumber(request: DocumentClaimNumberChangeRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/scandocs/reassign`,
      request,
      getAuthHeader()
    );
  }
  static updateStatus(request: DocumentUpdateStatusRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/scandocs/updatestatus`,
      request,
      getAuthHeader()
    );
  }
  static billReviewFederated(request: DocumentUpdateStatusRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/scandocs/updatestatus/billreview`,
      request,
      getAuthHeader()
    );
  }
  static getScanDocFile(docId: number) {
    return axios.get<FileDownload>(
      `${API_URL}/api/scandocs/${docId}/file`,
      getAuthHeader()
    );
  }
  static getScanDocPendingFile(docId: number) {
    return axios.get<FileDownload>(
      `${API_URL}/api/scandocs/pending/${docId}/file`,
      getAuthHeader()
    );
  }
  static deleteScanDocPending(docId: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/scandocs/pending/${docId}`,
      getAuthHeader()
    );
  }
  static getScanDocSimple(claimNumber: string, docId: number) {
    return axios.get<ScanDocSimple>(
      `${API_URL}/api/claims/${claimNumber}/scandocs/${docId}`,
      getAuthHeader()
    );
  }
  static getSecureShareForClaim(claimNumber: string) {
    return axios.get<SecureShare[]>(
      `${API_URL}/api/claims/${claimNumber}/scandocs/secureshares`,
      getAuthHeader()
    );
  }
  static getSecureShareForUser() {
    return axios.get<SecureShare[]>(
      `${API_URL}/api/scandocs/secureshares`,
      getAuthHeader()
    );
  }
  static deleteSecureShare(id: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/scandocs/secureshares/${id}`,
      getAuthHeader()
    );
  }
  static getSecureShareById(id: number) {
    return axios.get<SecureShare>(
      `${API_URL}/api/scandocs/secureshares/${id}`,
      getAuthHeader()
    );
  }
  static exportDocuments(request: ExportDocumentsRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/scandocs/export`,
      request,
      getAuthHeader()
    );
  }
  static getFullNewListByUser() {
    return axios.get<ScanDoc[]>(
      `${API_URL}/api/scandocs/usernew`,
      getAuthHeader()
    );
  }
  static getPending(docTypeId: number) {
    return axios.get<ScanDocsPending[]>(
      `${API_URL}/api/scandocs/getpendingdocuments/${docTypeId}`,
      getAuthHeader()
    );
  }
  static assignDocToClaim(request: AddDocumentRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/scandocs`,
      request,
      getAuthHeader()
    );
  }
  static fullListForStraightPay(statusId: number, startDate: string) {
    let url = `${API_URL}/api/scandocs/fulllistforstraightpay`;
    if (statusId > 0) {
      url += `?statusId=${statusId}`;
    }
    if (startDate) {
      if (statusId <= 0) {
        url += `?startDate=${startDate}`;
      } else {
        url += `&startDate=${startDate}`;
      }
    }
    return axios.get<ScanDoc[]>(url, getAuthHeader());
  }
  static loadEors() {
    return axios.get<FileObject[]>(
      `${API_URL}/api/scandocs/eors`,
      getAuthHeader()
    );
  }
  static getDocumentsWithControlNumber(
    checkNumber?: string,
    controlNumber?: string,
    claimNumber?: string
  ) {
    let url = `${API_URL}/api/scandocs/documentswithcontrolnumber`;
    let first = true;
    if (checkNumber) {
      url += first ? '?' : '&';
      url += `checkNumber=${checkNumber}`;
    }
    if (controlNumber) {
      url += first ? '?' : '&';
      url += `controlNumber=${controlNumber}`;
    }
    if (claimNumber) {
      url += first ? '?' : '&';
      url += `claimNumber=${claimNumber}`;
    }
    return axios.get<ScanDoc[]>(url, getAuthHeader());
  }
  static getNcmDocuments(statusId: number, insurCoId: number) {
    let url = `${API_URL}/api/scandocs/ncm?documentStatus=${statusId}&insurcoid=${insurCoId}`;
    return axios.get<ScanDoc[]>(url, getAuthHeader());
  }
  static updateNcmStatus(request: NcmUpdateDocumentStatusRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/scandocs/updatestatus/ncm`,
      request,
      getAuthHeader()
    );
  }
  static updateNcmNotes(request: UpdateNcmNotesRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/scandocs/ncm/updatencmnotes`,
      request,
      getAuthHeader()
    );
  }
  static getScanDocsByStatus(statusId: number) {
    return axios.get<ScanDocGridObject[]>(
      `${API_URL}/api/scandocs/status/${statusId}`,
      getAuthHeader()
    );
  }
  static getScanDocLogsByDates(fromDate: string, toDate: string) {
    return axios.get<ScanDocsLog[]>(
      `${API_URL}/api/scandocs/logs?fromDate=${fromDate}&toDate=${toDate}`,
      getAuthHeader()
    );
  }
  static getTurnAround() {
    return axios.get<VwDocTurnaroundGrouped[]>(
      `${API_URL}/api/scandocs/turnaround`,
      getAuthHeader()
    );
  }
  static getDocNotesRequests(statusId: number, claimNumber: string) {
    return axios.get<ScanDocNotesRequest[]>(
      `${API_URL}/api/scandocs/notesrequests/search?statusId=${statusId}&claimNumber=${claimNumber}`,
      getAuthHeader()
    );
  }
  static getFileByPath(request: GetFileByPathRequest) {
    return axios.post<FileDownload>(
      `${API_URL}/api/scandocs/getfilebypath`,
      request,
      getAuthHeader()
    );
  }
  static attachEorToDoc(request: AttachEORToDocRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/scandocs/attacheortodoc`,
      request,
      getAuthHeader()
    );
  }
  static viewEorFile(name: string) {
    return axios.get<FileDownload>(
      `${API_URL}/api/scandocs/vieweorfile?name=${name}`,
      getAuthHeader()
    );
  }
  static updateScanDocProperties(
    request: UpdateScanDocRequest,
    claimNumber: string
  ) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/scandocs/${request.docId}/update`,
      request,
      getAuthHeader()
    );
  }
  static downloadDocuments(docIds: number[]) {
    return axios.post<FileDownload>(
      `${API_URL}/api/scandocs/download`,
      { docIds: docIds },
      getAuthHeader()
    );
  }
}

export default DocumentsApi;
