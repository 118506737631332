export interface ClaimReservesWorksheet {
  id: number;
  claimNo: string | null;
  futureTempTotalExpected: number | null;
  futureTempTotalExpectedWeeks: number | null;
  permanantImpairment: number | null;
  percentageLoss: number | null;
  percentageLossWeeks: number | null;
  percentageLossCrtotal: number | null;
  tpdExposure: number | null;
  tpdExposureWeeks: number | null;
  permanentTotal: number | null;
  permanentTotalWeeks: number | null;
  deathBenefits: number | null;
  claimantAttorneyFees: number | null;
  futureMedicalPhysician: number | null;
  futureMedicalHospital: number | null;
  futureMedicalMileage: number | null;
  futureMedicalMedicalMgmt: number | null;
  futureMedicalPharmacy: number | null;
  futureMedicalPhysTherapy: number | null;
  newlegalReserves: number | null;
  newExpenseReserves: number | null;
  remarks: string | null;
  status: number | null;
  supervisorId: number | null;
  approvedBy: number | null;
  dateSubmitted: string | null;
  dateApproved: string | null;
  submittedBy: number | null;
  medicalRemarks: string | null;
  legalRemarks: string | null;
  expenseRemarks: string | null;
  indemnityRoundedOverride: number | null;
  medicalRoundedOverride: number | null;
  legalRoundedOverride: number | null;
  expenseRoundedOverride: number | null;
  oldIndemnity: number | null;
  oldMedical: number | null;
  oldLegal: number | null;
  oldExpense: number | null;
  totalIncurredReserves: number | null;
  totalReserveDifference: number | null;
  previouseIndemnityIncurred: number | null;
  previouseMedicalIncurred: number | null;
  previouseLegalIncurred: number | null;
  previouseExpenseIncurred: number | null;
}

export enum ReserveWorksheetStatus {
  InProgress = 1,
  SubmittedWaitingForApproval = 2,
  ApprovedByAdjuster = 3,
  ApprovedBySupervisor = 4,
  Deleted = 5,
  RejectedBySupervisor = 6
}