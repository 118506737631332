import React, { useRef, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import FieldBSRenderText from '../Common/FieldBSRenderText';

import styles from './index.module.css';
import ProviderLookUpTable from './ProviderLookUpTable';
import { VPaymentTab } from '../../ApiTypes/VPaymentTab';
import { ProviderSearchRequest } from '../../ApiTypes/ProviderSearchRequest';
import ProviderApi from '../../Api/ProviderApi';
import { ProviderSearchResult } from '../../ApiTypes/ProviderSearchResult';

export default function ProviderLookUpForm({
  selectProvider,
  selectedPayment,
  disabled,
}: {
  disabled: boolean;
  selectProvider: (provider: ProviderSearchResult) => void;
  selectedPayment: VPaymentTab | null;
}) {
  const [providers, setProviders] = useState<ProviderSearchResult[]>([]);

  // const inputRef = useRef();
  // const handleTaxIdFocus = () => {
  //   var taxId = document.getElementById('taxId');
  //   // taxId?..focus();
  // };

  const searchProviders = (values: ProviderSearchRequest) => {
    return ProviderApi.searchProviders(values)
      .then((res) => {
        setProviders(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = (values: ProviderSearchRequest) => {
    if (disabled) {
      return;
    }
    return searchProviders(values);
  };

  return (
    <div className='w-100'>
      <RFFForm
        onSubmit={onSubmit}
        initialValues={
          selectedPayment?.payee === 'Provider'
            ? {
                taxId: selectedPayment.taxId,
                name: selectedPayment.checkPayableTo,
                activeOnly: false,
              }
            : { activeOnly: false }
        }
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className='d-flex justify-content-between align-items-center'>
                <div className={styles.providerSearchGrid}>
                  <Field
                    name='taxId'
                    type='text'
                    label='Tax Id'
                    autoFocus={true}
                    className={'focus'}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='name'
                    type='text'
                    label='Provider Name'
                    component={FieldBSRenderText}
                  />
                  
                  <div>
                    <Button
                      type='submit'
                      size='sm'
                      variant='primary'
                      disabled={disabled}
                    >
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Search'
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      />
      <ProviderLookUpTable
        data={providers}
        selectProvider={selectProvider}
        setProviders={setProviders}
      />
    </div>
  );
}
