import { FormApi } from 'final-form';
import React from 'react';
import { Field } from 'react-final-form';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { displayDateOnly, parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import { formatNumbers, cleanMoney } from '../../Utils/InputFormatters';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';

export default function SuspensionForm({
  values,
  form,
  transaction,
}: {
  form: FormApi<EdiTransaction, Partial<EdiTransaction>>;
  values: Partial<EdiTransaction>;
  transaction: EdiTransaction | null;
}) {
  return (
    <div>
      <h5 className='text-center'>C. Suspension of Benefits</h5>
      <Field
        name='initialReturnToWorkDate'
        label='Date Employee Returned to Work'
        parse={parseDatesForServer}
        component={FieldBSRenderDate}
        validate={requiredField}
      />
      <div className='d-flex align-items-center gap1Rem'>
        <div className='form-check'>
          <label className='form-check-label'>
            <input
              name='suspended'
              type='checkbox'
              className='form-check-input'
              checked={
                values.benefitSuspendDate !== null &&
                values.benefitSuspendDate !== undefined &&
                values.benefitSuspendDate !== ''
              }
              readOnly
            />
            Benefits will be suspended on
          </label>
        </div>
        <Field
          name='benefitSuspendDate'
          label='Date Suspended'
          parse={parseDatesForServer}
          component={FieldBSRenderDate}
        />
        <p className='mt-2'>because:</p>
      </div>
      <div className='d-flex flex-column gap1Rem'>
        <Field
          name='suspensionCheck1'
          type='checkbox'
          label={`1.) Employee returned to work on ${
            values.initialReturnToWorkDate
              ? displayDateOnly(values.initialReturnToWorkDate)
              : 'N/A'
          } without restrictions from the authorized treating physician.`}
          checked={!!values?.suspensionCheck1}
          component={FieldBSRenderCheckbox}
        />
        <Field
          name='suspensionCheck2'
          type='checkbox'
          label={`2.) Employee returned to work on ${
            values.initialReturnToWorkDate
              ? displayDateOnly(values.initialReturnToWorkDate)
              : 'N/A'
          } with restrictions from the authorized treating physician at pre-injury or higher rate of pay.`}
          checked={!!values?.suspensionCheck2}
          component={FieldBSRenderCheckbox}
        />
        <Field
          name='suspensionCheck3'
          type='checkbox'
          label={`3.) Employee returned to work on ${
            values.initialReturnToWorkDate
              ? displayDateOnly(values.initialReturnToWorkDate)
              : 'N/A'
          } with restrictions from the authorized treating physician at reduced pay of`}
          checked={!!values?.suspensionCheck3}
          component={FieldBSRenderCheckbox}
        />
      </div>
      <div className='d-flex align-items-center ms-5 gap1Rem'>
        <Field
          name='suspensionReducedPay'
          label=' '
          type='text'
          format={formatNumbers}
          parse={cleanMoney}
          component={FieldBSRenderMoney}
        />
        <p>
          per week and temporary partial disability benefits are shown in Part B
          above.
        </p>
      </div>
      <div className='d-flex flex-column gap1Rem'>
        <Field
          name='suspensionCheck4'
          type='checkbox'
          label={`4.) Employee was able to return to work on ${
            values.initialReturnToWorkDate
              ? displayDateOnly(values.initialReturnToWorkDate)
              : 'N/A'
          } without restrictions from the authorized treating physician, the employee is being given ten (10) days notice, and the authorized treating physician's report is attached (Board Rule 221 (i)(4)).`}
          checked={!!values?.suspensionCheck4}
          component={FieldBSRenderCheckbox}
        />
        <Field
          name='suspensionCheck5'
          type='checkbox'
          label='5.) The Employee had undergone a change in condition pursuant to O.C.G.A. §34-9-104(a)(2) because the employee is not working, did not have a catastrophic injury, has been determined by the authorized treating physician to be capable of performing work with limitations or restrictions for the past 52 consecutive or 78 aggregate weeks, and was sent Form WC-104 within sixty days of the release. Temporary partial disability benefits are shown in Part B above.'
          checked={!!values?.suspensionCheck5}
          component={FieldBSRenderCheckbox}
        />
        <Field
          name='suspensionCheck6'
          type='checkbox'
          label='6.) The employee has been offered suitable employment pursuant to O.S.G.A. §34-9-240 and has unjustifiably refused to attempt to perform the job. Form WC-240 was sent at least ten (10) days before the employee was required to report for work. '
          checked={!!values?.suspensionCheck6}
          component={FieldBSRenderCheckbox}
        />
        <Field
          name='suspensionCheck7'
          type='checkbox'
          label='7.) This was not a catastrophic injury and the maximum number of temporary total disability payments has been paid.'
          checked={!!values?.suspensionCheck7}
          component={FieldBSRenderCheckbox}
        />
        <Field
          name='suspensionCheck8'
          type='checkbox'
          label='8.) The entire permanent partial disability benefit has been paid.'
          checked={!!values?.suspensionCheck8}
          component={FieldBSRenderCheckbox}
        />
        <Field
          name='suspensionCheck9'
          type='checkbox'
          label='9.) The maximum of temporary partial disability payments has been paid.'
          checked={!!values?.suspensionCheck9}
          component={FieldBSRenderCheckbox}
        />
        <Field
          name='suspensionCheck10'
          type='checkbox'
          label='10.) This claim is being controverted within sixty days of the due date of first payment.'
          checked={!!values?.suspensionCheck10}
          component={FieldBSRenderCheckbox}
        />
        <Field
          name='suspensionCheck11'
          type='checkbox'
          label='11.) Other '
          checked={!!values?.suspensionCheck11}
          component={FieldBSRenderCheckbox}
        />
      </div>
      <Field
        name='suspensionOther'
        label=' '
        placeholder='Other Reason'
        rows={3}
        component={FieldBSRenderTextArea}
      />
    </div>
  );
}
