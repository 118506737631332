import React, { useState, useMemo, useEffect } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { WorkStatusGridObj } from '../../../ApiTypes/WorkStatusGridObject';
import { displayDateOnly } from '../../../Utils';
import { Button } from 'react-bootstrap';
import {
  FaEdit,
  FaEnvelope,
  FaEye,
  FaFilePdf,
  FaPlusCircle,
  FaTrash,
} from 'react-icons/fa';
import DocumentsApi from '../../../Api/DocumentsApi';
import { toast } from 'react-toastify';
import { useDocViewModal } from '../../DocViewModal/useDocViewModal';
import PdfModal from '../../DocViewModal/DocViewModal';
import styles from './index.module.css';
import { useAppSelector } from '../../../Reducers/Store';
import EmailDocumentsModal from '../../EmailDocuments/EmailDocumentsModal';
import { EmailDocumentsRequest } from '../../../ApiTypes/EmailDocumentsRequest';
import { UserEmailList } from '../../../ApiTypes/UserEmailList';
import UserEmailListApi from '../../../Api/UserEmailListApi';
import { ScanDocSimple } from '../../../ApiTypes/ScanDocSimple';
import WorkStatusModal from '../../WorkStatusModal/WorkStatusModal';
import PhysicianApi from '../../../Api/PhysicianApi';
import { Physician } from '../../../ApiTypes/Physician';
import WorkStatusApi from '../../../Api/WorkStatusApi';
import { Link, useParams } from 'react-router-dom';

export default function WorkStatusTable({
  statusObjs,
  claimNumber,
  getWorkStatusesForGrid,
  claimType,
}: {
  statusObjs: WorkStatusGridObj[];
  claimNumber: string;
  getWorkStatusesForGrid: () => void;
  claimType: number;
}) {
  const { employer, claimant, claim } = useAppSelector(
    (state) => state.currentClaimReducer
  );
  const { userModel } = useAppSelector((state) => state.user);
  const { docid } = useParams();

  const {
    showDocViewModal,
    fileDownload,
    setShowDocViewModal,
    setFileDownload,
  } = useDocViewModal();

  const [claimantFullName, setClaimantFullName] = useState<string>('');
  const [selectedStatus, setSelectedStatus] =
    useState<WorkStatusGridObj | null>(null);

  // WorkStatus Modal
  const [showAddEdit, setShowAddEdit] = useState<boolean>(false);
  const [physicians, setPhysicians] = useState<Physician[]>([]);

  // email modal
  const [showEmailDocs, setShowEmailDocs] = useState<boolean>(false);
  const [userEmailList, setUserEmailList] = useState<UserEmailList[]>([]);
  const [docs, setDocs] = useState<ScanDocSimple[]>([]);
  const [toEmailAddresses, setToEmailAddresses] = useState<string[]>([]);
  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  useEffect(() => {
    if (docid) {
      if (statusObjs && statusObjs.some((x) => x.docId === +docid)) {
        var status = statusObjs.find((x) => x.docId === +docid);
        setSelectedStatus(status!);
        setShowAddEdit(true);
      }
    }
  }, [docid, statusObjs]);

  useEffect(() => {
    getUserEmailList();
  }, [userModel]);

  useEffect(() => {
    getClaimantFullName();
  }, [claimant]);

  useEffect(() => {
    getClaimAuthorizedPhysicians();
  }, [claimNumber]);

  const getUserEmailList = () => {
    if (userModel?.user?.userId) {
      UserEmailListApi.getEmailList(userModel?.user?.userId)
        .then((res) => {
          setUserEmailList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getClaimAuthorizedPhysicians = () => {
    if (claimNumber) {
      PhysicianApi.getClaimAuthorizedPhysicians(claimNumber)
        .then((res) => {
          var list: Physician[] = [];

          res.data.forEach((x) => {
            if (x.physician) {
              list.push(x.physician);
            }
          });
          setPhysicians(list);
        })
        .catch((err) => {
          console.log(err);
          setPhysicians([]);
        });
    } else {
      setPhysicians([]);
    }
  };

  const getClaimantFullName = () => {
    if (claimant) {
      setClaimantFullName(claimant.firstName + ' ' + claimant.lastName);
    }
  };

  const tableData = useMemo(() => statusObjs, [statusObjs]);

  const columnData: ColumnDef<WorkStatusGridObj>[] = [
    {
      header: '',
      id: 'actions',
      enableColumnFilter: false,
      enableSorting: false,
      size: 200,
      cell: ({ row }) => {
        return (
          <div
            className={`${styles.actionButtons} ${
              row.original.id === selectedStatus?.id ? 'orange' : ''
            }`}
          >
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='Edit'
              onClick={() => {
                setSelectedStatus(row.original);
                handleEdit(row.original);
              }}
            >
              <FaEdit />
            </Button>
            <Button
              type='button'
              variant='danger'
              size='sm'
              title='Delete'
              onClick={() => {
                handleDelete(row.original);
              }}
            >
              <FaTrash />
            </Button>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='View WC-104'
              onClick={() => {
                toast.info(
                  'To complete a valid WC104 you must file on ICMS immediately and mail document to all parties',
                  { autoClose: 4000 }
                );
                setSelectedStatus(row.original);
                handleViewWC104(row.original);
              }}
            >
              <FaEye />
            </Button>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='Email'
              onClick={() => {
                setSelectedStatus(row.original);
                handleEmail(row.original);
              }}
            >
              <FaEnvelope />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Type',
      accessorKey: 'statusType',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Description',
      accessorKey: 'statusDescription',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Physician',
      accessorKey: 'assignedPhysician',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Body Part',
      accessorKey: 'bodyPartsString',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date',
      accessorKey: 'date',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Light Duty Avail.',
      accessorFn: (d) => (d.lightDutyAvailable ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Next Visit',
      accessorKey: 'nextOfficeVisitString',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Set Diary',
      accessorFn: (d) => (d.setDiary ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'WC104 Needed',
      accessorFn: (d) => (d.wC104Needed ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Reduced From',
      accessorKey: 'benefitsReducedFrom',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Reduced To',
      accessorKey: 'benefitsReducedTo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Doc',
      id: 'doc',
      enableSorting: false,
      enableColumnFilter: false,
      size: 50,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center align-items-center'>
            <Link
              to={`/scandocs/${row.original.docId}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button
                type='button'
                variant='outline-primary'
                size='sm'
                onClick={() => {
                  // getDoc(row.original.docId);
                  setSelectedStatus(row.original);
                }}
                disabled={!row.original.docId}
              >
                <FaFilePdf />
              </Button>
            </Link>
          </div>
        );
      },
    },
  ];
  const columns = useMemo(
    () => columnData,
    [claim, claimant, userModel, columnData, physicians, selectedStatus]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  const getDoc = (docId: number) => {
    DocumentsApi.getScanDocFile(docId)
      .then((res) => {
        setFileDownload(res.data);
        setShowDocViewModal(true);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get document');
      });
  };

  const handleEmail = (ws: WorkStatusGridObj) => {
    if (ws.statusType === 'Unknown') {
      toast.error(
        'Please select a valid status type before attempting to send email!'
      );
      return;
    }

    const tos: string[] = [];
    let sub = `VSR ${claimantFullName} ${ws.statusType} ${displayDateOnly(
      claim?.injuryDate ?? ''
    )}`;
    let message = '';
    let dischargeMessage = '';

    if (!employer?.emailToNotify) {
      tos.push(userModel?.user?.emailAddress ?? '');
    } else {
      tos.push(employer?.emailToNotify);
    }
    if (ws.docId) {
      DocumentsApi.getScanDocSimple(claimNumber, ws.docId)
        .then((res) => {
          setDocs([res.data]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (ws.dischargedFromCare) {
      dischargeMessage = `<br /><br /> ${
        claimant?.sex === 'M' ? 'He' : 'She'
      } has been discharged from care.`;
    } else {
      dischargeMessage = `<br /><br /> ${
        claimant?.sex === 'M' ? 'He' : 'She'
      } has NOT been discharged from care.`;
    }

    switch (ws.statusType) {
      case 'No Work':
        message = `${claimantFullName} remains out of work as of ${displayDateOnly(
          ws.date
        )} by ${ws.assignedPhysician.trim()}.`;
        break;
      case 'Restricted Work':
        message = `${claimantFullName} remains on ${
          ws.statusType
        } as of ${displayDateOnly(
          ws.date
        )} by ${ws.assignedPhysician.trim()}. <br /><br />Please let me know if you are able to accommodate these restrictions: ${
          ws.statusDescription ? ws.statusDescription : 'Contact Me'
        }`;
        break;
      case 'Full Duty':
        message = `${claimantFullName} has been released to ${
          ws.statusType
        } as of ${displayDateOnly(ws.date)} by ${ws.assignedPhysician.trim()}.`;
        break;
      default:
        break;
    }

    if (ws.nextOfficeVisit && ws.nextOfficeVisitString !== '') {
      message += `<br /><br />Next appointment is scheduled for ${ws.nextOfficeVisitString}`;
    }
    if (dischargeMessage) {
      message += dischargeMessage;
    }

    setSubject(sub);
    setBody(message);
    setToEmailAddresses(tos);
    setShowEmailDocs(true);
  };

  const handleEmailDocuments = (request: EmailDocumentsRequest) => {
    return DocumentsApi.emailDocs(request)
      .then((res) => {
        if (res.data.success) {
          setShowEmailDocs(false);
          setDocs([]);
          setBody('');
          setSubject('');
          setToEmailAddresses([]);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to share documents');
      });
  };

  const handleEdit = (ws: WorkStatusGridObj) => {
    if (physicians.length < 1) {
      toast.error(
        'Please be sure to add Authorized Physicians to the Physicians tab before adding a Work Status.'
      );
      return;
    }
    setSelectedStatus(ws);
    setShowAddEdit(true);
  };

  const handleDelete = (ws: WorkStatusGridObj) => {
    return WorkStatusApi.deleteWorkStatus(ws.id)
      .then((res) => {
        if (res.data.success) {
          getWorkStatusesForGrid();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to delete work status');
      });
  };

  const handleViewWC104 = (ws: WorkStatusGridObj) => {
    WorkStatusApi.viewWorkStatusWC104(ws.id, claimNumber)
      .then((res) => {
        setFileDownload({
          file: res.data.reportBytes,
          fileName: res.data.reportFileName,
        });
        setShowDocViewModal(true);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get WC104 report');
      });
  };

  return (
    <div>
      <div className='d-flex justify-content-center align-items-center mb-3'>
        <Button
          type='button'
          variant='primary'
          size='sm'
          className='button-icon-text'
          onClick={() => {
            if (physicians.length < 1) {
              toast.error(
                'Please be sure to add Authorized Physicians to the Physicians tab before adding a Work Status.'
              );
              return;
            }
            setSelectedStatus(null);
            setShowAddEdit(true);
          }}
        >
          <FaPlusCircle /> Add New
        </Button>
      </div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        selectableRow={true}
        highlightRow={true}
      />
      <PdfModal
        show={showDocViewModal}
        setShow={setShowDocViewModal}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
      <EmailDocumentsModal
        show={showEmailDocs}
        setShow={setShowEmailDocs}
        claimNumber={claimNumber}
        docs={docs}
        userEmailList={userEmailList}
        handleEmailDocuments={handleEmailDocuments}
        subject={subject}
        body={body}
        toEmailAddresses={toEmailAddresses}
      />
      <WorkStatusModal
        show={showAddEdit}
        setShow={setShowAddEdit}
        nullWs={setSelectedStatus}
        workStatusId={selectedStatus?.id ?? null}
        authorizedPhysicians={physicians}
        claimNumber={claimNumber}
        getWorkStatusesForGrid={getWorkStatusesForGrid}
        claimType={claimType}
      />
    </div>
  );
}
