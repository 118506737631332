import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  FilterFn,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { Button } from 'react-bootstrap';
import { FaEdit, FaFileInvoiceDollar } from 'react-icons/fa';
import { ClaimNote } from '../../../ApiTypes/ClaimNote';
import { displayDateOnly } from '../../../Utils';
import { useAppSelector } from '../../../Reducers/Store';

export default function NcmNoteTable({
  notes,
  selectedNote,
  setSelectedNote,
  setShow,
  generateInvoice,
}: {
  notes: ClaimNote[];
  selectedNote: ClaimNote | null;
  setSelectedNote: (note: ClaimNote | null) => void;
  setShow: (show: boolean) => void;
  generateInvoice: (note: ClaimNote) => void;
}) {
  const { userModel } = useAppSelector((state) => state.user);
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => notes, [notes]);

  const noteTextFilter: FilterFn<ClaimNote> = (
    row,
    columnId,
    value,
    addMeta
  ) => {
    const item = row.getValue(columnId) as string;
    const it = JSON.stringify(item.toLowerCase())
      .replace(/^"|"$/g, '')
      .replace(/\\r+|\\n+|\\t/g, '');

    return it.includes(value.toLowerCase());
  };

  const columnData: ColumnDef<ClaimNote>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      maxSize: 75,
      size: 75,
      cell: (d) => {
        return (
          <div className='d-flex justify-content-around'>
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='Edit/View'
              onClick={() => {
                setSelectedNote(d.row.original);
                setShow(true);
              }}
              disabled={userModel?.user?.isNcm !== true}
            >
              <FaEdit />
            </Button>
            <Button
              type='button'
              size='sm'
              variant='primary'
              title='Generate Invoice'
              onClick={() => {
                generateInvoice(d.row.original);
              }}
              disabled={userModel?.user?.isAdmin !== true}
            >
              <FaFileInvoiceDollar />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Date',
      accessorKey: 'noteDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.noteDate ?? ''),
    },
    {
      header: 'User',
      accessorKey: 'userId',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Note Text',
      accessorFn: (d) => d.noteText,
      sortingFn: 'alphanumeric',
      filterFn: noteTextFilter,
      size: 300,
    },
    {
      header: 'Completed',
      accessorFn: (d) => (d.followUpComplete ? 'YES' : 'NO'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Follow Up Date',
      accessorKey: 'followUpDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.followUpDate ?? ''),
    },
    {
      header: 'Assigned To',
      accessorKey: 'assignedTo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date Completed',
      accessorKey: 'completedOn',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.completedOn ?? ''),
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
      />
    </div>
  );
}
