import React, { useState, useEffect, useCallback } from 'react';
import { Field, Form as RFFForm } from 'react-final-form';
import { Modal, Container, Form, Button, Spinner } from 'react-bootstrap';
import { FaEdit, FaPlusCircle } from 'react-icons/fa';

import styles from './index.module.css';
import { DoctorsOffice } from '../../ApiTypes/DoctorsOffice';
import DoctorOfficeApi from '../../Api/DoctorOfficeApi';
import { toast } from 'react-toastify';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';

export default function AddUpdateDoctorOffice({
  show,
  setShow,
  id,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  id?: number;
}) {
  const [doctorsOffice, setDoctorsOffice] = useState<DoctorsOffice | null>(
    null
  );
  const getOffice = useCallback(() => {
    if (id) {
      DoctorOfficeApi.getDoctorsOfficeById(id)
        .then((res) => {
          setDoctorsOffice(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setDoctorsOffice(null);
    }
  }, [id]);

  useEffect(() => {
    getOffice();
  }, [getOffice]);

  const submitOffice = (values: DoctorsOffice) => {
    if (values.id > 0) {
      return updateOffice(values);
    } else {
      return createOffice(values);
    }
  };

  const createOffice = (values: DoctorsOffice) => {
    return DoctorOfficeApi.createDoctorsOffice(values)
      .then((res) => {
        if (res.data.success) {
          setShow(false);
          // setShowNewOffice(false);
          // getDoctorOffices().then((r) => {
          //   formInstance.change('doctorId', +res.data.affectedEntityIdentifier);
          //   getDoctorLocationsForPhysician(+res.data.affectedEntityIdentifier);
          // });
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create doctors office');
      });
  };
  const updateOffice = (values: DoctorsOffice) => {
    return DoctorOfficeApi.updateDoctorsOffice(values)
      .then((res) => {
        if (res.data.success) {
          setShow(false);
          // getDoctorOffices().then((r) => {
          //   formInstance.change('doctorId', +res.data.affectedEntityIdentifier);
          //   getDoctorLocationsForPhysician(+res.data.affectedEntityIdentifier);
          // });
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update doctors office');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='View-Add-Edit-Physician-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='View-Add-Edit-Physician-Form-modal'
        >
          {id && id > 0 ? <FaEdit className='pe-1' /> : <FaPlusCircle />}
          {id && id > 0 ? 'Edit' : 'Add'}
          Facility
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={submitOffice}
            initialValues={
              doctorsOffice ?? {
                id: 0,
                doctorLocations: null,
                doctorNames: null,
              }
            }
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='officeName'
                    label='Facility Name'
                    type='text'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <Field
                    name='comments'
                    label='Notes'
                    rows={3}
                    component={FieldBSRenderTextArea}
                  />
                  <div className={`${styles.buttonDiv} my-5`}>
                    <Button type='submit' size='lg' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                    <Button
                      type='button'
                      size='lg'
                      variant='secondary'
                      onClick={() => {
                        form.reset();
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
