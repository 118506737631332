import React from 'react';
import DatePicker from 'react-datepicker';
import { isDate, format } from 'date-fns';
import { createDateObject } from '../../Utils';

import styles from './index.module.css';

type RenderDatePickerProps = {
  input: any;
  name: string;
  label: string;
  required: boolean;
  disabled: boolean;
  minDate: Date | null;
  className: string;
  onBlur: (v?: any) => void;
  onChange: (v?: any) => void;
  meta: { touched: boolean; error: string; warning: string };
};

export default function FieldBSRenderMonthPicker({
  input,
  name,
  label,
  required,
  disabled,
  minDate,
  className,
  onBlur,
  onChange,
  meta: { touched, error, warning },
}: RenderDatePickerProps) {
  const handleChange = (val: null | Date) => {
    if (isDate(val) && val instanceof Date) {
      const formatted = format(val, 'MM/yyyy');
      input.onChange(formatted);
      input.onBlur();
      onBlur && onBlur();
      onChange && onChange(formatted);
    } else {
      input.onChange(val);
      input.onBlur();
      onBlur && onBlur();
      onChange && onChange(val);
    }
  };

  return (
    <div
      className={`position-relative ${styles.vFieldHeight}`}
      style={{ width: '250px' }}
    >
      <label htmlFor={input.name} className='form-label fs-6  m-0'>
        {label}
      </label>
      <div>
        <DatePicker
          {...input}
          selected={
            isDate(input.value)
              ? input.value
              : !input.value
              ? null
              : createDateObject(input.value)
          }
          onChange={handleChange}
          name={input.name}
          id={input.name}
          className='form-control form-control-sm'
          disabled={disabled}
          showMonthYearPicker
          dropdownMode='select'
          minDate={minDate}
          popperPlacement='top-end'
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [5, 10],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                rootBoundary: 'document',
              },
            },
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['bottom', 'right', 'left'],
                rootBoundary: 'document',
                altBoundary: true,
              },
            },
          ]}
        />
        {touched &&
          ((error && (
            <span className={`${styles.fieldError} text-danger`}>{error}</span>
          )) ||
            (warning && (
              <span className={`${styles.fieldWarning} text-warning`}>
                {warning}
              </span>
            )))}
      </div>
    </div>
  );
}
